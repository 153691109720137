import { orderBy, uniq } from "lodash";

import type { DropdownOption } from "@/components/Elements/Dropdown/Dropdown";
import { generateUniqueId } from "@/utils/utils";

import {
  type Answer,
  type AnswerUpsert,
  type DocumentAnswer,
  type DocumentAnswerRead,
  type DocumentExisting,
  type FormQuestion,
  type FormQuestionGroup,
  isDocumentAnswer,
  isDocumentNew,
  isRadioAnswer,
  isTextAnswer,
  type Question,
  type QuestionChoice,
  type QuestionChoiceRead,
  type QuestionGroup,
  type QuestionGroupRead,
  type QuestionnaireRequest,
  type QuestionRead,
  QuestionType,
  type QuestionUpsert,
  type RadioAnswer,
  RiskType,
  type TextAnswer,
} from "../../features/risk-assessment";
import type { RiskArea } from "../../features/risk-assessment/types/risk-area";

function flattenQuestions(
  questions: Question[],
  groupRef: number,
  flattenedQuestions: QuestionUpsert[] = [],
  canAddResponders: boolean = false,
  includeIds: boolean = true,
): QuestionUpsert[] {
  for (const question of questions) {
    flattenedQuestions.push({
      ...(!question.isNew && includeIds && { id: question.id }),
      group_ref: groupRef,
      text: question.text,
      description: question.description,
      input_type: question.input_type,
      is_optional: question.is_optional,
      risk_type: question.risk_type,
      risk_area_id: question.risk_area_id,
      position: question.position,
      weight: question.weight,
      assignments_attributes: canAddResponders
        ? question.assignments.concat(question.responders)
        : question.assignments,
      _destroy: question._destroy,
      // @ts-expect-error TS(2322) FIXME: Type '{ ref: number; text: string; base_score: num... Remove this comment to see the full error message
      question_choices_attributes: question.choices.map((choice) => ({
        ...(!choice.isNew && includeIds && { id: choice.id }),
        ref: choice.id,
        text: choice.text,
        base_score: choice.base_score || null,
        position: choice.position,
        red_flag: choice.red_flag,
        _destroy: choice._destroy,
      })),
      trigger_choice_refs: question.triggeredBy.map((choice) => choice.id),
    });

    flattenQuestions(
      question.triggerQuestions,
      groupRef,
      flattenedQuestions,
      canAddResponders,
    );
  }

  return flattenedQuestions;
}

// @ts-expect-error TS(2345)
function scrubIds(data) {
  if (typeof data !== "object" || !data) {
    return;
  }
  for (const key in data) {
    if (key === "id") {
      delete data[key];
    } else {
      scrubIds(data[key]);
    }
  }
}

export function prepareQuestionnaireUpsertRequest(
  name: string,
  risk_methodology_id: number,
  groups: QuestionGroup[],
  isInternal = false,
  includeIds: boolean = true,
  saveAsTemplate: boolean = false,
  isPasswordProtected: boolean = false,
  dueDate: Date | null = null,
): QuestionnaireRequest {
  const questionnaire = {
    name,
    risk_methodology_id,
    is_internal: isInternal,
    due_date: dueDate,
    question_groups_attributes: groups.map((group) => ({
      ...(!group.isNew && includeIds && { id: group.id }),
      ref: group.id,
      text: group.name,
      position: group.position,
      assignments_attributes: isInternal
        ? group.assignments.concat(group.responders)
        : group.assignments,
      _destroy: Boolean(group._destroy),
    })),
    questions_attributes: groups
      .flatMap((group) =>
        flattenQuestions(group.questions, group.id, [], isInternal, includeIds),
      )
      .map((q, i) => ({
        ...q,
        position: i,
      })),
  };
  if (!includeIds) {
    scrubIds(questionnaire);
  }
  return {
    questionnaire: questionnaire!,
    save_as_template: saveAsTemplate,
    password_protected: isPasswordProtected,
  };
}

export function mapAnswer(answer: Answer): AnswerUpsert | undefined {
  if (isDocumentAnswer(answer)) {
    return {
      ...answer,
      attach_document_files: answer.documents
        .filter(isDocumentNew)
        .map((document) => document.externalFileId),
      answer_attachment_groups_attributes: answer.documents
        .filter((document) => !isDocumentNew(document) && document._destroy)
        // @ts-expect-error TS(2345) FIXME: Argument of type '(document: DocumentExisting) => ... Remove this comment to see the full error message
        .map((document: DocumentExisting) => ({
          id: document.id,
          _destroy: true,
        })),
    };
  } else if (isRadioAnswer(answer)) {
    return answer.question_choice_ids.length > 0 ? answer : undefined;
  }

  return answer;
}

function mapQuestionReadToQuestion(
  nonRootQuestionMap: Map<number, QuestionRead>,
  question: QuestionRead,
  parentChoices: QuestionChoiceRead[] = [],
): Question {
  const triggerQuestionIds = new Set<number>(
    question.question_choices.flatMap(
      (choice) => choice.depending_question_ids,
    ),
  );

  return {
    id: question.id,
    isNew: false,
    text: question.text,
    description: question.description,
    risk_type: question.risk_type,
    risk_area_id: question.risk_area_id,
    input_type: question.input_type,
    is_optional: question.is_optional,
    position: question.position,
    weight: question.weight,
    choices: question.question_choices.map((choice) => ({
      ...choice,
      id: choice.id,
      isNew: false,
      text: choice.text,
      base_score: choice.base_score,
      position: choice.position,
    })),
    triggeredBy: question.depending_question_choice_ids.map((choiceId) => ({
      id: choiceId,
      text: parentChoices.find((choice) => choice.id === choiceId)?.text || "",
    })),
    triggerQuestions: Array.from(triggerQuestionIds).map((id) =>
      mapQuestionReadToQuestion(
        nonRootQuestionMap,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'QuestionRead | undefined' is not... Remove this comment to see the full error message
        nonRootQuestionMap.get(id),
        question.question_choices,
      ),
    ),
    assignments: question.assignments,
    responders: question.responders,
  };
}

function mapQuestionsToNestedQuestions(questions: QuestionRead[]): Question[] {
  const rootQuestions: QuestionRead[] = [];
  const nonRootQuestionMap = new Map<number, QuestionRead>();

  for (const question of questions) {
    if (!question.depending_question_choice_ids.length) {
      rootQuestions.push(question);
    } else {
      nonRootQuestionMap.set(question.id, question);
    }
  }

  return rootQuestions.map((question) =>
    mapQuestionReadToQuestion(nonRootQuestionMap, question),
  );
}

export function mapQuestionsToGroups(
  questions: QuestionRead[],
  groups: QuestionGroupRead[],
): QuestionGroup[] {
  const sortedQuestions = orderBy(questions, "position", "asc");
  return groups.map((group) => {
    const questionsInGroup = sortedQuestions.filter(
      (question) => question.question_group_id === group.id,
    );

    return {
      id: group.id,
      isNew: false,
      name: group.text,
      position: group.position,
      questions: mapQuestionsToNestedQuestions(questionsInGroup),
      assignments: group.assignments,
      responders: group.responders,
    };
  });
}

function mapQuestionReadToFormQuestion(
  nonRootQuestionMap: Map<number, QuestionRead>,
  question: QuestionRead,
  parentChoices: QuestionChoiceRead[] = [],
): FormQuestion {
  const triggerQuestionIds = new Set<number>(
    question.question_choices.flatMap(
      (choice) => choice.depending_question_ids,
    ),
  );

  const baseAnswer: Pick<
    Answer,
    "id" | "comment" | "additional_files" | "is_completed"
  > = {
    id: question.answer?.id,
    comment: question.answer?.comment || "",
    additional_files: [],
    is_completed: question.answer?.is_completed,
  };
  let answer: Answer;

  if (question.input_type === QuestionType.DOCUMENTS) {
    answer = {
      ...baseAnswer,
      documents: (question.answer as DocumentAnswerRead)?.document_files || [],
    };
  } else if (
    [QuestionType.RADIO, QuestionType.YES_NO].includes(question.input_type)
  ) {
    answer = {
      ...baseAnswer,
      question_choice_ids:
        (question.answer as RadioAnswer)?.question_choice_ids || [],
    };
  } else if (question.input_type === QuestionType.TEXT) {
    answer = {
      ...baseAnswer,
      value: (question.answer as TextAnswer)?.value || "",
    };
  } else {
    throw new Error(
      `Question type "${question.input_type}" is not supported in mapping function`,
    );
  }

  return {
    id: question.id,
    isNew: false,
    text: question.text,
    description: question.description,
    risk_type: question.risk_type,
    risk_area_id: question.risk_area_id,
    input_type: question.input_type,
    is_optional: question.is_optional,
    position: question.position,
    weight: question.weight,
    responders: question.responders,
    question_group_id: question.question_group_id,
    choices: question.question_choices.map((choice) => ({
      ...choice,
      id: choice.id,
      isNew: false,
      text: choice.text,
      base_score: choice.base_score,
      position: choice.position,
    })),
    triggeredBy: question.depending_question_choice_ids.map((choiceId) => ({
      id: choiceId,
      text: parentChoices.find((choice) => choice.id === choiceId)?.text || "",
    })),
    answer,
    triggerQuestions: Array.from(triggerQuestionIds).map((id) =>
      mapQuestionReadToFormQuestion(
        nonRootQuestionMap,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'QuestionRead | undefined' is not... Remove this comment to see the full error message
        nonRootQuestionMap.get(id),
        question.question_choices,
      ),
    ),
    assignments: question.assignments,
    followup_ref_question_id: question.followup_ref_question_id,
    uncompleted_comments_count: question.uncompleted_comments_count,
  };
}

function mapFormQuestionsToNestedFormQuestions(
  questions: QuestionRead[],
): FormQuestion[] {
  const rootQuestions: QuestionRead[] = [];
  const nonRootQuestionMap = new Map<number, QuestionRead>();

  for (const question of questions) {
    if (!question.depending_question_choice_ids.length) {
      rootQuestions.push(question);
    } else {
      nonRootQuestionMap.set(question.id, question);
    }
  }

  return rootQuestions.map((question) =>
    mapQuestionReadToFormQuestion(nonRootQuestionMap, question),
  );
}

export function mapFormGroupsAndQuestionsToFormGroups(
  questions: QuestionRead[],
  groups: QuestionGroupRead[],
): FormQuestionGroup[] {
  const sortedQuestions = orderBy(questions, "position", "asc");
  return groups.map((group) => {
    const questionsInGroup = sortedQuestions.filter(
      (question) => question.question_group_id === group.id,
    );

    return {
      id: group.id,
      name: group.text,
      position: group.position,
      questions: mapFormQuestionsToNestedFormQuestions(questionsInGroup),
      assignments: group.assignments,
      responders: group.responders,
    };
  });
}

export function copyQuestion(
  question: Question,
  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Map<number,... Remove this comment to see the full error message
  parentChoiceIdMap: Map<number, number> = null,
): Question {
  const choiceIdMap = new Map<number, number>();

  for (const choice of question.choices) {
    choiceIdMap.set(choice.id, generateUniqueId());
  }

  return {
    ...question,
    id: generateUniqueId(),
    isNew: true,
    weight: question.weight,
    // @ts-expect-error TS(2322) FIXME: Type '{ isNew: true; id: number | undefined; text:... Remove this comment to see the full error message
    choices: question.choices.map((choice) => ({
      ...choice,
      isNew: true,
      id: choiceIdMap.get(choice.id),
    })),
    // @ts-expect-error TS(2322) FIXME: Type '{ id: number | undefined; text: string; }[]'... Remove this comment to see the full error message
    triggeredBy: parentChoiceIdMap
      ? question.triggeredBy.map((triggerChoice) => ({
          id: parentChoiceIdMap.get(triggerChoice.id),
          text: triggerChoice.text,
        }))
      : [],
    triggerQuestions: question.triggerQuestions.map((triggerQuestion) =>
      copyQuestion(triggerQuestion, choiceIdMap),
    ),
  };
}

export function createNewQuestion(
  questionType: QuestionType,
  questionOverrides: Partial<Question> = {},
): Question {
  let choices: QuestionChoice[] = [];

  if (questionType === QuestionType.RADIO) {
    choices = [
      {
        id: generateUniqueId(),
        isNew: true,
        text: "Option 1",
        base_score: 1,
      },
      {
        id: generateUniqueId(),
        isNew: true,
        text: "Option 2",
        base_score: 2,
      },
      {
        id: generateUniqueId(),
        isNew: true,
        text: "Option 3",
        base_score: 3,
      },
      {
        id: generateUniqueId(),
        isNew: true,
        text: "Option 4",
        base_score: 4,
      },
    ];
  } else if (questionType === QuestionType.YES_NO) {
    choices = [
      {
        id: generateUniqueId(),
        isNew: true,
        text: "Yes",
        base_score: 1,
      },
      {
        id: generateUniqueId(),
        isNew: true,
        text: "No",
        base_score: 4,
      },
    ];
  }

  return {
    id: generateUniqueId(),
    isNew: true,
    is_optional: false,
    triggeredBy: [],
    triggerQuestions: [],
    input_type: questionType,
    text: "",
    description: "",
    risk_type: RiskType.INHERENT,
    choices,
    assignments: [],
    responders: [],
    ...questionOverrides,
  };
}

function isTriggerQuestionTriggered(
  triggerQuestion: FormQuestion,
  parentSelectedChoiceIds: number[],
): boolean {
  return triggerQuestion.triggeredBy.some((choice) =>
    parentSelectedChoiceIds.includes(choice.id),
  );
}

function areFormQuestionAndTriggersValidForSubmit(
  question: FormQuestion,
): boolean {
  if ([QuestionType.RADIO, QuestionType.YES_NO].includes(question.input_type)) {
    const selectedChoiceIds = (question.answer as RadioAnswer)
      .question_choice_ids;

    if (!selectedChoiceIds || selectedChoiceIds.length === 0) {
      return false;
    }

    for (const triggerQuestion of question.triggerQuestions) {
      if (isTriggerQuestionTriggered(triggerQuestion, selectedChoiceIds)) {
        return areFormQuestionAndTriggersValidForSubmit(triggerQuestion);
      }
    }
  } else if (question.input_type === QuestionType.DOCUMENTS) {
    return (question.answer as DocumentAnswer).documents.length > 0;
  } else if (question.input_type === QuestionType.TEXT) {
    return (question.answer as TextAnswer).value.length > 0;
  } else {
    throw new Error(
      `Question type ${question.input_type} not supported in questionnaire form validation`,
    );
  }

  return true;
}

export function areFormGroupsValidForSubmit(
  groups: FormQuestionGroup[],
): boolean {
  return groups.every((group) =>
    group.questions.every(areFormQuestionAndTriggersValidForSubmit),
  );
}

function areQuestionAndTriggersValidForSubmit(question: Question): boolean {
  if (question._destroy) {
    return true;
  }
  return (
    Boolean(question.text.trim()) &&
    question.choices.every(
      (choice) => choice._destroy || Boolean(choice.text.trim()),
    ) &&
    question.triggerQuestions.every(areQuestionAndTriggersValidForSubmit)
  );
}

export function areGroupsValidForSubmit(groups: QuestionGroup[]): boolean {
  return groups.every((group) =>
    group.questions.every(areQuestionAndTriggersValidForSubmit),
  );
}

export function createNewGroup(name: string, position: number): QuestionGroup {
  return {
    id: generateUniqueId(),
    isNew: true,
    name,
    position,
    questions: [],
    assignments: [],
    responders: [],
  };
}

export function getRiskAreasFromQuestions(
  questions: QuestionRead[],
  moduleRiskAreas: RiskArea[],
): RiskArea[] {
  // @ts-expect-error TS(2322) FIXME: Type '(RiskArea | undefined)[]' is not assignable ... Remove this comment to see the full error message
  return uniq(
    questions.map((question) =>
      moduleRiskAreas.find((ra) => ra.id === question.risk_area_id),
    ),
  );
}

function getQuestionsRemaining(questions: FormQuestion[]): number {
  return questions.reduce((sum, question) => {
    if (!question.answer) {
      return sum + 1;
    }

    if (isRadioAnswer(question.answer)) {
      const radioAnswer = question.answer as RadioAnswer;
      if (radioAnswer.question_choice_ids.length === 0) {
        return sum + 1;
      }

      const triggeredQuestions = question.triggerQuestions.filter((tQuestion) =>
        isTriggerQuestionTriggered(tQuestion, radioAnswer.question_choice_ids),
      );
      return sum + getQuestionsRemaining(triggeredQuestions);
    } else if (isDocumentAnswer(question.answer)) {
      return sum + (question.answer.documents.length > 0 ? 0 : 1);
    } else if (isTextAnswer(question.answer)) {
      return sum + (question.answer.value.length > 0 ? 0 : 1);
    }

    throw new Error(
      `Question type ${question.input_type} not supported in questions remaining`,
    );
  }, 0);
}

export function getQuestionsRemainingForGroups(
  groups: FormQuestionGroup[],
): number {
  return groups.reduce(
    (sum, group) => sum + getQuestionsRemaining(group.questions),
    0,
  );
}

export function buildRiskRatingOptions(
  min: number,
  max: number,
): DropdownOption[] {
  const riskRatingOptions: DropdownOption[] = [
    {
      id: "NaN",
      name: "N/A",
    },
  ];

  for (let i = min; i <= max; i++) {
    riskRatingOptions.push({
      id: String(i),
      name: String(i),
    });
  }

  return riskRatingOptions;
}

export function markQuestionDestroyed(question: Question): Question {
  const stack: Question[] = [question];
  const visited: number[] = [];
  while (stack.length > 0) {
    const questionToHandle = stack.pop();
    if (!questionToHandle || visited.includes(questionToHandle.id)) {
      continue;
    }
    visited.push(questionToHandle.id);
    questionToHandle._destroy = true;
    for (const subQ of questionToHandle.triggerQuestions) {
      stack.push(subQ);
    }
  }

  return { ...question };
}
