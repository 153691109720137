import { http } from "msw";

import { createCreateModuleAssessmentMutationResponse } from "../createCreateModuleAssessment";

export const createModuleAssessmentHandler = http.post(
  "*/record_versions/:record_version_id/module_assessments",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateModuleAssessmentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
