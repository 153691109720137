import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import {
  PiCaretRightBold,
  PiDotsThreeOutlineVerticalFill,
} from "react-icons/pi";
import { Link, useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/options-check-blue-light.svg";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import PublishFlow from "../shared/PublishFlow";
import ReviewerApproveButton from "../shared/ReviewerApproveButton";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import Unlock from "../shared/Unlock";

interface Props {
  recordVersion: RecordVersion;
  showOnlyApprove?: boolean;
}

function DocumentsContextMenu({ recordVersion, showOnlyApprove }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const history = useHistory();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("menu");
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [sendSlide, setSendSlide] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  // @ts-expect-error TS(2339) FIXME: Property 'folder_id' does not exist on type '{}'.
  const { folder_id } = useParams();

  // Variables
  const { tableName, workspaceID, isIW } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const recordVersionID = recordVersion.id;
  const name = recordVersion.meta?.name;
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion.data,
  );
  const isPendingFinalization = status === "pending_finalization";
  const isPublished = status === "published";
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("documents", moduleWorkspaces, true);
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { taskDetail } = mainStore;

  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
      setSelectedFolder(null);
    }
  }, [showPopup]);

  // funcs
  const isDocumentsDetailView = useMemo(() => {
    const documentsDetailPageRe = /^\/modules\/documents\/\d+$/;
    const matches = location.pathname.match(documentsDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  // Variables
  const folderID = folder_id;
  const isSearch = location.pathname.includes("search");

  async function handleDeleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      if (isDocumentsDetailView) {
        history.push(`/workspaces/${workspaceID}/modules/documents`);
      }
      await mainStore.documents.delete(recordVersion.id);
    }
  }

  const handleArchive = async () => {
    await mainStore.documents.archive(recordVersionID);
    mainStore.documents.index({
      workspaceID,
      folderID: folderID || undefined,
    });
  };

  const handleOpenSendSlideMenu = () => {
    setShowPopup(false);
    setShowSlideMenu(true);
    setSendSlide(true);
  };

  const handleCloseSlideMenu = () => {
    setShowSlideMenu(false);
    setSendSlide(false);
  };

  const handleCreateTask = () => {
    setShowPopup(false);
    taskDetail.openFromRecord(recordVersion);
  };

  const moveToFolder = () => {
    if (recordVersion.section_tag_id) {
      history.push(
        `/workspaces/${workspaceID}/modules/documents/folder/${recordVersion.section_tag_id}`,
      );
    } else {
      history.push(`/workspaces/${workspaceID}/modules/documents`);
    }
  };

  const handleMoveDocument = async () => {
    const successMessage = name
      ? // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        `"${name}" moved to "${selectedFolder.title}"`
      : // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        `Moved to "${selectedFolder.title}"`;

    setShowPopup(false);

    try {
      await mainStore.documents.move({
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        folderID: selectedFolder.id === "dashboard" ? null : selectedFolder.id,
        recordVersionID,
      });
      mainStore.toast.setInfoText(successMessage);
    } catch {
      mainStore.toast.setErrorText("Unable to move");
    }
  };

  const onArchive = () => {
    setViewPopup("archive");
  };

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          showPopup,
      })}
    />
  );

  const deleteClassNames = classNames({ disabled: isPublished });

  const menuPopup = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="documents-context-menu-dropdown"
    >
      <ul>
        {!isDocumentsDetailView && (
          <Link
            to={`/workspaces/${workspaceID}/modules/documents/${recordVersionID}`}
            data-testid="view-detail"
          >
            View {recordName} Detail
          </Link>
        )}
        {hasModuleWriteAccess && (
          <li
            className={classNames({
              disabled: mainStore.sectionTags.list.length === 0,
            })}
            data-testid="documents-context-menu-dropdown-move"
            onClick={() => setViewPopup("move")}
          >
            Move to
          </li>
        )}

        {isIW && hasModuleWriteAccess && (
          <ShareToCW
            recordName={recordName}
            sendTrigger={handleOpenSendSlideMenu}
          />
        )}

        <Link
          data-testid="historical-versions"
          to={`/workspaces/${workspaceID}/modules/documents/${recordVersionID}/historical-versions`}
        >
          View Previous Versions
        </Link>

        {isPublished && hasModuleWriteAccess && (
          <li onClick={onArchive} data-testid="archive-button-trigger">
            Archive
          </li>
        )}
        <li onClick={handleCreateTask}>Create Task</li>

        {canDeleteRecords && (
          <>
            <hr />
            <li
              data-testid="documents-context-menu-dropdown-delete"
              onClick={() => !isPublished && setViewPopup("delete")}
              // @ts-expect-error TS(2322) FIXME: Type '{ children: string; "data-testid": string; o... Remove this comment to see the full error message
              disabled={isPublished}
              className={deleteClassNames}
            >
              Delete
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const allFolders = [
    folderID && { id: "dashboard", title: "Document Dashboard" },
    ...mainStore.sectionTags.list,
  ].filter((e) => e && String(e.id) !== folderID);

  const onApprove = async () => {
    await mainStore.documents.approve(recordVersionID);
    await mainStore.recordVersions.fetch(recordVersionID);
  };

  const onUnapprove = async () => {
    await mainStore.documents.unapprove(recordVersionID);
    await mainStore.recordVersions.fetch(recordVersionID);
  };

  async function onUnlock() {
    const result = await mainStore.documents.unlock(recordVersion.id);
    if (isDocumentsDetailView) {
      history.push(
        `/workspaces/${workspaceID}/modules/documents/${result.record_version.id}`,
      );
    }
  }

  const movePopup = (
    <div
      className="table-dropdown documents-context-menu-move"
      data-testid="documents-context-menu-move"
    >
      <span className="move-description">Select location to move to</span>

      <ul className="move-folders">
        {allFolders.map((folder) => (
          <li
            key={folder.id}
            className={classNames({
              "move-folder": folder.id !== "dashboard",
              "move-folder-dashboard": folder.id === "dashboard",
              // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
              active: folder.id === selectedFolder?.id,
            })}
            data-testid={`move-folder-${folder.id}`}
            onClick={() => setSelectedFolder(folder)}
          >
            <span className="move-folder-icon" />
            {folder.title}
            {/* @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'. */}
            {folder.id === selectedFolder?.id && (
              <img
                src={checkIcon}
                className="move-folder-check-icon"
                alt="move-folder-icon"
              />
            )}
          </li>
        ))}
      </ul>

      <button
        className="move-button"
        disabled={!selectedFolder}
        data-testid="move-folder-button"
        onClick={handleMoveDocument}
      >
        Move Document
      </button>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Document"
      content="Are you sure you want to delete this document? Actions are not reversible."
      handleConfirm={handleDeleteRow}
      handleReject={() => setShowPopup(false)}
    />
  );

  const renderArchiveConfirmation = (
    <div
      className="table-dropdown success-dropdown archive"
      style={{ width: 230 }}
    >
      <div>
        <h4>Archive Document</h4>
        <p>
          Please read carefully and make sure this Document qualifies the
          following points before you confirm archive:
        </p>
        <ul className="ul-square">
          <li>Document is no longer enforced</li>
          <li>No further updates can be made</li>
          <li>Open versions will be deleted with archive</li>
        </ul>
        <div className="confirmation">
          <button onClick={() => handleArchive()}>Yes</button>
          <button onClick={() => setShowPopup(false)}>No</button>
        </div>
      </div>
    </div>
  );

  const classes = classNames("list-points", {
    "without-background im-action-bar-buttons": showOnlyApprove,
  });

  const statusWorkflowButton = (
    <div className={classes} data-testid="documents-context-menu">
      {!isPublished && !isPendingFinalization && (
        <ReviewerApproveButton
          approversIDs={mainStore.avroSchemas.valueForField(
            "approvers",
            recordVersion?.data,
          )}
          moduleIdentifier="documents"
          onApprove={onApprove}
          onUnapprove={onUnapprove}
          reviews={recordVersion?.reviews || []}
        />
      )}

      {isPendingFinalization && (
        <PublishFlow
          recordVersionID={recordVersion.id}
          // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
          tableName={tableName}
          moduleStore={mainStore.documents}
          moduleIdentifier="documents"
        />
      )}

      {isPublished && (
        <Unlock
          onYes={onUnlock}
          buttonText="Unlock"
          showIcon={false}
          canEveryoneUnlock
          moduleIdentifier="documents"
        />
      )}

      <Popup
        position="bottom right"
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => renderTrigger}
        open={showPopup}
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
        keepTooltipInside
      >
        {viewPopup === "menu" && menuPopup}
        {viewPopup === "move" && movePopup}

        {viewPopup === "delete" && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}

        {viewPopup === "archive" && renderArchiveConfirmation}
      </Popup>

      {sendSlide && (
        <SlideMenu open={showSlideMenu} closeSlideMenu={handleCloseSlideMenu}>
          <SendRecordVersion
            recordVersionID={recordVersionID}
            recordName={recordName}
          />
        </SlideMenu>
      )}
    </div>
  );

  const moveToFolderButton = (
    <div
      className="tw-sticky tw-right-0 tw-z-[99] tw-pt-[5px]"
      data-tooltip-id="tooltip"
      data-tooltip-content="Move to the folder with this record"
      data-tooltip-place="right"
    >
      <IconButton
        Icon={PiCaretRightBold}
        color="primary"
        size="lg"
        onClick={moveToFolder}
      />
    </div>
  );

  return isSearch ? moveToFolderButton : statusWorkflowButton;
}

export default observer(DocumentsContextMenu);
