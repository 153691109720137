import React from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DetailView from "@/components/detailView/DetailView";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import PolicyActionBar from "@/components/table/policy/PolicyActionBar";
import Table from "@/components/table/Table";

interface PoliciesDetailViewPageProps {
  recordName: string;
}

const PoliciesDetailViewPage = ({
  recordName,
}: PoliciesDetailViewPageProps) => {
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader
        title={`${recordName} Detail`}
        onBackClick={() => history.goBack()}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<PolicyActionBar />}
      />
      <DashboardContentWrapper>
        <Table>
          <DetailView
            moduleIdentifier="policy"
            disabledFields={[]}
            ignoredFields={["approvers"]}
          />
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default PoliciesDetailViewPage;
