import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@themis/ui";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useWorkspaces } from "@/api/queries/workspaces/use-workspaces";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { useSearchParams } from "@/hooks/useSearchParams";

import { getSelectedWorkspaces } from "../utils";

export interface WorkspacesSearchParams {
  workspace_ids?: string[];
}

export function ManageWorkspacesModal({ onClose }: { onClose: () => void }) {
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const [{ workspace_ids }, setSearchParams] =
    useSearchParams<WorkspacesSearchParams>();

  const workspaceIds = getSelectedWorkspaces({
    selectedWorkspaceIds: workspace_ids,
    currentWorkspaceId: Number(workspace_id),
  });

  const [checkedWorkspaceIds, setCheckedWorkspaceIds] = useState(workspaceIds);

  const { data: workspaces } = useWorkspaces({
    select: (_workspaces) =>
      _workspaces.filter((workspace) => !workspace.is_archived),
  });

  const isSaveDisabled =
    !checkedWorkspaceIds.length ||
    isEqual(workspaceIds.sort(), checkedWorkspaceIds.sort());

  function handleSave() {
    setSearchParams({
      workspace_ids: checkedWorkspaceIds.map(String),
    });
    onClose();
  }

  function handleClickWorkspace(id: number) {
    setCheckedWorkspaceIds((prev: number[]) =>
      prev.includes(id)
        ? prev.filter((workspaceId: number) => workspaceId !== id)
        : [...prev, id],
    );
  }

  return (
    <Dialog open>
      <DialogContent className="tw-w-[480px] tw-px-0" onClose={onClose}>
        <DialogHeader className="tw-px-6">
          <DialogTitle>Manage</DialogTitle>
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Select which Workspace data to include in the dashboard views
          </div>
        </DialogHeader>
        <section className="tw-flex tw-max-h-60 tw-flex-col tw-self-stretch tw-overflow-auto">
          {workspaces?.map((workspace) => {
            const checked = checkedWorkspaceIds.includes(workspace.id);

            return (
              <div
                key={workspace.id}
                onClick={() => handleClickWorkspace(workspace.id)}
                className="tw-box-border tw-flex tw-min-h-10 tw-cursor-pointer tw-items-center tw-gap-2 tw-self-stretch tw-px-6 tw-py-2.5 hover:tw-bg-primary-25 [&>p]:hover:tw-text-primary-300"
              >
                <Checkbox
                  size="md"
                  color={checked ? "secondary" : "black"}
                  checked={checked}
                />
                <img
                  src={getWorkspaceLogo(workspace).logo}
                  alt={workspace.name}
                  className="tw-h-5 tw-w-5 tw-rounded-md"
                />
                <p className="tw-truncate tw-text-sm tw-text-neutral-500">
                  {workspace.name}
                </p>
              </div>
            );
          })}
        </section>
        <DialogFooter className="tw-px-6">
          <Button onClick={handleSave} disabled={isSaveDisabled}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
