import { useQuery } from "@tanstack/react-query";

import { _1_HOUR_MS } from "@/constants/duration";
import type { User } from "@/stores/types/user-types";

import api from "../legacy/api";

export const USERS_QUERY_KEY = "users";

export function useCurrentUser() {
  return useQuery({
    queryKey: [USERS_QUERY_KEY, "current"],
    queryFn: async () => {
      const user = await api.get<{ user: User }>(`/users/current`);

      return user.data.user;
    },
    staleTime: _1_HOUR_MS,
  });
}
