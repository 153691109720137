import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListRecordLinksPathParams,
  ListRecordLinksQueryResponse,
} from "../../models/ListRecordLinks";

/**
 * @description Get all record links for a record
 * @summary List record links
 * @link /:record_type/:record_id/record_links
 */
export async function listRecordLinks(
  recordType: ListRecordLinksPathParams["record_type"],
  recordId: ListRecordLinksPathParams["record_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListRecordLinksQueryResponse>["data"]> {
  const res = await client<ListRecordLinksQueryResponse>({
    method: "get",
    url: `/${recordType}/${recordId}/record_links`,
    ...options,
  });
  return res.data;
}
