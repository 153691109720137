import { http } from "msw";

import { createCreateAccountMutationResponse } from "../createCreateAccount";

export const createAccountHandler = http.post(
  "*/workspaces/:workspace_id/accounts",
  function handler(info) {
    return new Response(JSON.stringify(createCreateAccountMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
