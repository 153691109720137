import "./risk-methodology-page.scss";

import React from "react";
import { generatePath, useHistory } from "react-router-dom";

import HeadSelect from "@/components/table/shared/HeadSelect";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import ViewModuleUsers from "../../../../components/dashboard/ViewModuleUsers";
import DashboardContent from "../../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../../components/shared/DashboardHeader";
import ModuleHeader from "../../components/ModuleHeader/ModuleHeader";
import RiskAssessmentTabs from "../../components/RiskAssessmentTabs";
import RiskMethodologyTable from "../../components/RiskMethodology/RiskMethodologyTable";
import TemplateTabs from "../../components/TemplateTabs";
import { routes } from "../../routes";

export default function RiskMethodologyPage() {
  const history = useHistory();
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const addTemplate = () => {
    history.push(
      generatePath(routes.METHODOLOGY_CREATION_PATH, {
        workspace_id: Number(workspaceID),
      }),
    );
  };
  return (
    <DashboardContent>
      <DashboardHeader
        title="Questionnaires"
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper>
        <ModuleHeader>
          <RiskAssessmentTabs />
        </ModuleHeader>
        <ModuleHeader>
          <TemplateTabs />
        </ModuleHeader>
        <AddRecordHeader onAddRecord={addTemplate} recordName="Template" />

        <div className="risk-methodology-page">
          <RiskMethodologyTable />
        </div>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}
