import type {
  CreateAndFinalizeControlMappings201,
  CreateAndFinalizeControlMappings403,
  CreateAndFinalizeControlMappingsMutationRequest,
  CreateAndFinalizeControlMappingsMutationResponse,
} from "../models/CreateAndFinalizeControlMappings";
import { createControlMappings } from "./createControlMappings";
import { createUpdateRecordVersionRequest } from "./createUpdateRecordVersionRequest";

/**
 * @description OK
 */
export function createCreateAndFinalizeControlMappings201(): NonNullable<CreateAndFinalizeControlMappings201> {
  return { data: createControlMappings() };
}

/**
 * @description Forbidden
 */
export function createCreateAndFinalizeControlMappings403(): NonNullable<CreateAndFinalizeControlMappings403> {
  return { errors: {} };
}

export function createCreateAndFinalizeControlMappingsMutationRequest(): NonNullable<CreateAndFinalizeControlMappingsMutationRequest> {
  return { data: createUpdateRecordVersionRequest() };
}

/**
 * @description OK
 */
export function createCreateAndFinalizeControlMappingsMutationResponse(): NonNullable<CreateAndFinalizeControlMappingsMutationResponse> {
  return { data: createControlMappings() };
}
