import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { TaskDetailView } from "../components/TaskDetailView";
import { myTaskRoutes } from "../routes";
import Archived from "./Archived";
import MyTasksPage from "./MyTasksPage";

export function MyTasksRoutes() {
  return (
    <Switch>
      <Route exact path={myTaskRoutes.base}>
        <Redirect
          to={{
            pathname: myTaskRoutes.assignedToMeView,
          }}
        />
      </Route>
      <Route path={myTaskRoutes.viewsBase}>
        <MyTasksPage />
      </Route>
      <Route path={myTaskRoutes.taskDetail}>
        <TaskDetailView />
      </Route>
      <Route path={myTaskRoutes.archived}>
        <Archived />
      </Route>
    </Switch>
  );
}
