import "./partner-documents-page.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import ExportBulk from "@/components/dashboard/ExportBulk";
import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import { Icon } from "@/components/Elements";
import Loading from "@/components/Loading";
import AttachmentOverlay from "@/components/shared/attachment-overlay/AttachmentOverlay";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useMainStore } from "@/contexts/Store";
import ModuleHeader from "@/features/risk-assessment/components/ModuleHeader/ModuleHeader";
import RowOptions from "@/features/risk-assessment/components/RowOptions/RowOptions";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import GenericInputCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/GenericInputCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableCellSpacer from "@/features/risk-assessment/components/Table/GenericTable/TableCellSpacer";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";

import { PartnersAPI } from "../../../../api/legacy/risk-assessment/PartnersApi";
import { routes } from "../../routes";
import type { RAPartnerDocument } from "../../types/partners";
import { getPartnerName } from "../../utils/partners";
import EmptyMessage from "../EmptyMessage/EmptyMessage";
import { ModuleContentWrapper } from "../ModuleContentWrapper/ModuleContentWrapper";
import LongTextTableCell from "../Table/GenericTable/LongTextTableCell";
import SendDocumentContent from "./SendDocuments/SendDocumentContent";

interface PartnerDocumentsWrapperProps {
  recordVersionID: string;
  Tabs: React.FC;
  showActions?: boolean;
  backButtonPath?: string;
}

function PartnerDocumentsWrapper({
  recordVersionID,
  Tabs,
  showActions,
  backButtonPath,
}: PartnerDocumentsWrapperProps) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const [loading, setLoading] = useState(false);
  const [partnerDocuments, setPartnerDocuments] = useState<RAPartnerDocument[]>(
    [],
  );
  const [viewedAttachmentUrl, setViewedAttachmentUrl] = useState<string | null>(
    null,
  );
  const [sortObject, setSortObject] = useState<null | {
    fieldName: string;
    direction: SortOptions;
  }>(null);
  const [copyAttachmentGroupID, setCopyAttachmentGroupID] = useState<
    number | undefined
  >(undefined);

  const history = useHistory();
  const partnerName = getPartnerName(
    mainStore.riskAssessmentPartners.partners,
    recordVersionID,
  );

  const handleCloseSlideMenu = () => setCopyAttachmentGroupID(undefined);
  const openSendDocumentSlideOut = (id: number) => {
    setCopyAttachmentGroupID(id);
  };

  const downloadURI = (uri: string) => {
    const link = document.createElement("a");
    link.href = uri;
    link.click();
  };

  const exportTable = async () => {
    try {
      if (workspaceID) {
        const table = await PartnersAPI.exportPartnerDocumentTable(
          workspaceID,
          parseInt(recordVersionID),
        );
        if (table) {
          const link = document.createElement("a");
          const blob = new Blob([new Uint8Array(table)], {
            type: "application/octet-stream",
          });
          link.href = URL.createObjectURL(blob);
          link.download = "exported_documents.xlsx";
          link.click();
        }
      }
    } catch {
      mainStore.toast.setErrorText("Something went wrong while downloading");
    }
  };

  const exportFiles = async () => {
    try {
      if (workspaceID) {
        const zipData = await PartnersAPI.exportPartnerDocuments(
          workspaceID,
          parseInt(recordVersionID),
        );
        if (zipData) {
          const link = document.createElement("a");
          const blob = new Blob([new Uint8Array(zipData)], {
            type: "application/octet-stream",
          });
          link.href = URL.createObjectURL(blob);
          link.download = "exported_documents.zip";
          link.click();
        }
      }
    } catch {
      mainStore.toast.setErrorText("Something went wrong while downloading");
    }
  };

  const fetchPartnerDocuments = async (
    sortFieldName: null | string,
    sortDirection: SortOptions,
  ) => {
    setLoading(true);
    try {
      if (workspaceID) {
        let sortParams: {
          field_name: string;
          direction: SortOptions;
        } | null = null;
        if (sortFieldName && sortDirection) {
          sortParams = { field_name: sortFieldName, direction: sortDirection };
        }
        const documents = await PartnersAPI.listDocuments(
          workspaceID,
          recordVersionID,
          sortParams,
        );
        setPartnerDocuments(documents);
      }
    } catch {
      mainStore.toast.setErrorText("Something went wrong");
    }
    setLoading(false);
  };

  const setSort = (
    fieldName: string,
    direction: SortOptions,
  ): Promise<void> => {
    setSortObject({ fieldName, direction });
    return fetchPartnerDocuments(fieldName, direction);
  };

  const updateName = (id: number, docName: string) => {
    setPartnerDocuments((prev) =>
      prev.map((subDoc) => {
        if (subDoc.id !== id) {
          return subDoc;
        }
        subDoc.document_identifier = docName;
        return subDoc;
      }),
    );
  };

  const onBlurName = async (id: number, docName: string) => {
    try {
      if (workspaceID) {
        await PartnersAPI.updatePartnerDocumentName(
          workspaceID,
          parseInt(recordVersionID),
          id,
          docName,
        );
      }
    } catch {
      mainStore.toast.setErrorText(
        "Something went wrong while updating the document name",
      );
    }
  };

  useEffect(() => {
    if (!workspaceID) {
      return;
    }

    fetchPartnerDocuments(null, null);
  }, [workspaceID]);

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader={false} />;
  }

  if (viewedAttachmentUrl) {
    return (
      <Popup modal open onClose={() => setViewedAttachmentUrl(null)}>
        <AttachmentOverlay
          fileUrl={viewedAttachmentUrl}
          onClose={() => setViewedAttachmentUrl(null)}
        />
      </Popup>
    );
  }

  return (
    <DashboardContent>
      <DashboardHeader
        title={`${partnerName} Documents`.trim()}
        onBackClick={() =>
          history.push(
            backButtonPath ||
              generatePath(routes.PARTNERS_PATH, {
                workspace_id: Number(workspaceID),
              }),
          )
        }
        LeftActionBar={<ViewModuleUsers />}
      />
      <DashboardContentWrapper>
        <ModuleHeader>
          <Tabs />
          {partnerDocuments.length > 0 && (
            <ExportBulk
              isExportFilesEnabled
              customFileExportFunction={exportFiles}
              customTableExportFunction={exportTable}
            />
          )}
        </ModuleHeader>
        <ModuleContentWrapper>
          {partnerDocuments.length === 0 ? (
            <EmptyMessage>
              No documents available. The documents from answers will appear
              here.
            </EmptyMessage>
          ) : (
            <Table
              header={
                <>
                  <TableHeaderCell
                    title="Document Name"
                    identifier="document_identifier"
                    leftIcon={<Icon color="extrasBlueGrayDarker" name="text" />}
                    setSort={setSort}
                    sortDirection={
                      sortObject &&
                      sortObject.fieldName === "document_identifier"
                        ? sortObject.direction
                        : null
                    }
                    firstDataHeader
                  />
                  <TableHeaderCell
                    title="Attachment"
                    identifier="attachment"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="attachment" />
                    }
                    setSort={setSort}
                    sortDirection={
                      sortObject && sortObject.fieldName === "attachment"
                        ? sortObject.direction
                        : null
                    }
                  />
                  <TableHeaderCell
                    title="Assessment"
                    identifier="assessment"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="book1" />
                    }
                    setSort={setSort}
                    sortDirection={
                      sortObject && sortObject.fieldName === "assessment"
                        ? sortObject.direction
                        : null
                    }
                  />
                  <TableHeaderCell
                    title="Question"
                    identifier="question"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="book1" />
                    }
                    setSort={setSort}
                    sortDirection={
                      sortObject && sortObject.fieldName === "question"
                        ? sortObject.direction
                        : null
                    }
                  />
                  <TableHeaderCell
                    title="Risk Area"
                    identifier="risk_area"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="book1" />
                    }
                    setSort={setSort}
                    sortDirection={
                      sortObject && sortObject.fieldName === "risk_area"
                        ? sortObject.direction
                        : null
                    }
                  />
                  <TableHeaderCell
                    title="Upload Date"
                    identifier="upload_date"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="book1" />
                    }
                    setSort={setSort}
                    sortDirection={
                      sortObject && sortObject.fieldName === "upload_date"
                        ? sortObject.direction
                        : null
                    }
                    lastDataHeader
                  />
                  <TableCellSpacer />
                </>
              }
            >
              {partnerDocuments.map((doc) => (
                <TableRow rowIndex={1} key={`partner-document-${doc.id}`}>
                  <GenericInputCell
                    onChange={(docName: string) => updateName(doc.id, docName)}
                    value={doc.document_identifier}
                    isFirstDataCell
                    minWidth={200}
                    onBlur={(event) => onBlurName(doc.id, event.target.value)}
                  />
                  <ContentCell
                    content={
                      <div className="partner-attachment-cell">
                        <div
                          className="filename tw-truncate"
                          data-tooltip-id="tooltip"
                          data-tooltip-content={doc.name}
                        >
                          {doc.name}
                        </div>
                        <div className="tw-flex tw-gap-1 tw-align-middle">
                          <Icon
                            name="eye"
                            className="tw-h-4.5 tw-w-4.5"
                            onClick={() =>
                              setViewedAttachmentUrl(doc.attachment_url)
                            }
                          />
                          <Icon
                            name="download"
                            className="tw-h-4.5 tw-w-4.5"
                            onClick={() => downloadURI(doc.attachment_url)}
                          />
                        </div>
                      </div>
                    }
                    minWidth={200}
                  />
                  <LongTextTableCell>{doc.assessment_name}</LongTextTableCell>
                  <LongTextTableCell>{doc.question_text}</LongTextTableCell>
                  <ContentCell content={doc.risk_area_name} minWidth={200} />
                  <ContentCell content={doc.upload_date} minWidth={200} />
                  {showActions && (
                    <ActionCell>
                      <RowOptions
                        options={[
                          {
                            label: "Send to Module ",
                            onClick: () => openSendDocumentSlideOut(doc.id),
                          },
                        ]}
                      />
                    </ActionCell>
                  )}
                </TableRow>
              ))}
            </Table>
          )}
        </ModuleContentWrapper>
      </DashboardContentWrapper>
      {copyAttachmentGroupID && copyAttachmentGroupID > 0 && (
        <SlideMenu
          open={Boolean(copyAttachmentGroupID)}
          closeSlideMenu={handleCloseSlideMenu}
        >
          <SendDocumentContent
            closeSlide={handleCloseSlideMenu}
            attachmentGroupID={copyAttachmentGroupID}
          />
        </SlideMenu>
      )}
    </DashboardContent>
  );
}

export default observer(PartnerDocumentsWrapper);
