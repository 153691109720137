import { cn } from "@themis/ui";
import keyBy from "lodash/keyBy";
import type { ReactNode } from "react";
import type { SuggestionDataItem } from "react-mentions";
import { Mention, MentionsInput } from "react-mentions";

import { UsersCircle } from "@/components/users-circle/users-circle";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";
import type { User } from "@/stores/types/user-types";

interface UserMentionProps {
  commentInput: string;
  disableSubmit: boolean;
  placeholder: string;
  onChangeCommentInput: (commentInput: string) => void;
  onSubmit: (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  users: User[];
}

function renderSuggestionList(children: ReactNode) {
  return (
    <div className="tw-overflow-hidden tw-rounded-lg tw-py-2 tw-shadow-lg">
      {children}
    </div>
  );
}

function renderSuggestion(_suggestion: SuggestionDataItem, user: User) {
  return (
    <div className="tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-px-4 tw-py-1 tw-text-xs tw-font-bold tw-text-neutral-700 hover:tw-bg-primary-25 hover:tw-text-primary-200">
      <UsersCircle
        initials={user.initials}
        iconColorIndex={user.icon_color_index}
      />
      {user.full_name}
      {user.awaiting_verification && (
        <span
          className={cn(
            user.awaiting_verification
              ? "tw-text-neutral-300"
              : "tw-text-neutral-600",
          )}
        >
          {user.status}
        </span>
      )}
    </div>
  );
}

export function UserMention({
  commentInput,
  disableSubmit,
  placeholder,
  onSubmit,
  onChangeCommentInput,
  users,
}: UserMentionProps) {
  const usersMap = keyBy(users, "id");

  const isCommentMentionsEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_COMMENT_MENTIONS,
  );

  const handleSubmit = (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !disableSubmit &&
      commentInput.trim().length > 0
    ) {
      event.preventDefault();
      onSubmit(event);
    }
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      <MentionsInput
        className="tw-w-full tw-bg-transparent tw-p-0"
        style={{
          input: {
            border: "solid #e4e4eb 1px",
            borderRadius: "0.375rem",
            fontSize: "0.75rem",
            lineHeight: "1rem",
            padding: 4,
          },
          control: {
            fontSize: "0.75rem",
            lineHeight: "1rem",
          },
          highlighter: {
            zIndex: 1,
            pointerEvents: "none",
            padding: 4,
          },
        }}
        value={commentInput}
        onChange={(event) => onChangeCommentInput(event.target.value)}
        onKeyDown={handleSubmit}
        allowSuggestionsAboveCursor
        placeholder={placeholder}
        customSuggestionsContainer={renderSuggestionList}
      >
        <Mention
          trigger="@"
          className="tw-text-lightBlue-3"
          data={
            isCommentMentionsEnabled
              ? users.map((user) => ({
                  ...user,
                  id: user.id.toString(),
                  display: user.full_name,
                }))
              : []
          }
          renderSuggestion={(suggestion) =>
            renderSuggestion(suggestion, usersMap[suggestion.id])
          }
          appendSpaceOnAdd
        />
      </MentionsInput>
    </div>
  );
}
