import { http } from "msw";

import { createShowFindingQueryResponse } from "../createShowFinding";

export const showFindingHandler = http.get(
  "*/findings/:id",
  function handler(info) {
    return new Response(JSON.stringify(createShowFindingQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
