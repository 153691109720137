import { faker } from "@faker-js/faker";

import type {
  CompanyModuleRecordsReport201,
  CompanyModuleRecordsReport403,
  CompanyModuleRecordsReport422,
  CompanyModuleRecordsReportMutationResponse,
  CompanyModuleRecordsReportPathParams,
  CompanyModuleRecordsReportQueryParams,
} from "../models/CompanyModuleRecordsReport";

export function createCompanyModuleRecordsReportPathParams(): NonNullable<CompanyModuleRecordsReportPathParams> {
  return {
    company_id: faker.number.int(),
    module_identifier: faker.helpers.arrayElement<any>([
      "complaints",
      "issue_management",
    ]),
  };
}

export function createCompanyModuleRecordsReportQueryParams(): NonNullable<CompanyModuleRecordsReportQueryParams> {
  return {
    module_identifier: faker.helpers.arrayElement<any>([
      "complaints",
      "issue_management",
    ]),
  };
}

/**
 * @description Report generation initiated successfully
 */
export function createCompanyModuleRecordsReport201(): NonNullable<CompanyModuleRecordsReport201> {
  return { data: faker.string.alpha() };
}

/**
 * @description Forbidden
 */
export function createCompanyModuleRecordsReport403(): NonNullable<CompanyModuleRecordsReport403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Unprocessable Entity
 */
export function createCompanyModuleRecordsReport422(): NonNullable<CompanyModuleRecordsReport422> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Report generation initiated successfully
 */
export function createCompanyModuleRecordsReportMutationResponse(): NonNullable<CompanyModuleRecordsReportMutationResponse> {
  return { data: faker.string.alpha() };
}
