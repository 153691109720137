import { http } from "msw";

import { createDeleteModuleAssessmentMutationResponse } from "../createDeleteModuleAssessment";

export const deleteModuleAssessmentHandler = http.delete(
  "*/module_assessments/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteModuleAssessmentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
