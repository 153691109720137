import { faker } from "@faker-js/faker";

import type {
  UpdateDepartment200,
  UpdateDepartmentMutationRequest,
  UpdateDepartmentMutationResponse,
  UpdateDepartmentPathParams,
} from "../models/UpdateDepartment";
import { createDepartment } from "./createDepartment";

export function createUpdateDepartmentPathParams(): NonNullable<UpdateDepartmentPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateDepartment200(): NonNullable<UpdateDepartment200> {
  return { data: createDepartment() };
}

export function createUpdateDepartmentMutationRequest(): NonNullable<UpdateDepartmentMutationRequest> {
  return { department: createDepartment() };
}

/**
 * @description OK
 */
export function createUpdateDepartmentMutationResponse(): NonNullable<UpdateDepartmentMutationResponse> {
  return { data: createDepartment() };
}
