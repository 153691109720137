import { faker } from "@faker-js/faker";

import type {
  CreateAccountDocument201,
  CreateAccountDocument403,
  CreateAccountDocumentMutationRequest,
  CreateAccountDocumentMutationResponse,
  CreateAccountDocumentPathParams,
} from "../models/CreateAccountDocument";
import { createDocument } from "./createDocument";
import { createModifyDocumentRequest } from "./createModifyDocumentRequest";

export function createCreateAccountDocumentPathParams(): NonNullable<CreateAccountDocumentPathParams> {
  return { account_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateAccountDocument201(): NonNullable<CreateAccountDocument201> {
  return { document: createDocument() };
}

/**
 * @description Forbidden
 */
export function createCreateAccountDocument403(): NonNullable<CreateAccountDocument403> {
  return { error: {} };
}

export function createCreateAccountDocumentMutationRequest(): NonNullable<CreateAccountDocumentMutationRequest> {
  return { document: createModifyDocumentRequest() };
}

/**
 * @description OK
 */
export function createCreateAccountDocumentMutationResponse(): NonNullable<CreateAccountDocumentMutationResponse> {
  return { document: createDocument() };
}
