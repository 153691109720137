import type { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { MiniTag } from "@themis/ui";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ContractsActionsCell } from "../components/ContractsTable/ContractsActionsCell";
import { isContractActive } from "../utils";

export const useContractsTableColumns = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    (): ColDef[] => [
      {
        headerName: "Contract",
        minWidth: 300,
        cellRenderer: "attachmentCell",
        field: "file",
        valueFormatter: (params) => params.value || "No file found",
      },
      {
        headerName: "Account",
        minWidth: 200,
        cellRenderer: "textRenderCell",
        field: "account_name",
      },
      {
        headerName: "Status",
        minWidth: 150,
        cellRenderer: (props: CustomCellRendererProps) => {
          const isActive = isContractActive(props.data.end_date);
          const active = formatMessage({ defaultMessage: "Active" });
          const inactive = formatMessage({ defaultMessage: "Inactive" });

          return (
            <MiniTag
              size="lg"
              color={isActive ? "primary" : "red"}
              className="tw-ml-2"
            >
              {isActive ? active : inactive}
            </MiniTag>
          );
        },
      },
      {
        headerName: "Renewal Option",
        minWidth: 150,
        cellRenderer: (props: CustomCellRendererProps) => {
          const { renewal } = props.data;
          const automatic = formatMessage({ defaultMessage: "Automatic" });
          const manual = formatMessage({ defaultMessage: "Manual" });
          return (
            <MiniTag
              size="lg"
              color={renewal ? "secondary" : "primary"}
              className="tw-ml-2"
            >
              {renewal ? automatic : manual}
            </MiniTag>
          );
        },
      },
      {
        headerName: "Contract Start Date",
        minWidth: 200,
        cellRenderer: "datePickerCell",
        field: "start_date",
      },
      {
        headerName: "Contract Review Date",
        minWidth: 200,
        cellRenderer: "datePickerCell",
        field: "review_date",
      },
      {
        headerName: "Contract End Date",
        minWidth: 200,
        cellRenderer: "datePickerCell",
        field: "end_date",
      },
      {
        headerName: "Termination Date",
        minWidth: 200,
        cellRenderer: "textRenderCell",
        valueFormatter: (params: ValueFormatterParams) => {
          const contract = params.data;
          const onTermination = formatMessage({
            defaultMessage: "On termination date",
          });
          const daysBefore = formatMessage({
            defaultMessage: " days before termination date",
          });

          return contract.terms === "0"
            ? onTermination
            : `${contract.terms} ${daysBefore}`;
        },
      },
      {
        headerName: "Termination Details",
        field: "notes",
        minWidth: 200,
        cellRenderer: "textRenderCell",
      },
      {
        headerName: "",
        minWidth: 40,
        width: 40,
        sortable: false,
        resizable: false,
        pinned: "right" as const,
        cellStyle: () => ({ justifyContent: "center" }),
        cellRenderer: (props: CustomCellRendererProps) => (
          <ContractsActionsCell contract={props.data} />
        ),
      },
    ],
    [formatMessage],
  );
};
