import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";

import type { Field, RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";
import type { Section } from "@/stores/types/section-tags";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionPopupSettings from "../shared/SectionPopupSettings";
import SectionTag from "../shared/SectionTag";
import TableMenu from "../shared/tableMenu/TableMenu";

interface Props {
  sectionTag: Section;
  fields?: Field[];
  recordVersions?: RecordVersion[];
  onAddNewSection?: () => void;
}

function ChangeManagementSectionTag({
  sectionTag,
  fields,
  recordVersions,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isSectionTagOpened, setIsSectionTagOpened] = useState(true);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // Variables
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const { workspaceID, moduleWorkspaceID } = mainStore.context;

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });

  // Hooks
  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.changeManagements,
    sectionTagId: sectionTag?.id,
  });
  // Functions
  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const addApproval = () => {
    mainStore.changeManagements.create(workspaceID, sectionTag.id);
  };

  const deleteSectionTag = async () => {
    await mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag?.id);
    await mainStore.changeManagements.index({
      workspaceID,
    });
    await mainStore.toast.setText("Section has been deleted!");
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderFields = fields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      onDelete={deleteColumn}
      onRename={renameColumn}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
    />
  ));

  const renderRow = (recordVersion: RecordVersion, idx: number) => {
    const status = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion.data,
    );
    const isLocked = status === "published";

    if (!moduleWorkspaceID) {
      return null;
    }

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isLockedArchiveTable={isLocked}
        moduleWorkspaceID={moduleWorkspaceID}
        fields={fields}
        recordVersion={recordVersion}
        tableID={sectionTag.table_id}
        tableName={recordVersion.table_name}
        moduleIdentifier="new_product_approval"
        inSelectMenuActionMode={selectMode}
        isDraggable
        order={idx}
        isSorted={!!sortByFieldName}
      />
    );
  };

  return (
    <>
      {sectionTag && (
        <div className="tw-ml-[18px] tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={() => setIsSectionTagOpened((prev) => !prev)}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center  tw-text-neutral-500",
                {
                  "tw-rotate-180": isSectionTagOpened,
                  "tw-rotate-0 ": !isSectionTagOpened,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={editMode}
              setIsEditMode={setEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag?.editable &&
            isCurrentWorkspaceActive &&
            hasModuleWriteAccess && (
              <SectionPopupSettings
                onRename={() => setEditMode(true)}
                onDelete={deleteSectionTag}
              />
            )}
        </div>
      )}
      {isSectionTagOpened && (
        <div
          className="dropdown-table draggable"
          id={sectionTableID}
          data-testid="dropdown-section"
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="title-first-block table-column-reorder">
                  {fields?.length && fields.length > 0 && (
                    <TableMenu
                      setShowCheckbox={setShowCheckbox}
                      resetMenuActions={resetMenuActions}
                      selectMode={selectMode}
                      setSelectMode={setSelectMode}
                    />
                  )}
                </div>
                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              <AddColumnButton onAdd={createColumn} />
            </ul>
          </div>
          <ReorderableTable
            recordVersions={recordVersions}
            isSorted={!!sortByFieldName}
            renderer={renderRow}
            sectionTag={sectionTag}
            onAddNewRow={isCurrentWorkspaceActive ? addApproval : undefined}
            newRowName="Change"
          />
        </div>
      )}
    </>
  );
}

ChangeManagementSectionTag.defaultProps = {
  recordVersions: [],
};

export default observer(ChangeManagementSectionTag);
