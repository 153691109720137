import { faker } from "@faker-js/faker";

import type { Contract } from "../models/Contract";
import { createFile } from "./createFile";

export function createContract(
  data: NonNullable<Partial<Contract>> = {},
): NonNullable<Contract> {
  return {
    ...{
      account_id: faker.number.int(),
      account_name: faker.string.alpha(),
      end_date: faker.string.alpha(),
      file: Object.assign({}, createFile()),
      id: faker.number.int(),
      notes: faker.string.alpha(),
      renewal: faker.datatype.boolean(),
      review_date: faker.string.alpha(),
      start_date: faker.string.alpha(),
      terms: faker.string.alpha(),
    },
    ...data,
  };
}
