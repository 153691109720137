import React from "react";
import { useHistory } from "react-router-dom";

import DashboardContent from "../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import CreativeView from "../../../components/table/shared/creative-view/CreativeView";

const CreativeViewPage = ({ title }: { title: string }) => {
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader title={title} onBackClick={() => history.goBack()} />
      <DashboardContentWrapper>
        <CreativeView />
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default CreativeViewPage;
