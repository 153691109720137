import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link, useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion, TableName } from "@/api";
import { useMainStore } from "@/contexts/Store";

import SlideMenu from "../../slideMenu/SlideMenu";
import EmailAttest from "../policy/EmailAttest";
import ApproveButton from "../shared/ApproveButton";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import MoveToSectionPopup from "../shared/MoveToSectionPopup";
import PublishFlow from "../shared/PublishFlow";
import Unlock from "../shared/Unlock";

interface Props {
  moduleWorkspaceID: number;
  recordVersion: RecordVersion;
  tableName: TableName;
  approvedByUserIds: number[];
  showOnlyIcon?: boolean;
}

function ControlMappingActions({
  approvedByUserIds,
  recordVersion,
  moduleWorkspaceID,
  tableName,
  showOnlyIcon = true,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isArchiveConfirmationOpen, setIsArchiveConfirmationOpen] =
    useState(false);
  const [moveSection, setMoveSection] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const isControlMappingDetailView = useMemo(() => {
    const controlMappingDetailPageRe = /^\/modules\/control-mapping\/\d+$/;
    const matches = location.pathname.match(controlMappingDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  // Variables
  const { activeWorkspace } = mainStore.context;
  const {
    canDeleteRecords,
    canPublishOrLockRecords,
    canUnpublishOrUnlockRecords,
    hasModuleWriteAccess,
  } = mainStore.userPermissions;
  const approversIds =
    mainStore.avroSchemas.valueForField("approvers", recordVersion?.data) || [];
  const status = mainStore.avroSchemas
    .valueForField("status", recordVersion?.data)
    ?.toString();

  const haveLinkedControls = !recordVersion?.linked_controls;
  const isCompleted = status === "completed";

  const { workspaceID } = mainStore.context;
  const currentUserID = mainStore.users.user.id;
  const canApprove = currentUserID && approversIds.includes(currentUserID);
  const hasApproved =
    currentUserID && approvedByUserIds.includes(currentUserID);

  const canCompleteWithApprovers =
    status === "ready_to_finalize" && approversIds.length !== 0;
  const canCompleteWithoutApprovers =
    status === "new" && approversIds.length === 0;
  const canComplete = canCompleteWithApprovers || canCompleteWithoutApprovers;

  const { taskDetail } = mainStore;

  // Functions
  async function deleteRow() {
    try {
      await mainStore.controlMappings.delete(recordVersion.id);
      const previousVersion = mainStore.recordVersions.list.find(
        (rv) => rv.record_id === rv.record.id && rv.version === rv.version - 1,
      );

      if (previousVersion) {
        mainStore.toast.setInfoText(
          "Draft has been deleted. Reverting to previously finalized version.",
        );
      } else {
        mainStore.toast.setText("Control has been deleted!");
      }

      if (isControlMappingDetailView) {
        history.push(`/workspaces/${workspaceID}/modules/control-mapping`);
      }
    } catch {
      mainStore.toast.setErrorText(
        "An unexpected error occured while attempting to delete this Control.",
      );
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
    setIsArchiveConfirmationOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  function onArchive() {
    setIsDropdownOpen(false);
    setIsArchiveConfirmationOpen(true);
  }

  const onMove = () => {
    setIsDropdownOpen(false);
    setMoveSection(true);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'rvID' implicitly has an 'any' type.
  function handleMove(rvID, sectionTagID) {
    return mainStore.controlMappings.updateSection(rvID, sectionTagID);
  }

  function onApprove() {
    if (recordVersion.id) {
      mainStore.reviews.createApproval(recordVersion.id);
    }
  }

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  async function handleArchive() {
    await mainStore.controlMappings.archive(recordVersion.id);
    const tab = location.pathname.includes("/completed")
      ? "Completed"
      : "Active";

    mainStore.controlMappings.index({
      workspaceID: activeWorkspace?.id,
      tab,
    });
  }

  async function unlockRecordVersion() {
    mainStore.toast.setInfoText(
      "Unlocking record... this may take a few seconds",
    );
    const success = await mainStore.controlMappings.unlock(recordVersion.id);

    if (success) {
      mainStore.toast.setText("Record unlocked");
    }
  }

  function closeSlideMenu() {
    setShowSlideMenu(false);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="control-mapping-actions"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="cm-context-menu-dropdown"
    >
      <ul>
        {!isControlMappingDetailView && (
          <Link
            to={`/workspaces/${workspaceID}/modules/control-mapping/${recordVersion.id}`}
            data-testid="view-detail"
          >
            Details
          </Link>
        )}
        {hasModuleWriteAccess && (
          <li onClick={onMove} data-testid="move-button-trigger">
            Move to
          </li>
        )}

        <Link
          data-testid="historical-versions"
          to={`/workspaces/${workspaceID}/modules/control-mapping/${recordVersion.id}/historical-versions`}
        >
          View Previous Versions
        </Link>
        <li onClick={handleCreateTask}>Create Task</li>

        {haveLinkedControls && isCompleted && hasModuleWriteAccess && (
          <li onClick={onArchive} data-testid="archive-button-trigger">
            Archive
          </li>
        )}

        {canDeleteRecords && !isCompleted && haveLinkedControls && (
          <>
            <hr />
            <li
              onClick={deleteRowConfirmation}
              data-testid="cm-context-menu-dropdown-delete"
            >
              Delete
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const renderActionButton = () => {
    if (isCompleted && canUnpublishOrUnlockRecords) {
      return <Unlock onYes={unlockRecordVersion} tableName={tableName} />;
    }

    if (status === "in_review" && canApprove && !hasApproved) {
      return <ApproveButton onClick={onApprove} />;
    }

    if (canComplete && canPublishOrLockRecords) {
      return (
        <PublishFlow
          recordVersionID={recordVersion.id}
          tableName={tableName}
          moduleStore={mainStore.controlMappings}
          moduleIdentifier="control_mapping"
        />
      );
    }
  };

  const renderViewButton = (
    <Link
      to={`/workspaces/${workspaceID}/modules/control-mapping/${recordVersion.id}`}
      data-testid="view-detail"
      className="table-shared-view-button"
    >
      View
    </Link>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Control"
      content="Are you sure you want to delete this control? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={handlePopUpClose}
    />
  );

  const renderArchiveConfirmation = (
    <div
      className="table-dropdown success-dropdown archive"
      style={{ width: 230 }}
    >
      <div>
        <h4>Archive Control</h4>
        <p>
          Please read carefully and make sure this Control meets the following
          criteria before you confirm archive:
        </p>
        <ul className="ul-square">
          <li>Control is no longer enforced</li>
          <li>No further updates can be made</li>
          <li>Open versions will be deleted with archive</li>
        </ul>
        <div className="confirmation">
          <button onClick={() => handleArchive()}>Yes</button>
          <button onClick={() => setIsArchiveConfirmationOpen(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={classNames("control-mapping-actions", "list-points", {
        "without-background": !showOnlyIcon,
      })}
      data-testid="control-mapping-actions-container"
    >
      {renderActionButton() || renderViewButton}
      <Popup
        position="bottom right"
        open={isOpenModal}
        trigger={renderTrigger}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
      >
        {isDropdownOpen && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
        {isArchiveConfirmationOpen && renderArchiveConfirmation}
        {moveSection && (
          <MoveToSectionPopup
            moduleName="Control Mapping"
            recordVersionID={recordVersion.id}
            onMove={handleMove}
            onClose={() => setMoveSection(false)}
          />
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        <EmailAttest
          tableName="Default"
          moduleWorkspaceID={moduleWorkspaceID}
          recordVersionID={recordVersion.id}
          moduleIdentifier="control_mapping"
        />
      </SlideMenu>
    </div>
  );
}

export default observer(ControlMappingActions);
