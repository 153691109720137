import type { MultiStepSelectItem, MultiStepSelectStepProps } from "@themis/ui";
import { MultiStepSelect } from "@themis/ui";
import React, { useEffect } from "react";
import { PiPlusBold } from "react-icons/pi";

import type { Relatable } from "@/api";
import { useRelatables } from "@/api/queries/shared";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { useMainStore } from "@/contexts/Store";
import type { ModuleWorkspace } from "@/stores/types/workspace-types";

import { getModuleWorkspaceIcon } from "../helpers";

function RecordSelect({
  selectedWorkspaceId,
  ignoredRecords = [],
  isDisabled = false,
  onRecordSelect,
}: {
  selectedWorkspaceId?: number;
  ignoredRecords?: Relatable[];
  isDisabled?: boolean;
  onRecordSelect: (record: Relatable) => void;
}) {
  const mainStore = useMainStore();

  const companyID = mainStore.companies.company?.id;
  const workspaces = mainStore.workspaces.list.map((workspace) => ({
    label: workspace.name,
    value: workspace.id,
    other: getWorkspaceLogo(workspace).logo,
  }));

  const [modules, setModules] = React.useState<MultiStepSelectItem[]>([]);

  const [selectedModule, setSelectedModule] = React.useState<number>();

  const [searchValue, setSearchValue] = React.useState("");

  const { data, fetchNextPage } = useRelatables(
    companyID,
    selectedModule,
    searchValue || undefined,
  );

  const records =
    data?.pages
      .flatMap((page) => page?.data || [])
      .filter(
        (relatable) =>
          !ignoredRecords.some(
            (record) =>
              record.id === relatable.id &&
              record.relatable_type === relatable.relatable_type,
          ),
      ) || [];

  const recordSelectItems = records.map((record) => ({
    label: record.name || "No Name",
    value: record.identifier,
  }));

  useEffect(() => {
    if (selectedWorkspaceId) {
      fetchModules(selectedWorkspaceId);
    }
  }, [selectedWorkspaceId]);

  const fetchModules = async (workspaceId: number) => {
    const response =
      await mainStore.moduleWorkspaces.fetchModuleWorkspaces(workspaceId);
    const workspaceModules: MultiStepSelectItem[] =
      response.data.module_workspaces
        .filter((module: ModuleWorkspace) => module.added)
        .map((module: ModuleWorkspace) => ({
          label: module.name,
          value: module.id,
          other: getModuleWorkspaceIcon(module.themis_module.identifier),
        }));

    setModules(workspaceModules);
  };

  async function fetchRecords(
    nextPage: boolean,
    moduleId: number,
    name?: string,
  ) {
    if (nextPage) {
      fetchNextPage();
    } else {
      setSearchValue(name || "");
      setSelectedModule(moduleId);
    }
  }

  async function onSelect(step: number, value: string) {
    if (step === 0) {
      fetchModules(Number(value));
    } else if (step === 1) {
      fetchRecords(false, Number(value));
    } else {
      setSelectedModule(undefined);
      const selectedRecord = records.find(
        (record) => record.identifier === value,
      );
      if (selectedRecord) {
        onRecordSelect(selectedRecord);
      }
    }
  }

  function onGoBack(step: number) {
    if (step === 1) {
      setModules([]);
    } else if (step === 2) {
      setSelectedModule(undefined);
      setSearchValue("");
    }
  }

  const steps: MultiStepSelectStepProps[] = [
    {
      searchable: true,
      subtitle: "Select the workspace where the record is located",
      // eslint-disable-next-line react/no-unstable-nested-components
      Component: (label: string, other?: string) => (
        <div className="tw-flex tw-items-center">
          <img
            className="tw-mr-2 tw-w-[20px] tw-rounded"
            src={other}
            alt="image-icon"
          />
          <span className="tw-max-w-[228px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
            {label}
          </span>
        </div>
      ),
      items: workspaces,
    },
    {
      title: "Select Module",
      searchable: true,
      subtitle: "Select the module where the record is located",
      // eslint-disable-next-line react/no-unstable-nested-components
      Component: (label: string, value: string, other) => (
        <div className="tw-flex tw-items-center">
          <div className="tw-mr-2 tw-h-[20px] tw-w-[20px] tw-rounded">
            <img
              className="tw-h-[20px] tw-w-[20px]"
              src={other}
              alt={`${label}-icon`}
            />
          </div>
          <span className="tw-max-w-56 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
            {label}
          </span>
        </div>
      ),
      items: modules,
    },
    {
      title: "Select Record",
      subtitle: "Select an associated record",
      searchable: true,
      onScroll: (search: string) =>
        fetchRecords(true, Number(selectedModule), search),
      onSearchChange: (search?: string) => {
        fetchRecords(false, Number(selectedModule), search);
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      Component: (label: string) => (
        <div className="tw-flex tw-items-center">
          <span className="tw-max-w-56 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
            {label}
          </span>
        </div>
      ),
      items: recordSelectItems,
    },
  ];

  return (
    <MultiStepSelect
      readOnly={isDisabled}
      initialActiveStep={selectedWorkspaceId ? 1 : 0}
      steps={steps}
      onSelect={onSelect}
      onGoBack={onGoBack}
      buttonContent={<PiPlusBold className="tw-h-4 tw-w-4" />}
      buttonProps={{ color: "primary" }}
    />
  );
}

export default RecordSelect;
