import { LinkButton, LinkIconButton, SimpleTooltip, Table } from "@themis/ui";
import { PiPlusBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useLocation, useRouteMatch } from "react-router";

import type { Account } from "@/api";
import { Typography } from "@/components/Elements";

import {
  InfoPreviewActions,
  InfoPreviewFooter,
  InfoPreviewHeader,
  InfoPreviewSection,
  InfoPreviewTitle,
  NUM_PREVIEW_ITEMS,
} from "../../../../misc/info-preview-section/info-preview-section";
import type { AccountLocationState } from "../AccountDetails";
import { ACCOUNT_DETAILS_ROUTES } from "../AccountDetails";
import { useChildAccountsPreviewTableColumns } from "../use-child-accounts-preview-table-columns/use-child-accounts-preview-table-columns";

export function SubAccountsPreview({ account }: { account?: Account }) {
  const { url } = useRouteMatch();
  const { pathname } = useLocation<AccountLocationState>();
  const { formatMessage } = useIntl();

  const childAccountsColumns = useChildAccountsPreviewTableColumns();
  const previewChildAccounts = account?.child_accounts?.slice(
    0,
    NUM_PREVIEW_ITEMS,
  );

  return (
    <InfoPreviewSection>
      <InfoPreviewHeader>
        <InfoPreviewTitle>
          {formatMessage({
            defaultMessage: "Sub accounts",
            description: "Sub accounts section title",
          })}
        </InfoPreviewTitle>
        <SimpleTooltip
          disabled={!account}
          tooltipTriggerProps={{ asChild: true }}
          title={formatMessage({
            defaultMessage: "Available after record saved",
          })}
        >
          <InfoPreviewActions>
            <LinkIconButton
              Icon={PiPlusBold}
              title={formatMessage({
                defaultMessage: "Add",
                description: "Add button title",
              })}
              to={{
                pathname: `${url}${ACCOUNT_DETAILS_ROUTES.childAccounts}/new`,
                state: { from: pathname },
              }}
              size="md"
            />
          </InfoPreviewActions>
        </SimpleTooltip>
      </InfoPreviewHeader>
      {account && previewChildAccounts && previewChildAccounts.length > 0 && (
        <>
          <Table
            columns={childAccountsColumns}
            rows={previewChildAccounts}
            enablePinning={false}
          />
          <InfoPreviewFooter>
            <Typography type="h4">
              {formatMessage(
                {
                  defaultMessage:
                    "Showing {numberOfShown} of {total} sub accounts",
                },
                {
                  numberOfShown: previewChildAccounts?.length ?? 0,
                  total: account?.child_accounts?.length ?? 0,
                },
              )}
            </Typography>
            {(account?.child_accounts?.length ?? 0) >= NUM_PREVIEW_ITEMS && (
              <LinkButton
                color="tertiary"
                size="sm"
                to={`${url}${ACCOUNT_DETAILS_ROUTES.childAccounts}`}
              >
                {formatMessage({ defaultMessage: "View all" })}
              </LinkButton>
            )}
          </InfoPreviewFooter>
        </>
      )}
    </InfoPreviewSection>
  );
}
