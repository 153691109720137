import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateCommentMutationRequest,
  UpdateCommentMutationResponse,
  UpdateCommentPathParams,
} from "../../models/UpdateComment";

/**
 * @description Update a comment
 * @summary Update a comment
 * @link /:record_type/:record_id/comments/:id
 */
export async function updateComment(
  recordType: UpdateCommentPathParams["record_type"],
  recordId: UpdateCommentPathParams["record_id"],
  id: UpdateCommentPathParams["id"],
  data: UpdateCommentMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateCommentMutationResponse>["data"]> {
  const res = await client<
    UpdateCommentMutationResponse,
    UpdateCommentMutationRequest
  >({
    method: "put",
    url: `/${recordType}/${recordId}/comments/${id}`,
    data,
    ...options,
  });
  return res.data;
}
