import { http } from "msw";

import { createListThemisRecordLayoutsQueryResponse } from "../createListThemisRecordLayouts";

export const listThemisRecordLayoutsHandler = http.get(
  "*/companies/:company_id/:record_type/layouts",
  function handler(info) {
    return new Response(
      JSON.stringify(createListThemisRecordLayoutsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
