import { useParams } from "react-router-dom";

export function useRouteWorkspaceId(): number {
  const params = useParams<{ workspace_id: string }>();

  const workspaceId = Number.parseInt(params.workspace_id);

  if (isNaN(workspaceId)) {
    throw new Error("Workspace id not found in route");
  }

  return workspaceId;
}
