import { LinkButton, LinkIconButton, SimpleTooltip, Table } from "@themis/ui";
import { PiPlusBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useLocation, useRouteMatch } from "react-router";

import type { Account } from "@/api";
import { Typography } from "@/components/Elements";
import { useContractsPreviewTableColumns } from "@/features/accounts/config/use-contracts-preview-table-columns";

import {
  InfoPreviewActions,
  InfoPreviewFooter,
  InfoPreviewHeader,
  InfoPreviewSection,
  InfoPreviewTitle,
  NUM_PREVIEW_ITEMS,
} from "../../../../misc/info-preview-section/info-preview-section";
import type { AccountLocationState } from "../AccountDetails";
import { ACCOUNT_DETAILS_ROUTES } from "../AccountDetails";

export function ContractsPreview({ account }: { account?: Account }) {
  const { url } = useRouteMatch();
  const { pathname } = useLocation<AccountLocationState>();
  const { formatMessage } = useIntl();

  const contractsColumns = useContractsPreviewTableColumns();
  const previewContracts = account?.contracts?.slice(0, NUM_PREVIEW_ITEMS);

  return (
    <InfoPreviewSection>
      <InfoPreviewHeader>
        <InfoPreviewTitle>
          {formatMessage({
            defaultMessage: "Contracts",
            description: "Contracts section title",
          })}
        </InfoPreviewTitle>
        <SimpleTooltip
          disabled={!account}
          tooltipTriggerProps={{ asChild: true }}
          title={formatMessage({
            defaultMessage: "Available after record saved",
          })}
        >
          <InfoPreviewActions>
            <LinkIconButton
              Icon={PiPlusBold}
              title={formatMessage({
                defaultMessage: "Add",
                description: "Add button title",
              })}
              to={{
                pathname: `${url}${ACCOUNT_DETAILS_ROUTES.contracts}/new`,
                state: { from: pathname },
              }}
              size="md"
            />
          </InfoPreviewActions>
        </SimpleTooltip>
      </InfoPreviewHeader>
      {account && previewContracts && previewContracts.length > 0 && (
        <>
          <Table
            columns={contractsColumns}
            rows={previewContracts}
            enablePinning={false}
          />
          <InfoPreviewFooter>
            <Typography type="h4">
              {formatMessage(
                {
                  defaultMessage:
                    "Showing {numberOfShown} of {total} contracts",
                },
                {
                  numberOfShown: previewContracts?.length ?? 0,
                  total: account?.contracts?.length ?? 0,
                },
              )}
            </Typography>
            {(account?.contracts?.length ?? 0) >= NUM_PREVIEW_ITEMS && (
              <LinkButton
                color="tertiary"
                size="sm"
                to={`${url}${ACCOUNT_DETAILS_ROUTES.contracts}`}
              >
                {formatMessage({ defaultMessage: "View all" })}
              </LinkButton>
            )}
          </InfoPreviewFooter>
        </>
      )}
    </InfoPreviewSection>
  );
}
