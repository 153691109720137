import { http } from "msw";

import { createDirectUploadMutationResponse } from "../createDirectUpload";

export const directUploadHandler = http.post(
  "*/rails/active_storage/direct_upload",
  function handler(info) {
    return new Response(JSON.stringify(createDirectUploadMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
