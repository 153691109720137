import { http } from "msw";

import { createCreateTaskLinkMutationResponse } from "../createCreateTaskLink";

export const createTaskLinkHandler = http.post(
  "*/tasks/:task_id/task_links",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateTaskLinkMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
