import { faker } from "@faker-js/faker";

import type { RecordType } from "../models/RecordType";

export function createRecordType(): NonNullable<RecordType> {
  return faker.helpers.arrayElement<any>([
    "projects",
    "findings",
    "tasks",
    "module_assessments",
    "accounts",
    "trainings",
    "records",
  ]);
}
