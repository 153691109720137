import { faker } from "@faker-js/faker";

import type { Review } from "../models/Review";
import { createReviewable } from "./createReviewable";

export function createReview(
  data: NonNullable<Partial<Review>> = {},
): NonNullable<Review> {
  return {
    ...{
      id: faker.number.int(),
      approved_at: faker.date.anytime().toISOString(),
      review_type: faker.helpers.arrayElement<any>([
        "approval",
        "denial",
        "pending",
      ]),
      user_id: faker.number.int(),
      reviewable: createReviewable(),
      module_workspace: {
        id: faker.number.int(),
        name: faker.string.alpha(),
        workspace_id: faker.number.int(),
        themis_module: {
          identifier: faker.string.alpha(),
          name: faker.string.alpha(),
        },
      },
    },
    ...data,
  };
}
