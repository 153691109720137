import { faker } from "@faker-js/faker";

import type { ControlCategory } from "../models/ControlCategory";

export function createControlCategory(
  data: NonNullable<Partial<ControlCategory>> = {},
): NonNullable<ControlCategory> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      used_count: faker.number.int(),
      title: faker.string.alpha(),
    },
    ...data,
  };
}
