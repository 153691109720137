import { ColorSelect, FormField, IconButton, Stack } from "@themis/ui";
import type { KeyboardEvent, ReactElement } from "react";
import type { Control } from "react-hook-form";
import { PiTrashBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import { TextInputFormField } from "../../../../form-fields/text-input-form-field/text-input-form-field";
import type { ValidationSchema } from "../../use-validation-schema/use-validation-schema";

interface DropdownColumnOptionProps {
  autoFocus: boolean;
  control: Control<ValidationSchema>;
  enableDelete: boolean;
  name: `columnData.options.${number}`;
  onDeleteClick: () => void;
  onKeyDown: (event: KeyboardEvent) => void;
}

/**
 * @todo finish color select implementation
 */
export function DropdownColumnOption({
  autoFocus,
  control,
  enableDelete,
  name,
  onDeleteClick,
  onKeyDown,
}: DropdownColumnOptionProps): ReactElement {
  const { formatMessage } = useIntl();

  const showColorSelect = false;

  return (
    <Stack className="tw-w-full tw-pl-1" direction="row" spacing="1">
      <FormField
        control={control}
        name={`${name}.name`}
        render={({ field }) => (
          <TextInputFormField
            autoFocus={autoFocus}
            classes={{
              root: "tw-w-full tw-mr-3",
            }}
            control={control}
            name={field.name}
            placeholder={formatMessage({
              defaultMessage: "Option Name",
            })}
            size="sm"
            onKeyDown={onKeyDown}
          />
        )}
      />

      {/* @todo finish color select implementation */}
      {showColorSelect && (
        <FormField
          control={control}
          name={`${name}.color`}
          render={({ field }) => (
            <ColorSelect
              className="tw-w-auto"
              size="sm"
              value={field.value ?? ""}
              onChange={(value: string) => field.onChange(value)}
            />
          )}
        />
      )}

      <IconButton
        color="transparent"
        disabled={!enableDelete}
        Icon={PiTrashBold}
        onClick={onDeleteClick}
      />
    </Stack>
  );
}
