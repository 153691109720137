import { faker } from "@faker-js/faker";

import type {
  UpdateLayout200,
  UpdateLayoutMutationRequest,
  UpdateLayoutMutationResponse,
  UpdateLayoutPathParams,
} from "../models/UpdateLayout";
import { createLayout } from "./createLayout";
import { createRecordType } from "./createRecordType";

export function createUpdateLayoutPathParams(): NonNullable<UpdateLayoutPathParams> {
  return {
    company_id: faker.number.int(),
    record_type: createRecordType(),
    id: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createUpdateLayout200(): NonNullable<UpdateLayout200> {
  return { data: createLayout() };
}

export function createUpdateLayoutMutationRequest(): NonNullable<UpdateLayoutMutationRequest> {
  return {
    layout: {
      name: faker.string.alpha(),
      ordered_fields: faker.helpers.arrayElements([
        { id: faker.number.int(), visible: faker.datatype.boolean() },
      ]) as any,
    },
  };
}

/**
 * @description OK
 */
export function createUpdateLayoutMutationResponse(): NonNullable<UpdateLayoutMutationResponse> {
  return { data: createLayout() };
}
