import { Button, useToast } from "@themis/ui";
import { useState } from "react";
import { useIntl } from "react-intl";

import type { Comment, UpdateCommentPathParams } from "@/api";
import { useUpdateComment } from "@/api/queries/comments/useUpdateComment";
import { useUsers } from "@/api/queries/users/use-users";
import { Flex } from "@/components/Elements";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { DisabledUserMention } from "../user-mention/disabled-user-mention";
import { UserMention } from "../user-mention/user-mention";

interface ReplyContentProps {
  editing: boolean;
  recordId: UpdateCommentPathParams["record_id"];
  recordType: UpdateCommentPathParams["record_type"];
  reply: Comment;
  onStopEditing: () => void;
}

export function ReplyContent({
  editing,
  recordId,
  recordType,
  reply,
  onStopEditing,
}: ReplyContentProps) {
  const workspaceId = useRouteWorkspaceId();
  const { data: users = [] } = useUsers(workspaceId);

  const { formatMessage } = useIntl();
  const toast = useToast();

  const [inputValue, setInputValue] = useState(reply.content);
  const { mutate: updateComment, isPending } = useUpdateComment(
    { recordType, recordId },
    {
      onSuccess: onStopEditing,
      onError: () => {
        toast({
          content: "Failed to add comment",
          variant: "error",
        });
      },
    },
  );

  function handleSaveCommentEdit() {
    if (inputValue && inputValue !== reply?.content) {
      updateComment({
        commentId: reply.id,
        data: { comment: { content: inputValue } },
      });
    }
  }
  function handleCancelCommentEdit() {
    if (inputValue !== reply?.content) {
      setInputValue(reply.content);
    }
    onStopEditing();
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <div className="tw-flex tw-flex-col tw-gap-2">
        {editing ? (
          <UserMention
            commentInput={inputValue}
            disableSubmit={false}
            onChangeCommentInput={setInputValue}
            onSubmit={handleSaveCommentEdit}
            users={users}
            placeholder={formatMessage({
              defaultMessage: "Add a comment. Use @ to mention a user.",
            })}
          />
        ) : (
          <DisabledUserMention value={inputValue} />
        )}
      </div>
      {editing && (
        <Flex alignCenter columnGap={8}>
          <Button
            loading={isPending}
            disabled={!inputValue || inputValue === reply.content}
            size="sm"
            onClick={handleSaveCommentEdit}
          >
            {formatMessage({ defaultMessage: "Update" })}
          </Button>
          <Button color="tertiary" size="sm" onClick={handleCancelCommentEdit}>
            {formatMessage({ defaultMessage: "Cancel" })}
          </Button>
        </Flex>
      )}
    </div>
  );
}
