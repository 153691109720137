import { faker } from "@faker-js/faker";

import type {
  UpdateModuleAssessment200,
  UpdateModuleAssessmentMutationRequest,
  UpdateModuleAssessmentMutationResponse,
  UpdateModuleAssessmentPathParams,
} from "../models/UpdateModuleAssessment";
import { createModuleAssessment } from "./createModuleAssessment";
import { createModuleAssessmentRequest } from "./createModuleAssessmentRequest";

export function createUpdateModuleAssessmentPathParams(): NonNullable<UpdateModuleAssessmentPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateModuleAssessment200(): NonNullable<UpdateModuleAssessment200> {
  return { data: createModuleAssessment() };
}

export function createUpdateModuleAssessmentMutationRequest(): NonNullable<UpdateModuleAssessmentMutationRequest> {
  return createModuleAssessmentRequest();
}

/**
 * @description OK
 */
export function createUpdateModuleAssessmentMutationResponse(): NonNullable<UpdateModuleAssessmentMutationResponse> {
  return { data: createModuleAssessment() };
}
