import {
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import type { File } from "@/api";
import { useDeleteDocument } from "@/api/queries/documents";
import { useFetchFile } from "@/api/queries/use-fetch-file";
import { useOpen } from "@/hooks/use-open/use-open";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";

import type { ACCOUNT_ID_PARAM } from "../../../accounts-routes";

const DROPDOWN_ITEM_STYLE =
  "tw-flex tw-w-[228px] tw-items-center tw-justify-start tw-gap-1 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primary-50 tw-px-4 tw-py-[10px] tw-justify-between";

interface ActionsCellProps {
  documentFile: File;
  documentId: number;
}

export function ActionsCell({ documentFile, documentId }: ActionsCellProps) {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const { accountId, workspace_id } = useParams<{
    [ACCOUNT_ID_PARAM]: string;
    workspace_id: string;
  }>();

  const { mutateAsync: fetchFile } = useFetchFile();
  const { mutate: deleteDocument } = useDeleteDocument({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
    onSuccess: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Document has been removed!",
        }),
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Failed to remove document!",
        }),
        variant: "error",
      });
    },
  });

  async function handleClickDownload() {
    if (!documentFile) {
      return;
    }

    const blob = await fetchFile(documentFile.file_url);
    downloadFile(blob, documentFile.file_name);
  }

  const confirmation = useOpen();

  function handleDelete() {
    deleteDocument(documentId);
    confirmation.close();
  }

  function handleClickDelete() {
    confirmation.open();
  }

  function handleCancelDelete() {
    confirmation.close();
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            aria-label={formatMessage({
              defaultMessage: "Account attachment actions",
            })}
            aria-describedby={`document-id-${documentId}`}
            Icon={PiDotsThreeOutlineVerticalFill}
            color="transparent"
            className="tw-h-8 tw-w-8 tw-rounded-md"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="tw-py-0">
          <DropdownMenuItem
            aria-label={formatMessage({
              defaultMessage: "Download attachment",
            })}
            onClick={handleClickDownload}
            className={DROPDOWN_ITEM_STYLE}
          >
            {formatMessage({
              defaultMessage: "Download",
            })}
          </DropdownMenuItem>
          <DropdownMenuItem
            aria-label={formatMessage({ defaultMessage: "Delete attachment" })}
            onClick={handleClickDelete}
            className={`${DROPDOWN_ITEM_STYLE} tw-border-b-0`}
          >
            <span className="tw-text-sm tw-font-medium">
              {formatMessage({ defaultMessage: "Delete" })}
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Attachment"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={confirmation.isOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      />
    </>
  );
}
