import { http } from "msw";

import { createListModuleAssessmentsQueryResponse } from "../createListModuleAssessments";

export const listModuleAssessmentsHandler = http.get(
  "*/record_versions/:record_version_id/module_assessments",
  function handler(info) {
    return new Response(
      JSON.stringify(createListModuleAssessmentsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
