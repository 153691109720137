import type { PropsWithChildren } from "react";
import React, { useState } from "react";

import { DisplayFollowUpQuestionsPage } from "../../pages/DisplayFollowUpQuestionsPage";
import { CreateDisplayFollowUpSidebarContext } from "./display-follow-up-questions-sidebar-context";

export function DisplayFollowUpQuestionsSidebarProvider(
  props: PropsWithChildren,
) {
  const [questionId, setQuestionId] = useState<number | undefined>();
  const [questionTextEntry, setQuestionTextEntry] = useState<string>("");
  const [questionOrderEntry, setQuestionOrderEntry] = useState<string>("");

  const value = {
    openDisplaySidebar: (
      questionID: number,
      questionText?: string,
      questionOrder?: string,
    ) => {
      setQuestionId(questionID);
      setQuestionTextEntry(questionText || "");
      setQuestionOrderEntry(questionOrder || "");
    },
    closeDisplaySidebar: () => {
      setQuestionId(undefined);
      setQuestionTextEntry("");
      setQuestionOrderEntry("");
    },
  };

  return (
    <CreateDisplayFollowUpSidebarContext.Provider value={value}>
      {props.children}
      {questionId && (
        <DisplayFollowUpQuestionsPage
          questionID={questionId}
          questionText={questionTextEntry}
          questionOrder={questionOrderEntry}
        />
      )}
    </CreateDisplayFollowUpSidebarContext.Provider>
  );
}
