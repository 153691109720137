import { faker } from "@faker-js/faker";

import type {
  ControlMappingsLibrary,
  ControlMappingsLibrary200,
  ControlMappingsLibrary400,
  ControlMappingsLibrary403,
  ControlMappingsLibrary422,
  ControlMappingsLibraryQueryParams,
  ControlMappingsLibraryQueryResponse,
} from "../models/ControlMappingsLibrary";

export function createControlMappingsLibrary(
  data: NonNullable<Partial<ControlMappingsLibrary>> = {},
): NonNullable<ControlMappingsLibrary> {
  return {
    ...{ library_items: faker.helpers.arrayElements([undefined]) as any },
    ...data,
  };
}

export function createControlMappingsLibraryQueryParams(): NonNullable<ControlMappingsLibraryQueryParams> {
  return { library_type: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createControlMappingsLibrary200(): NonNullable<ControlMappingsLibrary200> {
  return { data: createControlMappingsLibrary() };
}

/**
 * @description Bad Request
 */
export function createControlMappingsLibrary400(): NonNullable<ControlMappingsLibrary400> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Forbidden
 */
export function createControlMappingsLibrary403(): NonNullable<ControlMappingsLibrary403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Bad request error.
 */
export function createControlMappingsLibrary422(): NonNullable<ControlMappingsLibrary422> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createControlMappingsLibraryQueryResponse(): NonNullable<ControlMappingsLibraryQueryResponse> {
  return { data: createControlMappingsLibrary() };
}
