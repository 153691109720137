import { http } from "msw";

import { createCreateProjectMutationResponse } from "../createCreateProject";

export const createProjectHandler = http.post(
  "*/companies/:company_id/projects",
  function handler(info) {
    return new Response(JSON.stringify(createCreateProjectMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
