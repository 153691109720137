import { useToast } from "@themis/ui";
import type { ListControlsModules } from "app/javascript/api/gen/models/ListControlsModules";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";

import type { LinkedRecord } from "@/api";
import { useSaveControlsModules } from "@/api/queries/controlsModules";
import type { ScoringMatrixRating } from "@/stores/types/risk-register-types";

import ModuleControlsSection from "./ModuleControlsSection";

const ratingSchema = z.object({
  field_name: z.string(),
  value: z.number().nullable(),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const formSchema = z.object({
  rating_value: z.string(),
  description_value: z.string(),
  records_ids: z.array(z.string()),
  module_ratings: z.array(ratingSchema).nullable(),
});

type ModuleControlsSchema = z.infer<typeof formSchema>;

interface Props {
  recordVersionID?: number;
  isLocked?: boolean;
  controlsModules: ListControlsModules;
  controlRatings: {
    title: string;
    column_scoring_matrix_ratings: { color: string };
  }[];
  rangeRatings: ScoringMatrixRating[];
  defaultValues: { [key: string]: string };
}

function ModuleControlsForm({
  recordVersionID,
  isLocked,
  controlsModules,
  controlRatings,
  rangeRatings,
}: Props) {
  const { workspace_id: workspaceID } = useParams<{ workspace_id: string }>();

  const [residualRiskRatings, setResidualRiskRatings] =
    useState<ScoringMatrixRating[]>();

  const toast = useToast();

  useEffect(() => {
    setResidualRiskRatings(
      rangeRatings.filter((rating) => rating.risk_type === "residual"),
    );
  }, [rangeRatings]);

  const getModuleRating = (identifier: string) => {
    const moduleKey = `${identifier}_rating`;
    return controlsModules?.modules_ratings?.[moduleKey];
  };

  const allLinkedRecords: number[] =
    controlsModules?.control?.linked_records?.map(
      (item: { [key: string]: string | number }) => Number(item.id),
    );

  const getIssueRelatedRecords = (itemID: number) =>
    controlsModules?.control?.monitoring_testing_issues?.filter(
      (item: { [key: string]: string | number }) =>
        Number(item.record_id) === itemID,
    );

  const getFilteredRecords = (selectedIds: number[]) => {
    const result = controlsModules?.control?.linked_records?.filter(
      (record: LinkedRecord) => selectedIds.includes(record.id),
    );

    return result?.map((item: { [key: string]: string | number }) => ({
      ...item,
      workspace_id: workspaceID,
      record_version_id: recordVersionID,
      all_linked_ids: allLinkedRecords || [],
      is_locked: isLocked,
      rows_number_in_table: result.length,
      monitoring_testing_issues: getIssueRelatedRecords(Number(item.id)),
    }));
  };

  const { mutateAsync: updateResult } = useSaveControlsModules({
    recordVersionId: Number(recordVersionID),
  });

  const handleUnlinkRecord = async (
    id: number,
    rowsNumber: number,
    moduleIdentifier: string,
  ) => {
    try {
      if (rowsNumber <= 1) {
        await updateResult({
          controls_data: {
            records_ids: allLinkedRecords.filter((rid) => rid !== id),
            module_ratings: [
              { value: null, field_name: `${moduleIdentifier}_rating` },
            ],
          },
        });
      } else {
        await updateResult({
          controls_data: {
            records_ids: allLinkedRecords.filter((rid) => rid !== id),
          },
        });
      }

      toast({ content: "Control unlinked successfully!", variant: "success" });
    } catch (err) {
      toast({
        content: "Something went wrong. Could not unlink control.",
        variant: "error",
      });
    }
  };

  const handleModuleRatingChange = async (
    value: string,
    moduleIdentifier: string,
  ) => {
    try {
      await updateResult({
        controls_data: {
          module_ratings: [
            { value: Number(value), field_name: `${moduleIdentifier}_rating` },
          ],
        },
      });
      toast({
        content: "Rating updated successfully!",
        variant: "success",
      });
    } catch (e) {
      toast({
        content: "Something went wrong. Could not update rating.",
        variant: "error",
      });
    }
  };

  return controlsModules?.modules?.map(
    (
      module: {
        title: string;
        identifier: string;
        data_type: string;
        selected_ids: [];
      },
      index: number,
    ) => (
      <ModuleControlsSection
        key={index}
        index={index}
        isLocked={isLocked}
        module={module}
        moduleRating={getModuleRating(module.identifier)}
        controlRatings={controlRatings}
        linkedRecords={allLinkedRecords}
        riskRatings={residualRiskRatings}
        records={getFilteredRecords(module.selected_ids)}
        onUnlink={handleUnlinkRecord}
        onRatingChange={handleModuleRatingChange}
      />
    ),
  );
}

export { ModuleControlsForm, type ModuleControlsSchema };
