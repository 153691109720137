import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getLayoutsQueryKey } from "@/api/queries/layouts/use-layouts";

import type {
  CreateFieldMutationRequest,
  CreateFieldMutationResponse,
  RecordType,
} from "../../gen";
import { createField } from "../../gen/axios/fieldsController";
import { getRecordsQueryKey } from "../records/get-records-query-key";
import { getFieldsQueryKey } from "./use-fields";

export function useCreateField({
  companyId,
  recordType,
  options,
}: {
  companyId: number;
  recordType: RecordType;
  options?: Partial<
    UseMutationOptions<
      CreateFieldMutationResponse,
      unknown,
      CreateFieldMutationRequest
    >
  >;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateFieldMutationRequest) =>
      createField(companyId, recordType, data),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: getFieldsQueryKey(),
          refetchType: "all",
        }),
        queryClient.invalidateQueries({
          queryKey: getLayoutsQueryKey(),
          refetchType: "all",
        }),
      ]);
      await queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({ recordType }),
        refetchType: "all",
      });
    },
    ...options,
  });
}
