import { faker } from "@faker-js/faker";

import type { AttestationList } from "../models/AttestationList";
import { createAttestation } from "./createAttestation";
import { createRecordReference } from "./createRecordReference";

export function createAttestationList(
  data: NonNullable<Partial<AttestationList>> = {},
): NonNullable<AttestationList> {
  return {
    ...{
      id: faker.number.int(),
      name: faker.string.alpha(),
      description: faker.string.alpha(),
      message: faker.string.alpha(),
      due_date: faker.string.alpha(),
      status: faker.helpers.arrayElement<any>([
        "unsent",
        "in_progress",
        "complete",
      ]),
      attestables: faker.helpers.arrayElements([
        createRecordReference(),
      ]) as any,
      attestations: {
        data: faker.helpers.arrayElements([createAttestation()]) as any,
        meta: { next_page: faker.string.alpha(), count: faker.number.int() },
      },
      completion_percentage: faker.number.float(),
    },
    ...data,
  };
}
