import { faker } from "@faker-js/faker";

import type { Taskable } from "../models/Taskable";
import { createRelatable } from "./createRelatable";

export function createTaskable(
  data: NonNullable<Partial<Taskable>> = {},
): NonNullable<Taskable> {
  return {
    ...{
      id: faker.number.int(),
      target: createRelatable(),
      status: faker.helpers.arrayElement<any>([
        "active",
        "deleted",
        "no_access",
      ]),
    },
    ...data,
  };
}
