import { action, computed, makeObservable, observable } from "mobx";

import type { MainStore } from "../Main";

/*
  Use exported constants to identify keys, these are needed for dismissing based on
  announcement specific logic.
  Exp. If the user navigates manually to compliance templates page

  These need to be unique
*/
export const controlAndRiskAnnouncementKey =
  "isControlAndRiskAnnouncmentDismissed";
export const complianceTemplatesAnnouncementKey =
  "isComplianceAnnouncementDismissed";

// Add new announcements to beginning of array
const ANNOUNCEMENTS: Announcement[] = [
  {
    localStorageKey: controlAndRiskAnnouncementKey,
    title: "Announcement",
    textContent:
      "Control Mapping and Risk Register modules can now be enabled for your partners in collaborative workspaces. Create dedicated risk matrices for your partners and link their risks and controls to their policies, procedures, issues, and documents. Click the 'Settings > Modules' page of your preferred collaborative workspace and enable the Risk Register and Control Mapping modules. This will be available on a trial basis.",
    displayFor: ["directUser"],
    displayInPopup: false,
  },
  {
    localStorageKey: complianceTemplatesAnnouncementKey,
    title: "Compliance Templates are now available on Themis!",
    textContent:
      "Use Templates to fortify your compliance program. Pre-built templates with customization can get your team up and running quickly.",
    pathToContent: "/resources/compliance-templates",
    displayFor: ["indirectUser"],
    displayInPopup: true,
  },
];

type UserType = "directUser" | "indirectUser";

export interface Announcement {
  localStorageKey: string;
  title: string;
  textContent: string;
  pathToContent?: string;
  displayFor: UserType[];
  displayInPopup: boolean;
}

export default class AnnouncementsStore {
  mainStore: MainStore;

  private _announcements: Announcement[] = [];
  private _undismissedAnnouncements: Announcement[] = [];

  constructor(mainStore: MainStore, announcements = ANNOUNCEMENTS) {
    makeObservable<AnnouncementsStore, "_undismissedAnnouncements">(this, {
      _undismissedAnnouncements: observable,
      announcement: computed,
      setAnnouncements: action,
    });

    this.mainStore = mainStore;
    this._announcements = announcements;
    this.setAnnouncements();
  }

  get announcement(): {
    forHeader?: Announcement | null;
    forPopup?: Announcement | null;
  } {
    if (
      !this.mainStore.areStoresInstantiated ||
      !this.mainStore.workspaces.list?.length
    ) {
      return {
        forHeader: null,
        forPopup: null,
      };
    }

    const userType: UserType = this.mainStore.workspaces.isCollaborativeOnly
      ? "indirectUser"
      : "directUser";
    return {
      forHeader: this._undismissedAnnouncements.find((announcement) =>
        announcement.displayFor.includes(userType),
      ),
      forPopup: this._undismissedAnnouncements.find(
        (announcement) =>
          announcement.displayInPopup &&
          announcement.displayFor.includes(userType),
      ),
    };
  }

  dismissAnnouncement(localStorageKey: string) {
    localStorage.setItem(localStorageKey, "true");
    this.setAnnouncements();
  }

  setAnnouncements() {
    this._undismissedAnnouncements = this._announcements.filter(
      (a) => !localStorage.getItem(a.localStorageKey),
    );
  }
}
