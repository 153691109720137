import {
  Button,
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  LinkButton,
  useToast,
} from "@themis/ui";
import { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { generatePath, useHistory } from "react-router-dom";

import type { ModuleAssessment } from "@/api";
import {
  useDeleteModuleAssessment,
  useUpdateStatusModuleAssessment,
} from "@/api/queries/moduleAssessments";
import { useMainStore } from "@/contexts/Store";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { moduleAssessmentRoutes } from "../../routes";

const STATUS_IN_PROGRESS = "in_progress";
const STATUS_COMPLETED = "completed";

function ActionButton({
  moduleAssessment,
  handleComplete,
  handleOpen,
  recordVersionId,
}: {
  moduleAssessment: ModuleAssessment;
  handleComplete: () => void;
  handleOpen: () => void;
  recordVersionId: number;
}) {
  const workspaceId = useRouteWorkspaceId();

  const mainStore = useMainStore();
  const currentUserID = mainStore.users.user!.id!;

  if (
    moduleAssessment.status === STATUS_IN_PROGRESS &&
    currentUserID === moduleAssessment?.assignee_id
  ) {
    return (
      <>
        <Button
          aria-describedby={`close-assessment-${moduleAssessment.id}`}
          className="tw-w-[86px]"
          color="tertiary"
          onClick={handleComplete}
        >
          Complete
        </Button>

        <span
          id={`close-assessment-${moduleAssessment.id}`}
          className="tw-hidden"
        >
          Complete Assessment
        </span>
      </>
    );
  }

  if (
    moduleAssessment.status === STATUS_COMPLETED &&
    currentUserID === moduleAssessment?.assignee_id
  ) {
    return (
      <>
        <Button
          aria-describedby={`reopen-assessment-${moduleAssessment.id}`}
          className="tw-w-[86px]"
          color="tertiary"
          onClick={handleOpen}
        >
          Reopen
        </Button>
        <span
          id={`reopen-assessment-${moduleAssessment.id}`}
          className="tw-hidden"
        >
          Reopen Assessment
        </span>
      </>
    );
  }

  return (
    <>
      <LinkButton
        aria-describedby={`view-assessment-${moduleAssessment.id}`}
        className="tw-w-[86px]"
        color="tertiary"
        to={generatePath(moduleAssessmentRoutes.detail, {
          workspace_id: workspaceId,
          module_assessment_id: moduleAssessment.id,
          record_version_id: recordVersionId,
        })}
      >
        View
      </LinkButton>

      <span id={`view-assessment-${moduleAssessment.id}`} className="tw-hidden">
        View Assessment
      </span>
    </>
  );
}

export function ModuleAssessmentsActionsCell({
  moduleAssessment,
  recordVersionId,
}: {
  moduleAssessment: ModuleAssessment;
  recordVersionId: number;
}) {
  const workspace_id = useRouteWorkspaceId();

  const toast = useToast();
  const history = useHistory();

  const moduleAssessmentId = Number(moduleAssessment.id);

  const { mutate: deleteModuleAssessment } = useDeleteModuleAssessment({
    recordVersionId,
    moduleAssessmentId: Number(moduleAssessment.id),
    onSuccess: () => {
      toast({
        content: "Control Assessment has been removed",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove Control Assessment",
        variant: "error",
      });
    },
  });
  const { mutateAsync: updateStatusAssessment } =
    useUpdateStatusModuleAssessment({
      moduleAssessmentId,
      recordVersionId,
      onSuccess: () => {
        toast({
          content: "Control Assessment has been opened",
          variant: "success",
        });
      },
      onError: () => {
        toast({
          content: "Failed to open Control Assessment",
          variant: "error",
        });
      },
    });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    deleteModuleAssessment();
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  function handleOpen() {
    const requestBody = { status: STATUS_IN_PROGRESS };
    updateStatusAssessment(requestBody);
  }

  function handleComplete() {
    const requestBody = { status: STATUS_COMPLETED };
    updateStatusAssessment(requestBody);
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      <ActionButton
        moduleAssessment={moduleAssessment}
        handleOpen={handleOpen}
        handleComplete={handleComplete}
        recordVersionId={recordVersionId}
      />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            className="tw-h-8"
            color="transparent"
            size="sm"
            Icon={PiDotsThreeOutlineVerticalFill}
            variant="vertical"
            aria-describedby={`assessment-context-menu-${moduleAssessment.id}`}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() =>
              history.push(
                generatePath(moduleAssessmentRoutes.detail, {
                  module_assessment_id: moduleAssessment.id,
                  record_version_id: recordVersionId,
                  workspace_id,
                }),
              )
            }
          >
            View Assessment Detail
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleClickDelete}>
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Control Assessment"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        sideOffset={20}
      />
      <span
        id={`assessment-context-menu-${moduleAssessment.id}`}
        className="tw-hidden"
      >
        Control Assessment context menu for
      </span>
    </div>
  );
}
