import { http } from "msw";

import { createDeleteProjectMutationResponse } from "../createDeleteProject";

export const deleteProjectHandler = http.delete(
  "*/projects/:id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteProjectMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
