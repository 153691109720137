import { faker } from "@faker-js/faker";

import type {
  UpdateSection200,
  UpdateSectionMutationRequest,
  UpdateSectionMutationResponse,
  UpdateSectionPathParams,
} from "../models/UpdateSection";
import { createSection } from "./createSection";
import { createUpdateSectionRequest } from "./createUpdateSectionRequest";

export function createUpdateSectionPathParams(): NonNullable<UpdateSectionPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateSection200(): NonNullable<UpdateSection200> {
  return { data: createSection() };
}

export function createUpdateSectionMutationRequest(): NonNullable<UpdateSectionMutationRequest> {
  return createUpdateSectionRequest();
}

/**
 * @description OK
 */
export function createUpdateSectionMutationResponse(): NonNullable<UpdateSectionMutationResponse> {
  return { data: createSection() };
}
