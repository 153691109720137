import { http } from "msw";

import { createGetTagQueryResponse } from "../createGetTag";

export const getTagHandler = http.get("*/tags/:tag_id", function handler(info) {
  return new Response(JSON.stringify(createGetTagQueryResponse()), {
    headers: {
      "Content-Type": "application/json",
    },
  });
});
