import { http } from "msw";

import { createUpdateTaskLinkMutationResponse } from "../createUpdateTaskLink";

export const updateTaskLinkHandler = http.put(
  "*/tasks/:task_id/task_links/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateTaskLinkMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
