import { faker } from "@faker-js/faker";

import type {
  UpdateFinding200,
  UpdateFindingMutationRequest,
  UpdateFindingMutationResponse,
  UpdateFindingPathParams,
} from "../models/UpdateFinding";
import { createFinding } from "./createFinding";
import { createUpdateFindingRequest } from "./createUpdateFindingRequest";

export function createUpdateFindingPathParams(): NonNullable<UpdateFindingPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateFinding200(): NonNullable<UpdateFinding200> {
  return { data: createFinding() };
}

export function createUpdateFindingMutationRequest(): NonNullable<UpdateFindingMutationRequest> {
  return createUpdateFindingRequest();
}

/**
 * @description OK
 */
export function createUpdateFindingMutationResponse(): NonNullable<UpdateFindingMutationResponse> {
  return { data: createFinding() };
}
