import { faker } from "@faker-js/faker";

import type { LinkedFinding } from "../models/LinkedFinding";

export function createLinkedFinding(
  data: NonNullable<Partial<LinkedFinding>> = {},
): NonNullable<LinkedFinding> {
  return {
    ...{
      id: faker.number.int(),
      name: faker.string.alpha(),
      record_name: faker.string.alpha(),
      themis_module_identifier: faker.string.alpha(),
      url: faker.string.alpha(),
      description: faker.string.alpha(),
      risk_level: faker.helpers.arrayElement<any>(["Low", "Medium", "High"]),
      due_date: faker.string.alpha(),
      date_identified: faker.string.alpha(),
    },
    ...data,
  };
}
