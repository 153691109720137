import { http } from "msw";

import { createListOfModuleAssessmentDocumentsQueryResponse } from "../createListOfModuleAssessmentDocuments";

export const listOfModuleAssessmentDocumentsHandler = http.get(
  "*/module_assessments/:module_assessment_id/documents",
  function handler(info) {
    return new Response(
      JSON.stringify(createListOfModuleAssessmentDocumentsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
