import { useToast } from "@themis/ui";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";

interface CopyCommentLinkParams {
  basePath: string;
  commentId: number;
  recordId: number;
}

export function useCopyCommentLink({
  basePath,
  commentId,
  recordId,
}: CopyCommentLinkParams) {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const handleCopyLink = async () => {
    const search = new URLSearchParams({
      showCommentsForRecord: recordId.toString(),
      comment_id: commentId.toString(),
    }).toString();

    const newPath = generatePath(basePath, {
      workspace_id,
      accountId: recordId,
    }).concat(`?${search}`);

    await navigator.clipboard.writeText(window.location.origin.concat(newPath));
    toast({
      content: formatMessage({
        defaultMessage: "Comment link copied to clipboard",
      }),
      variant: "info",
    });
  };

  return handleCopyLink;
}
