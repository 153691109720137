import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import DocumentsContextMenu from "./DocumentsContextMenu";

function DocumentsActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();
  const path = location.pathname;
  const recordVersionId = Number(path.substring(path.lastIndexOf("/") + 1));
  const recordVersion =
    mainStore.recordVersions.current?.id === recordVersionId
      ? mainStore.recordVersions.current
      : null;

  useEffect(() => {
    const fetchData = async () => {
      if (recordVersionId) {
        await mainStore.recordVersions.fetch(recordVersionId);
      }
    };

    fetchData();
  }, [recordVersionId]);

  if (!recordVersion) {
    return null;
  }

  return <DocumentsContextMenu recordVersion={recordVersion} showOnlyApprove />;
}

export default observer(DocumentsActionBar);
