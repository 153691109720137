import { http } from "msw";

import { createDeleteRiskRegisterGroupItemMutationResponse } from "../createDeleteRiskRegisterGroupItem";

export const deleteRiskRegisterGroupItemHandler = http.delete(
  "*/workspaces/:workspace_id/risk_registers/risk_register_groups/:risk_register_group_id/risk_register_group_items/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteRiskRegisterGroupItemMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
