import { http } from "msw";

import { createReadAllNotificationsMutationResponse } from "../createReadAllNotifications";

export const readAllNotificationsHandler = http.put(
  "*/notifications/read_all",
  function handler(info) {
    return new Response(
      JSON.stringify(createReadAllNotificationsMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
