import { type ComponentType } from "react";

import type { FieldConfiguration } from "@/api";
import { getDynamicFieldValue } from "@/utils/fields/get-dynamic-field-value";

import { DynamicDateCell } from "./dynamic-date-cell/dynamic-date-cell";
import { DynamicDateTimeCell } from "./dynamic-date-time-cell/dynamic-date-time-cell";
import { DynamicReferenceCell } from "./dynamic-reference-cell/dynamic-reference-cell";
import { DynamicSelectCell } from "./dynamic-select-cell/dynamic-select-cell";
import { DynamicStatusCell } from "./dynamic-status-cell/dynamic-status-cell";
import { DynamicTextCell } from "./dynamic-text-cell/dynamic-text-cell";
import type { UnknownRecord } from "./field-configuration";

interface BaseCellProps<
  TRecord extends UnknownRecord = UnknownRecord,
  TConfig extends FieldConfiguration = FieldConfiguration,
  TValue = TRecord[TConfig["name"]],
> {
  record: TRecord;
  configuration: TConfig;
  value: TValue;
}

type BaseCellComponent = ComponentType<BaseCellProps>;

const cells = {
  text: DynamicTextCell,
  date: DynamicDateCell,
  select: DynamicSelectCell,
  datetime: DynamicDateTimeCell,
  reference: DynamicReferenceCell,
  status: DynamicStatusCell,
  textarea: DynamicTextCell,
} satisfies Record<FieldConfiguration["field_type"], BaseCellComponent>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DynamicCellProps<T = any> = BaseCellProps<
  UnknownRecord,
  FieldConfiguration,
  T | T[] | undefined
>;

export const DynamicCell = ({
  record,
  configuration,
  ...rest
}: Omit<DynamicCellProps, "value">) => {
  const CellComponent: BaseCellComponent = cells[configuration.field_type];

  const value = getDynamicFieldValue(configuration, record);

  return (
    <CellComponent
      value={value}
      record={record}
      configuration={configuration}
      {...rest}
    />
  );
};
