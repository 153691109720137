import { faker } from "@faker-js/faker";

import type {
  ListTables200,
  ListTablesPathParams,
  ListTablesQueryResponse,
} from "../models/ListTables";
import { createTable } from "./createTable";

export function createListTablesPathParams(): NonNullable<ListTablesPathParams> {
  return { module_workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListTables200(): NonNullable<ListTables200> {
  return faker.helpers.arrayElements([createTable()]) as any;
}

/**
 * @description OK
 */
export function createListTablesQueryResponse(): NonNullable<ListTablesQueryResponse> {
  return faker.helpers.arrayElements([createTable()]) as any;
}
