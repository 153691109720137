import { observer } from "mobx-react";
import React from "react";

import type { RecordVersion } from "@/api/gen/models/RecordVersion";
import { getMatchingRecordsForMatrixIndex } from "@/features/risk-register/helpers";

import { INHERENT_MATRIX_TITLE } from "../../table/risk-register/Constants";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix: any;
  recordVersions?: RecordVersion[];
}

function RiskRegisterReportMatrixContent({ matrix, recordVersions }: Props) {
  // renders
  const renderRowsHeading = (
    <div
      className="rr-scoring-matrix-impact"
      data-testid="rr-scoring-matrix-rows-category"
    >
      <span>
        {matrix.name === INHERENT_MATRIX_TITLE ? "Impact" : "Inherent"}
      </span>
    </div>
  );

  const renderColumnsHeading = (
    <div
      className="rr-scoring-matrix-likelihood"
      data-testid="rr-scoring-matrix-columns-category"
    >
      <span>
        {matrix.name === INHERENT_MATRIX_TITLE ? "Likelihood" : "Control"}
      </span>
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'row' implicitly has an 'any' type.
  const renderRows = (row, rowIndex) => (
    <div
      key={row.id ?? rowIndex}
      className="rr-scoring-matrix-top-row"
      data-testid="rr-scoring-matrix-row"
    >
      <div className="rr-scoring-matrix-cell-title">
        <h5>{row.title}</h5>
      </div>

      {/* @ts-expect-error TS(7006) FIXME: Parameter 'cell' implicitly has an 'any' type. */}
      {row.scoring_matrix_cells.map((cell, colIndex) => {
        const style = {
          backgroundColor: cell.color,
        };
        const cellValue = cell.value;
        const filteredRecordVersions = getMatchingRecordsForMatrixIndex(
          matrix,
          recordVersions as RecordVersion[],
          rowIndex,
          colIndex,
        );

        return (
          <div
            key={cell.id ?? colIndex}
            style={style}
            className="rr-scoring-matrix-cell"
          >
            {filteredRecordVersions.length > 0 && (
              <div className="rr-scoring-matrix-cell-report-content">
                <div className="circle">{filteredRecordVersions.length}</div>
                <div className="label">
                  {cell.title} - {cellValue}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'column' implicitly has an 'any' type.
  const renderColumnsTitles = (column, colIndex: number) => (
    <div
      key={column.id ?? colIndex}
      className="rr-scoring-matrix-cell-title"
      data-testid="rr-scoring-matrix-column"
    >
      <h5>{column.title}</h5>
    </div>
  );

  return (
    <div className="rr-scoring-wrapper">
      <div className="rr-scoring-container" data-testid="rr-scoring-container">
        <div className="rr-scoring-matrix">
          <div className="rr-scoring-matrix-top">
            {renderRowsHeading}
            <div className="rr-scoring-matrix-top-rows">
              {matrix.scoring_matrix_rows.map(renderRows)}
            </div>
          </div>
          <div className="rr-scoring-matrix-bottom">
            <div className="rr-scoring-matrix-bottom-content">
              <div className="rr-scoring-matrix-columns-titles">
                {matrix.scoring_matrix_columns.map(renderColumnsTitles)}
              </div>
              {renderColumnsHeading}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RiskRegisterReportMatrixContent.defaultProps = {
  recordVersions: [],
};

export default observer(RiskRegisterReportMatrixContent);
