import { faker } from "@faker-js/faker";

import type { LinkedRecord } from "../models/LinkedRecord";

export function createLinkedRecord(
  data: NonNullable<Partial<LinkedRecord>> = {},
): NonNullable<LinkedRecord> {
  return {
    ...{
      global_id: faker.string.alpha(),
      id: faker.number.int(),
      latest_record_version_id: faker.number.int(),
      name: faker.string.alpha(),
      url: faker.string.alpha(),
    },
    ...data,
  };
}
