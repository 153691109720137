import { Label, useToast } from "@themis/ui";
import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { useControlMappingsEffectiveness } from "@/api/queries/controlMappings";
import {
  useCreateModuleAssessment,
  useShowModuleAssessments,
  useUpdateModuleAssessment,
} from "@/api/queries/moduleAssessments";
import { ErrorContainer } from "@/components/ErrorContainer";
import {
  formatDateForCellValue,
  stringToDate,
} from "@/components/helpers/DateFormatters";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";

import type { ModuleAssessmentInfoSchema } from "../components/ModuleAssessmentInfoForm";
import { ModuleAssessmentInfoForm } from "../components/ModuleAssessmentInfoForm";
import { ModuleAssessmentStatusTag } from "../components/ModuleAssessmentTable/ModuleAssessmentStatusTag";
import { moduleAssessmentRoutes } from "../routes";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function ModuleAssessmentInfoContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

export function ModuleAssessmentDetails({
  recordVersionId,
  moduleWorkspaceId: moduleWorkspaceID,
}: {
  recordVersionId: number;
  moduleWorkspaceId?: number;
}) {
  const toast = useToast();
  const history = useHistory();

  const { workspace_id, record_version_id, module_assessment_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
    module_assessment_id: string;
  }>();

  const {
    data: moduleAssessment,
    isLoading,
    error,
  } = useShowModuleAssessments(Number(module_assessment_id));

  const controlMappingsEffectiveness = useControlMappingsEffectiveness(
    Number(record_version_id),
    Number(moduleWorkspaceID),
  );

  const ModuleAssessmentsListPath = generatePath(
    moduleAssessmentRoutes.overview,
    { workspace_id, record_version_id },
  );

  const { mutateAsync: createModuleAssessment } = useCreateModuleAssessment({
    recordVersionId,
    onSuccess: () => {
      toast({
        content: "Control Assessment saved successfully",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not create Control Assessment.",
        variant: "error",
      });
    },
  });

  const { mutateAsync: updateModuleAssessment } = useUpdateModuleAssessment({
    moduleAssessmentId: Number(module_assessment_id),
    recordVersionId: Number(record_version_id),
  });

  const handleCreateModuleAssessment = async (
    values: Partial<ModuleAssessmentInfoSchema>,
  ) => {
    const requestBody = {
      compliance_status: values?.compliance_status || null,
      summary: values?.summary || null,
      due_date: values?.due_date || null,
      assessment_date: values?.assessment_date || null,
      control_effectiveness_rating:
        values?.control_effectiveness_rating || null,
      assignee_id: values?.assignee_id || null,
    };

    await createModuleAssessment(requestBody);

    history.push(ModuleAssessmentsListPath);
  };

  async function handleUpdateModuleAssessment(
    values: ModuleAssessmentInfoSchema,
  ) {
    try {
      const requestBody = {
        compliance_status: values?.compliance_status || null,
        summary: values?.summary || null,
        due_date: values?.due_date || null,
        assessment_date: values?.assessment_date || null,
        control_effectiveness_rating:
          values?.control_effectiveness_rating || null,
        assignee_id: values?.assignee_id || null,
      };

      await updateModuleAssessment(requestBody);

      history.push(ModuleAssessmentsListPath);

      toast({
        content: `Control Assessment has been updated`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not update Control Assessment.",
        variant: "error",
      });
    }
  }

  function handleSubmitForm(values: ModuleAssessmentInfoSchema) {
    if (!moduleAssessment) {
      handleCreateModuleAssessment(values);
    } else {
      handleUpdateModuleAssessment(values);
    }
  }

  if (error || isLoading) {
    return (
      <PageContent>
        {isLoading ? (
          <Loading loadingLayout="small-table" />
        ) : (
          <ErrorContainer
            backButtonProps={{ linkTo: ModuleAssessmentsListPath }}
          >
            Could not load Module Assessment details
          </ErrorContainer>
        )}
      </PageContent>
    );
  }

  return (
    <div className="tw-h-[calc(100%_-_48px)]">
      <PageLayout>
        <PageContent>
          <FormContainer>
            <div className="tw-flex tw-flex-row tw-gap-2">
              <StatusContainer>
                <Label className="tw-font-semibold tw-text-neutral-500">
                  Created Date
                </Label>
                <p className="tw-font-semibold tw-text-neutral-300">
                  {formatDateForCellValue(
                    stringToDate(moduleAssessment?.data?.created_at) ||
                      new Date(),
                    true,
                  )}
                </p>
              </StatusContainer>

              <StatusContainer>
                <Label className="tw-font-semibold tw-text-neutral-500">
                  Status
                </Label>
                <ModuleAssessmentStatusTag
                  status={moduleAssessment?.data?.status}
                />
              </StatusContainer>
            </div>
            <ModuleAssessmentInfoContainer>
              <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
                Assessment Info
              </h2>
              <ModuleAssessmentInfoForm
                defaultValues={moduleAssessment?.data}
                onSubmit={handleSubmitForm}
                controlMappingsEffectiveness={
                  controlMappingsEffectiveness?.data?.data
                }
              />
            </ModuleAssessmentInfoContainer>
          </FormContainer>
        </PageContent>
      </PageLayout>
    </div>
  );
}
