import type { SelectProps } from "@themis/ui";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
} from "@themis/ui";
import type { ReactNode } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

export interface SelectFormFieldProps<T extends FieldValues> {
  label?: ReactNode;
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  items: SelectProps["items"];
  searchable?: SelectProps["searchable"];
  multiselect?: boolean;
  renderSelected?: SelectProps["renderSelected"];
  renderOption?: SelectProps["renderOption"];
}

export function SelectFormField<T extends FieldValues>({
  control,
  items,
  name,
  label,
  required,
  searchable,
  multiselect,
  renderOption,
  renderSelected,
}: SelectFormFieldProps<T>) {
  return (
    <FormField
      control={control}
      name={name}
      required={required}
      render={({ field, fieldState }) => {
        const getSelected = () => {
          if (multiselect && Array.isArray(field.value)) {
            return field.value.map(String);
          }

          return field.value ? String(field.value) : field.value;
        };

        return (
          <FormItem>
            {label && <FormLabel>{label}</FormLabel>}

            <FormControl>
              <Select
                {...field}
                error={!!fieldState.error}
                multiple={multiselect}
                items={items}
                searchable={searchable}
                selected={getSelected()}
                onSelect={field.onChange}
                renderSelected={renderSelected}
                renderOption={renderOption}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
