import "./risk-assessment-tabs.scss";

import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import type { PropsWithChildren, ReactNode } from "react";
import React from "react";
import { generatePath } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";

import { routes } from "../routes";

type Props = PropsWithChildren<{
  RightActionBar?: ReactNode;
}>;

function RiskAssessmentTabs(props: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const TABS = [
    {
      name: "Overview",
      key: "dashboard",
      value: generatePath(routes.DASHBOARD_PATH, {
        workspace_id: Number(workspaceID),
      }),
    },
    {
      name: "Partners",
      key: "partners",
      value: generatePath(routes.PARTNERS_PATH, {
        workspace_id: Number(workspaceID),
      }),
    },
    {
      name: "Templates",
      key: "questionnaire-template",
      value: generatePath(routes.QUESTIONNAIRE_TEMPLATE_PATH, {
        workspace_id: Number(workspaceID),
      }),
    },
  ];

  return (
    <div className="risk-assessment-tabs">
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS, true)?.value || ""}
        isLink
      />
      {props.RightActionBar}
    </div>
  );
}

export default observer(RiskAssessmentTabs);
