import type { Location } from "history";

import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";
import { pathMatchesPattern } from "@/utils/routing";

interface ModuleRoutePattern {
  [key: string]: ModuleIdentifier;
}

const moduleRoutePatterns: ModuleRoutePattern = {
  "/workspaces/:workspace/modules/add-new-vendor": "vendor_due_diligence",
  "/workspaces/:workspace/accounts": "accounts",
  "/workspaces/:workspace/training": "training",
  "/workspaces/:workspace/modules/audits": "audits",
  "/workspaces/:workspace/modules/change-management": "new_product_approval",
  "/workspaces/:workspace/modules/complaints": "complaints",
  "/workspaces/:workspace/modules/conflicts-of-interest":
    "conflicts_of_interest",
  "/workspaces/:workspace/modules/control-mapping": "control_mapping",
  "/workspaces/:workspace/modules/control": "control_mapping",
  "/workspaces/:workspace/modules/customer-support": "customer_support",
  "/workspaces/:workspace/modules/documents": "documents",
  "/workspaces/:workspace/modules/finra": "finra",
  "/workspaces/:workspace/modules/issue_management": "issue_management",
  "/workspaces/:workspace/modules/issue-management": "issue_management",
  "/workspaces/:workspace/modules/key-risk-indicators": "key_risk_indicators",
  "/workspaces/:workspace/modules/marketing": "marketing",
  "/workspaces/:workspace/modules/new-product-approval": "new_product_approval",
  "/workspaces/:workspace/modules/policy": "policy",
  "/workspaces/:workspace/modules/procedures": "procedures",
  "/workspaces/:workspace/modules/qa-tests": "qa_tests_development",
  "/workspaces/:workspace/modules/qa-tests-development": "qa_tests_development",
  "/workspaces/:workspace/modules/risk-assessment": "risk_assessment",
  "/workspaces/:workspace/modules/risk-register": "risk_register",
  "/workspaces/:workspace/modules/risk-register-scoring-residual":
    "risk_register",
  "/workspaces/:workspace/modules/risk-register-scoring-inherent":
    "risk_register",
  "/workspaces/:workspace/modules/training": "training",
  "/workspaces/:workspace/modules/vendor-due-diligence": "vendor_due_diligence",
  "/workspaces/:workspace/modules/website_monitoring": "website_monitoring",
  "/workspaces/:workspace/modules/website_monitoring/monitoring_groups":
    "website_monitoring",
  "/workspaces/:workspace/modules/zendesk": "zendesk",
};

function getModuleIdentifierFromPath(path: string): ModuleIdentifier | null {
  const pattern = Object.keys(moduleRoutePatterns).find((routePattern) => {
    const match = pathMatchesPattern(path, routePattern);

    if (match) {
      return routePattern;
    }

    return null;
  });

  if (pattern) {
    return moduleRoutePatterns[pattern];
  }

  const moduleRoute = pathMatchesPattern(
    path,
    "/workspaces/:workspace/modules",
  );
  if (moduleRoute) {
    window.console.error(`WARNING: Unknown ThemisModule for path "${path}"`);
  }

  return null;
}

export function useModuleDetection(location: Location) {
  return getModuleIdentifierFromPath(location.pathname);
}
