import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Checkbox,
  cn,
  MiniTag,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@themis/ui";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { PiSlidersBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { FilterFieldData } from "@/hooks/useFilterSort";
import useFilterSort, { FilterOperand } from "@/hooks/useFilterSort";

export function FilterSelect({
  filtersFieldData,
}: {
  filtersFieldData: FilterFieldData<Record<string, string>>;
}) {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const { applyFilterSort, filters: filterParams } =
    useFilterSort(filtersFieldData);

  const initialFilters = Object.keys(filterParams || {}).reduce<
    Record<string, Array<string>>
  >(
    (acc, currentValue) => ({
      ...acc,
      [currentValue]: filterParams?.[currentValue]?.value || [],
    }),
    {},
  );

  const [selected, setSelected] =
    useState<Record<string, Array<string>>>(initialFilters);

  const buttonColor = isOpen ? "active" : "transparent";

  const handleSelect = (filterKey: string, optionKey: string) => {
    setSelected((prev) => {
      const currentFilters = { ...prev };
      const selectedOptions = currentFilters[filterKey] || [];

      const newSelectedOptions = selectedOptions.includes(optionKey)
        ? selectedOptions.filter((key) => key !== optionKey)
        : [...selectedOptions, optionKey];

      return {
        ...currentFilters,
        [filterKey]: newSelectedOptions,
      };
    });
  };

  const handleClear = () => {
    setSelected(
      Object.keys(selected).reduce<Record<string, Array<string>>>(
        (acc, key) => ({ ...acc, [key]: [] }),
        {},
      ),
    );
  };

  const handleCancel = () => {
    setSelected(initialFilters);
    setIsOpen(false);
  };

  const handleApply = () => {
    const filters = Object.entries(selected).reduce<
      Parameters<typeof applyFilterSort>[0]
    >(
      (acc, [fieldName, value]) => {
        if (!value || value.length === 0) {
          return {
            ...acc,
            filtersToRemove: [...(acc.filtersToRemove || []), fieldName],
          };
        }

        return {
          ...acc,
          filtersToApply: {
            ...acc.filtersToApply,
            [fieldName]: {
              operand: FilterOperand.ANY,
              value,
            },
          },
        };
      },
      {
        filtersToApply: {},
        filtersToRemove: [],
      },
    );

    applyFilterSort(filters);
    setIsOpen(false);
  };

  const isClearDisabled = Object.keys(selected).every((key) =>
    isEmpty(selected[key]),
  );

  const numberOfSelectedFilters = Object.keys(initialFilters).reduce(
    (acc, key) => acc + initialFilters?.[key].length,
    0,
  );

  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        <Button
          LeftIcon={PiSlidersBold}
          color={buttonColor}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {formatMessage({ defaultMessage: "Filters" })}

          {filterParams && !!numberOfSelectedFilters && (
            <MiniTag
              className="tw-min-w-5"
              size="sm"
              variant="default"
              color="black"
            >
              {numberOfSelectedFilters}
            </MiniTag>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" onEscapeKeyDown={handleCancel}>
        <div className="tw-box-border tw-flex tw-min-w-72 tw-max-w-80 tw-flex-col tw-gap-1 tw-font-sans">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-pb-2 tw-pt-4">
            <h3 className="tw-font-semibold tw-text-neutral-500">Filters</h3>
            <Button
              className={cn({
                "disabled:tw-bg-transparent disabled:tw-text-neutral-200":
                  isClearDisabled,
              })}
              disabled={isClearDisabled}
              color="transparentPrimary"
              onClick={handleClear}
            >
              {formatMessage({ defaultMessage: "Clear Filters" })}
            </Button>
          </div>
          <Accordion type="single" collapsible>
            {Object.keys(filtersFieldData).map((filterKey) => {
              const { displayName, options } =
                filtersFieldData[filterKey] || {};

              return (
                <AccordionItem key={filterKey} value={filterKey}>
                  <AccordionTrigger className="tw-px-4 tw-py-3 tw-font-medium tw-text-neutral-500">
                    {displayName}
                  </AccordionTrigger>
                  <AccordionContent className="tw-bg-primary-25 tw-px-4">
                    {options?.map((option) => (
                      <div
                        className="tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-2 tw-py-3"
                        key={option.value}
                        onClick={() => handleSelect(filterKey, option.key)}
                      >
                        <Checkbox
                          size="md"
                          color="primary"
                          checked={selected[filterKey]?.includes(option.key)}
                        />
                        <span className="tw-select-none tw-text-sm tw-font-medium tw-text-neutral-500">
                          {option.value}
                        </span>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
        <div className="tw-h-px tw-bg-neutral-100" />
        <div className="tw-flex tw-items-center tw-justify-end tw-gap-2 tw-px-4 tw-py-3">
          <Button onClick={handleApply}>
            {formatMessage({ defaultMessage: "Apply" })}
          </Button>

          <Button color="tertiary" onClick={handleCancel}>
            {formatMessage({ defaultMessage: "Cancel" })}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
