import { faker } from "@faker-js/faker";

import type {
  UnarchiveWorkspace200,
  UnarchiveWorkspace403,
  UnarchiveWorkspaceMutationResponse,
  UnarchiveWorkspacePathParams,
} from "../models/UnarchiveWorkspace";
import { createWorkspace } from "./createWorkspace";

export function createUnarchiveWorkspacePathParams(): NonNullable<UnarchiveWorkspacePathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUnarchiveWorkspace200(): NonNullable<UnarchiveWorkspace200> {
  return createWorkspace();
}

/**
 * @description Forbidden
 */
export function createUnarchiveWorkspace403(): NonNullable<UnarchiveWorkspace403> {
  return {};
}

/**
 * @description OK
 */
export function createUnarchiveWorkspaceMutationResponse(): NonNullable<UnarchiveWorkspaceMutationResponse> {
  return createWorkspace();
}
