import { http } from "msw";

import { createListTasksQueryResponse } from "../createListTasks";

export const listTasksHandler = http.get(
  "*/companies/:company_id/tasks",
  function handler(info) {
    return new Response(JSON.stringify(createListTasksQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
