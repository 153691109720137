import { http } from "msw";

import { createDeleteTaskMutationResponse } from "../createDeleteTask";

export const deleteTaskHandler = http.delete(
  "*/tasks/:id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteTaskMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
