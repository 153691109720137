import { http } from "msw";

import { createSendAttestationListMutationResponse } from "../createSendAttestationList";

export const sendAttestationListHandler = http.post(
  "*/attestation_lists/:attestation_list_id/send",
  function handler(info) {
    return new Response(
      JSON.stringify(createSendAttestationListMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
