import classNames from "classnames";
import { observer } from "mobx-react";
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import Popup from "reactjs-popup";

import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { useMainStore } from "../../../contexts/Store";
import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";
import departmentIcon from "../../../images/table-image/icon/department.svg";
import lightbulb from "../../../images/table-image/icon/lightbulb-white-icon.svg";
import search from "../../../images/table-image/icon/search-black-two.svg";
import { userColors } from "../../constants";
import { Icon, MiniTag } from "../../Elements";
import { validateEmail } from "../../helpers/EmailValidator";
import { recordTypeForThemisModuleIdentifier } from "../../helpers/nameForThemisModuleIdentifier";

interface Props {
  checkboxSelection?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emailSlideClose?: (...args: any[]) => any;
  moduleIdentifier?: ModuleIdentifier;
  moduleWorkspaceID?: number;
  recordVersionID?: number;
  questionnaireID?: number;
  tableName?: string;
  hideDepartment?: boolean;
}

function EmailAttest({
  emailSlideClose,
  moduleWorkspaceID,
  recordVersionID,
  questionnaireID,
  checkboxSelection,
  tableName,
  moduleIdentifier,
  hideDepartment,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const userDepartmentRef = useRef(null);
  const messageRef = useRef(null);

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupUpload, setShowPopupUpload] = useState(false);
  const [query, setQuery] = useState("");
  const [searchUserDepartment, setSearchUserDepartment] = useState(false);
  const [typedEmail, setTypedEmail] = useState("");
  const [selectedRecordVersionIDs, setSelectedRecordVersionIDs] = useState<
    number[]
  >([]);
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);
  const [selectedDepartmentIDs, setSelectedDepartmentIDs] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [shouldAttest, setShouldAttest] = useState(true);
  const { current: initialAttestationListId } = useRef(
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
    mainStore.attestationLists.current.id as number,
  );
  const [wrongEmails, setWrongEmails] = useState([]);
  const [showWrongEmails, setShowWrongEmails] = useState(false);

  // Hooks
  useEffect(() => {
    if (recordVersionID) {
      mainStore.attestations.fetchNotAttested(recordVersionID);
    }
  }, [recordVersionID]);

  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    function handleClickOutside(event) {
      if (
        userDepartmentRef.current &&
        // @ts-expect-error TS(2339) FIXME: Property 'contains' does not exist on type 'never'... Remove this comment to see the full error message
        !userDepartmentRef.current.contains(event.target)
      ) {
        setSearchUserDepartment(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      selectedRecordVersionIDs.length > 0 ||
      (questionnaireID &&
        (selectedUserIDs.length > 0 ||
          selectedDepartmentIDs.length > 0 ||
          selectedEmails.length > 0))
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [
    selectedRecordVersionIDs,
    selectedUserIDs,
    selectedDepartmentIDs,
    selectedEmails,
    questionnaireID,
  ]);

  useEffect(() => {
    // Copy selection from DynamicTable store
    const newSelection =
      mainStore.dynamicTable.selectedRecordVersionIDs.slice();

    // Add single selection if any
    if (recordVersionID) {
      newSelection.push(recordVersionID);
    }

    setSelectedRecordVersionIDs(newSelection);
  }, [recordVersionID, mainStore.dynamicTable.selectedRecordVersionIDs]);

  // When attestationList is saved - hide EmailAttest
  useEffect(() => {
    if (
      mainStore.attestationLists.current &&
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
      initialAttestationListId !== mainStore.attestationLists.current.id
    ) {
      mainStore.dynamicTable.clearSelectedRecordVersionIDs();
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      emailSlideClose();
    }
  }, [mainStore.attestationLists.current]);

  const filteredDepartments = hideDepartment
    ? []
    : mainStore.departments.departments
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        .filter((element) => !selectedDepartmentIDs.includes(element.id))
        .filter((searchItems) =>
          searchItems.title.toLowerCase().includes(query.toLowerCase()),
        );
  const filteredUsers = mainStore.users.users
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    .filter((user) => !selectedUserIDs.includes(user.id))
    .filter(
      (searchItems) =>
        searchItems.full_name?.toLowerCase().includes(query.toLowerCase()),
    );

  const filteredContacts = mainStore.contacts.list
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    .filter((contacts) => !selectedUserIDs.includes(contacts.id))
    .filter(
      (searchItems) =>
        searchItems.full_name?.toLowerCase().includes(query.toLowerCase()),
    );

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addUserById(id) {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    setSelectedUserIDs([...selectedUserIDs, id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addDepartmentById(id) {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    setSelectedDepartmentIDs([...selectedDepartmentIDs, id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function removeUserById(id) {
    const newSelectionUser = selectedUserIDs.filter(
      (element) => element !== id,
    );
    setSelectedUserIDs(newSelectionUser);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function removeDepartmentById(id) {
    const newSelectionDepartment = selectedDepartmentIDs.filter(
      (element) => element !== id,
    );
    setSelectedDepartmentIDs(newSelectionDepartment);
  }

  // Emails
  // @ts-expect-error TS(7006) FIXME: Parameter 'email' implicitly has an 'any' type.
  function addEmail(email) {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    setSelectedEmails([...selectedEmails, email]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'email' implicitly has an 'any' type.
  function removeEmail(email) {
    const newSelectionEmail = selectedEmails.filter(
      (element) => element !== email,
    );
    setSelectedEmails(newSelectionEmail);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleEmailKeyDown(event) {
    if (event.key === "Enter" || event.key === " " || event.type === "click") {
      if (validateEmail(typedEmail)) {
        addEmail(typedEmail);
        setTypedEmail("");
        setQuery("");
      }
    }
  }

  function searchUserDepartmentClick() {
    setSearchUserDepartment(true);
    setShowPopup(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function searchUserDepartmentEmailChange(event) {
    setQuery(event.target.value);
    if (event.target.value !== " ") {
      setTypedEmail(event.target.value);
    } else {
      setTypedEmail("");
    }
  }

  async function onSend() {
    // Create AttestationList (Attest Policies or Procedures)
    if (moduleWorkspaceID) {
      const attestationList = {
        share_only: !shouldAttest,
        record_version_ids: selectedRecordVersionIDs,
      };

      // @ts-expect-error TS(2339) FIXME: Property 'message' does not exist on type '{ share... Remove this comment to see the full error message
      attestationList.message = messageRef.current.value;
      if (selectedUserIDs.length > 0) {
        // @ts-expect-error TS(2339) FIXME: Property 'user_ids' does not exist on type '{ shar... Remove this comment to see the full error message
        attestationList.user_ids = selectedUserIDs;
      }
      if (selectedDepartmentIDs.length > 0) {
        // @ts-expect-error TS(2339) FIXME: Property 'department_ids' does not exist on type '... Remove this comment to see the full error message
        attestationList.department_ids = selectedDepartmentIDs;
      }
      if (selectedEmails.length > 0) {
        // @ts-expect-error TS(2339) FIXME: Property 'contact_emails' does not exist on type '... Remove this comment to see the full error message
        attestationList.contact_emails = selectedEmails;
      }
      if (questionnaireID) {
        // @ts-expect-error TS(2339) FIXME: Property 'questionnaire_id' does not exist on type '... Remove this comment to see the full error message
        attestationList.questionnaire_id = questionnaireID;
      }

      const params = { attestation_list: attestationList };

      const response = await mainStore.attestationLists.create(
        moduleWorkspaceID,
        params,
      );
      if (response) {
        let toastText = "Polices";
        if (tableName === "ProceduresFinalized") {
          toastText = "Procedures";
        } else if (isCM) {
          toastText = "Controls";
        } else if (isTraining) {
          toastText = "Trainings";
        }

        setTimeout(() => {
          if (isQuestionnaire) {
            mainStore.toast.setText("Questionnaire has been sent");
            return;
          }

          mainStore.recordVersions.list
            .filter((recordVersion) =>
              selectedRecordVersionIDs.includes(recordVersion.id),
            )
            .map((recordVersion) =>
              mainStore.toast.setText(
                `${
                  selectedRecordVersionIDs.length > 1
                    ? `${selectedRecordVersionIDs.length} ${toastText}`
                    : `“${recordVersion.meta.name || recordVersion.global_id}"`
                } have been sent`,
              ),
            );
        }, 300);
      }
    }
  }

  const buttonEnabled = classNames({ active: !buttonDisabled });
  const searchClasses = classNames("list-block", {
    "active-search": query.length > 0,
  });

  const listUsersElements = filteredUsers.slice(0, 1).map((elem) => (
    <div className="email-list-wrap" key={elem.id} data-testid="list-users">
      {filteredUsers
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        .filter((element) => !selectedUserIDs.includes(element.id))
        .map((user) => {
          const style = { background: userColors[user.icon_color_index] };

          return (
            <div
              key={user.id}
              data-testid="email-list-user-li"
              className={searchClasses}
              onClick={() => {
                addUserById(user.id);
              }}
            >
              <div className="users" data-testid="user">
                <div className="users-circle" style={style}>
                  <span>{user.initials}</span>
                </div>
                <div data-testid="user-name" className="user-full-name">
                  {user.full_name}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  ));

  const listContactsElements = filteredContacts.slice(0, 1).map((elem) => (
    <div className="email-list-wrap" key={elem.id} data-testid="list-contacts">
      {filteredContacts
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        .filter((element) => !selectedUserIDs.includes(element.id))
        .map((contact) => {
          const style = { borderColor: userColors[contact.icon_color_index] };

          return (
            <div
              key={contact.id}
              className={searchClasses}
              onClick={() => {
                addUserById(contact.id);
              }}
            >
              <div className="users" data-testid="contact">
                <div
                  className="users-circle users-circle-contact"
                  style={style}
                >
                  <span>{contact.initials}</span>
                </div>
                <div data-testid="contact-name">{contact.full_name}</div>
              </div>
            </div>
          );
        })}
    </div>
  ));

  const listAvailableDepartments = filteredDepartments
    .slice(0, 1)
    .map((elem) => (
      <div
        className="email-list-wrap"
        key={elem.id}
        data-testid="list-departments"
      >
        {filteredDepartments
          // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          .filter((element) => !selectedDepartmentIDs.includes(element.id))
          .map((department) => (
            <div
              key={department.id}
              className={searchClasses}
              onClick={() => {
                addDepartmentById(department.id);
              }}
              data-testid="department"
            >
              <div
                className="procedures-popup-element"
                data-testid="department-title"
              >
                <span>
                  <img
                    src={departmentIcon}
                    className="department"
                    alt="department-icon"
                  />
                </span>
                {department.title}
              </div>
            </div>
          ))}
      </div>
    ));

  const selectedRecordVersions = mainStore.recordVersions.list
    .filter((recordVersion) =>
      selectedRecordVersionIDs.includes(recordVersion.id),
    )
    .map((recordVersion) => (
      <div
        key={recordVersion.id}
        className="table-email-element attest-selected-item"
      >
        <MiniTag label={recordVersion.global_id} theme="navy" />
        <div className="name">
          {recordVersion.meta?.name || "-- Untitled --"}
        </div>
      </div>
    ));

  const { themisModuleIdentifier } = mainStore.context;
  const isCM = moduleIdentifier === "control_mapping";
  const isTraining = themisModuleIdentifier === "training";
  const isQuestionnaire = themisModuleIdentifier === "risk_assessment";
  const attestationEntitiesName =
    // @ts-expect-error TS(2345) FIXME: Argument of type '"audits" | "new_product_approval... Remove this comment to see the full error message
    recordTypeForThemisModuleIdentifier(themisModuleIdentifier)?.plural ||
    "records";

  const searchInput = (
    <label className="form-label" data-testid="add-attester-input">
      <input
        placeholder="– Search here –"
        value={typedEmail}
        onChange={searchUserDepartmentEmailChange}
        onClick={searchUserDepartmentClick}
        onKeyDown={handleEmailKeyDown}
      />
      <img src={search} alt="search-icon" />
    </label>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  async function processCSV(event) {
    const [file] = event.target.files;
    await mainStore.users.importCsvFile(
      file,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      mainStore.users.user.active_workspace.id,
      false,
      false,
    );
    // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
    await mainStore.contacts.index({});
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        results.data = results.data.map((row) => ({
          // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...row,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          "Email Address": row["Email Address"].trim().toLowerCase(),
        }));
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        const newEmails = results.data.map((row) => row["Email Address"]);
        newEmails.forEach((email) => {
          if (!validateEmail(email)) {
            // @ts-expect-error TS(2345) FIXME: Argument of type '(emails: never[]) => any[]' is n... Remove this comment to see the full error message
            setWrongEmails((emails) => [...emails, email]);
          }
        });
        const ids = mainStore.contacts.list
          .filter((contact) => newEmails.includes(contact.email?.toLowerCase()))
          .map((item) => item.id);
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        setSelectedUserIDs([...selectedUserIDs, ...ids]);
        const emailsWithoutName = results.data
          .filter(
            (newContact) =>
              // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
              newContact["Email Address"] && !newContact["Full Name"],
          )
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          .map((newContact) => newContact["Email Address"]);
        // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: never[]) => any[]' is not... Remove this comment to see the full error message
        setSelectedEmails((prev) => [...prev, ...emailsWithoutName]);
      },
    });
    setShowPopupUpload(false);
  }

  return (
    <div
      className="table-dropdown table-email-dropdown email-attest"
      onClick={() => setShowPopupUpload(false)}
    >
      <div className="email-dropdown-head">
        <h3>
          {!isQuestionnaire && `Email / Attest ${attestationEntitiesName}`}
          {isQuestionnaire && "Send Questionnaire"}
        </h3>
        <div onClick={emailSlideClose}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      {checkboxSelection && selectedRecordVersions.length === 0 && (
        <>
          <hr />
          <div className="initial-text-paragraph">
            Select {attestationEntitiesName.toLowerCase()} you want to email /
            attest by checking the checkboxes on the left
          </div>
          <hr />
        </>
      )}
      {selectedRecordVersions.length > 0 && (
        <div className="table-email-select">
          <div className="table-email-block">{selectedRecordVersions}</div>
        </div>
      )}
      {!checkboxSelection && <hr />}
      <div className="email-dropdown-wrap">
        <div className="email-dropdown-block">
          <div className="label">Add Users / Contacts / Emails</div>
          <div className="attestation-search-import">
            <div>
              <Popup
                position="bottom left"
                // eslint-disable-next-line react/no-unstable-nested-components
                trigger={() => searchInput}
                open={showPopup}
                onOpen={() => setShowPopup(true)}
                onClose={() => setShowPopup(false)}
                keepTooltipInside
                className="hit-popup"
                // @ts-expect-error TS(2322) FIXME: Type '"" | "hover"' is not assignable to type 'Eve... Remove this comment to see the full error message
                on={!searchUserDepartment ? "hover" : ""}
                arrow={false}
                offsetY={18}
              >
                <div className="hint-wrap">
                  <img src={lightbulb} alt="lightbulb" /> To add email, type
                  email above and hit “Enter”.{" "}
                </div>
              </Popup>
            </div>
            <div>
              <Popup
                position="bottom left"
                // eslint-disable-next-line react/no-unstable-nested-components
                trigger={() => (
                  <button
                    className="contact-upload-button"
                    data-testid="import-contact"
                  >
                    Import Contacts
                  </button>
                )}
                open={showPopupUpload}
                onOpen={() => setShowPopupUpload(true)}
                onClose={() => setShowPopupUpload(false)}
                keepTooltipInside
                offsetY={18}
                nested
              >
                {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
                <DirectUploadProvider
                  render={() => (
                    <div data-testid="direct-upload-wrapper">
                      <div className="drag-drop-wrap">
                        <div className="drag-drop-block">
                          <p>
                            <Icon name="upload" color="generalDarkGray" />
                            <br />
                            Drag & drop into this box
                            <br />- or -
                          </p>
                          <button>Choose a file</button>
                          <input
                            type="file"
                            onDrop={processCSV}
                            onChange={processCSV}
                            data-testid="direct-upload-input"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </Popup>
            </div>
          </div>

          {searchUserDepartment &&
            (filteredDepartments.length > 0 ||
              filteredUsers.length > 0 ||
              filteredContacts.length > 0) && (
              <div className="email-popup-wrap" ref={userDepartmentRef}>
                {listAvailableDepartments}
                {listUsersElements}
                {listContactsElements}
              </div>
            )}

          <div className="list-wrap-block">
            {mainStore.departments.departments
              // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
              .filter((element) => selectedDepartmentIDs.includes(element.id))
              .map((item) => (
                <div key={item.id} className="list-block">
                  <div className="procedures-popup-element">
                    <span>
                      <img
                        src={departmentIcon}
                        className="department"
                        alt="department-icon"
                      />
                    </span>
                    {item.title}
                  </div>
                  <div
                    onClick={() => {
                      removeDepartmentById(item.id);
                    }}
                  >
                    <img src={closeIcon} alt="close-icon" />
                  </div>
                </div>
              ))}

            {mainStore.users.users
              // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
              .filter((element) => selectedUserIDs.includes(element.id))
              .map((user) => {
                const style = {
                  background: userColors[user.icon_color_index],
                };
                const usersCircleClasses = classNames("users-circle", {
                  "users-circle-contact": user.is_contact,
                });
                return (
                  <div key={user.id} className="list-block">
                    <div className="users">
                      <div className={usersCircleClasses} style={style}>
                        <span>{user.initials}</span>
                      </div>
                      <div className="user-full-name">{user.full_name}</div>
                    </div>
                    <div
                      onClick={() => {
                        removeUserById(user.id);
                      }}
                    >
                      <img src={closeIcon} alt="close-icon" />{" "}
                    </div>
                  </div>
                );
              })}

            {mainStore.contacts.list
              // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
              .filter((element) => selectedUserIDs.includes(element.id))
              .map((contact) => {
                const style = {
                  borderColor: userColors[contact.icon_color_index],
                };
                const usersCircleClasses = classNames("users-circle", {
                  "users-circle-contact": contact.is_contact,
                });
                return (
                  <div key={contact.id} className="list-block">
                    <div className="users">
                      <div className={usersCircleClasses} style={style}>
                        <span>{contact.initials}</span>
                      </div>
                      <div>{contact.full_name}</div>
                    </div>
                    <div
                      onClick={() => {
                        removeUserById(contact.id);
                      }}
                    >
                      <img src={closeIcon} alt="close-icon" />{" "}
                    </div>
                  </div>
                );
              })}

            {selectedEmails.map((email) => (
              <div key={email} className="list-block">
                <div className="procedures-popup-element">{email}</div>
                <div
                  onClick={() => {
                    removeEmail(email);
                  }}
                >
                  <img src={closeIcon} alt="close-icon" />{" "}
                </div>
              </div>
            ))}
            {wrongEmails.length > 0 && (
              <div
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  padding: "6px 12px",
                }}
              >
                <hr />
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "4px",
                    color: "#E63956",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => setShowWrongEmails(!showWrongEmails)}
                >
                  <span> View failed import ({wrongEmails.length}) </span>
                  <span>
                    {" "}
                    {showWrongEmails ? (
                      <Icon name="minus" color="generalError" />
                    ) : (
                      <Icon name="plus" color="generalError" />
                    )}{" "}
                  </span>
                </div>
                {showWrongEmails && (
                  <>
                    {wrongEmails.map((email, index) => (
                      <div
                        key={`wrong-email-${index}`}
                        style={{ color: "#7A79BE", marginTop: "4px" }}
                      >
                        {" "}
                        {email}{" "}
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="label">
          {!isQuestionnaire && "Email / Attestation Notes (Optional)"}
          {isQuestionnaire && "Email Notes (Optional)"}
        </div>
        <textarea ref={messageRef} placeholder="Type here" />
        <div className="submit-row">
          <button
            className={buttonEnabled}
            disabled={buttonDisabled}
            onClick={onSend}
          >
            Send
          </button>
          {!isQuestionnaire && (
            <div className="toggle-checkbox">
              <label className="switch">
                <span className="switch-text">Request Attestation</span>
                <input
                  type="checkbox"
                  checked={shouldAttest}
                  onChange={() => setShouldAttest(!shouldAttest)}
                />
                <span className="slider" />
              </label>
            </div>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}

export default observer(EmailAttest);
