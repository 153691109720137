import { faker } from "@faker-js/faker";

import type {
  UpdateTaskLink200,
  UpdateTaskLinkMutationRequest,
  UpdateTaskLinkMutationResponse,
  UpdateTaskLinkPathParams,
} from "../models/UpdateTaskLink";
import { createTaskLink } from "./createTaskLink";

export function createUpdateTaskLinkPathParams(): NonNullable<UpdateTaskLinkPathParams> {
  return { task_id: faker.number.int(), id: faker.number.int() };
}

/**
 * @description Task link updated
 */
export function createUpdateTaskLink200(): NonNullable<UpdateTaskLink200> {
  return { data: createTaskLink() };
}

export function createUpdateTaskLinkMutationRequest(): NonNullable<UpdateTaskLinkMutationRequest> {
  return createTaskLink();
}

/**
 * @description Task link updated
 */
export function createUpdateTaskLinkMutationResponse(): NonNullable<UpdateTaskLinkMutationResponse> {
  return { data: createTaskLink() };
}
