import { http } from "msw";

import { createGetQuestionnairesQueryResponse } from "../createGetQuestionnaires";

export const getQuestionnairesHandler = http.get(
  "*/workspaces/:workspace_id/questionnaires",
  function handler(info) {
    return new Response(
      JSON.stringify(createGetQuestionnairesQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
