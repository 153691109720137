import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListCommentsPathParams,
  ListCommentsQueryResponse,
} from "../../models/ListComments";

/**
 * @description List comments for a reference
 * @summary List comments
 * @link /:record_type/:record_id/comments
 */
export async function listComments(
  recordType: ListCommentsPathParams["record_type"],
  recordId: ListCommentsPathParams["record_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListCommentsQueryResponse>["data"]> {
  const res = await client<ListCommentsQueryResponse>({
    method: "get",
    url: `/${recordType}/${recordId}/comments`,
    ...options,
  });
  return res.data;
}
