import { http } from "msw";

import { createCountByDateMutationResponse } from "../createCountByDate";

export const countByDateHandler = http.put(
  "*/reports/count_by_date",
  function handler(info) {
    return new Response(JSON.stringify(createCountByDateMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
