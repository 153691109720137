import { faker } from "@faker-js/faker";

import type {
  ListSearchRecordVersions200,
  ListSearchRecordVersions403,
  ListSearchRecordVersionsPathParams,
  ListSearchRecordVersionsQueryParams,
  ListSearchRecordVersionsQueryResponse,
} from "../models/ListSearchRecordVersions";
import { createField } from "./createField";
import { createPaginatedMeta } from "./createPaginatedMeta";
import { createRecordVersion } from "./createRecordVersion";
import { createTableFilters } from "./createTableFilters";

export function createListSearchRecordVersionsPathParams(): NonNullable<ListSearchRecordVersionsPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createListSearchRecordVersionsQueryParams(): NonNullable<ListSearchRecordVersionsQueryParams> {
  return {
    table_filters: createTableFilters(),
    offset: faker.number.int(),
    limit: faker.number.int(),
    sort_direction: faker.helpers.arrayElement<any>(["ASC", "DESC"]),
    sort_field_name: faker.string.alpha(),
    search_key: faker.string.alpha(),
    search_fields_name: faker.helpers.arrayElements([
      faker.string.alpha(),
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createListSearchRecordVersions200(): NonNullable<ListSearchRecordVersions200> {
  return {
    data: {
      fields: faker.helpers.arrayElements([createField()]) as any,
      record_versions: faker.helpers.arrayElements([
        createRecordVersion(),
      ]) as any,
      module_workspace_id: faker.number.int(),
    },
    meta: createPaginatedMeta(),
  };
}

/**
 * @description Forbidden
 */
export function createListSearchRecordVersions403(): NonNullable<ListSearchRecordVersions403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createListSearchRecordVersionsQueryResponse(): NonNullable<ListSearchRecordVersionsQueryResponse> {
  return {
    data: {
      fields: faker.helpers.arrayElements([createField()]) as any,
      record_versions: faker.helpers.arrayElements([
        createRecordVersion(),
      ]) as any,
      module_workspace_id: faker.number.int(),
    },
    meta: createPaginatedMeta(),
  };
}
