import { faker } from "@faker-js/faker";

import type {
  DirectUpload200,
  DirectUploadMutationRequest,
  DirectUploadMutationResponse,
} from "../models/DirectUpload";

/**
 * @description Successful operation
 */
export function createDirectUpload200(): NonNullable<DirectUpload200> {
  return {
    id: faker.number.int(),
    key: faker.string.alpha(),
    filename: faker.string.alpha(),
    content_type: faker.string.alpha(),
    metadata: {},
    service_name: faker.string.alpha(),
    byte_size: faker.number.int(),
    checksum: faker.string.alpha(),
    created_at: faker.date.anytime().toISOString(),
    signed_id: faker.string.alpha(),
    attachable_sgid: faker.string.alpha(),
    direct_upload: { url: faker.string.alpha(), headers: {} },
  };
}

export function createDirectUploadMutationRequest(): NonNullable<DirectUploadMutationRequest> {
  return {
    blob: {
      filename: faker.string.alpha(),
      content_type: faker.string.alpha(),
      byte_size: faker.number.int(),
      checksum: faker.string.alpha(),
    },
  };
}

/**
 * @description Successful operation
 */
export function createDirectUploadMutationResponse(): NonNullable<DirectUploadMutationResponse> {
  return {
    id: faker.number.int(),
    key: faker.string.alpha(),
    filename: faker.string.alpha(),
    content_type: faker.string.alpha(),
    metadata: {},
    service_name: faker.string.alpha(),
    byte_size: faker.number.int(),
    checksum: faker.string.alpha(),
    created_at: faker.date.anytime().toISOString(),
    signed_id: faker.string.alpha(),
    attachable_sgid: faker.string.alpha(),
    direct_upload: { url: faker.string.alpha(), headers: {} },
  };
}
