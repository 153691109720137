import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { relatableTypeToRecordType } from "@/features/misc/helpers";

import type {
  CreateRecordLinkMutationResponse,
  RecordType,
  Relatable,
} from "../../gen";
import { createRecordLink } from "../../gen/axios/recordLinksController";

interface UseCreateRecordLinkParams {
  recordType: RecordType;
  recordId: number;
}

export function useCreateRecordLink<TError = Error, TContext = unknown>(
  { recordType, recordId }: UseCreateRecordLinkParams,
  {
    onSettled,
    ...options
  }: UseMutationOptions<
    CreateRecordLinkMutationResponse,
    TError,
    Relatable,
    TContext
  > = {},
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (record) =>
      createRecordLink(recordType, recordId, {
        record_link: {
          outward_record_id: record.id,
          outward_record_type: relatableTypeToRecordType(record.relatable_type),
        },
      }),
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: [recordType],
      });

      onSettled?.(...args);
    },
    ...options,
  });
}
