import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import trashIcon from "../../../images/table-image/icon/delete-icon.svg";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";

interface Props {
  controlRecordVersionID: number;
}

function OperationalControlsActions({ controlRecordVersionID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);

  // vars
  const { canDeleteRecords } = mainStore.userPermissions;
  const { selectedIDs: selectedRecordVersionsIDs } =
    mainStore.riskRegisters.operationalControlsData;
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionParamID } = useParams();

  // Functions
  function handleDeleteConfirm() {
    const newSelectedRecordsIDs = selectedRecordVersionsIDs.filter(
      (item) => item !== controlRecordVersionID,
    );
    handleSave(newSelectedRecordsIDs);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  function handleSave(ids) {
    mainStore.riskRegisters.setOperationalControlsData(ids);
    mainStore.riskRegisters.saveOperationalControls(recordVersionParamID);
  }

  return (
    <div
      className="list-points rr-library-table-row-actions"
      data-testid="rr-operational-controls-actions-container"
    >
      <Popup
        position="bottom right"
        open={isOpenModal}
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => (
          <button
            type="button"
            data-testid="rr-operational-controls-actions-delete-trigger"
            disabled={!canDeleteRecords}
          >
            <img src={trashIcon} alt="trash-icon" />
          </button>
        )}
        disabled={!canDeleteRecords}
        onOpen={() => setIsOpenModal(true)}
        onClose={() => setIsOpenModal(false)}
      >
        <div
          className="table-dropdown success-dropdown"
          data-testid="rr-operational-controls-actions-delete-popup"
        >
          <ConfirmationDialog
            heading="Delete Control Item"
            content="Are you sure you want to delete this control item? Actions are not reversible."
            handleConfirm={handleDeleteConfirm}
            handleReject={() => setIsOpenModal(false)}
          />
        </div>
      </Popup>
    </div>
  );
}

export default observer(OperationalControlsActions);
