import { faker } from "@faker-js/faker";

import type {
  ListOfCompanyControlMappingTypes200,
  ListOfCompanyControlMappingTypesPathParams,
  ListOfCompanyControlMappingTypesQueryResponse,
} from "../models/ListOfCompanyControlMappingTypes";
import { createControlMappingType } from "./createControlMappingType";

export function createListOfCompanyControlMappingTypesPathParams(): NonNullable<ListOfCompanyControlMappingTypesPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListOfCompanyControlMappingTypes200(): NonNullable<ListOfCompanyControlMappingTypes200> {
  return {
    data: faker.helpers.arrayElements([createControlMappingType()]) as any,
  };
}

/**
 * @description OK
 */
export function createListOfCompanyControlMappingTypesQueryResponse(): NonNullable<ListOfCompanyControlMappingTypesQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createControlMappingType()]) as any,
  };
}
