import { faker } from "@faker-js/faker";

import type {
  ControlMappingsDetails,
  ControlMappingsDetails200,
  ControlMappingsDetails400,
  ControlMappingsDetails403,
  ControlMappingsDetailsQueryResponse,
} from "../models/ControlMappingsDetails";

export function createControlMappingsDetails(
  data: NonNullable<Partial<ControlMappingsDetails>> = {},
): NonNullable<ControlMappingsDetails> {
  return {
    ...{
      module_workspace: {},
      departments: {},
      control_categories: {},
      control_mapping_types: {},
    },
    ...data,
  };
}

/**
 * @description OK
 */
export function createControlMappingsDetails200(): NonNullable<ControlMappingsDetails200> {
  return {
    data: faker.helpers.arrayElements([createControlMappingsDetails()]) as any,
  };
}

/**
 * @description Bad Request
 */
export function createControlMappingsDetails400(): NonNullable<ControlMappingsDetails400> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Forbidden
 */
export function createControlMappingsDetails403(): NonNullable<ControlMappingsDetails403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createControlMappingsDetailsQueryResponse(): NonNullable<ControlMappingsDetailsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createControlMappingsDetails()]) as any,
  };
}
