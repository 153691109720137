import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { PROJECTS_ROUTES } from "../routes";
import ArchivedProjects from "./ArchivedProjects";
import Project from "./Project";
import Projects from "./Projects";
import ProjectTasks from "./ProjectTasks";

export const PROJECT_ID_PARAM = "project_id";

export default function ProjectRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}${PROJECTS_ROUTES.archived}`}>
        <ArchivedProjects />
      </Route>
      <Route exact path={`${path}${PROJECTS_ROUTES.details}`}>
        <Project />
      </Route>
      <Route exact path={`${path}${PROJECTS_ROUTES.tasks}`}>
        <ProjectTasks />
      </Route>
      <Route exact path={path}>
        <Projects />
      </Route>
    </Switch>
  );
}
