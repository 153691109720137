import { http } from "msw";

import { createWebsiteMonitoringGroupAssetUpdateMutationResponse } from "../createWebsiteMonitoringGroupAssetUpdate";

export const websiteMonitoringGroupAssetUpdateHandler = http.put(
  "*/website_monitoring/:monitoring_group_asset_id/monitoring_group_asset_update",
  function handler(info) {
    return new Response(
      JSON.stringify(createWebsiteMonitoringGroupAssetUpdateMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
