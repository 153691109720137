import { faker } from "@faker-js/faker";

import type { PermissionSet } from "../models/PermissionSet";

export function createPermissionSet(
  data: NonNullable<Partial<PermissionSet>> = {},
): NonNullable<PermissionSet> {
  return {
    ...{
      company_id: faker.number.int(),
      data: {},
      description: faker.string.alpha(),
      id: faker.helpers.arrayElement<any>([
        faker.string.alpha(),
        faker.string.uuid(),
      ]),
      locked_modules: faker.datatype.boolean(),
      name: faker.string.alpha(),
      permission_type: faker.helpers.arrayElement<any>([
        "company",
        "workspace_module",
      ]),
      read_only: faker.datatype.boolean(),
    },
    ...data,
  };
}
