import { faker } from "@faker-js/faker";

import type { Answer } from "../models/Answer";

export function createAnswer(
  data: NonNullable<Partial<Answer>> = {},
): NonNullable<Answer> {
  return {
    ...{
      id: faker.number.int(),
      comment: faker.string.alpha(),
      value: faker.string.alpha(),
      is_completed: faker.datatype.boolean(),
      question_choice_ids: faker.helpers.arrayElements([undefined]) as any,
      answer_review: {},
      approved_by: faker.helpers.arrayElements([undefined]) as any,
      additional_files: faker.helpers.arrayElements([undefined]) as any,
      document_files: faker.helpers.arrayElements([undefined]) as any,
    },
    ...data,
  };
}
