import type { PropsWithChildren } from "react";
import React, { useState } from "react";

import MonitoringGroupResultDetail from "../components/MonitoringGroupResultInfo/MonitoringGroupResultDetail";
import { MonitoringSidebarContext } from "./monitoring-sidebar-context";

export function MonitoringSidebarProvider(props: PropsWithChildren) {
  const [activeId, setActiveId] = useState<number | undefined>();
  const value = {
    activeId,
    openSidebarWithId: (id: number) => setActiveId(id),
    closeSidebar: () => setActiveId(undefined),
  };

  return (
    <MonitoringSidebarContext.Provider value={value}>
      {props.children}
      {activeId && <MonitoringGroupResultDetail activeId={activeId} />}
    </MonitoringSidebarContext.Provider>
  );
}
