import { faker } from "@faker-js/faker";

import type { PartialRecordVersion } from "../models/PartialRecordVersion";
import { createApprovalConfig } from "./createApprovalConfig";
import { createApprovalStatus } from "./createApprovalStatus";
import { createAttachmentGroup } from "./createAttachmentGroup";
import { createContract } from "./createContract";
import { createPartialRecord } from "./createPartialRecord";
import { createRecordVersionData } from "./createRecordVersionData";
import { createUncompletedCommentsCount } from "./createUncompletedCommentsCount";

export function createPartialRecordVersion(
  data: NonNullable<Partial<PartialRecordVersion>> = {},
): NonNullable<PartialRecordVersion> {
  return {
    ...{
      approval_config: Object.assign({}, createApprovalConfig()),
      approval_status: Object.assign({}, createApprovalStatus()),
      attachment_groups: faker.helpers.arrayElements([
        createAttachmentGroup(),
      ]) as any,
      contracts: faker.helpers.arrayElements([createContract()]) as any,
      data: createRecordVersionData(),
      external_token: faker.string.alpha(),
      id: faker.number.int(),
      global_id: faker.string.alpha(),
      linked_controls: faker.datatype.boolean(),
      record_id: faker.number.int(),
      record: createPartialRecord(),
      table_id: faker.number.int(),
      uncompleted_comments_count: createUncompletedCommentsCount(),
      version: faker.number.int(),
    },
    ...data,
  };
}
