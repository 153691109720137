import { http } from "msw";

import { createControlMappingsEffectivenessQueryResponse } from "../createControlMappingsEffectiveness";

export const controlMappingsEffectivenessHandler = http.get(
  "*/module_workspaces/:module_workspace_id/control_mappings/effectiveness",
  function handler(info) {
    return new Response(
      JSON.stringify(createControlMappingsEffectivenessQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
