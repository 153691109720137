import { faker } from "@faker-js/faker";

import type {
  ListOfModuleAssessmentDocuments200,
  ListOfModuleAssessmentDocumentsPathParams,
  ListOfModuleAssessmentDocumentsQueryResponse,
} from "../models/ListOfModuleAssessmentDocuments";
import { createThemisDocument } from "./createThemisDocument";

export function createListOfModuleAssessmentDocumentsPathParams(): NonNullable<ListOfModuleAssessmentDocumentsPathParams> {
  return { module_assessment_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListOfModuleAssessmentDocuments200(): NonNullable<ListOfModuleAssessmentDocuments200> {
  return { data: faker.helpers.arrayElements([createThemisDocument()]) as any };
}

/**
 * @description OK
 */
export function createListOfModuleAssessmentDocumentsQueryResponse(): NonNullable<ListOfModuleAssessmentDocumentsQueryResponse> {
  return { data: faker.helpers.arrayElements([createThemisDocument()]) as any };
}
