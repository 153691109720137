import { http } from "msw";

import { createDeleteFindingMutationResponse } from "../createDeleteFinding";

export const deleteFindingHandler = http.delete(
  "*/findings/:id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteFindingMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
