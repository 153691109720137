import { MiniTag } from "@themis/ui";
import type { FieldValues } from "react-hook-form";

import type { FieldConfiguration } from "@/api";
import type { SelectFormFieldProps } from "@/components/form-fields/select-form-field/select-form-field";
import { SelectFormField } from "@/components/form-fields/select-form-field/select-form-field";
import { hashString } from "@/utils/hash-string";

export interface DynamicSelectFieldProps<T extends FieldValues>
  extends Omit<SelectFormFieldProps<T>, "items"> {
  options: FieldConfiguration["options"];
  multiselect?: boolean;
}

export const DynamicSelectField = <T extends FieldValues>({
  options,
  ...rest
}: DynamicSelectFieldProps<T>) => {
  if (!options?.length) {
    return null;
  }

  const items: SelectFormFieldProps<T>["items"] = options
    .sort((a, b) => (a.position ?? Infinity) - (b.position ?? Infinity))
    .map((option) => ({
      label: option.value,
      value: option.key,
    }));

  return (
    <SelectFormField
      items={items}
      renderSelected={(item) => (
        <MiniTag colorIndex={hashString(item.value ?? "")}>
          {item.label}
        </MiniTag>
      )}
      {...rest}
    />
  );
};
