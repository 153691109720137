export function getTaggedUserIds(input: string): number[] {
  const regexPattern = /@\[[^\]]*\]\(\d+\)/g;

  const matches = input.match(regexPattern) ?? [];

  const taggedUserIds = matches
    .map((match) => findFirstIntegerInParentheses(match))
    .filter(isNumber);

  return taggedUserIds;
}

function findFirstIntegerInParentheses(input: string): number | null {
  const regexPattern = /\((\d+)\)/;

  const match = input.match(regexPattern);

  return match ? parseInt(match[1], 10) : null;
}

function isNumber(value: unknown): value is number {
  return typeof value === "number";
}
