import debounce from "lodash/debounce";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import TextInput from "@/components/form/TextInput";
import { useMainStore } from "@/contexts/Store";

interface Props {
  handleAfterSelect: (name: string) => void;
  handleChange?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

function CompanyNameAutocompleteInput({
  inputRef,
  handleChange,
  handleAfterSelect,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const { suggestions } = mainStore.clearbit;

  // Functions
  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    mainStore.clearbit.fetchSuggestedLogos(value);
    handleChange?.();
  }
  const debouncedHandleNameChange = debounce(handleNameChange, 150);

  // @ts-expect-error TS(7006) FIXME: Parameter 'suggestion' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleSelectSuggestion(suggestion) {
    mainStore.clearbit.fetchLogo(suggestion.domain);
    handleAfterSelect(suggestion.name);
    onClose();
  }

  function onClose() {
    inputRef?.current?.blur();
    setShowPopup(false);
  }

  return (
    <Popup
      position="bottom center"
      keepTooltipInside
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => (
        <div tabIndex={1}>
          <TextInput
            name="company-name"
            datatestid="company-input"
            placeholder="Company Name"
            inputRef={inputRef}
            onChange={debouncedHandleNameChange}
          />
        </div>
      )}
      on="focus"
      // @ts-expect-error TS(2322) FIXME: Type 'number | false' is not assignable to type 'b... Remove this comment to see the full error message
      open={showPopup && suggestions?.length}
      onOpen={() => setShowPopup(true)}
      onClose={onClose}
    >
      <div
        className="vdd-logo-suggestions-popup-wrap"
        onMouseDown={(event) => event.preventDefault()}
      >
        {suggestions?.length ? (
          <div className="vdd-logo-suggestions-popup">
            {suggestions.map((suggestion, index) => (
              <div
                // @ts-expect-error TS(2339) FIXME: Property 'domail' does not exist on type 'never'.
                key={`${suggestion.domail}-${index}`}
                className="item"
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {/* @ts-expect-error TS(2339) FIXME: Property 'logo' does not exist on type 'never'. */}
                <img src={suggestion.logo} alt="logo" />
                {/* @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'. */}
                <span>{suggestion.name}</span>
                {/* @ts-expect-error TS(2339) FIXME: Property 'domain' does not exist on type 'never'. */}
                <p>{suggestion.domain}</p>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </Popup>
  );
}

export default observer(CompanyNameAutocompleteInput);
