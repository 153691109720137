import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateFieldMutationRequest,
  CreateFieldMutationResponse,
  CreateFieldPathParams,
} from "../../models/CreateField";

/**
 * @description Create a new field for a specific record type
 * @summary Create a new field
 * @link /companies/:company_id/:record_type/fields
 */
export async function createField(
  companyId: CreateFieldPathParams["company_id"],
  recordType: CreateFieldPathParams["record_type"],
  data: CreateFieldMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateFieldMutationResponse>["data"]> {
  const res = await client<
    CreateFieldMutationResponse,
    CreateFieldMutationRequest
  >({
    method: "post",
    url: `/companies/${companyId}/${recordType}/fields`,
    data,
    ...options,
  });
  return res.data;
}
