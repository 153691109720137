import { faker } from "@faker-js/faker";

import type {
  IssuesReport201,
  IssuesReport403,
  IssuesReportMutationResponse,
  IssuesReportPathParams,
  IssuesReportQueryParams,
} from "../models/IssuesReport";
import { createFile } from "./createFile";
import { createTableFilters } from "./createTableFilters";

export function createIssuesReportPathParams(): NonNullable<IssuesReportPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createIssuesReportQueryParams(): NonNullable<IssuesReportQueryParams> {
  return {
    filters: createTableFilters(),
    file_format: faker.helpers.arrayElement<any>(["csv", "xlsx"]),
  };
}

/**
 * @description Blob for report is created
 */
export function createIssuesReport201(): NonNullable<IssuesReport201> {
  return { data: createFile() };
}

/**
 * @description Forbidden
 */
export function createIssuesReport403(): NonNullable<IssuesReport403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Blob for report is created
 */
export function createIssuesReportMutationResponse(): NonNullable<IssuesReportMutationResponse> {
  return { data: createFile() };
}
