import { action, makeObservable, observable } from "mobx";

import { searchService } from "@/api/gen/axios";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class DocumentsStore implements ModuleStore {
  mainStore;

  // Observable objects
  data = {};
  folderID = null;
  historical = [];
  page = 1;
  count = 0;
  // @ts-expect-error TS(7008) FIXME: Member 'controller' implicitly has an 'any' type.
  controller;

  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  constructor(mainStore) {
    makeObservable(this, {
      data: observable,
      folderID: observable,
      historical: observable,
      setData: action,
      setFolderID: action,
      setCount: action,
      setHistorical: action,
      page: observable,
    });
    this.mainStore = mainStore;
  }

  // GET /api/react/documents
  async index({
    sortParams,
    folderID,
    tableFilters = [],
    searchParams,
  }: IndexParams) {
    // Sorting doesnt have access to folderID
    if (!sortParams) {
      this.setFolderID(folderID);
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        search_key: searchParams?.key,
        search_fields_name: searchParams?.fields_name,
        section_tag_id: this.folderID || null,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/documents`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // Handle canceled requests
      if (!response.data) {
        return;
      }

      this.setData(response.data);
      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"Documents#Index for Company" error ${error}`);
    }
  }

  // @route GET /api/frontend/workspaces/:workspace_id/searches
  async search({
    sortParams,
    tableFilters = [],
    searchParams,
    workspaceID,
    page = 1,
  }: IndexParams) {
    if (!workspaceID) {
      window.console.warn(
        `Documents#searches => workspaceID(${workspaceID}) should be present!`,
      );
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        search_key: searchParams?.key,
        search_fields_name: searchParams?.fields_name,
        page,
      };

      const response = await searchService.listSearchRecordVersions(
        workspaceID,
        params,
        {
          signal: controller.signal,
        },
      );

      this.setPage(page);
      this.setData(response.data);
      this.setCount(response.meta.total_count || 0);
      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"Documents#searches" error ${error}`);
    }
  }

  // POST /api/react/documents
  async create(params = {}) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/documents`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      return data;
    } catch (error) {
      window.console.log(`"Documents#create for Company" error ${error}`);
    }
  }

  // DELETE /api/react/documents/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/documents/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
      } else {
        this.mainStore.toast.setInfoText(response.data?.message);
      }
    } catch (error) {
      window.console.log(`"Documents#delete" error ${error}`);
    }
  }

  // POST /api/react/documents/:id/approve
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async approve(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/documents/${recordVersionID}/approve`,
        headers: this.mainStore.getHeaders(),
      });

      // attempt to fix no rerendering
      this.mainStore.reviews.setCurrent(response.data.approval);
    } catch (error) {
      window.console.log(`"Documents#approve" error ${error}`);
    }
  }

  // DELETE /api/react/documents/:id/unapprove
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unapprove(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/documents/${recordVersionID}/unapprove`,
        headers: this.mainStore.getHeaders(),
      });

      // attempt to fix no rerendering
      this.mainStore.reviews.setCurrent(response.data.approval);
    } catch (error) {
      window.console.log(`"Documents#unapprove" error ${error}`);
    }
  }

  // POST /api/react/documents/:id/finalize
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/documents/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      this.mainStore.toast.setInfoText("Document has been published");
    } catch (error) {
      window.console.log(`"Documents#finalize" error ${error}`);
    }
  }

  // POST /api/react/documents/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/documents/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
      return data;
    } catch (error) {
      window.console.log(`"Documents#unlock" error ${error}`);
    }
  }

  // POST /api/react/documents/:id/archive
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async archive(recordVersionID) {
    const params = { identifier: "documents" };

    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/documents/${recordVersionID}/archive`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return data;
    } catch (error) {
      window.console.log(`"Documents#archive" error ${error}`);
    }
  }

  // GET /api/react/documents/archived
  async archivedList() {
    const params = { identifier: "documents" };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/documents/archived`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return response.data;
    } catch (error) {
      window.console.log(`"Documents#archivedList" error ${error}`);
    }
  }

  // GET /api/react/documents/:id/historical
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async historicalList(recordVersionID) {
    const params = { identifier: "documents" };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/documents/${recordVersionID}/historical`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setHistorical(response.data.record_versions);
    } catch (error) {
      this.setHistorical([]);
      window.console.log(`"Documents#historicalList" error ${error}`);
    }
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'folderID' implicitly has an 'any'... Remove this comment to see the full error message
  async move({ folderID, recordVersionID }) {
    if (!recordVersionID) {
      return;
    }

    const data = {
      section_tag_id: folderID,
      identifier: "documents",
    };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/documents/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.deleteByRecordVersionID(recordVersionID);
    } catch (error) {
      window.console.log(`"Documents#move" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setFolderID(value) {
    if (value) {
      this.folderID = value;
    } else {
      this.folderID = null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  deleteByRecordVersionID(recordVersionID) {
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.data.record_versions = this.data.record_versions.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
      (recordVersion) => recordVersion.id !== recordVersionID,
    );
    this.mainStore.recordVersions.deleteRecordVersionByID(recordVersionID);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setHistorical(value) {
    if (value) {
      this.historical = value;
    } else {
      this.historical = [];
    }
  }

  setPage(page: number) {
    this.page = page;
  }

  setCount(count: number) {
    this.count = count;
  }

  // Store Helpers
  cleanup() {
    this.setData({});
    this.setFolderID(null);
    this.setPage(1);
    this.setCount(0);
    this.setHistorical([]);
  }

  abort() {
    this.controller?.abort();
  }
}
