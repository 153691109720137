import { faker } from "@faker-js/faker";

import type { ThemisRecord } from "../models/ThemisRecord";
import { createRecordLink } from "./createRecordLink";

export function createThemisRecord(
  data: NonNullable<Partial<ThemisRecord>> = {},
): NonNullable<ThemisRecord> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      workspace_id: faker.number.int(),
      name: faker.string.alpha(),
      status: faker.string.alpha(),
      owner_id: faker.number.int(),
      created_by_id: faker.number.int(),
      created_at: faker.date.anytime().toISOString(),
      last_updated_by_id: faker.number.int(),
      last_updated_at: faker.date.anytime().toISOString(),
      custom_fields: {},
      record_links_from: faker.helpers.arrayElements([
        createRecordLink(),
      ]) as any,
      record_links_to: faker.helpers.arrayElements([createRecordLink()]) as any,
    },
    ...data,
  };
}
