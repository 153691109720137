import { AddNewRowLink, Table } from "@themis/ui";
import { useIntl } from "react-intl";
import { useParams, useRouteMatch } from "react-router-dom";

import type { Account } from "@/api/gen/models";
import { CommentsSidebar } from "@/components/comments-sidebar/comments-sidebar";
import Loading from "@/components/Loading";

import { ACCOUNT_DETAILS_PATH } from "../../accounts-routes";
import { useAccountsTableColumns } from "../../utils/use-accounts-table-columns";

export function ChildAccounts({ childAccounts }: { childAccounts: Account[] }) {
  const { formatMessage } = useIntl();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const { url } = useRouteMatch();

  const { columns, isColumnsPending } = useAccountsTableColumns({
    workspaceId: Number(workspace_id),
  });

  if (isColumnsPending) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <div>
      <CommentsSidebar
        recordType="accounts"
        detailsPagePath={ACCOUNT_DETAILS_PATH}
      />
      <Table columns={columns} rows={childAccounts} />
      <AddNewRowLink
        className="tw-mt-1"
        title={formatMessage({
          defaultMessage: "Add Account",
          description:
            "Add button that is shown under the child accounts table",
        })}
        to={`${url}/new`}
      />
    </div>
  );
}
