import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteCommentMutationResponse,
  DeleteCommentPathParams,
} from "../../models/DeleteComment";

/**
 * @description Delete a comment
 * @summary Delete a comment
 * @link /:record_type/:record_id/comments/:id
 */
export async function deleteComment(
  recordType: DeleteCommentPathParams["record_type"],
  recordId: DeleteCommentPathParams["record_id"],
  id: DeleteCommentPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteCommentMutationResponse>["data"]> {
  const res = await client<DeleteCommentMutationResponse>({
    method: "delete",
    url: `/${recordType}/${recordId}/comments/${id}`,
    ...options,
  });
  return res.data;
}
