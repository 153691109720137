import { cn } from "@themis/ui";
import { observer } from "mobx-react";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Mention, MentionsInput } from "react-mentions";

import type { Comment as CommentType, ListCommentsPathParams } from "@/api";
import { useCreateComment } from "@/api/queries/comments/useCreateComment";
import { useUsers } from "@/api/queries/users/use-users";
import { useScrollToSelectedCommentRef } from "@/components/settings/notificationsCenter/tasks/hooks/useScrollToSelectedCommentRef";
import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useMainStore } from "@/contexts/Store";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { useClickAwayListener } from "@/hooks/useClickAwayListener";
import { useSearchParams } from "@/hooks/useSearchParams";

import { Reply } from "../reply/reply";
import { UserMention } from "../user-mention/user-mention";
import { CommentContent } from "./comment-content";
import { CommentHeader } from "./comment-header";

interface CommentProps {
  comment: CommentType;
  detailsPagePath: string;
  recordType: ListCommentsPathParams["record_type"];
  recordId: ListCommentsPathParams["record_id"];
  replies: CommentType[];
}

function Comment({
  comment,
  detailsPagePath,
  recordType,
  recordId,
  replies,
}: CommentProps) {
  const mainStore = useMainStore();

  const { formatMessage } = useIntl();

  const workspaceId = useRouteWorkspaceId();
  const { data: users = [] } = useUsers(workspaceId);

  const [searchParams, setSearchParams] = useSearchParams<{
    comments_tab?: CommentTab;
    comment_id?: string;
  }>();

  const commentWrapper = useRef(null);

  const [replyInput, setReplyInput] = useState("");

  const selectedCommentRef = useScrollToSelectedCommentRef(
    mainStore.comments.privateComments,
  );

  const handleClickAway = () => {
    setSearchParams({ ...searchParams, comment_id: undefined });
  };

  const selectedCommentId = Number(searchParams.comment_id);
  const mainCommentActive = selectedCommentId === comment?.id;

  const [isEditingMainComment, setIsEditingMainComment] = useState(false);

  const createComment = useCreateComment(
    {
      recordType,
      recordId,
    },
    {
      onSuccess: () => {
        setReplyInput("");
      },
    },
  );

  useClickAwayListener(
    commentWrapper,
    mainCommentActive ? handleClickAway : undefined,
  );

  const handleCreateComment = () =>
    createComment.mutate({
      comment: { content: replyInput, parent_id: comment.id },
    });

  if (!comment) {
    return null;
  }

  return (
    <div ref={commentWrapper}>
      <div data-testid="comment">
        <div
          ref={mainCommentActive ? selectedCommentRef : undefined}
          className={cn(
            "tw-px-5 tw-py-2.5",
            mainCommentActive && "tw-bg-lightBlue-2/20",
          )}
        >
          <CommentHeader
            comment={comment}
            detailsPagePath={detailsPagePath}
            recordType={recordType}
            recordId={recordId}
            onStartEditing={() => setIsEditingMainComment(true)}
          />
          {comment.resolved ? (
            <MentionsInput
              disabled
              value={comment.content}
              className="tw-w-full tw-bg-transparent tw-p-0"
              style={{
                input: {
                  border: "none",
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  fontWeight: "600",
                  padding: 4,
                  color: "var(--color-neutral-600)",
                },
                control: {
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  fontWeight: "600",
                },
                highlighter: {
                  zIndex: 1,
                  pointerEvents: "none",
                  padding: 4,
                },
              }}
            >
              <Mention trigger="@" data={[]} />
            </MentionsInput>
          ) : (
            <>
              <CommentContent
                comment={comment}
                editing={isEditingMainComment}
                onStopEditing={() => setIsEditingMainComment(false)}
                recordType={recordType}
                recordId={recordId}
              />
              {replies.map((reply) => (
                <Reply
                  key={reply.id}
                  detailsPagePath={detailsPagePath}
                  reply={reply}
                  recordType={recordType}
                  recordId={recordId}
                  selectedCommentRef={selectedCommentRef}
                />
              ))}
              <UserMention
                commentInput={replyInput}
                disableSubmit={createComment.isPending}
                onChangeCommentInput={setReplyInput}
                onSubmit={handleCreateComment}
                users={users}
                placeholder={formatMessage({
                  defaultMessage: "Reply",
                })}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(Comment);
