import classNames from "classnames";
import { groupBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { useHistory, useParams } from "react-router-dom";

import { IconButton } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

import ChecklistDefaultQuestion from "./ChecklistDefaultQuestion";
import ChecklistExcelExport from "./ChecklistExcelExport";
import ChecklistItem from "./ChecklistItem";

const VDD_TYPE = "VDD";

interface Props {
  editingMode?: boolean;
  isTemplate?: boolean;
  type?: string;
  usingMode?: boolean;
}

function BuildChecklist({ editingMode, isTemplate, usingMode, type }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [checklistName, setChecklistName] = useState("");
  const [checklistQuestions, setChecklistQuestions] = useState([]);
  const [checklistParentID, setChecklistParentID] = useState("");
  const [checklistShared, setChecklistShared] = useState(false);

  // Variables
  const isVDD = type === VDD_TYPE;
  const store = isVDD ? mainStore.vendors : mainStore.changeManagements;
  // @ts-expect-error TS(2339) FIXME: Property 'record_id' does not exist on type '{}'.
  const { record_id, checklist_id } = useParams();
  const { active_workspace: workspace } = mainStore.users.user;
  const { workspaceID } = mainStore.context;
  const { isCurrentWorkspaceActive, isCurrentWorkspaceArchived } =
    mainStore.workspaces;
  const { defaultQuestions } = store;
  const groupedDefaultQuestions = Object.entries(
    groupBy(defaultQuestions, "category"),
  );
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const { module_workspace_id } = store.data || {};
  const isSubmitEnabled =
    checklistName.length &&
    checklistQuestions.length &&
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
    checklistQuestions.every((item) => item.name !== "");
  const history = useHistory();
  const disableEditExistingQuestions = isVDD && editingMode && checklistShared;

  // D&D
  const [{ droppableActive }, drop] = useDrop(() => ({
    accept: "question",
    drop: () => ({
      name: "checklist-items",
    }),
    collect: (monitor) => ({
      droppableActive: monitor.isOver() && monitor.canDrop(),
    }),
  }));

  // Effects
  useEffect(() => {
    if (!workspace?.id) {
      return;
    }

    store.index({ workspaceID: workspace.id });
  }, [workspace]);

  useEffect(() => {
    if (!module_workspace_id) {
      return;
    }

    store.fetchQuestionsWithCategory(module_workspace_id);
  }, [module_workspace_id]);

  useEffect(() => {
    setChecklsits();
  }, [checklist_id, module_workspace_id]);

  // Functions
  async function setChecklsits() {
    if (!editingMode || !checklist_id || !module_workspace_id) {
      return;
    }

    const checklist = await store.fetchChecklist(
      module_workspace_id,
      checklist_id,
    );
    if (!checklist) {
      return;
    }

    setChecklistName(checklist.name);
    setChecklistQuestions(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      checklist.checklist_questions.map((item) => ({
        ...item[isVDD ? "vendor_question" : "new_product_question"],
        listID: item.order,
      })),
    );
    if (isVDD) {
      setChecklistParentID(checklist.vendor_id);
    }
    setChecklistShared(checklist.shared);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleChecklistNameChange(e) {
    const { value } = e.target;
    setChecklistName(value);
  }

  function generateNewListID() {
    const maxID = checklistQuestions.length
      ? // @ts-expect-error TS(2339) FIXME: Property 'listID' does not exist on type 'never'.
        Math.max(...checklistQuestions.map((item) => item.listID))
      : 0;
    return maxID + 1;
  }

  function handleAddNewChecklistQuestion() {
    setChecklistQuestions([
      // @ts-expect-error TS(2322) FIXME: Type '{ name: string; listID: number; }' is not as... Remove this comment to see the full error message
      ...checklistQuestions,
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
      { name: "", listID: generateNewListID() },
    ]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'questions' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleAddNewChecklistQuestions(questions) {
    const newId = generateNewListID();
    // @ts-expect-error TS(7006) FIXME: Parameter 'question' implicitly has an 'any' type.
    questions.forEach((question, index) => {
      // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
      checklistQuestions.push({ name: question, listID: newId + index });
    });
    setChecklistQuestions([...checklistQuestions]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'question' implicitly has an 'any' type.
  function handleAddDefaultQuestionToChecklist(question) {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
    if (checklistQuestions.some((item) => item.id === question.id)) {
      return;
    }
    question.listID = generateNewListID();
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    setChecklistQuestions([...checklistQuestions, question]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
  function handleRemoveChecklistQuestion(index) {
    setChecklistQuestions(checklistQuestions.filter((_, i) => i !== index));
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleChangeChecklistQuestion(e, index) {
    const { value } = e.target;
    setChecklistQuestions(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      checklistQuestions.map((item, i) =>
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        i === index ? { ...item, name: value } : item,
      ),
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'dragIndex' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleMoveItem(dragIndex, hoverIndex) {
    if (!(Number(dragIndex) > -1) || !(Number(hoverIndex) > -1)) {
      return;
    }

    setChecklistQuestions((prevChecklistQuestions) => {
      if (hoverIndex < dragIndex) {
        return [
          ...prevChecklistQuestions.slice(0, hoverIndex),
          prevChecklistQuestions[dragIndex],
          ...prevChecklistQuestions.slice(hoverIndex, dragIndex),
          ...prevChecklistQuestions.slice(dragIndex + 1),
        ];
      }

      return [
        ...prevChecklistQuestions.slice(0, dragIndex),
        ...prevChecklistQuestions.slice(dragIndex + 1, hoverIndex + 1),
        prevChecklistQuestions[dragIndex],
        ...prevChecklistQuestions.slice(hoverIndex + 1),
      ];
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  async function handleContributeQuestion(e, category) {
    e.preventDefault();
    const { contribute } = e.target;

    const data = {
      name: contribute?.value,
      category,
    };

    await store.createQuestion(module_workspace_id, data);
    if (contribute) {
      contribute.value = null;
    }
  }

  function generateSubmitData() {
    const questions = [...checklistQuestions].map((item, index) => ({
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...item,
      order: index,
    }));

    return {
      name: checklistName,
      questions,
    };
  }

  async function handleAfterSubmit() {
    await store.index({ workspaceID: workspace?.id });
    if (isVDD) {
      if (isTemplate) {
        history.push(
          `/workspaces/${workspaceID}/modules/vendor-due-diligence/templates`,
        );
      } else {
        history.push(
          `/workspaces/${workspaceID}/modules/vendor-due-diligence/${
            record_id || checklistParentID
          }/questionnaires`,
        );
      }
    } else {
      history.push(`/workspaces/${workspaceID}/modules/change-management`);
    }
  }

  function checkSubmitEnabled() {
    if (!isSubmitEnabled) {
      const toastMsgText =
        checklistName === ""
          ? "Please fill in the list title before submitting."
          : "Please fill in all list items before submitting.";
      mainStore.toast.setErrorText(toastMsgText);
      return false;
    }
    mainStore.toast.setInfoText(
      "Please wait... It may take a few seconds to save your changes.",
    );
    return true;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'shouldAssignToRecord' implicitly has an... Remove this comment to see the full error message
  async function handleCreateSubmit(shouldAssignToRecord) {
    if (!checkSubmitEnabled()) {
      return;
    }
    const data = generateSubmitData();
    if (shouldAssignToRecord) {
      // @ts-expect-error TS(2339) FIXME: Property 'record_id' does not exist on type '{ nam... Remove this comment to see the full error message
      data.record_id = record_id;
    }

    await store.createChecklist(module_workspace_id, data);
    handleAfterSubmit();
  }

  async function handleUpdateSubmit() {
    if (!checkSubmitEnabled()) {
      return;
    }
    const data = generateSubmitData();

    await store.updateChecklist(
      module_workspace_id,
      Number(checklist_id),
      data,
    );
    handleAfterSubmit();
  }

  // Elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'category' implicitly has an 'any' type.
  const renderContributeQuestion = (category) => (
    <form
      className="contribute-question"
      onSubmit={(e) => handleContributeQuestion(e, category)}
    >
      <input
        type="text"
        name="contribute"
        placeholder="Contribute a Question"
      />
      <IconButton
        transparent
        icon="plus"
        buttonType="submit"
        data-testid="contribute-question"
      />
    </form>
  );

  const renderDefaultQuestions = groupedDefaultQuestions.map((group) => {
    const [category, questions] = group;
    const isGeneralGroup = category === "All";

    return (
      <div
        key={category}
        className="questions-block"
        data-testid="checklist-questions-section"
      >
        <h4>{`${isGeneralGroup ? "General" : category} Questions`}</h4>
        {renderContributeQuestion(category)}
        {isGeneralGroup && (
          <p className="questions-subheading">Available to all departments</p>
        )}
        {questions.map((question, index) => (
          <ChecklistDefaultQuestion
            key={index}
            question={question}
            handleAddQuestion={handleAddDefaultQuestionToChecklist}
            checklistQuestions={checklistQuestions}
          />
        ))}
      </div>
    );
  });

  const renderChecklistNameInput = (
    <input
      data-testid="checklist-name-input"
      type="text"
      name="name"
      className="checklist-name-input"
      placeholder={
        isVDD
          ? `Enter ${isTemplate ? "Template" : "Questionnaire"} Name`
          : "Enter Checklist name, try “Finance onboarding checklist”"
      }
      value={checklistName}
      onChange={handleChecklistNameChange}
    />
  );

  const renderEmptyChecklistItems = (
    <div className="checklist-items-empty" data-testid="checklist-list-empty">
      <button
        type="button"
        onClick={handleAddNewChecklistQuestion}
        data-testid="checklist-add-new-item"
      >
        Add {isVDD ? "Question" : "Checklist Item"}
      </button>
      <ChecklistExcelExport
        handleAddNewChecklistQuestions={handleAddNewChecklistQuestions}
        moduleWorkspaceID={module_workspace_id}
      />
      <p>You have 0 {isVDD ? "questions" : "checklist items"}</p>
      <div className="answer-type-text">User&apos;s answer type: Yes / No</div>
    </div>
  );

  const renderSubmitBlock = (
    <div className="submit-container">
      {!editingMode && (
        <>
          {!isVDD && (
            <>
              <button
                type="button"
                className={classNames("btn-template", {
                  disabled: !isSubmitEnabled,
                })}
                onClick={() => handleCreateSubmit(true)}
                data-testid="checklist-submit"
              >
                Create Checklist
              </button>
              <button
                type="button"
                className={classNames("btn-template", {
                  disabled: !isSubmitEnabled,
                })}
                onClick={() => handleCreateSubmit(false)}
                data-testid="checklist-submit-template"
              >
                Save as Template
              </button>
            </>
          )}
          {isVDD && (
            <button
              type="button"
              className={classNames("btn-template", {
                disabled: !isSubmitEnabled,
              })}
              onClick={() => handleCreateSubmit(!isTemplate)}
              data-testid="checklist-submit-template"
            >
              {isTemplate ? "Save Template" : "Create Questionnaire"}
            </button>
          )}
        </>
      )}
      {editingMode && !isTemplate && (
        <>
          {!isVDD && (
            <>
              <button
                type="button"
                className={classNames("btn-template", {
                  disabled: !isSubmitEnabled,
                })}
                onClick={handleUpdateSubmit}
                data-testid="checklist-submit-update"
              >
                Update Checklist
              </button>
              <button
                type="button"
                className={classNames("btn-template", {
                  disabled: !isSubmitEnabled,
                })}
                onClick={() => handleCreateSubmit(false)}
                data-testid="checklist-submit-template"
              >
                Save as Template
              </button>
            </>
          )}
          {isVDD && !usingMode && (
            <button
              type="button"
              className={classNames("btn-template", {
                disabled: !isSubmitEnabled,
              })}
              onClick={handleUpdateSubmit}
              data-testid="checklist-submit-template"
            >
              Update Questionnaire
            </button>
          )}
          {isVDD && usingMode && (
            <button
              type="button"
              className={classNames("btn-template", {
                disabled: !isSubmitEnabled,
              })}
              onClick={() => handleCreateSubmit(true)}
              data-testid="checklist-submit-update-template"
            >
              Save as Questionnaire
            </button>
          )}
        </>
      )}
      {editingMode && isTemplate && (
        <button
          type="button"
          className={classNames("btn-template", { disabled: !isSubmitEnabled })}
          onClick={handleUpdateSubmit}
          data-testid="checklist-submit-update-template"
        >
          Update Template
        </button>
      )}
    </div>
  );

  const renderChecklistItems = (
    <div className="checklist-items-container" data-testid="checklist-list">
      <p>
        You have {checklistQuestions.length}{" "}
        {isVDD ? "questions" : "checklist items"}
      </p>
      <div className="checklist-items-list">
        {checklistQuestions.map((checklistQuestion, index) => (
          <ChecklistItem
            // @ts-expect-error TS(2339) FIXME: Property 'listID' does not exist on type 'never'.
            key={checklistQuestion.listID}
            item={checklistQuestion}
            index={index}
            disabled={
              isCurrentWorkspaceArchived ||
              // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
              (disableEditExistingQuestions && checklistQuestion.id)
            }
            handleMoveItem={handleMoveItem}
            handleChange={(e) => handleChangeChecklistQuestion(e, index)}
            handleRemove={() => handleRemoveChecklistQuestion(index)}
          />
        ))}
      </div>
      <div className="drop-placeholder" />
      {isCurrentWorkspaceActive && (
        <>
          <div className="add-import-buttons-container">
            <button
              type="button"
              className="add-checklist-question"
              onClick={handleAddNewChecklistQuestion}
              data-testid="checklist-add-another-item"
            >
              Add {isVDD ? "Question" : "Checklist Item"} +
            </button>
            <ChecklistExcelExport
              handleAddNewChecklistQuestions={handleAddNewChecklistQuestions}
              moduleWorkspaceID={module_workspace_id}
            />
          </div>
          {renderSubmitBlock}
        </>
      )}
    </div>
  );

  return (
    <section
      className="create-checklist"
      data-testid="checklist-create-section"
    >
      <div
        className="questions-container"
        data-testid="checklist-create-questions"
      >
        <p className="questions-help">
          Drag and drop any applicable questions to the right canvas to build
          your own checklist
        </p>
        {renderDefaultQuestions}
      </div>
      <div className="checklist-container" data-testid="checklist-create-list">
        {renderChecklistNameInput}
        <div
          className={classNames("checklist-content", {
            "droppable-active": droppableActive,
          })}
          ref={drop}
          data-testid="checklist-create-drop"
        >
          {checklistQuestions.length === 0 && isCurrentWorkspaceActive
            ? renderEmptyChecklistItems
            : renderChecklistItems}
        </div>
      </div>
    </section>
  );
}

BuildChecklist.defaultProps = {
  editingMode: false,
  isTemplate: false,
  usingMode: false,
  type: "ChangeManagement",
};

export default observer(BuildChecklist);
