import classNames from "classnames";
import React from "react";

import type { IconSize } from "../Icon/icon";
import Icon from "../Icon/icon";

export interface AvatarProps {
  className?: string;
  size?: IconSize;
  status?: string;
  value: React.ReactNode;
}

const Avatar: React.FC<AvatarProps> = ({
  className = "",
  size = "de",
  status = "default",
  value,
  ...rest
}) => (
  <div
    className={classNames(
      "themisAvatar",
      `themisAvatar__${size}`,
      `themisAvatar__${status}`,
      className,
    )}
    {...rest}
  >
    {status === "deleted" ? (
      <Icon name="block" size={size} color="generalDarkGray" />
    ) : (
      value
    )}
  </div>
);

Avatar.displayName = "Avatar";

export default Avatar;
