import { matchPath } from "react-router-dom";

export function pathMatchesPattern(pathname: string, pattern: string) {
  const match = matchPath(pathname, {
    path: pattern,
    exact: false,
    strict: false,
  });

  return match;
}

export function patternToRoute(
  pattern: string,
  options: { [key: string]: string | number | null },
): string {
  return Object.entries(options).reduce(
    (route, [key, value]) => route.replace(`:${key}`, `${value}`),
    pattern,
  );
}

export function routeWorkspaceID(pathname: string): string | null {
  if (!pathname.startsWith("/workspaces/")) {
    return null;
  }

  const workspace_id = pathname.split("/")?.[2];
  if (workspace_id) {
    return workspace_id;
  }

  return null;
}

export function pathWithoutWorkspacePrefix(pathname: string): string {
  if (!pathname.startsWith("/workspaces/")) {
    return pathname;
  }

  let route = `${pathname}`;
  route = route.substring(route.indexOf("/") + 1); // first "/"
  route = route.substring(route.indexOf("/") + 1); // "workspaces/"
  route = route.substring(route.indexOf("/")); // ":workspace_id" without trailing slash

  return route;
}
