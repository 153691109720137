import { http } from "msw";

import { createGetExportedFileQueryResponse } from "../createGetExportedFile";

export const getExportedFileHandler = http.get(
  "*/exported_files/:exported_file_id",
  function handler(info) {
    return new Response(JSON.stringify(createGetExportedFileQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
