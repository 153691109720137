var GRID_CELL_CLASSNAME = "ag-cell";
function getAllFocusableElementsOf(el) {
    return Array.from(el.querySelectorAll("button, input, a, [href], select, textarea")).filter(function (focusableEl) { return focusableEl.tabIndex !== -1; });
}
function getEventPath(event) {
    var path = [];
    var currentTarget = event.target;
    while (currentTarget) {
        path.push(currentTarget);
        currentTarget = currentTarget.parentElement;
    }
    return path;
}
/**
 * Capture whether the user is tabbing forwards or backwards and suppress keyboard event if tabbing
 * outside of the children
 */
export function suppressKeyboardEvent(_a) {
    var _b, _c;
    var event = _a.event;
    var key = event.key, shiftKey = event.shiftKey;
    var path = getEventPath(event);
    var isTabForward = key === "Tab" && shiftKey === false;
    var isTabBackward = key === "Tab" && shiftKey === true;
    var spaceKey = key === " ";
    var clickableElements = ["button", "a"];
    var activeElement = document.activeElement;
    var suppressEvent = false;
    // Ignore space key events on clickable elements
    if (spaceKey &&
        clickableElements.includes((activeElement === null || activeElement === void 0 ? void 0 : activeElement.tagName.toLowerCase()) || "")) {
        event.preventDefault();
    }
    // Handle cell children tabbing
    if (isTabForward || isTabBackward) {
        var eGridCell = path.find(function (el) {
            if (el.classList === undefined) {
                return false;
            }
            return el.classList.contains(GRID_CELL_CLASSNAME);
        });
        if (!eGridCell) {
            return suppressEvent;
        }
        var focusableChildrenElements = getAllFocusableElementsOf(eGridCell);
        var lastCellChildEl = focusableChildrenElements[focusableChildrenElements.length - 1];
        var firstCellChildEl = focusableChildrenElements[0];
        // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
        if (focusableChildrenElements.length === 0) {
            return false;
        }
        var currentIndex = focusableChildrenElements.indexOf(activeElement);
        if (isTabForward) {
            var isLastChildFocused = lastCellChildEl && activeElement === lastCellChildEl;
            if (!isLastChildFocused) {
                suppressEvent = true;
                if (currentIndex !== -1 || activeElement === eGridCell) {
                    event.preventDefault();
                    // If the identifier cell is not in edit mode, allow tabbing to the next cell
                    if (((_b = activeElement === null || activeElement === void 0 ? void 0 : activeElement.children[0]) === null || _b === void 0 ? void 0 : _b.id) === "identifier-cell" &&
                        ((_c = focusableChildrenElements[1]) === null || _c === void 0 ? void 0 : _c.classList.contains("tw-hidden"))) {
                        suppressEvent = false;
                    }
                    // If the identifier cell is in edit mode, allow tabbing to the button
                    if (lastCellChildEl &&
                        (activeElement === null || activeElement === void 0 ? void 0 : activeElement.id) === "identifier-cell-text-input") {
                        event.stopPropagation();
                        focusableChildrenElements[1].focus();
                    }
                    else {
                        focusableChildrenElements[currentIndex + 1].focus();
                    }
                }
            }
            else if ((activeElement === null || activeElement === void 0 ? void 0 : activeElement.id) === "identifier-cell-button-edit") {
                suppressEvent = true;
            }
        }
        // Suppress keyboard event if tabbing backwards within the cell, and the current focused element is not the first child
        else {
            var cellHasFocusedChildren = eGridCell.contains(activeElement) && eGridCell !== activeElement;
            // Manually set focus to the last child element if cell doesn't have focused children
            if (!cellHasFocusedChildren) {
                lastCellChildEl.focus();
                event.preventDefault();
            }
            var isFirstChildFocused = firstCellChildEl && activeElement === firstCellChildEl;
            if (!isFirstChildFocused) {
                suppressEvent = true;
                if (currentIndex !== -1 || activeElement === eGridCell) {
                    if (firstCellChildEl &&
                        (activeElement === null || activeElement === void 0 ? void 0 : activeElement.id) === "identifier-cell-button-edit") {
                        event.stopPropagation();
                        focusableChildrenElements[0].focus();
                    }
                    else {
                        event.preventDefault();
                        focusableChildrenElements[currentIndex + 1].focus();
                    }
                }
            }
            else if ((activeElement === null || activeElement === void 0 ? void 0 : activeElement.id) === "identifier-cell-text-input") {
                suppressEvent = true;
            }
        }
    }
    return suppressEvent;
}
