var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cx } from "class-variance-authority";
import React from "react";
import { Link } from "react-router-dom";
import { cn } from "../../lib/utils";
import { buttonVariants } from "./Button";
import { buttonBaseVariants } from "./ButtonBase";
var LinkButton = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, color = _a.color, _b = _a.size, size = _b === void 0 ? "md" : _b, LeftIcon = _a.LeftIcon, RightIcon = _a.RightIcon, props = __rest(_a, ["className", "children", "color", "size", "LeftIcon", "RightIcon"]);
    return (React.createElement(Link, __assign({ className: cn(cx("tw-no-underline", buttonBaseVariants({ color: color }), buttonVariants({ size: size, className: className }))), ref: ref }, props),
        LeftIcon && React.createElement(LeftIcon, { className: "tw-h-4 tw-w-4" }),
        children,
        RightIcon && React.createElement(RightIcon, { className: "tw-h-4 tw-w-4" })));
});
LinkButton.displayName = "LinkButton";
export { LinkButton };
