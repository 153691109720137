import { faker } from "@faker-js/faker";

import type { ActionPlan } from "../models/ActionPlan";

export function createActionPlan(
  data: NonNullable<Partial<ActionPlan>> = {},
): NonNullable<ActionPlan> {
  return {
    ...{
      action_plan_owners: faker.helpers.arrayElements([
        faker.number.int(),
      ]) as any,
      global_id: faker.string.alpha(),
      name: faker.string.alpha(),
      target_completion_date: faker.string.alpha(),
    },
    ...data,
  };
}
