import { http } from "msw";

import { createCreateTrainingMutationResponse } from "../createCreateTraining";

export const createTrainingHandler = http.post(
  "*/workspaces/:workspace_id/trainings",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateTrainingMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
