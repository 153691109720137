import { faker } from "@faker-js/faker";

import type { RecordReference } from "../models/RecordReference";
import { createRecordType } from "./createRecordType";

export function createRecordReference(
  data: NonNullable<Partial<RecordReference>> = {},
): NonNullable<RecordReference> {
  return {
    ...{
      id: faker.number.int(),
      record_type: createRecordType(),
      name: faker.string.alpha(),
      url: faker.string.alpha(),
    },
    ...data,
  };
}
