import { faker } from "@faker-js/faker";

import type { Tag } from "../models/Tag";

export function createTag(
  data: NonNullable<Partial<Tag>> = {},
): NonNullable<Tag> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      title: faker.string.alpha(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
      tag_type: faker.string.alpha(),
    },
    ...data,
  };
}
