import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createFieldOption } from "../../gen/axios/fieldsController";
import type { CreateFieldOptionMutationRequest } from "../../gen/models";
import { getFieldsQueryKey } from "./use-fields";

export function useCreateFieldOption(fieldId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateFieldOptionMutationRequest) =>
      createFieldOption(fieldId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getFieldsQueryKey(),
      });
    },
  });
}
