import classNames from "classnames";
import React, { useEffect, useReducer, useState } from "react";
import Popup from "reactjs-popup";

import useDidMountEffect from "@/hooks/useDidMount";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import { Icon } from "../Elements";
import {
  IGNORED_DATA_TYPES_FOR_EMPTY_OPTIONS,
  IGNORED_FIELDS_FOR_EMPTY_OPTIONS,
} from "./constants";
import FiltersDateNew from "./FiltersDateNew";
import FiltersFieldSelect from "./FiltersFieldSelect";
import FiltersInteger from "./FiltersInteger";
import FiltersOptionsSelect from "./FiltersOptionsSelect";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddFilter?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeCondition?: (filter: any, condition: string) => void;
}
function FiltersAddNew({ fields, onAddFilter, handleChangeCondition }: Props) {
  const { isFieldGroupPresent, canAddFilter } = useUpdateFilter();

  // state
  const [data, setData] = useReducer(
    // @ts-expect-error TS(7006) FIXME: Parameter 'prev' implicitly has an 'any' type.
    (prev, action) => {
      const newEvent = { ...prev, ...action };
      const filteredType = newEvent.selectedField?.data_type;

      if (
        !newEvent.popupOpened &&
        !filteredType?.includes("date") &&
        !filteredType?.includes("integer") &&
        !filteredType?.includes("float")
      ) {
        newEvent.selectedField = null;
      }
      return newEvent;
    },
    {
      popupOpened: false,
      selectedField: null,
    },
  );

  const [showAddFilter, setShowAddFilter] = useState(true);

  // Variables
  const dataFilteredType = data.selectedField?.data_type;

  // Effects
  useEffect(() => {
    if (
      dataFilteredType?.includes("date") ||
      dataFilteredType?.includes("integer") ||
      dataFilteredType?.includes("float")
    ) {
      setData({
        ...data,
        popupOpened: false,
      });
      if (dataFilteredType?.includes("date")) {
        toggleAddFilter(false);
      }
    }
  }, [data.selectedField]);

  useDidMountEffect(() => {
    setData({ ...data, popupOpened: false });
  }, [handleChangeCondition]);

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  function handleSelectField(field) {
    setData({ selectedField: field, popupOpened: true });
  }

  function clearField() {
    setData({ selectedField: null, popupOpened: true });
  }

  function toggleAddFilter(value?: boolean) {
    setShowAddFilter(value || !showAddFilter);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
  const handleOptionSelect = (filter, fieldName) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onAddFilter(filter, fieldName);
    setData({ selectedField: null, popupOpened: false });
  };

  // renders
  const trigger = (
    <button
      type="button"
      disabled={!canAddFilter}
      className={classNames("filters-add-btn", { active: data.popupOpened })}
      data-testid="filters-add-btn"
    >
      Add Filter
      <Icon name="plus" color="generalDark" size="de" />
    </button>
  );

  const isDateType = data.selectedField?.data_type?.includes("date");
  const isIntegerType = data.selectedField?.data_type?.includes("integer");
  const isFloatType = data.selectedField?.data_type?.includes("float");

  const hideEmptyOptions =
    IGNORED_FIELDS_FOR_EMPTY_OPTIONS.includes(data.selectedField?.name) ||
    IGNORED_DATA_TYPES_FOR_EMPTY_OPTIONS.includes(
      data.selectedField?.data_type,
    );

  return (
    <>
      {isDateType && !data.popupOpened && (
        <FiltersDateNew
          fieldName={data.selectedField.display_name}
          onAddFilter={() =>
            setData({ selectedField: null, popupOpened: false })
          }
          clearField={clearField}
          toggleShowFilter={toggleAddFilter}
        />
      )}

      {(isIntegerType || isFloatType) && !data.popupOpened && (
        <FiltersInteger
          fieldName={data.selectedField.display_name}
          onAddFilter={() =>
            setData({ selectedField: null, popupOpened: false })
          }
          clearField={clearField}
          toggleShowFilter={toggleAddFilter}
        />
      )}

      {showAddFilter && canAddFilter && (
        <Popup
          position="bottom left"
          trigger={trigger}
          open={data.popupOpened}
          onOpen={() => setData({ popupOpened: true })}
          onClose={() => setData({ popupOpened: false })}
          nested
        >
          <div
            className="table-dropdown success-dropdown filters-popup"
            data-testid="filters-popup"
          >
            {data.selectedField ? (
              <FiltersOptionsSelect
                field={data.selectedField}
                onOptionSelect={handleOptionSelect}
                handleConditionSelectNew={handleChangeCondition}
                hideEmptyConditions={hideEmptyOptions}
              />
            ) : (
              <FiltersFieldSelect
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                fields={fields.filter((field) => !isFieldGroupPresent(field))}
                onSelectField={handleSelectField}
              />
            )}
          </div>
        </Popup>
      )}
    </>
  );
}
FiltersAddNew.defaultProps = {
  fields: [],
  onAddFilter: () => {},
};

export default FiltersAddNew;
