import type { AxiosResponse } from "axios";

import {
  type FormQuestionUpsert,
  type GetQuestionnaireFormReadReponse,
  type QuestionnaireAnswerPostRequest,
} from "@/features/risk-assessment";

import api from "../api";

export const QuestionnaireFormApi = {
  async get(id: string, password?: string) {
    const response = await api.get<GetQuestionnaireFormReadReponse>(
      `/forms/questionnaires/${id}`,
      {
        headers: { "X-form-auth": password },
      },
    );

    return response.data.questionnaire;
  },
  async saveAnswers(
    external_id: string,
    answeredQuestions: FormQuestionUpsert[],
    isComplete = false,
  ) {
    const response = await api.post<
      QuestionnaireAnswerPostRequest,
      AxiosResponse<GetQuestionnaireFormReadReponse>
    >(`/forms/questionnaires/${external_id}/answers`, {
      completed: isComplete,
      questionnaire: {
        questions_attributes: answeredQuestions,
      },
    });

    return response.data.questionnaire;
  },
  async download(slug: string) {
    const response = await api.get(`/forms/questionnaires/download/${slug}`);
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });
    const filename = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .replace(/"/g, "");
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename || "questionnaire.xlsx";
    link.click();
  },
};
