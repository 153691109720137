import classNames from "classnames";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";

import type { Field, RecordVersion } from "@/api";
import Loading from "@/components/Loading";
import { useCustomColumns } from "@/components/table/shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "@/components/table/shared/ModuleRecordVersion";
import ModuleTableColumn from "@/components/table/shared/ModuleTableColumn";
import SectionPopupSettings from "@/components/table/shared/SectionPopupSettings";
import SectionTag from "@/components/table/shared/SectionTag";
import Sharing from "@/components/table/shared/Sharing";
import TableMenu from "@/components/table/shared/tableMenu";
import Table from "@/components/table/Table";
import { useMainStore } from "@/contexts/Store";
import { useSortingAndPagination } from "@/hooks/useSortingAndPagination";
import type KeyRiskIndicatorsStore from "@/stores/data/KeyRiskIndicatorsStore";
import type UserPermissionsStore from "@/stores/data/UserPermissions";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";
import type { Section } from "@/stores/types/section-tags";
import type ContextStore from "@/stores/ui/Context";

import {
  convertToFields,
  isFieldNameAMonth,
  isFieldNameAQuarter,
  KRI_INPUT_WIDTH,
  kriFields,
  mergeChildRecords,
  modifyInputCellWidths,
  quarterToDateString,
  THRESHOLDS,
} from "../kri-summary/helpers";

interface KRIInputSectionProps {
  sectionTag?: Section;
  recordVersions?: RecordVersion[];
  isDropdownOpen?: boolean;
  kriValuesHeader: string[];
  refreshMonths: () => void;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

const KRIInputSection = ({
  sectionTag,
  recordVersions,
  kriValuesHeader,
  refreshMonths,
  ...props
}: KRIInputSectionProps) => {
  const mainStore = useMainStore();

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.keyRiskIndicators,
  });

  const { data }: KeyRiskIndicatorsStore = mainStore.keyRiskIndicators;
  const moduleWorkspaceID: number | undefined = data?.module_workspace_id;
  const { isAdmin }: ContextStore = mainStore.context;
  const { canManageSections }: UserPermissionsStore = mainStore.userPermissions;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);

  useEffect(() => {
    mainStore.keyRiskIndicators.index({});
  }, []);

  useEffect(() => {
    refreshMonths();
  }, [data]);

  const { renameColumn, deleteColumn } = useCustomColumns({
    sectionTableID: "",
  });

  const fields: Field[] = useMemo(
    () =>
      data
        ? [...kriFields(data?.fields), ...convertToFields(kriValuesHeader)]
        : [],
    [mainStore.keyRiskIndicators.data, kriValuesHeader],
  );

  const renameClick = () => {
    setIsEditMode(true);
  };
  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const deleteSectionTag = () => {
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag?.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const handleUpdateCell = (
    fieldName: string,
    value: string,
    recordVersion: RecordVersion,
  ) => {
    const isQuarter = isFieldNameAQuarter(fieldName);
    const childValue = recordVersion.data[fieldName];

    const editingThresholds = THRESHOLDS.includes(fieldName);
    if (editingThresholds) {
      mainStore.recordVersions.update({
        recordVersionID: recordVersion.id,
        fieldName,
        value: { value: parseFloat(value) },
      });
    } else if (childValue?.value) {
      mainStore.recordVersions.update({
        recordVersionID: childValue.id || undefined,
        fieldName: "value",
        value: { value: parseFloat(value) },
      });
    } else {
      mainStore.keyRiskIndicators.createKRIValue(
        recordVersion.id,
        {
          date: isQuarter
            ? quarterToDateString(fieldName)
            : dayjs(new Date(`1 ${fieldName}`)).format("YYYY-MM-DD"),
          value: parseFloat(value),
        },
        { type: isQuarter ? "quarterly" : null },
      );
    }
  };

  // sets smaller width for kri input fields, adds a boolean flag to the start and end dates so we can show an indicator
  const handleField = (field: Field) => {
    const fieldWithKriWidth = { ...field, width: KRI_INPUT_WIDTH };

    if (isFieldNameAQuarter(field.name)) {
      return fieldWithKriWidth;
    }
    if (isFieldNameAMonth(field.name)) {
      // mark the columns that are the selected start and end
      if (field.name === dayjs(props.startDate).format("MMM YYYY")) {
        return { ...fieldWithKriWidth, kri_input_start_month: true };
      }
      if (field.name === dayjs(props.endDate).format("MMM YYYY")) {
        return { ...fieldWithKriWidth, kri_input_end_month: true };
      }
      return fieldWithKriWidth;
    }
    return field;
  };

  const renderRow = recordVersions?.map(
    (recordVersion: RecordVersion, idx: number) => (
      <ModuleRecordVersion
        key={recordVersion.id}
        fields={modifyInputCellWidths(fields)}
        recordVersion={mergeChildRecords(recordVersion)}
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName="Default"
        moduleIdentifier={TopLevelModule.KEY_RISK_INDICATORS}
        showCheckboxFromSlideMenu={false}
        isSorted={!!sortByFieldName}
        order={idx}
        handleUpdate={handleUpdateCell}
      />
    ),
  );

  const renderColumns = fields.map((field: Field) => (
    <ModuleTableColumn
      key={field.name}
      field={handleField(field)}
      sortDirection={
        field.name === sortByFieldName ? (sortByDirection as string) : undefined
      }
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      onDelete={deleteColumn}
      onRename={renameColumn}
      disableResize
    />
  ));

  if (!data?.fields) {
    return (
      <div className="dashboard-content">
        <div className="dashboard-content-wrap">
          <Table>
            <Loading loadingLayout="table" showTableHeader />
          </Table>
        </div>
      </div>
    );
  }

  return (
    <div className="kri-summary" data-testid="kri-input-table">
      {sectionTag && (
        <div className="tw-ml-[18px] tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={dropdownClick}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center  tw-text-neutral-500",
                {
                  "tw-rotate-180": !isDropdownOpen,
                  "tw-rotate-0 ": isDropdownOpen,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag?.editable && !isEditMode && (
            <>
              {isAdmin && (
                <Sharing
                  moduleWorkspaceID={moduleWorkspaceID}
                  objectID={sectionTag.id}
                  selectedDepartmentIDs={sectionTag.department_ids}
                  selectedUserIDs={sectionTag.user_ids}
                  allowSharing
                />
              )}

              {canManageSections && (
                <SectionPopupSettings
                  onRename={renameClick}
                  onDelete={deleteSectionTag}
                />
              )}
            </>
          )}
        </div>
      )}

      {isDropdownOpen && (
        <div className="dropdown-table" data-testid="dropdown-table">
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="procedures-list-column-first-blocks table-column-reorder">
                  {fields.length > 0 && <TableMenu />}
                </div>
                <div className="list-title-table-wrap">
                  {renderColumns}
                  <span className="stretch-cell" />
                </div>
              </div>
            </ul>
          </div>
          {renderRow}
        </div>
      )}
    </div>
  );
};

export default observer(KRIInputSection);
