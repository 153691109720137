import { faker } from "@faker-js/faker";

import type {
  CreateRiskRegisterGroupItemInGroup201,
  CreateRiskRegisterGroupItemInGroup403,
  CreateRiskRegisterGroupItemInGroupMutationRequest,
  CreateRiskRegisterGroupItemInGroupMutationResponse,
  CreateRiskRegisterGroupItemInGroupPathParams,
} from "../models/CreateRiskRegisterGroupItemInGroup";
import { createRiskRegisterGroupItem } from "./createRiskRegisterGroupItem";

export function createCreateRiskRegisterGroupItemInGroupPathParams(): NonNullable<CreateRiskRegisterGroupItemInGroupPathParams> {
  return {
    workspace_id: faker.number.int(),
    risk_register_group_id: faker.number.int(),
  };
}

/**
 * @description CREATED
 */
export function createCreateRiskRegisterGroupItemInGroup201(): NonNullable<CreateRiskRegisterGroupItemInGroup201> {
  return { data: { schema: createRiskRegisterGroupItem() } };
}

/**
 * @description Forbidden
 */
export function createCreateRiskRegisterGroupItemInGroup403(): NonNullable<CreateRiskRegisterGroupItemInGroup403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateRiskRegisterGroupItemInGroupMutationRequest(): NonNullable<CreateRiskRegisterGroupItemInGroupMutationRequest> {
  return {
    risk_register_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
  };
}

/**
 * @description CREATED
 */
export function createCreateRiskRegisterGroupItemInGroupMutationResponse(): NonNullable<CreateRiskRegisterGroupItemInGroupMutationResponse> {
  return { data: { schema: createRiskRegisterGroupItem() } };
}
