import { generatePath, Link, useLocation, useParams } from "react-router-dom";

import { moduleAssessmentRoutes } from "../../routes";

export function ModuleAssessmentIdLinkCell({
  moduleAssessmentId,
}: {
  moduleAssessmentId: number;
}) {
  const location = useLocation();

  const { workspace_id, record_version_id } = useParams<{
    record_version_id: string;
    workspace_id: string;
  }>();

  return (
    <Link
      className="tw-w-full tw-text-inherit hover:tw-underline"
      to={{
        pathname: generatePath(
          generatePath(moduleAssessmentRoutes.detail, {
            module_assessment_id: moduleAssessmentId,
            record_version_id,
            workspace_id,
          }),
        ),
        state: { from: location.pathname },
      }}
    >
      {moduleAssessmentId}
    </Link>
  );
}
