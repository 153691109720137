import { http } from "msw";

import { createDeleteTaskableMutationResponse } from "../createDeleteTaskable";

export const deleteTaskableHandler = http.delete(
  "*/tasks/:task_id/taskables/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteTaskableMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
