import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import pointsIconActive from "../../../images/table-image/icon/points-icon-active.svg";
import pointsIcon from "../../../images/table-image/icon/points-icon.svg";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import SubmitComplaint from "./SubmitComplaint";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
}

function FinraComplaintsContextMenu({ recordVersion }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showFormSubmit, setShowFormSubmit] = useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [sendSlide, setSendSlide] = useState(false);
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("finra", moduleWorkspaces, true);

  // Variables
  const { isIW, workspaceID } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const recordVersionID = recordVersion.id;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { taskDetail } = mainStore;

  // Variables - send to cw
  const handleOpenSendSlideMenu = () => {
    setIsOpenModal(false);
    setShowSlideMenu(true);
    setSendSlide(true);
  };

  const handleCloseSlideMenu = () => {
    setShowSlideMenu(false);
    setSendSlide(false);
  };

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  const escalateToFinra = mainStore.avroSchemas.firstValueForField(
    "escalate_to_finra",
    recordVersion.data,
  ); // "yes", "no"
  const escalatedStatus = mainStore.avroSchemas.firstValueForField(
    "escalated_status",
    recordVersion.data,
  ); // "open", "complete"
  const reviewStatus = mainStore.avroSchemas.firstValueForField(
    "review_status",
    recordVersion.data,
  ); // "reviewing", "submitted", "complete", "closed", "re_opened"

  const canEscalate = escalateToFinra === "yes";
  const isClosed = reviewStatus === "closed";
  const isSubmitted = reviewStatus === "submitted";
  const isComplete = escalatedStatus === "complete";
  const isDisabled = isClosed || isSubmitted || isComplete;
  const finraSubmission = recordVersion?.finra_submission;

  // Hooks
  const location = useLocation();

  // Functions
  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    if (isDisabled) {
      handlePopUpClose();
    } else {
      setIsDropdownOpen(false);
      setIsDeleteConfirmationOpen(true);
    }
  }

  function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      mainStore.finraComplaints.delete(recordVersionID);
      mainStore.toast.setText("Complaint was successfully deleted!");
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDropdownOpen(true);
    setIsDeleteConfirmationOpen(false);
    setShowFormSubmit(false);
  }

  function closeComplaint() {
    mainStore.finraComplaints.close(recordVersionID);
    handlePopUpClose();
  }

  function reopenComplaint() {
    mainStore.finraComplaints.reopen(recordVersionID);
    handlePopUpClose();
  }

  function submitEscalatedComplaint() {
    setIsOpenModal(false);
    setIsDropdownOpen(false);
    mainStore.finraComplaints.complete(recordVersionID);
  }

  function showSubmitPopup() {
    setShowFormSubmit(true);
    setIsDropdownOpen(false);
  }

  function submitReviewComplaint(fullName = null, phone = null) {
    handlePopUpClose();

    if (fullName && phone) {
      mainStore.finraComplaints.submitToFINRA(recordVersionID, fullName, phone);
    }
  }

  // Rendering
  const buttonClasses = classNames("points-button", {
    "big-active": isOpenModal,
  });

  const renderTrigger = (
    <button
      className={buttonClasses}
      data-testid="complaints-context-menu-trigger"
    >
      <img
        className="points-icon"
        src={pointsIcon}
        alt="points-icon"
        data-testid="points-button-trigger"
      />
      <img
        className="points-icon-active"
        src={pointsIconActive}
        alt="points-icon-active"
      />
    </button>
  );

  const deleteClassNames = classNames({ disabled: isDisabled });

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="complaints-context-menu-dropdown"
    >
      <ul>
        {location.pathname ===
          `/workspaces/${workspaceID}/modules/finra/escalated` &&
          !isComplete && (
            <>
              <li onClick={submitEscalatedComplaint}>Complete Complaint</li>
              <hr />
            </>
          )}

        {location.pathname ===
          `/workspaces/${workspaceID}/modules/finra/review` && (
          <>
            {!isClosed && !isSubmitted && canEscalate && (
              <li onClick={showSubmitPopup}>Submit to FINRA</li>
            )}

            {!isClosed && !isSubmitted && (
              <li onClick={closeComplaint}>Close Complaint</li>
            )}

            {(isClosed || (isSubmitted && !finraSubmission)) && (
              <li onClick={reopenComplaint}>Reopen Complaint</li>
            )}

            {isClosed && <hr />}
          </>
        )}

        {isIW && hasModuleWriteAccess && (
          <>
            <ShareToCW
              recordName={recordName}
              sendTrigger={handleOpenSendSlideMenu}
            />
            <hr />
          </>
        )}
        <li onClick={handleCreateTask}>Create Task</li>

        {canDeleteRecords && (
          <li
            onClick={deleteRowConfirmation}
            className={deleteClassNames}
            data-testid="complaints-context-menu-dropdown-delete"
          >
            Delete
          </li>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Complaints"
      content="Are you sure you want to delete this complaints? Actions are not reversable."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  return (
    <div className="list-points finra" data-testid="complaints-context-menu">
      <Popup
        position="bottom right"
        trigger={hasModuleWriteAccess ? renderTrigger : <div />}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && renderBaseContent}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}

        {showFormSubmit && (
          <SubmitComplaint submitPressed={submitReviewComplaint} />
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={handleCloseSlideMenu}>
        {sendSlide && (
          <SendRecordVersion
            recordVersionID={recordVersionID}
            recordName={recordName}
          />
        )}
      </SlideMenu>
    </div>
  );
}

export default observer(FinraComplaintsContextMenu);
