import { http } from "msw";

import { createShowContactQueryResponse } from "../createShowContact";

export const showContactHandler = http.get(
  "*/contacts/:id",
  function handler(info) {
    return new Response(JSON.stringify(createShowContactQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
