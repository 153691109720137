import { http } from "msw";

import { createListRecordFindingsQueryResponse } from "../createListRecordFindings";

export const listRecordFindingsHandler = http.get(
  "*/records/:record_id/findings",
  function handler(info) {
    return new Response(
      JSON.stringify(createListRecordFindingsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
