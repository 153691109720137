import { http } from "msw";

import { createShowDocumentQueryResponse } from "../createShowDocument";

export const showDocumentHandler = http.get(
  "*/documents/:id",
  function handler(info) {
    return new Response(JSON.stringify(createShowDocumentQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
