import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import ConfirmationDialog from "../table/shared/ConfirmationDialog/confirmation-dialog";

function RemoveCompanyButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [showPopup, setShowPopup] = useState(false);

  // vars
  const { company } = mainStore.companies;
  const { canDeleteCompany } = mainStore.userPermissions;

  // funcs
  function handlePopUpOpen() {
    setShowPopup(true);
  }

  function handlePopUpClose() {
    setShowPopup(false);
  }

  async function handleConfirm() {
    handlePopUpClose();
    const success = await mainStore.companies.delete(company.id);
    if (success) {
      window.location.reload();
    }
  }

  // elements
  const renderTrigger = (
    <button className={classNames({ active: showPopup })}>
      Delete Company
    </button>
  );

  if (!canDeleteCompany) {
    return null;
  }

  return (
    <div className="remove-company-button-container">
      {canDeleteCompany && (
        <Popup
          position="bottom left"
          trigger={renderTrigger}
          open={showPopup}
          onOpen={handlePopUpOpen}
          onClose={handlePopUpClose}
          keepTooltipInside
        >
          <div className="table-dropdown success-dropdown back-btn-confirm-popup">
            <ConfirmationDialog
              handleConfirm={handleConfirm}
              handleReject={handlePopUpClose}
              heading="Delete company?"
              content="Are you sure you want to delete this company?"
            />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default observer(RemoveCompanyButton);
