import { FilePicker, IconButton, useToast } from "@themis/ui";
import { DirectUploadProvider } from "react-activestorage-provider";
import { FiPlus } from "react-icons/fi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useCreateDocument } from "@/api/queries/documents";
import type { ACCOUNT_ID_PARAM } from "@/features/accounts/pages";

export function DocumentsActions({ disabled = false }: { disabled?: boolean }) {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const { accountId, workspace_id } = useParams<{
    [ACCOUNT_ID_PARAM]: string;
    workspace_id: string;
  }>();

  const { mutateAsync: createDocument } = useCreateDocument({
    accountId: Number(accountId),
    workspaceId: Number(workspace_id),
    onSuccess: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Attachment has been added!",
        }),
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Something went wrong. Could not add attachment.",
        }),
        variant: "error",
      });
    },
  });

  async function handleFileChange(signedIds: string[]) {
    const requestBody = { document: { file: signedIds[0] } };

    await createDocument(requestBody);
  }

  return (
    <DirectUploadProvider
      onSuccess={handleFileChange}
      render={({ handleUpload, ready, uploads }) => {
        const [upload] = uploads;
        return (
          <FilePicker
            isLoading={
              upload?.state === "uploading" || upload?.state === "waiting"
            }
            percentage={Math.round(uploads[0]?.progress) || 0}
            readOnly={!ready}
            onSelectFile={(selectedFile) => handleUpload([selectedFile])}
            trigger={<IconButton disabled={disabled} Icon={FiPlus} />}
            alignPopover="end"
          />
        );
      }}
    />
  );
}
