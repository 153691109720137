import type { FieldConfiguration } from "@/api";
import type { FilterFieldData } from "@/hooks/useFilterSort";

export function getFiltersFieldData<T>(
  fields: FieldConfiguration[] | undefined,
) {
  if (!fields) {
    return;
  }

  return fields.reduce<FilterFieldData<T>>((accumulator, currValue) => {
    if (!currValue.options) {
      return accumulator;
    }

    return {
      ...accumulator,
      [currValue.name]: {
        displayName: currValue.display_name,
        options: currValue.options.map((option) => ({
          value: option.value,
          key: option.key,
        })),
        type: "string",
      },
    };
  }, {});
}
