import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import groupFiltersIcon from "../../images/filters/group-filters.webp";
import { Icon } from "../Elements";
import { mapHeader } from "../reports/common/MetricsPage/helpers";
import { metricsDashboardUrl } from "../reports/common/MetricsPage/urlPaths";
import {
  EMPTY_OPTIONS,
  IGNORED_DATA_TYPES_FOR_EMPTY_OPTIONS,
  IGNORED_FIELDS_FOR_EMPTY_OPTIONS,
} from "./constants";
import {
  FiltersIntegerConditions,
  FiltersIntegerRangeValues,
  FiltersIntegerValue,
} from "./FiltersIntegerDropdowns";
import FiltersOptionsSelect from "./FiltersOptionsSelect";
import { filterIntegerChange } from "./helpers";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeCondition: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRemoveFilter?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onToggleFilterOption?: (...args: any[]) => any;
}

function FiltersAppliedItem({
  field,
  filter,
  onChangeCondition,
  onRemoveFilter,
  onToggleFilterOption,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { state } = useLocation<{ from?: string }>();

  const { getTableFiltersParam, setTableFiltersParam } = useUpdateFilter();

  // state
  const [isConditionPopupOpened, setIsConditionPopupOpened] = useState(false);
  const [isValuesPopupOpened, setIsValuesPopupOpened] = useState(false);
  const [integerValues, setIntegerValues] = useState({
    singleValue: filter.options[0]?.value,
    rangeValueFrom: filter.options[0]?.value,
    rangeValueTo: filter.options[1]?.value,
  });

  if (!field) {
    return null;
  }

  // vars
  const isMultiple = filter.options.length > 1;
  const isNumber =
    field.data_type === "com.askthemis.types.v1.integer" ||
    field.data_type === "com.askthemis.types.v1.float";
  const isMultipleBasicType = isMultiple && !isNumber;
  const isMultipleIntegerType = isMultiple && isNumber;
  const modes = mainStore.filters.getFilterOptionsGeneratingModes(
    field.data_type,
  );

  let filterLabel = filter.options[0]?.label;
  let integerFilterLabel = filter.options[0]?.value;

  if (filter.name === "department") {
    filterLabel = mainStore.departments.departments.find(
      (dept) => dept.id === filter.options[0]?.value,
    )?.title;
  }

  if (state?.from?.includes(metricsDashboardUrl)) {
    filterLabel = mapHeader(
      mainStore.fieldOptions.all,
      mainStore.reports.imSourceGroupName,
      filterLabel,
    );
  }

  if (isMultipleIntegerType) {
    const valuesFromTo = [
      filter.options[0]?.value,
      filter.options[1]?.value,
    ].sort((a, b) => a - b);
    integerFilterLabel = `${valuesFromTo?.[0]} ... ${valuesFromTo?.[1]}`;
  }

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'condition' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleConditionSelect(condition) {
    setIsConditionPopupOpened(false);
    const valueIsEmpty = !filter.options[0]?.value;

    if ((isNumber || valueIsEmpty) && !EMPTY_OPTIONS.includes(condition)) {
      setIsValuesPopupOpened(true);
    }

    onChangeCondition(filter, condition);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'condition' implicitly has an 'any' type... Remove this comment to see the full error message
  function parseDateConditionLabel(condition) {
    const [{ label }] = filter.options;

    if (label.includes("week") || label.includes("month")) {
      return condition ===
        mainStore.filters.getConditionText(field.data_type, true)
        ? "in the last"
        : "older than";
    }

    return condition;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleFilterIntegerChange(filterArg: any, value: string[]) {
    filterIntegerChange(
      filterArg,
      value,
      setTableFiltersParam,
      getTableFiltersParam,
      setIsValuesPopupOpened,
    );
  }

  function onFilterIntegerChange() {
    const { rangeValueFrom, rangeValueTo } = integerValues;

    if (Number(rangeValueFrom) > Number(rangeValueTo)) {
      mainStore.toast.setErrorText(
        "The first number must be less than the second",
      );
      return;
    }
    handleFilterIntegerChange?.(filter, [rangeValueFrom, rangeValueTo]);
  }

  function handleIntegerValuesChange(name: string, value: string) {
    setIntegerValues({
      ...integerValues,
      [name]: value,
    });
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if ((event as React.KeyboardEvent).key === "Enter") {
      const { value } = event.target as HTMLInputElement;

      if (value.length > 0 && Number(value)) {
        handleFilterIntegerChange?.(filter, [value]);
      } else {
        mainStore.toast.setErrorText("Please only enter integer values.");
      }
    }
  }

  const hideIntegerValueDiv = EMPTY_OPTIONS.includes(filter.condition);

  // renders
  const renderCondition = () => {
    const triggerLabel = () => {
      if (isMultipleBasicType) {
        return modes.calendar ? "between" : `${filter.condition} any of`;
      }

      if (modes.calendar) {
        return parseDateConditionLabel(filter.condition);
      }

      return filter.condition;
    };
    const trigger = (
      <span
        className={classNames("filters-applied-item-condition", {
          "remove-right-border": hideIntegerValueDiv,
        })}
        data-testid="filters-applied-item-condition-trigger"
      >
        {triggerLabel()}
      </span>
    );

    if (isMultiple && modes.calendar) {
      return trigger;
    }

    // @ts-expect-error TS(7031) FIXME: Binding element 'icon' implicitly has an 'any' typ... Remove this comment to see the full error message
    const renderListItem = ({ icon, isPositive }) => {
      const conditionValue = mainStore.filters.getConditionText(
        field.data_type,
        isPositive,
      );
      const conditionLabel = modes.calendar
        ? parseDateConditionLabel(conditionValue)
        : conditionValue;

      return (
        <div
          className="conditions-list-item"
          onClick={() => handleConditionSelect(conditionValue)}
          data-testid="filters-applied-item-condition-popup-list-item"
        >
          <Icon name={icon} color="generalDark" size="de" />
          {conditionLabel}
        </div>
      );
    };

    const showEmptyOptions = !(
      IGNORED_FIELDS_FOR_EMPTY_OPTIONS.includes(field.name) ||
      [
        ...IGNORED_DATA_TYPES_FOR_EMPTY_OPTIONS,
        "com.askthemis.types.v1.attachment",
      ].includes(field?.data_type)
    );

    const renderEmptyOptions = () => (
      <>
        {EMPTY_OPTIONS.map((option) => (
          <div
            key={option}
            className="conditions-list-item"
            onClick={() => handleConditionSelect(option)}
            data-testid="filters-applied-item-condition-popup-list-item"
          >
            {option}
          </div>
        ))}
      </>
    );

    return (
      <Popup
        position="bottom left"
        trigger={trigger}
        open={isConditionPopupOpened}
        onOpen={() => setIsConditionPopupOpened(true)}
        onClose={() => setIsConditionPopupOpened(false)}
      >
        <div
          className="table-dropdown success-dropdown filters-popup"
          data-testid="filters-applied-item-condition-popup"
        >
          {isNumber ? (
            <FiltersIntegerConditions
              showBackButton={false}
              handleSelectCondition={handleConditionSelect}
              condition={integerFilterLabel}
            />
          ) : (
            <div className="conditions-list">
              {renderListItem({ icon: "plus", isPositive: true })}
              {renderListItem({ icon: "close", isPositive: false })}
              {showEmptyOptions && renderEmptyOptions()}
            </div>
          )}
        </div>
      </Popup>
    );
  };

  const renderNumberFilter = () => {
    if (filter.condition === "is between") {
      return (
        <FiltersIntegerRangeValues
          showBackButton={false}
          setValueFrom={(value) =>
            handleIntegerValuesChange("rangeValueFrom", value)
          }
          setValueTo={(value) =>
            handleIntegerValuesChange("rangeValueTo", value)
          }
          betweenIntegerRangeSelected={onFilterIntegerChange}
          onlyContent={false}
          disabled={
            !integerValues.rangeValueFrom && !integerValues.rangeValueTo
          }
          valueFrom={integerValues.rangeValueFrom}
          valueTo={integerValues.rangeValueTo}
        />
      );
    }

    return (
      <FiltersIntegerValue
        showBackButton={false}
        onlyContent={false}
        handleKeyDown={(e) => handleKeyDown(e)}
        setValue={(value) => handleIntegerValuesChange("singleValue", value)}
        value={integerValues.singleValue}
      />
    );
  };

  const renderValues = () => {
    const multipleBasicEl = modes.calendar ? (
      filter.options.map((item: { label: string }) => item.label).join(" and ")
    ) : (
      <>
        <img src={groupFiltersIcon} alt="group-filters" />
        {filter.options.length} Selections
      </>
    );

    const trigger = (
      <span
        className={classNames("filters-applied-item-values", {
          "is-multiple": isMultipleBasicType,
          hidden: hideIntegerValueDiv,
        })}
        data-testid="filters-applied-item-values-trigger"
      >
        {isMultipleBasicType ? multipleBasicEl : filterLabel}
        {isNumber && integerFilterLabel}
      </span>
    );

    return (
      <Popup
        position="bottom left"
        trigger={trigger}
        open={isValuesPopupOpened}
        onOpen={() => setIsValuesPopupOpened(true)}
        onClose={() => setIsValuesPopupOpened(false)}
        nested
      >
        {isNumber ? (
          renderNumberFilter()
        ) : (
          <div
            className="table-dropdown success-dropdown filters-popup"
            data-testid="filters-applied-item-values-popup"
          >
            <FiltersOptionsSelect
              hideEmptyConditions
              field={field}
              onOptionSelect={onToggleFilterOption}
            />
          </div>
        )}
      </Popup>
    );
  };

  const renderRemove = (
    <span
      className={classNames("filters-applied-item-remove", {
        "no-margin-left": hideIntegerValueDiv,
      })}
    >
      <Icon
        name="close"
        color="generalDark"
        size="de"
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClick={() => onRemoveFilter(filter, field.name)}
        data-testid="filters-applied-item-remove"
      />
    </span>
  );

  return (
    <div className="filters-applied-item" data-testid="filters-applied-item">
      <span
        className="filters-applied-item-name"
        data-testid="filters-applied-item-name"
      >
        {field.display_name}
      </span>
      {renderCondition()}
      {renderValues()}
      {renderRemove}
    </div>
  );
}

FiltersAppliedItem.defaultProps = {
  onChangeCondition: () => {},
  onRemoveFilter: () => {},
  onToggleFilterOption: () => {},
};

export default observer(FiltersAppliedItem);
