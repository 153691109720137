import { faker } from "@faker-js/faker";

import type {
  ListControlsModules,
  ListControlsModules200,
  ListControlsModulesPathParams,
  ListControlsModulesQueryResponse,
} from "../models/ListControlsModules";

export function createListControlsModules(
  data: NonNullable<Partial<ListControlsModules>> = {},
): NonNullable<ListControlsModules> {
  return {
    ...{
      control: {},
      modules: faker.helpers.arrayElements([undefined]) as any,
      field_options: {},
      scoring_matrices: faker.helpers.arrayElements([undefined]) as any,
      rating: {},
      description: {},
      risk_event: {},
      event_description: {},
      l1_risk_taxonomy: {},
      l2_risk_taxonomy: {},
      record_version: {},
      modules_ratings: {},
    },
    ...data,
  };
}

export function createListControlsModulesPathParams(): NonNullable<ListControlsModulesPathParams> {
  return { record_version_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListControlsModules200(): NonNullable<ListControlsModules200> {
  return { data: createListControlsModules() };
}

/**
 * @description OK
 */
export function createListControlsModulesQueryResponse(): NonNullable<ListControlsModulesQueryResponse> {
  return { data: createListControlsModules() };
}
