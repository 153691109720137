import { http } from "msw";

import { createListColorSchemesQueryResponse } from "../createListColorSchemes";

export const listColorSchemesHandler = http.get(
  "*/color_schemes",
  function handler(info) {
    return new Response(JSON.stringify(createListColorSchemesQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
