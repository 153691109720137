import { http } from "msw";

import { createUnlinkFindingRecordMutationResponse } from "../createUnlinkFindingRecord";

export const unlinkFindingRecordHandler = http.delete(
  "*/findings/:finding_id/records/:record_id/link",
  function handler(info) {
    return new Response(
      JSON.stringify(createUnlinkFindingRecordMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
