import axios from "axios";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import type { AttachmentGroup } from "@/api";
import { attachmentFileType } from "@/api";
import { Icon } from "@/components/Elements";
import {
  buildUploadPayload,
  getAllAttachments,
  getFigmaAttachment,
  getFilenames,
  getLatestAttachment,
} from "@/components/helpers/AttachmentGroupsHelper";
import { getFileExtension } from "@/components/helpers/Files";
import { FileUploadLoading } from "@/components/table/shared/cell-type/file-select/FileUploadLoading";
import { googleFilePicker } from "@/components/table/shared/cell-type/file-select/GoogleFilePicker/helpers";
import SharePointFilePicker from "@/components/table/shared/cell-type/file-select/SharePointFilePicker";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog/confirmation-dialog";
import FileUploadChanges from "@/components/table/shared/FileUploadChanges";
import FileUploadResetApprovers from "@/components/table/shared/FileUploadResetApprovers";
import { isSupportedViewFileType } from "@/components/table/shared/helpers";
import Spinner from "@/components/table/shared/Spinner";
import UploadDate from "@/components/table/shared/UploadDate";
import { useMainStore } from "@/contexts/Store";
import warningIcon from "@/images/table-image/icon/warning-icon.svg";
import type { IntegrationAttachmentTypes } from "@/stores/types/attachment-types";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { FileTypeSelectionPopup } from "./FileTypeSelectionPopup";

type PopupTypes =
  | "approvals-confirmation"
  | "device-upload"
  | "figma-live-embed"
  | "figma-upload"
  | "file-attachment"
  | "file-changes"
  | "file-delete-confirmation"
  | "file-error"
  | "file-upload"
  | "google-drive-loading"
  | "preview-figma-file"
  | "share-point-upload"
  | "share-point-loading";

interface Props {
  fieldName: string;
  recordVersionID: number;
  attachedDocumentID?: number;
  attachmentGroups?: AttachmentGroup[];
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadingFile?: (...args: any[]) => any;
  errorMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  hasErrorClass?: string;
  hasErrors?: boolean;
  isApprovedMarketing?: boolean;
  isInTableView?: boolean;
  isLockedRow?: boolean;
  isLockedTable?: boolean;
  isMultiple?: boolean;
  locked?: boolean;
  meta?: {
    url?: string;
    file_type?: string;
  };
  onAfterFileUpload?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  otherFiles?: any[];
  pinned?: boolean;
  recordID?: number;
  width?: number | string;
}

function FileSelect({
  attachedDocumentID,
  attachmentGroups,
  downloadingFile,
  errorMessage,
  otherFiles,
  exposeData,
  fieldName,
  hasErrorClass,
  hasErrors,
  isApprovedMarketing,
  isLockedRow,
  isLockedTable,
  isMultiple,
  locked,
  meta,
  pinned,
  recordID,
  recordVersionID,
  width,
  disabled,
  onAfterFileUpload,
  isInTableView,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const location = useLocation();

  // State
  const [upload, setUpload] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState<PopupTypes | null>(null);
  const [figmaLink, setFigmaLink] = useState("");
  const [showAllFiles, setShowAllFiles] = useState(false);
  const [popupSize, setPopupSize] = useState(false);
  const [replacedAttachmentId, setReplacedAttachmentId] = useState(-1);
  const [addToAttachmentGroupID, setAddToAttachmentGroupID] = useState<
    number | null
  >(null);
  const [screenshot, setScreenshot] = useState(null);
  const [screenshotFileID, setScreenshotFileID] = useState(null);
  const [showFileActions, setShowFileActions] = useState(!isInTableView);
  const [showFileInfo, setShowFileInfo] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [attachmentIdToOpen, setAttachmentIdToOpen] = useState<null | number>(
    null,
  );
  const [canUpdateApprovers, setCanUpdateApprovers] = useState(true);

  // Variables
  const field = mainStore.fields.list.find((f) => f.name === fieldName);
  const isFileVersioningEnabled = field?.is_file_versioning_enabled;
  const isCreative = fieldName === "creative";
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const googleEnabled = mainStore?.users?.user?.google_integration_enabled;
  const sharePointEnabled = mainStore.users.user.sharepoint_integration_enabled;
  const figmaEnabledModules: ModuleIdentifier[] = [
    "documents",
    "new_product_approval",
    "marketing",
  ];
  const figmaEnabled =
    mainStore?.users?.user?.figma_integration_enabled &&
    themisModuleIdentifier &&
    figmaEnabledModules.includes(themisModuleIdentifier);
  const someIntegrationsEnabled =
    figmaEnabled || googleEnabled || sharePointEnabled;
  const numberOfFilesToShow = 3;
  const shouldShowLockedFiles = isLockedRow || isLockedTable || locked;
  const fileNames = getFilenames(attachmentGroups, shouldShowLockedFiles);
  const allAttachments = getAllAttachments(attachmentGroups);
  const fileTypes = allAttachments.map((attachment) => attachment.file_type);
  const isModal = viewPopup === "figma-live-embed";
  const isFigmaGroup = fileTypes.includes(attachmentFileType.figma);
  const isGoogleDrive = fileTypes.includes(attachmentFileType.google_drive);
  const isSharePoint = fileTypes.includes(attachmentFileType.share_point);

  const uploaderRef = useRef();

  // Hooks
  const history = useHistory();

  const files = useMemo(() => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (otherFiles?.length > 0) {
      return otherFiles;
    }

    if (fileNames.length > 0) {
      return fileNames;
    }

    return [];
  }, [attachmentGroups, otherFiles]);

  useEffect(() => {
    if (files) {
      setIsLoading(false);
    }
  }, [files]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleDrop = (event) => {
    setUpload(event.target.files[0]);
  };

  const openUrl = allAttachments.find(
    (attachment) => attachment.id === attachmentIdToOpen,
  )?.url;

  const canFileBeOpenedIn = (fileType: string) =>
    allAttachments.find((attachment) => attachment.id === attachmentIdToOpen)
      ?.file_type === fileType;

  // @ts-expect-error TS(7006) FIXME: Parameter 'groups' implicitly has an 'any' type.
  const filterAttachedDocumentGroupsOut = (groups) =>
    (groups || []).filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentGroup' implicitly has an 'any... Remove this comment to see the full error message
      (attachmentGroup) => attachmentGroup.file_type !== "AttachedDocument",
    );

  // Google & Figma resync
  const resync = async (fileType: IntegrationAttachmentTypes) => {
    if (recordVersionID && fieldName) {
      setViewPopup("file-changes");
      await mainStore.files.resync(
        recordVersionID,
        fieldName,
        fileType,
        attachmentIdToOpen,
      );
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'signedIds' implicitly has an 'any' type... Remove this comment to see the full error message
  const handleAttachment = async (signedIds) => {
    if (attachedDocumentID) {
      await sendAttachedDocument(signedIds[0]);
      setShowPopup(false);
    } else {
      const cellParams = { files: signedIds };
      if (screenshot) {
        setScreenshotFileID(signedIds[0]);
      }
      await sendRequest(cellParams);
    }
  };

  const handleOpenGoogleDrivePicker = async () => {
    try {
      await googleFilePicker(saveGoogleDriveUrl);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 424) {
        mainStore.toast.setErrorText(`${error.response.data.errors.base}`);
      } else {
        mainStore.toast.setErrorText("Error!");
      }
    }
  };

  const saveGoogleDriveUrl = async (url: string) => {
    const params = {
      meta: {
        file_type: attachmentFileType.google_drive,
        url,
      },
    };

    await sendRequest(params);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'docId' implicitly has an 'any' type.
  const sendAttachedDocument = (docId) => {
    const params = { attached_document: { file: docId } };
    mainStore.attachedDocuments.update(attachedDocumentID, recordID, params);
  };

  // Update file cell
  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  const sendRequest = async (params) => {
    params.add_to_this_version = Boolean(screenshot);
    const signedIDs = params.files;

    const fileType = params.meta?.file_type || attachmentFileType.direct_upload;
    const url = params.meta?.url;

    // "Upload New Version" logic for single-select fields and file versioning enabled
    if (addToAttachmentGroupID) {
      await mainStore.attachments.create({
        attachmentGroupID: addToAttachmentGroupID,
        signedID: signedIDs[0],
      });
      setCanUpdateApprovers(true);
    } else if (
      !field?.is_multiselect &&
      field?.is_file_versioning_enabled &&
      replacedAttachmentId !== -1
    ) {
      const payload = buildUploadPayload(fieldName, fileType, signedIDs?.[0]);
      if (!payload) {
        return;
      }

      // Reset upload popup state
      setReplacedAttachmentId(-1);
      setCanUpdateApprovers(false);

      // Destroy all existing AttachmentGroups for field
      await mainStore.files.deleteAllFiles(recordVersionID, fieldName);

      // Create new AttachmentGroup
      await mainStore.attachmentGroups.create({ recordVersionID, payload });
    } else {
      // Below from this line is our old logic
      await mainStore.attachmentGroups.uploadFiles({
        recordVersionID,
        fieldName,
        signedIDs,
        fileType,
        url,
        isMultiselectField: isMultiple,
        replacedAttachmentID: replacedAttachmentId,
      });
      setCanUpdateApprovers(true);
    }

    if (
      files?.length &&
      themisModuleIdentifier &&
      ["policy", "procedures", "documents"].includes(themisModuleIdentifier)
    ) {
      setViewPopup("file-changes");
      setReplacedAttachmentId(-1);
    } else if (viewPopup === "file-upload" && googleEnabled) {
      setViewPopup("google-drive-loading");
    } else {
      setShowPopup(false);
    }

    if (typeof onAfterFileUpload === "function") {
      onAfterFileUpload();
    }

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    exposeData(params);
  };

  // Effects
  useEffect(() => {
    if (
      (viewPopup?.includes("preview-") || viewPopup?.includes("-loading")) &&
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      files.length === 0
    ) {
      return;
    }
    // @ts-expect-error TS(2345) FIXME: Argument of type '"audits" | "new_product_approval... Remove this comment to see the full error message
    if (screenshot && figmaEnabledModules.includes(themisModuleIdentifier)) {
      return history.push(
        `/workspaces/${workspaceID}/modules/${kebabCase(
          // @ts-expect-error TS(2345) FIXME: Argument of type '"audits" | "new_product_approval... Remove this comment to see the full error message
          themisModuleIdentifier,
        )}/attachment_view/${recordVersionID}/${screenshotFileID}`,
      );
    }

    if (
      viewPopup !== "file-changes" &&
      viewPopup !== "approvals-confirmation"
    ) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (files.length === 0) {
        setViewPopup("file-upload");
      } else {
        setViewPopup("file-attachment");
      }
    }
  }, [files, location.pathname]);

  useEffect(() => {
    if (
      mainStore.toast.text.includes(
        "account isn't connected or you are not the file owner",
      )
    ) {
      setShowPopup(false);
    }
  }, [mainStore.toast.text]);

  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    const handler = (event) => {
      if (
        event.data.name !== "screenshot:done" ||
        event.data.recordVersionID !== recordVersionID
      ) {
        return;
      }
      mainStore.pageLoading.startLoading();
      const { data } = event.data;
      setViewPopup("device-upload");
      setScreenshot(data);
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [recordVersionID]);

  useEffect(() => {
    if (!screenshot || !uploaderRef.current) {
      return;
    }
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(screenshot);
    // @ts-expect-error TS(2349) FIXME: This expression is not callable.
    uploaderRef.current(dataTransfer.files);
  }, [uploaderRef.current, screenshot]);

  useEffect(() => {
    if (!files || files.length === 0) {
      setShowFileActions(true);
      setShowFileInfo(false);
    }
  }, [files]);

  useEffect(() => {
    if (viewPopup === "file-changes") {
      setShowFileActions(true);
      setShowFileInfo(false);
    }
  }, [viewPopup]);

  const onClose = () => {
    if (viewPopup?.includes("-loading") || viewPopup?.includes("preview-")) {
      return setShowPopup(false);
    }

    setViewPopup(files?.length === 0 ? "file-upload" : "file-attachment");
    setReplacedAttachmentId(-1);
    setAddToAttachmentGroupID(null);
    setShowPopup(false);
    setPopupSize(false);
    setUpload("");

    if (isInTableView && files && files.length > 0) {
      setShowFileInfo(true);
      setShowFileActions(false);
    }
  };

  const showDeleteConfirmation = () => {
    setViewPopup("file-delete-confirmation");
  };

  const onDelete = () => {
    if (recordVersionID && fieldName) {
      mainStore.files.deleteAllFiles(recordVersionID, fieldName);
    }

    setShowPopup(false);
    setUpload("");
  };

  // Figma functions
  const handleOpenFigmaUploader = () => {
    setFigmaLink("");
    setViewPopup("figma-upload");
  };

  const renderFigmaLinkErrorToast = () => {
    mainStore.toast.setErrorText(
      'We are unable to pull this linked document from Figma. Try copying the "share link" in Figma instead of the url in your browser.',
    );
  };

  const handleFigmaSave = async () => {
    const mainParts = figmaLink.split("https://www.figma.com/design/");

    if (mainParts.length !== 2) {
      return renderFigmaLinkErrorToast();
    }

    const propsParts = mainParts[1].split("/");
    if (propsParts.length !== 2) {
      return renderFigmaLinkErrorToast();
    }

    const urlParamsParts = propsParts[1].split("node-id=");
    if (urlParamsParts.length !== 2) {
      return renderFigmaLinkErrorToast();
    }

    handleFigmaSaveSubmit(figmaLink);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'link' implicitly has an 'any' type.
  const handleFigmaSaveSubmit = async (link) => {
    if (!link) {
      return;
    }

    setViewPopup("preview-figma-file");

    const signedIDs = undefined;
    const fileType = attachmentFileType.figma;
    const url = link;

    const payload = buildUploadPayload(fieldName, fileType, signedIDs, url);
    if (payload) {
      await mainStore.attachmentGroups.create({ recordVersionID, payload });
    }
  };

  const handleScreenshot = () => {
    window.postMessage(
      { name: "screenshot:capture", recordVersionID },
      document.location.origin,
    );
  };

  const handleShowFileActions = useCallback(() => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (isInTableView && files.length > 0) {
      setShowFileActions(true);
      setShowFileInfo(false);
      setShowPopup(true);
    }
  }, [isInTableView, files]);

  const fileFormat =
    fileNames.length > 0 ? fileNames[0]?.split(".").pop() : null;
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const fileExtensions = files.map((filename) =>
    getFileExtension(filename).toLowerCase(),
  );
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const areMultipleFilesPresent = isMultiple && attachmentGroups?.length > 1;
  const isSupportedViewType =
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    files.some((filename) => isSupportedViewFileType(filename)) ||
    fileExtensions.includes("");
  const isMonitoringTestingDocuments =
    location.pathname.startsWith(
      `/workspaces/${workspaceID}/modules/qa-tests`,
    ) && location.pathname.endsWith("/documents");

  const style = { width };

  const popupClasses = classNames("file-placeholder", {
    active: showPopup || viewPopup === "file-error",
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    "file-uploaded": files.length > 0 || (meta && meta.url),
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "active-file-finalized":
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      (["policy", "procedures"].includes(themisModuleIdentifier) &&
        (fileFormat === "pdf" || fileExtensions.includes("pdf"))) ||
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      ["complaints", "issue_management"].includes(themisModuleIdentifier),
    disabled: isLockedRow || disabled || isCurrentWorkspaceArchived,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isCurrentWorkspaceArchived,
    pinned,
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentID' implicitly has an 'any' t... Remove this comment to see the full error message
  const download = (attachmentID, file_type = "", id = -1) => {
    if (file_type === "AttachedDocument") {
      mainStore.attachedDocuments.fetchFileUrl(id);
      return;
    }

    mainStore.files.fetchFile({
      recordVersionID,
      fieldName,
      attachmentID,
      downloadMode: true,
    });
  };

  const attachmentGroupsWithFiles = useMemo(() => {
    const numberOfFiles = showAllFiles ? fileNames.length : numberOfFilesToShow;

    return (
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      attachmentGroups
        .filter((_, index) =>
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          isFigmaGroup ? index === attachmentGroups.length - 1 : true,
        )
        .slice(0, numberOfFiles)
    );
  }, [
    fileNames,
    showAllFiles,
    attachmentGroups,
    numberOfFilesToShow,
    isFigmaGroup,
  ]);

  const renderTrigger = () => {
    if (!fileNames || fileNames.length === 0) {
      return (
        <div style={{ position: "relative" }}>
          <span>+ Add a file</span>
        </div>
      );
    }

    const numberOfFiles = showAllFiles ? fileNames.length : numberOfFilesToShow;

    // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
    const filenameContainerClasses = (index) => {
      const canAddMb = () => {
        if (fileNames.length <= 1) {
          return false;
        }
        if (isFigmaGroup) {
          return false;
        }

        return numberOfFiles > 1 && index < numberOfFiles;
      };

      return classNames("file-select-display-container", {
        "file-select-container-mb": canAddMb(),
      });
    };

    return (
      <div
        className="file-select-display-file"
        data-testid="file-select-filename"
      >
        {attachmentGroupsWithFiles.map((attachmentGroup, index) => {
          const latestAttachment = isFigmaGroup
            ? getFigmaAttachment(attachmentGroups)
            : getLatestAttachment(attachmentGroup);

          if (!latestAttachment) {
            return null;
          }

          const filename =
            shouldShowLockedFiles &&
            latestAttachment &&
            latestAttachment.preview
              ? latestAttachment.preview.filename
              : latestAttachment?.original?.filename;

          return (
            <div
              onClick={() =>
                setAttachmentIdToOpen(latestAttachment?.id || null)
              }
              key={`file-name-${attachmentGroup.id}`}
              data-testid={`attachments-cell-${attachmentGroup.id}`}
            >
              <div className={filenameContainerClasses(index)}>
                {latestAttachment &&
                  (latestAttachment.file_type === "figma" ||
                    latestAttachment.file_type === "google_drive" ||
                    latestAttachment.file_type === "share_point") && (
                    <div className="file-select-download">
                      <a
                        target="_blank"
                        href={latestAttachment.url || undefined}
                        rel="noreferrer"
                        className="integration-icon-link"
                        data-testid="integration-icon-link"
                      >
                        {latestAttachment.file_type === "figma" && (
                          <Icon name="figma" />
                        )}
                        {latestAttachment.file_type === "google_drive" && (
                          <Icon name="google" />
                        )}
                        {latestAttachment.file_type === "share_point" && (
                          <Icon name="sharePoint" />
                        )}
                      </a>
                    </div>
                  )}
                <div
                  className="file-select-filename"
                  data-testid="file-select-filename"
                >
                  {filename}
                </div>
              </div>
            </div>
          );
        })}

        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {attachmentGroups.length === 0 && (
          <>
            <span>{fileNames}</span>
            <Icon
              name="download"
              onClick={(event) => {
                event.stopPropagation();
                downloadingFile?.();
              }}
            />
          </>
        )}
        {!showAllFiles &&
          fileNames.length > numberOfFilesToShow &&
          !isFigmaGroup && (
            <span
              style={{ color: "#353549" }}
              onClick={() => setShowAllFiles(true)}
            >
              + {fileNames.length - numberOfFilesToShow} Attachments
            </span>
          )}
      </div>
    );
  };

  // @ts-expect-error TS(7031) FIXME: Binding element 'uploads' implicitly has an 'any' ... Remove this comment to see the full error message
  const uploadState = ({ uploads }) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const sizeInBytes = uploads.map((elem) => elem.file.size);
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const isVideo = uploads.every((elem) => elem.file.type.startsWith("video"));
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const states = uploads.map((elem) => elem.state);
    const sizeInMB = (sizeInBytes / (1000 * 1000)).toFixed(2);

    let [currentState] = states;

    if (states.includes("uploading")) {
      if (
        (!isVideo && sizeInBytes > 52428800) ||
        (isVideo && sizeInBytes > 1073741824)
      ) {
        currentState = "size_limit";
      } else {
        currentState = "uploading";
      }
    } else if (states.includes("waiting")) {
      currentState = "waiting";
      // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
    } else if (states.filter((state) => state !== "finished").length === 0) {
      currentState = "finished";
    }
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const progresses = uploads.map((elem) => elem.progress);
    let totalProgress =
      // @ts-expect-error TS(7006) FIXME: Parameter 'total' implicitly has an 'any' type.
      progresses.reduce((total, progress) => total + progress, 0) /
      progresses.length;
    if (Number.isNaN(totalProgress)) {
      totalProgress = 100;
    }
    switch (currentState) {
      case "waiting":
        return (
          <div className="uploading">
            <p>0%</p>
            <Spinner />
          </div>
        );
      case "uploading":
        return (
          <div className="uploading">
            <p>{Math.round(totalProgress)}%</p>
            <Spinner />
          </div>
        );
      case "size_limit":
        return (
          <div>
            <div className="uploading size-limit">
              <Icon
                className="warning-icon"
                name="warning"
                color="generalError"
                size="lg"
              />
              <p>
                The maximum upload file size is 1 GB for videos, and 128 MB
                otherwise.
              </p>
              <p>Your current file size:</p>
              <p className="warning-mb">{sizeInMB} MB</p>
            </div>
            <div className="uploading small-spinner">
              <p>{Math.round(totalProgress)}%</p>
              <Spinner />
            </div>
          </div>
        );
      case "finished":
        return null;
      default:
        if (states.includes("error")) {
          const erroredUploads = uploads.filter(
            // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
            (elem) => elem.state === "error",
          );
          return (
            <>
              {
                // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
                erroredUploads.map((elem) => (
                  <p key={elem.id}>
                    Error uploading {elem.file.name}: {elem.error}
                  </p>
                ))
              }
            </>
          );
        }
        return null;
    }
  };

  const sizeLimitPopup = () => (
    <div>
      <div className="uploading size-limit-exceeded">
        <Icon
          className="warning-icon"
          name="warning"
          color="generalError"
          size="lg"
        />
        <p>
          The maximum upload file size is 1 GB for videos, and 128 MB otherwise.
        </p>
        <p>Your selected file exceeds this limit.</p>
      </div>
    </div>
  );

  const directUploadProvider = (
    <DirectUploadProvider
      onSuccess={handleAttachment}
      render={({ handleUpload, uploads }) => {
        uploaderRef.current = handleUpload;

        return (
          <div data-testid="direct-upload-provider">
            <div className="drag-drop-wrap">
              <div className="drag-drop-block">
                {!upload && !isLoading && (
                  <div>
                    <Icon name="addFile" color="generalDarkGray" size="lg" />
                    <p>
                      Drag & drop into this box
                      <br />
                      {isCreative && (
                        <>
                          (PNG / JPG / PDF/ MP4/ MOV/ GIF)
                          <br />
                        </>
                      )}
                      - or -
                    </p>
                    <button>Choose a file</button>
                    <input
                      type="file"
                      onDrop={(event) => handleDrop(event)}
                      onChange={(event) => {
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'FileList | null' is not assignab... Remove this comment to see the full error message
                        setUpload(event.target.files);
                        if (
                          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                          !event.currentTarget.files[0].type.startsWith(
                            "video",
                          ) &&
                          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                          event.currentTarget.files[0].size > 134217728
                        ) {
                          setPopupSize(true);
                        } else {
                          handleUpload(event.currentTarget.files);
                        }
                      }}
                      multiple={isMultiple ?? false}
                    />
                  </div>
                )}
                {upload && uploadState({ uploads })}
                {!upload && isLoading && (
                  <div className="uploading">
                    <Spinner />
                  </div>
                )}
                {popupSize && sizeLimitPopup()}
              </div>
            </div>
          </div>
        );
      }}
    />
  );

  const figmaUploader = (
    <div>
      <div className="drag-drop-wrap g-drive-uploader figma-uploader">
        <textarea
          placeholder="Paste Figma file link here"
          autoFocus
          data-testid="figma-upload-textarea"
          onChange={(e) => setFigmaLink(e.target.value)}
        />
        <div
          className="save-button"
          data-testid="figma-upload-submit"
          onClick={handleFigmaSave}
        >
          <span>Save</span>
        </div>
      </div>
    </div>
  );

  const renderFileInfo = () => (
    <div className="table-dropdown file-info-container" data-testid="file-info">
      <div className="info-container">
        {attachmentGroups?.map((attachmentGroup) => {
          const latestAttachment = getLatestAttachment(attachmentGroup);
          if (
            !latestAttachment ||
            (isFigmaGroup &&
              latestAttachment.file_type !== attachmentFileType.figma)
          ) {
            return null;
          }

          const integrationFileType =
            latestAttachment.file_type === "figma" ||
            latestAttachment.file_type === "google_drive" ||
            latestAttachment.file_type === "share_point";

          const getIcon = () => {
            switch (latestAttachment.file_type) {
              case "figma":
                return "figma";
              case "share_point":
                return "sharePoint";
              case "google_drive":
                return "google";
              default:
            }
          };

          return (
            <div
              key={`attachment-group-${attachmentGroup.id}`}
              className="file-info file-select-display-file"
            >
              <div className="file-select-display-container">
                {integrationFileType && (
                  <div className="file-select-download">
                    <a
                      target="_blank"
                      href={latestAttachment.url || undefined}
                      rel="noreferrer"
                      className="integration-icon-link"
                      data-testid="integration-icon-link"
                    >
                      <Icon name={getIcon()} />
                    </a>
                  </div>
                )}
                <div
                  className="file-info-name"
                  data-testid="file-select-filename"
                  onClick={renderFileNavigationInfo}
                >
                  {latestAttachment.original?.filename}
                </div>
                <div className="file-select-download">
                  <a
                    target={
                      ["device", "direct_upload", null].includes(
                        latestAttachment.file_type,
                      )
                        ? ""
                        : "_blank"
                    }
                    rel="noreferrer"
                    className="attachment-download"
                    data-testid="attachments-cell-download"
                    onClick={(event) => {
                      event.stopPropagation();
                      download(
                        latestAttachment.id,
                        latestAttachment.file_type,
                        attachmentGroup.id,
                      );
                    }}
                  >
                    <Icon name="download" />
                  </a>
                </div>
              </div>
              <UploadDate date={latestAttachment.upload_date} />
            </div>
          );
        })}
      </div>
    </div>
  );

  // Variables
  const isComplaintsOrQaTestsView =
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    ["complaints", "qa_tests_development"].includes(themisModuleIdentifier) &&
    filterAttachedDocumentGroupsOut(attachmentGroups).length > 0 &&
    isSupportedViewType;
  const isQaTestsDevelopmentView =
    themisModuleIdentifier === "qa_tests_development" &&
    isSupportedViewType &&
    isMonitoringTestingDocuments;
  const isSupportedModuleView =
    ["complaints", "qa_tests_development", "new_product_approval"].includes(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      themisModuleIdentifier,
    ) && isSupportedViewType;
  const isNewProductApprovalView =
    themisModuleIdentifier === "new_product_approval" && isSupportedViewType;

  const moduleIdentifier = themisModuleIdentifier
    ? kebabCase(themisModuleIdentifier)
    : "";
  const attachmentViewURL = `/workspaces/${workspaceID}/modules/${moduleIdentifier}/attachment_view/${recordVersionID}/${attachmentGroups?.[0]?.id}`;

  const qaTestsPreviewURL = `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/preview/${attachedDocumentID}`;
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const changeManagementAttachmentViewURL = `/workspaces/${workspaceID}/modules/change-management/attachment_view/${recordVersionID}/${attachmentGroups[0]?.id}`;
  const customAttachmentViewURL = `/workspaces/${workspaceID}/modules/${moduleIdentifier}/attachment_view/${recordVersionID}/${attachmentGroups?.[0]?.id}`;
  const newDocumentPreviewURL = `/workspaces/${workspaceID}/modules/${moduleIdentifier}/document_preview/${recordVersionID}/${attachmentGroups?.[0]?.id}`;

  const renderFileActions = () => (
    <div className="file-actions" data-testid="file-actions">
      {viewPopup === "file-upload" && someIntegrationsEnabled && (
        <FileTypeSelectionPopup
          width={width}
          onFileUpload={() => setViewPopup("device-upload")}
          onGoogleDriveUpload={
            googleEnabled ? () => handleOpenGoogleDrivePicker() : undefined
          }
          onFigmaUpload={
            figmaEnabled && files?.length === 0
              ? handleOpenFigmaUploader
              : undefined
          }
          onSharePointUpload={
            sharePointEnabled
              ? () => setViewPopup("share-point-upload")
              : undefined
          }
        />
      )}

      {viewPopup === "file-upload" &&
        !someIntegrationsEnabled &&
        directUploadProvider}

      {viewPopup === "device-upload" && directUploadProvider}
      {viewPopup === "figma-upload" && figmaUploader}
      {viewPopup === "share-point-upload" && (
        <SharePointFilePicker
          recordVersionID={recordVersionID}
          fieldName={fieldName}
          onPickerClose={() => setViewPopup(null)}
          onPickerUploading={() => setViewPopup("share-point-loading")}
        />
      )}
      {viewPopup === "google-drive-loading" && (
        <FileUploadLoading dataTestID="g-drive-upload-loading" icon="google" />
      )}
      {viewPopup === "share-point-loading" && (
        <FileUploadLoading dataTestID="share-point-loading" icon="sharePoint" />
      )}
      {viewPopup === "file-changes" && (
        <FileUploadChanges
          // @ts-expect-error TS(2322) FIXME: Type '"audits" | "new_product_approval" | "complai... Remove this comment to see the full error message
          moduleIdentifier={themisModuleIdentifier}
          handleNext={() => {
            if (canUpdateApprovers) {
              setViewPopup("approvals-confirmation");
            } else {
              setShowPopup(false);
            }
          }}
          recordVersionID={recordVersionID}
        />
      )}
      {viewPopup === "approvals-confirmation" && (
        // @ts-expect-error TS(2741) FIXME: Property 'skipReset' is missing in type '{ recordV... Remove this comment to see the full error message
        <FileUploadResetApprovers
          recordVersionID={recordVersionID}
          handleNext={() => setShowPopup(false)}
        />
      )}

      {viewPopup === "figma-live-embed" && (
        <div className="figma-live-embed-container">
          <button onClick={handleScreenshot}>Add Comments</button>
          <iframe
            height="100%"
            width="100%"
            src={`https://www.figma.com/embed?embed_host=astra&url=${
              openUrl || meta?.url
            }`}
            allowFullScreen
          />
        </div>
      )}

      {viewPopup === "preview-figma-file" && (
        <div
          className="table-dropdown attachment-dropdown"
          data-testid="attachment-cell-dropdown"
        >
          <ul className="file-select-popup-hover-container">
            <FileUploadLoading dataTestID="figma-upload-loading" icon="figma" />
            <li className="hover-container">
              <Icon className="field-icon no-hover-only" name="eye" />
              <Icon
                className="field-icon hover-only"
                name="eye"
                color="brandingHighlightViolet"
              />
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${kebabCase(
                  // @ts-expect-error TS(2345) FIXME: Argument of type '"audits" | "new_product_approval... Remove this comment to see the full error message
                  themisModuleIdentifier,
                  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                )}/attachment_view/${recordVersionID}/${attachmentGroups[0]
                  ?.id}`}
                className="link-creative"
              >
                Preview Creative{areMultipleFilesPresent ? "s" : ""}
              </NavLink>
            </li>
          </ul>
        </div>
      )}

      {viewPopup === "file-attachment" && (
        <div
          className="table-dropdown attachment-dropdown"
          data-testid="attachment-cell-dropdown"
        >
          <ul className="file-select-popup-hover-container">
            {isComplaintsOrQaTestsView && (
              <li className="hover-container">
                <Icon className="field-icon no-hover-only" name="eye" />
                <Icon
                  className="field-icon hover-only"
                  name="eye"
                  color="brandingHighlightViolet"
                />
                <NavLink to={attachmentViewURL} className="link-creative">
                  View Attachment{areMultipleFilesPresent ? "s" : ""}
                </NavLink>
              </li>
            )}

            {isQaTestsDevelopmentView && (
              <li className="hover-container">
                <Icon className="field-icon no-hover-only" name="eye" />
                <Icon
                  className="field-icon hover-only"
                  name="eye"
                  color="brandingHighlightViolet"
                />

                <NavLink to={qaTestsPreviewURL} className="link-creative">
                  View Attachment{areMultipleFilesPresent ? "s" : ""}
                </NavLink>
              </li>
            )}

            {isNewProductApprovalView && (
              <li className="hover-container">
                <Icon className="field-icon no-hover-only" name="eye" />
                <Icon
                  className="field-icon hover-only"
                  name="eye"
                  color="brandingHighlightViolet"
                />
                <NavLink
                  to={changeManagementAttachmentViewURL}
                  className="link-creative"
                >
                  View Attachment{areMultipleFilesPresent ? "s" : ""}
                </NavLink>
              </li>
            )}

            {!isSupportedModuleView && !isFileVersioningEnabled && (
              <li
                className="hover-container"
                data-testid="view-attachment-button"
              >
                <Icon className="field-icon no-hover-only" name="eye" />
                <Icon
                  className="field-icon hover-only"
                  name="eye"
                  color="brandingHighlightViolet"
                />
                <NavLink to={customAttachmentViewURL} className="link-creative">
                  View Attachment{areMultipleFilesPresent ? "s" : ""}
                </NavLink>
              </li>
            )}

            {isFileVersioningEnabled && (
              <li
                className="hover-container"
                data-testid="view-attachment-button"
              >
                <Icon className="field-icon no-hover-only" name="eye" />
                <Icon
                  className="field-icon hover-only"
                  name="eye"
                  color="brandingHighlightViolet"
                />
                <NavLink to={newDocumentPreviewURL} className="link-creative">
                  View Attachment{areMultipleFilesPresent ? "s" : ""}
                </NavLink>
              </li>
            )}

            {canFileBeOpenedIn(attachmentFileType.figma) && (
              <>
                <li>
                  <Icon name="figma" spaceRight />
                  <a
                    href={openUrl || meta?.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link-creative"
                  >
                    Open in Figma
                  </a>
                </li>
                <li onClick={() => setViewPopup("figma-live-embed")}>
                  <Icon name="figma" spaceRight />
                  <span>Figma live embed</span>
                </li>
                {!isLockedTable && !isApprovedMarketing && (
                  <li
                    className="hover-container"
                    onClick={() => resync(attachmentFileType.figma)}
                  >
                    <Icon className="field-icon no-hover-only" name="sync" />
                    <Icon
                      className="field-icon hover-only"
                      name="sync"
                      color="brandingHighlightViolet"
                    />
                    <span>Resync from Figma</span>
                  </li>
                )}
              </>
            )}

            {canFileBeOpenedIn(attachmentFileType.google_drive) && (
              <>
                {!isLockedTable && (
                  <li>
                    <Icon name="google" spaceRight />
                    <a
                      href={openUrl || meta?.url}
                      target="_blank"
                      rel="noreferrer"
                      className="link-creative"
                    >
                      Open in Google Drive
                    </a>
                  </li>
                )}
                {!isLockedTable && !isApprovedMarketing && (
                  <li
                    className="hover-container"
                    onClick={() => resync(attachmentFileType.google_drive)}
                    data-testid="google-resync-button"
                  >
                    <Icon className="field-icon no-hover-only" name="sync" />
                    <Icon
                      className="field-icon hover-only"
                      name="sync"
                      color="brandingHighlightViolet"
                    />
                    <span>Resync from Google Drive</span>
                  </li>
                )}
              </>
            )}

            {canFileBeOpenedIn(attachmentFileType.share_point) && (
              <>
                {fileTypes.length === 1 && !isLockedTable && (
                  <li>
                    <Icon name="sharePoint" spaceRight />
                    <a
                      href={openUrl || meta?.url}
                      target="_blank"
                      rel="noreferrer"
                      className="link-creative"
                    >
                      Open in Microsoft SharePoint
                    </a>
                  </li>
                )}
                {!isLockedTable && !isApprovedMarketing && (
                  <li
                    className="hover-container"
                    onClick={() => resync(attachmentFileType.share_point)}
                    data-testid="sharepoint-resync-button"
                  >
                    <Icon className="field-icon no-hover-only" name="sync" />
                    <Icon
                      className="field-icon hover-only"
                      name="sync"
                      color="brandingHighlightViolet"
                    />
                    <span>Resync from Microsoft SharePoint</span>
                  </li>
                )}
              </>
            )}

            {!isLockedTable &&
              !isLockedRow &&
              !locked &&
              hasModuleWriteAccess && (
                <>
                  {!isFigmaGroup && themisModuleIdentifier !== "marketing" && (
                    <li
                      className="hover-container"
                      data-testid="attachment-cell-dropdown-replace-trigger"
                      onClick={() => {
                        if (field?.is_file_versioning_enabled) {
                          setViewPopup("device-upload");
                        } else {
                          setViewPopup("file-upload");
                        }

                        setUpload("");

                        if (!isMultiple && attachmentGroups?.length) {
                          setReplacedAttachmentId(
                            // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                            getLatestAttachment(attachmentGroups[0]).id,
                          );
                        }
                      }}
                    >
                      <Icon className="field-icon no-hover-only" name="edit" />
                      <Icon
                        className="field-icon hover-only"
                        name="edit"
                        color="brandingHighlightViolet"
                      />
                      <span>
                        {isMultiple
                          ? "Upload New Attachment"
                          : "Delete All and Replace"}
                      </span>
                    </li>
                  )}

                  {!isFigmaGroup &&
                    !isGoogleDrive &&
                    !isSharePoint &&
                    !isMultiple &&
                    field?.is_file_versioning_enabled && (
                      <li
                        className="hover-container"
                        data-testid="attachment-cell-dropdown-upload-new-version-trigger"
                        onClick={() => {
                          if (attachmentGroups?.length) {
                            setViewPopup("device-upload");
                            setUpload("");
                            setAddToAttachmentGroupID(attachmentGroups[0].id);
                          }
                        }}
                      >
                        <Icon
                          className="field-icon no-hover-only"
                          name="sync"
                        />
                        <Icon
                          className="field-icon hover-only"
                          name="sync"
                          color="brandingHighlightViolet"
                        />
                        <span>Upload New Version</span>
                      </li>
                    )}

                  {!locked &&
                    filterAttachedDocumentGroupsOut(attachmentGroups).length >
                      0 && (
                      <>
                        <hr />
                        <li
                          className="hover-container"
                          data-testid="attachment-cell-dropdown-delete-trigger"
                          onClick={showDeleteConfirmation}
                        >
                          <Icon
                            className="field-icon no-hover-only"
                            name="trash"
                          />
                          <Icon
                            className="field-icon hover-only"
                            name="trash"
                            color="brandingHighlightViolet"
                          />
                          Delete {areMultipleFilesPresent ? "All" : ""}
                        </li>
                      </>
                    )}
                </>
              )}
          </ul>
        </div>
      )}

      {viewPopup === "file-delete-confirmation" && (
        <ConfirmationDialog
          containerClass="table-dropdown"
          heading={`Delete Attachment${areMultipleFilesPresent ? "s" : ""}`}
          content={`Are you sure you want to delete ${
            areMultipleFilesPresent ? "the" : "this"
          } attachment${areMultipleFilesPresent ? "s" : ""}?`}
          handleConfirm={onDelete}
          handleReject={() => setShowPopup(false)}
          data-testid="confirmation-dialog"
        />
      )}
    </div>
  );

  const renderFileNavigationInfo = () => {
    if (isComplaintsOrQaTestsView) {
      history.push(attachmentViewURL);
    }

    if (isQaTestsDevelopmentView) {
      history.push(qaTestsPreviewURL);
    }

    if (isNewProductApprovalView) {
      history.push(changeManagementAttachmentViewURL);
    }

    if (!isSupportedModuleView) {
      history.push(customAttachmentViewURL);
    }
  };

  const noFileActions =
    showFileActions &&
    (isLockedRow || isLockedTable || locked) &&
    !isSupportedViewType;

  const popup = (
    <Popup
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => (
        <li
          className={popupClasses}
          style={style}
          data-testid="attachments-cell-trigger"
          data-field-name={fieldName}
          onClick={handleShowFileActions}
        >
          <div className="cell-content">{renderTrigger()}</div>
        </li>
      )}
      onOpen={() => setShowPopup(true)}
      keepTooltipInside
      open={showPopup}
      onClose={onClose}
      overlayStyle={isModal ? { background: "rgba(0, 0, 0, .4)" } : {}}
      modal={isModal}
      arrow={!noFileActions}
      disabled={disabled || isCurrentWorkspaceArchived}
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      on={isInTableView && files.length > 0 && showFileInfo ? "hover" : "click"}
    >
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {showFileInfo && isInTableView && files.length > 0 && renderFileInfo()}
      {showFileActions && renderFileActions()}
    </Popup>
  );

  if (errorMessage !== undefined) {
    return (
      <>
        {showPopup && popup}
        {!showPopup && (
          <Popup
            position="bottom right"
            // eslint-disable-next-line react/no-unstable-nested-components
            trigger={() => (
              <li
                className={popupClasses}
                style={style}
                onClick={() => {
                  setViewPopup("file-upload");
                  setShowPopup(true);
                }}
              >
                <div className="cell-content">{renderTrigger()}</div>
              </li>
            )}
            on="hover"
            open={viewPopup === "file-error"}
            onOpen={() => setViewPopup("file-error")}
            onClose={() => setViewPopup(null)}
            keepTooltipInside
          >
            <div className="table-dropdown error">
              <ul className="errors">
                <li>
                  <img src={warningIcon} alt="warning-icon" />
                  {errorMessage}
                </li>
              </ul>
            </div>
          </Popup>
        )}
      </>
    );
  }

  if (
    !isLockedRow ||
    Number(files?.length) > 0 ||
    Number(attachmentGroups?.length) > 0
  ) {
    return popup;
  }

  return (
    <li
      className={popupClasses}
      style={style}
      data-testid="attachments-cell-trigger"
    >
      <div className="cell-content">
        <div style={{ position: "relative" }}>
          <span>+ Add a file</span>
        </div>
      </div>
    </li>
  );
}

FileSelect.defaultProps = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
  width: "100%",
  hasErrors: false,
  isLockedTable: false,
  isLockedRow: false,
  isMultiple: false,
  attachmentGroups: [],
  hasErrorClass: "has-errors",
  disabled: false,
  onAfterFileUpload: () => {},
  isInTableView: false,
};

export default observer(FileSelect);
