import { faker } from "@faker-js/faker";

import type {
  ListRecordFindings200,
  ListRecordFindingsPathParams,
  ListRecordFindingsQueryParams,
  ListRecordFindingsQueryResponse,
} from "../models/ListRecordFindings";
import { createFinding } from "./createFinding";

export function createListRecordFindingsPathParams(): NonNullable<ListRecordFindingsPathParams> {
  return { record_id: faker.number.int() };
}

export function createListRecordFindingsQueryParams(): NonNullable<ListRecordFindingsQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
  };
}

/**
 * @description OK
 */
export function createListRecordFindings200(): NonNullable<ListRecordFindings200> {
  return { data: faker.helpers.arrayElements([createFinding()]) as any };
}

/**
 * @description OK
 */
export function createListRecordFindingsQueryResponse(): NonNullable<ListRecordFindingsQueryResponse> {
  return { data: faker.helpers.arrayElements([createFinding()]) as any };
}
