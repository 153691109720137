import { faker } from "@faker-js/faker";

import type {
  GetDashboards200,
  GetDashboardsPathParams,
  GetDashboardsQueryParams,
  GetDashboardsQueryResponse,
} from "../models/GetDashboards";
import { createDashboard } from "./createDashboard";

export function createGetDashboardsPathParams(): NonNullable<GetDashboardsPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createGetDashboardsQueryParams(): NonNullable<GetDashboardsQueryParams> {
  return {
    system_name: faker.helpers.arrayElement<any>(["retrospective", "outlook"]),
    filtered_workspace_ids: faker.helpers.arrayElements([
      faker.number.int(),
    ]) as any,
    start_date: faker.string.alpha(),
    end_date: faker.string.alpha(),
    days: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createGetDashboards200(): NonNullable<GetDashboards200> {
  return { data: faker.helpers.arrayElements([createDashboard()]) as any };
}

/**
 * @description OK
 */
export function createGetDashboardsQueryResponse(): NonNullable<GetDashboardsQueryResponse> {
  return { data: faker.helpers.arrayElements([createDashboard()]) as any };
}
