import { http } from "msw";

import { createControlMappingsDetailsQueryResponse } from "../createControlMappingsDetails";

export const controlMappingsDetailsHandler = http.get(
  "*/control_mappings/details",
  function handler(info) {
    return new Response(
      JSON.stringify(createControlMappingsDetailsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
