import { faker } from "@faker-js/faker";

import type {
  ListRecordLinks200,
  ListRecordLinksPathParams,
  ListRecordLinksQueryResponse,
} from "../models/ListRecordLinks";
import { createRecordLink } from "./createRecordLink";
import { createRecordType } from "./createRecordType";

export function createListRecordLinksPathParams(): NonNullable<ListRecordLinksPathParams> {
  return { record_type: createRecordType(), record_id: faker.number.int() };
}

/**
 * @description List of record links
 */
export function createListRecordLinks200(): NonNullable<ListRecordLinks200> {
  return {
    data: {
      record_links_from: faker.helpers.arrayElements([
        createRecordLink(),
      ]) as any,
      record_links_to: faker.helpers.arrayElements([createRecordLink()]) as any,
    },
  };
}

/**
 * @description List of record links
 */
export function createListRecordLinksQueryResponse(): NonNullable<ListRecordLinksQueryResponse> {
  return {
    data: {
      record_links_from: faker.helpers.arrayElements([
        createRecordLink(),
      ]) as any,
      record_links_to: faker.helpers.arrayElements([createRecordLink()]) as any,
    },
  };
}
