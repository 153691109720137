import { faker } from "@faker-js/faker";

import type { Country } from "../models/Country";

export function createCountry(
  data: NonNullable<Partial<Country>> = {},
): NonNullable<Country> {
  return {
    ...{ name: faker.string.alpha(), code: faker.string.alpha() },
    ...data,
  };
}
