import { http } from "msw";

import { createCreateRecordLinkMutationResponse } from "../createCreateRecordLink";

export const createRecordLinkHandler = http.post(
  "*/:record_type/:record_id/record_links",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateRecordLinkMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
