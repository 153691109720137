import { http } from "msw";

import { createCreateTaskMutationResponse } from "../createCreateTask";

export const createTaskHandler = http.post(
  "*/companies/:company_id/tasks",
  function handler(info) {
    return new Response(JSON.stringify(createCreateTaskMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
