import { http } from "msw";

import { createListOfCompanyUsersQueryResponse } from "../createListOfCompanyUsers";

export const listOfCompanyUsersHandler = http.get(
  "*/companies/:company_id/users",
  function handler(info) {
    return new Response(
      JSON.stringify(createListOfCompanyUsersQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
