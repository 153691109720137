import { observer } from "mobx-react";
import React, { useState } from "react";
import { generatePath, useHistory } from "react-router";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";
import { Icon } from "@/components/Elements";
import { formatPercentage } from "@/components/helpers/number-formatters";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import EmailAttest from "@/components/table/policy/EmailAttest";
import { useMainStore } from "@/contexts/Store";
import { type QuestionnaireRead } from "@/features/risk-assessment";
import type { Option } from "@/features/risk-assessment/components/RowOptions/RowOptions";
import RowOptions from "@/features/risk-assessment/components/RowOptions/RowOptions";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableCellSpacer from "@/features/risk-assessment/components/Table/GenericTable/TableCellSpacer";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import { getQuestionnaireStatusLabel } from "@/features/risk-assessment/utils/questionnaire-helpers";

import { routes } from "../../../routes";
import UploadQuestionnaireModal from "./UploadQuestionnaireModal";

interface PendingQuestionnaireListProps {
  questionnaires: Array<QuestionnaireRead>;
  onRefreshQuestionnaire: () => void;
}

const PendingQuestionnaireList = (props: PendingQuestionnaireListProps) => {
  const history = useHistory();
  const mainStore = useMainStore();
  const { id: userID } = mainStore.users.user;
  const { isAdmin, workspaceID } = mainStore.context;
  const [questionnaireId, setQuestionnaireId] = useState<number | null>(null);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const { active_workspace: activeWorkspace } = mainStore.users.user;

  const copyLink = async (externalId: string) => {
    await navigator.clipboard.writeText(
      `${location.host}${routes.QUESTIONNAIRE_FORM_PATH}/${externalId}`,
    );
    mainStore.toast.setInfoText("Link copied!");
  };

  const closeSlideMenu = () => {
    setShowSlideMenu(false);
  };

  const copyPassword = async (password: string) => {
    await navigator.clipboard.writeText(password);
    mainStore.toast.setInfoText("Password copied!");
  };

  const completeQuestionnaire = async (id: number) => {
    await QuestionnaireAPI.complete(id);
    props.onRefreshQuestionnaire();
  };

  const downloadQuestionnaire = (id: number) =>
    QuestionnaireAPI.downloadQuestionnaire(id);

  const generateOptions = (questionnaire: QuestionnaireRead): Option[] => {
    const options: Option[] = [];

    if (activeWorkspace?.is_internal) {
      const canAnswer =
        isAdmin ||
        questionnaire.responder_ids?.length === 0 ||
        (userID && questionnaire.responder_ids.includes(userID));
      if (canAnswer && questionnaire.is_internal) {
        options.push({
          label: "Answer Questionnaire",
          onClick: () =>
            history.push(
              generatePath(routes.INTERNAL_QUESTIONNAIRE_REVIEW_PATH, {
                questionnaireId: questionnaire.id,
                workspace_id: Number(workspaceID),
              }),
            ),
        });

        options.push({
          label: "Import Answers",
          onClick: () => setQuestionnaireId(questionnaire.id),
        });

        if (questionnaire.completion_percentage === 1) {
          options.push({
            label: "Submit for Review",
            onClick: () => completeQuestionnaire(questionnaire.id),
          });
        }

        options.push(
          {
            label: "Copy Link",
            disabled: true,
            disabledTooltip:
              "This action is disabled for internal questionnaires",
            onClick: () => questionnaire.external_id,
          },
          {
            label: "Send Questionnaire",
            disabled: true,
            disabledTooltip:
              "This action is disabled for internal questionnaires",
            onClick: () => questionnaire,
          },
          {
            label: "Copy password",
            disabled: true,
            disabledTooltip:
              "This action is disabled for internal questionnaires",
            onClick: () => questionnaire.external_password,
          },
        );
      } else if (!questionnaire.is_internal) {
        if (questionnaire.completion_percentage === 1) {
          options.push({
            label: "Submit for Review",
            onClick: () => completeQuestionnaire(questionnaire.id),
          });
        }
        options.push(
          {
            label: "Copy Link",
            onClick: () => copyLink(questionnaire.external_id),
          },
          {
            label: "Send Questionnaire",
            onClick: () => setShowSlideMenu(true),
          },
        );
      }

      if (questionnaire.external_password) {
        options.push({
          label: "Copy password",
          onClick: () => copyPassword(questionnaire.external_password || ""),
        });
      }
    }

    options.push(
      {
        label: "View Progress",
        onClick: () =>
          history.push(
            generatePath(routes.QUESTIONNAIRE_REVIEW_PATH, {
              questionnaireId: questionnaire.id,
              workspace_id: Number(workspaceID),
            }),
          ),
      },
      {
        label: "Export",
        onClick: () => downloadQuestionnaire(questionnaire.id),
      },
    );

    if (!questionnaire.is_internal && activeWorkspace?.is_internal) {
      options.push({
        label: "Import Answers",
        onClick: () => setQuestionnaireId(questionnaire.id),
      });
    }

    return options;
  };

  return (
    <>
      <Table
        className="pending-questionnaires-table"
        header={
          <>
            <TableHeaderCell
              title="Status"
              leftIcon={
                <Icon color="extrasBlueGrayDarker" name="folderLinear" />
              }
              firstDataHeader
            />
            <TableHeaderCell
              title="Percent Answered"
              leftIcon={
                <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
              }
            />
            <TableHeaderCell
              title="Name"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="book1" />}
            />
            <TableHeaderCell
              title="Inherent Questions"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="number" />}
            />
            <TableHeaderCell
              title="Control Questions"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="number" />}
              lastDataHeader
            />
            <TableCellSpacer />
          </>
        }
      >
        {props.questionnaires.map((questionnaire, index) => (
          <TableRow key={questionnaire.id} rowIndex={index}>
            <ContentCell
              content={getQuestionnaireStatusLabel(
                questionnaire.status,
                questionnaire.completion_percentage,
              )}
              isFirstDataCell
            />
            <ContentCell
              content={formatPercentage(questionnaire.completion_percentage)}
            />
            <ContentCell content={questionnaire.name} />
            <ContentCell
              content={questionnaire.inherent_questions_count || "0"}
            />
            <ContentCell
              content={questionnaire.control_questions_count || "0"}
              isLastDataCell
            />
            <ActionCell>
              <RowOptions options={generateOptions(questionnaire)} />
            </ActionCell>
          </TableRow>
        ))}
        <UploadQuestionnaireModal
          isOpen={!!questionnaireId}
          onClose={() => setQuestionnaireId(null)}
          onFileAdded={async (file) => {
            try {
              await QuestionnaireAPI.importQuestionnaire(
                questionnaireId!,
                file,
              );
              props.onRefreshQuestionnaire();
            } catch (err) {
              mainStore.toast.setErrorFromResponse(err);
            } finally {
              setQuestionnaireId(null);
            }
          }}
        />
      </Table>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        <EmailAttest
          moduleWorkspaceID={mainStore.context.moduleWorkspaceID!}
          questionnaireID={questionnaireId!}
          hideDepartment
        />
      </SlideMenu>
    </>
  );
};

export default observer(PendingQuestionnaireList);
