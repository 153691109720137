import { http } from "msw";

import { createApiAuthGetQueryResponse } from "../createApiAuthGet";

export const apiAuthGetHandler = http.get(
  "*/integrations/api_auth",
  function handler(info) {
    return new Response(JSON.stringify(createApiAuthGetQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
