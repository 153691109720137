import { faker } from "@faker-js/faker";

import type {
  ShowTraining200,
  ShowTrainingPathParams,
  ShowTrainingQueryResponse,
} from "../models/ShowTraining";
import { createTraining } from "./createTraining";

export function createShowTrainingPathParams(): NonNullable<ShowTrainingPathParams> {
  return { id: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createShowTraining200(): NonNullable<ShowTraining200> {
  return { data: createTraining() };
}

/**
 * @description OK
 */
export function createShowTrainingQueryResponse(): NonNullable<ShowTrainingQueryResponse> {
  return { data: createTraining() };
}
