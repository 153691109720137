import { faker } from "@faker-js/faker";

import type { Contact } from "../models/Contact";

export function createContact(
  data: NonNullable<Partial<Contact>> = {},
): NonNullable<Contact> {
  return {
    ...{
      id: faker.number.int(),
      full_name: faker.string.alpha(),
      initials: faker.string.alpha(),
      first_name: faker.string.alpha(),
      last_name: faker.string.alpha(),
      middle_name: faker.string.alpha(),
      phone: faker.string.alpha(),
      email: faker.string.alpha(),
    },
    ...data,
  };
}
