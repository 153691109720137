import { http } from "msw";

import { createApiAuthUpdateMutationResponse } from "../createApiAuthUpdate";

export const apiAuthUpdateHandler = http.put(
  "*/integrations/api_auth/:id",
  function handler(info) {
    return new Response(JSON.stringify(createApiAuthUpdateMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
