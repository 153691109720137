import type {
  CreateFollowupQuestion201,
  CreateFollowupQuestion403,
  CreateFollowupQuestionMutationRequest,
  CreateFollowupQuestionMutationResponse,
} from "../models/CreateFollowupQuestion";
import { createFollowupQuestionRequest } from "./createFollowupQuestionRequest";
import { createQuestion } from "./createQuestion";

/**
 * @description OK
 */
export function createCreateFollowupQuestion201(): NonNullable<CreateFollowupQuestion201> {
  return { data: createQuestion() };
}

/**
 * @description Forbidden
 */
export function createCreateFollowupQuestion403(): NonNullable<CreateFollowupQuestion403> {
  return { errors: {} };
}

export function createCreateFollowupQuestionMutationRequest(): NonNullable<CreateFollowupQuestionMutationRequest> {
  return createFollowupQuestionRequest();
}

/**
 * @description OK
 */
export function createCreateFollowupQuestionMutationResponse(): NonNullable<CreateFollowupQuestionMutationResponse> {
  return { data: createQuestion() };
}
