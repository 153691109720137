import { faker } from "@faker-js/faker";

import type {
  CreateTaskable201,
  CreateTaskableMutationRequest,
  CreateTaskableMutationResponse,
  CreateTaskablePathParams,
} from "../models/CreateTaskable";
import { createTaskable } from "./createTaskable";

export function createCreateTaskablePathParams(): NonNullable<CreateTaskablePathParams> {
  return { task_id: faker.number.int() };
}

/**
 * @description Taskable created
 */
export function createCreateTaskable201(): NonNullable<CreateTaskable201> {
  return { data: createTaskable() };
}

export function createCreateTaskableMutationRequest(): NonNullable<CreateTaskableMutationRequest> {
  return {
    taskable: {
      target_id: faker.number.float(),
      target_type: faker.string.alpha(),
    },
  };
}

/**
 * @description Taskable created
 */
export function createCreateTaskableMutationResponse(): NonNullable<CreateTaskableMutationResponse> {
  return { data: createTaskable() };
}
