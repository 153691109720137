import { faker } from "@faker-js/faker";

import type {
  ArchiveWorkspace200,
  ArchiveWorkspace403,
  ArchiveWorkspaceMutationResponse,
  ArchiveWorkspacePathParams,
} from "../models/ArchiveWorkspace";
import { createWorkspace } from "./createWorkspace";

export function createArchiveWorkspacePathParams(): NonNullable<ArchiveWorkspacePathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createArchiveWorkspace200(): NonNullable<ArchiveWorkspace200> {
  return createWorkspace();
}

/**
 * @description Forbidden
 */
export function createArchiveWorkspace403(): NonNullable<ArchiveWorkspace403> {
  return {};
}

/**
 * @description OK
 */
export function createArchiveWorkspaceMutationResponse(): NonNullable<ArchiveWorkspaceMutationResponse> {
  return createWorkspace();
}
