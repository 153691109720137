import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupChildResults200,
  WebsiteMonitoringGroupChildResultsPathParams,
  WebsiteMonitoringGroupChildResultsQueryParams,
  WebsiteMonitoringGroupChildResultsQueryResponse,
} from "../models/WebsiteMonitoringGroupChildResults";
import { createMonitoringGroupAssetChild } from "./createMonitoringGroupAssetChild";

export function createWebsiteMonitoringGroupChildResultsPathParams(): NonNullable<WebsiteMonitoringGroupChildResultsPathParams> {
  return { monitoring_group_asset_child_id: faker.number.int() };
}

export function createWebsiteMonitoringGroupChildResultsQueryParams(): NonNullable<WebsiteMonitoringGroupChildResultsQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
  };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupChildResults200(): NonNullable<WebsiteMonitoringGroupChildResults200> {
  return { data: createMonitoringGroupAssetChild() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupChildResultsQueryResponse(): NonNullable<WebsiteMonitoringGroupChildResultsQueryResponse> {
  return { data: createMonitoringGroupAssetChild() };
}
