import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListTrainingsPathParams,
  ListTrainingsQueryParams,
  ListTrainingsQueryResponse,
} from "../../models/ListTrainings";

/**
 * @description List trainings
 * @link /workspaces/:workspace_id/trainings
 */
export async function listTrainings(
  workspaceId: ListTrainingsPathParams["workspace_id"],
  params?: ListTrainingsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListTrainingsQueryResponse>["data"]> {
  const res = await client<ListTrainingsQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/trainings`,
    params,
    ...options,
  });
  return res.data;
}
