import { SimpleTooltip } from "@themis/ui";
import React from "react";
import type { ReactElement } from "react";
import { PiWarningBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { FieldConfiguration } from "@/api/gen/models";

interface ColumnHeaderIconProps {
  field: FieldConfiguration;
}

export function ColumnHeaderIcon({
  field,
}: ColumnHeaderIconProps): ReactElement | null {
  const { formatMessage } = useIntl();

  if (!field.internal_only) {
    return null;
  }

  return (
    <SimpleTooltip
      tooltipContentProps={{
        side: "bottom",
      }}
      title={formatMessage({
        defaultMessage: "Visible to Internal Users only",
      })}
    >
      <span className="tw-inline-flex">
        <PiWarningBold size="16" className="tw-mr-1 tw-text-primary-300" />
      </span>
    </SimpleTooltip>
  );
}
