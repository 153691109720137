import { faker } from "@faker-js/faker";

import type { ApprovalStep } from "../models/ApprovalStep";

export function createApprovalStep(
  data: NonNullable<Partial<ApprovalStep>> = {},
): NonNullable<ApprovalStep> {
  return {
    ...{
      id: faker.number.int(),
      position: faker.number.int(),
      users_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    },
    ...data,
  };
}
