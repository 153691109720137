import { faker } from "@faker-js/faker";

import type {
  CreateAccount201,
  CreateAccount403,
  CreateAccountMutationRequest,
  CreateAccountMutationResponse,
  CreateAccountPathParams,
} from "../models/CreateAccount";
import { createAccount } from "./createAccount";
import { createModifyAccountRequest } from "./createModifyAccountRequest";

export function createCreateAccountPathParams(): NonNullable<CreateAccountPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateAccount201(): NonNullable<CreateAccount201> {
  return { data: createAccount() };
}

/**
 * @description Forbidden
 */
export function createCreateAccount403(): NonNullable<CreateAccount403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateAccountMutationRequest(): NonNullable<CreateAccountMutationRequest> {
  return {
    account: Object.assign({}, createModifyAccountRequest(), {
      parent_account_id: faker.number.int(),
    }),
  };
}

/**
 * @description OK
 */
export function createCreateAccountMutationResponse(): NonNullable<CreateAccountMutationResponse> {
  return { data: createAccount() };
}
