import { faker } from "@faker-js/faker";

import type {
  CreateBlob201,
  CreateBlob401,
  CreateBlob422,
  CreateBlobMutationRequest,
  CreateBlobMutationResponse,
} from "../models/CreateBlob";
import { createFile } from "./createFile";

/**
 * @description Blob created successfully
 */
export function createCreateBlob201(): NonNullable<CreateBlob201> {
  return { blob: createFile() };
}

/**
 * @description Unauthorized error, such as when the user is not logged in.
 */
export function createCreateBlob401(): NonNullable<CreateBlob401> {
  return { error: faker.string.alpha() };
}

/**
 * @description Bad request error, such as when the URL is invalid or the file could not be downloaded.
 */
export function createCreateBlob422(): NonNullable<CreateBlob422> {
  return { error: faker.string.alpha() };
}

export function createCreateBlobMutationRequest(): NonNullable<CreateBlobMutationRequest> {
  return {
    blob: {
      url: faker.helpers.arrayElement<any>([
        faker.string.alpha(),
        faker.internet.url(),
      ]),
    },
  };
}

/**
 * @description Blob created successfully
 */
export function createCreateBlobMutationResponse(): NonNullable<CreateBlobMutationResponse> {
  return { blob: createFile() };
}
