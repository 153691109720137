import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import IconButton from "../../../Elements/IconButton/icon-button";
import ConfirmationDialog from "../../shared/ConfirmationDialog/confirmation-dialog";
import { RISK_LEVELS_COLORS } from "../constants";

interface Props {
  canDelete?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
}

function RiskAssessmentRiskLevels({ matrix, canDelete, onChange }: Props) {
  const [colorChangePopupOpened, setColorChangePopupOpened] = useState(null);
  const [deletePopupOpened, setDeletePopupOpened] = useState(null);

  const { risk_levels: riskLevels, risk_themes: riskThemes } = matrix;

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleNewRiskLevelTitleChange(evt, index) {
    const data = { title: evt.target.value };
    handleLocalMatrixUpdate({ mode: "update", index, data });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
  function handleSelectRiskLevelColor(index, color) {
    const data = { color_index: RISK_LEVELS_COLORS.indexOf(color) };
    handleLocalMatrixUpdate({ mode: "update", index, data });
  }

  function handleDeleteSubmit() {
    handleLocalMatrixUpdate({ mode: "delete", index: deletePopupOpened });
    setDeletePopupOpened(null);
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'mode' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleLocalMatrixUpdate({ mode, index, data = {} }) {
    let newRiskLevels = riskLevels;
    let newRiskThemes = riskThemes;

    if (mode === "update") {
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      newRiskLevels = riskLevels.map((item, i) =>
        i === index ? { ...item, ...data } : item,
      );
    } else if (mode === "delete") {
      // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
      newRiskLevels = riskLevels.filter((_, i) => i !== index);
      // @ts-expect-error TS(7006) FIXME: Parameter 'riskTheme' implicitly has an 'any' type... Remove this comment to see the full error message
      newRiskThemes = riskThemes.map((riskTheme) => ({
        ...riskTheme,
        risk_descriptions: riskTheme.risk_descriptions.filter(
          // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
          (_, i) => i !== index,
        ),
      }));
    }

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onChange({
      ...matrix,
      risk_levels: newRiskLevels,
      risk_themes: newRiskThemes,
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskLevel' implicitly has an 'any' type... Remove this comment to see the full error message
  const renderRiskLevelItem = (riskLevel, index) => {
    const isDeleting = deletePopupOpened === index;
    const isChangingColor = colorChangePopupOpened === index;

    const colorChangePopupTrigger = (
      <span>
        <span
          style={{ backgroundColor: RISK_LEVELS_COLORS[riskLevel.color_index] }}
        />
      </span>
    );

    const colorChangePopup = (
      <div>
        <div className="table-dropdown vdd-risk-assessment__risk-level-change-color">
          {RISK_LEVELS_COLORS.map((color) => (
            <div
              key={color}
              className={classNames(
                "vdd-risk-assessment__risk-level-context-menu-color",
                {
                  active: RISK_LEVELS_COLORS[riskLevel.color_index] === color,
                },
              )}
              style={{ backgroundColor: color }}
              data-testid="vdd-risk-assessment__risk-level-context-menu-color"
              onClick={() => handleSelectRiskLevelColor(index, color)}
            />
          ))}
        </div>
      </div>
    );

    const deletePopupTrigger = (
      <div>
        <IconButton
          size="sm"
          icon="trash"
          active={isDeleting}
          transparent
          color="generalWhite"
          data-testid="vdd-risk-assessment__risk-level-context-menu-trigger"
        />
      </div>
    );

    const renderDeleteConfirmation = (
      <div className="vdd-risk-assessment__popup">
        <div className="table-dropdown success-dropdown">
          <ConfirmationDialog
            heading="Delete Rating"
            content="Are you sure you want to delete this Rating? Every field under this column will be deleted."
            handleConfirm={handleDeleteSubmit}
            handleReject={() => setDeletePopupOpened(null)}
          />
        </div>
      </div>
    );

    return (
      <div
        key={index}
        className="vdd-risk-assessment__risk-level add-bulk"
        data-testid="vdd-risk-assessment__risk-level"
      >
        <Popup
          position="bottom left"
          trigger={colorChangePopupTrigger}
          open={isChangingColor}
          onOpen={() => setColorChangePopupOpened(index)}
          onClose={() => setColorChangePopupOpened(null)}
          keepTooltipInside
        >
          {colorChangePopup}
        </Popup>

        <input
          type="text"
          data-testid="vdd-risk-assessment__risk-level-input"
          placeholder="eg. Extreme"
          value={riskLevel.title}
          onChange={(evt) => handleNewRiskLevelTitleChange(evt, index)}
        />

        {canDelete && (
          <Popup
            position="bottom right"
            trigger={deletePopupTrigger}
            open={isDeleting}
            onOpen={() => setDeletePopupOpened(index)}
            onClose={() => setDeletePopupOpened(null)}
            keepTooltipInside
          >
            {renderDeleteConfirmation}
          </Popup>
        )}
      </div>
    );
  };

  return (
    <section
      className="vdd-risk-assessment__risk-levels"
      data-testid="vdd-risk-assessment__risk-levels"
    >
      {riskLevels.map(renderRiskLevelItem)}
    </section>
  );
}

RiskAssessmentRiskLevels.defaultProps = {
  canDelete: true,
};

export default RiskAssessmentRiskLevels;
