import "./questionnaire-form.scss";

import { Label, Select } from "@themis/ui";
import classNames from "classnames";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { Button, Flex, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import {
  areFormGroupsValidForSubmit,
  getQuestionsRemainingForGroups,
  mapAnswer,
  mapFormGroupsAndQuestionsToFormGroups,
} from "@/stores/helpers/RiskAssessmentHelpers";

import { QuestionnaireFormApi } from "../../../../../api/legacy/risk-assessment/QuestionnaireFormApi";
import type {
  AutoSaveOptions,
  FlattedAnswer,
  FormQuestion,
  FormQuestionGroup,
  QuestionnaireFormRead,
  QuestionnaireForm as TQuestionnaireForm,
} from "../../../types/questionnaire-form";
import { isDocumentAnswer } from "../../../types/questionnaire-form";
import UserAssignment from "../../UserAssignment";
import QuestionnaireBuilderUserAssignment from "../QuestionnaireBuilderUserAssignment/QuestionnaireBuilderUserAssignment";
import QuestionnaireGroups from "../QuestionnaireGroups/QuestionnaireGroups";
import QuestionsRemaining from "../QuestionsRemaining/QuestionsRemaining";
import QuestionnaireFormQuestion from "./QuestionnaireFormQuestion/QuestionnaireFormQuestion";

interface Props {
  questionnaireForm: QuestionnaireFormRead;
  externalId: string;
  disabled?: boolean;
  setAutoSaveState?: (state: AutoSaveOptions) => void;
  afterSubmit?: (qForm: TQuestionnaireForm) => void;
  hideResponderChange?: boolean;
  onChangeResponders?: (
    assignableID: number,
    userIDs: number[],
    isQuestion: boolean,
  ) => void;
  cwOnlyUserIDs?: number[];
  saveAnswerViaAPI?: (
    questionnaireID: string | number,
    answers: FlattedAnswer[],
    isCompleted?: boolean,
  ) => Promise<QuestionnaireFormRead>;
  showResponderList?: boolean;
  showRespondentFilter?: boolean;
}

function QuestionnaireFormBody({
  questionnaireForm,
  externalId,
  disabled,
  setAutoSaveState,
  afterSubmit,
  onChangeResponders,
  cwOnlyUserIDs = [],
  hideResponderChange = true,
  showResponderList = false,
  showRespondentFilter = false,
  saveAnswerViaAPI = (
    questionnaireID: string | number,
    answers: FlattedAnswer[],
    isCompleted: boolean = false,
  ) =>
    QuestionnaireFormApi.saveAnswers(
      questionnaireID.toString(),
      answers,
      isCompleted,
    ),
}: Props) {
  const mainStore = useMainStore();
  const { isAdmin } = mainStore.context;
  const [form, setForm] = useState<TQuestionnaireForm>({
    ...questionnaireForm,
    external_id: externalId,
    groups: mapFormGroupsAndQuestionsToFormGroups(
      questionnaireForm.questions,
      questionnaireForm.question_groups,
    ),
  });
  const [activeGroupId, setActiveGroupId] = useState(form.groups[0].id);
  const activeGroup = form?.groups.find((group) => group.id === activeGroupId);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedRespondentID, setSelectedRespondentID] = useState<
    null | string
  >(null);

  const [onLastSection, setOnLastSection] = useState(false);
  const [onFirstSection, setOnFirstSection] = useState(true);

  useEffect(() => {
    const activeGroupIndex = form.groups.findIndex(
      (group) => group.id === activeGroupId,
    );

    setOnLastSection(!!(activeGroupIndex + 1 === form.groups.length));
    setOnFirstSection(!!(activeGroupIndex === 0));
  }, [activeGroupId]);

  function filterQuestionsByRespondent(
    questions: FormQuestion[],
  ): FormQuestion[] {
    if (!selectedRespondentID) {
      return questions;
    }

    return questions.filter(
      (question) =>
        question.responders
          .map((assignment) => assignment.user_id)
          .includes(Number(selectedRespondentID)) ||
        form.groups
          .filter((g) =>
            g.responders
              .map((r) => r.user_id)
              .includes(Number(selectedRespondentID)),
          )
          .map((g) => g.id)
          .includes(Number(question.question_group_id)),
    );
  }

  function filterGroupsByRespondent(
    questionGroups: FormQuestionGroup[],
  ): FormQuestionGroup[] {
    if (!selectedRespondentID) {
      return questionGroups;
    }
    return questionGroups.filter(
      (questionGroup) =>
        filterQuestionsByRespondent(questionGroup.questions).length > 0,
    );
  }

  function setAutoSaveStateHelper(state: AutoSaveOptions) {
    if (!setAutoSaveState) {
      return;
    }
    setAutoSaveState(state);
  }

  function flattenAnswers(
    question: FormQuestion,
    output: FlattedAnswer[],
  ): FlattedAnswer[] {
    if (!question) {
      return output;
    }

    output.push({
      id: question.id,
      // @ts-expect-error TS(2322) FIXME: Type 'AnswerUpsert | undefined' is not assignable ... Remove this comment to see the full error message
      answer_attributes: question.answer && mapAnswer(question.answer),
    });

    question.triggerQuestions.forEach((tQuestion) =>
      flattenAnswers(tQuestion, output),
    );

    return output;
  }

  const saveAnswers = async (
    changedQuestion: FormQuestion,
    formAtRequest: TQuestionnaireForm,
  ) => {
    try {
      setAutoSaveStateHelper("saving");

      const formFromResponse = await saveAnswerViaAPI(
        form.external_id,
        flattenAnswers(changedQuestion, []),
      );
      /* update all questions from backend except for questions changed on the
          frontend since the time the api request was sent.
          Patch updates update just the question that changed, but the backend
          returns the entire state of the questionnaire form.
          The idea is to trust the backend for the most recent state that may
          have been updated by other users, except for the question/s the user
          has edited since last auto-save request - for these edited questions
          we we want to trust the state of the frontend.
          Known tradeoff here being the current user will not see other user's
          updates for the question they are currently answering.
      */
      setForm((formAtResponse) => {
        const mergedGroups = mapFormGroupsAndQuestionsToFormGroups(
          formFromResponse.questions,
          formFromResponse.question_groups,
        ).map((groupFromResponse, i) => {
          const groupAtRequest = formAtRequest.groups[i];
          const groupAtResponse = formAtResponse.groups[i];

          return {
            ...groupFromResponse,
            questions: groupFromResponse.questions.map(
              (questionFromResponse, j) => {
                const questionAtRequest = groupAtRequest.questions[j];
                const questionAtResponse = groupAtResponse.questions[j];

                if (questionFromResponse.id === changedQuestion.id) {
                  // Document answers need to be replaced with the file object from the server
                  if (isDocumentAnswer(questionFromResponse.answer)) {
                    return {
                      ...questionFromResponse,
                      answer: {
                        ...questionFromResponse.answer,
                        comment: questionAtResponse.answer.comment,
                      },
                    };
                  }
                  return {
                    ...questionAtResponse,
                    triggerQuestions: questionAtResponse.triggerQuestions.map(
                      (triggerQuestion, questionIndex) => {
                        // Document answers for trigger questions also need to be replaced with the file object from the server
                        if (isDocumentAnswer(triggerQuestion.answer)) {
                          return questionFromResponse.triggerQuestions[
                            questionIndex
                          ];
                        }

                        return triggerQuestion;
                      },
                    ),
                  };
                }
                return isEqual(questionAtRequest, questionAtResponse)
                  ? questionFromResponse
                  : questionAtResponse;
              },
            ),
          };
        });
        return {
          ...formAtResponse,
          groups: mergedGroups,
        };
      });
      setAutoSaveStateHelper("saved");
    } catch (err) {
      setForm(formAtRequest);
      setAutoSaveStateHelper("failed");
    }
  };

  const onQuestionChange = async (
    groupId: number,
    changedQuestion: FormQuestion,
  ) => {
    const previousState = form;
    setForm((prevForm) => ({
      ...prevForm,
      groups: prevForm.groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              questions: group.questions.map((question) =>
                question.id === changedQuestion.id ? changedQuestion : question,
              ),
            }
          : group,
      ),
    }));

    saveAnswers(changedQuestion, previousState);
  };

  const onNext = (): void => {
    const activeGroupIndex = form.groups.findIndex(
      (group) => group.id === activeGroupId,
    );

    setActiveGroupId(form.groups[activeGroupIndex + 1].id);
  };

  const onBack = (): void => {
    const activeGroupIndex = form.groups.findIndex(
      (group) => group.id === activeGroupId,
    );

    setActiveGroupId(form.groups[activeGroupIndex - 1].id);
  };

  const onSubmit = (): void => {
    if (!areFormGroupsValidForSubmit(form.groups)) {
      return mainStore.toast.setErrorText(
        "All questions must be answered before submitting",
      );
    }

    setIsSaving(true);

    saveAnswerViaAPI(form.external_id, [], true)
      .then((new_form) => {
        const modifiedForm = {
          ...new_form,
          groups: mapFormGroupsAndQuestionsToFormGroups(
            new_form.questions,
            new_form.question_groups,
          ),
          completed: true,
        };
        setForm(modifiedForm);
        if (afterSubmit) {
          afterSubmit(modifiedForm);
        }
      })
      .catch((error) => {
        window.console.log(error);
        mainStore.toast.setErrorText(
          "There was an error submitting the questionnaire.",
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setForm({
      ...questionnaireForm,
      external_id: externalId,
      groups: mapFormGroupsAndQuestionsToFormGroups(
        questionnaireForm.questions,
        questionnaireForm.question_groups,
      ),
    });
  }, [questionnaireForm]);

  return (
    <div className="questionnaire-form__groups-and-questions">
      <div
        className="questionnaire-form__groups"
        data-testid="questionnaire-form-groups"
      >
        <QuestionnaireGroups
          groups={filterGroupsByRespondent(form.groups)}
          activeGroupId={activeGroupId}
          onActiveGroupChanged={(id) => setActiveGroupId(id)}
          GroupsHeader={
            <Typography
              label="Sections"
              color="generalMidnightDark"
              weight="semiBold"
            />
          }
        />
        <QuestionsRemaining
          count={getQuestionsRemainingForGroups(form.groups)}
        />
        {showRespondentFilter && (
          <>
            <Label>- Repondents -</Label>
            <Select
              items={mainStore.users.allUsers.map((user) => ({
                label: user.full_name || "N/A",
                value: user.id.toString(),
              }))}
              placeholder="Select Respondent"
              selected={selectedRespondentID || "-1"}
              onSelect={(val: string) =>
                val === selectedRespondentID
                  ? setSelectedRespondentID(null)
                  : setSelectedRespondentID(val)
              }
            />
          </>
        )}
      </div>
      <div
        className={classNames("questionnaire-form__questions-wrapper", {
          "questionnaire-form__questions-wrapper": Boolean(onChangeResponders),
        })}
        data-testid="questionnaire-form-questions"
      >
        <Flex justifySpaceBetween fullWidth>
          <div>
            <Typography
              label={activeGroup?.name}
              className="questionnaire-form__group-name"
              color="generalMidnightDark"
              weight="semiBold"
            />
            {showResponderList && isAdmin && (
              <Typography
                label="*You are an admin and can answer any question"
                className="questionnaire-form__group-name"
                color="generalMidnightDark"
                weight="semiBold"
              />
            )}
          </div>

          {!hideResponderChange && onChangeResponders && (
            <UserAssignment
              assignedUsers={mainStore.users.allUsers.filter((user) =>
                (
                  activeGroup?.responders?.map(
                    (responder) => responder.user_id,
                  ) || []
                ).includes(user.id),
              )}
              assigneeWord="Respondents"
            >
              <QuestionnaireBuilderUserAssignment
                userIDsToInclude={cwOnlyUserIDs}
                selectedIDs={
                  activeGroup?.responders?.map(
                    (responder) => responder.user_id,
                  ) || []
                }
                setSelectedIDs={(assigneeIDs: number[]) =>
                  onChangeResponders(activeGroup?.id || -1, assigneeIDs, false)
                }
              />
            </UserAssignment>
          )}
        </Flex>
        <div className="questionnaire-form__questions">
          {filterQuestionsByRespondent(activeGroup?.questions || []).map(
            (question, i) => (
              <QuestionnaireFormQuestion
                key={question.id}
                order={String(i + 1)}
                question={question}
                onChange={(changedQuestion) =>
                  onQuestionChange(activeGroupId, changedQuestion)
                }
                disableAnswering={question.answer.is_completed}
                onChangeResponders={onChangeResponders}
                cwOnlyUserIDs={cwOnlyUserIDs}
                isInternalQuestionnaire={questionnaireForm.is_internal}
                isExternalId={externalId !== questionnaireForm?.id?.toString()}
                hideResponderChange={hideResponderChange}
                groupResponders={activeGroup?.responders?.map(
                  (assignment) => assignment.user_id,
                )}
                showResponderList={showResponderList}
              />
            ),
          )}
        </div>
        {!disabled && (
          <div className="questionnaire-form__save-buttons">
            {form.groups.length > 1 && (
              <Button
                disabled={isSaving || onFirstSection}
                label="Back"
                onClick={onBack}
                theme="tertiary"
                className="tw-bg-transparent"
              />
            )}
            <Button label="Submit Questionnaire" onClick={onSubmit} />
            {form.groups.length > 1 && (
              <Button
                disabled={isSaving || onLastSection}
                label="Next"
                onClick={onNext}
                theme="tertiary"
                className="tw-bg-transparent"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(QuestionnaireFormBody);
