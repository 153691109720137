import type { RecordType } from "@/api/gen";

import { accountsQueryKey } from "../accounts";
import { FINDING_QUERY_KEYS } from "../findings";
import { LIST_MODULE_ASSESSMENT_QUERY_KEY } from "../moduleAssessments";
import { PROJECTS_QUERY_KEY } from "../projects";
import { TASKS_QUERY_KEY } from "../tasks";
import { getTrainingsQueryKey } from "../training/use-trainings";

export function getRecordsQueryKey({
  recordType,
  workspaceId,
}: {
  recordType: RecordType;
  workspaceId: number;
}) {
  switch (recordType) {
    case "accounts":
      return accountsQueryKey.all;
    case "trainings":
      return getTrainingsQueryKey({ workspaceId });
    case "projects":
      return PROJECTS_QUERY_KEY.all;
    case "findings":
      return FINDING_QUERY_KEYS.all;
    case "tasks":
      return [TASKS_QUERY_KEY];
    case "module_assessments":
      return [LIST_MODULE_ASSESSMENT_QUERY_KEY];
    default:
      throw new Error(`Invalid record type: ${recordType}`);
  }
}
