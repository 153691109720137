import { faker } from "@faker-js/faker";

import type { Reviewable } from "../models/Reviewable";

export function createReviewable(
  data: NonNullable<Partial<Reviewable>> = {},
): NonNullable<Reviewable> {
  return {
    ...{
      id: faker.number.int(),
      identifier: faker.string.alpha(),
      name: faker.string.alpha(),
      path: faker.string.alpha(),
      reviewable_type: faker.helpers.arrayElement<any>([
        "record_version",
        "answer",
      ]),
    },
    ...data,
  };
}
