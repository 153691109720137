import { faker } from "@faker-js/faker";

import type {
  Regions200,
  Regions404,
  RegionsPathParams,
  RegionsQueryResponse,
} from "../models/Regions";
import { createRegion } from "./createRegion";

export function createRegionsPathParams(): NonNullable<RegionsPathParams> {
  return { country_code: faker.string.alpha() };
}

/**
 * @description A list of regions for the specified country
 */
export function createRegions200(): NonNullable<Regions200> {
  return { data: faker.helpers.arrayElements([createRegion()]) as any };
}

/**
 * @description Country not found
 */
export function createRegions404(): NonNullable<Regions404> {
  return {
    errors: faker.helpers.arrayElements([
      { base: faker.string.alpha() },
    ]) as any,
  };
}

/**
 * @description A list of regions for the specified country
 */
export function createRegionsQueryResponse(): NonNullable<RegionsQueryResponse> {
  return { data: faker.helpers.arrayElements([createRegion()]) as any };
}
