import { faker } from "@faker-js/faker";

import type { MonitoringGroupPolicy } from "../models/MonitoringGroupPolicy";

export function createMonitoringGroupPolicy(
  data: NonNullable<Partial<MonitoringGroupPolicy>> = {},
): NonNullable<MonitoringGroupPolicy> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      policy_name: faker.string.alpha(),
      monitoring_group_policy_uid: faker.string.alpha(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
    },
    ...data,
  };
}
