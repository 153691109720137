import { faker } from "@faker-js/faker";

import type { Level } from "../models/Level";

export function createLevel(
  data: NonNullable<Partial<Level>> = {},
): NonNullable<Level> {
  return {
    ...{ color: faker.string.alpha(), title: faker.string.alpha() },
    ...data,
  };
}
