import "../styles.scss";

import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import type { SubmitErrorHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import { Flex, Typography } from "@/components/Elements";
import Loading from "@/components/Loading";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import {
  MODULE_NOTIFICATIONS_BASE_PATH,
  MODULE_NOTIFICATIONS_CREATE_PATH,
  MODULE_NOTIFICATIONS_EDIT_PATH,
  MODULE_NOTIFICATIONS_TAB_PATH,
} from "@/features/notifications/pages/constants";
import type {
  ModuleNotificationLocationState,
  ModuleNotificationParams,
} from "@/features/notifications/types/types";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { useLoading } from "@/hooks/useLoading";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";
import type { NotificationRuleData } from "@/stores/types/notification-rule-types";
import { RELATIVE_TO } from "@/stores/types/notification-rule-types";

import { Criteria } from "../../../components/criteria/criteria";
import { NotificationDetailsHeader } from "../../../components/header/notification-details-header";
import { PSText } from "../../../components/ps-text/ps-text";
import NotificationRecipients from "../../../components/recipients/notification-recipients";
import RightActionBar from "../../../components/right-action-bar/right-action-bar";
import SelectField from "../../../components/select-field/select-field";
import { TimePeriodSelectField } from "../../../components/time-period-select/time-period-select-field";
import { initialCreateValues } from "./constants";
import { parseNotificationRule, serializeFormData } from "./helpers";
import type { DateNotificationFormValues } from "./types";

function DateNotificationsSinglePage({
  moduleWorkspaceID,
}: {
  moduleWorkspaceID: number;
}) {
  const mainStore = useMainStore();
  const history = useHistory();
  const location = useLocation<ModuleNotificationLocationState>();
  const params = useParams<ModuleNotificationParams>();
  const createMatch = useRouteMatch(MODULE_NOTIFICATIONS_CREATE_PATH);

  const { moduleIdentifier } = params;

  const isTrainingEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
  );

  const isRiskAssessment = moduleIdentifier === "risk-assessment";
  const allowedFields = isRiskAssessment
    ? ["due_date", "status", "respondents", "reviewers"]
    : [];

  const {
    data: { field_options, notification_rules },
    fieldsForSelectedModule,
    currentFieldOptions,
  } = mainStore.notificationsRules;

  useEffect(() => {
    if (moduleWorkspaceID) {
      mainStore.notificationsRules.index(moduleWorkspaceID);
      mainStore.notificationsRules.getFields(moduleWorkspaceID);
      mainStore.notificationsRules.getModuleFieldOptions(moduleWorkspaceID);
    }
  }, [moduleWorkspaceID]);

  useEffect(
    () => () => {
      mainStore.notificationsRules.cleanup();
    },
    [],
  );

  const { watch, control, handleSubmit, reset, formState } =
    useForm<DateNotificationFormValues>({
      defaultValues: initialCreateValues,
    });

  useEffect(() => {
    if (notification_rules) {
      reset(
        parseNotificationRule(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'NotificationRuleData | undefined... Remove this comment to see the full error message
          notification_rules?.find(
            (notificationRule) =>
              params.notificationId === String(notificationRule.id),
          ),
        ),
      );
    }
  }, [notification_rules]);

  const getOptions = (
    fieldName: string,
    index?: number,
  ): { value: string; label: string; colorSchemeId?: number }[] => {
    const options: { value: string; label: string }[] = [];

    const filteredFieldsForSelectedModule = allowedFields.length
      ? fieldsForSelectedModule.filter((field) =>
          allowedFields.includes(field.name),
        )
      : fieldsForSelectedModule;

    if (fieldName === "trigger_column") {
      filteredFieldsForSelectedModule.forEach((option) => {
        if (option.data_type === "com.askthemis.types.v1.date") {
          options.push({ value: option.name, label: option.display_name });
        }
      });

      return options;
    }

    if (fieldName === "criteria_name") {
      filteredFieldsForSelectedModule.forEach((option) => {
        if (option.data_type === "com.askthemis.types.v1.option") {
          options.push({ value: option.name, label: option.display_name });
        }
      });

      return options;
    }

    if (fieldName === "criteria_value") {
      // @ts-expect-error TS(2538) FIXME: Type 'readonly (string | number | boolean | string... Remove this comment to see the full error message
      return currentFieldOptions?.[watch(`criteria.${index}.fieldName`)]?.map(
        // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
        (option) => ({
          value: option.name,
          label: option.display_name,
          colorSchemeId: option.color_scheme_id,
        }),
      );
    }

    if (fieldName === "relative") {
      field_options?.[fieldName].forEach((option) => {
        // Remove `same day of` option from condition options if frequency is
        // daily or weekly
        if (
          ["days", "weeks", "months"].includes(watch("frequency")) &&
          option.name === RELATIVE_TO.ON
        ) {
          return;
        }

        options.push({
          value: option.name,
          label: option.display_name,
        });
      });

      return options;
    }

    // @ts-expect-error TS(2322) FIXME: Type '{ value: string; label: string; }[] | undefi... Remove this comment to see the full error message
    return field_options?.[fieldName].map((option) => ({
      value: option.name,
      label: option.display_name,
    }));
  };

  const onSubmit = async (data: DateNotificationFormValues) => {
    let recordType = isRiskAssessment ? "questionnaire" : "record_version";
    if (moduleIdentifier === "training" && isTrainingEnabled) {
      recordType = "themis_record";
    }

    try {
      const response: AxiosResponse<NotificationRuleData> = await legacyApi({
        method: createMatch ? "POST" : "PATCH",
        url: createMatch
          ? `${API_URL}/module_workspaces/${moduleWorkspaceID}/notification_rules`
          : `${API_URL}/notification_rules/${params.notificationId}`,
        headers: mainStore.getHeaders(),
        data: serializeFormData(data, recordType),
      });

      if (axios.isAxiosError(response)) {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw (response as AxiosError<any>).response.data.errors.base;
      }

      reset({}, { keepValues: true });
      mainStore.toast.setInfoText(
        `Date Notification was successfully ${
          createMatch ? "created" : "updated"
        }.`,
      );

      if (createMatch) {
        history.push(
          generatePath(MODULE_NOTIFICATIONS_EDIT_PATH, {
            ...params,
            notificationId: response.data.id,
          }),
          location.state,
        );
      }
    } catch {
      mainStore.toast.setErrorText(
        `An error occurred. Could not ${
          createMatch ? "create" : "update"
        } notification`,
      );
    }
  };

  const onInvalid: SubmitErrorHandler<DateNotificationFormValues> = () => {
    mainStore.toast.setErrorText(
      "There is something not right! Please check if all necessary fields have been filled properly!",
    );
  };

  function handleDelete() {
    return mainStore.notificationsRules.delete(
      params.notificationId,
      moduleWorkspaceID,
    );
  }

  const loading = useLoading(fieldsForSelectedModule);

  return (
    <DashboardContent>
      <form
        onSubmit={handleSubmit(onSubmit, onInvalid)}
        className="notification-details--form"
      >
        <DashboardHeader
          title={`${createMatch ? "Add New" : "Edit"} Date Notification`}
          onBackClick={() => {
            history.push(
              generatePath(
                location.state?.fromAllTab
                  ? MODULE_NOTIFICATIONS_BASE_PATH
                  : MODULE_NOTIFICATIONS_TAB_PATH,
                {
                  ...params,
                },
              ),
            );
          }}
          RightActionBar={
            <RightActionBar
              isDirty={formState.isDirty}
              // @ts-expect-error TS(2322) FIXME: Type '() => Promise<true | undefined>' is not assi... Remove this comment to see the full error message
              onDelete={handleDelete}
            />
          }
        />
        <DashboardContentWrapper>
          {loading ? (
            <Loading loadingLayout="small-table" />
          ) : (
            <Flex column className="notification-details">
              <NotificationDetailsHeader control={control} name="enabled" />
              <Flex column rowGap={12} className="notification-details--box">
                <Flex alignCenter columnGap={12}>
                  <Typography
                    label="Send a"
                    size="md"
                    color="generalMidnightDark"
                  />
                  <SelectField
                    control={control}
                    name="frequency"
                    placeholder="- Daily/Weekly/Monthly/One Time -"
                    rules={{ required: true }}
                    options={getOptions("scale")}
                  />
                  <SelectField
                    control={control}
                    name="type"
                    placeholder="- Overdue/Reminder -"
                    rules={{ required: true }}
                    options={getOptions("notification_type")}
                    miniTagTheme="violet"
                  />
                  <Typography
                    label="email"
                    size="md"
                    color="generalMidnightDark"
                  />
                </Flex>
                <Flex alignCenter columnGap={12}>
                  <Typography label="(" size="md" color="generalMidnightDark" />
                  {watch("condition") !== RELATIVE_TO.ON && (
                    <TimePeriodSelectField
                      control={control}
                      name="timePeriod"
                      frequencyFieldName="frequency"
                    />
                  )}
                  <SelectField
                    control={control}
                    name="condition"
                    placeholder="- Condition -"
                    rules={{ required: true }}
                    options={getOptions("relative")}
                  />
                  <Typography
                    label="the"
                    size="md"
                    color="generalMidnightDark"
                  />
                  <SelectField
                    control={control}
                    name="triggerColumn"
                    placeholder="- Trigger Column -"
                    rules={{ required: true }}
                    options={getOptions("trigger_column")}
                  />
                  <Typography label=")" size="md" color="generalMidnightDark" />
                </Flex>
                <PSText control={control} />
              </Flex>
              <Criteria getOptions={getOptions} control={control} />
              <NotificationRecipients
                name="recipients"
                control={control}
                allowedFields={allowedFields}
              />
            </Flex>
          )}
        </DashboardContentWrapper>
      </form>
    </DashboardContent>
  );
}

export default observer(DateNotificationsSinglePage);
