import { http } from "msw";

import { createListControlsModulesQueryResponse } from "../createListControlsModules";

export const listControlsModulesHandler = http.get(
  "*/record_versions/:record_version_id/controls/modules",
  function handler(info) {
    return new Response(
      JSON.stringify(createListControlsModulesQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
