import React from "react";

export const CreateControlMappingSidebarContext = React.createContext<{
  recordVersionId: number | undefined;
  openSidebar: (
    recordId: number,
    tab: string,
    dataType?: string,
    linkedRecords?: number[],
  ) => void;
  closeSidebar: () => void;
}>(undefined!);

export const useControlMappingSidebar = () =>
  React.useContext(CreateControlMappingSidebarContext);
