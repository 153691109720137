import { FilePicker, TextArea } from "@themis/ui";
import classNames from "classnames";
import React, { useRef } from "react";
import { PiXBold } from "react-icons/pi";

import type { Question } from "@/api/gen/models/Question";
import { useFollowupQuestions } from "@/api/queries/apiQuestion";

import { useDisplayFollowUpSidebar } from "../providers/display-followup-questions-sidebar-provider/display-follow-up-questions-sidebar-context";

interface DisplayFollowUpQuestionsPageProps {
  questionID: number;
  questionText?: string;
  questionOrder?: string;
}

export function DisplayFollowUpQuestionsPage({
  questionID,
  questionText,
  questionOrder,
}: DisplayFollowUpQuestionsPageProps) {
  const { closeDisplaySidebar } = useDisplayFollowUpSidebar();

  const { data, error, isPending } = useFollowupQuestions({
    question_id: questionID,
  });

  const resultDetailContainer = useRef<HTMLDivElement>(null);

  return (
    !isPending &&
    !error && (
      <div
        ref={resultDetailContainer}
        className={classNames(
          "slide-animation tw-z-30 tw-h-full tw-w-[500px]",
          {
            "tw-flex tw-flex-col": true,
          },
        )}
      >
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-px-5 tw-py-3 tw-text-base tw-font-semibold tw-text-neutral-500">
            Follow Up Question
          </div>
          <PiXBold
            onClick={closeDisplaySidebar}
            className="tw-mr-2 tw-h-5 tw-w-5 tw-cursor-pointer tw-text-neutral-500"
          />
        </div>

        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-items-center tw-border-0 tw-border-b tw-border-t tw-border-solid tw-border-generals-underline tw-py-2">
            <div className="tw-font-meidum tw-cursor-default tw-px-5 tw-text-sm tw-text-primaryDim-300">
              {`${questionOrder}. ${questionText}`}
            </div>
          </div>
        </div>

        {data?.data?.filter(Boolean).map(
          (questionData: Question, index: number) =>
            questionData && (
              <div
                key={index}
                className="tw-font-meidum tw-items-left tw-flex tw-flex-col tw-border-0 tw-border-b tw-border-solid tw-border-generals-underline tw-py-2 tw-text-sm"
              >
                <div className="tw-cursor-default tw-px-5 tw-pb-2 tw-text-sm tw-text-neutral-500">
                  {`${questionOrder}${String.fromCharCode(65 + index)}. ${
                    questionData.text
                  }`}
                </div>
                <div className="tw-line-clamp-1 tw-cursor-default tw-px-5 tw-pb-1 tw-text-xs tw-font-semibold tw-text-neutral-300">
                  Answer
                </div>
                <div className="tw-line-clamp-1 tw-cursor-default tw-px-5 tw-pb-5">
                  {questionData.input_type === "text" ? (
                    <TextArea
                      locked
                      value={
                        questionData.answer?.is_completed
                          ? questionData.answer?.value || "None"
                          : "Waiting for answer ..."
                      }
                      className="tw-text-neutral-500 read-only:tw-text-neutral-500"
                    />
                  ) : (
                    <>
                      <TextArea
                        locked
                        value={
                          questionData.answer?.is_completed
                            ? questionData.answer?.comment || "None"
                            : "Waiting for answer ..."
                        }
                        className="tw-text-neutral-500 read-only:tw-text-neutral-500"
                      />
                      <div className="tw-line-clamp-1 tw-cursor-default tw-py-2 tw-text-xs tw-font-semibold tw-text-neutral-300">
                        Attachments
                      </div>
                      {questionData?.answer?.document_files?.map((document) => (
                        <div
                          className="tw-line-clamp-1 tw-cursor-default tw-py-1"
                          key={`doc-${document.id}`}
                        >
                          <FilePicker
                            className="tw-bg-neutral-25 tw-text-secondary-200"
                            file={{
                              name: String(document?.file_name),
                              url: String(document?.file_url),
                              type: String(document?.content_type),
                            }}
                            readOnly
                            alignPopover="end"
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            ),
        )}
      </div>
    )
  );
}
