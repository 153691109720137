import { faker } from "@faker-js/faker";

import type { RecordLink } from "../models/RecordLink";
import { createRelatable } from "./createRelatable";

export function createRecordLink(
  data: NonNullable<Partial<RecordLink>> = {},
): NonNullable<RecordLink> {
  return {
    ...{
      id: faker.number.int(),
      link_type: faker.string.alpha(),
      record: Object.assign({}, createRelatable()),
      outward_record: Object.assign({}, createRelatable()),
    },
    ...data,
  };
}
