import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupChildResultsUpdate200,
  WebsiteMonitoringGroupChildResultsUpdateMutationRequest,
  WebsiteMonitoringGroupChildResultsUpdateMutationResponse,
  WebsiteMonitoringGroupChildResultsUpdatePathParams,
} from "../models/WebsiteMonitoringGroupChildResultsUpdate";
import { createMonitoringGroupAssetChild } from "./createMonitoringGroupAssetChild";
import { createUpdateMonitoringGroupResolvedRequest } from "./createUpdateMonitoringGroupResolvedRequest";

export function createWebsiteMonitoringGroupChildResultsUpdatePathParams(): NonNullable<WebsiteMonitoringGroupChildResultsUpdatePathParams> {
  return { monitoring_group_asset_child_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupChildResultsUpdate200(): NonNullable<WebsiteMonitoringGroupChildResultsUpdate200> {
  return { data: createMonitoringGroupAssetChild() };
}

export function createWebsiteMonitoringGroupChildResultsUpdateMutationRequest(): NonNullable<WebsiteMonitoringGroupChildResultsUpdateMutationRequest> {
  return { data: createUpdateMonitoringGroupResolvedRequest() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupChildResultsUpdateMutationResponse(): NonNullable<WebsiteMonitoringGroupChildResultsUpdateMutationResponse> {
  return { data: createMonitoringGroupAssetChild() };
}
