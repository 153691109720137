import { Avatar, Tooltip, TooltipContent, TooltipTrigger } from "@themis/ui";
import React, { useMemo } from "react";

import type { Account } from "@/api";
import { useAccountTypeFieldOptions } from "@/hooks/use-account-type-field-options";

import { AccountTypeTagsList } from "./account-type-tags-list";

function RemainingAccountTypesAvatar({ count }: { count: number }) {
  return (
    <Avatar size="lg" variant="basic" color="gray">
      +{count}
    </Avatar>
  );
}

export function AccountTypesTags({
  accountTypes,
  maxShown,
  isTableTooltip,
}: {
  accountTypes: Account["account_types"];
  maxShown: number;
  /**
   * If the tags are in a table tooltip, the tooltip will be rendered by AG Grid.
   * It is defined in the AG Grid column definition.
   */
  isTableTooltip?: boolean;
}) {
  const { options: accountTypeFieldOptions } = useAccountTypeFieldOptions();

  const selectedAccountTypes = useMemo(
    () =>
      accountTypeFieldOptions.filter((option) =>
        accountTypes.includes(option.key),
      ),
    [accountTypes, accountTypeFieldOptions],
  );

  const accountTypesToShow = selectedAccountTypes.slice(0, maxShown);
  const remainingAccountTypes = selectedAccountTypes.slice(maxShown);

  return (
    <div className="tw-flex tw-gap-1">
      <AccountTypeTagsList types={accountTypesToShow} />
      {remainingAccountTypes.length > 0 &&
        (isTableTooltip ? (
          <RemainingAccountTypesAvatar count={remainingAccountTypes.length} />
        ) : (
          <Tooltip>
            <TooltipTrigger>
              <RemainingAccountTypesAvatar
                count={remainingAccountTypes.length}
              />
            </TooltipTrigger>
            <TooltipContent>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <AccountTypeTagsList
                  isInTooltip
                  types={remainingAccountTypes}
                />
              </div>
            </TooltipContent>
          </Tooltip>
        ))}
    </div>
  );
}
