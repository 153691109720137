import { faker } from "@faker-js/faker";

import type {
  CreateAccountContact201,
  CreateAccountContact403,
  CreateAccountContactMutationRequest,
  CreateAccountContactMutationResponse,
  CreateAccountContactPathParams,
} from "../models/CreateAccountContact";
import { createContact } from "./createContact";
import { createModifyContactRequest } from "./createModifyContactRequest";

export function createCreateAccountContactPathParams(): NonNullable<CreateAccountContactPathParams> {
  return { account_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateAccountContact201(): NonNullable<CreateAccountContact201> {
  return { contact: createContact() };
}

/**
 * @description Forbidden
 */
export function createCreateAccountContact403(): NonNullable<CreateAccountContact403> {
  return { error: {} };
}

export function createCreateAccountContactMutationRequest(): NonNullable<CreateAccountContactMutationRequest> {
  return { contact: createModifyContactRequest() };
}

/**
 * @description OK
 */
export function createCreateAccountContactMutationResponse(): NonNullable<CreateAccountContactMutationResponse> {
  return { contact: createContact() };
}
