var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva } from "cva";
import * as React from "react";
import { cn } from "../../lib/utils";
var defaultStyles = "tw-inline-flex tw-border-none tw-items-center tw-justify-center tw-gap-1 tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-semibold tw-transition-colors";
var activeStyles = "active:tw-bg-neutral-500 active:tw-text-neutral-25";
var disabledStyles = "disabled:tw-bg-primaryDim-50 disabled:tw-text-neutral-300 disabled:tw-pointer-events-none";
var focusStyles = "focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring";
var defaultHoverStyles = "hover:tw-bg-primary-100";
var transparentHoverStyles = "hover:tw-bg-primary-25";
var defaultColorStyles = "tw-bg-primary-50 hover:tw-bg-primary-100 tw-text-neutral-500";
var transparentColorStyles = "tw-bg-transparent tw-text-neutral-500";
var defaultStateStyles = "data-[state=on]:tw-bg-secondary-300 data-[state=on]:hover:tw-bg-secondary-500 data-[state=on]:tw-text-white";
var transparentStateStyles = "data-[state=on]:tw-bg-neutral-500 data-[state=on]:tw-text-neutral-25";
var toggleButtonVariants = cva({
    base: [defaultStyles, activeStyles, disabledStyles, focusStyles],
    variants: {
        size: {
            sm: "tw-h-6 tw-min-w-6",
            md: "tw-h-7 tw-min-w-7",
            lg: "tw-h-8 tw-min-w-8",
        },
        variant: {
            default: [defaultColorStyles, defaultStateStyles, defaultHoverStyles],
            transparent: [
                transparentColorStyles,
                transparentStateStyles,
                transparentHoverStyles,
            ],
        },
    },
    defaultVariants: {
        size: "md",
        variant: "default",
    },
});
var ToggleButton = React.forwardRef(function (_a, ref) {
    var className = _a.className, size = _a.size, variant = _a.variant, props = __rest(_a, ["className", "size", "variant"]);
    return (React.createElement(TogglePrimitive.Root, __assign({ ref: ref, className: cn(toggleButtonVariants({ size: size, className: className, variant: variant })) }, props)));
});
ToggleButton.displayName = "ToggleButton";
export { ToggleButton, toggleButtonVariants };
