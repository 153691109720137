import { useParams } from "react-router-dom";

import type { ACCOUNT_ID_PARAM } from "../../../accounts-routes";
import { DueDiligenceTable } from "./due-diligence-table/due-diligence-table";

export default function DueDiligence() {
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
  }>();

  return <DueDiligenceTable workspaceId={workspace_id} accountId={accountId} />;
}
