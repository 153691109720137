import { faker } from "@faker-js/faker";

import type {
  CreateModuleAssessment201,
  CreateModuleAssessment403,
  CreateModuleAssessmentMutationRequest,
  CreateModuleAssessmentMutationResponse,
  CreateModuleAssessmentPathParams,
} from "../models/CreateModuleAssessment";
import { createModuleAssessment } from "./createModuleAssessment";
import { createModuleAssessmentRequest } from "./createModuleAssessmentRequest";

export function createCreateModuleAssessmentPathParams(): NonNullable<CreateModuleAssessmentPathParams> {
  return { record_version_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateModuleAssessment201(): NonNullable<CreateModuleAssessment201> {
  return { data: createModuleAssessment() };
}

/**
 * @description Forbidden
 */
export function createCreateModuleAssessment403(): NonNullable<CreateModuleAssessment403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateModuleAssessmentMutationRequest(): NonNullable<CreateModuleAssessmentMutationRequest> {
  return createModuleAssessmentRequest();
}

/**
 * @description OK
 */
export function createCreateModuleAssessmentMutationResponse(): NonNullable<CreateModuleAssessmentMutationResponse> {
  return { data: createModuleAssessment() };
}
