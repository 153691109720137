import { http } from "msw";

import { createIssuesReportMutationResponse } from "../createIssuesReport";

export const issuesReportHandler = http.post(
  "*/workspaces/:workspace_id/issues/reports",
  function handler(info) {
    return new Response(JSON.stringify(createIssuesReportMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
