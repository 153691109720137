import {
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useDeleteContact } from "@/api/queries/contacts";

export function ContactsActionsCell({ contactId }: { contactId: number }) {
  const { formatMessage } = useIntl();

  const toast = useToast();
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { mutate: deleteContact } = useDeleteContact({
    accountId: Number(accountId),
    onSuccess: () => {
      toast({
        content: "Contact has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove contact!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleDelete = () => {
    setIsConfirmationOpen(false);
    deleteContact(contactId);
  };

  const handleDeleteClick = () => {
    setIsConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            aria-label={formatMessage({
              defaultMessage: "Contact Actions",
            })}
            Icon={PiDotsThreeOutlineVerticalFill}
            color="transparent"
            className="tw-h-8 tw-w-8 tw-rounded-md"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            key="Delete"
            onClick={handleDeleteClick}
            className="tw-flex tw-w-[228px] tw-items-center tw-justify-start tw-gap-1 tw-border-x-0 tw-border-b tw-border-b-0 tw-border-t-0 tw-border-solid tw-border-primary-50 tw-px-4 tw-py-[10px]"
          >
            <span className="tw-text-sm tw-font-medium">
              {formatMessage({
                defaultMessage: "Delete",
              })}
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <ConfirmationPopup
        anchor
        align="end"
        title={formatMessage({
          defaultMessage: "Delete Contact",
        })}
        content={formatMessage({
          defaultMessage:
            "Actions are non-reversible. Are you sure you want to proceed?",
        })}
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      />
    </>
  );
}
