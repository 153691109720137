import "./risk-methodology-builder.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import type {
  ResidualRisk,
  ResidualRiskMatrix as ResidualRiskMatrixItem,
  RiskRating,
} from "../../../types/risk-methodology";
import { makeMatrix } from "./helpers";
import MatrixCell from "./MatrixCell";

interface ResidualRiskMatrixProps {
  controlRiskRatings: Array<RiskRating>;
  inherentRiskRatings: Array<RiskRating>;
  residualRisks: Array<ResidualRisk>;
  residualRiskMatrices: Array<ResidualRiskMatrixItem>;
  setResidualRiskMatrices: (
    residualRisks: Array<ResidualRiskMatrixItem>,
  ) => void;
}

function ResidualRiskMatrix({
  controlRiskRatings,
  inherentRiskRatings,
  residualRisks,
  residualRiskMatrices,
  setResidualRiskMatrices,
}: ResidualRiskMatrixProps) {
  const [matrix, setMatrix] = useState(
    makeMatrix(
      controlRiskRatings,
      inherentRiskRatings,
      residualRisks,
      residualRiskMatrices,
    ),
  );

  function updateResidualRiskMatrix(
    rowIndex: number,
    columnIndex: number,
    residualRisk: ResidualRisk,
  ): void {
    const newResidualRiskMatrices = [...residualRiskMatrices];
    const reisudalRiskMatrix = newResidualRiskMatrices.find(
      (item: ResidualRiskMatrixItem) =>
        item.control_risk_rating.text ===
          controlRiskRatings[columnIndex].text &&
        item.inherent_risk_rating.text === inherentRiskRatings[rowIndex].text,
    );
    if (reisudalRiskMatrix) {
      reisudalRiskMatrix.residual_risk = residualRisk;
    } else {
      newResidualRiskMatrices.push({
        control_risk_rating: controlRiskRatings[columnIndex],
        inherent_risk_rating: inherentRiskRatings[rowIndex],
        residual_risk: residualRisk,
      });
    }
    setResidualRiskMatrices(newResidualRiskMatrices);
  }

  const updateMatrix = (
    rowIndex: number,
    columnIndex: number,
    residualRisk: ResidualRisk,
  ) => {
    const newMatrix = [...matrix];
    newMatrix[rowIndex][columnIndex] = residualRisk;
    setMatrix(newMatrix);
    updateResidualRiskMatrix(rowIndex, columnIndex, residualRisk);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'row' implicitly has an 'any' type.
  const renderRow = (row, rowNumber) => (
    <div className="matrix-row" key={`matrixRow${rowNumber}`}>
      <div className="matrix-cell matrix-cell__title">
        {inherentRiskRatings[rowNumber]?.text}
      </div>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'residualRiskItem' implicitly has an 'an... Remove this comment to see the full error message */}
      {row.map((residualRiskItem, columnNumber) => (
        <MatrixCell
          key={`matrix-cell-${rowNumber}-${columnNumber}`}
          residualRisk={residualRiskItem}
          index={columnNumber}
          residualRisks={residualRisks}
          updateCell={(newResidualRiskItem) =>
            updateMatrix(rowNumber, columnNumber, newResidualRiskItem)
          }
        />
      ))}
    </div>
  );

  useEffect(() => {
    const newMatrix = makeMatrix(
      controlRiskRatings,
      inherentRiskRatings,
      residualRisks,
      residualRiskMatrices,
    );
    setMatrix(newMatrix);
  }, [controlRiskRatings, inherentRiskRatings, residualRisks]);

  useEffect(() => {
    const newResidualArray: Array<ResidualRiskMatrixItem> = [];
    for (let rowIndex = 0; rowIndex < matrix.length; rowIndex++) {
      for (
        let columnIndex = 0;
        columnIndex < matrix[rowIndex].length;
        columnIndex++
      ) {
        const residualRisk = matrix[rowIndex][columnIndex];
        const newResidualRiskItem = {
          control_risk_rating: controlRiskRatings[columnIndex],
          inherent_risk_rating: inherentRiskRatings[rowIndex],
          residual_risk: residualRisk,
        };
        newResidualArray.push(newResidualRiskItem);
      }
    }
    setResidualRiskMatrices(newResidualArray);
  }, [matrix]);

  return (
    <div className="ra-matrix-max-width">
      <div className="matrix-row">
        {[{ text: "", color: "" }, ...controlRiskRatings].map(
          (item, titleIndex) => {
            if (item.color === "") {
              return (
                <div
                  className="matrix-cell matrix-cell__title"
                  key={`matrix-title-${titleIndex}`}
                >
                  <div className="residual-risk-matrices-label">
                    <div className="inherents-label">Inherent</div>
                    <hr />
                    <div className="controls-label">Control</div>
                  </div>
                </div>
              );
            }
            return (
              <div
                className="matrix-cell matrix-cell__title"
                key={`matrix-title-${titleIndex}`}
              >
                {item.text}
              </div>
            );
          },
        )}
      </div>
      {matrix.map((row, i) => renderRow(row, i))}
    </div>
  );
}

export default observer(ResidualRiskMatrix);
