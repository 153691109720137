import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useTableData } from "@/hooks/useTableData";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import type { IndexParams } from "@/stores/types/module-store-types";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import ExportBulk from "../../dashboard/ExportBulk";
import { Icon } from "../../Elements";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import Loading from "../../Loading";
import SlideMenu from "../../slideMenu/SlideMenu";
import MenuActions from "../menu-actions/MenuActions";
import CreateCTACW from "../shared/CreateCTACW";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import TableMenu from "../shared/tableMenu/TableMenu";
import Table from "../Table";
import BulkSubmitSlideout from "./BulkSubmitSlideout";

function FinraComplaints() {
  // Import MobX stores
  const mainStore = useMainStore();
  useTableData();

  // Variables
  const { visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;
  const {
    tableName,
    isSupport,
    isFinraSupport,
    moduleWorkspaceID,
    activeWorkspace,
    workspaceID,
  } = mainStore.context;
  const { data, page } = mainStore.finraComplaints;
  const hideFirstCTAMemo = tableName === "FINRA Escalated" && isSupport;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  // State
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showCheckboxFromSlideMenu, setShowCheckboxFromSlideMenu] =
    useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectMode, setSelectMode] = useState(false);

  // Hooks
  const history = useHistory();
  const loading = useLoading(visibleFields);
  const { getTableFiltersParam } = useUpdateFilter();
  const filters = getTableFiltersParam();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({});

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
    setSortBy,
  } = useSortingAndPagination({ store: mainStore.finraComplaints, page });

  useEffect(() => {
    const userID = mainStore.users.user.id;
    if (userID && tableName === "FINRA Review") {
      mainStore.finraContacts.show(userID);
    }
  }, [mainStore.users.user, tableName]);

  useEffect(() => {
    setButtonDisabled(recordVersions.length === 0);
  }, [recordVersions]);

  const onPageChange = async (newPage: number) => {
    const params: IndexParams = {};
    params.workspaceID = activeWorkspace?.id;
    params.page = newPage;
    params.tableName = tableName || undefined;

    if (sortByFieldName) {
      params.sortParams = {
        field_name: sortByFieldName,
        direction: sortByDirection || "ASC",
      };
    }

    if (filters) {
      params.tableFilters = filters;
    }

    await mainStore.finraComplaints.index(params);
  };

  async function createNewComplaints() {
    if (showCheckbox && selectMode) {
      return;
    }

    if (tableName) {
      const recordVersion = await mainStore.finraComplaints.create(tableName);
      history.push(
        `/workspaces/${workspaceID}/modules/finra/${recordVersion.id}`,
      );
    }
  }

  function resetMenuActions() {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  }

  function onSubmitInBulkClick() {
    resetMenuActions();
    setShowCheckboxFromSlideMenu(true);
  }

  function renderCTA() {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    return activeWorkspace.is_internal ? (
      <CreateFirstCTA subject="Complaint" />
    ) : (
      <CreateCTACW subject="Complaint" />
    );
  }

  const renderRows = recordVersions.map((recordVersion) => {
    const escalateToFinra = mainStore.avroSchemas.firstValueForField(
      "escalate_to_finra",
      recordVersion.data,
    ); // "yes", "no"
    const escalatedStatus = mainStore.avroSchemas.firstValueForField(
      "escalated_status",
      recordVersion.data,
    ); // "open", "complete"
    const reviewStatus = mainStore.avroSchemas.firstValueForField(
      "review_status",
      recordVersion.data,
    ); // "reviewing", "submitted", "complete", "closed", "re_opened"

    const isClosed = reviewStatus === "closed";
    const isSubmitted = reviewStatus === "submitted";

    const escalateToFinraYes =
      escalateToFinra === "yes" &&
      tableName === "FINRA Review" &&
      !isClosed &&
      !isSubmitted;

    const isComplete =
      tableName === "FINRA Escalated" ? escalatedStatus === "complete" : false;
    const isLockedRow = isClosed || isSubmitted || isComplete;

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isLockedRow={isLockedRow}
        recordVersion={recordVersion}
        fields={visibleFields}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={recordVersion.table_name}
        moduleIdentifier="finra"
        isArchivedRow={isLockedRow}
        finraSubmission={recordVersion.finra_submission}
        isComplaintClosed={isClosed}
        inSelectMenuActionMode={selectMode}
        showCheckboxFromSlideMenu={
          showCheckboxFromSlideMenu && escalateToFinraYes
        }
      />
    );
  });

  const TABS = [
    {
      name: "Escalated Complaints",
      key: "escalated",
      value: `/workspaces/${workspaceID}/modules/finra/escalated`,
    },
    {
      name: "Review Complaints",
      key: "review",
      value: `/workspaces/${workspaceID}/modules/finra/review`,
      dataTestId: "review-complaints-link",
      hasPermission: !isSupport && !isFinraSupport,
    },
  ];

  const renderTabs = () => {
    if (!mainStore.context.activeWorkspace?.is_internal) {
      return;
    }

    return (
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS)?.value || ""}
        isLink
      />
    );
  };

  const renderFields = visibleFields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      setSortBy={setSortBy}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  return (
    <Table>
      {loading && <Loading loadingLayout="table" showTableHeader />}
      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            <div>{renderTabs()}</div>
            <div className="import-export-buttons-container">
              <div className="table-export-block">
                <ExportBulk />
                {tableName === "FINRA Review" && (
                  <SlideMenu
                    closeSlideMenu={() => setShowCheckboxFromSlideMenu(false)}
                    trigger={
                      <button
                        id="triggerId5"
                        className="table-button"
                        disabled={buttonDisabled}
                        style={{ background: "#6D71F9" }}
                        onClick={onSubmitInBulkClick}
                      >
                        Submit in Bulk
                      </button>
                    }
                  >
                    <BulkSubmitSlideout
                      tableName={tableName}
                      checkboxSelection
                      // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                      moduleWorkspaceID={moduleWorkspaceID}
                    />
                  </SlideMenu>
                )}
                {filtersTrigger}
              </div>
            </div>
          </div>
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          <div
            className="table-list-wrap complaints-list-wrap"
            data-testid="complaints-table"
          >
            <div className="dropdown-table">
              <div className="list-title-table">
                <ul>
                  <div className="list-column-wrap">
                    <div className="title-first-block table-column-reorder">
                      {visibleFields.length > 0 && (
                        <TableMenu
                          setShowCheckbox={setShowCheckbox}
                          resetMenuActions={resetMenuActions}
                          selectMode={selectMode}
                          setSelectMode={setSelectMode}
                        />
                      )}
                    </div>

                    {showCheckbox && selectMode ? (
                      <div>
                        <MenuActions resetMenuActions={resetMenuActions} />
                      </div>
                    ) : (
                      <div className="list-title-table-wrap">
                        {renderFields}
                        <span className="stretch-cell" />
                      </div>
                    )}
                  </div>
                  {!(showCheckbox && selectMode) && (
                    <AddColumnButton onAdd={createColumn} />
                  )}
                </ul>
              </div>
              {renderRows}
              <div
                className="tw-sticky tw-left-[19px] tw-ml-[19px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
                onClick={createNewComplaints}
                data-testid="add-new-record-button"
              >
                <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
                <div className="tw-neutral-300 tw-text-sm">
                  Add {getRecordName("finra", moduleWorkspaces, true)}...
                </div>
              </div>
            </div>
            {!hideFirstCTAMemo && recordVersions.length < 3 && renderCTA()}
            <Pagination
              onChange={onPageChange}
              current={page}
              // @ts-expect-error TS(2339) FIXME: Property 'count' does not exist on type '{}'.
              total={data.count}
              prevIcon={arrowLeftImage}
              nextIcon={arrowRightImage}
              showPrevNextJumpers
            />
          </div>
        </>
      )}
    </Table>
  );
}

export default observer(FinraComplaints);
