import { LinkButton, Table } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import type { Account, ListAccountsQueryParams } from "@/api/gen/models";
import { useAccounts } from "@/api/queries/accounts";
import { CommentsSidebar } from "@/components/comments-sidebar/comments-sidebar";
import Loading from "@/components/Loading";
import type { FilterFieldData } from "@/hooks/useFilterSort";
import useFilterSort from "@/hooks/useFilterSort";
import { useSearchParams } from "@/hooks/useSearchParams";

import { ErrorContainer } from "../../../components/ErrorContainer";
import { ACCOUNT_DETAILS_PATH, ACCOUNT_ROUTES } from "../pages";
import type { AccountListView, AccountSearchParams } from "../pages/Accounts";
import { useAccountsTableColumns } from "../utils/use-accounts-table-columns";
import { AccountCard } from "./AccountCard";

function CardsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      data-testid="cards-container"
      className="tw-flex tw-flex-wrap tw-items-center tw-gap-4 tw-self-stretch tw-py-2"
    >
      {children}
    </div>
  );
}

function CardPlaceholderContainer({ children }: { children: React.ReactNode }) {
  return (
    <section className="tw-flex tw-max-h-[180px] tw-min-h-[180px] tw-flex-wrap tw-items-center tw-justify-center tw-gap-8 tw-overflow-hidden">
      {children}
    </section>
  );
}

function CardPlaceholder() {
  return (
    <div className="tw-h-[180px] tw-w-[180px] tw-rounded-xl tw-bg-neutral-50" />
  );
}

function EmptyStateContainer({
  accountsListView,
  children,
}: {
  accountsListView: AccountListView;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "tw-inline-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4",
        {
          "tw-pt-4": accountsListView === "grid",
          "tw-pt-48": accountsListView === "table",
        },
      )}
    >
      {children}
    </div>
  );
}

export function AccountList({
  filtersFieldData,
}: {
  filtersFieldData?: FilterFieldData<Account>;
}) {
  const { formatMessage } = useIntl();
  const { url } = useRouteMatch();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const [{ view }] = useSearchParams<AccountSearchParams>();
  const { listRequestQueryParams } = useFilterSort<Account>(
    filtersFieldData || {},
  );
  const workspaceId = Number(workspace_id);

  const queryParams: ListAccountsQueryParams | null = filtersFieldData
    ? {
        expand: "main_contact,parent",
        ...listRequestQueryParams,
      }
    : null;

  const {
    data: accounts,
    isPending: isAccountsPending,
    isError: isAccountsError,
  } = useAccounts(Number(workspace_id), queryParams);

  const { columns, isColumnsError, isColumnsPending } = useAccountsTableColumns(
    {
      workspaceId,
    },
  );

  const accountsListView = view || "table";

  if (isAccountsPending || isColumnsPending) {
    return <Loading loadingLayout={accountsListView} />;
  }

  if (isAccountsError || isColumnsError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id,
          }),
        }}
      >
        {formatMessage({ defaultMessage: "Could not load accounts." })}
      </ErrorContainer>
    );
  }

  return (
    <>
      <CommentsSidebar
        recordType="accounts"
        detailsPagePath={ACCOUNT_DETAILS_PATH}
      />
      {accountsListView === "grid" ? (
        <CardsContainer>
          {accounts.data.map((account: Account) => (
            <AccountCard
              key={account.id}
              linkTo={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
                accountId: account.id,
              })}
              account={account}
            />
          ))}
        </CardsContainer>
      ) : (
        <Table columns={columns} rows={accounts.data} />
      )}

      {!accounts.data.length && (
        <>
          {accountsListView === "grid" && (
            <CardPlaceholderContainer>
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
            </CardPlaceholderContainer>
          )}
          <EmptyStateContainer accountsListView={accountsListView}>
            <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
              {formatMessage({
                defaultMessage: "Add your first Account",
              })}
            </h3>
            <p className="tw-w-[370px] tw-text-center tw-text-sm tw-font-medium tw-text-neutral-300">
              {formatMessage({
                defaultMessage:
                  "To get started, add your first Account by clicking 'Add Account' and filling in the details.",
              })}
            </p>
            <LinkButton
              LeftIcon={PiPlusBold}
              to={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
                accountId: "new",
              })}
            >
              {formatMessage({
                defaultMessage: "Add Account",
              })}
            </LinkButton>
          </EmptyStateContainer>
        </>
      )}
    </>
  );
}
