import { http } from "msw";

import { createUpdateFindingStatusOpenMutationResponse } from "../createUpdateFindingStatusOpen";

export const updateFindingStatusOpenHandler = http.put(
  "*/findings/:finding_id/status/reopen",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateFindingStatusOpenMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
