import { faker } from "@faker-js/faker";

import type {
  RecordsByMonthByFieldOption200,
  RecordsByMonthByFieldOptionPathParams,
  RecordsByMonthByFieldOptionQueryParams,
  RecordsByMonthByFieldOptionQueryResponse,
} from "../models/RecordsByMonthByFieldOption";
import { createRecordType } from "./createRecordType";

export function createRecordsByMonthByFieldOptionPathParams(): NonNullable<RecordsByMonthByFieldOptionPathParams> {
  return { company_id: faker.number.int(), record_type: createRecordType() };
}

export function createRecordsByMonthByFieldOptionQueryParams(): NonNullable<RecordsByMonthByFieldOptionQueryParams> {
  return {
    workspace_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    field_name: faker.string.alpha(),
    date_field_name: faker.string.alpha(),
    from_date: faker.string.alpha(),
    to_date: faker.string.alpha(),
    filters: {},
  };
}

/**
 * @description A JSON object containing the counts of the records grouped by month & the specified field option
 */
export function createRecordsByMonthByFieldOption200(): NonNullable<RecordsByMonthByFieldOption200> {
  return { data: faker.helpers.arrayElements([undefined]) as any };
}

/**
 * @description A JSON object containing the counts of the records grouped by month & the specified field option
 */
export function createRecordsByMonthByFieldOptionQueryResponse(): NonNullable<RecordsByMonthByFieldOptionQueryResponse> {
  return { data: faker.helpers.arrayElements([undefined]) as any };
}
