import {
  Checkbox,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@themis/ui";
import React from "react";
import type { Control } from "react-hook-form";
import { useController, useFieldArray, useWatch } from "react-hook-form";
import { PiLockSimpleBold } from "react-icons/pi";

import type { UnlockTabNotificationFormValues } from "@/features/notifications/pages/single/unlock-task/types";

import SelectField from "../select-field/select-field";
import { TimePeriodSelectField } from "../time-period-select/time-period-select-field";

interface Props {
  control: Control<UnlockTabNotificationFormValues>;
  getOptions: (
    fieldName: string,
    index?: number,
  ) => {
    value: string;
    label: string;
    colorSchemeId?: number;
  }[];
}

export const UnlockTaskCriteria: React.FC<Props> = ({
  control,
  getOptions,
}) => {
  const { fields } = useFieldArray({
    control,
    name: "criteria",
  });

  const selectedTriggerColumn = useWatch({ control, name: "triggerColumn" });

  const { field: canUnlockTestField } = useController({
    control,
    name: "canUnlockTest",
  });

  return (
    !!fields.length && (
      <div className="notification-details--box tw-flex tw-h-44 tw-flex-col tw-gap-3">
        {fields.map((field) => (
          <div
            key={field.id}
            className="tw-flex tw-content-center tw-justify-between"
          >
            <div className="tw-flex tw-content-center tw-gap-3">
              <span className="tw-text-base tw-font-medium tw-text-neutral-500">
                If Status is Completed
              </span>
            </div>
          </div>
        ))}

        <div className="tw-flex tw-content-center tw-gap-3">
          <span className="tw-text-base tw-font-medium tw-text-neutral-500">
            unlock record
          </span>
          <TimePeriodSelectField
            control={control}
            name="timePeriod"
            frequencyFieldName="frequency"
            isUnlockDaily
          />
          <span className="tw-text-base tw-font-medium tw-text-neutral-500">
            before the
          </span>

          <SelectField
            control={control}
            name="triggerColumn"
            placeholder="- Trigger Column -"
            rules={{ required: true }}
            options={getOptions("trigger_column")}
          />
        </div>
        <div className="tw-flex tw-content-center tw-gap-3">
          <span className="tw-text-base tw-font-medium tw-text-neutral-500">
            then proceed with the following:
          </span>
        </div>
        <div className="tw-flex tw-content-center tw-gap-3">
          <div className="tw-flex tw-h-9 tw-items-center tw-justify-center tw-gap-1">
            <div>
              {selectedTriggerColumn ? (
                <Checkbox
                  checked={canUnlockTestField.value}
                  color="primary"
                  onCheckedChange={(val: boolean) =>
                    canUnlockTestField.onChange(val)
                  }
                  size="md"
                />
              ) : (
                <TooltipProvider>
                  <Tooltip delayDuration={700}>
                    <TooltipTrigger className="tw-cursor-default">
                      <div>
                        <PiLockSimpleBold size="16px" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent align="start" side="bottom" disableArrow>
                      <p>This record is locked due to being published.</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <div>Auto-unlock the record</div>
          </div>
        </div>
        <div className="tw-flex tw-content-center tw-gap-3">
          <div className="tw-flex tw-h-5 tw-items-center tw-justify-center tw-gap-1">
            <div>
              <Checkbox color="primary" checked disabled size="md" />
            </div>
            <div>Send a one-time reminder email</div>
          </div>
        </div>
      </div>
    )
  );
};
