import "./questionnaire-templates.scss";

import {
  ConfirmationDialog,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import axios from "axios";
import { format, isPast, parseISO } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { generatePath, useHistory } from "react-router";

import { Icon, Typography } from "@/components/Elements";
import { formatDate, stringToDate } from "@/components/helpers/DateFormatters";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import HeadSelect from "@/components/table/shared/HeadSelect";
import SendRecordVersion from "@/components/table/shared/SendRecordVersion";
import UsersCircle from "@/components/table/shared/UsersCircle";
import { COLOR } from "@/config/theme";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import RowOptions from "@/features/risk-assessment/components/RowOptions/RowOptions";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableCellSpacer from "@/features/risk-assessment/components/Table/GenericTable/TableCellSpacer";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import type { QuestionnaireRead } from "@/features/risk-assessment/types/questionnaire";

import { QuestionnaireAPI } from "../../../api/legacy/risk-assessment/QuestionnaireApi";
import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import Loading from "../../../components/Loading";
import DashboardContent from "../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import { useMainStore } from "../../../contexts/Store";
import MiniTag from "../components/MiniTag/MiniTag";
import { ModuleContentWrapper } from "../components/ModuleContentWrapper/ModuleContentWrapper";
import ModuleHeader from "../components/ModuleHeader/ModuleHeader";
import ImportQuestionnaireTemplates from "../components/Questionnaires/QuestionnaireTemplates/ImportQuestionnaireTemplates";
import RiskAssessmentTabs from "../components/RiskAssessmentTabs";
import TemplateTabs from "../components/TemplateTabs";
import { routes } from "../routes";

function QuestionnaireTemplatesPage() {
  const mainStore = useMainStore();

  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(true);
  const [questionnaireIDToBeSent, setQuestionnaireIDToBeSent] = useState<
    number | undefined
  >();

  const [confirmQuestionnaireIdToDelete, setConfirmQuestionnaireIdToDelete] =
    useState<number | null>(null);

  const handleCloseConfirmDelete = () => {
    setConfirmQuestionnaireIdToDelete(null);
  };

  const history = useHistory();
  const { questionnaireTemplates } = mainStore.riskAssessmentQuestionnaires;
  const { workspaceID } = mainStore.context;
  const moduleWorkspaceID = mainStore.moduleWorkspaces.list.find(
    (item) => item.themis_module.identifier === "risk_assessment",
  )?.id;

  const closeSlideMenu = () => setQuestionnaireIDToBeSent(undefined);

  const loadTemplates = async (passedWorkspaceID: number | null) => {
    if (!passedWorkspaceID) {
      return;
    }

    await mainStore.riskAssessmentQuestionnaires.indexQuestionnaires(
      passedWorkspaceID,
    );
    setLoading(false);
  };

  const sendQuestionnaireToCW = async (
    originalQuestionnaireID: number,
    workspaceIDs: number[],
  ) => {
    if (!moduleWorkspaceID) {
      return;
    }
    try {
      await QuestionnaireAPI.sendQuestionnaireToCW(
        originalQuestionnaireID,
        workspaceIDs,
      );
      const questionnaireName =
        questionnaireTemplates.find(
          (template) => template.id === originalQuestionnaireID,
        )?.name || "";
      const workspaceNames = mainStore.workspaces.list
        .filter((ws) => workspaceIDs.includes(ws.id))
        .map((ws) => ws.name);
      mainStore.toast.setInfoText(
        `${questionnaireName} successfully sent to ${workspaceNames.join(
          "`, ",
        )} Workspace`,
      );
    } catch {
      mainStore.toast.setErrorText("Something went wrong");
    }

    closeSlideMenu();
  };

  const retrieveLastEditedBy = (item: QuestionnaireRead) =>
    mainStore.users.allUsers.find((u) =>
      [item.last_updated_by_user_id].includes(u.id),
    );

  const deleteQuestionnaire = async (id: number) => {
    mainStore.toast.setInfoText("The questionnaire is being removed.");
    try {
      await QuestionnaireAPI.delete(id);
      mainStore.riskAssessmentQuestionnaires.deleteQuestionnaire(id);
      mainStore.toast.setInfoText("The questionnaire was removed.");
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        e.response?.data?.errors?.risk_assessment_names
      ) {
        const joinedNames =
          e.response.data.errors.risk_assessment_names.join(", ");
        mainStore.toast.setErrorText(
          `The questionnaire is in use for the following assessment${
            joinedNames.includes(",") ? "s" : ""
          }: ${joinedNames}`,
        );
      } else {
        mainStore.toast.setErrorText("Something went wrong..");
      }
    }
  };

  const isDueDateInPast = (questionnaire: QuestionnaireRead): boolean => {
    if (!questionnaire.due_date) {
      return false;
    }

    return isPast(parseISO(questionnaire.due_date.toString()));
  };

  const addTemplate = () => {
    history.push(
      generatePath(routes.QUESTIONNAIRE_TEMPLATE_CREATION_PATH, {
        workspace_id: Number(workspaceID),
      }),
    );
  };

  useEffect(() => {
    loadTemplates(workspaceID);
  }, [workspaceID]);

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader={false} />;
  }

  return (
    <>
      <DashboardContent>
        <DashboardHeader
          title="Questionnaires"
          LeftActionBar={<ViewModuleUsers />}
          RightActionBar={<HeadSelect />}
        />
        <DashboardContentWrapper>
          <ModuleHeader>
            <RiskAssessmentTabs />
            <ImportQuestionnaireTemplates />
          </ModuleHeader>
          <ModuleHeader>
            <TemplateTabs />
          </ModuleHeader>
          <AddRecordHeader onAddRecord={addTemplate} recordName="Template" />
          <ModuleContentWrapper className="questionnaire-templates-table-wrapper">
            <Table
              header={
                <>
                  <TableHeaderCell
                    title="Name"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="book1" />
                    }
                    firstDataHeader
                  />
                  <TableHeaderCell
                    title="Type"
                    leftIcon={<Icon color="extrasBlueGrayDarker" name="tag" />}
                  />
                  <TableHeaderCell
                    title="Due Date"
                    leftIcon={
                      <Icon name="calendar" color="extrasBlueGrayDarker" />
                    }
                  />
                  <TableHeaderCell
                    title="Inherent Questions"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="number" />
                    }
                  />
                  <TableHeaderCell
                    title="Control Questions"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="number" />
                    }
                  />
                  <TableHeaderCell
                    title="Last Edited By"
                    leftIcon={<Icon color="extrasBlueGrayDarker" name="user" />}
                  />
                  <TableHeaderCell
                    title="Last Updated Date"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="calendar" />
                    }
                  />
                  <TableHeaderCell
                    title="Created Date"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="calendar" />
                    }
                    lastDataHeader
                  />
                  <TableCellSpacer />
                </>
              }
              actionRow={{
                text: "Add Template",
                onClick: () =>
                  history.push(
                    generatePath(routes.QUESTIONNAIRE_TEMPLATE_CREATION_PATH, {
                      workspace_id: Number(workspaceID),
                    }),
                  ),
                hasTableCellSpacer: true,
              }}
            >
              {questionnaireTemplates.map((template, rowIndex) => {
                const isInPast = isDueDateInPast(template);
                return (
                  <TableRow
                    key={template.id}
                    rowIndex={rowIndex}
                    onClick={() =>
                      history.push(
                        generatePath(routes.QUESTIONNAIRE_TEMPLATE_EDIT_PATH, {
                          templateId: template.id,
                          workspace_id: Number(workspaceID),
                        }),
                      )
                    }
                  >
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="tw-flex tw-h-[42px]">
                          <ContentCell
                            content={template.name}
                            isFirstDataCell
                            className="tw-flex tw-w-full tw-min-w-[300px] tw-items-center"
                            textClassName="!tw-block"
                          />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="tw-box-border tw-inline-flex tw-w-72 tw-flex-col tw-items-start tw-gap-1 tw-px-6 tw-py-4">
                        {template.name}
                      </TooltipContent>
                    </Tooltip>

                    <ContentCell
                      content={
                        <MiniTag
                          label={template.is_internal ? "Internal" : "Public"}
                          color={
                            template.is_internal
                              ? COLOR.extrasSlateBlue
                              : COLOR.extrasFuchsia
                          }
                        />
                      }
                    />
                    <ContentCell
                      content={
                        <Typography
                          weight="semiBold"
                          label={
                            template.due_date
                              ? format(
                                  parseISO(template.due_date.toString()),
                                  "MM/dd/yyyy",
                                )
                              : "N/A"
                          }
                          color={
                            isInPast ? "generalError" : "extrasBlueGrayDarker"
                          }
                        />
                      }
                      hasErrors={isInPast}
                    />
                    <ContentCell
                      content={template.inherent_questions_count.toString()}
                    />
                    <ContentCell
                      content={template.control_questions_count.toString()}
                    />
                    <ContentCell
                      content={
                        <UsersCircle user={retrieveLastEditedBy(template)} />
                      }
                    />
                    <ContentCell
                      content={
                        formatDate(stringToDate(template.last_updated_at)) ??
                        "N/A"
                      }
                    />
                    <ContentCell
                      content={
                        formatDate(stringToDate(template.created_at)) ?? "N/A"
                      }
                      isLastDataCell
                    />
                    <ActionCell>
                      <RowOptions
                        options={[
                          {
                            label: "Export Template",
                            onClick: () =>
                              QuestionnaireAPI.downloadQuestionnaire(
                                template.id,
                                true,
                              ),
                          },
                          {
                            label: "Send to Workspace Partner",
                            disabled: template.is_internal,
                            disabledTooltip: template.is_internal
                              ? "This action is enabled for internal questionnaire templates"
                              : undefined,
                            onClick: () =>
                              setQuestionnaireIDToBeSent(template.id),
                          },
                          {
                            label: "Delete",
                            onClick: () =>
                              setConfirmQuestionnaireIdToDelete(template.id),
                          },
                        ]}
                      />
                    </ActionCell>
                  </TableRow>
                );
              })}
            </Table>
          </ModuleContentWrapper>
        </DashboardContentWrapper>
        <SlideMenu
          open={!!questionnaireIDToBeSent && questionnaireIDToBeSent > 0}
          closeSlideMenu={closeSlideMenu}
        >
          {questionnaireIDToBeSent && questionnaireIDToBeSent > 0 && (
            <SendRecordVersion
              recordName="Questionnaire"
              recordVersionID={questionnaireIDToBeSent}
              sendFunction={sendQuestionnaireToCW}
            />
          )}
        </SlideMenu>
      </DashboardContent>
      {!!confirmQuestionnaireIdToDelete && (
        <ConfirmationDialog
          isOpen
          title={formatMessage({ defaultMessage: "Are you sure?" })}
          description={formatMessage({
            defaultMessage:
              "Deletion of this questionnaire template cannot be undone.",
          })}
          onIsOpenChange={handleCloseConfirmDelete}
          onConfirm={() => deleteQuestionnaire(confirmQuestionnaireIdToDelete)}
          confirmText={formatMessage({ defaultMessage: "Delete" })}
          confirmVariant="warning"
        />
      )}
    </>
  );
}

export default observer(QuestionnaireTemplatesPage);
