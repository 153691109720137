import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { MAX_CONTROL_COUNT } from "@/components/detailView/constants";
import { Button, Icon, MiniTag } from "@/components/Elements";
import { iconForThemisModuleIdentifier } from "@/components/helpers/iconForThemisModuleIdentifier";
import PermissionPopup from "@/components/shared/permission-popup/PermissionPopup";
import { useMainStore } from "@/contexts/Store";

interface Props {
  moduleIdentifier: string;
  recordVersionID: number;
  isLocked: boolean;
  isSummaryAndOperational?: boolean;
}

function ViewModuleControlSummary({
  moduleIdentifier,
  recordVersionID,
  isLocked,
  isSummaryAndOperational = false,
}: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // Variables
  const listRecordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const { workspaceID } = mainStore.context;
  const recordVersion = listRecordVersion || mainStore.recordVersions.current;
  const linkedRecords = recordVersion?.record?.owned_control?.linked_records;
  const mwList = mainStore.moduleWorkspaces.list;

  const excludeModules = () => {
    if (isSummaryAndOperational && moduleIdentifier === "risk_register") {
      return ["risk_register"];
    }
    return ["risk_register", "control_mapping"];
  };

  const filteredControls = () =>
    linkedRecords?.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'record' implicitly has an 'any' type.
      (record) => !excludeModules().includes(record.themis_module_identifier),
    );
  const controlsURL = `/workspaces/${workspaceID}/modules/${kebabCase(
    moduleIdentifier,
  )}/${recordVersionID}/controls`;

  // Hooks
  const history = useHistory();

  // State
  const [visibleRecordCount, setVisibleRecordCount] =
    useState(MAX_CONTROL_COUNT);

  // Functions
  function handleShowMore() {
    setVisibleRecordCount(visibleRecordCount + MAX_CONTROL_COUNT);
  }

  return (
    <section className="section-approval">
      <div className="section-header">
        <h3>Module Control Summary</h3>
        <Link to={controlsURL}>
          <Button theme="tertiary" size="sm" label="Select Module Controls" />
        </Link>
      </div>
      <div className="section-content">
        {!filteredControls()?.length && (
          <div className="table-row empty">
            {isLocked
              ? "No Module Controls."
              : "No Module Controls added yet. Click “Module Controls” on top / “Select Module Controls” button to start with."}
          </div>
        )}

        {filteredControls()
          ?.slice(0, visibleRecordCount)
          // @ts-expect-error TS(7006) FIXME: Parameter 'record' implicitly has an 'any' type.
          ?.map((record) => {
            const themisModuleIdentifier = record.themis_module_identifier;
            const moduleAccess = mwList.find(
              (mw) =>
                mw.added &&
                mw.themis_module.identifier === themisModuleIdentifier,
            );
            const moduleURL = `/workspaces/${workspaceID}/modules/${kebabCase(
              record.themis_module_identifier,
            )}/${record.latest_record_version_id}`;

            return (
              <div key={record.id} className="table-row">
                <div className="dv-rr-controls-row">
                  <img
                    src={iconForThemisModuleIdentifier(themisModuleIdentifier)}
                    alt="module-icon"
                  />
                  <span className="module-identifier">
                    {themisModuleIdentifier === "qa_tests_development"
                      ? "Monitoring & Testing"
                      : themisModuleIdentifier.replace(/_/g, " ")}
                  </span>
                  <PermissionPopup
                    trigger={
                      <div className="dv-rr-controls-row__trigger-wrap">
                        <div
                          className="dv-rr-controls-row__trigger"
                          onClick={() =>
                            !!moduleAccess && history.push(moduleURL)
                          }
                        >
                          <MiniTag label={record.global_id} theme="navy" />
                          <div className="dv-rr-controls-row__link">
                            {record.meta?.name}
                          </div>
                          <Icon
                            name="chevronRight"
                            className="dv-rr-controls-row__arrow"
                          />
                        </div>
                      </div>
                    }
                    disabled={!!moduleAccess}
                  />
                </div>
              </div>
            );
          })}

        {filteredControls()?.length > visibleRecordCount && (
          <div className="table-row">
            <div
              className="dv-rr-controls-row cursor-pointer"
              onClick={handleShowMore}
            >
              {`+ ${
                filteredControls().length - visibleRecordCount
              } more module controls`}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default observer(ViewModuleControlSummary);
