import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  HeaderTabs,
  IconButton,
} from "@themis/ui";
import { observer } from "mobx-react";
import { PiArchiveBold, PiDotsThreeBold } from "react-icons/pi";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { useCurrentUser } from "@/api/queries/users";
import { getSelectedTab } from "@/components/helpers/Tabs";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import AuditTrailButton from "@/features/misc/AuditTrailButton";
import ExportButton from "@/features/misc/ExportButton";
import ImportButton from "@/features/misc/ImportButton";
import { FilterOperand } from "@/hooks/useFilterSort";

import StatusFilterButton from "../components/StatusFilterButton";
import TasksTable from "../components/table/TasksTable";
import { taskTableColumnKeys } from "../config/myTasksTableConfig";
import { myTaskRoutes } from "../routes";

const TASKS_RECORD_TYPE = "tasks";

function MyTasksPage() {
  const TABS = [
    {
      name: "My Tasks",
      key: "assigned-to-me",
      value: myTaskRoutes.assignedToMeView,
    },
    {
      name: "Created by me",
      key: "created-by-me",
      value: myTaskRoutes.createdByMeView,
    },
    {
      name: "Assigned to others",
      key: "assigned-to-others",
      value: myTaskRoutes.assignedToOthersView,
    },
    {
      name: "All",
      key: "all",
      value: myTaskRoutes.allView,
    },
  ];

  const history = useHistory();

  const store = useMainStore();

  const { data: user, isPending: isUserPending } = useCurrentUser();
  const userId = user?.id;
  const activeWorkspaceId = user?.active_workspace?.id;

  const isActiveWorkspaceInternal = user?.active_workspace?.is_internal;

  const isAssignedToMeRoute = Boolean(
    useRouteMatch(myTaskRoutes.assignedToMeView),
  );

  const isCreatedByMeRoute = Boolean(
    useRouteMatch(myTaskRoutes.createdByMeView),
  );

  const isAssignedToOthersRoute = Boolean(
    useRouteMatch(myTaskRoutes.assignedToOthersView),
  );

  const tasksInActiveWorkspaceFilterQueryParam = {
    workspace_id: {
      [FilterOperand.ANY]: String(activeWorkspaceId),
    },
  };

  const assignedToMeFilterQueryParam = {
    collaborator_ids: {
      [FilterOperand.ANY]: String(userId),
    },
  };

  const createdByMeFilterQueryParam = {
    created_by_id: {
      [FilterOperand.ANY]: String(userId),
    },
  };

  const assignedToOthersFilterQueryParam = {
    created_by_id: {
      [FilterOperand.ANY]: String(userId),
    },
    assignee_id: {
      [FilterOperand.NOT]: String(userId),
    },
  };

  return (
    <PageLayout>
      <Header
        title="Tasks"
        HeaderOptions={
          <>
            <AuditTrailButton recordType={TASKS_RECORD_TYPE} />
            <Button
              size="sm"
              onClick={() =>
                store.taskDetail.open(
                  isAssignedToMeRoute
                    ? {
                        assignee_id: store.users.user.id,
                        collaborator_ids: [store.users.user.id!],
                      }
                    : undefined,
                )
              }
            >
              Add New Task
            </Button>
            <ExportButton
              recordType={TASKS_RECORD_TYPE}
              excludeWorkspaceParam
            />
            <ImportButton recordType={TASKS_RECORD_TYPE} />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <IconButton Icon={PiDotsThreeBold} color="tertiary" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => history.push(myTaskRoutes.archived)}
                  className="tw-flex tw-items-center tw-gap-2"
                >
                  <PiArchiveBold />
                  View Archived Tasks
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        }
      />
      <div className="tw-flex tw-h-10 tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-white tw-px-6">
        <HeaderTabs
          tabs={TABS}
          selectedTab={getSelectedTab(TABS)?.value || ""}
          isLink
        />
        <StatusFilterButton />
      </div>
      <PageContent>
        <Switch>
          <Route path={myTaskRoutes.viewsBase}>
            <TasksTable
              displayedColumnKeys={taskTableColumnKeys}
              initialFilters={
                !isUserPending
                  ? {
                      // Internal WSs show tasks in all WSs, CW show only tasks related to that WS
                      ...(isActiveWorkspaceInternal === false &&
                        tasksInActiveWorkspaceFilterQueryParam),
                      ...(isAssignedToMeRoute && assignedToMeFilterQueryParam),
                      ...(isCreatedByMeRoute && createdByMeFilterQueryParam),
                      ...(isAssignedToOthersRoute &&
                        assignedToOthersFilterQueryParam),
                    }
                  : null
              }
            />
          </Route>
        </Switch>
      </PageContent>
    </PageLayout>
  );
}

export default observer(MyTasksPage);
