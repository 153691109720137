import { faker } from "@faker-js/faker";

import type {
  GetDashboardComponent200,
  GetDashboardComponentPathParams,
  GetDashboardComponentQueryResponse,
} from "../models/GetDashboardComponent";
import { createDashboardComponent } from "./createDashboardComponent";

export function createGetDashboardComponentPathParams(): NonNullable<GetDashboardComponentPathParams> {
  return {
    system_name: faker.string.alpha(),
    workspace_id: faker.number.int(),
  };
}

/**
 * @description Task link updated
 */
export function createGetDashboardComponent200(): NonNullable<GetDashboardComponent200> {
  return { data: createDashboardComponent() };
}

/**
 * @description Task link updated
 */
export function createGetDashboardComponentQueryResponse(): NonNullable<GetDashboardComponentQueryResponse> {
  return { data: createDashboardComponent() };
}
