import { faker } from "@faker-js/faker";

import type { TaskSection } from "../models/TaskSection";

export function createTaskSection(
  data: NonNullable<Partial<TaskSection>> = {},
): NonNullable<TaskSection> {
  return {
    ...{
      id: faker.number.int(),
      title: faker.string.alpha(),
      position: faker.number.float(),
    },
    ...data,
  };
}
