import "./risk-assessment-tabs.scss";

import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import { generatePath } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";

import { routes } from "../routes";

function TemplateTabs() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const TABS = [
    {
      name: "Questionnaires",
      key: "questionnaire-template",
      value: generatePath(routes.QUESTIONNAIRE_TEMPLATE_PATH, {
        workspace_id: Number(workspaceID),
      }),
    },
    {
      name: "Risk Methodologies",
      key: "risk-methodology",
      value: generatePath(routes.METHODOLOGY_PATH, {
        workspace_id: Number(workspaceID),
      }),
    },
    {
      name: "Risk Areas",
      key: "risk-area",
      value: generatePath(routes.RISK_AREA_PATH, {
        workspace_id: Number(workspaceID),
      }),
    },
  ];

  return (
    <div className="risk-assessment-tabs">
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS, true)?.value || ""}
        isLink
      />
    </div>
  );
}

export default observer(TemplateTabs);
