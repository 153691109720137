import { http } from "msw";

import { createCreateRiskRegisterGroupItemInGroupMutationResponse } from "../createCreateRiskRegisterGroupItemInGroup";

export const createRiskRegisterGroupItemInGroupHandler = http.post(
  "*/workspaces/:workspace_id/risk_registers/risk_register_groups/:risk_register_group_id/risk_register_group_items",
  function handler(info) {
    return new Response(
      JSON.stringify(
        createCreateRiskRegisterGroupItemInGroupMutationResponse(),
      ),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
