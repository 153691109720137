import type { ColDef } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { Table, useToast } from "@themis/ui";
import axios from "axios";
import { useIntl } from "react-intl";
import { useParams } from "react-router";

import type { RecordLink, RecordType, Relatable } from "@/api";
import { createRelatable } from "@/api/gen";
import { useCreateRecordLink } from "@/api/queries/record-links/use-create-record-link";
import { useRecordLinks } from "@/api/queries/record-links/use-record-links";

import RecordSelect from "../tasks/components/Select/RecordSelect";
import { recordTypeToRelatableType } from "./helpers";
import {
  InfoPreviewActions,
  InfoPreviewFooter,
  InfoPreviewHeader,
  InfoPreviewSection,
  InfoPreviewTitle,
} from "./info-preview-section/info-preview-section";
import { RecordLinkActionsCell } from "./RecordLinkActionsCell";

const createColumns = (
  type: "outward_record" | "record",
  recordType: RecordType,
): ColDef<RecordLink>[] => [
  {
    headerName: "Title",
    field: `${type}.name`,
    cellRenderer: "identifierCellRenderer",
    cellRendererParams: (props: CustomCellRendererProps<RecordLink>) => {
      const record = props.data?.[type];
      return record
        ? { ...props, to: record.path }
        : { ...props, value: "No Access" };
    },
  },
  {
    headerName: "",
    maxWidth: 40,
    sortable: false,
    resizable: false,
    flex: 2,
    pinned: "right",
    cellRenderer: (props: CustomCellRendererProps<RecordLink>) => {
      if (!props.data?.[type]) {
        return null;
      }
      return (
        <RecordLinkActionsCell
          recordLink={props.data}
          recordType={recordType}
        />
      );
    },
  },
];

export function RecordLinkSection({
  recordType,
  recordId,
  disabled,
}: {
  recordType: RecordType;
  recordId: number;
  disabled: boolean;
}) {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const query = useRecordLinks(recordType, recordId);
  const recordLinksTo = query.data?.data.record_links_to ?? [];
  const recordLinksFrom = query.data?.data.record_links_from ?? [];

  const params = useParams<{ workspace_id: string }>();
  const workspaceId = Number(params.workspace_id);

  const { mutateAsync: createRecordLink } = useCreateRecordLink({
    recordType,
    recordId,
  });

  const outboundColumns = createColumns("outward_record", recordType);
  const outboundCount = recordLinksFrom?.length;
  const inboundColumns = createColumns("record", recordType);
  const inboundCount = recordLinksTo?.length;

  const currentRecordRelatable = createRelatable({
    id: recordId,
    relatable_type: recordTypeToRelatableType(recordType),
  });

  const ignoredRecords = recordLinksFrom
    .map((record) => record.outward_record)
    .concat(recordLinksTo.map((record) => record.record))
    .concat([currentRecordRelatable])
    .filter((record): record is Relatable => record !== null);

  function handleCreate(selectedRecord: Relatable) {
    createRecordLink(selectedRecord, {
      onSuccess: () => {
        toast({
          content: formatMessage(
            {
              defaultMessage: "{name} has been linked!",
              description: "Success message when record is linked",
            },
            { name: selectedRecord.name },
          ),
          variant: "success",
        });
      },
      onError: (error) => {
        const error_msg =
          axios.isAxiosError(error) && error?.response?.data?.errors?.base
            ? error.response.data.errors.base
            : formatMessage(
                {
                  defaultMessage: "Failed to link {name}!",
                  description: "Error message when linking fails",
                },
                { name: selectedRecord.name },
              );
        toast({
          content: error_msg,
          variant: "error",
        });
      },
    });
  }

  return (
    <>
      <InfoPreviewSection>
        <InfoPreviewHeader>
          <InfoPreviewTitle>
            {formatMessage({
              defaultMessage: "Linked Records",
              description: "Links from this Record",
            })}
          </InfoPreviewTitle>
          <InfoPreviewActions>
            <RecordSelect
              ignoredRecords={ignoredRecords}
              selectedWorkspaceId={workspaceId}
              isDisabled={disabled}
              onRecordSelect={handleCreate}
            />
          </InfoPreviewActions>
        </InfoPreviewHeader>
        <InfoPreviewFooter>
          {Boolean(outboundCount) && (
            <Table
              columns={outboundColumns}
              rows={recordLinksFrom}
              enablePinning={false}
            />
          )}
        </InfoPreviewFooter>
      </InfoPreviewSection>

      <InfoPreviewSection>
        <InfoPreviewHeader>
          <InfoPreviewTitle>
            {formatMessage({
              defaultMessage: "Referenced In",
              description: "Links to this Record",
            })}
          </InfoPreviewTitle>
        </InfoPreviewHeader>
        <InfoPreviewFooter>
          {Boolean(inboundCount) && (
            <Table
              columns={inboundColumns}
              rows={recordLinksTo}
              enablePinning={false}
            />
          )}
        </InfoPreviewFooter>
      </InfoPreviewSection>
    </>
  );
}
