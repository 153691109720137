import { faker } from "@faker-js/faker";

import type {
  ControlMappingsEffectiveness,
  ControlMappingsEffectiveness200,
  ControlMappingsEffectiveness400,
  ControlMappingsEffectiveness403,
  ControlMappingsEffectiveness422,
  ControlMappingsEffectivenessPathParams,
  ControlMappingsEffectivenessQueryResponse,
} from "../models/ControlMappingsEffectiveness";

export function createControlMappingsEffectiveness(
  data: NonNullable<Partial<ControlMappingsEffectiveness>> = {},
): NonNullable<ControlMappingsEffectiveness> {
  return {
    ...{
      id: faker.number.int(),
      module_workspace_id: faker.number.int(),
      color: faker.string.alpha(),
      title: faker.string.alpha(),
      value: faker.number.float(),
      risk_type: faker.string.alpha(),
    },
    ...data,
  };
}

export function createControlMappingsEffectivenessPathParams(): NonNullable<ControlMappingsEffectivenessPathParams> {
  return { module_workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createControlMappingsEffectiveness200(): NonNullable<ControlMappingsEffectiveness200> {
  return {
    data: faker.helpers.arrayElements([
      createControlMappingsEffectiveness(),
    ]) as any,
  };
}

/**
 * @description Bad Request
 */
export function createControlMappingsEffectiveness400(): NonNullable<ControlMappingsEffectiveness400> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Forbidden
 */
export function createControlMappingsEffectiveness403(): NonNullable<ControlMappingsEffectiveness403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Bad request error.
 */
export function createControlMappingsEffectiveness422(): NonNullable<ControlMappingsEffectiveness422> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createControlMappingsEffectivenessQueryResponse(): NonNullable<ControlMappingsEffectivenessQueryResponse> {
  return {
    data: faker.helpers.arrayElements([
      createControlMappingsEffectiveness(),
    ]) as any,
  };
}
