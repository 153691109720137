import { faker } from "@faker-js/faker";

import type {
  GetProject200,
  GetProjectPathParams,
  GetProjectQueryResponse,
} from "../models/GetProject";
import { createProject } from "./createProject";

export function createGetProjectPathParams(): NonNullable<GetProjectPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetProject200(): NonNullable<GetProject200> {
  return { data: createProject() };
}

/**
 * @description OK
 */
export function createGetProjectQueryResponse(): NonNullable<GetProjectQueryResponse> {
  return { data: createProject() };
}
