export type SortDirection = "asc" | "desc";

export function sortByText<T extends object>(
  items: T[],
  valueFn: (item: T) => string,
  direction: SortDirection = "asc",
) {
  const directionValue = direction === "asc" ? 1 : -1;

  return items.sort((a, b) => {
    const aValue = valueFn(a);
    const bValue = valueFn(b);

    const compareValue = aValue.localeCompare(bValue, undefined, {
      sensitivity: "base",
      caseFirst: "false",
      numeric: true,
      usage: "sort",
    });

    return directionValue * compareValue;
  });
}
