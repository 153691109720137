import "./questionnaire-partner-review-respondents.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { Flex, Typography } from "@/components/Elements";
import { UsersCircle } from "@/components/users-circle/users-circle";
import { COLOR } from "@/config/theme";
import type { User } from "@/stores/types/user-types";

interface Props {
  assignedUsers?: User[];
}

function QuestionnairePartnerReviewRespondents({ assignedUsers = [] }: Props) {
  const [showPopup, setShowPopup] = useState(false);

  const onOpen = () => setShowPopup(true);
  const onClose = () => setShowPopup(false);
  const trigger = (
    <Flex className="questionnaire-partner-review-respondents">
      <span>
        {!!assignedUsers.length && (
          <Typography label="Respondents" size="sm" color="generalDarkGray" />
        )}
        {!assignedUsers.length && (
          <Typography
            label="*This question can be answered by anyone in the team"
            color="generalDuskDark"
          />
        )}
      </span>
      {assignedUsers && (
        <div className="user-assignment-flex">
          {assignedUsers
            .filter((u, index) => index < 3)
            .map((user, index) => (
              <div
                className={classNames({ "user-circle-assignment": index > 0 })}
                key={`user-responents-in-review-${user.id}`}
              >
                <UsersCircle
                  initials={user.initials}
                  iconColorIndex={user.icon_color_index!}
                />
              </div>
            ))}
          {assignedUsers && assignedUsers.length > 3 && (
            <div className="user-circle-assignment">
              <UsersCircle
                initials={`+${assignedUsers.length - 3}`}
                customColor={COLOR.generalDarkGray}
                iconColorIndex={0}
              />
            </div>
          )}
        </div>
      )}
    </Flex>
  );

  return (
    <Popup
      position="bottom left"
      trigger={<div>{trigger}</div>}
      open={showPopup}
      onOpen={onOpen}
      onClose={onClose}
      disabled={!assignedUsers.length}
      on="hover"
      keepTooltipInside
    >
      <div className="questionnaire-partner-review-respondent-list">
        <Typography
          label="Respondent List"
          className="questionnaire-partner-review-respondent-list-header"
          color="generalMidnightDark"
        />

        {assignedUsers.map((user) => (
          <div
            className="user-item"
            key={`show-user-in-review-respondents-${user.id}`}
          >
            <UsersCircle
              initials={user.initials}
              iconColorIndex={user.icon_color_index!}
              small
            />

            <Typography
              label={user.full_name}
              size="sm"
              color="generalMidnightDark"
            />
          </div>
        ))}
      </div>
    </Popup>
  );
}

export default observer(QuestionnairePartnerReviewRespondents);
