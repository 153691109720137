import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DestroyManyMutationResponse,
  DestroyManyPathParams,
  DestroyManyQueryParams,
} from "../../models/DestroyMany";

/**
 * @description Bulk delete
 * @summary Bulk delete
 * @link /companies/:company_id/:record_type/destroy_many
 */
export async function destroyMany(
  companyId: DestroyManyPathParams["company_id"],
  recordType: DestroyManyPathParams["record_type"],
  params: DestroyManyQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DestroyManyMutationResponse>["data"]> {
  const res = await client<DestroyManyMutationResponse>({
    method: "delete",
    url: `/companies/${companyId}/${recordType}/destroy_many`,
    params,
    ...options,
  });
  return res.data;
}
