import { http } from "msw";

import { createCreateTagMutationResponse } from "../createCreateTag";

export const createTagHandler = http.post(
  "*/companies/:company_id/tags",
  function handler(info) {
    return new Response(JSON.stringify(createCreateTagMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
