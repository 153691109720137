var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cva } from "cva";
import { debounce } from "lodash";
import * as React from "react";
import { PiCaretLeft } from "react-icons/pi";
import { cn } from "../../lib/utils";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, } from "../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
var defaultStyles = "tw-flex tw-w-full tw-bg-neutral-25 tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-transition-colors";
var fontStyles = "tw-text-sm tw-font-semibold tw-font-sans";
var focusStyles = "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
var readOnlyStyles = "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var lockedStyles = "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var DEBOUNCE_TIMEOUT = 250;
var selectInputVariants = cva({
    base: [defaultStyles, fontStyles, focusStyles],
    variants: {
        size: {
            sm: "tw-h-7",
            md: "tw-h-8",
            lg: "tw-h-9",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});
var MultiStepSelect = React.forwardRef(function (_a, ref) {
    var _b;
    var steps = _a.steps, _c = _a.initialActiveStep, initialActiveStep = _c === void 0 ? 0 : _c, _d = _a.onSelect, onSelect = _d === void 0 ? function () { } : _d, _e = _a.onGoBack, onGoBack = _e === void 0 ? function () { } : _e, className = _a.className, locked = _a.locked, readOnly = _a.readOnly, _f = _a.buttonContent, buttonContent = _f === void 0 ? "Select" : _f, buttonProps = _a.buttonProps;
    var _g = React.useState(initialActiveStep), activeStep = _g[0], setActiveStep = _g[1];
    var _h = React.useState(false), open = _h[0], setOpen = _h[1];
    var _j = React.useState(""), search = _j[0], setSearch = _j[1];
    var disabled = locked || readOnly;
    var resetValues = function () {
        setActiveStep(initialActiveStep);
        setSearch("");
        setOpen(false);
    };
    var handleSelect = function (value) {
        if (activeStep < steps.length - 1) {
            setActiveStep(function (prev) { return prev + 1; });
            onSelect(activeStep, value);
        }
        else {
            resetValues();
            // Since we hide the selected item from the list, this ensures list doesn't glitch before closing popup
            setTimeout(function () { return onSelect(activeStep, value); }, 100);
        }
    };
    var goBack = function () {
        onGoBack(activeStep);
        setActiveStep(activeStep - 1);
    };
    var _k = steps[activeStep], title = _k.title, subtitle = _k.subtitle, searchable = _k.searchable, items = _k.items, Component = _k.Component, onScroll = _k.onScroll, onSearchChange = _k.onSearchChange;
    var debouncedHandleFetchSearchResults = React.useMemo(function () {
        return debounce(function (keyword) {
            if (onSearchChange) {
                onSearchChange(keyword);
            }
        }, DEBOUNCE_TIMEOUT);
    }, [onSearchChange]);
    var onScrollChange = function (e) {
        if (onScroll &&
            e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
                e.currentTarget.scrollHeight) {
            onScroll(search);
        }
    };
    var onTextChange = function (value) {
        setSearch(value);
        debouncedHandleFetchSearchResults(value);
    };
    return (React.createElement(Popover, { open: open, onOpenChange: function (val) {
            setOpen(val);
            if (!val) {
                resetValues();
            }
        } },
        React.createElement(PopoverTrigger, { disabled: disabled, className: cn((_b = {},
                _b[lockedStyles] = locked,
                _b[readOnlyStyles] = readOnly,
                _b["tw-cursor-default"] = disabled,
                _b), className), ref: ref, asChild: true, onClick: function () { return setOpen(true); } },
            React.createElement(Button, __assign({}, buttonProps, { disabled: disabled, size: "sm" }), buttonContent)),
        React.createElement(PopoverContent, { align: "start", side: "left", className: "tw-min-w-60" },
            (activeStep > initialActiveStep || title) && (React.createElement("div", { className: "tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100" },
                React.createElement("div", { className: "tw-flex tw-items-center tw-justify-between tw-rounded-t-md tw-bg-neutral-25 tw-p-1" },
                    React.createElement("div", { className: "tw-flex tw-items-center" },
                        activeStep > initialActiveStep && (React.createElement(IconButton, { Icon: PiCaretLeft, onClick: goBack, className: "[&_svg]:tw-fill-primaryDim-300", color: "transparent", size: "lg", "popup-select-button": "true" })),
                        title && (React.createElement("div", { className: "tw-text-sm tw-font-semibold tw-text-primaryDim-300" }, title)))))),
            React.createElement(Command, { tabIndex: 0, className: "tw-outline-none", shouldFilter: !onSearchChange },
                searchable && (React.createElement(CommandInput, { autoFocus: true, value: search, onValueChange: onTextChange, placeholder: "Search..." })),
                subtitle && (React.createElement("div", { className: cn("tw-w-full tw-px-4 tw-pt-1 tw-text-sm tw-font-semibold tw-text-primaryDim-300", {
                        "tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-neutral-100": searchable,
                    }) }, subtitle)),
                React.createElement(CommandList, { className: "tw-max-h-72 tw-overflow-y-auto", onScroll: onScrollChange },
                    searchable && React.createElement(CommandEmpty, null, "No results."),
                    React.createElement(CommandGroup, null, items.map(function (_a) {
                        var value = _a.value, label = _a.label, other = _a.other;
                        return (React.createElement(CommandItem, { key: value + label, value: value.toString(), keywords: [label], onSelect: handleSelect }, Component
                            ? Component(label, value.toString(), other)
                            : label));
                    })))))));
});
MultiStepSelect.displayName = "MultiStepSelect";
export { MultiStepSelect, };
