import { faker } from "@faker-js/faker";

import type { ShowRecordVersion } from "../models/ShowRecordVersion";

export function createShowRecordVersion(
  data: NonNullable<Partial<ShowRecordVersion>> = {},
): NonNullable<ShowRecordVersion> {
  return {
    ...{
      record_version: {},
      fields: faker.helpers.arrayElements([undefined]) as any,
      field_options: {},
      module_workspace_id: faker.number.int(),
    },
    ...data,
  };
}
