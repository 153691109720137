import { http } from "msw";

import { createUpdateProjectMutationResponse } from "../createUpdateProject";

export const updateProjectHandler = http.put(
  "*/projects/:id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateProjectMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
