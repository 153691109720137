import { faker } from "@faker-js/faker";

import type {
  ListShowRecords200,
  ListShowRecords400,
  ListShowRecords403,
  ListShowRecordsQueryParams,
  ListShowRecordsQueryResponse,
} from "../models/ListShowRecords";
import { createRecordShow } from "./createRecordShow";

export function createListShowRecordsQueryParams(): NonNullable<ListShowRecordsQueryParams> {
  return { data_type: faker.string.alpha(), workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListShowRecords200(): NonNullable<ListShowRecords200> {
  return { data: faker.helpers.arrayElements([createRecordShow()]) as any };
}

/**
 * @description Bad Request
 */
export function createListShowRecords400(): NonNullable<ListShowRecords400> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Forbidden
 */
export function createListShowRecords403(): NonNullable<ListShowRecords403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createListShowRecordsQueryResponse(): NonNullable<ListShowRecordsQueryResponse> {
  return { data: faker.helpers.arrayElements([createRecordShow()]) as any };
}
