import { faker } from "@faker-js/faker";

import type {
  UpdateFindingStatusOpen200,
  UpdateFindingStatusOpenMutationResponse,
  UpdateFindingStatusOpenPathParams,
} from "../models/UpdateFindingStatusOpen";
import { createFinding } from "./createFinding";

export function createUpdateFindingStatusOpenPathParams(): NonNullable<UpdateFindingStatusOpenPathParams> {
  return { finding_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateFindingStatusOpen200(): NonNullable<UpdateFindingStatusOpen200> {
  return { data: createFinding() };
}

/**
 * @description OK
 */
export function createUpdateFindingStatusOpenMutationResponse(): NonNullable<UpdateFindingStatusOpenMutationResponse> {
  return { data: createFinding() };
}
