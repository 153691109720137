import { useMutation } from "@tanstack/react-query";
import type { UseMutationOptions } from "@tanstack/react-query";

import { policyExportPdf } from "@/api/gen/axios/policiesController";
import type {
  PolicyExportPdfQueryParams,
  PolicyExportPdfQueryResponse,
} from "@/api/gen/models";

export function usePolicyExport<TError = Error>(
  recordVersionId: number,
  options?: Partial<
    UseMutationOptions<
      PolicyExportPdfQueryResponse["data"],
      TError,
      PolicyExportPdfQueryParams
    >
  >,
) {
  return useMutation({
    mutationFn: async (params: PolicyExportPdfQueryParams) => {
      const response = await policyExportPdf(recordVersionId, params);
      return response.data;
    },
    ...options,
  });
}
