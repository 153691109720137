import { faker } from "@faker-js/faker";

import type { PaginatedMeta } from "../models/PaginatedMeta";

export function createPaginatedMeta(
  data: NonNullable<Partial<PaginatedMeta>> = {},
): NonNullable<PaginatedMeta> {
  return {
    ...{
      total_count: faker.number.int(),
      offset: faker.number.int(),
      limit: faker.number.int(),
      next_page: faker.datatype.boolean(),
    },
    ...data,
  };
}
