import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import { getWorkspacesQueryKey } from "@/api/queries/workspaces/get-workspaces-query-key";
import { _1_HOUR_MS } from "@/constants/duration";
import type { Workspace } from "@/stores/types/workspace-types";
import type { RailsErrors } from "@/utils/errors";

import { getWorkspaces } from "../../legacy/workspaces/workspaces";

export function useWorkspaces<TError = RailsErrors, TData = Workspace[]>(
  options: Partial<UseQueryOptions<Workspace[], TError, TData>> = {},
) {
  return useQuery({
    queryKey: getWorkspacesQueryKey(),
    queryFn: async () => {
      const response = await getWorkspaces();

      return response.workspaces;
    },
    staleTime: _1_HOUR_MS,
    ...options,
  });
}
