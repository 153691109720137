import { faker } from "@faker-js/faker";

import type {
  UpdateFindingStatusClose200,
  UpdateFindingStatusCloseMutationResponse,
  UpdateFindingStatusClosePathParams,
} from "../models/UpdateFindingStatusClose";
import { createFinding } from "./createFinding";

export function createUpdateFindingStatusClosePathParams(): NonNullable<UpdateFindingStatusClosePathParams> {
  return { finding_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateFindingStatusClose200(): NonNullable<UpdateFindingStatusClose200> {
  return { data: createFinding() };
}

/**
 * @description OK
 */
export function createUpdateFindingStatusCloseMutationResponse(): NonNullable<UpdateFindingStatusCloseMutationResponse> {
  return { data: createFinding() };
}
