import { useQuery } from "@tanstack/react-query";

import { fetchCompanyInfo } from "@/api/legacy/clearbit";

export const CLEARBIT_QUERY_KEY = "clearbit";

export function useClearbit(companyNameOrDomain: string) {
  return useQuery({
    queryKey: [CLEARBIT_QUERY_KEY, companyNameOrDomain],
    queryFn: () => fetchCompanyInfo(companyNameOrDomain),
  });
}
