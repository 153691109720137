import "../questionnaire-builder-question.scss";

import type { DraggableProvided } from "@akojic27/react-beautiful-dnd";
import { Draggable } from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import { observer } from "mobx-react";
import type { ReactNode } from "react";

import { getDraggbleIdFromQuestionId } from "@/components/helpers/draggable";
import {
  REVIEWER,
  SELECTABLE_QUESTION_TYPES,
} from "@/features/risk-assessment/config";
import draggableIcon from "@/images/table-image/icon/draggable-icon.svg";

import {
  TruncateText,
  Typography,
} from "../../../../../../components/Elements";
import type {
  Question,
  ResponderType,
  ReviewerType,
} from "../../../../types/questionnaire";
import { QuestionHeader } from "./question-header/question-header";
import { UserAssignmentSection } from "./user-assignment-section/user-assignment-section";

interface QuestionnaireBuilderQuestionProps {
  question: Question;
  order: string;
  onChange: (question: Question) => void;
  onDelete: () => void;
  onCopy?: () => void;
  depth?: number;
  isCollapsed?: boolean;
  onCollapseChange?: (questionId: number, isCollapsed: boolean) => void;
  isNew: boolean;
  isInternal: boolean;
  sectionReviewerIDs?: number[];
  sectionResponderIDs?: number[];
  expandedContent: ReactNode;
}

function QuestionnaireBuilderQuestion({
  question,
  order,
  onChange,
  expandedContent,
  onCopy,
  onDelete,
  depth = 0,
  isCollapsed = false,
  onCollapseChange = () => {},
  isNew,
  isInternal,
  sectionReviewerIDs = [],
  sectionResponderIDs = [],
}: QuestionnaireBuilderQuestionProps) {
  const nonDestroyedReviewerUserIDS = question.assignments
    .filter((assignment) => !assignment._destroy)
    .map((assignment) => assignment.user_id)
    .concat(sectionReviewerIDs);

  const nonDestroyedResponderIDs = question.responders
    .filter((assignment) => !assignment._destroy)
    .map((assignment) => assignment.user_id)
    .concat(sectionResponderIDs);

  const questionTypeInfo =
    SELECTABLE_QUESTION_TYPES.find(
      (questionType) => questionType.type === question.input_type,
    ) || SELECTABLE_QUESTION_TYPES[0];
  const isTriggerQuestion = depth > 0;

  function onChangeAssignee(
    ids: number[],
    assignmentType: ReviewerType | ResponderType,
  ) {
    const assignmentGroup =
      question[assignmentType === REVIEWER ? "assignments" : "responders"];

    const assigneeIdsToDestroy = assignmentGroup
      .filter((assignment) => !ids.some((id) => id === assignment.user_id))
      .map((assignment) => ({ ...assignment, _destroy: true }));

    onChange({
      ...question,
      [assignmentType === REVIEWER ? "assignments" : "responders"]: [
        ...ids
          .filter(
            (id) =>
              !(
                assignmentType === REVIEWER
                  ? sectionReviewerIDs
                  : sectionResponderIDs
              ).includes(id),
          )
          .map((user_id) => {
            const existingAssignment = assignmentGroup.find(
              (a) => a.user_id === user_id,
            );

            if (existingAssignment) {
              return {
                ...existingAssignment,
                _destroy: false,
              };
            }

            return {
              assignment_type: assignmentType,
              user_id,
            };
          }),
        ...assigneeIdsToDestroy,
      ],
    });
  }

  const renderContent = (provided?: DraggableProvided) => (
    <div
      className={classNames("questionnaire-question", {
        "questionnaire-question__trigger": isTriggerQuestion,
      })}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      {provided && (
        <div
          {...provided.dragHandleProps}
          className="questionnaire-question__handle"
          data-tooltip-id="tooltip"
          data-tooltip-content="Drag to reorder or to move this question to another section on the left."
          data-tooltip-place="bottom"
          data-testid="questionnaire-question-reorder"
        >
          <img src={draggableIcon} />
        </div>
      )}

      <QuestionHeader
        isCollapsed={isCollapsed}
        isTriggerQuestion={isTriggerQuestion}
        onCollapseChange={onCollapseChange}
        onCopy={onCopy}
        onDelete={onDelete}
        order={order}
        question={question}
        questionTypeInfo={questionTypeInfo}
      />

      <UserAssignmentSection
        isInternal={isInternal}
        isNew={isNew}
        nonDestroyedResponderIDs={nonDestroyedResponderIDs}
        nonDestroyedReviewerUserIDS={nonDestroyedReviewerUserIDS}
        onChangeAssignee={onChangeAssignee}
        sectionResponderIDs={sectionResponderIDs}
        sectionReviewerIDs={sectionReviewerIDs}
      />

      {isCollapsed ? (
        <Typography
          className="questionnaire-question__text"
          label={
            <TruncateText text={question.text} toLength={70} displayTooltip />
          }
          color="generalMidnightDark"
        />
      ) : (
        expandedContent
      )}
    </div>
  );

  if (isTriggerQuestion) {
    return renderContent();
  }

  return (
    <Draggable
      draggableId={getDraggbleIdFromQuestionId(question.id)}
      index={parseFloat(order)} // root level questions use numbers exclusively
      dropTargetCalculationMode="pointer"
    >
      {(provided) => renderContent(provided)}
    </Draggable>
  );
}

export default observer(QuestionnaireBuilderQuestion);
