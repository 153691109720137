import { faker } from "@faker-js/faker";

import type {
  GetRiskMethodologies200,
  GetRiskMethodologies403,
  GetRiskMethodologiesPathParams,
  GetRiskMethodologiesQueryResponse,
} from "../models/GetRiskMethodologies";
import { createRiskMethodology } from "./createRiskMethodology";

export function createGetRiskMethodologiesPathParams(): NonNullable<GetRiskMethodologiesPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetRiskMethodologies200(): NonNullable<GetRiskMethodologies200> {
  return {
    data: faker.helpers.arrayElements([createRiskMethodology()]) as any,
  };
}

/**
 * @description Forbidden
 */
export function createGetRiskMethodologies403(): NonNullable<GetRiskMethodologies403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createGetRiskMethodologiesQueryResponse(): NonNullable<GetRiskMethodologiesQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createRiskMethodology()]) as any,
  };
}
