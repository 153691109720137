import { http } from "msw";

import { createCreateTaskableMutationResponse } from "../createCreateTaskable";

export const createTaskableHandler = http.post(
  "*/tasks/:task_id/taskables",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateTaskableMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
