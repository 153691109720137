import { http } from "msw";

import { createDeleteFieldOptionMutationResponse } from "../createDeleteFieldOption";

export const deleteFieldOptionHandler = http.delete(
  "*/fields/:field_id/options/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteFieldOptionMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
