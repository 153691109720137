import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useCurrentUser } from "@/api/queries/users";

import CompanyOutlook from "./CompanyOutlook";
import CompanyRetro from "./CompanyRetro";
import { MyWork } from "./MyWork";
import { COMPANY_RETRO_PATH, MY_WORK_PATH } from "./routes";

export function HomeRoutes() {
  const { path } = useRouteMatch();

  const { isPending, isError } = useCurrentUser();

  if (isPending || isError) {
    return null;
  }

  return (
    <Switch>
      <Route path={MY_WORK_PATH}>
        <MyWork />
      </Route>
      <Route path={COMPANY_RETRO_PATH}>
        <CompanyRetro />
      </Route>
      <Route exact path={path}>
        <CompanyOutlook />
      </Route>
    </Switch>
  );
}
