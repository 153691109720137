import { faker } from "@faker-js/faker";

import type {
  CreateIssueManagementRecord201,
  CreateIssueManagementRecord403,
  CreateIssueManagementRecordMutationRequest,
  CreateIssueManagementRecordMutationResponse,
  CreateIssueManagementRecordPathParams,
} from "../models/CreateIssueManagementRecord";
import { createIssueManagementRecord } from "./createIssueManagementRecord";
import { createRecordVersion } from "./createRecordVersion";

export function createCreateIssueManagementRecordPathParams(): NonNullable<CreateIssueManagementRecordPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateIssueManagementRecord201(): NonNullable<CreateIssueManagementRecord201> {
  return { data: createRecordVersion() };
}

/**
 * @description Forbidden
 */
export function createCreateIssueManagementRecord403(): NonNullable<CreateIssueManagementRecord403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateIssueManagementRecordMutationRequest(): NonNullable<CreateIssueManagementRecordMutationRequest> {
  return createIssueManagementRecord();
}

/**
 * @description OK
 */
export function createCreateIssueManagementRecordMutationResponse(): NonNullable<CreateIssueManagementRecordMutationResponse> {
  return { data: createRecordVersion() };
}
