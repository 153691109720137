import { Button } from "@themis/ui";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

import { PageContent } from "@/components/Layout/PageContent";
import moduleIcons from "@/images/modules.webp";

import { PROJECTS_ROUTES } from "../routes";

function ProjectsEmptyState() {
  const history = useHistory();
  const { url } = useRouteMatch();
  return (
    <PageContent>
      <div className="-tw-mt-6 tw-flex tw-flex-col tw-items-center tw-gap-3 tw-text-center tw-font-semibold tw-text-neutral-500">
        <img src={moduleIcons} />
        <p>
          Projects are great for capturing workflows and tracking work across
          teams.
        </p>
        <Button
          size="lg"
          onClick={() =>
            history.push(
              generatePath(`${url}${PROJECTS_ROUTES.details}`, {
                project_id: "new",
              }),
            )
          }
        >
          Create New Project
        </Button>
      </div>
    </PageContent>
  );
}

export default ProjectsEmptyState;
