import { faker } from "@faker-js/faker";

import type { Document } from "../models/Document";
import { createFile } from "./createFile";

export function createDocument(
  data: NonNullable<Partial<Document>> = {},
): NonNullable<Document> {
  return {
    ...{
      id: faker.number.int(),
      upload_date: faker.string.alpha(),
      file: createFile(),
    },
    ...data,
  };
}
