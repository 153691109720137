import { faker } from "@faker-js/faker";

import type {
  RecordVersionsUpdate200,
  RecordVersionsUpdateMutationRequest,
  RecordVersionsUpdateMutationResponse,
  RecordVersionsUpdatePathParams,
} from "../models/RecordVersionsUpdate";
import { createShowRecordVersion } from "./createShowRecordVersion";
import { createUpdateRecordVersionRequest } from "./createUpdateRecordVersionRequest";

export function createRecordVersionsUpdatePathParams(): NonNullable<RecordVersionsUpdatePathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createRecordVersionsUpdate200(): NonNullable<RecordVersionsUpdate200> {
  return { data: createShowRecordVersion() };
}

export function createRecordVersionsUpdateMutationRequest(): NonNullable<RecordVersionsUpdateMutationRequest> {
  return { data: createUpdateRecordVersionRequest() };
}

/**
 * @description OK
 */
export function createRecordVersionsUpdateMutationResponse(): NonNullable<RecordVersionsUpdateMutationResponse> {
  return { data: createShowRecordVersion() };
}
