import { LinkButton, LinkIconButton } from "@themis/ui";
import { PiArchiveBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { useCompany } from "@/api/queries/companies";
import { useCreateProject, useProjects } from "@/api/queries/projects";
import { useCurrentUser } from "@/api/queries/users";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import ImportButton from "@/features/misc/ImportButton";

import ProjectsEmptyState from "../components/ProjectsEmptyState";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { PROJECTS_ARCHIVE_PATH, PROJECTS_ROUTES } from "../routes";

export default function Projects() {
  const { url } = useRouteMatch();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const {
    data: user,
    isPending: isUserPending,
    isError: isUserError,
  } = useCurrentUser();

  const {
    data: company,
    isPending: isCompanyPending,
    isError: isCompanyError,
  } = useCompany("current");

  const {
    data: projects,
    isPending: isProjectsPending,
    isError: isProjectsError,
  } = useProjects(Number(company?.data.company.id));

  const { mutateAsync: createProject } = useCreateProject({
    companyId: Number(company?.data.company.id),
  });

  if (isProjectsPending || isUserPending || isCompanyPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isProjectsError || isUserError || isCompanyError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath("/workspaces/:workspace_id/home", {
              workspace_id,
            }),
          }}
        >
          Could not load projects.
        </ErrorContainer>
      </PageContent>
    );
  }

  const isActiveWorkspaceInternal = user.active_workspace.is_internal;

  const workspaceScopedProjects = isActiveWorkspaceInternal
    ? projects.data
    : projects.data.filter(
        (project) => project.workspace_id === Number(workspace_id),
      );

  return (
    <PageLayout>
      <Header
        title={<>Projects</>}
        HeaderOptions={
          <>
            <LinkButton
              size="sm"
              to={(location) => ({
                pathname: generatePath(`${url}${PROJECTS_ROUTES.details}`, {
                  project_id: "new",
                }),
                state: { from: location.pathname },
              })}
            >
              Add Project
            </LinkButton>
            <ImportButton recordType="projects" />
            <LinkIconButton
              className="tw-text-neutral-500"
              to={(location) => ({
                pathname: generatePath(PROJECTS_ARCHIVE_PATH, {
                  workspace_id,
                }),
                state: { from: location.pathname },
              })}
              Icon={PiArchiveBold}
              color="tertiary"
              data-tooltip-id="tooltip"
              data-tooltip-place="bottom-end"
              data-tooltip-content="View archived projects"
            />
          </>
        }
      />
      {workspaceScopedProjects.length > 0 ? (
        <ProjectsTable
          projects={workspaceScopedProjects}
          createProject={createProject}
          workspace_id={workspace_id}
          companyId={company.data.company.id}
        />
      ) : (
        <ProjectsEmptyState />
      )}
    </PageLayout>
  );
}
