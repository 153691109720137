import { faker } from "@faker-js/faker";

import type { RecordShow } from "../models/RecordShow";

export function createRecordShow(
  data: NonNullable<Partial<RecordShow>> = {},
): NonNullable<RecordShow> {
  return {
    ...{
      id: faker.number.int(),
      global_id: faker.string.alpha(),
      meta: {},
      record_versions: {},
      latest_record_version: {},
      monitoring_testing_issues: faker.helpers.arrayElements([{}]) as any,
      name: faker.string.alpha(),
    },
    ...data,
  };
}
