import { faker } from "@faker-js/faker";

import type { Countries200, CountriesQueryResponse } from "../models/Countries";
import { createCountry } from "./createCountry";

/**
 * @description OK
 */
export function createCountries200(): NonNullable<Countries200> {
  return { data: faker.helpers.arrayElements([createCountry()]) as any };
}

/**
 * @description OK
 */
export function createCountriesQueryResponse(): NonNullable<CountriesQueryResponse> {
  return { data: faker.helpers.arrayElements([createCountry()]) as any };
}
