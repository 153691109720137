import { faker } from "@faker-js/faker";

import type {
  CountByStatus200,
  CountByStatusMutationRequest,
  CountByStatusMutationResponse,
} from "../models/CountByStatus";

/**
 * @description OK
 */
export function createCountByStatus200(): NonNullable<CountByStatus200> {
  return faker.number.int();
}

export function createCountByStatusMutationRequest(): NonNullable<CountByStatusMutationRequest> {
  return {
    module_workspace_id: faker.number.int(),
    statuses: faker.helpers.arrayElement<any>(["open", "closed"]),
  };
}

/**
 * @description OK
 */
export function createCountByStatusMutationResponse(): NonNullable<CountByStatusMutationResponse> {
  return faker.number.int();
}
