import { Label } from "@themis/ui";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import React from "react";

import type { MonitoringGroupAsset, MonitoringGroupAssetChild } from "@/api";

import MonitoringGroupAssetChildSection from "../../components/MonitoringGroupAssetChildSection";
import { MonitoringGroupAssetInfoForm } from "../../components/MonitoringGroupResultInfo/MonitoringGroupAssetInfoForm";
import { MONITORING_GROUP_ASSET_CHILD_RESULT_COLUMNS } from "../../config/monitoringGroupResultsTable";
import { MonitoringSidebarProvider } from "../../providers/MonitoringSidebarProvider";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32"
      style={{ backgroundColor: "white", overflow: "auto", height: "100%" }}
    >
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-col-span-3 tw-flex tw-min-w-28 tw-items-center tw-justify-center tw-gap-8 tw-rounded-md">
      {children}
    </div>
  );
}

function MonitoringGroupResultInfoContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

function WebsiteMonitoringContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "tw-z-0 tw-flex tw-h-full tw-min-w-96 tw-flex-col tw-gap-4",
      )}
    >
      {children}
    </div>
  );
}

export function MonitoringGroupResultInfo({
  monitoringGroupResults,
}: {
  monitoringGroupResults?: MonitoringGroupAsset | null;
}) {
  return (
    <MonitoringSidebarProvider>
      {monitoringGroupResults && (
        <FormContainer>
          <div className="tw-flex tw-flex-row tw-gap-2 tw-py-3">
            <StatusContainer>
              <Label className="tw-h-8 tw-content-center tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-neutral-500">
                Group{" "}
                <span className="tw-text-neutral-300">
                  {monitoringGroupResults?.group?.group_name}
                </span>
              </Label>
              <Label className="tw-h-8 tw-content-center tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-neutral-500">
                Created Date{" "}
                <span className="tw-text-neutral-300">
                  {format(
                    parseISO(
                      monitoringGroupResults?.monitoring_group_asset_created_at ||
                        "",
                    ),
                    "MM/dd/yyyy",
                  )}
                </span>
              </Label>
              <Label className="tw-h-8 tw-content-center tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-neutral-500">
                Last Updated{" "}
                <span className="tw-text-neutral-300">
                  {format(
                    parseISO(
                      monitoringGroupResults?.monitoring_group_asset_updated_at ||
                        "",
                    ),
                    "MM/dd/yyyy",
                  )}
                </span>
              </Label>
            </StatusContainer>
          </div>
          <MonitoringGroupResultInfoContainer>
            <h2 className="tw-text-xl tw-font-semibold tw-text-neutral-500">
              Asset / Job Info
            </h2>
            <MonitoringGroupAssetInfoForm
              defaultValues={monitoringGroupResults}
            />
          </MonitoringGroupResultInfoContainer>

          <MonitoringGroupResultInfoContainer>
            <Label className="tw-text-xl tw-font-semibold tw-text-neutral-500">
              All Asset Results{" "}
              <span className="tw-text-sm tw-text-neutral-300">
                {monitoringGroupResults?.children?.length}{" "}
                {(monitoringGroupResults?.children?.length || 0) > 1
                  ? "children"
                  : "child"}
              </span>
            </Label>

            <WebsiteMonitoringContainer>
              {monitoringGroupResults?.children?.map(
                (
                  monitoring_group_child: MonitoringGroupAssetChild | undefined,
                ) => (
                  <MonitoringGroupAssetChildSection
                    isDropdownOpen
                    key={monitoring_group_child?.id}
                    monitoringGroupAssetChild={monitoring_group_child}
                    rows={monitoring_group_child?.results || []}
                    columns={MONITORING_GROUP_ASSET_CHILD_RESULT_COLUMNS}
                  />
                ),
              )}
            </WebsiteMonitoringContainer>
          </MonitoringGroupResultInfoContainer>
        </FormContainer>
      )}
    </MonitoringSidebarProvider>
  );
}
