import "./styles.scss";

import { observer } from "mobx-react";
import React, { useState } from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController, useWatch } from "react-hook-form";
import Popup from "reactjs-popup";

import { Flex, Icon, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import type { Recipients } from "@/features/notifications/pages/single/types";
import type { ValueLabelOption } from "@/stores/types/field-types";

import MultiSelectField from "../multi-select-field/multi-select-field";
import DepartmentsSearchResults from "./components/departments-search-results/departments-search-results";
import SelectedRecipientsTable from "./components/selected-recipients-table/selected-recipients-table";
import UsersSearchResults from "./components/users-search-results/users-search-results";

type RecipientsProps<T extends FieldValues> = UseControllerProps<T> & {
  columnOptions?: ValueLabelOption[];
  isDisableColumn?: boolean;
  allowedFields?: string[];
};

function NotificationRecipients<T extends FieldValues>({
  control,
  name,
  allowedFields = [],
  ...props
}: RecipientsProps<T>) {
  const mainStore = useMainStore();
  const [showPopup, setShowPopup] = useState(false);
  const [query, setQuery] = useState("");

  const recipients: Recipients = useWatch({ control, name });

  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate: (recipient) =>
        Object.keys(recipient).some((key) => !!recipient[key].length),
    },
  });

  const {
    columns: selectedColumns,
    department_ids: selectedDepartmentIds,
    user_ids: selectedUserIds,
  } = recipients;

  const columnOptions = (() => {
    if (props.columnOptions) {
      return props.columnOptions;
    }

    const options: ValueLabelOption[] = [];
    const fieldsForSelectedModule =
      mainStore.notificationsRules?.fieldsForSelectedModule || [];
    const filteredFieldsForSelectedModule = fieldsForSelectedModule.filter(
      (subField) =>
        !allowedFields.length || allowedFields.includes(subField.name),
    );
    filteredFieldsForSelectedModule.forEach((moduleField) => {
      if (
        [
          "com.askthemis.types.v1.tag_user",
          "com.askthemis.types.v1.tag_verifier",
          "com.askthemis.types.v1.tag_user_contact",
        ].includes(moduleField.data_type)
      ) {
        options.push({
          value: moduleField.name,
          label: moduleField.display_name,
        });
      }
    });

    return options;
  })();

  const updateRecipients =
    (recipientType: keyof Recipients, remove: boolean) =>
    (changeRecipientValue: string | number) => {
      const selectedRecipients: (string | number)[] = (() => {
        if (recipientType === "columns") {
          return selectedColumns;
        }

        if (recipientType === "department_ids") {
          return selectedDepartmentIds;
        }

        return selectedUserIds;
      })();

      const newSelectedRecipients = remove
        ? selectedRecipients.filter(
            (selectedRecipient) => selectedRecipient !== changeRecipientValue,
          )
        : [...selectedRecipients, changeRecipientValue];

      field.onChange({ ...recipients, [recipientType]: newSelectedRecipients });
    };

  const searchInputTrigger = (
    <div className="users-departments-search">
      <input
        className="users-departments-search--input"
        placeholder="– Search Departments/Users –"
        value={query}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
      />
      <Icon name="search" className="users-departments-search--icon" />
    </div>
  );

  const numberOfRecipients =
    (!props.isDisableColumn ? selectedColumns.length : 0) +
    selectedDepartmentIds.length +
    selectedUserIds.length;

  return (
    <Flex column rowGap={8}>
      <Flex alignCenter justifySpaceBetween>
        <Flex alignCenter columnGap={8}>
          <Typography
            label="Recipients"
            color="generalMidnightDark"
            size="lg"
            weight="semiBold"
          />
          <Typography
            label={`${numberOfRecipients} Recipients`}
            color="extrasBlueGrayDarker"
            size="sm"
            weight="semiBold"
          />
        </Flex>
        <Flex alignCenter columnGap={8}>
          {!props.isDisableColumn && (
            <MultiSelectField
              outlined
              hideSelectedValues
              name="recipients.columns"
              control={
                control as unknown as Control<{ recipients: Recipients }>
              }
              placeholder="- Select Column -"
              options={columnOptions}
            />
          )}
          <Popup
            trigger={searchInputTrigger}
            open={showPopup}
            onOpen={() => setShowPopup(true)}
            arrow={false}
          >
            <div className="select wide">
              <div className="select-dropdown">
                <ul>
                  <DepartmentsSearchResults
                    query={query}
                    selectedDepartmentIds={selectedDepartmentIds}
                    onAddDepartment={updateRecipients("department_ids", false)}
                  />
                  <UsersSearchResults
                    query={query}
                    selectedUserIds={selectedUserIds}
                    onAddUser={updateRecipients("user_ids", false)}
                  />
                </ul>
              </div>
            </div>
          </Popup>
        </Flex>
      </Flex>
      <div className="section-content">
        <SelectedRecipientsTable
          columnOptions={columnOptions}
          columns={selectedColumns}
          department_ids={selectedDepartmentIds}
          user_ids={selectedUserIds}
          onRemoveRecipient={updateRecipients}
          // @ts-expect-error TS(2322) FIXME: Type 'FieldError | undefined' is not assignable to... Remove this comment to see the full error message
          error={error}
        />
      </div>
    </Flex>
  );
}

export default observer(NotificationRecipients);
