import { faker } from "@faker-js/faker";

import type { SaveControlsModuleResponse } from "../models/SaveControlsModuleResponse";

export function createSaveControlsModuleResponse(
  data: NonNullable<Partial<SaveControlsModuleResponse>> = {},
): NonNullable<SaveControlsModuleResponse> {
  return {
    ...{
      id: faker.number.int(),
      data: {},
      global_id: faker.string.alpha(),
      owner_id: faker.number.int(),
      record_id: faker.number.int(),
      table_id: faker.number.int(),
      table_name: faker.string.alpha(),
      attachment_groups: faker.helpers.arrayElements([undefined]) as any,
      meta: {},
      record: {},
      reviews: faker.helpers.arrayElements([undefined]) as any,
      status: faker.string.alpha(),
      uncompleted_comments_count: {},
      version: faker.number.int(),
      section_tag_id: faker.number.int(),
      inherent_risk_rating_cell: {},
      residual_risk_rating_cell: {},
    },
    ...data,
  };
}
