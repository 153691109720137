import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";

import { useMainStore } from "@/contexts/Store";
import { useOpen } from "@/hooks/use-open/use-open";

import type { LibraryRecord } from "../../../stores/types/control-mapping-types";
import Checkbox from "../shared/Checkbox";
import SectionDropdownTitle from "../shared/SectionDropdownTitle";
import LibraryTableColumn from "./LibraryTableColumn";
import LibraryTableRow from "./LibraryTableRow";

const COLUMNS = [
  "Framework",
  "Control Category",
  "Type",
  "Control Title",
  "Description",
];

interface LibraryTableProps {
  title: string;
  rows: Array<LibraryRecord>;
  onSelectChange: (rowId: number, value: boolean) => void;
  onSelectAllChange: (rowIds: number[], value: boolean) => void;
  canDeleteRecords: boolean;
  isInternalWorkspace: boolean;
  onDeleteRecord: (id: number) => void;
  onCreate: () => void;
  isOpen: boolean;
  libraryType: string;
  onToggleSection?: (sectionTagId: string, isOpen: boolean) => void;
}

const LibraryTable = (props: LibraryTableProps) => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const sectionTitle = useOpen();
  const [recordIdToDelete, setRecordIdToDelete] = useState<number | null>(null);
  const allSelected = props.rows.every((item) => item.checked);
  const isCompanyLibrary = props.libraryType === "company";
  const isEditable = isCompanyLibrary && props.isInternalWorkspace;

  useEffect(() => {
    if (sectionTitle.isOpen !== props.isOpen) {
      sectionTitle.toggle();
      props.onToggleSection?.(props.title, props.isOpen);
    }
  }, [props.isOpen]);

  const onSelectAllChange: React.ChangeEventHandler<HTMLInputElement> = () => {
    props.onSelectAllChange(
      props.rows.map((row) => row.id),
      !allSelected,
    );
  };

  const onDelete = async (id: number) => {
    await props.onDeleteRecord(id);
    setRecordIdToDelete(null);
  };

  const handleToggleSection = (open: boolean) => {
    sectionTitle.onIsOpenChange(open);
    props.onToggleSection?.(props.title, open);
  };

  return (
    <>
      <SectionDropdownTitle
        title={props.title}
        isOpen={sectionTitle.isOpen}
        onToggleOpen={() => handleToggleSection(!sectionTitle.isOpen)}
        rolePrefix="rr-library-table"
      />
      {sectionTitle.isOpen && (
        <div
          className="rr-library-table-container rr-library-table-container--multi"
          data-testid="rr-library-table"
        >
          <div className="rr-library-table-columns rr-library-table-columns--multi">
            <Checkbox
              checked={allSelected}
              onChange={onSelectAllChange}
              data-testid="rr-library-main-checkbox"
              disabled={!hasModuleWriteAccess}
              padding
            />
            {COLUMNS.map((column) => (
              <LibraryTableColumn key={column} title={column} />
            ))}
          </div>
          <div className="rr-library-table-rows">
            {props.rows.map((row) => (
              <LibraryTableRow
                key={row.id}
                record={row}
                workspaceID={workspaceID}
                onChange={props.onSelectChange}
                isEditable={isEditable}
                canDeleteRecords={
                  props.canDeleteRecords && props.isInternalWorkspace
                }
                onDelete={() => onDelete(row.id)}
                onDeletePromptChange={(show) => {
                  if (show) {
                    setRecordIdToDelete(row.id);
                  } else {
                    setRecordIdToDelete(null);
                  }
                }}
                showDeletePrompt={row.id === recordIdToDelete}
                hasModuleWriteAccess={hasModuleWriteAccess}
              />
            ))}
          </div>
          {props.isInternalWorkspace &&
            isCompanyLibrary &&
            hasModuleWriteAccess && (
              <div
                className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-7 tw-px-[7px] tw-py-[6px]"
                onClick={props.onCreate}
                data-testid="risk-register-library-add-new"
              >
                <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
                <div className="tw-neutral-300 tw-text-sm">Add Library...</div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default observer(LibraryTable);
