import "../questionnaire-templates.scss";

import axios from "axios";
import { format, isPast, parseISO } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { generatePath, NavLink, useHistory } from "react-router-dom";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";
import { Icon, Typography } from "@/components/Elements";
import { formatPercentage } from "@/components/helpers/number-formatters";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import {
  CW_QUESTIONNAIRE_COMPLETED,
  CW_QUESTIONNAIRE_IN_REVIEW,
  CW_QUESTIONNAIRE_SUBMITTED,
} from "@/features/risk-assessment";
import ModuleTabs from "@/features/risk-assessment/components/ModuleTabs/ModuleTabs";
import QuestionnaireDueDateModal from "@/features/risk-assessment/components/Questionnaires/QuestionnaireDueDateModal/QuestionnaireDueDateModal";
import RowOptions from "@/features/risk-assessment/components/RowOptions/RowOptions";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableCellSpacer from "@/features/risk-assessment/components/Table/GenericTable/TableCellSpacer";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import type { CWQuestionnaireRead } from "@/features/risk-assessment/types/questionnaire";

import DashboardContent from "../../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../../components/shared/DashboardHeader";
import EmptyMessage from "../../components/EmptyMessage/EmptyMessage";
import { ModuleContentWrapper } from "../../components/ModuleContentWrapper/ModuleContentWrapper";
import ModuleHeader from "../../components/ModuleHeader/ModuleHeader";
import { routes } from "../../routes";

function PartnerQuestionnairePage() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [questionnaireToUpdate, setQuestionnaireToUpdate] =
    useState<CWQuestionnaireRead | null>(null);
  const [list, setList] = useState<CWQuestionnaireRead[]>([]);

  const isInternalUser = !!mainStore.workspaces.list.find(
    (ws) => ws.is_internal,
  );

  const loadTemplates = async (passedWorkspaceID: number | null) => {
    if (!passedWorkspaceID) {
      return;
    }

    const { questionnaires } = await QuestionnaireAPI.getAllCW(
      passedWorkspaceID,
      undefined,
    );
    setList(questionnaires as CWQuestionnaireRead[]);
    setLoading(false);
  };

  const handleDeleteQuestionnaire = async (questionnaireId: number) => {
    await QuestionnaireAPI.delete(questionnaireId);
    mainStore.riskAssessmentQuestionnaires.deleteQuestionnaire(questionnaireId);
    loadTemplates(workspaceID);
  };

  const generateOptions = (questionnaire: CWQuestionnaireRead) => {
    const cwUserOptions = [];
    if (isInternalUser) {
      if (
        [CW_QUESTIONNAIRE_SUBMITTED, CW_QUESTIONNAIRE_IN_REVIEW].includes(
          questionnaire.status,
        )
      ) {
        cwUserOptions.push({
          label: "Review Questionnaire",
          onClick: () => onReview(questionnaire),
        });
      }
      cwUserOptions.push({
        label: "View Questionnaire Review Page",
        onClick: () => viewPartnerReviewPage(questionnaire),
      });
      if (
        ![
          CW_QUESTIONNAIRE_SUBMITTED,
          CW_QUESTIONNAIRE_IN_REVIEW,
          CW_QUESTIONNAIRE_COMPLETED,
        ].includes(questionnaire.status)
      ) {
        cwUserOptions.push({
          label: "Edit Due Date",
          onClick: () => setQuestionnaireToUpdate(questionnaire),
        });
        cwUserOptions.push({
          label: "Delete from Workspace",
          onClick: () => handleDeleteQuestionnaire(questionnaire.id),
        });
      }
    } else {
      if (
        questionnaire.completion_percentage === 1 &&
        ![
          CW_QUESTIONNAIRE_SUBMITTED,
          CW_QUESTIONNAIRE_IN_REVIEW,
          CW_QUESTIONNAIRE_COMPLETED,
        ].includes(questionnaire.status)
      ) {
        cwUserOptions.push({
          label: "Submit",
          onClick: () => submitQuestionnaire(questionnaire.id),
        });
      }

      cwUserOptions.push({
        label: `${
          [
            CW_QUESTIONNAIRE_SUBMITTED,
            CW_QUESTIONNAIRE_IN_REVIEW,
            CW_QUESTIONNAIRE_COMPLETED,
          ].includes(questionnaire.status)
            ? "View"
            : "Answer"
        } Questionnaire`,
        onClick: () => viewQuestionnaire(questionnaire),
      });
    }

    return cwUserOptions;
  };

  const setNewDueDate = (date: Date | undefined) => {
    if (date === undefined || !questionnaireToUpdate) {
      return;
    }

    setList((prev) =>
      prev.map((subQuestionnaire) => {
        if (subQuestionnaire.id === questionnaireToUpdate.id) {
          subQuestionnaire.due_date = date.toISOString();
        }
        return subQuestionnaire;
      }),
    );
  };

  const submitQuestionnaire = async (questionnaireID: number) => {
    try {
      await QuestionnaireAPI.saveAnswers(questionnaireID, [], true);
      const newList = [...list];
      const questionnaireIndex = list.findIndex(
        (questionnaire) => questionnaire.id === questionnaireID,
      );
      newList[questionnaireIndex].status = "Submitted";
      setList(newList);
      mainStore.toast.setInfoText("Questionnaire successfully submitted");
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response?.data?.errors?.base?.[0].includes(
          "Not all required questions have been answered",
        )
      ) {
        return mainStore.toast.setErrorText("Please, answer all questions.");
      }
      mainStore.toast.setErrorText("Something went wrong.");
    }
  };
  const viewQuestionnaire = (questionnaire: CWQuestionnaireRead) => {
    const redirectionPath = isInternalUser
      ? generatePath(routes.QUESTIONNAIRE_REVIEW_PATH, {
          questionnaireId: questionnaire.id,
          workspace_id: Number(workspaceID),
        })
      : generatePath(routes.PARTNERS_QUESTIONNAIRE_REVIEW_PATH, {
          questionnaireId: questionnaire.id,
          workspace_id: Number(workspaceID),
        });
    history.push(redirectionPath);
  };

  const onReview = async (questionnaire: CWQuestionnaireRead) => {
    if (questionnaire.status === "Submitted") {
      await QuestionnaireAPI.setInReview(questionnaire.id);
    }

    const redirectionPath = isInternalUser
      ? generatePath(routes.QUESTIONNAIRE_REVIEW_PATH, {
          questionnaireId: questionnaire.id,
          workspace_id: Number(workspaceID),
        })
      : generatePath(routes.PARTNERS_QUESTIONNAIRE_REVIEW_PATH, {
          questionnaireId: questionnaire.id,
          workspace_id: Number(workspaceID),
        });
    history.push(redirectionPath);
  };

  const viewPartnerReviewPage = (questionnaire: CWQuestionnaireRead) => {
    history.push(
      generatePath(routes.CUSTOMER_QUESTIONNAIRE_DETAIL_REVIEW_PATH, {
        questionnaireId: questionnaire.id,
        workspace_id: Number(workspaceID),
      }),
    );
  };

  const isDueDateInPast = (questionnaire: CWQuestionnaireRead): boolean => {
    const { due_date, status } = questionnaire;

    if (!due_date || !["New", "In progress"].includes(status)) {
      return false;
    }

    return isPast(parseISO(due_date.toString()));
  };

  useEffect(() => {
    loadTemplates(workspaceID);
  }, [workspaceID]);

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader={false} />;
  }

  return (
    <DashboardContent>
      <DashboardHeader title="Questionnaires" />
      <DashboardContentWrapper>
        <ModuleHeader>
          <div className="risk-assessment-tabs">
            <ModuleTabs>
              <NavLink
                exact
                to={`/workspaces/${workspaceID}/modules/risk-assessment/dashboard`}
              >
                Questionnaires
              </NavLink>
              <NavLink
                exact
                to={`/workspaces/${workspaceID}/modules/risk-assessment/documents`}
              >
                Documents
              </NavLink>
            </ModuleTabs>
          </div>
        </ModuleHeader>
        <ModuleContentWrapper className="questionnaire-templates-table-wrapper">
          {list.length === 0 ? (
            <EmptyMessage>No questionnaire available.</EmptyMessage>
          ) : (
            <Table
              header={
                <>
                  <TableHeaderCell
                    title="Status"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="book1" />
                    }
                    firstDataHeader
                  />
                  <TableHeaderCell
                    title="Name"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="book1" />
                    }
                  />
                  <TableHeaderCell
                    title="Due Date"
                    leftIcon={
                      <Icon name="calendar" color="extrasBlueGrayDarker" />
                    }
                  />
                  <TableHeaderCell
                    title="Percentage Answered"
                    leftIcon={
                      <Icon color="extrasBlueGrayDarker" name="number" />
                    }
                    lastDataHeader
                  />
                  <TableCellSpacer />
                </>
              }
            >
              {list.map((item, rowIndex) => (
                <TableRow key={item.id} rowIndex={rowIndex} onClick={() => {}}>
                  <ContentCell content={item.status} isFirstDataCell />
                  <ContentCell content={item.name} />
                  <ContentCell
                    content={
                      <Typography
                        weight="semiBold"
                        label={
                          item.due_date
                            ? format(
                                parseISO(item.due_date.toString()),
                                "MM/dd/yyyy",
                              )
                            : "N/A"
                        }
                        color={
                          isDueDateInPast(item)
                            ? "generalError"
                            : "extrasBlueGrayDarker"
                        }
                      />
                    }
                    hasErrors={isDueDateInPast(item)}
                  />
                  <ContentCell
                    content={formatPercentage(item.completion_percentage)}
                  />
                  <ActionCell>
                    <RowOptions options={generateOptions(item)} />
                  </ActionCell>
                </TableRow>
              ))}
            </Table>
          )}
        </ModuleContentWrapper>
      </DashboardContentWrapper>
      <QuestionnaireDueDateModal
        onClose={(date) => {
          setNewDueDate(date);
          setQuestionnaireToUpdate(null);
        }}
        questionnaire={questionnaireToUpdate}
      />
    </DashboardContent>
  );
}

export default observer(PartnerQuestionnairePage);
