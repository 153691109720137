import { http } from "msw";

import { createCreateAccountContactMutationResponse } from "../createCreateAccountContact";

export const createAccountContactHandler = http.post(
  "*/accounts/:account_id/contacts",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateAccountContactMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
