import { faker } from "@faker-js/faker";

import type {
  ListThemisCustomAuditsForThemisRecord200,
  ListThemisCustomAuditsForThemisRecordPathParams,
  ListThemisCustomAuditsForThemisRecordQueryParams,
  ListThemisCustomAuditsForThemisRecordQueryResponse,
} from "../models/ListThemisCustomAuditsForThemisRecord";
import { createAudit } from "./createAudit";
import { createPaginatedMeta } from "./createPaginatedMeta";
import { createRecordType } from "./createRecordType";

export function createListThemisCustomAuditsForThemisRecordPathParams(): NonNullable<ListThemisCustomAuditsForThemisRecordPathParams> {
  return { record_type: createRecordType(), record_id: faker.number.int() };
}

export function createListThemisCustomAuditsForThemisRecordQueryParams(): NonNullable<ListThemisCustomAuditsForThemisRecordQueryParams> {
  return {
    action_name: faker.string.alpha(),
    offset: faker.number.int(),
    limit: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createListThemisCustomAuditsForThemisRecord200(): NonNullable<ListThemisCustomAuditsForThemisRecord200> {
  return {
    data: faker.helpers.arrayElements([createAudit()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createListThemisCustomAuditsForThemisRecordQueryResponse(): NonNullable<ListThemisCustomAuditsForThemisRecordQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createAudit()]) as any,
    meta: createPaginatedMeta(),
  };
}
