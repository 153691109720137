import { http } from "msw";

import { createUpdateDepartmentMutationResponse } from "../createUpdateDepartment";

export const updateDepartmentHandler = http.put(
  "*/departments/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateDepartmentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
