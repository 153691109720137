import { faker } from "@faker-js/faker";

import type {
  ApiAuthGet200,
  ApiAuthGetQueryParams,
  ApiAuthGetQueryResponse,
} from "../models/ApiAuthGet";
import { createApiAuth } from "./createApiAuth";

export function createApiAuthGetQueryParams(): NonNullable<ApiAuthGetQueryParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createApiAuthGet200(): NonNullable<ApiAuthGet200> {
  return { data: faker.helpers.arrayElements([createApiAuth()]) as any };
}

/**
 * @description OK
 */
export function createApiAuthGetQueryResponse(): NonNullable<ApiAuthGetQueryResponse> {
  return { data: faker.helpers.arrayElements([createApiAuth()]) as any };
}
