import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateTrainingMutationRequest,
  UpdateTrainingMutationResponse,
  UpdateTrainingPathParams,
} from "../../models/UpdateTraining";

/**
 * @description Update training
 * @link /trainings/:id
 */
export async function updateTraining(
  id: UpdateTrainingPathParams["id"],
  data: UpdateTrainingMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateTrainingMutationResponse>["data"]> {
  const res = await client<
    UpdateTrainingMutationResponse,
    UpdateTrainingMutationRequest
  >({
    method: "put",
    url: `/trainings/${id}`,
    data,
    ...options,
  });
  return res.data;
}
