import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { ResponseConfig } from "@/api/client";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import type {
  DeleteCommentMutationResponse,
  DeleteCommentPathParams,
} from "../../gen";
import { deleteComment } from "../../gen/axios/commentsController";
import { getRecordsQueryKey } from "../records/getRecordsQueryKey";
import { getCommentsQueryKey } from "./useComments";

interface UseDeleteCommentParams {
  recordType: DeleteCommentPathParams["record_type"];
  recordId: DeleteCommentPathParams["record_id"];
}

export function useDeleteComment<TError = Error, TContext = unknown>(
  { recordType, recordId }: UseDeleteCommentParams,
  {
    onSuccess,
    ...options
  }: Partial<
    UseMutationOptions<
      ResponseConfig<DeleteCommentMutationResponse>["data"],
      TError,
      DeleteCommentPathParams["id"],
      TContext
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const workspaceId = useRouteWorkspaceId();

  return useMutation({
    ...options,
    mutationFn: (commentId) => deleteComment(recordType, recordId, commentId),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({ recordType, workspaceId }),
      });
      queryClient.invalidateQueries({
        queryKey: getCommentsQueryKey({ recordType, recordId }),
      });
      onSuccess?.(...args);
    },
  });
}
