import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  PolicyExportPdfPathParams,
  PolicyExportPdfQueryParams,
  PolicyExportPdfQueryResponse,
} from "../../models/PolicyExportPdf";

/**
 * @description Export policy in PDF
 * @summary Export policy in PDF
 * @link /policies/:id/export
 */
export async function policyExportPdf(
  id: PolicyExportPdfPathParams["id"],
  params?: PolicyExportPdfQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<PolicyExportPdfQueryResponse>["data"]> {
  const res = await client<PolicyExportPdfQueryResponse>({
    method: "get",
    url: `/policies/${id}/export`,
    params,
    ...options,
  });
  return res.data;
}
