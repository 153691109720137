import { faker } from "@faker-js/faker";

import type { FavoriteModuleWorkspace } from "../models/FavoriteModuleWorkspace";

export function createFavoriteModuleWorkspace(
  data: NonNullable<Partial<FavoriteModuleWorkspace>> = {},
): NonNullable<FavoriteModuleWorkspace> {
  return {
    ...{
      id: faker.number.int(),
      module_workspace_id: faker.number.int(),
      workspace_id: faker.number.int(),
      created_at: faker.date.anytime().toISOString(),
    },
    ...data,
  };
}
