import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { reportsService } from "@/api";
import type { CountByDateMutationRequest } from "@/api/gen/models/CountByDate";

export const getReportCountByDateQueryKey = (
  params: CountByDateMutationRequest,
): QueryKey => [
  params.module_workspace_id,
  "reportsService",
  "countByDate",
  params.section_tag_id,
  params.field_name,
  params.number_of_days,
];

async function countByDate(params: CountByDateMutationRequest) {
  const results = await Promise.all([
    reportsService.countByDate(params),
    reportsService.countByDate({ ...params, number_of_days: 0 }),
  ]);

  return {
    at_risk_count: results[0],
    overdue_count: results[1],
  };
}

export const useReportCountByDate = (
  params: CountByDateMutationRequest,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery({
    queryFn: () => countByDate(params),
    queryKey: getReportCountByDateQueryKey(params),
    enabled: !!options?.enabled,
  });
