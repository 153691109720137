import { http } from "msw";

import { createCreateAttestationListMutationResponse } from "../createCreateAttestationList";

export const createAttestationListHandler = http.post(
  "*/companies/:company_id/attestation_lists",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateAttestationListMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
