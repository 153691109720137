import { MiniTag } from "@themis/ui";
import React from "react";

import type { FieldOption } from "@/api/gen/models";

interface AccountTypeTagsListProps {
  types: FieldOption[];
  isInTooltip?: boolean;
  maxShown?: number;
}

export function AccountTypeTagsList({
  types,
  isInTooltip,
  maxShown,
}: AccountTypeTagsListProps) {
  const shownOptions = types.slice(0, maxShown);

  return shownOptions.map((selectedOption, index: number) => (
    <MiniTag
      key={selectedOption.key}
      size="lg"
      colorIndex={index}
      className={isInTooltip ? "" : "tw-max-w-32"}
    >
      <div
        className={isInTooltip ? "" : "tw-truncate"}
        title={selectedOption.value}
      >
        {selectedOption.value}
      </div>
    </MiniTag>
  ));
}
