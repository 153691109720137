import { useIntl } from "react-intl";

import type { CompanyInfo } from "@/api/legacy/clearbit/types";

interface ClearbitSuggestionProps extends CompanyInfo {
  onSelect: (suggestion: CompanyInfo) => void;
}

export function ClearbitSuggestion({
  domain,
  logo,
  name,
  onSelect,
}: ClearbitSuggestionProps) {
  const { formatMessage } = useIntl();

  const handleClick = () => {
    onSelect({ domain, logo, name });
  };

  return (
    <div
      className="tw-flex tw-cursor-pointer tw-items-center tw-gap-x-2"
      onClick={handleClick}
    >
      <img
        className="tw-h-6 tw-w-6"
        src={logo}
        alt={formatMessage({ defaultMessage: "{name} logo" }, { name })}
      />
      <p className="tw-break-all tw-text-neutral-500">{name}</p>
      <span className="tw-ml-auto tw-text-neutral-300">{domain}</span>
    </div>
  );
}
