import "./partners.scss";

import { uniqBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { getRepositionPayloadFromIndices } from "@/components/helpers/reordering";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import NewSectionTag from "@/components/table/shared/NewSectionTag";
import SectionDragContext from "@/components/table/shared/SectionDragContext";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import { PartnersAPI } from "../../../../api/legacy/risk-assessment/PartnersApi";
import { routes } from "../../routes";
import PartnerSection from "./PartnerSection";

function Partners() {
  const mainStore = useMainStore();
  const [partnerList, setPartnerList] = useState([]);
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const { list: partners } = mainStore.recordVersions;
  const { tableName, moduleWorkspaceID, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const history = useHistory();

  const addNewPartner = () =>
    history.push(
      generatePath(`${routes.PARTNERS_DETAILS_NEW_PATH}`, {
        workspace_id: workspaceID as number,
      }),
    );
  const onSectionChange = async (
    recordVersionId: number,
    sectionId: number,
  ) => {
    try {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      await PartnersAPI.movePartner(workspaceID, sectionId, recordVersionId);
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      const partner = partnerList.find((rv) => rv.id === recordVersionId);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      partner.section_tag_id = sectionId;
      setPartnerList([...partnerList]);
    } catch {
      mainStore.toast.setErrorText("Something went wrong. Please, try again.");
    }
  };

  const onRecordMoved = async (
    recordVersionId: number,
    sectionId: number,
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const relevantRecordVersions = partnerList.filter(
      // @ts-expect-error TS(2339) FIXME: Property 'section_tag_id' does not exist on type '... Remove this comment to see the full error message
      (partner) => partner.section_tag_id === sectionId,
    );
    await mainStore.recordVersions.reorder(
      recordVersionId,
      getRepositionPayloadFromIndices(
        relevantRecordVersions,
        sourceIndex,
        destinationIndex,
      ),
    );
  };

  // @ts-expect-error TS(2345) FIXME: Argument of type 'RecordVersion<string>[]' is not ... Remove this comment to see the full error message
  useEffect(() => setPartnerList(partners), [partners]);

  return (
    <>
      <AddRecordHeader
        onAddRecord={addNewPartner}
        recordName="Partner"
        onAddSection={() => setShowAddNewSection(true)}
        canAddSection
      />
      <div className="partners-table-section" data-testid="section-page">
        <div className="table-list-wrap no-margin table-list-wrap-wider policy-table">
          <SectionDragContext
            onSectionChange={onSectionChange}
            onRecordMoved={onRecordMoved}
          >
            <PartnerSection
              // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
              tableName={tableName}
              recordVersions={partnerList.filter(
                // @ts-expect-error TS(2339) FIXME: Property 'section_tag_id' does not exist on type '... Remove this comment to see the full error message
                (recordVersion) => !recordVersion.section_tag_id,
              )}
              // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              moduleWorkspaceID={moduleWorkspaceID}
            />

            {uniqBy(mainStore.sectionTags.orderedList, (item) => item.id).map(
              (sectionTag) => {
                const filteredRecordVersions = partnerList.filter(
                  (recordVersion) =>
                    // @ts-expect-error TS(2339) FIXME: Property 'section_tag_id' does not exist on type '... Remove this comment to see the full error message
                    recordVersion.section_tag_id === sectionTag.id,
                );
                return (
                  <PartnerSection
                    key={sectionTag.id}
                    // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                    tableName={tableName}
                    recordVersions={filteredRecordVersions}
                    // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                    moduleWorkspaceID={moduleWorkspaceID}
                    sectionTag={sectionTag}
                  />
                );
              },
            )}
          </SectionDragContext>
          {showAddNewSection && (
            <NewSectionTag
              // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              moduleWorkspaceID={moduleWorkspaceID}
              takeAfterAction={() => setShowAddNewSection(false)}
            />
          )}

          {canManageSections && (
            <div
              className="tw-sticky tw-left-[25px] tw-ml-[25px] tw-mt-8"
              data-testid="add-new-section-button"
            >
              <AddNewSectionButton
                onClick={() => setShowAddNewSection(true)}
                title="Add New Section"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default observer(Partners);
