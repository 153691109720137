import {
  IconButton,
  LinkButton,
  ToggleButtonGroup,
  ToggleButtonGroupItem,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import classNames from "classnames";
import React from "react";
import {
  PiDotsNineBold,
  PiInfoBold,
  PiListBulletsBold,
  PiPlusBold,
} from "react-icons/pi";
import { useIntl } from "react-intl";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import type { Account } from "@/api";
import { useFields } from "@/api/queries/fields/use-fields";
import { ColumnsSelect } from "@/components/columns/columns-select/columns-select";
import { FilterSelect } from "@/components/FilterSelect/FilterSelect";
import { getFiltersFieldData } from "@/components/FilterSelect/utils/get-filters-field-data";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import ExportButton from "@/features/misc/ExportButton";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import ImportButton from "@/features/misc/ImportButton";
import { useCompanyId } from "@/hooks/use-company-id";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { useSearchParams } from "@/hooks/useSearchParams";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { ACCOUNT_ROUTES } from "../accounts-routes";
import { AccountList } from "../components/AccountsList";
import AccountsHeader from "./AccountsHeader";

export interface AccountSearchParams
  extends Record<string, string | undefined> {
  view?: AccountListView;
}

export type AccountListView = "grid" | "table";

function AccountsContainer({ children }: { children: React.ReactNode }) {
  const [{ view }] = useSearchParams<AccountSearchParams>();

  return (
    <div
      className={classNames(
        "tw-flex tw-min-w-96 tw-flex-col tw-gap-4 tw-overflow-auto",
        {
          "tw-px-16": view === "grid",
        },
      )}
    >
      {children}
    </div>
  );
}

function ActionsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-generals-underline tw-bg-white tw-px-6 tw-py-2">
      {children}
    </div>
  );
}

function TitleContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-items-center tw-gap-1">{children}</div>;
}

export function Accounts() {
  const { formatMessage } = useIntl();
  const mainStore = useMainStore();
  const { companyId } = useCompanyId();
  const recordType = "accounts";
  const { url } = useRouteMatch();
  const params = useParams<{ workspace_id: string }>();
  const workspaceId = Number(params.workspace_id);

  const [searchParams, setSearchParams] =
    useSearchParams<AccountSearchParams>();

  const { data: columns } = useFields({
    companyId,
    recordType: "accounts",
  });

  const accountsListView = searchParams.view || "table";

  const filtersFieldData = getFiltersFieldData<Account>(columns);

  const isCustomColumnsEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_CUSTOM_COLUMNS,
  );

  function handleViewChange(newView: AccountListView) {
    setSearchParams({ ...searchParams, view: newView }, true);
  }

  return (
    <PageLayout>
      <Header
        title={
          <TitleContainer>
            {formatMessage({
              defaultMessage: "Accounts",
            })}
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton Icon={PiInfoBold} color="transparent" size="sm" />
              </TooltipTrigger>
              <TooltipContent className="tw-box-border tw-inline-flex tw-w-72 tw-flex-col tw-items-start tw-gap-1 tw-px-6 tw-py-4">
                <h3 className="tw-text-base tw-font-semibold">
                  {formatMessage({
                    defaultMessage: "Accounts",
                  })}
                </h3>
                <p className="tw-font-medium">
                  {formatMessage({
                    defaultMessage:
                      "Accounts are business entities, Vendor or Partners used in modules like Vendor Due Diligence and Questionnaires.",
                  })}
                </p>
              </TooltipContent>
            </Tooltip>
          </TitleContainer>
        }
        HeaderOptions={
          <>
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === recordType,
                )?.id
              }
            />
            <ExportButton recordType={recordType} />
            <ImportButton recordType={recordType} />
          </>
        }
      />
      <AccountsHeader />
      <ActionsContainer>
        {filtersFieldData && (
          <FilterSelect filtersFieldData={filtersFieldData} />
        )}
        <ToggleButtonGroup
          size="lg"
          type="single"
          variant="transparent"
          value={accountsListView}
          onValueChange={handleViewChange}
        >
          <ToggleButtonGroupItem
            aria-label={formatMessage({ defaultMessage: "Table View" })}
            value="table"
            className="tw-px-3"
          >
            <PiListBulletsBold className="tw-h-4 tw-w-4" />
            {formatMessage({ defaultMessage: "List" })}
          </ToggleButtonGroupItem>
          <ToggleButtonGroupItem
            aria-label={formatMessage({ defaultMessage: "Grid View" })}
            value="grid"
            className="tw-px-3"
          >
            <PiDotsNineBold className="tw-h-4 tw-w-4" />
            {formatMessage({ defaultMessage: "Grid" })}
          </ToggleButtonGroupItem>
        </ToggleButtonGroup>
        {isCustomColumnsEnabled && companyId && (
          <ColumnsSelect
            companyId={companyId}
            recordType="accounts"
            workspaceId={workspaceId}
          />
        )}
        <LinkButton
          LeftIcon={PiPlusBold}
          to={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
            accountId: "new",
          })}
        >
          {formatMessage({ defaultMessage: "Add Account" })}
        </LinkButton>
      </ActionsContainer>
      <PageContent>
        <AccountsContainer>
          <AccountList filtersFieldData={filtersFieldData} />
        </AccountsContainer>
      </PageContent>
    </PageLayout>
  );
}
