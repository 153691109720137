import { mapKeys } from "lodash";
import { useMemo } from "react";

import type { FieldOption } from "../api/gen/models";
import { useCompany } from "../api/queries/companies";
import { useFields } from "../api/queries/fields/use-fields";
import { sortByText } from "../utils/sorts";

export function useAccountTypeFieldOptions() {
  const { data: companyResult } = useCompany("current");

  const { data: columns = [] } = useFields({
    companyId: companyResult?.data?.company?.id,
    recordType: "accounts",
  });

  const accountsTypeField = useMemo(
    () => columns?.find(({ name }) => name === "account_types"),
    [columns],
  );

  const options = useMemo(() => {
    const _options = accountsTypeField?.options || [];

    return sortByText(_options, (option: FieldOption) => option.value, "asc");
  }, [accountsTypeField]);

  const optionsMap = useMemo(
    () => mapKeys(options, (option: FieldOption) => option.value),
    [options],
  );

  return {
    accountsTypeField,
    options,
    optionsMap,
  };
}
