import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import type { TableName } from "@/api";
import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import CreateFirstCTA from "@/components/table/shared/CreateFirstCTA";
import HeadSelect from "@/components/table/shared/HeadSelect";
import NewSectionTag from "@/components/table/shared/NewSectionTag";
import SectionDragContext from "@/components/table/shared/SectionDragContext";
import Table from "@/components/table/Table";
import { useMainStore } from "@/contexts/Store";
import KeyRiskIndicatorsSection from "@/features/key-risk-indicators/components/KeyRiskIndicatorsSection";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import useDragAndDropRecords from "@/hooks/useDragAndDropRecords";
import { useLoading } from "@/hooks/useLoading";
import { useTableData } from "@/hooks/useTableData";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import KRITabsHeader from "../components/KRITabsHeader";

const KRIPage = ({ moduleName }: { moduleName: string }) => {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  // Variables
  const { visibleFields } = mainStore.fields;
  const { activeWorkspace, tableName } = mainStore.context;
  const recordVersions = mainStore.recordVersions.list?.filter(
    (recordVersion) => recordVersion.table_name === tableName,
  );
  const { data } = mainStore.keyRiskIndicators;
  const moduleWorkspaceID = data?.module_workspace_id;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { canManageSections } = mainStore.userPermissions;

  // Hooks
  const loading = useLoading(visibleFields);
  useTableData();

  // Effects
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    mainStore.keyRiskIndicators.index({
      workspaceID: activeWorkspace?.id,
    });
  }, []);

  function addNewSection() {
    setShowAddNewSection(!showAddNewSection);
  }

  async function createKRI() {
    if (tableName) {
      await mainStore.keyRiskIndicators.create({
        sectionTagID: undefined,
      });
      await mainStore.keyRiskIndicators.index({
        workspaceID: activeWorkspace?.id,
      });
    }
  }

  const { onSectionChange, onRecordMoved } = useDragAndDropRecords({
    themisIdentifier: TopLevelModule.KEY_RISK_INDICATORS,
    mainStore,
  });

  return (
    <DashboardContent>
      <DashboardHeader
        title={moduleName}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper>
        <KRITabsHeader />
        {!loading && (
          <AddRecordHeader
            onAddRecord={createKRI}
            recordName="Key Risk Indicator"
            canAddSection
            onAddSection={addNewSection}
          />
        )}

        <Table extraClassNames="kri">
          {!loading && (
            <div
              className="table-list-wrap kri-list"
              data-testid="kri-table-list"
            >
              <SectionDragContext
                onSectionChange={onSectionChange}
                onRecordMoved={onRecordMoved}
              >
                <KeyRiskIndicatorsSection
                  isDropdownOpen
                  isDraggable
                  tableName={tableName as TableName}
                  fields={visibleFields}
                  recordVersions={recordVersions.filter(
                    (recordVersion) => !recordVersion.section_tag_id,
                  )}
                  moduleWorkspaceID={moduleWorkspaceID}
                  showCheckbox={showCheckbox}
                  setShowCheckbox={setShowCheckbox}
                />
                {sectionTags.map((sectionTag) => {
                  const filteredRecordVersions = recordVersions.filter(
                    (recordVersion) =>
                      recordVersion.section_tag_id === sectionTag.id,
                  );
                  return (
                    <KeyRiskIndicatorsSection
                      isDraggable
                      isDropdownOpen
                      key={sectionTag.id}
                      tableName={tableName as TableName}
                      fields={visibleFields}
                      recordVersions={filteredRecordVersions}
                      moduleWorkspaceID={moduleWorkspaceID}
                      sectionTag={sectionTag}
                      showCheckbox={showCheckbox}
                      setShowCheckbox={setShowCheckbox}
                    />
                  );
                })}

                {showAddNewSection && (
                  <NewSectionTag
                    moduleWorkspaceID={moduleWorkspaceID}
                    onCancel={() => setShowAddNewSection(false)}
                  />
                )}
                {canManageSections && (
                  <div className="tw-sticky tw-left-[25px] tw-ml-[25px]">
                    <AddNewSectionButton onClick={addNewSection} />
                  </div>
                )}
                {recordVersions.length === 0 && (
                  <CreateFirstCTA subject="Key Risk Indicators" />
                )}
              </SectionDragContext>
            </div>
          )}
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(KRIPage);
