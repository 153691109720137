import React from "react";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import Table from "@/components/table/Table";

import KRISummary from "../components/kri-summary/KRISummary";
import KRITabsHeader from "../components/KRITabsHeader";

const KRISummaryPage = ({ moduleName }: { moduleName: string }) => (
  <DashboardContent>
    <DashboardHeader
      title={moduleName}
      LeftActionBar={<ViewModuleUsers />}
      RightActionBar={<HeadSelect />}
    />
    <DashboardContentWrapper>
      <KRITabsHeader />
      <Table extraClassNames="kri">
        <div className="table-list-wrap">
          <KRISummary />
        </div>
      </Table>
    </DashboardContentWrapper>
  </DashboardContent>
);

export default KRISummaryPage;
