import { faker } from "@faker-js/faker";

import type {
  CreateTaskSection201,
  CreateTaskSectionMutationRequest,
  CreateTaskSectionMutationResponse,
  CreateTaskSectionPathParams,
} from "../models/CreateTaskSection";
import { createTaskSection } from "./createTaskSection";

export function createCreateTaskSectionPathParams(): NonNullable<CreateTaskSectionPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description Created
 */
export function createCreateTaskSection201(): NonNullable<CreateTaskSection201> {
  return { data: createTaskSection() };
}

export function createCreateTaskSectionMutationRequest(): NonNullable<CreateTaskSectionMutationRequest> {
  return { task_section: createTaskSection() };
}

/**
 * @description Created
 */
export function createCreateTaskSectionMutationResponse(): NonNullable<CreateTaskSectionMutationResponse> {
  return { data: createTaskSection() };
}
