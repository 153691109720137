import { faker } from "@faker-js/faker";

import type { ApprovalStatus } from "../models/ApprovalStatus";

export function createApprovalStatus(
  data: NonNullable<Partial<ApprovalStatus>> = {},
): NonNullable<ApprovalStatus> {
  return {
    ...{
      id: faker.number.int(),
      approval_step_id: faker.number.int(),
      completed: faker.datatype.boolean(),
    },
    ...data,
  };
}
