import { http } from "msw";

import { createCountriesQueryResponse } from "../createCountries";

export const countriesHandler = http.get("*/countries", function handler(info) {
  return new Response(JSON.stringify(createCountriesQueryResponse()), {
    headers: {
      "Content-Type": "application/json",
    },
  });
});
