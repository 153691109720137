import React from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import PolicyActionBar from "@/components/table/policy/PolicyActionBar";
import LegacyAuditTrail from "@/components/table/shared/LegacyAuditTrail";
import Table from "@/components/table/Table";

interface PoliciesAuditTrailPageProps {
  isRecordView: boolean;
  title: string;
}

const PoliciesAuditTrailPage = ({
  isRecordView,
  title,
}: PoliciesAuditTrailPageProps) => {
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader
        title={title}
        onBackClick={() => history.goBack()}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<PolicyActionBar />}
      />
      <DashboardContentWrapper>
        <Table>
          <LegacyAuditTrail isRecordView={isRecordView} />
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default PoliciesAuditTrailPage;
