import type { ColDef } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import type { Document } from "@/api";
import { DATE_FORMAT } from "@/constants";

import { ActionsCell } from "./actions-cell/actions-cell";

export const useDocumentsTableColumns = (): ColDef<Document>[] => {
  const { formatMessage } = useIntl();

  return useMemo(
    (): ColDef<Document>[] => [
      {
        sortable: false,
        field: "file.file_name",
        headerName: formatMessage({ defaultMessage: "Name" }),
        minWidth: 180,
        flex: 1,
        cellRenderer: "textRenderCell",
      },
      {
        sortable: false,
        field: "upload_date",
        headerName: formatMessage({ defaultMessage: "Upload Date" }),
        minWidth: 120,
        flex: 1,
        resizable: false,
        valueFormatter: (params: { value: string }) =>
          dayjs(params.value).format(DATE_FORMAT),
        cellRenderer: "textRenderCell",
      },
      {
        headerName: "",
        width: 40,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        pinned: "right" as const,
        cellStyle: () => ({ justifyContent: "center" }),
        cellRenderer: (props: CustomCellRendererProps) => (
          <ActionsCell
            documentFile={props.data.file}
            documentId={props.data.id}
          />
        ),
      },
    ],
    [formatMessage],
  );
};
