import React, { forwardRef, useState } from "react";
import { LuFilePlus2 } from "react-icons/lu";
import { cn } from "../../lib/utils";
var ACCEPTED_FORMATS = "image/jpeg, .jpeg, image/png, .png, image/gif, .gif, image/bmp, .bmp, image/webp, .webp, image/svg+xml, .svg";
var defaultStyles = "tw-flex tw-cursor-pointer tw-justify-center tw-items-center tw-w-full tw-box-border tw-h-32 tw-p-7 tw-rounded-md tw-border tw-border-dashed tw-border-neutral-100 tw-bg-transparent tw-transition-colors";
var fontStyles = "tw-text-xs tw-font-semibold tw-font-sans";
var textStyles = "tw-flex tw-max-w-60 tw-text-center tw-text-primaryDim-300";
var focusStyles = "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-solid focus-visible:tw-border-primary-300";
var lockedStyles = "disabled:tw-bg-primary-25 disabled:tw-cursor-default disabled:tw-border-solid";
var readOnlyStyles = "disabled:tw-bg-primaryDim-25 disabled:tw-cursor-default disabled:tw-border-solid";
var imageSelectedStyles = "!tw-border-solid";
var draggingStyles = "tw-rounded-md !tw-border-solid tw-border-secondary-300 !tw-bg-secondary-50 tw-text-neutral-500";
var ImagePicker = forwardRef(function (_a, ref) {
    var _b;
    var url = _a.url, defaultUrl = _a.defaultUrl, locked = _a.locked, readOnly = _a.readOnly, _c = _a.onSelectFile, onSelectFile = _c === void 0 ? function () { } : _c, _d = _a.className, className = _d === void 0 ? "" : _d, _e = _a.placeholder, placeholder = _e === void 0 ? "Upload here" : _e, _f = _a.placeholderSubtext, placeholderSubtext = _f === void 0 ? "" : _f;
    var inputRef = React.useRef(null);
    var _g = useState(false), dragging = _g[0], setDragging = _g[1];
    var _h = useState(defaultUrl || ""), internalValue = _h[0], setInternalValue = _h[1];
    var handleFileSelect = function (file) {
        onSelectFile(file);
        setInternalValue(URL.createObjectURL(file));
    };
    var handleFileDrop = function (event) {
        event.preventDefault();
        setDragging(false);
        if (!event.dataTransfer.files.length) {
            return;
        }
        if (!ACCEPTED_FORMATS.includes(event.dataTransfer.files[0].type)) {
            return;
        }
        handleFileSelect(event.dataTransfer.files[0]);
    };
    var handleDragOver = function (event) {
        event.preventDefault();
        setDragging(true);
    };
    var handleInputChange = function (event) {
        var files = event.target.files;
        if (!(files === null || files === void 0 ? void 0 : files.length)) {
            return;
        }
        handleFileSelect(files[0]);
    };
    var handleButtonClick = function () {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var renderImage = function () { return (React.createElement("img", { src: url || internalValue, alt: "logo-image", className: "tw-h-full tw-w-full tw-object-contain" })); };
    var renderPlaceholder = function () {
        if (dragging) {
            return (React.createElement("div", { className: "tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2" },
                React.createElement(LuFilePlus2, { className: "tw-h-6 tw-w-6 tw-text-neutral-500" }),
                React.createElement("p", { className: cn(textStyles, "!tw-text-neutral-500") }, "Drop file here")));
        }
        return (React.createElement("p", { className: cn(textStyles) },
            placeholder,
            React.createElement("br", null),
            placeholderSubtext));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: cn(defaultStyles, focusStyles, fontStyles, (_b = {},
                _b[lockedStyles] = locked,
                _b[readOnlyStyles] = readOnly,
                _b[imageSelectedStyles] = url || internalValue,
                _b[draggingStyles] = dragging,
                _b), className), onClick: handleButtonClick, onDrop: handleFileDrop, onDragOver: handleDragOver, onDragExit: function () { return setDragging(false); }, disabled: locked || readOnly, ref: ref }, url || internalValue ? renderImage() : renderPlaceholder()),
        React.createElement("input", { placeholder: placeholder, ref: inputRef, type: "file", onChange: handleInputChange, className: cn("tw-hidden", className), accept: ACCEPTED_FORMATS })));
});
ImagePicker.displayName = "ImagePicker";
export { ImagePicker };
