import { faker } from "@faker-js/faker";

import type { Layout } from "../models/Layout";
import { createLayoutField } from "./createLayoutField";

export function createLayout(
  data: NonNullable<Partial<Layout>> = {},
): NonNullable<Layout> {
  return {
    ...{
      id: faker.number.int(),
      workspace_id: faker.number.int(),
      layout_type: faker.helpers.arrayElement<any>(["table", "detail"]),
      name: faker.string.alpha(),
      system: faker.datatype.boolean(),
      record_type: faker.string.alpha(),
      layout_fields: faker.helpers.arrayElements([createLayoutField()]) as any,
    },
    ...data,
  };
}
