var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cva } from "cva";
import React from "react";
var stackVariants = cva({
    base: ["tw-flex"],
    variants: {
        direction: {
            row: "tw-flex-row",
            column: "tw-flex-col",
        },
        spacing: {
            "0": "tw-gap-0",
            "0.5": "tw-gap-1",
            "1": "tw-gap-2",
            "2": "tw-gap-4",
            "3": "tw-gap-6",
            "4": "tw-gap-8",
            "5": "tw-gap-10",
            "6": "tw-gap-12",
        },
        wrap: {
            true: "tw-flex-wrap",
            false: "tw-flex-nowrap",
        },
    },
    defaultVariants: {
        direction: "column",
        spacing: "1",
        wrap: false,
    },
});
export function Stack(_a) {
    var className = _a.className, children = _a.children, _b = _a.direction, direction = _b === void 0 ? "column" : _b, _c = _a.spacing, spacing = _c === void 0 ? "1" : _c, wrap = _a.wrap, rest = __rest(_a, ["className", "children", "direction", "spacing", "wrap"]);
    return (React.createElement("div", __assign({ className: stackVariants({ direction: direction, spacing: spacing, className: className, wrap: wrap }) }, rest), children));
}
