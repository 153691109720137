import { http } from "msw";

import { createListFieldOptionsQueryResponse } from "../createListFieldOptions";

export const listFieldOptionsHandler = http.get(
  "*/fields/:field_id/options",
  function handler(info) {
    return new Response(JSON.stringify(createListFieldOptionsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
