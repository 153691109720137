import { http } from "msw";

import { createUpdateSectionMutationResponse } from "../createUpdateSection";

export const updateSectionHandler = http.put(
  "*/sections/:id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateSectionMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
