import { http } from "msw";

import { createDeleteDepartmentMutationResponse } from "../createDeleteDepartment";

export const deleteDepartmentHandler = http.delete(
  "*/departments/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteDepartmentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
