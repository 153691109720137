import { http } from "msw";

import { createGetFollowupQuestionsQueryResponse } from "../createGetFollowupQuestions";

export const getFollowupQuestionsHandler = http.get(
  "*/followup_questions",
  function handler(info) {
    return new Response(
      JSON.stringify(createGetFollowupQuestionsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
