import React from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import PolicyActionBar from "@/components/table/policy/PolicyActionBar";
import HistoricalVersions from "@/components/table/shared/views/HistoricalVersions";
import Table from "@/components/table/Table";

import { useMainStore } from "../../../contexts/Store";

const PoliciesHistoricalVersionsPage = () => {
  const mainStore = useMainStore();
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader
        title="View Previous Versions"
        onBackClick={() => history.goBack()}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<PolicyActionBar />}
      />
      <DashboardContentWrapper>
        <Table>
          <HistoricalVersions moduleStore={mainStore.policies} />
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default PoliciesHistoricalVersionsPage;
