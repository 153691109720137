import { LinkButton } from "@themis/ui";
import { generatePath, useRouteMatch } from "react-router-dom";

import { websiteMonitoringRoutes } from "../routes";

export function ViewButton({
  monitoringGroupAssetId,
}: {
  monitoringGroupAssetId: number;
}) {
  const { url } = useRouteMatch();

  return (
    <LinkButton
      to={generatePath(
        `${url}${websiteMonitoringRoutes.monitoringGroupAssetChildren}`,
        {
          monitoringGroupAssetId,
        },
      )}
      size="lg"
      color="tertiary"
    >
      View
    </LinkButton>
  );
}
