import { z } from "zod";

import type { FieldConfiguration } from "@/api";

export const getUserSchema = (
  field: FieldConfiguration,
  requiredMessage: string,
) => {
  const baseSchema = z.coerce.number().positive(requiredMessage);
  const schema = field.multiselect ? z.array(baseSchema) : baseSchema;

  if (!field.required) {
    return schema.optional();
  }

  return schema;
};
