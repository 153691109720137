import type { ColDef, ColDefField } from "@ag-grid-community/core";

import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";
import { ColumnHeaderIcon } from "@/components/columns/column-header-icon/column-header-icon";
import type { DynamicCellProps } from "@/components/dynamic-cell/dynamic-cell";
import { DynamicCell } from "@/components/dynamic-cell/dynamic-cell";
import { getDynamicFieldPath } from "@/utils/fields/get-dynamic-field-path";

export type ColumnOverrides<T> = { [key in keyof T]?: Partial<ColDef<T>> };

interface GetDynamicTableColumnsParams<T extends { id: number }> {
  layoutFields: FieldConfigurationWithLayout[];
  columnOverrides?: ColumnOverrides<T>;
  additionalColumns?: (ColDef<T> & { order?: number })[];
}

interface CellRendererProps {
  data: DynamicCellProps["record"];
}

export function getDynamicTableColumns<
  T extends { id: number },
  K extends keyof T = keyof T,
>({
  layoutFields,
  columnOverrides,
  additionalColumns = [],
}: GetDynamicTableColumnsParams<T>): ColDef<T>[] {
  const columns = layoutFields.map(
    ({ field, layoutField }): ColDef<T, K> => ({
      field: getDynamicFieldPath(field) as ColDefField<T, K>,
      headerName: field.display_name,
      hide: !layoutField.visible,
      autoHeight: true,
      minWidth: 200,
      headerComponentParams: {
        leftIcon: <ColumnHeaderIcon field={field} />,
      },
      cellStyle: { padding: 8 },
      cellRenderer: (props: CellRendererProps) => (
        <DynamicCell configuration={field} record={props.data} />
      ),
      ...(columnOverrides?.[field.name as keyof T] || {}),
    }),
  );
  const allColumns = columns.concat(additionalColumns);

  const sortedColumns = allColumns
    .map((column, idx) => ({
      ...column,
      order:
        "order" in column && typeof column.order === "number"
          ? column.order
          : idx,
    }))
    .sort((a, b) => a.order - b.order);

  return sortedColumns.map(({ order, ...column }) => column);
}
