import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import IconButton from "../../../Elements/IconButton/icon-button";
import ConfirmationDialog from "../../shared/ConfirmationDialog/confirmation-dialog";
import { RISK_LEVELS_COLORS } from "../constants";
import type { RenamingItemProps } from "./types";

interface Props {
  isNew?: boolean;
  isTemplate?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
}

function RiskAssessmentRiskLevels({ matrix, isNew, isTemplate }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [contextMenuOpened, setContextMenuOpened] = useState(null);
  const [renamingItem, setRenamingItem] = useState<RenamingItemProps>(
    {} as RenamingItemProps,
  );
  const [deletingItem, setDeletingItem] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newRatingTitle, setNewRatingTitle] = useState("");

  // Variables
  const { risk_levels: riskLevels, risk_themes: riskThemes } = matrix;
  const { currentVendor } = mainStore.vendors;
  const status = mainStore.avroSchemas.valueForField(
    "status",
    currentVendor?.data,
  );
  const isActive = !currentVendor || status !== "disabled";

  // Functions
  function handleAddNewRiskLevelClick() {
    if (!isAddingNew) {
      setIsAddingNew(true);
      return;
    }

    if (!newRatingTitle) {
      return;
    }

    const data = { title: newRatingTitle, color_index: 4 };
    // @ts-expect-error TS(2322) FIXME: Type '{ title: string; color_index: number; }' is ... Remove this comment to see the full error message
    handleLocalMatrixUpdate({ mode: "create", data });

    if (!isNew) {
      mainStore.vendors.createRiskLevel(matrix.id, data);
    }

    handleCancelNewRiskLevel();
  }

  function handleCancelNewRiskLevel() {
    setIsAddingNew(false);
    setNewRatingTitle("");
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleNewRiskLevelTitleChange(e) {
    const { value } = e.target;
    setNewRatingTitle(value);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
  function handleSelectRiskLevelColor(index, color) {
    const data = { color_index: RISK_LEVELS_COLORS.indexOf(color) };
    // @ts-expect-error TS(2322) FIXME: Type '{ color_index: number; }' is not assignable ... Remove this comment to see the full error message
    handleLocalMatrixUpdate({ mode: "update", index, data });

    if (!isNew) {
      mainStore.vendors.updateRiskLevel(matrix.id, riskLevels[index].id, data);
    }
    handlePopupClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskLevel' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleRenameRiskLevelClick(riskLevel) {
    setRenamingItem(riskLevel);
    handlePopupClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleRiskLevelTitleChange(e) {
    const { value } = e.target;
    setRenamingItem({ ...renamingItem, title: value });
  }

  function handleRenameRiskLevelSubmit() {
    if (!renamingItem) {
      return;
    }

    const { title, index } = renamingItem;
    const data = { title };

    // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'null | un... Remove this comment to see the full error message
    handleLocalMatrixUpdate({ mode: "update", index, data });
    if (!isNew) {
      mainStore.vendors.updateRiskLevel(matrix.id, riskLevels[index].id, data);
    }

    setRenamingItem({} as RenamingItemProps);
  }

  function handleDeleteSubmit() {
    handleLocalMatrixUpdate({ mode: "delete", index: deletingItem });
    if (!isNew) {
      // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
      mainStore.vendors.deleteRiskLevel(matrix.id, riskLevels[deletingItem].id);
    }

    handleDeleteConfirmationClose();
  }

  function handleDeleteConfirmationClose() {
    handlePopupClose();
    setDeletingItem(null);
  }

  function handlePopupClose() {
    setContextMenuOpened(null);
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'mode' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleLocalMatrixUpdate({ mode, index = null, data = null }) {
    let newRiskLevels = riskLevels;
    let newRiskThemes = riskThemes;

    if (mode === "create") {
      newRiskLevels = [...riskLevels, data];
      // @ts-expect-error TS(7006) FIXME: Parameter 'riskTheme' implicitly has an 'any' type... Remove this comment to see the full error message
      newRiskThemes = riskThemes.map((riskTheme) => ({
        ...riskTheme,
        risk_descriptions: [
          ...riskTheme.risk_descriptions,
          // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'.
          { content: `Select ${data?.title}` },
        ],
      }));
    } else if (mode === "update") {
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      newRiskLevels = riskLevels.map((item, i) =>
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        i === index ? { ...item, ...data } : item,
      );
    } else if (mode === "delete") {
      // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
      newRiskLevels = riskLevels.filter((_, i) => i !== index);
      // @ts-expect-error TS(7006) FIXME: Parameter 'riskTheme' implicitly has an 'any' type... Remove this comment to see the full error message
      newRiskThemes = riskThemes.map((riskTheme) => ({
        ...riskTheme,
        risk_descriptions: riskTheme.risk_descriptions.filter(
          // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
          (_, i) => i !== index,
        ),
      }));
    }

    mainStore.vendors.setCurrentRiskAssessment({
      ...matrix,
      risk_levels: newRiskLevels,
      risk_themes: newRiskThemes,
    });
  }

  // elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'riskLevel' implicitly has an 'any' type... Remove this comment to see the full error message
  const renderRiskLevelItem = (riskLevel, index) => {
    const style = {
      backgroundColor: RISK_LEVELS_COLORS[riskLevel.color_index],
    };
    const active = contextMenuOpened === index;
    const isRenaming = renamingItem?.index === index;
    const isDeleting = deletingItem === index;

    const popupTrigger = (
      <div>
        <IconButton
          size="sm"
          icon="moreHorizontal"
          active={active}
          transparent
          color="generalWhite"
          data-testid="vdd-risk-assessment__risk-level-context-menu-trigger"
        />
      </div>
    );

    const renderRenameConfirm = (
      <IconButton
        icon="check"
        size="sm"
        color="generalWhite"
        active
        transparent
        data-testid="vdd-risk-assessment__risk-level-rename-confirm"
        onClick={handleRenameRiskLevelSubmit}
      />
    );

    const renderDeleteConfirmation = (
      <div className="vdd-risk-assessment__popup">
        <div className="table-dropdown success-dropdown">
          <ConfirmationDialog
            heading="Delete Rating"
            content="Are you sure you want to delete this Rating? Every field under this column will be deleted."
            handleConfirm={handleDeleteSubmit}
            handleReject={handleDeleteConfirmationClose}
          />
        </div>
      </div>
    );

    const renderRegularPopupContent = (
      <div
        className="vdd-risk-assessment__risk-level-context-menu"
        data-testid="vdd-risk-assessment__risk-level-context-menu"
      >
        <div className="vdd-risk-assessment__risk-level-context-menu-colors">
          <p>Select Color</p>
          <div>
            {RISK_LEVELS_COLORS.map((color) => (
              <div
                key={color}
                className={classNames(
                  "vdd-risk-assessment__risk-level-context-menu-color",
                  {
                    active: RISK_LEVELS_COLORS[riskLevel.color_index] === color,
                  },
                )}
                style={{ backgroundColor: color }}
                data-testid="vdd-risk-assessment__risk-level-context-menu-color"
                onClick={() => handleSelectRiskLevelColor(index, color)}
              />
            ))}
          </div>
        </div>

        <hr />

        <ul>
          <li
            data-testid="vdd-risk-assessment__risk-level-context-menu-rename"
            onClick={() => handleRenameRiskLevelClick({ ...riskLevel, index })}
          >
            Rename Rating
          </li>
          <li
            data-testid="vdd-risk-assessment__risk-level-context-menu-delete"
            onClick={() => setDeletingItem(index)}
          >
            Delete Rating
          </li>
        </ul>
      </div>
    );

    return (
      <div
        key={index}
        className="vdd-risk-assessment__risk-level"
        style={style}
        data-testid="vdd-risk-assessment__risk-level"
      >
        <input
          type="text"
          data-testid="vdd-risk-assessment__risk-level-input"
          value={isRenaming ? renamingItem.title : riskLevel.title}
          disabled={!isRenaming}
          onChange={handleRiskLevelTitleChange}
        />
        {isRenaming && renderRenameConfirm}
        {!isRenaming && isActive && (
          <Popup
            position="bottom right"
            trigger={popupTrigger}
            open={active}
            onOpen={() => setContextMenuOpened(index)}
            onClose={handlePopupClose}
            keepTooltipInside
          >
            {isDeleting ? renderDeleteConfirmation : renderRegularPopupContent}
          </Popup>
        )}
      </div>
    );
  };

  const renderAddNewRiskLevelPlaceholder = (
    <div
      className="vdd-risk-assessment__risk-level add-new-placeholder"
      data-testid="vdd-risk-assessment__risk-level-add-new-placeholder"
    >
      <span>Add New Level</span>
      <IconButton
        icon="plus"
        size="sm"
        transparent
        data-testid="vdd-risk-assessment__risk-level-add-new-trigger"
        onClick={handleAddNewRiskLevelClick}
      />
    </div>
  );

  const renderAddNewRiskLevel = (
    <div
      className="vdd-risk-assessment__risk-level add-new"
      data-testid="vdd-risk-assessment__risk-level-add-new"
    >
      <span style={{ backgroundColor: RISK_LEVELS_COLORS[4] }} />
      <input
        type="text"
        data-testid="vdd-risk-assessment__risk-level-add-new-input"
        placeholder="eg. Extreme"
        onChange={handleNewRiskLevelTitleChange}
      />
      <IconButton
        icon="check"
        size="sm"
        transparent
        data-testid="vdd-risk-assessment__risk-level-add-new-submit"
        onClick={handleAddNewRiskLevelClick}
      />
      <IconButton
        icon="close"
        size="sm"
        transparent
        data-testid="vdd-risk-assessment__risk-level-add-new-cancel"
        onClick={handleCancelNewRiskLevel}
      />
    </div>
  );

  const renderComments = (
    <div
      className="vdd-risk-assessment__risk-level comments"
      data-testid="vdd-risk-assessment__risk-level-comments"
    >
      <span>Comments</span>
    </div>
  );

  return (
    <section
      className="vdd-risk-assessment__risk-levels"
      data-testid="vdd-risk-assessment__risk-levels"
    >
      {riskLevels.map(renderRiskLevelItem)}
      {isAddingNew && renderAddNewRiskLevel}
      {!isAddingNew && isActive && renderAddNewRiskLevelPlaceholder}
      {!isTemplate && renderComments}
    </section>
  );
}

RiskAssessmentRiskLevels.defaultProps = {
  isNew: false,
  isTemplate: false,
};

export default observer(RiskAssessmentRiskLevels);
