import { Button, TextInput } from "@themis/ui";
import classNames from "classnames";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { PiLockSimple } from "react-icons/pi";

import { COLOR } from "@/config/theme";

import type { GroupScoringRegister } from "../../types/GroupScoringRegister";

interface AddNewGroupMenuProps {
  registers?: Array<GroupScoringRegister>;
  defaultSelectedIds?: number[];
  onClose: (refetch?: boolean) => void;
  onSubmit: (name: string, riskRegisterIds: number[]) => void;
}

function AddNewGroupMenu(props: AddNewGroupMenuProps) {
  const [selectedRegisters, setSelectedRegisters] = useState<number[]>(
    props.defaultSelectedIds || [],
  );
  const [groupName, setGroupName] = useState<string>("");

  const handleRegisterSelect = (id: number) => {
    const exists = selectedRegisters.includes(id);
    if (exists) {
      setSelectedRegisters((prev) => prev.filter((pid) => pid !== id));
    } else {
      setSelectedRegisters((prev) => [...prev, id]);
    }
  };

  return (
    <div>
      <div
        className=" tw-flex tw-items-center tw-justify-between tw-px-5 tw-py-3 tw-text-neutral-500"
        style={{ borderBottom: `1px solid ${COLOR.generalIconGray}` }}
      >
        <p className="tw-font-sans tw-text-base tw-font-semibold">
          Add New Register
        </p>
        <IoClose
          className="tw-h-5 tw-w-5 tw-cursor-pointer"
          onClick={() => props.onClose()}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-1 tw-px-5 tw-py-4">
        <div className="tw-text-xs tw-font-semibold tw-text-neutral-300">
          Group Name
          <p className="tw-inline tw-pl-1 tw-font-sans tw-text-base tw-font-semibold tw-text-warning-300">
            *
          </p>
        </div>

        <TextInput
          size="lg"
          placeholder="- Type here -"
          name="groupNameInput"
          onChange={(e) => setGroupName(e.target.value)}
        />
      </div>
      <div className="tw-mb-2 tw-px-5">
        {props.registers?.length === 0 && (
          <p className="emptystate">
            You do not have any questionnaires under this Risk Methodology
          </p>
        )}
        {props.registers?.length !== 0 && (
          <div className="tw-text-sm tw-font-semibold tw-text-primaryDim-300">
            Select the Risk Registers you want to score
          </div>
        )}
      </div>
      {props.registers
        ?.sort((a, b) => (b.title > a.title ? -1 : 1))
        .map((register) => (
          <div
            key={register.id}
            className={classNames(
              "tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-px-5 tw-py-[10px]",
              {
                "tw-bg-secondary-25": selectedRegisters.includes(register.id),
              },
            )}
            onClick={() =>
              register.aggregate_scores && handleRegisterSelect(register.id)
            }
          >
            <p
              className={classNames(
                "tw-text-sm tw-font-semibold tw-text-neutral-500",
                {
                  "tw-text-secondary-300": selectedRegisters.includes(
                    register.id,
                  ),
                },
              )}
            >
              {register.title}
            </p>
            {!register.aggregate_scores ? (
              <div
                data-tooltip-content="Register has invalid scoring."
                data-tooltip-id="tooltip"
                data-tooltip-place="left"
              >
                <PiLockSimple className="tw-mr-[1px] tw-h-5 tw-w-5 tw-fill-neutral-300" />
              </div>
            ) : (
              <input
                type="checkbox"
                readOnly
                checked={selectedRegisters.includes(register.id)}
                className="tw-boder-neutral-500 tw-h-4 tw-w-4 tw-text-neutral-500"
              />
            )}
          </div>
        ))}
      <div
        className="tw-mt-2 tw-px-5 tw-py-3"
        style={{
          borderTop: `1px solid ${COLOR.generalIconGray}`,
          borderBottom: `1px solid ${COLOR.generalIconGray}`,
        }}
      >
        <Button
          disabled={
            selectedRegisters.filter(
              (sid) => !props.defaultSelectedIds?.includes(sid),
            ).length === 0 || !groupName
          }
          onClick={() => props.onSubmit(groupName, selectedRegisters)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

export default AddNewGroupMenu;
