import { http } from "msw";

import { createListSearchRecordVersionsQueryResponse } from "../createListSearchRecordVersions";

export const listSearchRecordVersionsHandler = http.get(
  "*/workspaces/:workspace_id/searches",
  function handler(info) {
    return new Response(
      JSON.stringify(createListSearchRecordVersionsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
