import { http } from "msw";

import { createListOfCompanyControlCategoriesQueryResponse } from "../createListOfCompanyControlCategories";

export const listOfCompanyControlCategoriesHandler = http.get(
  "*/companies/:company_id/control_categories",
  function handler(info) {
    return new Response(
      JSON.stringify(createListOfCompanyControlCategoriesQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
