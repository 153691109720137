import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import * as AuthAPI from "@/api/legacy/auth";
import { AUTHENTICATION_OPTION } from "@/api/legacy/auth/types";
import { useMainStore } from "@/contexts/Store";
import { PasswordFields } from "@/features/auth";

import DashboardMenuPopup from "../dashboard/DashboardMenuPopup";
import Button from "../form/Button";
import EmailInput from "../form/EmailInput";
import PasswordInput from "../form/PasswordInput";
import { getWorkspaceLogo } from "../helpers/getWorkspaceLogo";
import CurrentUserSettingsNavigation from "./CurrentUserSettingsNavigation";

function UserSettings() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [authenticationOptions, setAuthenticationOptions] = useState<
    AUTHENTICATION_OPTION[]
  >([]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState();
  const [newPassword, setNewPassword] = useState();
  const [incorrectCurrentPassword, setIncorrectCurrentPassword] =
    useState(false);
  const [complete, setComplete] = useState(false);

  // Variables
  const { user } = mainStore.users;
  const canChangePassword = authenticationOptions.includes(
    AUTHENTICATION_OPTION.EMAIL_PASSWORD,
  );

  // Hooks
  useEffect(() => {
    if (user?.email) {
      loadAuthenticationOptions(user.email);
    }
  }, [user]);

  // Functions
  async function loadAuthenticationOptions(email: string) {
    try {
      const data = await AuthAPI.getAuthenticationOptions(email);
      setAuthenticationOptions(data.authentication_options);
    } catch (error) {
      setAuthenticationOptions([]);
      window.console.error(`AuthAPI.getAuthenticationOptions error ${error}`);
    }
  }

  function handleCurrentPasswordChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { value } = event.target;
    setCurrentPassword(value);
    setIncorrectCurrentPassword(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  async function handleSubmit(event) {
    event.preventDefault();

    await mainStore.users.newPasswordAuthenticated(
      currentPassword,
      newPassword,
    );

    if (mainStore.users.newPasswordIsSet) {
      setComplete(true);
    } else {
      setIncorrectCurrentPassword(true);
    }
  }

  if (complete) {
    return (
      <div className="form-container">
        <h4 className="form-header">Password changed successfully!</h4>
      </div>
    );
  }

  const renderWorkspaces = mainStore.workspaces.list.map((workspace) => {
    const { logo, isInternal } = getWorkspaceLogo(workspace);

    return (
      <div className="workspaces-element" key={workspace.id}>
        <DashboardMenuPopup
          logo={logo}
          name={(isInternal ? workspace.company.name : workspace.name) || ""}
          isInternal={isInternal}
          membersCount={workspace.members_count}
        />
      </div>
    );
  });

  return (
    <>
      <CurrentUserSettingsNavigation />
      <div className="personal-profile-wrap">
        <div className="personal-profile-block">
          <div className="form-container">
            <h4 className="form-header">Your Personal Profile</h4>
            <EmailInput defaultValue={user.email} disabled />
          </div>
          {canChangePassword && (
            <form onSubmit={handleSubmit}>
              <hr className="form-divider" />
              <div className="form-container">
                <h4 className="form-header">Update Password</h4>
                <PasswordInput
                  placeholder="Current Password"
                  name="current_password"
                  onChange={handleCurrentPasswordChange}
                  autoComplete="current-password"
                />
                {incorrectCurrentPassword && (
                  <p className="error-input">Current password incorrect</p>
                )}
                <PasswordFields
                  // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<undefined>>' is not ... Remove this comment to see the full error message
                  onValidityChange={setPasswordValid}
                  // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<undefined>>' is not ... Remove this comment to see the full error message
                  onPasswordChange={setNewPassword}
                />
              </div>
              <Button
                title="Save New Password"
                enabled={passwordValid && currentPassword.length >= 8}
              />
            </form>
          )}
          <hr className="form-divider" />
        </div>
        <div className="workspaces-block">
          <div className="form-container">
            <h4 className="form-header">Your Workspaces</h4>
            <div className="workspaces-list">
              <h5>Your Company Workspaces</h5>
              {renderWorkspaces}
              <hr className="form-divider" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(UserSettings);
