import { http } from "msw";

import { createListAccountsWithLimitedResponseQueryResponse } from "../createListAccountsWithLimitedResponse";

export const listAccountsWithLimitedResponseHandler = http.get(
  "*/module_workspaces/:module_workspace_id/accounts",
  function handler(info) {
    return new Response(
      JSON.stringify(createListAccountsWithLimitedResponseQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
