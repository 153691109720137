import { observer } from "mobx-react";
import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  getRecordName,
  nameFromModuleWorkspace,
} from "@/components/helpers/nameForThemisModuleIdentifier";
import { useMainStore } from "@/contexts/Store";
import {
  kriAuditTrailUrl,
  kriCompanyLibraryUrl,
  kriCompanyLibraryUrlEdit,
  kriCompanyLibraryUrlNew,
  kriDetailViewUrl,
  kriInputUrl,
  kriListUrl,
  kriModuleAuditTrailUrl,
  kriSummaryUrl,
  kriThemisLibraryUrl,
} from "@/features/key-risk-indicators/urlPaths";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import { ModuleNotificationsRoutes } from "../notifications";
import { MODULE_NOTIFICATIONS_BASE_PATH } from "../notifications/pages/constants";
import KRIAuditTrailPage from "./pages/KRIAuditTrailPage";
import KRICompanyLibraryEditPage from "./pages/KriCompanyLibraryEditPage";
import KRICompanyLibraryNewPage from "./pages/KRICompanyLibraryNewPage";
import KRICompanyLibraryPage from "./pages/KRICompanyLibraryPage";
import KRIDetailsPage from "./pages/KRIDetailsPage";
import KRIInputPage from "./pages/KRIInputPage";
import KRIPage from "./pages/KRIPage";
import KRISummaryPage from "./pages/KRISummaryPage";
import KRIThemisLibraryPage from "./pages/KRIThemisLibraryPage";

const KeyRiskIndicatorsPage = () => {
  const mainStore = useMainStore();

  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const moduleName = nameFromModuleWorkspace(
    TopLevelModule.KEY_RISK_INDICATORS,
    moduleWorkspaces,
  );
  const recordName = getRecordName(
    TopLevelModule.KEY_RISK_INDICATORS,
    moduleWorkspaces,
    true,
  );
  return (
    <Switch>
      {mainStore.userPermissions.canSeeNotifications && (
        <Route path={MODULE_NOTIFICATIONS_BASE_PATH}>
          <ModuleNotificationsRoutes />
        </Route>
      )}
      <Route exact path={kriListUrl}>
        <KRIPage moduleName={moduleName} />
      </Route>
      <Route exact path={kriSummaryUrl}>
        <KRISummaryPage moduleName={moduleName} />
      </Route>
      <Route exact path={kriInputUrl}>
        <KRIInputPage moduleName={moduleName} />
      </Route>
      <Route exact path={kriThemisLibraryUrl}>
        <KRIThemisLibraryPage moduleName={moduleName} />
      </Route>
      <Route exact path={kriCompanyLibraryUrl}>
        <KRICompanyLibraryPage moduleName={moduleName} />
      </Route>
      <Route exact path={kriCompanyLibraryUrlNew}>
        <KRICompanyLibraryNewPage />
      </Route>
      <Route exact path={kriCompanyLibraryUrlEdit}>
        <KRICompanyLibraryEditPage />
      </Route>
      <Route exact path={kriModuleAuditTrailUrl}>
        <KRIAuditTrailPage
          isRecordView={false}
          title="Module Level Audit Trail"
        />
      </Route>

      <Route exact path={kriAuditTrailUrl}>
        <KRIAuditTrailPage isRecordView title="Record Level Audit Trail" />
      </Route>
      <Route exact path={kriDetailViewUrl}>
        <KRIDetailsPage recordName={recordName} />
      </Route>
    </Switch>
  );
};

export default observer(KeyRiskIndicatorsPage);
