import { Table } from "@themis/ui";
import React, { useMemo } from "react";

import type { Questionnaire } from "@/api";
import { useAccount } from "@/api/queries/accounts";
import { useRiskMethodologies } from "@/api/queries/riskMethodologies";
import { useUsers } from "@/api/queries/users/use-users";
import type { RiskMethodology } from "@/features/risk-assessment";
import { getDefinitionsFromScores } from "@/features/risk-assessment";

import { columns } from "./TableColumns";

interface AccountQuestionnairesProps {
  accountId: number;
  workspaceId: number;
}

function AccountQuestionnaires({
  accountId,
  workspaceId,
}: AccountQuestionnairesProps) {
  const { data: accountData, isLoading: accountIsLoading } = useAccount({
    workspaceId,
    accountId,
    queryParams: {
      expand: "questionnaires",
    },
  });

  const { data: users = [], isLoading: usersIsLoading } = useUsers(workspaceId);

  const { data: riskData, isLoading: riskIsLoading } =
    useRiskMethodologies(workspaceId);

  const riskMethodologies: RiskMethodology[] =
    riskData?.data.risk_methodologies;

  const tableRows = useMemo(
    () =>
      accountData?.data.questionnaires?.map((questionnaire: Questionnaire) => {
        const methodology = riskMethodologies?.find(
          (rm) => rm.id === questionnaire.risk_methodology_id,
        );

        const {
          overallRiskRating,
          controlRiskRating,
          inherentRiskRating,
          residualRiskRating,
        } = getDefinitionsFromScores(questionnaire.scoring, methodology);

        return {
          ...questionnaire,
          overallRiskRating,
          controlRiskRating,
          inherentRiskRating,
          residualRiskRating,
        };
      }) || [],
    [accountData, riskMethodologies],
  );

  if (
    accountIsLoading ||
    usersIsLoading ||
    riskIsLoading ||
    !tableRows.length
  ) {
    return null;
  }

  return (
    <Table
      columns={columns(users, workspaceId)}
      rows={tableRows}
      width="100%"
    />
  );
}

export default AccountQuestionnaires;
