import { faker } from "@faker-js/faker";

import type { ControlEffectivenessRating } from "../models/ControlEffectivenessRating";

export function createControlEffectivenessRating(
  data: NonNullable<Partial<ControlEffectivenessRating>> = {},
): NonNullable<ControlEffectivenessRating> {
  return {
    ...{
      id: faker.number.int(),
      title: faker.string.alpha(),
      value: faker.number.int(),
      color: faker.string.alpha(),
    },
    ...data,
  };
}
