import { http } from "msw";

import { createCreateSectionMutationResponse } from "../createCreateSection";

export const createSectionHandler = http.post(
  "*/companies/:company_id/sections",
  function handler(info) {
    return new Response(JSON.stringify(createCreateSectionMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
