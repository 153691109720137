import { faker } from "@faker-js/faker";

import type {
  CreateFavoriteModuleWorkspace201,
  CreateFavoriteModuleWorkspace403,
  CreateFavoriteModuleWorkspaceMutationRequest,
  CreateFavoriteModuleWorkspaceMutationResponse,
  CreateFavoriteModuleWorkspacePathParams,
} from "../models/CreateFavoriteModuleWorkspace";
import { createCreateModuleWorkspace } from "./createCreateModuleWorkspace";
import { createFavoriteModuleWorkspace } from "./createFavoriteModuleWorkspace";

export function createCreateFavoriteModuleWorkspacePathParams(): NonNullable<CreateFavoriteModuleWorkspacePathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description CREATED
 */
export function createCreateFavoriteModuleWorkspace201(): NonNullable<CreateFavoriteModuleWorkspace201> {
  return { data: createFavoriteModuleWorkspace() };
}

/**
 * @description Forbidden
 */
export function createCreateFavoriteModuleWorkspace403(): NonNullable<CreateFavoriteModuleWorkspace403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateFavoriteModuleWorkspaceMutationRequest(): NonNullable<CreateFavoriteModuleWorkspaceMutationRequest> {
  return createCreateModuleWorkspace();
}

/**
 * @description CREATED
 */
export function createCreateFavoriteModuleWorkspaceMutationResponse(): NonNullable<CreateFavoriteModuleWorkspaceMutationResponse> {
  return { data: createFavoriteModuleWorkspace() };
}
