import { Button, IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import MoveToSectionPopup from "../shared/MoveToSectionPopup";
import SendRecordVersion from "../shared/SendRecordVersion";

export interface QAContextMenuProps {
  recordVersion: RecordVersion;
  isPlanStarted?: boolean;
}

function QAContextMenu({ recordVersion, isPlanStarted }: QAContextMenuProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showUnlockPopup, setShowUnlockPopup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isArchiveConfirmationOpen, setIsArchiveConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("menu");

  // Variables
  const { canDeleteRecords, hasModuleWriteAccess } = mainStore.userPermissions;
  const activeWorkspace = mainStore.users.user.active_workspace;

  const listPointsClasses = classNames("list-points");
  const statusValue = mainStore.avroSchemas.valueForField(
    "status",
    recordVersion?.data,
  )?.[0];
  const { isIW: isInternal, workspaceID } = mainStore.context;
  const inProgress = statusValue === "in_progress";
  const pendingFinalization = statusValue === "pending_finalization";
  const completed = statusValue === "completed";
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName(
    "qa_tests_development",
    moduleWorkspaces,
    true,
  );
  const { taskDetail } = mainStore;

  // Effects
  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
    }
  }, [showPopup]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'rvID' implicitly has an 'any' type.
  const handleMove = async (rvID, sectionTagID) => {
    await mainStore.qa.updateSection(rvID, sectionTagID);
  };

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  function archiveRowConfirmation() {
    setIsDropdownOpen(false);
    setIsArchiveConfirmationOpen(true);
  }

  function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      mainStore.qa.delete(recordVersion.id);
      mainStore.toast.setText("Test has been deleted!");
    }
  }

  function archiveRow() {
    const { company } = mainStore.companies;
    if (company && company.id) {
      // @ts-expect-error TS(2339) FIXME: Property 'archive' does not exist on type 'QA'.
      mainStore.qa.archive(recordVersion.id);
      mainStore.toast.setText("Test has been archived!");
    }
  }

  function duplicateRecord() {
    mainStore.recordVersions.duplicate(recordVersion.id);
    setIsOpenModal(false);
  }
  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsDeleteConfirmationOpen(false);
    setIsOpenModal(false);
    setViewPopup("menu");
  }

  function closeSlideMenu() {
    setShowSlideMenu(false);
  }

  function openSendSlideMenu() {
    setShowSlideMenu(true);
    setIsOpenModal(false);
  }

  function goToTestDetails() {
    history.push(
      `/workspaces/${workspaceID}/modules/qa-tests-development/${recordVersion.id}`,
    );
  }

  function shareRecordVersion(recordVersionId: number, workspaceIds: number[]) {
    return mainStore.recordVersions.share(
      recordVersionId,
      "RecordVersion",
      workspaceIds,
    );
  }

  const completeQA = () => mainStore.qa.completeQATest(recordVersion.id);

  const unlockQA = () => mainStore.qa.unlockQATest(recordVersion.id);

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const isRecordShared = recordVersion.record.shared_with_workspace_ids.length;

  const renderBaseContent = (
    <div className="table-dropdown policy-dropdown">
      <ul>
        {(completed || inProgress || pendingFinalization) && (
          <li data-testid="view-detail" onClick={goToTestDetails}>
            View
          </li>
        )}
        {activeWorkspace?.is_internal && (
          <li
            data-testid="share-test-trigger"
            data-tooltip-id="tooltip"
            data-tooltip-place="left"
            data-tooltip-content={
              isRecordShared ? "Already shared with 1 workspace" : undefined
            }
            className={classNames({
              disabled: !!isRecordShared,
            })}
            onClick={!isRecordShared ? openSendSlideMenu : undefined}
          >
            Share Test
          </li>
        )}
        {!completed && (
          <li
            className={classNames({
              // @ts-expect-error TS(2339) FIXME: Property 'length' does not exist on type 'SectionT... Remove this comment to see the full error message
              disabled: mainStore.sectionTags.length === 0,
            })}
            onClick={() => setViewPopup("move")}
            data-testid="move-button-trigger"
          >
            Move to
          </li>
        )}
        {hasModuleWriteAccess && (
          <li data-testid="duplicate-test-trigger" onClick={duplicateRecord}>
            Duplicate
          </li>
        )}
        {isPlanStarted && (
          <li
            data-testid="archive-test-trigger"
            onClick={archiveRowConfirmation}
          >
            Archive
          </li>
        )}
        <li onClick={handleCreateTask}>Create Task</li>

        {mainStore.context.activeWorkspace?.is_internal && canDeleteRecords && (
          <>
            <hr />
            <li
              data-testid="delete-test-trigger"
              onClick={deleteRowConfirmation}
            >
              Delete
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const renderUnlockPopupTrigger = (
    <div>
      <Button
        color="transparent"
        size="md"
        onClick={() => setShowUnlockPopup(true)}
        data-testid="qa-context-menu-unlock-trigger"
        disabled={!hasModuleWriteAccess}
      >
        Unlock
      </Button>
    </div>
  );

  const renderUnlockPopup = (
    <Popup
      position="bottom right"
      trigger={renderUnlockPopupTrigger}
      open={showUnlockPopup}
      onOpen={() => setShowUnlockPopup(true)}
      onClose={() => setShowUnlockPopup(false)}
      keepTooltipInside
    >
      <div className="table-dropdown">
        <ConfirmationDialog
          heading="Unlock Test"
          content="Are you sure you wish to unlock this Test?"
          handleConfirm={unlockQA}
          handleReject={() => setShowUnlockPopup(false)}
        />
      </div>
    </Popup>
  );

  const renderButton = () => {
    if (!isInternal) {
      return (
        <Button
          color="tertiary"
          size="md"
          className="tw-w-[86px] tw-max-w-[86px]"
          data-testid="qa-test-view-button"
          onClick={goToTestDetails}
        >
          View
        </Button>
      );
    }

    if (pendingFinalization) {
      return (
        <Button
          color="secondary"
          size="md"
          className="tw-w-[86px] tw-max-w-[86px]"
          disabled={!hasModuleWriteAccess}
          onClick={completeQA}
        >
          Complete
        </Button>
      );
    } else if (completed) {
      return renderUnlockPopup;
    }

    return (
      <Button
        color="tertiary"
        size="md"
        className="tw-w-[86px] tw-max-w-[86px]"
        data-testid="qa-test-view-button"
        onClick={goToTestDetails}
      >
        View
      </Button>
    );
  };

  return (
    <div className={listPointsClasses}>
      {renderButton()}
      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {viewPopup === "move" && (
          <MoveToSectionPopup
            moduleName="Tests"
            recordVersionID={recordVersion.id}
            onMove={handleMove}
            onClose={() => setShowPopup(false)}
            omitTopLevelSection
          />
        )}
        {isDropdownOpen && viewPopup === "menu" && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            <ConfirmationDialog
              heading="Delete Test"
              content="Are you sure you want to delete this test? This is not reversible."
              handleConfirm={deleteRow}
              handleReject={noButtonPressed}
            />
          </div>
        )}
        {isArchiveConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            <ConfirmationDialog
              heading="Archive Test"
              content="Are you sure you want to archive this test?"
              handleConfirm={archiveRow}
              handleReject={noButtonPressed}
            />
          </div>
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        <SendRecordVersion
          isFromMonitoringAndTesting
          recordVersionID={recordVersion.id}
          recordName={recordName}
          sendFunction={shareRecordVersion}
        />
      </SlideMenu>
    </div>
  );
}

export default observer(QAContextMenu);
