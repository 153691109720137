import type {
  CreateControlMappings201,
  CreateControlMappings403,
  CreateControlMappingsMutationRequest,
  CreateControlMappingsMutationResponse,
} from "../models/CreateControlMappings";
import { createControlMappings } from "./createControlMappings";
import { createCreateControlMappingsRequest } from "./createCreateControlMappingsRequest";

/**
 * @description OK
 */
export function createCreateControlMappings201(): NonNullable<CreateControlMappings201> {
  return { data: createControlMappings() };
}

/**
 * @description Forbidden
 */
export function createCreateControlMappings403(): NonNullable<CreateControlMappings403> {
  return { errors: {} };
}

export function createCreateControlMappingsMutationRequest(): NonNullable<CreateControlMappingsMutationRequest> {
  return { data: createCreateControlMappingsRequest() };
}

/**
 * @description OK
 */
export function createCreateControlMappingsMutationResponse(): NonNullable<CreateControlMappingsMutationResponse> {
  return { data: createControlMappings() };
}
