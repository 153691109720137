import { faker } from "@faker-js/faker";

import type {
  ShowContact200,
  ShowContactPathParams,
  ShowContactQueryResponse,
} from "../models/ShowContact";
import { createContact } from "./createContact";

export function createShowContactPathParams(): NonNullable<ShowContactPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createShowContact200(): NonNullable<ShowContact200> {
  return { contact: createContact() };
}

/**
 * @description OK
 */
export function createShowContactQueryResponse(): NonNullable<ShowContactQueryResponse> {
  return { contact: createContact() };
}
