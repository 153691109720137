import { createFieldConfiguration } from "@/api/gen/index";
import type { FieldConfiguration } from "@/api/gen/models";

import { isCustomField } from "../get-is-custom-field";

export function getDynamicFieldPath<
  Config extends FieldConfiguration,
  Name extends string = Config["name"],
>(field: Config) {
  return isCustomField(field)
    ? (`custom_fields.${field.name as Name}` as const)
    : (field.name as Name);
}

const fakeFieldConfiguration = createFieldConfiguration({
  custom: true,
}) as FieldConfiguration & { name: "name" };

export const fakeDynamicFieldPath = getDynamicFieldPath(fakeFieldConfiguration);
