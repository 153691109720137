import { faker } from "@faker-js/faker";

import type { PartialRecord } from "../models/PartialRecord";

export function createPartialRecord(
  data: NonNullable<Partial<PartialRecord>> = {},
): NonNullable<PartialRecord> {
  return {
    ...{
      account_id: faker.number.int(),
      id: faker.number.int(),
      meta: {},
      module_workspace_id: faker.number.int(),
      checklist_count: faker.number.int(),
      completed_checklist_count: faker.number.int(),
    },
    ...data,
  };
}
