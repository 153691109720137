import { faker } from "@faker-js/faker";

import type {
  CreateFindingLegacy201,
  CreateFindingLegacy403,
  CreateFindingLegacyMutationRequest,
  CreateFindingLegacyMutationResponse,
  CreateFindingLegacyPathParams,
} from "../models/CreateFindingLegacy";
import { createCreateFindingRequest } from "./createCreateFindingRequest";
import { createFinding } from "./createFinding";

export function createCreateFindingLegacyPathParams(): NonNullable<CreateFindingLegacyPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateFindingLegacy201(): NonNullable<CreateFindingLegacy201> {
  return { data: createFinding() };
}

/**
 * @description Forbidden
 */
export function createCreateFindingLegacy403(): NonNullable<CreateFindingLegacy403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateFindingLegacyMutationRequest(): NonNullable<CreateFindingLegacyMutationRequest> {
  return createCreateFindingRequest();
}

/**
 * @description OK
 */
export function createCreateFindingLegacyMutationResponse(): NonNullable<CreateFindingLegacyMutationResponse> {
  return { data: createFinding() };
}
