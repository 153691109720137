import { http } from "msw";

import { createListRiskRegisterGroupsQueryResponse } from "../createListRiskRegisterGroups";

export const listRiskRegisterGroupsHandler = http.get(
  "*/workspaces/:workspace_id/risk_registers/risk_register_groups",
  function handler(info) {
    return new Response(
      JSON.stringify(createListRiskRegisterGroupsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
