import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupAsset200,
  WebsiteMonitoringGroupAssetPathParams,
  WebsiteMonitoringGroupAssetQueryResponse,
} from "../models/WebsiteMonitoringGroupAsset";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";

export function createWebsiteMonitoringGroupAssetPathParams(): NonNullable<WebsiteMonitoringGroupAssetPathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupAsset200(): NonNullable<WebsiteMonitoringGroupAsset200> {
  return { data: createMonitoringGroupAsset() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupAssetQueryResponse(): NonNullable<WebsiteMonitoringGroupAssetQueryResponse> {
  return { data: createMonitoringGroupAsset() };
}
