import { http } from "msw";

import { createCreateFieldOptionMutationResponse } from "../createCreateFieldOption";

export const createFieldOptionHandler = http.post(
  "*/fields/:field_id/options",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateFieldOptionMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
