import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { websiteMonitoringRoutes } from "../routes";
import {
  MonitoringGroupChildrenDetails,
  MonitoringGroupChildResults,
  MonitoringGroupDetails,
} from "./MonitoringGroupDetails";
import { MonitoringGroups } from "./MonitoringGroups";

export function WebsiteMonitoringRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${websiteMonitoringRoutes.monitoringGroups}${websiteMonitoringRoutes.monitoringGroupResults}`}
      >
        <MonitoringGroupDetails />
      </Route>
      <Route exact path={`${path}${websiteMonitoringRoutes.monitoringGroups}`}>
        <MonitoringGroups />
      </Route>
      <Route
        path={`${path}${websiteMonitoringRoutes.monitoringGroups}${websiteMonitoringRoutes.monitoringGroupAssetChildren}`}
      >
        <MonitoringGroupChildrenDetails />
      </Route>
      <Route
        path={`${path}${websiteMonitoringRoutes.monitoringGroups}${websiteMonitoringRoutes.monitoringGroupAssetChildResults}`}
      >
        <MonitoringGroupChildResults />
      </Route>
    </Switch>
  );
}
