import { faker } from "@faker-js/faker";

import type { Audit } from "../models/Audit";
import { createUser } from "./createUser";

export function createAudit(
  data: NonNullable<Partial<Audit>> = {},
): NonNullable<Audit> {
  return {
    ...{
      id: faker.number.int(),
      created_at: faker.date.anytime().toISOString(),
      themis_record_type: faker.string.alpha(),
      record_name: faker.string.alpha(),
      action: faker.string.alpha(),
      description: faker.string.alpha(),
      user: Object.assign({}, createUser()),
    },
    ...data,
  };
}
