import { HeaderTabs } from "@themis/ui";
import dayjs from "dayjs";
import toLower from "lodash/toLower";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import type { RecordVersion } from "@/api";
import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import { getSelectedTab } from "@/components/helpers/Tabs";
import {
  dashboardDisplayNames,
  mapUrlToDashboard,
} from "@/components/reports/common/MetricsPage/constants";
import {
  Dashboards,
  IssueManagementTabs as IMTabs,
} from "@/components/reports/common/MetricsPage/types";
import {
  activeIssuesUrl,
  allIssuesUrl,
  completedIssuesUrl,
  createdDateDashboardUrl,
  metricsDashboardUrl,
  overdueDashboardUrl,
  statusDashboardUrl,
  timeToCloseDashboardUrl,
} from "@/components/reports/common/MetricsPage/urlPaths";
import SingleSelectField from "@/components/reports/common/SingleSelectField";
import IssueManagementMetrics from "@/components/reports/issueManagement/IssueManagementMetrics";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import useDragAndDropRecords from "@/hooks/useDragAndDropRecords";
import usePrevious from "@/hooks/usePrevious";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";
import { pathWithoutWorkspacePrefix } from "@/utils/routing";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import HeadSelect from "../shared/HeadSelect";
import SectionDragContext from "../shared/SectionDragContext";
import SectionPage from "../shared/SectionPage";

const TABS = [
  {
    name: "All",
    key: "all",
    value: allIssuesUrl,
    dataTestId: "issue-management-tab-All-trigger",
  },
  {
    name: "Active",
    key: "drafts",
    value: activeIssuesUrl,
    dataTestId: "issue-management-tab-Active-trigger",
  },
  {
    name: "Completed",
    key: "completed",
    value: completedIssuesUrl,
    dataTestId: "issue-management-tab-Completed-trigger",
  },
  {
    name: "Metrics",
    key: "metrics",
    value: metricsDashboardUrl,
    dataTestId: "issue-management-tab-Metrics-trigger",
  },
];

function IssueManagement() {
  const mainStore = useMainStore();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const workspaceId = Number(workspace_id);
  const history = useHistory();
  const location = useLocation();
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const { groupFieldName } = mainStore.reports;
  const { canViewReports } = mainStore.userPermissions;

  const tabs = canViewReports
    ? TABS
    : TABS.filter((tab) => tab.name !== "Metrics");

  const prevLocation = usePrevious(location.pathname);

  const { onSectionChange, onRecordMoved } = useDragAndDropRecords({
    themisIdentifier: TopLevelModule.ISSUE_MANAGEMENT,
    mainStore,
  });

  useEffect(() => {
    if (mainStore.fields.allGroupableFields.length === 0 && workspaceId) {
      mainStore.fields.getAllFieldsByDataType({
        workspaceIDs: [workspaceId],
        identifier: TopLevelModule.ISSUE_MANAGEMENT,
      });
    }
  }, [workspaceId, mainStore.fields.allGroupableFields]);

  useEffect(() => {
    const path = location.pathname.split("/");

    if (path[path.length - 1] === toLower(IMTabs.METRICS)) {
      history.push(`/workspaces/${workspaceId}${statusDashboardUrl}`);
    }
  }, [location.pathname]);

  const tabName = useMemo(() => {
    const relativePath = location.pathname.replace(
      `/workspaces/${workspaceId}`,
      "",
    );

    switch (relativePath) {
      case statusDashboardUrl:
      case createdDateDashboardUrl:
      case overdueDashboardUrl:
      case timeToCloseDashboardUrl:
      case metricsDashboardUrl:
        return IMTabs.METRICS;
      case allIssuesUrl:
        return IMTabs.ALL;
      case activeIssuesUrl:
        return IMTabs.ACTIVE;
      case completedIssuesUrl:
        return IMTabs.COMPLETED;
      default:
        return IMTabs.ALL;
    }
  }, [location.pathname]);

  useEffect(() => {
    mainStore.issueManagement.setLastTab(tabName);
  }, [tabName]);

  useEffect(
    () => () => {
      mainStore.issueManagement.abort();
    },
    [],
  );

  useEffect(() => {
    const routeWithoutWorkspacePrefix = pathWithoutWorkspacePrefix(
      location.pathname,
    );
    if (
      Object.keys(mapUrlToDashboard).includes(routeWithoutWorkspacePrefix) &&
      prevLocation !== location.pathname
    ) {
      mainStore.reports.setGroupFieldName(
        mapUrlToDashboard[routeWithoutWorkspacePrefix],
      );
    }
  }, [location.pathname]);

  function createNewIssue(sectionTagID?: number | null) {
    const tableId = mainStore.context.tableID;

    if (!tableId) {
      return;
    }

    const searchParams = new URLSearchParams({
      ...(sectionTagID ? { section_tag_id: String(sectionTagID) } : {}),
      table_id: String(tableId),
    }).toString();

    history.push(
      `/workspaces/${workspaceId}/modules/issue-management/new-record?${searchParams}`,
    );
  }

  const minDateFunction = (columnTitle: string, rv: RecordVersion) => {
    if (columnTitle !== "Due Date") {
      return null;
    }

    const actionPlansDates = rv.record.action_plans.reduce<number[]>(
      (dates, { target_completion_date }) =>
        target_completion_date
          ? [...dates, dayjs(target_completion_date).valueOf()]
          : dates,
      [],
    );

    return actionPlansDates.length
      ? dayjs(Math.max(...actionPlansDates)).toDate()
      : null;
  };

  const maxDateFunction = (columnTitle: string) => {
    if (columnTitle === "Date Identified") {
      return new Date();
    }
    return null;
  };

  const handleChangeGroupFieldName = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
    (option) => {
      switch (option) {
        case dashboardDisplayNames[Dashboards.STATUS]:
          history.push(`/workspaces/${workspaceId}${statusDashboardUrl}`);
          break;
        case dashboardDisplayNames[Dashboards.CREATED_DATE]:
          history.push(`/workspaces/${workspaceId}${createdDateDashboardUrl}`);
          break;
        case dashboardDisplayNames[Dashboards.OVERDUE]:
          history.push(`/workspaces/${workspaceId}${overdueDashboardUrl}`);
          break;
        case dashboardDisplayNames[Dashboards.TIME_TO_CLOSE]:
          history.push(`/workspaces/${workspaceId}${timeToCloseDashboardUrl}`);
          break;
        default:
          break;
      }
    },
    [history],
  );

  const Tab = (
    <div className="issue-management-tabs-container tw-h-full">
      <HeaderTabs
        tabs={tabs}
        selectedTab={getSelectedTab(TABS)?.value || ""}
        isLink
        dataTestId="main-tabs"
      />
      <div>
        {tabName === IMTabs.METRICS && (
          <div>
            <SingleSelectField
              value={dashboardDisplayNames[groupFieldName]}
              options={Object.values(dashboardDisplayNames)}
              handleChange={handleChangeGroupFieldName}
              canSelectSameOption={false}
              testId="dashboard-select"
            />
          </div>
        )}
      </div>
    </div>
  );

  const renderPage = (screen: IMTabs) => (
    <SectionDragContext
      onSectionChange={onSectionChange}
      onRecordMoved={onRecordMoved}
    >
      <SectionPage
        isDraggable
        isTopLevelSectionAdded
        archived={screen === IMTabs.COMPLETED}
        approvalFieldName=""
        CTASubject="Issue"
        moduleIdentifier="issue_management"
        recordName={getRecordName("issue_management", moduleWorkspaces, true)}
        tabs={Tab}
        createNewRecord={createNewIssue}
        selectedTab={screen}
        paginated
        archivedWord="closed"
        minDateFunction={minDateFunction}
        maxDateFunction={maxDateFunction}
      />
    </SectionDragContext>
  );

  return (
    <>
      <DashboardHeader
        title="Issue Management"
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContent>
        <Switch>
          <Route path={`/workspaces/:workspace_id${allIssuesUrl}`}>
            <DashboardContentWrapper>
              {renderPage(IMTabs.ALL)}
            </DashboardContentWrapper>
          </Route>

          <Route path={`/workspaces/:workspace_id${activeIssuesUrl}`}>
            <DashboardContentWrapper>
              {renderPage(IMTabs.ACTIVE)}
            </DashboardContentWrapper>
          </Route>
          <Route path={`/workspaces/:workspace_id${completedIssuesUrl}`}>
            <DashboardContentWrapper>
              {renderPage(IMTabs.COMPLETED)}
            </DashboardContentWrapper>
          </Route>

          <Route path={`/workspaces/:workspace_id${metricsDashboardUrl}`}>
            <div className="table-header-wrap im-tab-container">{Tab}</div>
            <DashboardContentWrapper>
              <IssueManagementMetrics />
              <div style={{ height: "40px" }} />
            </DashboardContentWrapper>
          </Route>
        </Switch>
      </DashboardContent>
    </>
  );
}

export default observer(IssueManagement);
