import { faker } from "@faker-js/faker";

import type {
  RecordVersion,
  RecordVersion200,
  RecordVersionPathParams,
  RecordVersionQueryResponse,
} from "../models/RecordVersion";
import { createPartialRecordVersion } from "./createPartialRecordVersion";
import { createRecord } from "./createRecord";
import { createReview } from "./createReview";
import { createShowRecordVersion } from "./createShowRecordVersion";
import { createTableName } from "./createTableName";

export function createRecordVersion(
  data?: NonNullable<Partial<RecordVersion>>,
): NonNullable<RecordVersion> {
  return Object.assign({}, createPartialRecordVersion(), {
    record: createRecord(),
    meta: {},
    position: faker.number.float(),
    reviews: faker.helpers.arrayElements([createReview()]) as any,
    section_tag_id: faker.number.int(),
    status: faker.string.alpha(),
    table_name: createTableName(),
  });
}

export function createRecordVersionPathParams(): NonNullable<RecordVersionPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createRecordVersion200(): NonNullable<RecordVersion200> {
  return { data: createShowRecordVersion() };
}

/**
 * @description OK
 */
export function createRecordVersionQueryResponse(): NonNullable<RecordVersionQueryResponse> {
  return { data: createShowRecordVersion() };
}
