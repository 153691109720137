import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupResultUpdate200,
  WebsiteMonitoringGroupResultUpdateMutationRequest,
  WebsiteMonitoringGroupResultUpdateMutationResponse,
  WebsiteMonitoringGroupResultUpdatePathParams,
} from "../models/WebsiteMonitoringGroupResultUpdate";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";
import { createUpdateMonitoringGroupResultRequest } from "./createUpdateMonitoringGroupResultRequest";

export function createWebsiteMonitoringGroupResultUpdatePathParams(): NonNullable<WebsiteMonitoringGroupResultUpdatePathParams> {
  return { monitoring_group_result_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupResultUpdate200(): NonNullable<WebsiteMonitoringGroupResultUpdate200> {
  return { data: createMonitoringGroupAsset() };
}

export function createWebsiteMonitoringGroupResultUpdateMutationRequest(): NonNullable<WebsiteMonitoringGroupResultUpdateMutationRequest> {
  return { data: createUpdateMonitoringGroupResultRequest() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupResultUpdateMutationResponse(): NonNullable<WebsiteMonitoringGroupResultUpdateMutationResponse> {
  return { data: createMonitoringGroupAsset() };
}
