import { http } from "msw";

import { createCreateThemisDocumentMutationResponse } from "../createCreateThemisDocument";

export const createThemisDocumentHandler = http.post(
  "*/:record_type/:record_id/themis_documents",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateThemisDocumentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
