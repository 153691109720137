export const moduleAssessmentRoutes = {
  overview:
    "/workspaces/:workspace_id/modules/control-mapping/:record_version_id/module_assessments",
  detail:
    "/workspaces/:workspace_id/modules/control-mapping/:record_version_id/module_assessments/:module_assessment_id",
  new: "/workspaces/:workspace_id/modules/control-mapping/:record_version_id/module_assessments/new",
} as const;

export const RECORD_VERSION_LIST_PATH =
  `/workspaces/:workspace_id/modules/control-mapping/:record_version_id` as const;

export const AUDIT_LIST_PATH =
  `/workspaces/:workspace_id/modules/control-mapping/:record_version_id/audit_trail` as const;
