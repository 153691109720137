import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DatePicker,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@themis/ui";
import dayjs from "dayjs";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { SHORT_API_FORMAT } from "@/components/constants";
import { useSearchParams } from "@/hooks/useSearchParams";

import type { HomePageSearchParams } from "../pages/CompanyOutlook";

const formSchema = z
  .object({
    start_date: z.string({ required_error: "Start Date is required" }).date(),
    end_date: z.string({ required_error: "End Date is required" }).date(),
  })
  .refine(
    (data) => {
      if (data.start_date && data.end_date) {
        return new Date(data.start_date) < new Date(data.end_date);
      }
      return true;
    },
    { message: "End date must be after start date", path: ["end_date"] },
  );

type CustomTimeRangeSchema = z.infer<typeof formSchema>;

export function CustomTimeRangeModal({
  onClose,
  onSetCustomTimeRange,
  isBackwardRange,
}: {
  onClose: () => void;
  onSetCustomTimeRange: ({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) => void;
  isBackwardRange?: boolean;
}) {
  const [{ start_date, end_date }] = useSearchParams<HomePageSearchParams>();

  const form = useForm<CustomTimeRangeSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      start_date,
      end_date,
    },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    form.handleSubmit((data) => {
      onSetCustomTimeRange(data);
    })();
  }

  return (
    <Dialog open>
      <DialogContent className="tw-w-[480px]" onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <DialogHeader className="tw-pb-6">
            <DialogTitle>Custom Time Range</DialogTitle>
            <DialogDescription>
              You can only select date range in the
              {isBackwardRange ? " past" : " future"}
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <div className="tw-flex tw-flex-col tw-gap-3">
              <FormField
                control={form.control}
                name="start_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Start Date</FormLabel>
                    <FormControl>
                      <DatePicker
                        closeOnDateSelect
                        calendarProps={{
                          mode: "single",
                          selected: field.value
                            ? dayjs(field.value).toDate()
                            : undefined,
                          fromDate: !isBackwardRange ? new Date() : undefined,
                          toDate: isBackwardRange ? new Date() : undefined,
                          onSelect: (date) =>
                            date &&
                            field.onChange(
                              dayjs(date).format(SHORT_API_FORMAT),
                            ),
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="end_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>End Date</FormLabel>
                    <FormControl>
                      <DatePicker
                        closeOnDateSelect
                        calendarProps={{
                          mode: "single",
                          selected: field.value
                            ? dayjs(field.value).toDate()
                            : undefined,
                          fromDate: !isBackwardRange ? new Date() : undefined,
                          toDate: isBackwardRange ? new Date() : undefined,
                          onSelect: (date) =>
                            date &&
                            field.onChange(
                              dayjs(date).format(SHORT_API_FORMAT),
                            ),
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Form>
          <DialogFooter className="tw-pt-6">
            <Button type="submit">Set</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
