var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { sortBy } from "lodash";
export var defaultSort = function (sort, items) {
    var _items = __spreadArray([], items, true);
    var sortDirection = sort === "asc" ? 1 : -1;
    return _items.sort(function (a, b) {
        var compareValue = a.label.localeCompare(b.label, undefined, {
            sensitivity: "base",
            caseFirst: "false",
            numeric: true,
            usage: "sort",
        });
        return sortDirection * compareValue;
    });
};
export var selectionSort = function (sort, items, selected) {
    var _items = __spreadArray([], items, true);
    var defaultSortedItems = defaultSort(sort, _items);
    return sortBy(defaultSortedItems, function (item) { return !selected.includes(item.value); });
};
export var selectSorts = {
    defaultSort: defaultSort,
    selectionSort: selectionSort,
};
