var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cva } from "cva";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { PiCaretDownBold, PiCheckBold, PiPlusBold } from "react-icons/pi";
import { cn } from "../../../lib/utils";
import { Button } from "../../Button/Button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, } from "../../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover/Popover";
import { Stack } from "../../stack/stack";
import { Checkbox } from "../Checkbox";
import { selectSorts } from "./selectSorts";
var defaultStyles = "tw-flex tw-w-full tw-bg-neutral-25 tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-transition-colors";
var fontStyles = "tw-text-sm tw-font-medium tw-font-sans tw-text-neutral-500";
var focusStyles = "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
var readOnlyStyles = "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var lockedStyles = "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var selectInputVariants = cva({
    base: [defaultStyles, fontStyles, focusStyles],
    variants: {
        size: {
            sm: "tw-h-7",
            md: "tw-h-8",
            lg: "tw-h-9",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});
var Select = React.forwardRef(function (_a, ref) {
    var _b;
    var size = _a.size, _c = _a.placeholder, placeholder = _c === void 0 ? "- Select -" : _c, searchable = _a.searchable, selected = _a.selected, renderSelected = _a.renderSelected, onSelect = _a.onSelect, items = _a.items, className = _a.className, locked = _a.locked, readOnly = _a.readOnly, _d = _a.defaultOpen, defaultOpen = _d === void 0 ? false : _d, _e = _a.hideCaret, hideCaret = _e === void 0 ? false : _e, _f = _a.alignItemList, alignItemList = _f === void 0 ? "start" : _f, popoverContentClassName = _a.popoverContentClassName, sort = _a.sort, _g = _a.onPopupClosed, onPopupClosed = _g === void 0 ? function () { } : _g, multiple = _a.multiple, title = _a.title, createNewItem = _a.createNewItem, error = _a.error, renderOption = _a.renderOption, rest = __rest(_a, ["size", "placeholder", "searchable", "selected", "renderSelected", "onSelect", "items", "className", "locked", "readOnly", "defaultOpen", "hideCaret", "alignItemList", "popoverContentClassName", "sort", "onPopupClosed", "multiple", "title", "createNewItem", "error", "renderOption"]);
    var _h = useState(defaultOpen), open = _h[0], setOpen = _h[1];
    var _j = useState(false), isCreateDialogOpen = _j[0], setIsCreateDialogOpen = _j[1];
    var shownItems = useMemo(function () {
        if (sort && multiple) {
            return selectSorts.selectionSort(sort, items, selected);
        }
        if (sort) {
            return selectSorts.defaultSort(sort, items);
        }
        return items;
    }, [items, multiple, selected, sort]);
    // Internal state to manage selected items in case of multiple select
    // This is required to manage the selected items before confirming the selection
    var _k = useState(multiple ? selected : []), internalSelected = _k[0], setInternalSelected = _k[1];
    var disabled = locked || readOnly;
    var handleSelect = function (newValue) {
        if (multiple) {
            var newItems = internalSelected.includes(newValue)
                ? internalSelected.filter(function (item) { return item !== newValue; })
                : __spreadArray(__spreadArray([], internalSelected, true), [newValue], false);
            setInternalSelected(newItems);
        }
        else {
            setOpen(false);
            // Since we hide the selected item from the list, this ensures list doesn't glitch before closing popup
            setTimeout(function () { return onSelect(newValue); }, 100);
        }
    };
    var handleCreate = function (value) {
        handleSelect(value);
        if (multiple) {
            // also add the option to the currently "commited" selection
            var newSelection = __spreadArray(__spreadArray([], selected, true), [value], false);
            onSelect(newSelection);
        }
    };
    var handleCloseCreateDialog = function () {
        setIsCreateDialogOpen(false);
    };
    var handleConfirm = function () {
        if (!multiple) {
            return;
        }
        onSelect(internalSelected);
        setOpen(false);
    };
    var handleCancel = function () {
        if (!multiple) {
            return;
        }
        setOpen(false);
        setInternalSelected(selected);
    };
    var renderComponent = function (selectedItem) {
        if (!selectedItem) {
            return null;
        }
        if (renderSelected) {
            return renderSelected(selectedItem || {});
        }
        if (renderOption) {
            return renderOption(selectedItem, { index: -1 });
        }
        var CustomComponent = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.Component;
        if (CustomComponent) {
            return (React.createElement("div", { key: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value, className: "tw-mr-1" },
                React.createElement(CustomComponent, __assign({}, selectedItem))));
        }
        return selectedItem.label;
    };
    var renderSingleSelection = function () {
        if (selected) {
            var selectedItem = items.find(function (item) { return item.value === selected; });
            return renderComponent(selectedItem);
        }
        return (React.createElement("p", { className: cn("tw-text-neutral-200", {
                "tw-text-warning-300": error,
            }) }, placeholder));
    };
    var renderMultipleSelection = function () {
        if (!multiple || isEmpty(selected)) {
            return (React.createElement("p", { className: cn("tw-text-neutral-200", {
                    "tw-text-warning-300": error,
                }) }, placeholder));
        }
        var selectedItemsToRender = shownItems
            .filter(function (_a) {
            var value = _a.value;
            return selected.includes(value);
        })
            .slice(0, 3)
            .map(function (selectedItem) { return renderComponent(selectedItem); });
        return __spreadArray(__spreadArray([], selectedItemsToRender, true), [
            selected.length > 3 && (React.createElement("div", { key: "more", className: "tw-flex tw-min-h-6 tw-min-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50 tw-text-xxs tw-font-semibold tw-text-neutral-500" },
                "+",
                selected.length - 3)),
        ], false);
    };
    var handleRenderOption = function (option, state) {
        if (renderOption) {
            return renderOption(option, state);
        }
        if (option.Component) {
            var Component = option.Component, props = __rest(option, ["Component"]);
            return React.createElement(Component, __assign({}, props));
        }
        return option.label;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { open: open, onOpenChange: setOpen },
            React.createElement(PopoverTrigger, { disabled: disabled, ref: ref, asChild: true },
                React.createElement("button", __assign({ role: "combobox", "aria-expanded": open, className: cn(selectInputVariants({ size: size }), (_b = {
                            "tw-border-neutral-100": !open,
                            "tw-border-primary-300": open,
                            "tw-text-neutral-200": !selected
                        },
                        _b[lockedStyles] = locked,
                        _b[readOnlyStyles] = readOnly,
                        _b["tw-cursor-default"] = disabled,
                        _b), className) }, rest),
                    React.createElement(Stack, { direction: "row", spacing: "0.5" }, multiple ? renderMultipleSelection() : renderSingleSelection()),
                    !disabled && !hideCaret && (React.createElement(PiCaretDownBold, { className: cn("tw-ml-auto tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-500 tw-transition-transform", { "tw-rotate-180": open }) })))),
            React.createElement(PopoverContent, { align: alignItemList, className: cn("tw-min-w-60", popoverContentClassName, {
                    "tw-hidden": isCreateDialogOpen,
                }), onCloseAutoFocus: onPopupClosed, onPointerDownOutside: function (event) {
                    if (multiple) {
                        event.preventDefault();
                    }
                }, onFocusOutside: function (event) {
                    if (multiple) {
                        event.preventDefault();
                    }
                }, onEscapeKeyDown: handleCancel },
                title && (React.createElement("h6", { className: "tw-m-0 tw-px-4 tw-pt-2 tw-text-base tw-font-semibold tw-text-neutral-500" }, title)),
                React.createElement(Command, { tabIndex: 0, className: "tw-outline-none" },
                    searchable && React.createElement(CommandInput, { autoFocus: true, placeholder: "Search..." }),
                    React.createElement(CommandList, { className: cn({
                            "tw-border-t tw-border-solid tw-border-neutral-100": searchable,
                        }, popoverContentClassName) },
                        React.createElement("div", { className: "tw-max-h-72 tw-overflow-y-auto" },
                            searchable && React.createElement(CommandEmpty, null, "No results."),
                            React.createElement(CommandGroup, null, shownItems.map(function (option, index) {
                                var value = option.value, label = option.label;
                                var isSelected = multiple
                                    ? internalSelected.includes(value)
                                    : selected === value;
                                return (React.createElement(CommandItem, { key: value, value: String(value), keywords: [label], onSelect: handleSelect },
                                    multiple && (React.createElement(Checkbox, { tabIndex: -1, size: "md", color: "primary", className: "tw-mr-2", checked: isSelected })),
                                    handleRenderOption(option, { index: index }),
                                    !multiple && (React.createElement(PiCheckBold, { className: cn("tw-ml-auto tw-h-4 tw-w-4", {
                                            "tw-opacity-0": !isSelected,
                                            "tw-text-secondary-300": isSelected,
                                        }) }))));
                            }))),
                        createNewItem && (React.createElement(CommandItem, { tabIndex: 0, className: "tw-cursor-pointer tw-border-x-0 tw-border-y tw-border-solid tw-border-primary-50 tw-py-2", forceMount: true, onClick: function (event) {
                                event.preventDefault();
                            }, onSelect: function () {
                                setIsCreateDialogOpen(true);
                            } },
                            React.createElement(PiPlusBold, { className: "tw-mr-2 tw-h-4 tw-w-4" }),
                            createNewItem.selectItemLabel)))),
                multiple && (React.createElement("section", { className: "tw-align-center tw-flex tw-justify-end tw-gap-2 tw-px-4 tw-py-2" },
                    React.createElement(Button, { onClick: handleConfirm }, "Confirm"),
                    React.createElement(Button, { onClick: handleCancel, color: "tertiary" }, "Cancel"))))),
        isCreateDialogOpen &&
            createNewItem &&
            createNewItem.createSelectItemComponent(handleCloseCreateDialog, handleCreate)));
});
Select.displayName = "Select";
export { Select };
