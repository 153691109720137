import { Button, useToast } from "@themis/ui";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import type { Comment, UpdateCommentPathParams } from "@/api";
import { useUpdateComment } from "@/api/queries/comments/useUpdateComment";
import { useUsers } from "@/api/queries/users/use-users";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { Flex } from "../../Elements";
import { DisabledUserMention } from "../user-mention/disabled-user-mention";
import { UserMention } from "../user-mention/user-mention";

interface CommentContentProps {
  comment: Comment;
  editing: boolean;
  onStopEditing: () => void;
  recordId: UpdateCommentPathParams["record_id"];
  recordType: UpdateCommentPathParams["record_type"];
}

export function CommentContent({
  comment,
  editing,
  onStopEditing,
  recordId,
  recordType,
}: CommentContentProps) {
  const workspaceId = useRouteWorkspaceId();
  const { data: users = [] } = useUsers(workspaceId);

  const { formatMessage } = useIntl();
  const toast = useToast();

  const [commentInput, setCommentInput] = useState(comment.content);
  const { mutate: updateComment, isPending } = useUpdateComment(
    { recordType, recordId },
    {
      onSuccess: onStopEditing,
      onError: () => {
        toast({
          content: "Failed to add comment",
          variant: "error",
        });
      },
    },
  );

  useEffect(() => {
    setCommentInput(comment.content);
  }, [comment.content]);

  function handleSaveCommentEdit() {
    if (commentInput && commentInput !== comment?.content) {
      updateComment({
        commentId: comment.id,
        data: { comment: { content: commentInput } },
      });
    }
  }
  function handleCancelCommentEdit() {
    if (commentInput !== comment?.content) {
      setCommentInput(comment.content);
    }
    onStopEditing();
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-py-3">
      <div className="tw-flex tw-flex-col tw-gap-2">
        {editing ? (
          <UserMention
            commentInput={commentInput}
            disableSubmit={false}
            onChangeCommentInput={setCommentInput}
            onSubmit={handleSaveCommentEdit}
            users={users}
            placeholder={formatMessage({
              defaultMessage: "Add a comment. Use @ to mention a user.",
            })}
          />
        ) : (
          <DisabledUserMention value={commentInput} />
        )}
      </div>
      {editing && (
        <Flex alignCenter columnGap={8}>
          <Button
            loading={isPending}
            disabled={!commentInput || commentInput === comment.content}
            size="sm"
            onClick={handleSaveCommentEdit}
          >
            {formatMessage({ defaultMessage: "Update" })}
          </Button>
          <Button color="tertiary" size="sm" onClick={handleCancelCommentEdit}>
            {formatMessage({ defaultMessage: "Cancel" })}
          </Button>
        </Flex>
      )}
    </div>
  );
}
