import { useCallback } from "react";

import { useSearchParams } from "@/hooks/useSearchParams";

export function useCommentsRouteParams() {
  const [params, setParams] = useSearchParams();

  const isOpen = !!params.showCommentsForRecord;

  const close = useCallback(() => {
    setParams({});
  }, [setParams]);

  const open = useCallback(
    (recordId: number) => {
      setParams({ showCommentsForRecord: recordId.toString() });
    },
    [setParams],
  );

  return {
    close,
    isOpen,
    open,
    selectedRecordId: Number(params.showCommentsForRecord),
  };
}
