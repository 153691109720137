import { faker } from "@faker-js/faker";

import type {
  ListTaskSections200,
  ListTaskSectionsPathParams,
  ListTaskSectionsQueryResponse,
} from "../models/ListTaskSections";
import { createTaskSection } from "./createTaskSection";

export function createListTaskSectionsPathParams(): NonNullable<ListTaskSectionsPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListTaskSections200(): NonNullable<ListTaskSections200> {
  return { data: faker.helpers.arrayElements([createTaskSection()]) as any };
}

/**
 * @description OK
 */
export function createListTaskSectionsQueryResponse(): NonNullable<ListTaskSectionsQueryResponse> {
  return { data: faker.helpers.arrayElements([createTaskSection()]) as any };
}
