import { faker } from "@faker-js/faker";

import type {
  GetFollowupQuestions200,
  GetFollowupQuestionsQueryParams,
  GetFollowupQuestionsQueryResponse,
} from "../models/GetFollowupQuestions";
import { createQuestion } from "./createQuestion";

export function createGetFollowupQuestionsQueryParams(): NonNullable<GetFollowupQuestionsQueryParams> {
  return { question_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetFollowupQuestions200(): NonNullable<GetFollowupQuestions200> {
  return { data: faker.helpers.arrayElements([createQuestion()]) as any };
}

/**
 * @description OK
 */
export function createGetFollowupQuestionsQueryResponse(): NonNullable<GetFollowupQuestionsQueryResponse> {
  return { data: faker.helpers.arrayElements([createQuestion()]) as any };
}
