import { faker } from "@faker-js/faker";

import type {
  UpdateModuleAssessmentStatus200,
  UpdateModuleAssessmentStatusMutationResponse,
  UpdateModuleAssessmentStatusPathParams,
  UpdateModuleAssessmentStatusQueryParams,
} from "../models/UpdateModuleAssessmentStatus";
import { createModuleAssessment } from "./createModuleAssessment";

export function createUpdateModuleAssessmentStatusPathParams(): NonNullable<UpdateModuleAssessmentStatusPathParams> {
  return { module_assessment_id: faker.number.int() };
}

export function createUpdateModuleAssessmentStatusQueryParams(): NonNullable<UpdateModuleAssessmentStatusQueryParams> {
  return { status: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createUpdateModuleAssessmentStatus200(): NonNullable<UpdateModuleAssessmentStatus200> {
  return { data: createModuleAssessment() };
}

/**
 * @description OK
 */
export function createUpdateModuleAssessmentStatusMutationResponse(): NonNullable<UpdateModuleAssessmentStatusMutationResponse> {
  return { data: createModuleAssessment() };
}
