import { http } from "msw";

import { createDeleteTrainingMutationResponse } from "../createDeleteTraining";

export const deleteTrainingHandler = http.delete(
  "*/trainings/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteTrainingMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
