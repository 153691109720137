import { http } from "msw";

import { createCreateIssueManagementRecordMutationResponse } from "../createCreateIssueManagementRecord";

export const createIssueManagementRecordHandler = http.post(
  "*/workspaces/:workspace_id/issue_management_records",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateIssueManagementRecordMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
