import { faker } from "@faker-js/faker";

import type {
  ListProjects200,
  ListProjectsPathParams,
  ListProjectsQueryParams,
  ListProjectsQueryResponse,
} from "../models/ListProjects";
import { createProject } from "./createProject";

export function createListProjectsPathParams(): NonNullable<ListProjectsPathParams> {
  return { company_id: faker.number.int() };
}

export function createListProjectsQueryParams(): NonNullable<ListProjectsQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
    expand: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createListProjects200(): NonNullable<ListProjects200> {
  return { data: faker.helpers.arrayElements([createProject()]) as any };
}

/**
 * @description OK
 */
export function createListProjectsQueryResponse(): NonNullable<ListProjectsQueryResponse> {
  return { data: faker.helpers.arrayElements([createProject()]) as any };
}
