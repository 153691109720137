import { faker } from "@faker-js/faker";

import type {
  ListThemisCustomAuditsForThemisModule200,
  ListThemisCustomAuditsForThemisModulePathParams,
  ListThemisCustomAuditsForThemisModuleQueryParams,
  ListThemisCustomAuditsForThemisModuleQueryResponse,
} from "../models/ListThemisCustomAuditsForThemisModule";
import { createAudit } from "./createAudit";
import { createPaginatedMeta } from "./createPaginatedMeta";
import { createRecordType } from "./createRecordType";

export function createListThemisCustomAuditsForThemisModulePathParams(): NonNullable<ListThemisCustomAuditsForThemisModulePathParams> {
  return { company_id: faker.number.int(), record_type: createRecordType() };
}

export function createListThemisCustomAuditsForThemisModuleQueryParams(): NonNullable<ListThemisCustomAuditsForThemisModuleQueryParams> {
  return {
    workspace_id: faker.number.int(),
    action_name: faker.string.alpha(),
    offset: faker.number.int(),
    limit: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createListThemisCustomAuditsForThemisModule200(): NonNullable<ListThemisCustomAuditsForThemisModule200> {
  return {
    data: faker.helpers.arrayElements([createAudit()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createListThemisCustomAuditsForThemisModuleQueryResponse(): NonNullable<ListThemisCustomAuditsForThemisModuleQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createAudit()]) as any,
    meta: createPaginatedMeta(),
  };
}
