import { http } from "msw";

import { createListOfAccountDocumentsQueryResponse } from "../createListOfAccountDocuments";

export const listOfAccountDocumentsHandler = http.get(
  "*/accounts/:account_id/documents",
  function handler(info) {
    return new Response(
      JSON.stringify(createListOfAccountDocumentsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
