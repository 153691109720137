import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import PublishFlow from "../shared/PublishFlow";
import ReviewerApproveButton from "../shared/ReviewerApproveButton";
import Unlock from "../shared/Unlock";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  showOnlyApprove?: boolean;
}

function QALinkedDocumentsContextMenu({
  recordVersion,
  showOnlyApprove,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("menu");

  // Variables
  const { tableName } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const recordVersionID = recordVersion.id;
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion.data,
    // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
    mainStore.linkedDocuments.fields,
  );
  const isPendingFinalization = status === "ready_to_finalize";
  const isPublished = status === "published";

  // Effects
  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
    }
  }, [showPopup]);

  // Helper functions
  const onApprove = () => {
    mainStore.linkedDocuments.approve(recordVersionID);
  };

  const onUnapprove = () => {
    mainStore.linkedDocuments.unapprove(recordVersionID);
  };

  const onUnlock = () => {
    mainStore.linkedDocuments.unlock(recordVersionID);
  };

  // Rendering
  const handleDeleteRow = () => {
    if (recordVersionID) {
      mainStore.linkedDocuments.delete(recordVersionID);
    }
  };

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="documents-context-menu-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          showPopup,
      })}
      disabled={!canDeleteRecords}
    />
  );

  const deleteClassNames = classNames({ disabled: isPublished });

  const menuPopup = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="documents-context-menu-dropdown"
    >
      <ul>
        <li
          data-testid="documents-context-menu-dropdown-delete"
          onClick={() => !isPublished && setViewPopup("delete")}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: string; "data-testid": string; o... Remove this comment to see the full error message
          disabled={isPublished}
          className={deleteClassNames}
        >
          Delete
        </li>
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Document"
      content="Are you sure you want to delete this document? Actions are not reversible."
      handleConfirm={handleDeleteRow}
      handleReject={() => setShowPopup(false)}
    />
  );

  const classes = classNames("list-points", {
    "without-background im-action-bar-buttons": showOnlyApprove,
  });

  return (
    <div className={classes} data-testid="documents-context-menu">
      {!isPublished && !isPendingFinalization && (
        <ReviewerApproveButton
          approversIDs={
            mainStore.avroSchemas.valueForField(
              "approvers",
              recordVersion?.data,
              // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
              mainStore.linkedDocuments.fields,
            ) || []
          }
          moduleIdentifier="linked_documents"
          onApprove={onApprove}
          onUnapprove={onUnapprove}
          reviews={recordVersion?.reviews || []}
        />
      )}

      {isPendingFinalization && (
        <PublishFlow
          recordVersionID={recordVersion.id}
          // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
          tableName={tableName}
          moduleStore={mainStore.linkedDocuments}
          moduleIdentifier="linked_documents"
        />
      )}

      {isPublished && (
        <Unlock
          onYes={onUnlock}
          buttonText="Unlock"
          showIcon={false}
          canEveryoneUnlock
          moduleIdentifier="linked_documents"
        />
      )}

      <Popup
        position="bottom right"
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => renderTrigger}
        open={showPopup}
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
        disabled={!canDeleteRecords}
        keepTooltipInside
      >
        {viewPopup === "menu" && menuPopup}

        {viewPopup === "delete" && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

QALinkedDocumentsContextMenu.defaultProps = {
  showOnlyApprove: false,
};

export default observer(QALinkedDocumentsContextMenu);
