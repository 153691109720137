import { http } from "msw";

import { createLatestRecordVersionQueryResponse } from "../createLatestRecordVersion";

export const latestRecordVersionHandler = http.get(
  "*/record_versions/latest_record_version/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createLatestRecordVersionQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
