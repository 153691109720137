import { LinkButton, SimpleTooltip, Table } from "@themis/ui";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";

import type { Account } from "@/api";
import { Typography } from "@/components/Elements";

import {
  InfoPreviewActions,
  InfoPreviewFooter,
  InfoPreviewHeader,
  InfoPreviewSection,
  InfoPreviewTitle,
  NUM_PREVIEW_ITEMS,
} from "../../../../misc/info-preview-section/info-preview-section";
import { ALL_DOCUMENTS_PATH } from "../../../accounts-routes";
import { useDocumentsTableColumns } from "../../../config/use-documents-table-columns/use-documents-table-columns";
import { DocumentsActions } from "../../documents/documents-actions/documents-actions";

export function DocumentsPreview({ account }: { account?: Account }) {
  const { formatMessage } = useIntl();
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();
  const columns = useDocumentsTableColumns();

  const previewDocuments = account?.documents?.slice(0, NUM_PREVIEW_ITEMS);

  return (
    <InfoPreviewSection>
      <InfoPreviewHeader>
        <InfoPreviewTitle>
          {formatMessage({
            defaultMessage: "Attachments",
            description: "Attachments section title",
          })}
        </InfoPreviewTitle>
        <SimpleTooltip
          disabled={!account}
          tooltipTriggerProps={{ asChild: true }}
          title={formatMessage({
            defaultMessage: "Available after record saved",
          })}
        >
          <InfoPreviewActions>
            <DocumentsActions disabled={!account} />
          </InfoPreviewActions>
        </SimpleTooltip>
      </InfoPreviewHeader>
      {account && previewDocuments && previewDocuments.length > 0 && (
        <>
          <Table
            data-testid="documents-table"
            columns={columns}
            enablePinning={false}
            rows={previewDocuments}
            width="100%"
          />
          <InfoPreviewFooter>
            <Typography type="h4">
              {formatMessage(
                {
                  defaultMessage:
                    "Showing {numberOfShown} of {total} attachments",
                },
                {
                  numberOfShown: previewDocuments?.length ?? 0,
                  total: account?.documents?.length ?? 0,
                },
              )}
            </Typography>
            {(account?.documents?.length ?? 0) >= NUM_PREVIEW_ITEMS && (
              <LinkButton
                color="tertiary"
                size="sm"
                to={generatePath(ALL_DOCUMENTS_PATH, {
                  workspace_id,
                  accountId,
                })}
              >
                {formatMessage({ defaultMessage: "View all" })}
              </LinkButton>
            )}
          </InfoPreviewFooter>
        </>
      )}
    </InfoPreviewSection>
  );
}
