import type { ColDef } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { useMemo } from "react";

import type { Contact } from "@/api/gen/models";

import { ContactsActionsCell } from "../components/ContactsTable/ContactsActionCell";

interface UseContactsColumnsParams {
  contactsRoute: string;
}

export function useContactsColumns({
  contactsRoute,
}: UseContactsColumnsParams) {
  return useMemo(
    (): Array<ColDef<Contact>> => [
      {
        headerName: "First Name",
        field: "first_name",
        minWidth: 200,
        cellRenderer: "identifierCellRenderer",
        cellRendererParams: (props: CustomCellRendererProps) => {
          const accountId = props.data.id;
          return {
            to: `${contactsRoute}/${accountId}`,
            ...props,
          };
        },
      },
      {
        field: "middle_name",
        headerName: "Middle Name",
        cellRenderer: "textRenderCell",
        minWidth: 200,
      },
      {
        field: "last_name",
        headerName: "Last Name",
        cellRenderer: "textRenderCell",
        minWidth: 200,
      },
      {
        field: "phone",
        headerName: "Phone",
        cellRenderer: "textRenderCell",
        minWidth: 200,
      },
      {
        field: "email",
        headerName: "Email",
        cellRenderer: "textRenderCell",
        minWidth: 200,
      },
      {
        headerName: "",
        minWidth: 40,
        width: 40,
        sortable: false,
        resizable: false,
        pinned: "right" as const,
        cellStyle: () => ({ justifyContent: "center" }),
        cellRenderer: ({ data }: CustomCellRendererProps<Contact, Contact>) =>
          data ? <ContactsActionsCell contactId={data.id} /> : null,
      },
    ],
    [contactsRoute],
  );
}
