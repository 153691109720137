import { Breadcrumbs } from "@themis/ui";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";

import { useAccount } from "@/api/queries/accounts";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";

import {
  ACCOUNT_DETAILS_PATH,
  type ACCOUNT_ID_PARAM,
  ACCOUNT_ROUTES,
  ACCOUNTS_LIST_PATH,
} from "../../accounts-routes";
import { DocumentsActions } from "./documents-actions/documents-actions";
import { DocumentsTable } from "./documents-table/documents-table";

export const Documents = () => {
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
  }>();

  const {
    data: accountData,
    isPending: isAccountPending,
    isError: isAccountError,
  } = useAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
    queryParams: { expand: "documents" },
  });

  const { formatMessage } = useIntl();

  if (isAccountPending) {
    return (
      <PageContent>
        <Loading loadingLayout="small-table" />
      </PageContent>
    );
  }

  if (isAccountError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath(`${ACCOUNT_ROUTES.details}`, {
              accountId,
            }),
          }}
        >
          {formatMessage({ defaultMessage: "Could not load account details" })}
        </ErrorContainer>
      </PageContent>
    );
  }

  return (
    <PageLayout>
      <Header
        title={
          <Breadcrumbs
            breadcrumbItems={[
              {
                label: "Accounts",
                to: generatePath(ACCOUNTS_LIST_PATH, {
                  workspace_id,
                }),
              },
              {
                label: accountData.data.name,
                to: generatePath(ACCOUNT_DETAILS_PATH, {
                  workspace_id,
                  accountId,
                }),
              },
              {
                label: formatMessage({
                  defaultMessage: "Account Info - Attachments",
                }),
              },
            ]}
          />
        }
      />
      <PageContent>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
              {formatMessage({ defaultMessage: "Attachments" })}
            </h2>
            <DocumentsActions />
          </div>
          <DocumentsTable documents={accountData.data.documents} />
        </div>
      </PageContent>
    </PageLayout>
  );
};
