import { faker } from "@faker-js/faker";

import type { ModuleAssessment } from "../models/ModuleAssessment";
import { createControlEffectivenessRating } from "./createControlEffectivenessRating";
import { createThemisDocument } from "./createThemisDocument";
import { createUser } from "./createUser";

export function createModuleAssessment(
  data: NonNullable<Partial<ModuleAssessment>> = {},
): NonNullable<ModuleAssessment> {
  return {
    ...{
      id: faker.number.int(),
      status: faker.helpers.arrayElement<any>([
        "new",
        "in_progress",
        "completed",
      ]),
      compliance_status: faker.helpers.arrayElement<any>([
        "Compliant",
        "Non_Compliant",
      ]),
      summary: faker.string.alpha(),
      due_date: faker.string.alpha(),
      assessment_date: faker.string.alpha(),
      control_effectiveness_rating: createControlEffectivenessRating(),
      evidences: faker.helpers.arrayElements([createThemisDocument()]) as any,
      assignee: Object.assign({}, createUser()),
      assignee_id: faker.number.int(),
      record_id: faker.number.int(),
      module_workspace_id: faker.number.int(),
      record_version_id: faker.number.int(),
      record_name: faker.string.alpha(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
    },
    ...data,
  };
}
