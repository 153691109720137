import { Table } from "@themis/ui";

import type { Document } from "@/api";

import { useDocumentsTableColumns } from "../../../config/use-documents-table-columns/use-documents-table-columns";

interface DocumentsTableProps {
  documents: Document[] | undefined;
}

export const DocumentsTable = ({ documents = [] }: DocumentsTableProps) => {
  const columns = useDocumentsTableColumns();

  return (
    <Table
      data-testid="documents-table"
      columns={columns}
      rows={documents}
      width="100%"
    />
  );
};
