import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteRecordLinkMutationResponse,
  DeleteRecordLinkPathParams,
} from "../../models/DeleteRecordLink";

/**
 * @description Delete a record link
 * @summary Delete a record link
 * @link /record_links/:id
 */
export async function deleteRecordLink(
  id: DeleteRecordLinkPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteRecordLinkMutationResponse>["data"]> {
  const res = await client<DeleteRecordLinkMutationResponse>({
    method: "delete",
    url: `/record_links/${id}`,
    ...options,
  });
  return res.data;
}
