import type { UseQueryOptions } from "@tanstack/react-query";
import { skipToken, useQuery } from "@tanstack/react-query";
import { millisecondsInHour } from "date-fns";
import { keyBy } from "lodash";

import { listThemisRecordFields } from "../../gen/axios/fieldsController";
import type {
  ListThemisRecordFieldsQueryResponse,
  RecordType,
} from "../../gen/models";

interface UseFieldsParams {
  companyId: number | undefined;
  recordType: RecordType;
}

export const getFieldsQueryKey = (params?: UseFieldsParams) =>
  ["fields", params?.companyId, params?.recordType].filter(Boolean);

export function useFields<
  TError = Error,
  TData = ListThemisRecordFieldsQueryResponse["data"],
>(
  { companyId, recordType }: UseFieldsParams,
  options?: Partial<
    UseQueryOptions<ListThemisRecordFieldsQueryResponse["data"], TError, TData>
  >,
) {
  return useQuery({
    queryKey: getFieldsQueryKey({ companyId, recordType }),
    queryFn:
      companyId && recordType
        ? async () => {
            const response = await listThemisRecordFields(
              companyId,
              recordType,
            );

            return response.data;
          }
        : skipToken,
    staleTime: millisecondsInHour,
    ...options,
  });
}

export const selectField =
  (name: string) => (data: ListThemisRecordFieldsQueryResponse["data"]) =>
    data.find((field) => field.name === name);

export const selectFieldOptions =
  (name: string) => (data: ListThemisRecordFieldsQueryResponse["data"]) => {
    const configuration = data.find((field) => field.name === name);
    return keyBy(configuration?.options, "key");
  };
