import { http } from "msw";

import { createDeleteAttestationListMutationResponse } from "../createDeleteAttestationList";

export const deleteAttestationListHandler = http.delete(
  "*/attestation_lists/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteAttestationListMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
