import { faker } from "@faker-js/faker";

import type { TaskReference } from "../models/TaskReference";

export function createTaskReference(
  data: NonNullable<Partial<TaskReference>> = {},
): NonNullable<TaskReference> {
  return {
    ...{
      id: faker.number.int(),
      name: faker.string.alpha(),
      status: faker.helpers.arrayElement<any>([
        "not_started",
        "in_progress",
        "done",
      ]),
      assignee_id: faker.number.int(),
      task_section_id: faker.number.int(),
      visibility: faker.helpers.arrayElement<any>([
        "internal",
        "public",
        "private",
      ]),
      comments_count: faker.number.int(),
      subtasks: faker.helpers.arrayElements([
        faker.helpers.arrayElement<any>([{}, createTaskReference()]),
      ]) as any,
    },
    ...data,
  };
}
