import { faker } from "@faker-js/faker";

import type {
  ShowFinding200,
  ShowFindingPathParams,
  ShowFindingQueryResponse,
} from "../models/ShowFinding";
import { createFinding } from "./createFinding";

export function createShowFindingPathParams(): NonNullable<ShowFindingPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createShowFinding200(): NonNullable<ShowFinding200> {
  return { data: createFinding() };
}

/**
 * @description OK
 */
export function createShowFindingQueryResponse(): NonNullable<ShowFindingQueryResponse> {
  return { data: createFinding() };
}
