import "../assessment-page.scss";

import { observer } from "mobx-react";
import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment";
import ExportBulk from "@/components/dashboard/ExportBulk";
import { Flex, Typography } from "@/components/Elements";
import Loading from "@/components/Loading";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import { useQuestionnaireData } from "@/features/risk-assessment";
import { QuestionnaireStatus } from "@/features/risk-assessment/types/questionnaire";

import CWCustomerQuestionnaireDetailTabs from "../../components/CWCustomerQuestionnaireDetailTabs";
import { ModuleContentWrapper } from "../../components/ModuleContentWrapper/ModuleContentWrapper";
import ModuleHeader from "../../components/ModuleHeader/ModuleHeader";
import PendingQuestionnaireList from "../../components/Questionnaires/QuestionnaireList/PendingQuestionnaireList";
import SubmittedQuestionnaireList from "../../components/Questionnaires/QuestionnaireList/SubmittedQuestionnaireList";
import { routes } from "../../routes";

function CustomerQuestionnaireDetailPage() {
  const history = useHistory();
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const activeWorkspaceId: number | undefined =
    mainStore.context.activeWorkspace?.id;
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const { questionnaire, fetchQuestionnaireData } =
    useQuestionnaireData(activeWorkspaceId);

  if (!questionnaire || !activeWorkspaceId) {
    return <Loading loadingLayout="small-table" />;
  }

  const riskMethodology = (mainStore.riskMethodologies.list || []).find(
    (rm) => rm.id === questionnaire.risk_methodology_id,
  )!;

  const downloadQuestionnaire = () => {
    if (workspaceID) {
      return QuestionnaireAPI.downloadQuestionnaire(parseInt(questionnaireId));
    }
  };

  return (
    <DashboardContent>
      <DashboardHeader
        title={questionnaire?.name || "Questionnaire"}
        onBackClick={() =>
          history.push(
            generatePath(routes.DASHBOARD_PATH, {
              workspace_id: Number(workspaceID),
            }),
          )
        }
      />
      <DashboardContentWrapper>
        <ModuleHeader>
          <CWCustomerQuestionnaireDetailTabs
            questionnaireId={Number(questionnaireId)}
          />
          <ExportBulk
            customFileExportFunction={downloadQuestionnaire}
            isExportFilesEnabled
            isExportSheetEnabled={false}
          />
        </ModuleHeader>
        <ModuleContentWrapper>
          <div className="assessment-page">
            <Flex column rowGap={20}>
              <div>
                <Typography
                  label="Risk Methodology"
                  color="generalMidnightDark"
                  size="lg"
                  weight="semiBold"
                  className="assessment-page__header"
                />
                {riskMethodology.name}
              </div>
            </Flex>
            {(questionnaire.status === QuestionnaireStatus.NOT_STARTED ||
              questionnaire.status === QuestionnaireStatus.INCOMPLETE) && (
              <div>
                <Typography
                  label="Pending Questionnaire"
                  color="generalMidnightDark"
                  size="lg"
                  weight="semiBold"
                  className="assessment-page__header"
                />
                <PendingQuestionnaireList
                  questionnaires={[questionnaire]}
                  onRefreshQuestionnaire={fetchQuestionnaireData}
                />
              </div>
            )}
            {(
              [
                QuestionnaireStatus.READY_FOR_REVIEW,
                QuestionnaireStatus.IN_REVIEW,
                QuestionnaireStatus.FINALIZED,
              ] as string[]
            ).includes(questionnaire.status) && (
              <div>
                <Typography
                  label="Submitted Questionnaire"
                  color="generalMidnightDark"
                  size="lg"
                  weight="semiBold"
                  className="assessment-page__header"
                />
                <SubmittedQuestionnaireList
                  questionnaires={[questionnaire]}
                  onRefreshQuestionnaire={fetchQuestionnaireData}
                />
              </div>
            )}
          </div>
        </ModuleContentWrapper>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(CustomerQuestionnaireDetailPage);
