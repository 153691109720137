import { http } from "msw";

import { createListThemisRecordFieldsQueryResponse } from "../createListThemisRecordFields";

export const listThemisRecordFieldsHandler = http.get(
  "*/companies/:company_id/:record_type/fields",
  function handler(info) {
    return new Response(
      JSON.stringify(createListThemisRecordFieldsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
