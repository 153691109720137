import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { PiPlusBold } from "react-icons/pi";
import { useHistory, useParams } from "react-router-dom";

import DetailNav from "@/components/detailView/DetailNav";
import { useMainStore } from "@/contexts/Store";

import ModuleTableColumn from "../shared/ModuleTableColumn";
import UsersCircle from "../shared/UsersCircle";
import Table from "../Table";
import AuditDetailViewDocumentsActions from "./AuditDetailViewDocumentsActions";
import AuditFileSelect from "./AuditFileSelect";

const FIELDS = [
  {
    id: 1,
    width: 200,
    name: "name",
    display_name: "Name",
    is_custom_field: false,
    data_type: "com.askthemis.types.v1.text",
  },
  {
    id: 2,
    width: 400,
    name: "document",
    display_name: "Document",
    is_custom_field: false,
    data_type: "com.askthemis.types.v1.attachment",
  },
  {
    id: 3,
    width: 200,
    name: "owner",
    display_name: "Owner",
    is_custom_field: false,
    data_type: "com.askthemis.types.v1.tag_user",
  },
  {
    id: 4,
    width: 200,
    name: "upload_date",
    display_name: "Upload Date",
    is_custom_field: false,
    data_type: "com.askthemis.types.v1.date",
  },
];

function AuditDetailViewDocuments() {
  // Import Mobx stores
  const mainStore = useMainStore();
  const history = useHistory();

  // Constants
  const { workspace_id, record_version_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
  }>();
  const recordVersionID = Number(record_version_id);
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const { workspaceID } = mainStore.context;
  const { users } = mainStore.users;
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  useEffect(() => {
    if (recordVersion) {
      return;
    }

    mainStore.themisAudits.index({ workspaceID: Number(workspace_id) });
  }, [recordVersion]);

  // Elements
  const renderColumns = FIELDS.map((field) => (
    <ModuleTableColumn key={field.id} field={field} />
  ));

  const renderRows = () =>
    // @ts-expect-error TS(2339) FIXME: Property 'documents' does not exist on type 'Recor... Remove this comment to see the full error message
    recordVersion?.record?.documents?.map((document) => (
      <div key={document.id} className="list-table">
        <ul>
          <div className="list-table-wrap">
            <div className="list-table-block">
              <li style={{ width: 200 }}>
                <div className="cell-content">{document.name}</div>
              </li>
              <li style={{ width: 400 }}>
                <div className="cell-content">
                  <AuditFileSelect
                    isInTableView
                    fieldName="documents"
                    document={document}
                    recordVersionID={recordVersionID}
                    attachmentDownloadText="Download Attachment"
                  />
                </div>
              </li>
              <li style={{ width: 200 }}>
                <div className="cell-content">
                  <UsersCircle
                    user={users?.find((item) => item.id === document.user_id)}
                  />
                </div>
              </li>
              <li style={{ width: 200 }}>
                <div className="cell-content">{document.upload_date}</div>
              </li>
              <span className="stretch-cell" />
            </div>
            <AuditDetailViewDocumentsActions
              recordVersionID={recordVersionID}
              documentID={document.id}
            />
          </div>
        </ul>
      </div>
    ));
  return (
    <Table>
      <div className="settings-wrap company-users-settings-container detail-view-documents-wrap">
        <DetailNav
          moduleIdentifier="audits"
          recordVersionID={recordVersionID}
        />
        <div className="settings-content-wrap">
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="list-title-table-wrap">
                  {renderColumns}
                  <span className="stretch-cell" />
                </div>
                <div className="list-plus">
                  <a href="#" />
                </div>
              </div>
            </ul>
          </div>

          {renderRows()}
          {hasModuleWriteAccess && (
            <div
              className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
              onClick={() =>
                history.push(
                  `/workspaces/${workspaceID}/modules/audits/${recordVersionID}/documents/create`,
                )
              }
              data-testid="add-new-record-button"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">Add Document...</div>
            </div>
          )}
        </div>
      </div>
    </Table>
  );
}

export default observer(AuditDetailViewDocuments);
