import { faker } from "@faker-js/faker";

import type { Section } from "../models/Section";

export function createSection(
  data: NonNullable<Partial<Section>> = {},
): NonNullable<Section> {
  return {
    ...{
      id: faker.number.int(),
      name: faker.string.alpha(),
      company_id: faker.number.int(),
      workspace_id: faker.number.int(),
      parent_themis_record_id: faker.number.int(),
      themis_record_type: faker.string.alpha(),
      percentage_complete: faker.number.int(),
    },
    ...data,
  };
}
