import { http } from "msw";

import { createUpdateFindingStatusCloseMutationResponse } from "../createUpdateFindingStatusClose";

export const updateFindingStatusCloseHandler = http.put(
  "*/findings/:finding_id/status/close",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateFindingStatusCloseMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
