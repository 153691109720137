import type { PropsWithChildren } from "react";
import React from "react";

export const NUM_PREVIEW_ITEMS = 10;

export const InfoPreviewActions = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren
>(({ children, ...props }, ref) => (
  <div className="tw-flex tw-items-center tw-gap-1" ref={ref} {...props}>
    {children}
  </div>
));
InfoPreviewActions.displayName = "ActionsContainer";

export function InfoPreviewHeader({ children }: PropsWithChildren) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
      {children}
    </div>
  );
}

export function InfoPreviewTitle({ children }: PropsWithChildren) {
  return (
    <h2 className="tw-flex tw-items-baseline tw-gap-2 tw-text-lg tw-font-semibold tw-text-neutral-500">
      {children}
    </h2>
  );
}

export function InfoPreviewFooter({ children, ...props }: PropsWithChildren) {
  return (
    <div
      className="tw-flex tw-w-full tw-items-center tw-justify-between"
      {...props}
    >
      {children}
    </div>
  );
}

export function InfoPreviewSection({ children }: PropsWithChildren) {
  return (
    <section className="tw-flex tw-flex-col tw-items-start tw-gap-2">
      {children}
    </section>
  );
}
