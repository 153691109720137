import type {
  ApiAuthCreate201,
  ApiAuthCreateMutationRequest,
  ApiAuthCreateMutationResponse,
} from "../models/ApiAuthCreate";
import { createApiAuth } from "./createApiAuth";
import { createCreateApiAuthRequest } from "./createCreateApiAuthRequest";

/**
 * @description OK
 */
export function createApiAuthCreate201(): NonNullable<ApiAuthCreate201> {
  return { data: createApiAuth() };
}

export function createApiAuthCreateMutationRequest(): NonNullable<ApiAuthCreateMutationRequest> {
  return { data: createCreateApiAuthRequest() };
}

/**
 * @description OK
 */
export function createApiAuthCreateMutationResponse(): NonNullable<ApiAuthCreateMutationResponse> {
  return { data: createApiAuth() };
}
