import { http } from "msw";

import { createDeleteTaskSectionMutationResponse } from "../createDeleteTaskSection";

export const deleteTaskSectionHandler = http.delete(
  "*/task_sections/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteTaskSectionMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
