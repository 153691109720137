import React from "react";

import type { Assignment } from "@/features/risk-assessment/types";

export const CreateFollowUpSidebarContext = React.createContext<{
  openSidebar: (
    questionID: number,
    questionText?: string,
    questionOrder?: string,
    questionReviewers?: Assignment[],
  ) => void;
  closeSidebar: () => void;
}>(undefined!);

export const useFollowUpSidebar = () =>
  React.useContext(CreateFollowUpSidebarContext);
