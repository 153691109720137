import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  createAccountDocument,
  deleteDocument,
  listOfAccountDocuments,
} from "@/api/gen/axios/documentsController";
import { type CreateAccountDocumentMutationRequest } from "@/api/gen/models";

import { accountsQueryKey } from "./accounts/accounts";

export const DOCUMENTS_QUERY_KEY = "documents";

export function useAccountDocuments(accountId: number) {
  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, accountId],
    queryFn: accountId ? () => listOfAccountDocuments(accountId) : skipToken,
  });
}

export function useCreateDocument({
  accountId,
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateAccountDocumentMutationRequest) =>
      createAccountDocument(accountId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEY, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: [accountsQueryKey, workspaceId, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: accountsQueryKey.details(accountId),
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteDocument({
  accountId,
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (documentId: number) => deleteDocument(documentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEY, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: [accountsQueryKey, workspaceId, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: accountsQueryKey.details(Number(accountId)),
      });
      onSuccess();
    },
    onError,
  });
}
