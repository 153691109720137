import type { DropdownOption } from "@/components/Elements/Dropdown/Dropdown";
import type { THEMIS_MINITAG_THEMES } from "@/config/theme";
import type { RiskRating } from "@/features/risk-assessment/types";
import {
  QuestionnaireStatus,
  QuestionType,
} from "@/features/risk-assessment/types";
import { createNewQuestion } from "@/stores/helpers/RiskAssessmentHelpers";

import type { SelectableQuestion } from "../components/Questionnaires/QuestionnaireBuilderPanel/selectable-questions/SelectableQuestionTypes";

export const RISK_METHODOLOGY_RISK_AREA_HEADERS = [
  {
    id: 1,
    width: 200,
    data_type: "com.askthemis.types.v1.text",
    display_name: "Risk Area",
    name: "name",
    is_user_editable: true,
  },
  {
    id: 2,
    width: 400,
    data_type: "com.askthemis.types.v1.text",
    display_name: "Definition",
    name: "definition",
    is_user_editable: true,
  },
];

export const ASSESSMENT_TEMPLATE_HEADERS = [
  {
    id: 1,
    width: 300,
    data_type: "com.askthemis.types.v1.text",
    display_name: "Assessment Name",
    name: "name",
    is_user_editable: false,
  },
  {
    id: 2,
    width: 200,
    data_type: "com.askthemis.types.v1.computed",
    display_name: "Questionnaire Template",
    name: "questionnaire_template",
    is_user_editable: false,
  },
  {
    id: 3,
    width: 200,
    data_type: "com.askthemis.types.v1.computed",
    display_name: "Risk Methodology",
    name: "risk_methodology",
    is_user_editable: false,
  },
  {
    id: 4,
    width: 150,
    data_type: "com.askthemis.types.v1.computed",
    display_name: "Created Date",
    name: "created_date",
    is_user_editable: false,
  },
  {
    id: 5,
    width: 200,
    data_type: "com.askthemis.types.v1.computed",
    display_name: "Actions",
    name: "actions",
    is_user_editable: false,
  },
];

export const PARTNER_ASSESSMENT_TEMPLATE_HEADERS = [
  {
    id: 1,
    width: 120,
    data_type: "com.askthemis.types.v1.computed",
    display_name: "Status",
    name: "status",
    is_user_editable: false,
  },
  ...ASSESSMENT_TEMPLATE_HEADERS.filter(
    (field) => !["created_date"].includes(field.name),
  ).map((field, index) => ({ ...field, id: index + 2 })),
];

export const SELECTABLE_QUESTION_TYPES: SelectableQuestion[] = [
  {
    type: QuestionType.RADIO,
    icon: "radioChecked",
    name: "Multiple Choice",
    canAddNewOption: true,
    optionIcon: "radioUnchecked",
    questionDefaults: () => createNewQuestion(QuestionType.RADIO),
  },
  {
    type: QuestionType.YES_NO,
    icon: "radioChecked",
    name: "Yes / No",
    canAddNewOption: false,
    optionIcon: "radioUnchecked",
    questionDefaults: () => createNewQuestion(QuestionType.YES_NO),
  },
  {
    type: QuestionType.DOCUMENTS,
    icon: "document",
    name: "Attachment",
    canAddNewOption: false,
    questionDefaults: () => createNewQuestion(QuestionType.DOCUMENTS),
  },
  {
    type: QuestionType.TEXT,
    icon: "text",
    name: "Free Text",
    canAddNewOption: false,
    questionDefaults: () => createNewQuestion(QuestionType.TEXT),
  },
];

export const RISK_AREA_OPTIONS: DropdownOption[] = [
  {
    id: "Operational Risk",
    name: "Operational Risk",
  },
  {
    id: "Financial Risk",
    name: "Financial Risk",
  },
  {
    id: "Institutional Risk",
    name: "Institutional Risk",
  },
];

export const RISK_TYPE_OPTIONS: Array<
  DropdownOption & { theme: (typeof THEMIS_MINITAG_THEMES)[number] }
> = [
  {
    id: "inherent",
    name: "Inherent",
    theme: "purple",
  },
  {
    id: "control",
    name: "Control",
    theme: "cherry",
  },
];

export const QUESTION_WEIGHTS: Array<DropdownOption> = [
  {
    id: 1,
    name: "Weighting 1X",
  },
  {
    id: 2,
    name: "Weighting 2X",
  },
  {
    id: 3,
    name: "Weighting 3X",
  },
  {
    id: 4,
    name: "Weighting 4X",
  },
  {
    id: 5,
    name: "Weighting 5X",
  },
  {
    id: 6,
    name: "Weighting 6X",
  },
  {
    id: 7,
    name: "Weighting 7X",
  },
  {
    id: 8,
    name: "Weighting 8X",
  },
  {
    id: 9,
    name: "Weighting 9X",
  },
  {
    id: 10,
    name: "Weighting 10X",
  },
];

export const RISK_RATING_COLUMN_COLORS: string[] = [
  "#791779",
  "#EB2E4E",
  "#FF879B",
  "#FDB932",
  "#99D959",
  "#6D8CF9",
  "#6161C3",
];

export const RISK_RATING_COLORS: string[] = [
  "#EB2E4E",
  "#FF879B",
  "#FF9900",
  "#FDB932",
  "#66AA22",
  "#6D8CF9",
  "#6161C3",
];

const CREATE_RISK_METHODOLOGY_DEFAULT_LOWRANGE = 1;
const CREATE_RISK_METHODOLOGY_DEFAULT_HIGHRANGE = 4;
const CREATE_RISK_METHODOLOGY_DEFAULT_OVERALL_RISK_RATINGS: RiskRating[] = [
  {
    text: "Overall Risk 1",
    color: RISK_RATING_COLORS[0],
    lower_range: CREATE_RISK_METHODOLOGY_DEFAULT_LOWRANGE,
    higher_range: 2,
  },
  {
    text: "Overall Risk 2",
    color: RISK_RATING_COLORS[1],
    lower_range: 2,
    higher_range: CREATE_RISK_METHODOLOGY_DEFAULT_HIGHRANGE,
  },
];
const CREATE_RISK_METHODOLOGY_DEFAULT_INHERENT_RISK_RATINGS = [
  {
    text: "Inherent Risk 1",
    color: RISK_RATING_COLORS[0],
    lower_range: CREATE_RISK_METHODOLOGY_DEFAULT_LOWRANGE,
    higher_range: 2,
  },
  {
    text: "Inherent Risk 2",
    color: RISK_RATING_COLORS[1],
    lower_range: 2,
    higher_range: CREATE_RISK_METHODOLOGY_DEFAULT_HIGHRANGE,
  },
];
const CREATE_RISK_METHODOLOGY_DEFAULT_CONTROL_RISK_RATINGS = [
  {
    text: "Control Risk 1",
    color: RISK_RATING_COLORS[2],
    lower_range: CREATE_RISK_METHODOLOGY_DEFAULT_LOWRANGE,
    higher_range: 2,
  },

  {
    text: "Control Risk 2",
    color: RISK_RATING_COLORS[3],
    lower_range: 2,
    higher_range: CREATE_RISK_METHODOLOGY_DEFAULT_HIGHRANGE,
  },
];
const CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISKS = [
  { text: "Low", color: "#FF879B" },

  { text: "Medium", color: "#FF9900" },

  { text: "High", color: "#FDB932" },
];
const CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISK_MATRICES = [
  {
    control_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_CONTROL_RISK_RATINGS[0],
    inherent_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_INHERENT_RISK_RATINGS[0],
    residual_risk: CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISKS[0],
  },
  {
    control_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_CONTROL_RISK_RATINGS[1],
    inherent_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_INHERENT_RISK_RATINGS[0],
    residual_risk: CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISKS[0],
  },
  {
    control_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_CONTROL_RISK_RATINGS[0],
    inherent_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_INHERENT_RISK_RATINGS[1],
    residual_risk: CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISKS[0],
  },
  {
    control_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_CONTROL_RISK_RATINGS[1],
    inherent_risk_rating:
      CREATE_RISK_METHODOLOGY_DEFAULT_INHERENT_RISK_RATINGS[1],
    residual_risk: CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISKS[0],
  },
];

export const CREATE_RISK_METHODOLOGY_DEFAULTS = {
  name: "",
  lowRange: CREATE_RISK_METHODOLOGY_DEFAULT_LOWRANGE,
  highRange: CREATE_RISK_METHODOLOGY_DEFAULT_HIGHRANGE,
  overallRiskRatings: CREATE_RISK_METHODOLOGY_DEFAULT_OVERALL_RISK_RATINGS,
  inherentRiskRatings: CREATE_RISK_METHODOLOGY_DEFAULT_INHERENT_RISK_RATINGS,
  controlRiskRatings: CREATE_RISK_METHODOLOGY_DEFAULT_CONTROL_RISK_RATINGS,
  residualRisks: CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISKS,
  residualRiskMatrices: CREATE_RISK_METHODOLOGY_DEFAULT_RESIDUAL_RISK_MATRICES,
} as const;

export const RISK_ASSESSMENT_STATUS_DISPLAY: Record<
  string,
  { theme: (typeof THEMIS_MINITAG_THEMES)[number]; text: string; order: number }
> = {
  [QuestionnaireStatus.FINALIZED]: {
    text: "Completed",
    theme: "seaBlue",
    order: 1,
  },
  [QuestionnaireStatus.IN_REVIEW]: {
    text: "In Review",
    theme: "purple",
    order: 2,
  },
  [QuestionnaireStatus.READY_FOR_REVIEW]: {
    text: "Ready For Review",
    theme: "mint",
    order: 3,
  },
  [QuestionnaireStatus.INCOMPLETE]: {
    text: "In Progress",
    theme: "salmon",
    order: 4,
  },
  [QuestionnaireStatus.NOT_STARTED]: {
    text: "New",
    theme: "cherry",
    order: 5,
  },
};

export const REVIEWER = "reviewer";
export const RESPONDER = "responder";
export const CW_QUESTIONNAIRE_IN_REVIEW = "In Review";
export const CW_QUESTIONNAIRE_SUBMITTED = "Submitted";
export const CW_QUESTIONNAIRE_COMPLETED = "Completed";
