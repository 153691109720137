import { faker } from "@faker-js/faker";

import type {
  UpdateRiskRegisterGroups200,
  UpdateRiskRegisterGroups403,
  UpdateRiskRegisterGroupsMutationRequest,
  UpdateRiskRegisterGroupsMutationResponse,
  UpdateRiskRegisterGroupsPathParams,
} from "../models/UpdateRiskRegisterGroups";
import { createRiskRegisterGroup } from "./createRiskRegisterGroup";
import { createUpdateRiskRegisterGroup } from "./createUpdateRiskRegisterGroup";

export function createUpdateRiskRegisterGroupsPathParams(): NonNullable<UpdateRiskRegisterGroupsPathParams> {
  return { workspace_id: faker.number.int(), id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateRiskRegisterGroups200(): NonNullable<UpdateRiskRegisterGroups200> {
  return {
    data: faker.helpers.arrayElements([createRiskRegisterGroup()]) as any,
  };
}

/**
 * @description Forbidden
 */
export function createUpdateRiskRegisterGroups403(): NonNullable<UpdateRiskRegisterGroups403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createUpdateRiskRegisterGroupsMutationRequest(): NonNullable<UpdateRiskRegisterGroupsMutationRequest> {
  return createUpdateRiskRegisterGroup();
}

/**
 * @description OK
 */
export function createUpdateRiskRegisterGroupsMutationResponse(): NonNullable<UpdateRiskRegisterGroupsMutationResponse> {
  return {
    data: faker.helpers.arrayElements([createRiskRegisterGroup()]) as any,
  };
}
