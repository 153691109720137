import { http } from "msw";

import { createCreateTaskSectionMutationResponse } from "../createCreateTaskSection";

export const createTaskSectionHandler = http.post(
  "*/companies/:company_id/task_sections",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateTaskSectionMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
