import {
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import React from "react";
import { PiLinkSimpleBold } from "react-icons/pi";

import type { LinkedRecord } from "@/api";

export function FindingTitleCell({
  title,
  linkedRecords,
}: {
  title: string;
  linkedRecords: LinkedRecord[];
}) {
  return (
    <>
      <p className="tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-semibold">
        {title}
      </p>
      {!!linkedRecords.length && (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <IconButton
              Icon={PiLinkSimpleBold}
              size="lg"
              color="transparent"
              variant="vertical"
            />
          </TooltipTrigger>
          <TooltipContent
            align="center"
            side="right"
            className="tw-max-h-48 tw-overflow-y-auto tw-font-semibold"
          >
            <span className="tw-flex tw-pb-7">
              This is tied to the following records:
            </span>
            <ul>
              {linkedRecords.map((record) => (
                <li key={record.id}>• {record.global_id}</li>
              ))}
            </ul>
          </TooltipContent>
        </Tooltip>
      )}
    </>
  );
}
