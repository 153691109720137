import { faker } from "@faker-js/faker";

import type {
  ListNotifications200,
  ListNotificationsQueryParams,
  ListNotificationsQueryResponse,
} from "../models/ListNotifications";
import { createNotification } from "./createNotification";
import { createPaginatedMeta } from "./createPaginatedMeta";

export function createListNotificationsQueryParams(): NonNullable<ListNotificationsQueryParams> {
  return { page: faker.number.int() };
}

/**
 * @description A list of notifications
 */
export function createListNotifications200(): NonNullable<ListNotifications200> {
  return {
    notifications: faker.helpers.arrayElements([createNotification()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description A list of notifications
 */
export function createListNotificationsQueryResponse(): NonNullable<ListNotificationsQueryResponse> {
  return {
    notifications: faker.helpers.arrayElements([createNotification()]) as any,
    meta: createPaginatedMeta(),
  };
}
