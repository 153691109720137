import { http } from "msw";

import { createCreateRiskRegisterGroupMutationResponse } from "../createCreateRiskRegisterGroup";

export const createRiskRegisterGroupHandler = http.post(
  "*/workspaces/:workspace_id/risk_registers/risk_register_groups",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateRiskRegisterGroupMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
