import { debounce } from "lodash";
import React, { useRef, useState } from "react";
import { Popover, PopoverAnchor, PopoverContent } from "../../Popover/Popover";
import { checkTextOverflows } from "../../utils/dom";
function TextRenderCell(_a) {
    var value = _a.value, valueFormatted = _a.valueFormatted;
    var textRef = useRef(null);
    var _b = useState(false), isHovering = _b[0], setIsHovering = _b[1];
    var _c = useState(false), isOverflowing = _c[0], setIsOverflowing = _c[1];
    var measureTextElement = function (textElement) {
        setIsOverflowing(function () {
            if (textElement) {
                return checkTextOverflows(textElement);
            }
            return false;
        });
    };
    var debouncedHandleMouseEnter = debounce(function () {
        setIsHovering(true);
    }, 500);
    var handleOnMouseLeave = function () {
        debouncedHandleMouseEnter.cancel();
        setIsHovering(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: textRef, onMouseEnter: debouncedHandleMouseEnter, onMouseLeave: handleOnMouseLeave, className: "tw-flex tw-h-full tw-w-full tw-items-center tw-overflow-hidden" },
            React.createElement("div", { ref: measureTextElement, className: "tw-overflow-hidden tw-overflow-ellipsis tw-px-2 tw-font-sans tw-font-medium tw-text-neutral-500 read-only:tw-text-neutral-300" }, valueFormatted || value)),
        React.createElement(Popover, { open: isHovering && isOverflowing },
            React.createElement(PopoverAnchor, { virtualRef: textRef }),
            React.createElement(PopoverContent, { align: "center", side: "bottom", onEscapeKeyDown: function () { return setIsHovering(false); } },
                React.createElement("div", { className: "tw-max-w-[250px] tw-break-words tw-p-2 tw-text-sm tw-text-neutral-500" }, value || valueFormatted)))));
}
export default TextRenderCell;
