import { HeaderTabs } from "@themis/ui";
import React from "react";
import { generatePath, useParams } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";

import { PROJECT_DETAILS_PATH, PROJECT_TASKS_PATH } from "../routes";

function ProjectHeaderTabs({
  rightHeaderSlot,
}: {
  rightHeaderSlot?: React.ReactNode;
}) {
  const { workspace_id, project_id } = useParams<{
    workspace_id: string;
    project_id?: string;
  }>();
  const isNewProject = !Number.isInteger(Number(project_id));

  const TABS = [
    {
      name: "Project Info",
      key: "project_info",
      value: generatePath(PROJECT_DETAILS_PATH, {
        workspace_id,
        project_id: project_id || "",
      }),
    },
    {
      name: "Tasks",
      key: "tasks",
      value: generatePath(PROJECT_TASKS_PATH, {
        workspace_id,
        project_id: project_id || "",
      }),
      hasPermission: !isNewProject,
    },
  ];

  return (
    <div className="tw-flex tw-h-11 tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-pl-2 tw-pr-4">
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS)?.value || ""}
        isLink
      />
      {rightHeaderSlot && <div>{rightHeaderSlot}</div>}
    </div>
  );
}

export default ProjectHeaderTabs;
