import { http } from "msw";

import { createCreateAndFinalizeControlMappingsMutationResponse } from "../createCreateAndFinalizeControlMappings";

export const createAndFinalizeControlMappingsHandler = http.post(
  "*/control_mappings/create_and_finalize",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateAndFinalizeControlMappingsMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
