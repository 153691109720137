import { faker } from "@faker-js/faker";

import type {
  GetUsers200,
  GetUsers403,
  GetUsersPathParams,
  GetUsersQueryResponse,
} from "../models/GetUsers";
import { createUser } from "./createUser";

export function createGetUsersPathParams(): NonNullable<GetUsersPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetUsers200(): NonNullable<GetUsers200> {
  return { data: faker.helpers.arrayElements([createUser()]) as any };
}

/**
 * @description Forbidden
 */
export function createGetUsers403(): NonNullable<GetUsers403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createGetUsersQueryResponse(): NonNullable<GetUsersQueryResponse> {
  return { data: faker.helpers.arrayElements([createUser()]) as any };
}
