import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteDepartmentMutationResponse,
  DeleteDepartmentPathParams,
} from "../../models/DeleteDepartment";

/**
 * @description Delete department
 * @summary Delete department
 * @link /departments/:id
 */
export async function deleteDepartment(
  id: DeleteDepartmentPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteDepartmentMutationResponse>["data"]> {
  const res = await client<DeleteDepartmentMutationResponse>({
    method: "delete",
    url: `/departments/${id}`,
    ...options,
  });
  return res.data;
}
