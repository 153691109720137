import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateDepartmentMutationRequest,
  CreateDepartmentMutationResponse,
  CreateDepartmentPathParams,
} from "../../models/CreateDepartment";

/**
 * @description Create Department
 * @summary Create Department
 * @link /companies/:company_id/departments
 */
export async function createDepartment(
  companyId: CreateDepartmentPathParams["company_id"],
  data: CreateDepartmentMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateDepartmentMutationResponse>["data"]> {
  const res = await client<
    CreateDepartmentMutationResponse,
    CreateDepartmentMutationRequest
  >({
    method: "post",
    url: `/companies/${companyId}/departments`,
    data,
    ...options,
  });
  return res.data;
}
