import { http } from "msw";

import { createListOfAccountContactsQueryResponse } from "../createListOfAccountContacts";

export const listOfAccountContactsHandler = http.get(
  "*/accounts/:account_id/contacts",
  function handler(info) {
    return new Response(
      JSON.stringify(createListOfAccountContactsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
