import { FilePicker } from "@themis/ui";
import { useMemo } from "react";

import type { File, ThemisDocument } from "@/api/gen/models";

import type { DynamicCellProps } from "../../dynamic-cell";
import type { GetReferenceType } from "../../field-configuration";

type ReferenceType = GetReferenceType<"ThemisDocument">;

export const ThemisDocumentCell = ({
  value,
}: DynamicCellProps<ReferenceType>) => {
  const themisDocuments = useMemo(() => {
    if (value) {
      return Array.isArray(value) ? value : [value];
    }

    return [];
  }, [value]);

  const files = useMemo(() => {
    const _themisDocuments = themisDocuments.filter(
      (item) => !!item && !!item.file,
    ) as Array<NonNullable<ThemisDocument> & { file: NonNullable<File> }>;
    return _themisDocuments.map((themisDocument) => ({
      name: themisDocument.file.file_name,
      type: themisDocument.file.content_type,
      url: themisDocument.file.file_url,
    }));
  }, [themisDocuments]);

  return (
    <div>
      {files.map((file) => (
        <FilePicker
          key={file.url}
          file={file}
          className="tw-h-full tw-w-full tw-rounded-none tw-border-none group-hover/row:tw-bg-neutral-50"
          isOpen={false}
          readOnly
        />
      ))}
    </div>
  );
};
