import { useState } from "react";

const useOpenSections = () => {
  const [openedSections, setOpenedSections] = useState<
    Partial<Record<number | string, boolean>>
  >({});

  const handleToggleSection = (
    sectionTagId: number | string,
    isOpen?: boolean,
  ) => {
    setOpenedSections({
      ...openedSections,
      [sectionTagId]:
        isOpen !== undefined ? isOpen : !openedSections[sectionTagId],
    });
  };

  return { openedSections, setOpenedSections, handleToggleSection };
};

export default useOpenSections;
