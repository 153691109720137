import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import LegacyAuditTrail from "@/components/table/shared/LegacyAuditTrail";
import Table from "@/components/table/Table";

import { kriListUrl } from "../urlPaths";

const KRIAuditTrailPage = ({
  isRecordView,
  title,
}: {
  isRecordView: boolean;
  title: string;
}) => {
  const history = useHistory();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  return (
    <DashboardContent>
      <DashboardHeader
        title={title}
        onBackClick={() =>
          history.push(generatePath(kriListUrl, { workspace_id }))
        }
        LeftActionBar={<ViewModuleUsers />}
      />
      <DashboardContentWrapper>
        <Table>
          <LegacyAuditTrail isRecordView={isRecordView} />
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default KRIAuditTrailPage;
