import { faker } from "@faker-js/faker";

import type { Questionnaire } from "../models/Questionnaire";
import { createScoring } from "./createScoring";

export function createQuestionnaire(
  data: NonNullable<Partial<Questionnaire>> = {},
): NonNullable<Questionnaire> {
  return {
    ...{
      id: faker.number.int(),
      completed_at: faker.date.anytime().toISOString(),
      completion_percentage: faker.number.float(),
      control_questions_count: faker.number.int(),
      created_at: faker.date.anytime().toISOString(),
      due_date: faker.date.anytime().toISOString(),
      external_id: faker.string.alpha(),
      external_password: faker.string.alpha(),
      inherent_questions_count: faker.number.int(),
      is_internal: faker.datatype.boolean(),
      is_template: faker.datatype.boolean(),
      last_updated_at: faker.date.anytime().toISOString(),
      last_updated_by_user_id: faker.number.int(),
      name: faker.string.alpha(),
      record_version_id: faker.number.int(),
      responder_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
      reviewer_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
      risk_methodology_id: faker.number.int(),
      scoring: Object.assign({}, createScoring()),
      status: faker.string.alpha(),
      summary: faker.string.alpha(),
    },
    ...data,
  };
}
