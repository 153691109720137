import { faker } from "@faker-js/faker";

import type {
  LatestRecordVersion200,
  LatestRecordVersionPathParams,
  LatestRecordVersionQueryResponse,
} from "../models/LatestRecordVersion";
import { createShowRecordVersion } from "./createShowRecordVersion";

export function createLatestRecordVersionPathParams(): NonNullable<LatestRecordVersionPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLatestRecordVersion200(): NonNullable<LatestRecordVersion200> {
  return { data: createShowRecordVersion() };
}

/**
 * @description OK
 */
export function createLatestRecordVersionQueryResponse(): NonNullable<LatestRecordVersionQueryResponse> {
  return { data: createShowRecordVersion() };
}
