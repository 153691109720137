import React, { useEffect, useMemo, useState } from "react";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { cn } from "../../lib/utils";
import { PdfViewer } from "./PdfViewer";
var FILE_TYPES = {
    PDF: ["application/pdf"],
    IMAGE: [
        "image/png",
        "image/avif",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/webp",
    ],
    VIDEO: ["video/mp4", "video/ogg", "video/webm", "video/quicktime"],
};
var FileViewer = function (_a) {
    var file = _a.file, type = _a.type, _b = _a.onClose, onClose = _b === void 0 ? function () { } : _b, _c = _a.overlay, overlay = _c === void 0 ? true : _c;
    var _d = useState(), containerRef = _d[0], setContainerRef = _d[1];
    var observedContainer = useResizeObserver(containerRef);
    var maxWidth = (observedContainer === null || observedContainer === void 0 ? void 0 : observedContainer.contentRect.width)
        ? (observedContainer === null || observedContainer === void 0 ? void 0 : observedContainer.contentRect.width) * 0.8
        : 500;
    var handleOverlayClicked = function () {
        var closeFromOverlay = !FILE_TYPES.PDF.includes(type);
        if (!closeFromOverlay) {
            return;
        }
        onClose();
    };
    useEffect(function () {
        var handleKeyboardInput = function (event) {
            if (event.key === "Escape") {
                onClose();
            }
        };
        document.addEventListener("keydown", handleKeyboardInput, true);
        return function () {
            document.removeEventListener("keydown", handleKeyboardInput, true);
        };
    }, []);
    var viewerEl = useMemo(function () {
        if (FILE_TYPES.PDF.includes(type)) {
            return (React.createElement(PdfViewer, { pdf: file, pageHeight: observedContainer === null || observedContainer === void 0 ? void 0 : observedContainer.contentRect.height, onClose: onClose }));
        }
        if (FILE_TYPES.IMAGE.includes(type)) {
            var imageSrc = typeof file === "string" ? file : URL.createObjectURL(file);
            return (React.createElement("img", { src: imageSrc, style: {
                    maxWidth: maxWidth,
                } }));
        }
        if (FILE_TYPES.VIDEO.includes(type)) {
            var videoSrc = typeof file === "string" ? file : URL.createObjectURL(file);
            return (React.createElement("video", { src: videoSrc, style: {
                    maxWidth: maxWidth,
                }, controls: true }));
        }
        return React.createElement("div", { className: "tw-text-white" }, "Unsupported File Type");
    }, [file, type, containerRef, onClose, observedContainer]);
    return (React.createElement("div", { className: cn("tw-z-[102] tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-overflow-scroll", {
            "tw-fixed tw-right-0 tw-top-0 tw-bg-neutral-500 tw-bg-opacity-50": overlay,
        }), ref: setContainerRef, onClick: handleOverlayClicked }, viewerEl));
};
export { FileViewer };
