import { http } from "msw";

import { createMonitoringGroupPoliciesQueryResponse } from "../createMonitoringGroupPolicies";

export const monitoringGroupPoliciesHandler = http.get(
  "*/companies/:company_id/website_monitoring/monitoring_group_policies",
  function handler(info) {
    return new Response(
      JSON.stringify(createMonitoringGroupPoliciesQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
