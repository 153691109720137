import React from "react";

import logo from "@/images/logo.svg";

interface Props {
  showLogo?: boolean;
}

export const TermsAndPolicy = ({ showLogo = true }: Props) => (
  <div className="politics-wrap">
    {showLogo && (
      <a href="/" className="politics-logo">
        <img src={logo} alt="logo" />
      </a>
    )}
    <section>
      <h2>TERMS OF SERVICE</h2>
      <div className="politics-block">
        <p>
          Welcome to Themis! Our platform provides our customers with a holistic
          view of compliance through a single platform to manage policies,
          procedures, and controls. The platform integrates with the disparate
          suite of compliance tools that exist across a company in order to
          instill greater trust with clients, vendors, and regulators. Themis
          allows compliance teams to make faster and more informed decision with
          the help of data science and analytics.
        </p>
        <b>Introduction</b>
        <p>
          Who Can Use the Themis Platform <br />
          This website is operated and owned by Ask Themis, Inc. (“Themis”), a
          Delaware corporation. The following terms and conditions (the “Terms
          of Service”) govern your access to our websites, including but not
          limited to https://www.themis.com/ (the “Site”), and your use of our
          platform (the “Service”).
        </p>
        <p>Our users can be placed into two categories: </p>
        <ol>
          <li>We call general users of the Site “Site Visitors.”</li>
          <li>
            Individual users who are given access to the Service as part of a
            paid Themis subscription plan are called “Subscribers.” The specific
            Service features and functionalities which are available to
            Subscribers is determined by the specific terms between Themis and
            the organization, such as your employer or another entity or person,
            that entered into a separate agreement that governs the delivery,
            access, and use of the Service (the “Customer” and the “Customer
            Agreement”).
          </li>
        </ol>

        <br />

        <p>
          We refer to these three types of users collectively as “Users” or
          “you” for purposes of these Terms of Service. Regardless of what type
          of User you are, these Terms create a legal agreement directly between
          you and Themis. There are some portions of these Terms of Service
          which apply only to our Subscribers and not to Users who are only Site
          Visitors; those sections are labeled “Subscribers Only.”
        </p>
        <p>
          Please read these Terms of Service carefully as they provide important
          information to you. By accessing or using the Service and Site, you
          acknowledge and agree that you have read, understand, and agree to be
          bound by these Terms of Service. If you do not agree to these Terms of
          Service, you are not permitted to access or use the Service or Site.
        </p>
        <p>A few important highlights of these Terms of Service include:</p>
        <ol>
          <li>
            Limited Liabilities. You understand that we offer no warranties, and
            our liabilities are limited (See Section 6).
          </li>
          <li>
            Communication About New Features and Products. If you provide us
            with your email address or phone number (or activate your Account
            using a referred email address), you agree to receive, as
            applicable, emails, chats, calls, and text messages from us with
            information relating to your Account, new and existing features,
            product updates and improvements, company and industry news and
            events (See Section 10).
          </li>
          <li>
            Modification. You understand that we may change these Terms of
            Service or any portion of the Service at any time. Your continued
            use of the Service constitutes your acceptance of any modified Terms
            of Service (See Section 11).
          </li>
        </ol>

        <br />

        <b>1. Eligibility and Scope</b>
        <br />
        <ol>
          <li>
            Access to the Site and Service. To use the Site, the Service, or
            both, you represent and warrant to Themis that: (i) you are of legal
            age to form a binding contract; (ii) all registration information
            you submit is accurate, current, and complete; (iii) you will
            maintain the accuracy and completeness of such information; and (iv)
            if you are a Subscriber, you have been authorized by the applicable
            Customer to access the Service pursuant to a Customer Agreement. If
            you do not meet all of these requirements, you must not access or
            use the Service.
          </li>
        </ol>

        <b>2. Account Registration and Use (Subscribers Only)</b>
        <br />
        <ol>
          <li>
            Accounts and Account Administrators. To access the Service and Site,
            you must register for a Themis account (an “Account”) by creating a
            username and password. It is your responsibility to ensure that your
            password remains confidential and secure. By registering, you agree
            that you are fully responsible for all activities that occur under
            your username and password. We may assume that any communications we
            receive under your Account have been made by you. If you are a
            billing owner, an administrator, or if you have confirmed in writing
            that you have the authority to make decisions on behalf of a
            Customer (“Account Administrator”), you represent and warrant that
            you are authorized to make decisions on behalf of the Customer and
            agree that Themis is entitled to rely on your instructions.
          </li>
          <li>
            Unauthorized Account Use. You are responsible for notifying us at
            support@askthemis.com if you become aware of any unauthorized use of
            or access to your Account. You understand and agree that we may
            require you to provide information that may be used to confirm your
            identity and help ensure the security of your Account. Themis will
            not be liable for any loss, damages, liability, expenses, or
            attorneys’ fees that you may incur as a result of someone else using
            your credentials or your Account, either with or without your
            knowledge and/or authorization, and regardless of whether you have
            or have not advised us of such unauthorized use. You will be liable
            for losses, damages, liability, expenses, and attorneys’ fees
            incurred by Themis or a third party due to someone else using your
            Account. In the event that the Account Administrator or Customer
            loses access to an Account or otherwise requests information about
            an Account, Themis reserves the right to request from the Account
            Administrator or Customer any verification it deems necessary before
            restoring access to or providing information about such Account in
            its sole discretion.
          </li>
        </ol>

        <b>3. Our Proprietary Rights</b>
        <p>
          The Service and Site are owned and operated by Themis and contain
          materials (including all software, design, text, editorial materials,
          informational text, photographs, illustrations, audio clips, video
          clips, artwork and other graphic materials, and names, logos,
          trademarks and services marks) which are derived in whole or in part
          from materials supplied by Themis and its partners, as well as other
          sources, and are protected by United States copyright laws,
          international treaty provisions, trademarks, service marks and other
          intellectual property laws (“Themis Materials”). The Service, Site,
          Themis Materials (“Themis Property”) are also protected as a
          collective work or compilation under U.S. copyright and other law and
          treaties. You agree to abide by all applicable copyright and other
          laws, as well as any additional copyright notices or restrictions
          contained in the Service and Site. You acknowledge that the Service
          and Site have been developed, compiled, prepared, revised, selected,
          and arranged by Themis and others through the application of methods
          and standards of judgment developed and applied through the
          expenditure of substantial time, effort, and money and constitute
          valuable intellectual property of Themis and such others. You agree to
          protect the proprietary rights of Themis and to comply with all
          written requests made by Themis to protect its and others’
          contractual, statutory, and common law rights in the Themis Property.
          You agree to notify Themis immediately upon becoming aware of any
          claim that the Themis Property infringes upon any copyright,
          trademark, or other contractual, statutory, or common law rights. All
          present and future rights in and to trade secrets, patents,
          copyrights, trademarks, service marks, know-how, and other proprietary
          rights of any type under the laws of any governmental authority,
          domestic or foreign, including without limitation rights in and to all
          applications and registrations relating to the Themis Property shall,
          as between you and Themis, at all times be and remain the sole and
          exclusive property of Themis. Any unauthorized use of any Themis
          Property may violate copyright laws, trademark laws, the laws of
          privacy and publicity and communications regulations and statutes.
        </p>

        <b>4. Subscriber Content; Feedback.</b>
        <br />
        <ol>
          <li>
            User Content. The Service allows Users to upload documents, images,
            information, and other content (collectively, “User Content”) and to
            share that User Content with others. User Content is owned and
            controlled by the Customer as set forth in the introduction to these
            Terms of Service and the Customer Agreement. Themis maintains a
            limited, non-exclusive and non-transferrable (except in connection
            with the sale or transfer of its business) license to access, use,
            copy, reproduce, process, adapt, publish, transmit, host, and
            display User Content for the following purposes: (i) to maintain,
            provide and improve the Service; (ii) to prevent or address
            technical or security issues and resolve support requests; (iii) to
            investigate when we have a good faith belief, or have received a
            complaint alleging, that such User Content is in violation of the
            Customer Agreement or these Terms of Service; (iv) to comply with a
            valid legal subpoena, request, or other lawful process that meets
            the requirements of the Customer Agreement; and (v) as otherwise set
            forth in our Customer Agreement or as expressly permitted in writing
            by the Customer. To the extent there is any conflict between these
            Terms of Service and the Customer Agreement, the terms under the
            Customer Agreement shall control.
          </li>
          <li>
            Feedback. The Service and the Site may have certain features that
            allow you to submit comments, suggestions, ideas, information, and
            other materials (collectively, “Feedback”) to Themis and share such
            Feedback with other users, or the public. By submitting Feedback to
            us, you grant Themis a license to access, use, copy, reproduce,
            process, adapt, publish, transmit, host, and display that Feedback
            for any purpose (including in testimonials or other Themis marketing
            materials and where required to do so by law or in good faith to
            comply with legal process).
          </li>
          <li>
            Representations. You acknowledge and agree that you have all
            required rights to submit User Content and Feedback without
            violation of any third-party rights. You understand that Themis does
            not control, and is not responsible for, User Content or Feedback,
            and that by using the Service, you may be exposed to User Content or
            Feedback from other users that is offensive, indecent, inaccurate,
            misleading, or otherwise objectionable. Please also note that User
            Content and Feedback may contain typographical errors, other
            inadvertent errors, or inaccuracies. You agree that you will
            indemnify, defend, and hold harmless Themis for all claims resulting
            from User Content or Feedback you submit through the Service, the
            Site, or both. We reserve the right, at our own expense, to assume
            the exclusive defense and control of such disputes, and in any
            event, you will cooperate with us in asserting any available
            defenses.
          </li>
        </ol>

        <b>5. Access to the Site and Service; Use Restrictions</b>
        <br />
        <ol>
          <li>
            Limited Right of Access. Subject to your compliance with these Terms
            of Service, we grant you a limited, non-exclusive,
            non-sublicensable, non-transferable right to access and use the
            Service and Site only for your own internal use (or, for
            Subscribers, uses authorized by the Customer for Customer’s internal
            purposes), and only in a manner that complies with all legal
            requirements that apply to you or your use of the Service and Site,
            including our Privacy Policy, available at
            https://www.themis.com/privacy-policy. Themis may revoke this right
            at any time, in its sole discretion.
          </li>
          <li>
            Disruption of the Service. You may not: (a) access, tamper with, or
            use non-public areas of the Service and Site, Themis’s computer
            systems, or the technical delivery systems of Themis’s providers;
            (b) probe, scan, or test the vulnerability of any system or network
            or breach or circumvent any security or authentication measure; (c)
            access or search the Service and Site by any means other than
            Themis’s publicly supported interfaces (for example, “scraping”);
            (d) attempt to disrupt or overwhelm our infrastructure by
            intentionally imposing unreasonable requests or burdens on our
            resources (e.g., using “bots” or other automated systems to send
            requests to our servers at a rate beyond what could be sent by a
            human user during the same period of time); or (e) interfere with or
            disrupt the access of any user, host or network, including, without
            limitation, by sending a virus, overloading, flooding, spamming,
            mail-bombing the Service and Site, or by scripting the creation of
            Subscriber Content in such a manner as to interfere with or create
            an undue burden on the Service and Site.
          </li>
          <li>
            Misuse of the Service and Site. You may not utilize the Service and
            Site to carry out, promote or support: (a) any unlawful or
            fraudulent activities; (b) the impersonation of another person or
            entity or the misrepresentation of an affiliation with a person or
            entity in a manner that does or is intended to mislead, confuse, or
            deceive others; (c) activities that are defamatory, libelous or
            threatening, constitute hate speech, harassment, or stalking; (d)
            the publishing or posting of other people’s private or personal
            information without their express authorization and permission; (e)
            the sending of unsolicited communications, promotions
            advertisements, or spam; (f) the publishing of or linking to
            malicious content intended to damage or disrupt another user’s
            browser or computer; or (g) the promotion or advertisement of
            products or services other than your own without appropriate
            authorization. You shall not, and shall not permit any third party
            to, disassemble, decompile, reverse engineer, or otherwise attempt
            to derive source code or other trade secrets from the Services, or
            modify, make derivative works based upon, copy, or otherwise use any
            ideas, features, functions, or graphics of the Services in order to
            (a) build a competitive product or service; or (b) build a product
            using similar features, functions, or graphics of the Services.
          </li>
          <li>
            User Content Standards Within the Service and Site. You may not post
            any User Content on the Service or Site that: (a) violates any
            applicable law, any third party’s intellectual property rights, or
            anyone’s right of privacy or publicity; (b) is deceptive,
            fraudulent, illegal, obscene, pornographic (including child
            pornography, which, upon becoming aware of, we will remove and
            report to law enforcement, including the National Center for Missing
            and Exploited children), defamatory, libelous or threatening,
            constitutes hate speech, harassment, or stalking; (c) contains any
            personal information of minors; (d) contains any sensitive personal
            information, such as financial information, payment card numbers,
            social security numbers, or health information without Themis’s
            prior written consent granted as part of a Customer Agreement; (e)
            contains viruses, bots, worms, or similar harmful materials; or (f)
            contains any information that you do not have a right to make
            available under law or any contractual or fiduciary duty.
          </li>
          <li>
            Themis Remedies. In addition to any other remedies that may be
            available to us, Themis reserves the right to take any remedial
            action it deems necessary, including immediately suspending or
            terminating your Account or your access to the Service or Site, upon
            notice and without liability for Themis should you fail to abide by
            the rules in this Section 5 or if, in Themis’s sole discretion, such
            action is necessary to prevent disruption of the Service or Site for
            other users. If you are a Subscriber, Themis reserves the right to
            notify the Customer’s Account Administrator(s) or other Customer
            representative(s) of any violations of these Terms of Service.
          </li>
          <li>
            Reliance on Posted Materials. As part of the Service, Themis may
            make certain Themis Materials available for download and for your
            internal business uses. You may not alter, delete, obscure, or
            conceal any trademark, copyright or other notice appearing in any
            Themis Materials. Unless otherwise expressly stated in these Terms
            of Service or you receive Themis’s prior written consent, you may
            not modify, translate, create derivative works of, copy, distribute,
            market, display, remove or alter any proprietary notices or labels
            from, lease, sell, sublicense, clone, transfer, decompile, reverse
            engineer, or incorporate into any information retrieval system
            (electronic or mechanical), any Themis Materials. By accessing the
            Site and Service, you acknowledge that the Themis Materials are made
            available for your convenience only and do not constitute legal
            advice. The Themis Materials may or may not reflect the most current
            legal developments; accordingly, information accessed through the
            Service is not promised or guaranteed to be correct or complete, and
            should not be relied upon as such. As legal advice must be tailored
            to the specific circumstances of each case, nothing provided on
            through the Site and Service should be used as a substitute for
            advice of competent counsel. The materials on the Site and Service
            do not constitute legal advice and do not necessarily reflect the
            opinions of Themis. You acknowledge and agree the Themis shall not
            be liable to you or any other party for any act or failure to act
            relating thereto, in addition to the limitation of liability claims
            contained in this Terms of Service.
          </li>
        </ol>

        <b>6. Digital Millennium Copyright Act Notice.</b>
        <br />
        <ol>
          <li>
            If you believe that your copyrighted work has been copied in a way
            that constitutes copyright infringement and is accessible through
            the Service or Site, please notify Themis’s copyright agent, as set
            forth in the Digital Millennium Copyright Act of 1998 (DMCA). For
            your complaint to be valid under the DMCA, you must provide the
            following information in writing: (a) an electronic or physical
            signature of a person authorized to act on behalf of the copyright
            owner; (b) identification of the copyrighted work that you claim is
            being infringed; (c) identification of the material that is claimed
            to be infringing and where it is located on the Service or Site; (d)
            information reasonably sufficient to permit Themis to contact you,
            such as your address, telephone number, and email address; (e)
            statement that you have a good faith belief that use of the material
            in the manner complained of is not authorized by the copyright
            owner, its agent, or law; and (f) statement made under penalty of
            perjury that the above information is accurate and that you are the
            copyright owner or are authorized to act on behalf of the owner.
          </li>
          <li>
            Themis’s Designated Copyright Agent to receive notifications of
            claimed infringement can be reached as follows: Attention: Copyright
            Agent Contact: support@themis.com
          </li>
        </ol>

        <b>7. WARRANTIES, DISCLAIMERS AND LIMITATION OF LIABILITY.</b>
        <p>
          THE SERVICE AND SITE AND USER CONTENT, WHETHER PROVIDED BY THEMIS, ITS
          LICENSORS, ITS VENDORS OR ITS USERS, AND OTHER INFORMATION ON OR
          ACCESSIBLE FROM THE SERVICE AND SITE ARE PROVIDED “AS IS” WITHOUT
          WARRANTY, REPRESENTATION, CONDITION, OR GUARANTEE OF ANY KIND, EITHER
          EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED
          WARRANTIES, REPRESENTATIONS, CONDITIONS OR GUARANTEES OF QUALITY,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT, ALL OF WHICH ARE DISCLAIMED TO THE FULLEST EXTENT
          PERMITTED BY LAW. SPECIFICALLY, BUT WITHOUT LIMITATION, THEMIS DOES
          NOT WARRANT THAT: (i) THE INFORMATION AVAILABLE ON THE SERVICE AND
          SITE IS FREE OF ERRORS; (ii) THE FUNCTIONS OR FEATURES (INCLUDING BUT
          NOT LIMITED TO MECHANISMS FOR THE DOWNLOADING AND UPLOADING OF USER
          CONTENT) WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS; (iii)
          DEFECTS WILL BE CORRECTED, OR (iv) THE SERVICE AND SITE OR THE
          SERVER(S) THAT MAKE THE SERVICE AND SITE AVAILABLE ARE FREE OF VIRUSES
          OR OTHER HARMFUL COMPONENTS. IN NO EVENT SHALL THEMIS OR ITS
          AFFILIATES, LICENSORS, VENDORS, OR ANY OF THEIR RESPECTIVE DIRECTORS,
          OFFICERS, EMPLOYEES, AGENTS, OR OTHER REPRESENTATIVES BE LIABLE TO YOU
          OR ANY OTHER PERSON OR ENTITY FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO,
          DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE, OR COSTS OF
          OBTAINING SUBSTITUTE GOODS OR SERVICES), ARISING OUT OF OR IN
          CONNECTION WITH THE SERVICE AND SITE, ANY MATERIALS, ADVICE,
          INFORMATION, OR RECOMMENDATIONS APPEARING ON THE SERVICE AND SITE, OR
          ANY LINK PROVIDED ON THE SERVICE AND SITE, WHETHER OR NOT THEMIS HAS
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND WHETHER BASED UPON
          WARRANTY, CONTRACT, TORT, STRICT LIABILITY, VIOLATION OF STATUTE, OR
          OTHERWISE. THIS EXCLUSION OF LIABILITY SHALL APPLY TO THE FULLEST
          EXTENT PERMITTED BY LAW. IN ANY EVENT, OUR AGGREGATE LIABILITY WILL
          NOT EXCEED THE AMOUNT PAID FOR THE SERVICE OR SITE TO WHICH THE CLAIM
          RELATES OR, IF THE CLAIM DOES NOT RELATE TO A PRODUCT OR SERVICE,
          $100. THEMIS DOES NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
          THIRD PARTY THROUGH THE SERVICE AND SITE OR ANY WEBSITE FEATURED OR
          LINKED TO THROUGH THE SERVICE AND SITE, AND THEMIS WILL NOT BE A PARTY
          TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
          YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICE AND SITE. THEMIS
          WILL NOT BE LIABLE FOR THE OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD
          PARTY. YOU VOLUNTARILY ASSUME THE RISK OF HARM OR DAMAGE FROM THE
          FOREGOING. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF A REMEDY FAILS
          OF ITS ESSENTIAL PURPOSE AND TO THE FULLEST EXTENT PERMITTED BY LAW.
          If you are a California resident, you hereby waive California Civil
          Code §1542, which says: “A general release does not extend to claims
          which the creditor does not know or suspect to exist in his favor at
          the time of executing the release, which if known by him or her must
          have materially affected his or her settlement with the debtor.” This
          release includes the criminal acts of others.
        </p>

        <b>8. Indemnity</b>
        <p>
          YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD THEMIS AND ITS RESPECTIVE
          OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, SHAREHOLDERS, CONTRACTORS, OR
          REPRESENTATIVES (AND ALL SUCCESSORS AND ASSIGNS OF ANY OF THE
          FOREGOING), HARMLESS FROM AND AGAINST ANY CLAIM OR DEMAND, INCLUDING
          WITHOUT LIMITATION, REASONABLE ATTORNEYS’ FEES AND DISBURSEMENTS, MADE
          BY ANY THIRD PARTY IN CONNECTION WITH OR ARISING OUT OF YOUR USE OF
          THE SERVICE AND SITE, YOUR CONNECTION TO THE SERVICE AND SITE, YOUR
          VIOLATION OF THE TERMS OR THEMIS’S PRIVACY POLICY, YOUR VIOLATION OF
          AN APPLICABLE LAW, YOUR SUBMISSION, POSTING, OR TRANSMISSION OF USER
          CONTENT TO THE SERVICE AND SITE, AND/OR YOUR VIOLATION OF ANY RIGHTS
          OF ANOTHER INDIVIDUAL OR ENTITY. WE RESERVE THE RIGHT, AT OUR OWN
          EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF SUCH DISPUTES,
          AND IN ANY EVENT, YOU WILL COOPERATE WITH US IN ASSERTING ANY
          AVAILABLE DEFENSES.
        </p>

        <b>9. Third Parties</b>
        <br />
        <ol>
          <li>
            Third-Party Services and Links. The Service and Site may provide (1)
            information and content provided by third parties; (2) links to
            third-party websites or resources, such as sellers of goods and
            services; and (3) third-party products and services for sale
            directly to you. Themis is not responsible for the availability of
            such external sites or resources and does not endorse and is not
            responsible or liable for (i) any content, advertising, products, or
            other materials on or available from such sites or resources, (ii)
            any errors or omissions in these websites or resources, or (iii) any
            information handling practices or other business practices of the
            operators of such sites or resources. You further acknowledge and
            agree that Themis shall not be responsible or liable, directly, or
            indirectly, for any damage or loss caused or alleged to be caused by
            or in connection with use of or reliance on any linked sites or
            resources. Your interactions with such third parties will be
            governed by the third parties’ own terms of service and privacy
            policies, and any other similar terms.
          </li>
          <li>
            Third-Party Disputes. Any dispute you have with any such third party
            arising from your use of the Service or Site, including, without
            limitation, your employer, is directly between you and such third
            party, and you irrevocably release Themis (and our directors,
            officers, subsidiaries, affiliates, agents and employees) from any
            and all claims, demands and damages (actual and consequential) of
            every kind and nature, known and unknown, arising out of or in any
            way connected with such disputes. Themis will not, and will not
            attempt, to reverse or otherwise recover any payment or transaction
            that is subject to a bona fide dispute.
          </li>
        </ol>

        <b>10. Communications</b>
        <p>
          By providing us with your email address, you agree to receive notices
          electronically, to that email address. You further verify that you are
          the telephone subscriber and/or that you own any telephone numbers
          that you provide to Themis. You acknowledge that by voluntarily
          providing your telephone numbers to Themis, you expressly agree to be
          contacted at the telephone numbers you provide. You consent to receive
          e-mails, chat messages, pre-recorded voice messages and/or autodialed
          calls (including text messages) by or on behalf of Themis relating to
          this Agreement, any transaction with Themis, matters related to your
          Account, and promotions from Themis. These communications may be made
          by or on behalf of Themis, even if your phone number is registered on
          any state or federal Do Not Call list. You acknowledge that you may
          incur a charge for these texts or calls by your telephone carrier and
          that Themis will not be responsible for these charges. Themis will use
          your primary login credentials (email address and/or mobile phone
          number, depending on your chosen registration method) to send you
          operational communications concerning your Account, updates concerning
          new and existing features on the Service, notifications about product
          updates and improvements, company and industry news and events,
          updates from our community, and regarding and administering programs
          that you or the Customer have enrolled in.
        </p>

        <b>11. Modification</b>
        <p>
          Themis reserves the right at any time to modify or discontinue,
          temporarily or permanently, the Service and Site (or any part
          thereof), with or without notice. You agree that Themis shall not be
          liable to you or any third party for any modification, suspension or
          discontinuance of the Service and Site.
        </p>

        <b>12. General</b>
        <br />
        <ol>
          <li>
            Governing Law. These Terms of Service shall be construed in
            accordance with and governed by the laws of New York notwithstanding
            its conflicts of law principles. Any dispute arising out of these
            terms and conditions or the use of this site shall be initiated and
            conducted in the state or federal courts of New York City, New York,
            and you and Themis consent to the exclusive jurisdiction of such
            courts.
          </li>
          <li>
            Force Majeure. Under no circumstances shall Themis or its licensor
            or supplier be held liable for any delay or failure in performance
            resulting directly or indirectly from an event beyond its reasonable
            control.
          </li>
          <li>
            No Waiver. No waiver of any provision of these Terms of Service will
            be binding unless in writing, no waiver of any provisions of these
            Terms of Service will be deemed a further or continuing waiver of
            such provision or any other provision, and the failure of Themis to
            exercise or enforce any right or remedy in these Terms of Service
            does not waive that right or remedy. If a court of competent
            jurisdiction finds any provision of these Terms of Service to be
            invalid, the parties agree that the court should endeavor to give
            effect, to the maximum extent permitted by law, to the parties’
            intentions as reflected in the provision, and the other provisions
            of these Terms of Service will remain in full force and effect.
          </li>
          <li>
            Third-Party Beneficiaries. You agree that, except as otherwise
            expressly provided in these Terms of Service, there shall be no
            third-party beneficiaries to these Terms of Service.
          </li>
          <li>
            Statute of Limitations. Except where such a waiver is prohibited by
            state law, any claim or cause of action arising out of or related to
            the use of the Service and Site and/or these Terms must be filed
            within one (1) year after such claim or cause of action arose or be
            forever barred.
          </li>
          <li>
            Miscellaneous. These Terms of Service (and all terms and conditions
            incorporated herein) constitute the entire agreement between you and
            Themis and govern your use of the Service and Site, and supersede
            any prior agreements between you and Themis on the subject matter;
            provided, however, that for Subscribers, the Customer Agreement
            shall control to the extent the Customer Agreement conflicts with
            these Terms of Service. These Terms of Service, and any rights or
            licenses granted hereunder, may not be assigned or delegated by you.
            These Terms of Service, and any rights or licenses granted
            hereunder, may be assigned or delegated by Themis without
            restriction. These Terms of Service bind and inure to the benefit of
            each party and the party’s successors and permitted assigns. These
            Terms of Service may not be modified by an oral statement by a
            representative of Themis. No agency, partnership, joint venture, or
            employee-employer relationship is intended or created by these Terms
            of Service. You agree that any agreements made by and between you
            and us in electronic form are as legally binding as if made in
            physical written form. If you are using the Service and Site for or
            on behalf of the U.S. government, your license rights do not exceed
            those granted to non-government consumers. The section titles in
            these Terms of Service are for convenience only and have no legal or
            contractual effect. Any provision of these Terms of Service that by
            its nature is reasonably intended to survive beyond termination of
            these Terms of Service shall survive.
          </li>
        </ol>
      </div>
    </section>
  </div>
);
