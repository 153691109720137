import { faker } from "@faker-js/faker";

import type { Record } from "../models/Record";
import { createAccount } from "./createAccount";
import { createActionPlan } from "./createActionPlan";
import { createLinkedFinding } from "./createLinkedFinding";
import { createPartialRecord } from "./createPartialRecord";

export function createRecord(
  data?: NonNullable<Partial<Record>>,
): NonNullable<Record> {
  return Object.assign({}, createPartialRecord(), {
    account: Object.assign({}, createAccount()),
    action_plans: faker.helpers.arrayElements([createActionPlan()]) as any,
    owner_id: faker.number.int(),
    linked_findings: faker.helpers.arrayElements([
      createLinkedFinding(),
    ]) as any,
    shared_with_workspace_ids: faker.helpers.arrayElements([
      faker.number.int(),
    ]) as any,
  });
}
