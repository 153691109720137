import type { ColDef } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useLocation, useParams } from "react-router";

import { ACCOUNT_DETAILS_PATH } from "../../../accounts-routes";

export function useChildAccountsPreviewTableColumns() {
  const { formatMessage } = useIntl();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const location = useLocation();

  return useMemo(
    (): ColDef[] => [
      {
        field: "id",
        headerName: formatMessage({ defaultMessage: "ID" }),
        minWidth: 160,
        flex: 1,
        cellRenderer: "identifierCellRenderer",
        cellRendererParams: (props: CustomCellRendererProps) => {
          const accountId = props.data.id;
          return {
            to: {
              pathname: generatePath(ACCOUNT_DETAILS_PATH, {
                workspace_id,
                accountId,
              }),
              state: { from: location.pathname },
            },
            ...props,
          };
        },
      },
      {
        field: "name",
        headerName: formatMessage({ defaultMessage: "Name" }),
        minWidth: 200,
        flex: 1,
        cellRenderer: "textRenderCell",
      },
    ],
    [formatMessage, location.pathname, workspace_id],
  );
}
