import React from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface props {
  title: string;
  icon: React.ReactNode;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}

function SidebarSectionHeader({ title, icon, setOpen, open }: props) {
  return (
    <button
      onClick={() => setOpen && setOpen((prev) => !prev)}
      className="tw-flex tw-h-8 tw-w-full tw-items-center tw-justify-between tw-pl-3 tw-pr-3"
    >
      <div className="tw-flex tw-items-center tw-text-neutral-500">
        {icon}
        <label className="tw-cursor-pointer tw-font-[poppins] tw-text-sm tw-font-semibold">
          {title}
        </label>
      </div>

      {typeof open === "boolean" && setOpen && (
        <span>
          {open ? (
            <FiChevronUp className="tw-h-[20px] tw-w-[20px]" />
          ) : (
            <FiChevronDown className="tw-h-[20px] tw-w-[20px]" />
          )}
        </span>
      )}
    </button>
  );
}

export default SidebarSectionHeader;
