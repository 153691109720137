import React from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import type {
  Framework,
  LibraryRecord,
} from "@/stores/types/control-mapping-types";

import trashIcon from "../../../images/table-image/icon/delete-icon.svg";
import { Icon } from "../../Elements";
import Checkbox from "../shared/Checkbox";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";

interface LibraryTableRowProps {
  record: LibraryRecord;
  workspaceID: number | null;
  canDeleteRecords: boolean;
  showDeletePrompt: boolean;
  onChange: (id: number, checked: boolean) => void;
  onDelete: () => void;
  onDeletePromptChange: (show: boolean) => void;
  isEditable: boolean;
  hasModuleWriteAccess: boolean;
}

const LibraryTableRow = (props: LibraryTableRowProps) => (
  <div className="rr-library-table-row-container">
    <div
      key={props.record.id}
      className="rr-library-table-row"
      data-testid="rr-library-row"
    >
      <Checkbox
        checked={props.record.checked}
        onChange={(e) => props.onChange(props.record.id, e.target.checked)}
        data-testid="rr-library-regular-checkbox"
        disabled={!props.hasModuleWriteAccess}
        padding
      />
      <div
        className="rr-library-table-cell option"
        data-testid="rr-library-cell-framework"
      >
        {props.record.frameworks?.map((framework: Framework) => (
          <span key={framework.id}>{framework.title}</span>
        )) || <span>-No Frameworks-</span>}
      </div>
      <div
        className="rr-library-table-cell option risk-type"
        data-testid="rr-library-cell-control-category"
      >
        <span>{props.record.control_category || "-No Control Category-"}</span>
      </div>
      <div
        className="rr-library-table-cell option"
        data-testid="rr-library-cell-control-mapping-type"
      >
        <span>{props.record.control_mapping_type || "-No Type-"}</span>
      </div>
      <div
        className="rr-library-table-cell"
        data-testid="rr-library-cell-title"
      >
        <span>{props.record.title || "-No Title-"}</span>
      </div>
      <div
        className="rr-library-table-cell"
        data-testid="rr-library-cell-description"
      >
        <span>{props.record.description || "-No Description-"}</span>
      </div>
    </div>
    {props.isEditable && props.hasModuleWriteAccess && (
      <div
        className="rr-library-table-row-actions"
        data-testid="rr-library-table-row-actions"
      >
        <Link
          to={`/workspaces/${props.workspaceID}/modules/control-mapping/templates/${props.record.id}`}
        >
          <Icon name="edit" color="generalDark" size="de" />
        </Link>
        <Popup
          position="bottom right"
          open={props.showDeletePrompt}
          // eslint-disable-next-line react/no-unstable-nested-components
          trigger={() => (
            <button
              type="button"
              data-testid="cm-library-table-row-actions-delete-trigger"
              disabled={!props.canDeleteRecords}
            >
              <img src={trashIcon} alt="trash-icon" />
            </button>
          )}
          disabled={!props.canDeleteRecords}
          onOpen={() => props.onDeletePromptChange(true)}
          onClose={() => props.onDeletePromptChange(false)}
        >
          <div
            className="table-dropdown success-dropdown"
            data-testid="rr-library-table-row-actions-delete-popup"
          >
            <ConfirmationDialog
              heading="Delete Library Item"
              content="Are you sure you want to delete this library item? Actions are not reversible."
              handleConfirm={props.onDelete}
              handleReject={() => props.onDeletePromptChange(false)}
            />
          </div>
        </Popup>
      </div>
    )}
  </div>
);

export default LibraryTableRow;
