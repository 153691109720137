import { http } from "msw";

import { createDismissAllNotificationsMutationResponse } from "../createDismissAllNotifications";

export const dismissAllNotificationsHandler = http.put(
  "*/notifications/dismiss_all",
  function handler(info) {
    return new Response(
      JSON.stringify(createDismissAllNotificationsMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
