import { faker } from "@faker-js/faker";

import type { AttachmentFile } from "../models/AttachmentFile";

export function createAttachmentFile(
  data: NonNullable<Partial<AttachmentFile>> = {},
): NonNullable<AttachmentFile> {
  return {
    ...{
      content_type: faker.string.alpha(),
      filename: faker.string.alpha(),
      signed_id: faker.string.alpha(),
      url: faker.string.alpha(),
    },
    ...data,
  };
}
