import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "@/api/legacy/api";

import type { IssueManagementRecord } from "../gen";
import { createIssueManagementRecord } from "../gen/axios/issuesManagementRecordsController";
import { FINDING_QUERY_KEYS } from "./findings";

export function useCreateIssueManagementRecord({
  workspaceId,
  recordId,
  findingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  recordId: number;
  findingId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (issue: IssueManagementRecord) =>
      createIssueManagementRecord(workspaceId, issue),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.listRecordFindings({
          workspaceId,
          recordId,
        }),
      });

      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.detail(findingId),
      });

      onSuccess();
    },
    onError,
  });
}

export function useDeleteIssueManagementRecord({
  findingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  findingId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (recordVersionId: number) =>
      api.delete(`/issue_management/${recordVersionId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.detail(findingId),
      });
      onSuccess();
    },
    onError,
  });
}
