import React from "react";

import type { LoadingProps } from "@/components/Loading";
import Loading from "@/components/Loading";
import LoadingError from "@/components/loading-error/loading-error";
import type { LoadableData } from "@/hooks/useLoadableData";

type LoadingWrapperProps<T> = Omit<LoadableData<T>, "data"> &
  LoadingProps & {
    children: React.ReactNode;
  };

export default function LoadingWrapper<T>({
  loading,
  errorText,
  children,
  ...loadingProps
}: LoadingWrapperProps<T>) {
  if (loading) {
    return <Loading {...loadingProps} />;
  }

  if (errorText) {
    return <LoadingError loadingError={errorText} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment -- required for valid return type
  return <>{children}</>;
}
