import { Button } from "@themis/ui";
import pluralize from "pluralize";
import React from "react";

function ButtonsContainer({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled?: boolean;
}) {
  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      {!disabled ? (
        children
      ) : (
        <Button size="sm" disabled>
          Available After Record Saved
        </Button>
      )}
    </div>
  );
}

function HeaderContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
      {children}
    </div>
  );
}

function TitleDescriptionContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="tw-flex tw-items-baseline tw-gap-2">{children}</div>;
}

/**
 *
 * @deprecated use the new `InfoPreviewSection` and `InfoPreviewSectionFooter` instead.
 */
export function InfoPreviewSection({
  title,
  actions,
  content,
  count = 0,
  countDescription,
  disabled,
  showCount = true,
}: {
  title: string;
  actions?: React.ReactNode;
  content?: React.ReactNode;
  count?: number;
  /**
   * Description for the count. If not provided, it will be generated by pluralizing the title.
   */
  countDescription?: string;
  disabled?: boolean;
  showCount?: boolean;
}) {
  return (
    <section className="tw-flex tw-flex-col tw-items-start tw-gap-2">
      <HeaderContainer>
        <TitleDescriptionContainer>
          <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
            {title}
          </h2>
          {showCount && (
            <span className="tw-text-xs tw-font-semibold tw-text-neutral-300">
              {pluralize(countDescription || title, count, true)}
            </span>
          )}
        </TitleDescriptionContainer>
        <ButtonsContainer disabled={disabled}>{actions}</ButtonsContainer>
      </HeaderContainer>
      {!disabled && content}
    </section>
  );
}
