import { http } from "msw";

import { createDeleteFavoriteModuleWorkspaceMutationResponse } from "../createDeleteFavoriteModuleWorkspace";

export const deleteFavoriteModuleWorkspaceHandler = http.delete(
  "*/favorites/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteFavoriteModuleWorkspaceMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
