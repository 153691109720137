import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupAssetUpdate200,
  WebsiteMonitoringGroupAssetUpdateMutationRequest,
  WebsiteMonitoringGroupAssetUpdateMutationResponse,
  WebsiteMonitoringGroupAssetUpdatePathParams,
} from "../models/WebsiteMonitoringGroupAssetUpdate";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";
import { createUpdateMonitoringGroupAssetRequest } from "./createUpdateMonitoringGroupAssetRequest";

export function createWebsiteMonitoringGroupAssetUpdatePathParams(): NonNullable<WebsiteMonitoringGroupAssetUpdatePathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupAssetUpdate200(): NonNullable<WebsiteMonitoringGroupAssetUpdate200> {
  return { data: createMonitoringGroupAsset() };
}

export function createWebsiteMonitoringGroupAssetUpdateMutationRequest(): NonNullable<WebsiteMonitoringGroupAssetUpdateMutationRequest> {
  return { data: createUpdateMonitoringGroupAssetRequest() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupAssetUpdateMutationResponse(): NonNullable<WebsiteMonitoringGroupAssetUpdateMutationResponse> {
  return { data: createMonitoringGroupAsset() };
}
