import { get } from "lodash";

import type { FieldConfiguration } from "@/api/gen/models";

import { getDynamicFieldPath } from "../get-dynamic-field-path";

export function getDynamicFieldValue<
  T extends object,
  Config extends FieldConfiguration,
>(field: Config, record: T) {
  const fieldPath = getDynamicFieldPath(field);

  return get(record, fieldPath);
}
