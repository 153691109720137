import { faker } from "@faker-js/faker";

import type { ColorScheme } from "../models/ColorScheme";

export function createColorScheme(
  data: NonNullable<Partial<ColorScheme>> = {},
): NonNullable<ColorScheme> {
  return {
    ...{
      id: faker.number.int(),
      background_color: faker.string.alpha(),
      border: faker.string.alpha(),
      border_radius: faker.string.alpha(),
      text_color: faker.string.alpha(),
      title: faker.string.alpha(),
    },
    ...data,
  };
}
