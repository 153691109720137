import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ShowTrainingPathParams,
  ShowTrainingQueryResponse,
} from "../../models/ShowTraining";

/**
 * @description Training with related data
 * @link /trainings/:id
 */
export async function showTraining(
  id: ShowTrainingPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ShowTrainingQueryResponse>["data"]> {
  const res = await client<ShowTrainingQueryResponse>({
    method: "get",
    url: `/trainings/${id}`,
    ...options,
  });
  return res.data;
}
