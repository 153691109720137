import { faker } from "@faker-js/faker";

import type {
  ListRiskRegisterGroups200,
  ListRiskRegisterGroups403,
  ListRiskRegisterGroupsPathParams,
  ListRiskRegisterGroupsQueryResponse,
} from "../models/ListRiskRegisterGroups";
import { createRiskRegisterGroup } from "./createRiskRegisterGroup";

export function createListRiskRegisterGroupsPathParams(): NonNullable<ListRiskRegisterGroupsPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListRiskRegisterGroups200(): NonNullable<ListRiskRegisterGroups200> {
  return {
    data: faker.helpers.arrayElements([createRiskRegisterGroup()]) as any,
  };
}

/**
 * @description Forbidden
 */
export function createListRiskRegisterGroups403(): NonNullable<ListRiskRegisterGroups403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createListRiskRegisterGroupsQueryResponse(): NonNullable<ListRiskRegisterGroupsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createRiskRegisterGroup()]) as any,
  };
}
