import { http } from "msw";

import { createGetAttestationListQueryResponse } from "../createGetAttestationList";

export const getAttestationListHandler = http.get(
  "*/attestation_lists/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createGetAttestationListQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
