import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import Loading from "@/components/Loading";
import AttachmentOverlay from "@/components/shared/attachment-overlay/AttachmentOverlay";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import { useMainStore } from "@/contexts/Store";
import { ModuleContentWrapper } from "@/features/risk-assessment/components/ModuleContentWrapper/ModuleContentWrapper";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import LongTextTableCell from "@/features/risk-assessment/components/Table/GenericTable/LongTextTableCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableCellSpacer from "@/features/risk-assessment/components/Table/GenericTable/TableCellSpacer";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";

import EmptyMessage from "../EmptyMessage/EmptyMessage";
import type { VendorDocument } from "../types/vendor-document";

interface VendorDocumentsWrapperProps {
  recordVersionID: number;
}

function VendorDocumentsWrapper({
  recordVersionID,
}: VendorDocumentsWrapperProps) {
  const mainStore = useMainStore();

  const [loading, setLoading] = useState(false);
  const [vendorDocuments, setVendorDocuments] = useState<VendorDocument[]>([]);
  const [viewedAttachmentUrl, setViewedAttachmentUrl] = useState<string | null>(
    null,
  );

  const downloadURI = (uri: string) => {
    const link = document.createElement("a");
    link.href = uri;
    link.click();
  };

  const fetchVendorDocuments = async () => {
    setLoading(true);
    try {
      const documents =
        await mainStore.vendors.fetchDocumentsList(recordVersionID);
      setVendorDocuments(documents);
    } catch {
      mainStore.toast.setErrorText("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchVendorDocuments();
  }, []);

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader={false} />;
  }

  if (viewedAttachmentUrl) {
    return (
      <Popup modal open onClose={() => setViewedAttachmentUrl(null)}>
        <AttachmentOverlay
          fileUrl={viewedAttachmentUrl}
          onClose={() => setViewedAttachmentUrl(null)}
        />
      </Popup>
    );
  }

  return (
    <DashboardContent>
      <DashboardContentWrapper>
        <ModuleContentWrapper>
          {!loading &&
            (vendorDocuments.length === 0 ? (
              <EmptyMessage>
                No documents available. The documents from answers will appear
                here.
              </EmptyMessage>
            ) : (
              <Table
                header={
                  <>
                    <TableHeaderCell
                      title="Document Name"
                      leftIcon={
                        <Icon color="extrasBlueGrayDarker" name="text" />
                      }
                      firstDataHeader
                    />
                    <TableHeaderCell
                      title="Attachment"
                      leftIcon={
                        <Icon color="extrasBlueGrayDarker" name="attachment" />
                      }
                    />
                    <TableHeaderCell
                      title="Questionnaire"
                      leftIcon={
                        <Icon color="extrasBlueGrayDarker" name="book1" />
                      }
                    />
                    <TableHeaderCell
                      title="Question"
                      leftIcon={
                        <Icon color="extrasBlueGrayDarker" name="book1" />
                      }
                    />
                    <TableHeaderCell
                      title="Upload Date"
                      leftIcon={
                        <Icon color="extrasBlueGrayDarker" name="book1" />
                      }
                      lastDataHeader
                    />
                    <TableCellSpacer />
                  </>
                }
              >
                {vendorDocuments.map((doc: VendorDocument) => (
                  <TableRow rowIndex={1} key={`vendor-document-${doc.id}`}>
                    <LongTextTableCell>{doc.file.name}</LongTextTableCell>
                    <ContentCell
                      content={
                        <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
                          <div
                            className="tw-truncate tw-text-secondary-300"
                            data-tooltip-id="tooltip"
                            data-tooltip-content={doc.file.name}
                          >
                            {doc.file.name}
                          </div>
                          <div className="tw-flex tw-gap-1 tw-align-middle">
                            <Icon
                              name="eye"
                              className="tw-h-4.5 tw-w-4.5"
                              onClick={() =>
                                setViewedAttachmentUrl(doc.file.url)
                              }
                            />
                            <Icon
                              name="download"
                              className="tw-h-4.5 tw-w-4.5"
                              onClick={() => downloadURI(doc.file.url)}
                            />
                          </div>
                        </div>
                      }
                      minWidth={200}
                    />
                    <LongTextTableCell>
                      {doc.vendor_checklist.name}
                    </LongTextTableCell>
                    <LongTextTableCell>
                      {doc.vendor_question.name}
                    </LongTextTableCell>
                    <ContentCell
                      content={doc.file.upload_date}
                      minWidth={200}
                    />
                  </TableRow>
                ))}
              </Table>
            ))}
        </ModuleContentWrapper>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(VendorDocumentsWrapper);
