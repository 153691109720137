import { faker } from "@faker-js/faker";

import type { Field } from "../models/Field";

export function createField(
  data: NonNullable<Partial<Field>> = {},
): NonNullable<Field> {
  return {
    ...{
      name: faker.string.alpha(),
      data_type: faker.string.alpha(),
      computed_column_identifier: faker.string.alpha(),
      display_name: faker.string.alpha(),
      is_computed_column: faker.datatype.boolean(),
      is_custom_field: faker.datatype.boolean(),
      is_default_field: faker.datatype.boolean(),
      is_hidden: faker.datatype.boolean(),
      is_multiselect: faker.datatype.boolean(),
      is_required: faker.datatype.boolean(),
      is_user_editable: faker.datatype.boolean(),
      is_file_versioning_enabled: faker.datatype.boolean(),
      position: faker.number.float(),
      width: faker.number.int(),
      validation_rules: {
        exists: {},
        greater_than_or_equal_to: {
          options: { other_field_name: faker.string.alpha() },
        },
        less_than_or_equal_to: {
          options: { other_field_name: faker.string.alpha() },
        },
        present: {},
        approvers: {},
        validators: {},
        date_is_not_in_the_past: {
          options: { custom_message: faker.string.alpha() },
        },
        date_is_not_in_the_future: {
          options: { custom_message: faker.string.alpha() },
        },
        length: {
          options: { maximum: faker.number.int(), minimum: faker.number.int() },
        },
        one_choice: { options: { other_field_name: faker.string.alpha() } },
        unique_finra_submission: {},
        attachment: {},
        checklist: {},
      },
    },
    ...data,
  };
}
