import { http } from "msw";

import { createGetUsersQueryResponse } from "../createGetUsers";

export const getUsersHandler = http.get(
  "*/workspaces/:workspace_id/users",
  function handler(info) {
    return new Response(JSON.stringify(createGetUsersQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
