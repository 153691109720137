import { http } from "msw";

import { createListPermissionSetsQueryResponse } from "../createListPermissionSets";

export const listPermissionSetsHandler = http.get(
  "*/companies/:company_id/permission_sets",
  function handler(info) {
    return new Response(
      JSON.stringify(createListPermissionSetsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
