import type { DataWithIdAndCustomFields, PreviewTableColumn } from "@themis/ui";
import type { IconType } from "react-icons";
import {
  PiCalendarBlankBold,
  PiListBulletsBold,
  PiPaperclipBold,
  PiTextAlignLeftBold,
  PiUserBold,
} from "react-icons/pi";

import type {
  FieldConfigurationFieldType,
  FieldConfigurationReferenceType,
} from "@/api/gen/models";
import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";

const icons: Partial<
  Record<
    FieldConfigurationFieldType | FieldConfigurationReferenceType,
    IconType
  >
> = {
  text: PiTextAlignLeftBold,
  textarea: PiTextAlignLeftBold,
  select: PiListBulletsBold,
  date: PiCalendarBlankBold,
  datetime: PiCalendarBlankBold,
  User: PiUserBold,
  Attachment: PiPaperclipBold,
};

export function getDynamicPreviewTableColumns<
  T extends DataWithIdAndCustomFields,
>(fieldWithLayouts: FieldConfigurationWithLayout[]): PreviewTableColumn<T>[] {
  return fieldWithLayouts.map(({ field, layoutField }) => ({
    isCustomColumn: field.custom,
    key: field.name as keyof T,
    title: field.display_name,
    type: "locked",
    width: layoutField.width || 200,
    Icon: icons[field.reference_type || field.field_type],
  }));
}
