import { faker } from "@faker-js/faker";

import type {
  UpdateNotification200,
  UpdateNotificationMutationRequest,
  UpdateNotificationMutationResponse,
  UpdateNotificationPathParams,
} from "../models/UpdateNotification";
import { createNotification } from "./createNotification";
import { createUpdateNotificationRequest } from "./createUpdateNotificationRequest";

export function createUpdateNotificationPathParams(): NonNullable<UpdateNotificationPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateNotification200(): NonNullable<UpdateNotification200> {
  return { notification: createNotification() };
}

export function createUpdateNotificationMutationRequest(): NonNullable<UpdateNotificationMutationRequest> {
  return createUpdateNotificationRequest();
}

/**
 * @description OK
 */
export function createUpdateNotificationMutationResponse(): NonNullable<UpdateNotificationMutationResponse> {
  return { notification: createNotification() };
}
