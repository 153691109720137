import { faker } from "@faker-js/faker";

import type {
  GetQuestionnaires200,
  GetQuestionnaires403,
  GetQuestionnairesPathParams,
  GetQuestionnairesQueryResponse,
} from "../models/GetQuestionnaires";
import { createQuestionnaire } from "./createQuestionnaire";

export function createGetQuestionnairesPathParams(): NonNullable<GetQuestionnairesPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetQuestionnaires200(): NonNullable<GetQuestionnaires200> {
  return { data: faker.helpers.arrayElements([createQuestionnaire()]) as any };
}

/**
 * @description Forbidden
 */
export function createGetQuestionnaires403(): NonNullable<GetQuestionnaires403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createGetQuestionnairesQueryResponse(): NonNullable<GetQuestionnairesQueryResponse> {
  return { data: faker.helpers.arrayElements([createQuestionnaire()]) as any };
}
