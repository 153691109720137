import { faker } from "@faker-js/faker";

import type { Scoring } from "../models/Scoring";

export function createScoring(
  data: NonNullable<Partial<Scoring>> = {},
): NonNullable<Scoring> {
  return {
    ...{
      control_rating_id: faker.number.int(),
      control_risk_score: faker.string.alpha(),
      inherent_rating_id: faker.number.int(),
      inherent_risk_score: faker.string.alpha(),
      overall_rating_id: faker.number.int(),
      overall_risk_score: faker.string.alpha(),
      residual_risk_id: faker.number.int(),
      risk_area_ratings: faker.helpers.arrayElements([
        {
          risk_area_id: faker.number.int(),
          residual_risk_id: faker.number.int(),
          control_rating_id: faker.number.int(),
          inherent_rating_id: faker.number.int(),
          overall_rating_id: faker.number.int(),
          inherent_risk_score: faker.string.alpha(),
          control_risk_score: faker.string.alpha(),
          overall_risk_score: faker.string.alpha(),
        },
      ]) as any,
      scored_control_question_count: faker.number.int(),
      scored_inherent_question_count: faker.number.int(),
      scored_question_count: faker.number.int(),
      total_control_question_weight: faker.number.int(),
      total_inherent_question_weight: faker.number.int(),
      total_question_weight: faker.number.int(),
    },
    ...data,
  };
}
