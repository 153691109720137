import { Flex } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import UserAssignment from "@/features/risk-assessment/components/UserAssignment";
import { RESPONDER, REVIEWER } from "@/features/risk-assessment/config";
import type {
  ResponderType,
  ReviewerType,
} from "@/features/risk-assessment/types";

import QuestionnaireBuilderQuestionUserTrigger from "../../../QuestionnaireBuilderQuestionUserTrigger/QuestionnaireBuilderQuestionUserTrigger";
import QuestionnaireBuilderUserAssignment from "../../../QuestionnaireBuilderUserAssignment/QuestionnaireBuilderUserAssignment";

interface UserAssignmentSectionProps {
  isInternal: boolean;
  isNew: boolean;
  nonDestroyedResponderIDs: number[];
  onChangeAssignee: (
    ids: number[],
    assignmentType: ReviewerType | ResponderType,
  ) => void;
  sectionResponderIDs: number[];
  nonDestroyedReviewerUserIDS: number[];
  sectionReviewerIDs: number[];
}

export function UserAssignmentSection({
  isInternal,
  isNew,
  nonDestroyedResponderIDs,
  nonDestroyedReviewerUserIDS,
  onChangeAssignee,
  sectionResponderIDs,
  sectionReviewerIDs,
}: UserAssignmentSectionProps) {
  const mainStore = useMainStore();

  return (
    <Flex
      alignCenter
      className="questionnaire-question-user-assignment-wrapper"
    >
      {isInternal && (
        <>
          <UserAssignment
            assigneeWord="Respondents"
            tooltip={
              (isNew && "This will only appear in internal questionnaires") ||
              undefined
            }
            assignedUsers={mainStore.users.allUsers.filter((user) =>
              nonDestroyedResponderIDs.some((userID) => userID === user.id),
            )}
            CustomTrigger={QuestionnaireBuilderQuestionUserTrigger}
          >
            <QuestionnaireBuilderUserAssignment
              selectedIDs={nonDestroyedResponderIDs}
              setSelectedIDs={(ids: number[]) =>
                onChangeAssignee(ids, RESPONDER)
              }
              nonRemovableIDs={sectionResponderIDs}
            />
          </UserAssignment>
          <div className="questionnaire-question-vertical-line questionnaire-seperate-responder-reviewer" />
        </>
      )}

      <UserAssignment
        assignedUsers={mainStore.users.allUsers.filter((user) =>
          nonDestroyedReviewerUserIDS.some((userID) => userID === user.id),
        )}
        CustomTrigger={QuestionnaireBuilderQuestionUserTrigger}
      >
        <QuestionnaireBuilderUserAssignment
          selectedIDs={nonDestroyedReviewerUserIDS}
          setSelectedIDs={(ids: number[]) => onChangeAssignee(ids, REVIEWER)}
          nonRemovableIDs={sectionReviewerIDs}
        />
      </UserAssignment>
    </Flex>
  );
}
