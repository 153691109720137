import axios from "axios";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { attachmentFileType } from "@/api";
import { Icon } from "@/components/Elements";
import {
  buildUploadPayload,
  getAllAttachments,
  getLatestAttachment,
} from "@/components/helpers/AttachmentGroupsHelper";
import { FileUploadLoading } from "@/components/table/shared/cell-type/file-select/FileUploadLoading";
import { googleFilePicker } from "@/components/table/shared/cell-type/file-select/GoogleFilePicker/helpers";
import { useMainStore } from "@/contexts/Store";
import type { IntegrationAttachmentTypes } from "@/stores/types/attachment-types";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import fileAddGrayIcon from "../../../../images/table-image/icon/file-add-gray-icon.svg";
import FileUploadChanges from "../FileUploadChanges";
import FileUploadResetApprovers from "../FileUploadResetApprovers";
import Spinner from "../Spinner";
import { FileTypeSelectionPopup } from "./file-select/FileTypeSelectionPopup";
import SharePointFilePicker from "./file-select/SharePointFilePicker/SharePointFilePicker";

interface Props {
  fieldName: string;
  width: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAddFile?: (...args: any[]) => any;
  icon?: React.ReactNode;
  isMultiple?: boolean;
  label?: string;
  moduleIdentifier?: ModuleIdentifier;
  recordVersion?: RecordVersion;
  replacedAttachmentId?: number;
  triggerAction?: React.ReactNode;
  hasReadWriteAccess?: boolean;
}

interface Params {
  files: string[];
}

function FileUploader({
  fieldName,
  recordVersion,
  icon,
  label,
  isMultiple,
  width,
  replacedAttachmentId,
  triggerAction,
  handleAddFile,
  moduleIdentifier,
  hasReadWriteAccess,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("file-upload");
  const [figmaLink, setFigmaLink] = useState("");
  const [upload, setUpload] = useState("");
  const [fileNames, setFileNames] = useState();
  const [uploadingFiles, setUploadingFiles] = useState();
  const [attachmentParams, setAttachmentParams] = useState<Params | null>();
  const [uploadChanges, setUploadChanges] = useState(null);
  const [resetApprovers, setResetApprovers] = useState(false);

  // Refs
  const uploaderRef = useRef();

  // Variables
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly =
    !hasReadWriteAccess &&
    (!hasModuleWriteAccess || isCurrentWorkspaceArchived);
  const googleEnabled = mainStore?.users?.user?.google_integration_enabled;
  const sharePointEnabled = mainStore.users.user.sharepoint_integration_enabled;
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const files = recordVersion?.attachment_groups || [];
  const recordVersionID = recordVersion?.id;
  const filteredFiles = files.filter((item) => item.field_name === fieldName);
  const figmaEnabledModules: ModuleIdentifier[] = [
    "documents",
    "new_product_approval",
    "marketing",
  ];
  const figmaEnabled =
    mainStore?.users?.user?.figma_integration_enabled &&
    themisModuleIdentifier &&
    figmaEnabledModules.includes(themisModuleIdentifier) &&
    !location.pathname.includes("edit-approval");
  const someIntegrationsEnabled =
    figmaEnabled || googleEnabled || sharePointEnabled;
  const hidePopupResetApprovals =
    !(viewPopup === "file-changes") &&
    !(viewPopup === "approvals-confirmation");
  const attachmentGroups =
    recordVersion?.attachment_groups?.filter(
      (attachmentGroup) => attachmentGroup.field_name === fieldName,
    ) || [];
  const allAttachments = getAllAttachments(attachmentGroups);
  const urls = allAttachments.map((attachment) => attachment.url);
  const fileTypes = allAttachments.map((attachment) => attachment.file_type);

  if (!icon && !label) {
    return null;
  }

  const latestAttachment = attachmentGroups?.map((attachmentGroup) =>
    getLatestAttachment(attachmentGroup),
  );
  const figmaAttachment = latestAttachment?.find(
    (attachment) => attachment?.file_type === attachmentFileType.figma,
  );
  const isFigmaAttached = figmaAttachment !== undefined;
  const isSharePointAttached = fileTypes.includes(
    attachmentFileType.share_point,
  );

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleDrop = (event) => {
    setUpload(event.target.files[0]);
  };

  const handleFileUploadChanges = (
    // @ts-expect-error TS(7006) FIXME: Parameter 'updatedFileName' implicitly has an 'any... Remove this comment to see the full error message
    updatedFileName,
    // @ts-expect-error TS(7006) FIXME: Parameter 'updatedDocumentDescription' implicitly ... Remove this comment to see the full error message
    updatedDocumentDescription,
  ) => {
    if (updatedFileName || updatedDocumentDescription) {
      setUploadChanges({
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ updatedFileName: any; updatedD... Remove this comment to see the full error message
        updatedFileName,
        updatedDocumentDescription,
      });
    }

    setViewPopup("approvals-confirmation");
  };

  const sendRequest = async (params: Params) => {
    if (handleAddFile) {
      handleAddFile(params.files, fileNames, uploadingFiles, fieldName);
    } else {
      await mainStore.attachmentGroups.uploadFiles({
        recordVersionID,
        fieldName,
        signedIDs: params.files,
        fileType: attachmentFileType.direct_upload,
        url: null,
        isMultiselectField: isMultiple,
        replacedAttachmentID: replacedAttachmentId,
      });
    }

    setAttachmentParams(null);
  };

  const handleAttachment = async (signedIds: string[]) => {
    const params: Params = {
      files: signedIds,
    };

    setAttachmentParams(params);
    if (
      filteredFiles.length > 0 &&
      moduleIdentifier !== undefined &&
      ["policy", "procedures", "documents"].includes(moduleIdentifier)
    ) {
      setViewPopup("file-changes");
    } else {
      onClose();
    }
  };

  const onClose = () => {
    if (attachmentParams) {
      sendRequest(attachmentParams);
    }

    if (uploadChanges) {
      const { updatedFileName, updatedDocumentDescription } = uploadChanges;
      mainStore.recordVersions.update({
        // @ts-expect-error TS(2339) FIXME: Property 'updatedFileName' does not exist on type ... Remove this comment to see the full error message
        fieldName: uploadChanges.updatedFileName,
        recordVersionID,
        value: mainStore.avroSchemas.serializeValue(
          updatedFileName,
          updatedDocumentDescription,
        ),
      });
    }

    if (resetApprovers && recordVersionID) {
      mainStore.reviews.delete(recordVersionID, true);
    }

    setShowPopup(false);
    setViewPopup("file-upload");
    setUpload("");
  };

  const handleResetApprovers = (shouldReset: boolean | undefined) => {
    if (shouldReset) {
      setResetApprovers(true);
    }

    onClose();
  };

  const handleOpenGoogleDrivePicker = async () => {
    try {
      await googleFilePicker(saveGoogleDriveUrl);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 424) {
        mainStore.toast.setErrorText(`${error.response.data.errors.base}`);
      } else {
        mainStore.toast.setErrorText("Error!");
      }
    }
  };

  const saveGoogleDriveUrl = async (url: string) => {
    const fileType = attachmentFileType.google_drive;
    const signedID = undefined;

    const payload = buildUploadPayload(fieldName, fileType, signedID, url);
    if (payload && recordVersionID) {
      await mainStore.attachmentGroups.create({ recordVersionID, payload });
    }

    setAttachmentParams(null);
    setShowPopup(false);
  };

  // @ts-expect-error TS(7031) FIXME: Binding element 'uploads' implicitly has an 'any' ... Remove this comment to see the full error message
  const uploadState = ({ uploads }) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const states = uploads.map((elem) => elem.state);

    let [currentState] = states;
    if (states.includes("uploading")) {
      currentState = "uploading";
    } else if (states.includes("waiting")) {
      currentState = "waiting";
      // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
    } else if (states.filter((state) => state !== "finished").length === 0) {
      currentState = "finished";
    }
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const progresses = uploads.map((elem) => elem.progress);
    let totalProgress =
      // @ts-expect-error TS(7006) FIXME: Parameter 'total' implicitly has an 'any' type.
      progresses.reduce((total, progress) => total + progress, 0) /
      progresses.length;
    if (Number.isNaN(totalProgress)) {
      totalProgress = 100;
    }
    switch (currentState) {
      case "waiting":
        return (
          <div className="uploading">
            <p>0%</p>
            <Spinner />
          </div>
        );
      case "uploading":
        return (
          <div className="uploading">
            <p>{Math.round(totalProgress)}%</p>
            <Spinner />
          </div>
        );
      case "finished":
        return null;
      default:
        if (states.includes("error")) {
          const erroredUploads = uploads.filter(
            // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
            (elem) => elem.state === "error",
          );
          return (
            <>
              {
                // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
                erroredUploads.map((elem) => (
                  <p key={elem.id}>
                    Error uploading {elem.file.name}: {elem.error}
                  </p>
                ))
              }
            </>
          );
        }
        return null;
    }
  };

  const directUploadProvider = (
    <DirectUploadProvider
      onSuccess={handleAttachment}
      render={({ handleUpload, uploads }) => {
        uploaderRef.current = handleUpload;

        return (
          <div data-testid="direct-upload-provider">
            <div className="drag-drop-wrap">
              <div className="drag-drop-block">
                {!upload && (
                  <div>
                    <img src={fileAddGrayIcon} alt="file-add-gray-icon.svg" />
                    <p>
                      Drag & drop into this box <br />- or -
                    </p>
                    <button>Choose a file</button>
                    <input
                      type="file"
                      data-testid="direct-file-input"
                      onDrop={(event) => handleDrop(event)}
                      onChange={(event) => {
                        const { files: targetFiles } = event.currentTarget;
                        handleUpload(targetFiles);
                        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                        setUpload(event.target.files[0]);
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'FileList | null' is not assignab... Remove this comment to see the full error message
                        setFileNames(event.target.files);
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'FileList | null' is not assignab... Remove this comment to see the full error message
                        setUploadingFiles(targetFiles);
                      }}
                      multiple={isMultiple}
                    />
                  </div>
                )}
                {upload && uploadState({ uploads })}
              </div>
            </div>
          </div>
        );
      }}
    />
  );

  const buttonClasses = classNames("upload-document", {
    multiple: isMultiple,
    "big-active": showPopup,
  });

  const trigger = icon ? (
    <div data-testid="upload-document-icon">{icon}</div>
  ) : (
    <button
      data-testid="upload-document-button"
      className={buttonClasses}
      disabled={
        (!isMultiple && filteredFiles.length > 0) ||
        isFigmaAttached ||
        isReadOnly
      }
      onMouseDown={() => setUpload("")}
      type="button"
    >
      {label}
    </button>
  );

  // Figma functions
  const areMultipleFilesPresent = isMultiple && attachmentGroups?.length > 1;
  const handleOpenFigmaUploader = () => {
    setFigmaLink("");
    setViewPopup("figma-upload");
  };

  const handleFigmaSave = async () => {
    const mainParts = figmaLink.split("https://www.figma.com/design/");
    if (mainParts.length !== 2) {
      return renderFigmaLinkErrorToast();
    }

    const propsParts = mainParts[1].split("/");
    if (propsParts.length !== 2) {
      return renderFigmaLinkErrorToast();
    }

    const urlParamsParts = propsParts[1].split("node-id=");
    if (urlParamsParts.length !== 2) {
      return renderFigmaLinkErrorToast();
    }

    handleFigmaSaveSubmit(figmaLink);
  };

  const renderFigmaLinkErrorToast = () => {
    mainStore.toast.setErrorText(
      'We are unable to pull this linked document from Figma. Try copying the "share link" in Figma instead of the url in your browser.',
    );
  };

  // Google & Figma resync
  const resync = async (fileType: IntegrationAttachmentTypes) => {
    if (recordVersionID && fieldName) {
      setViewPopup("file-changes");
      await mainStore.files.resync(recordVersionID, fieldName, fileType);
    }
  };

  const handleFigmaSaveSubmit = async (link: string) => {
    if (!link) {
      return;
    }

    setViewPopup("preview-figma-file");

    const signedIDs = undefined;
    const fileType = attachmentFileType.figma;
    const url = link;

    const payload = buildUploadPayload(fieldName, fileType, signedIDs, url);
    if (payload && recordVersionID) {
      await mainStore.attachmentGroups.create({ recordVersionID, payload });
    }
  };

  const figmaUploader = (
    <div>
      <div className="drag-drop-wrap g-drive-uploader figma-uploader">
        <textarea
          placeholder="Paste Figma file link here"
          autoFocus
          data-testid="figma-upload-textarea"
          onChange={(e) => setFigmaLink(e.target.value)}
        />
        <div
          className="save-button"
          data-testid="figma-upload-submit"
          onClick={handleFigmaSave}
        >
          <span>Save</span>
        </div>
      </div>
    </div>
  );

  const previewFigmaFile = (
    <div
      className="table-dropdown attachment-dropdown"
      data-testid="attachment-cell-dropdown"
    >
      <ul className="file-select-popup-hover-container">
        <FileUploadLoading dataTestID="figma-upload-loading" icon="figma" />
        <li className="hover-container">
          <Icon className="field-icon no-hover-only" name="eye" />
          <Icon
            className="field-icon hover-only"
            name="eye"
            color="brandingHighlightViolet"
          />
          <Icon name="figma" spaceRight />
          <NavLink
            to={`/workspaces/${workspaceID}/modules/${kebabCase(
              themisModuleIdentifier as string,
            )}/attachment_view/${recordVersionID}/${attachmentGroups[0]?.id}`}
            className="link-creative"
          >
            Preview Creative{areMultipleFilesPresent ? "s" : ""}
          </NavLink>
        </li>
      </ul>
    </div>
  );

  const figmaAttachedPopup = (
    <div
      className="table-dropdown attachment-dropdown"
      data-testid="attachment-cell-dropdown"
    >
      <ul className="file-select-popup-hover-container">
        {urls[0] && fileTypes.includes(attachmentFileType.figma) && (
          <>
            <li>
              <Icon name="figma" spaceRight />
              <a
                href={urls[0]}
                target="_blank"
                rel="noreferrer"
                className="link-creative"
              >
                Open in Figma
              </a>
            </li>
            <li
              className="hover-container"
              onClick={() => resync(attachmentFileType.figma)}
            >
              <Icon className="field-icon no-hover-only" name="sync" />
              <Icon
                className="field-icon hover-only"
                name="sync"
                color="brandingHighlightViolet"
              />
              <span>Resync from Figma</span>
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const sharePointAttachedPopup = (
    <div
      className="table-dropdown attachment-dropdown"
      data-testid="attachment-cell-dropdown"
    >
      <ul className="file-select-popup-hover-container">
        {urls[0] && (
          <>
            <li>
              <Icon name="sharePoint" spaceRight />
              <a
                href={urls[0]}
                target="_blank"
                rel="noreferrer"
                className="link-creative"
              >
                Open in Microsoft SharePoint
              </a>
            </li>
            <li
              className="hover-container"
              onClick={() => resync(attachmentFileType.share_point)}
              data-testid="sharepoint-resync-button"
            >
              <Icon className="field-icon no-hover-only" name="sync" />
              <Icon
                className="field-icon hover-only"
                name="sync"
                color="brandingHighlightViolet"
              />
              <span>Resync from Microsoft SharePoint</span>
            </li>
          </>
        )}
      </ul>
    </div>
  );

  return (
    triggerAction || (
      <Popup
        position={["bottom center", "top center"]}
        trigger={trigger}
        open={showPopup}
        onOpen={() => setShowPopup(true)}
        onClose={onClose}
        disabled={Boolean(upload)}
        keepTooltipInside
      >
        {viewPopup === "file-upload" &&
          someIntegrationsEnabled &&
          !isFigmaAttached &&
          !isSharePointAttached && (
            <FileTypeSelectionPopup
              width={width}
              onFileUpload={() => setViewPopup("device-upload")}
              onGoogleDriveUpload={
                googleEnabled ? () => handleOpenGoogleDrivePicker() : undefined
              }
              onFigmaUpload={
                figmaEnabled && files?.length === 0
                  ? handleOpenFigmaUploader
                  : undefined
              }
              onSharePointUpload={
                sharePointEnabled && files?.length === 0
                  ? () => setViewPopup("share-point-upload")
                  : undefined
              }
            />
          )}
        {viewPopup === "file-upload" &&
          !someIntegrationsEnabled &&
          directUploadProvider}
        {viewPopup === "device-upload" && directUploadProvider}
        {viewPopup === "figma-upload" && figmaUploader}
        {viewPopup === "share-point-upload" && recordVersionID && (
          <SharePointFilePicker
            recordVersionID={recordVersionID}
            fieldName={fieldName}
            onPickerClose={() => setViewPopup("")}
            onPickerUploading={() => setViewPopup("share-point-loading")}
          />
        )}
        {viewPopup === "preview-figma-file" &&
          !isFigmaAttached &&
          figmaEnabled &&
          previewFigmaFile}
        {viewPopup === "share-point-loading" &&
          !isSharePointAttached &&
          sharePointEnabled && (
            <FileUploadLoading
              dataTestID="share-point-loading"
              icon="sharePoint"
            />
          )}
        {viewPopup === "file-changes" && (
          <FileUploadChanges
            skipUpdate
            // @ts-expect-error TS(2322) FIXME: Type '"audits" | "new_product_approval" | "complai... Remove this comment to see the full error message
            moduleIdentifier={moduleIdentifier}
            handleNext={handleFileUploadChanges}
          />
        )}
        {viewPopup === "approvals-confirmation" && (
          <FileUploadResetApprovers
            skipReset
            recordVersionID={recordVersionID!}
            handleNext={handleResetApprovers}
          />
        )}
        {hidePopupResetApprovals && isFigmaAttached && figmaAttachedPopup}
        {hidePopupResetApprovals &&
          isSharePointAttached &&
          sharePointAttachedPopup}
      </Popup>
    )
  );
}

FileUploader.defaultProps = {
  isMultiple: false,
  width: "100%",
  replacedAttachmentId: null,
};

export default observer(FileUploader);
