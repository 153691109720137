import { http } from "msw";

import { createCreateFindingLegacyMutationResponse } from "../createCreateFindingLegacy";

export const createFindingLegacyHandler = http.post(
  "*/workspaces/:workspace_id/findings",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateFindingLegacyMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
