import { faker } from "@faker-js/faker";

import type { Department } from "../models/Department";

export function createDepartment(
  data: NonNullable<Partial<Department>> = {},
): NonNullable<Department> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      title: faker.string.alpha(),
    },
    ...data,
  };
}
