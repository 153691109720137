import { faker } from "@faker-js/faker";

import type {
  ShowAccount200,
  ShowAccountPathParams,
  ShowAccountQueryParams,
  ShowAccountQueryResponse,
} from "../models/ShowAccount";
import { createAccount } from "./createAccount";

export function createShowAccountPathParams(): NonNullable<ShowAccountPathParams> {
  return { workspace_id: faker.number.int(), id: faker.number.int() };
}

export function createShowAccountQueryParams(): NonNullable<ShowAccountQueryParams> {
  return { expand: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createShowAccount200(): NonNullable<ShowAccount200> {
  return { data: createAccount() };
}

/**
 * @description OK
 */
export function createShowAccountQueryResponse(): NonNullable<ShowAccountQueryResponse> {
  return { data: createAccount() };
}
