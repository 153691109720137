import { http } from "msw";

import { createExportRiskRegisterGroupsQueryResponse } from "../createExportRiskRegisterGroups";

export const exportRiskRegisterGroupsHandler = http.get(
  "*/workspaces/:workspace_id/risk_registers/risk_register_groups/export_scores",
  function handler(info) {
    return new Response(
      JSON.stringify(createExportRiskRegisterGroupsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
