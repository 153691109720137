import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  HeaderTabs,
  useToast,
} from "@themis/ui";
import classNames from "classnames";
import dayjs from "dayjs";
import { debounce, isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { PiDownloadSimple } from "react-icons/pi";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import {
  getSelectedTab,
  getTabByName,
  getTabByURL,
} from "@/components/helpers/Tabs";
import Loading from "@/components/Loading";
import AddNewButtonPopup from "@/components/table/risk-register/AddNewButtonPopup";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { exportRiskRegisterGroups } from "@/features/risk-register/hooks";
import GroupScoringPage from "@/features/risk-register/pages/GroupScoringPage";
import useOpenSections from "@/hooks/use-open-sections";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { usePreventUnsavedChanges } from "@/hooks/usePreventUnsavedChanges";
import { useTableData } from "@/hooks/useTableData";
import type {
  LibraryRecord,
  RiskRegisterScoringMatrix,
  ScoringMatrixRating,
} from "@/stores/types/risk-register-types";
import type { Section } from "@/stores/types/section-tags";

import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import RegularDateSelect from "../shared/RegularDateSelect";
import Switch from "../shared/Switch";
import Table from "../Table";
import AllRiskPopup from "./AllRiskPopup";
import { INHERENT_MATRIX_TITLE, RESIDUAL_MATRIX_TITLE } from "./Constants";
import Library from "./Library";
import LibraryExport from "./LibraryExport";
import LibraryImport from "./LibraryImport";
import RiskRegisterLibraryApplyButton from "./RiskRegisterLibraryApplyButton";
import RiskRegisterLibraryTemplateButton from "./RiskRegisterLibraryTemplateButton";
import RiskRegisterScoringModal from "./RiskRegisterScoringModal";
import RiskRegistersTable from "./RiskRegistersTable";
import Scoring from "./Scoring";

interface RiskRegisterTab {
  id: string;
  name: string;
  key: string;
  value: string;
}

const TABS = [
  {
    id: "AllRisk",
    name: "All Risks",
    key: "risk-register",
    value: "/modules/risk-register",
  },
  {
    id: "Inherent",
    name: "Inherent",
    key: "risk-register-scoring-inherent",
    value: "/modules/risk-register-scoring-inherent",
  },
  {
    id: "Residual",
    name: "Residual",
    key: "risk-register-scoring-residual",
    value: "/modules/risk-register-scoring-residual",
  },
  {
    id: "Scoring",
    name: "Scoring Group",
    key: "scoring",
    value: "/modules/risk-register/scoring",
  },
  {
    id: "Templates",
    name: "Company Library",
    key: "templates",
    value: "/modules/risk-register/templates",
  },
  {
    id: "ThemisRiskLibrary",
    name: "Themis Risk Library",
    key: "themis-risk-library",
    value: "/modules/risk-register/themis-risk-library",
  },
];

const FILTER_TABS = [
  {
    id: "All",
    name: "All",
    key: "",
    value: "/modules/risk-register",
  },
  {
    id: "Active",
    name: "Active",
    key: "active",
    value: "/modules/risk-register/active",
  },
  {
    id: "Completed",
    name: "Completed",
    key: "completed",
    value: "/modules/risk-register/completed",
  },
];

function RiskRegister() {
  const mainStore = useMainStore();

  useTableData();
  const toast = useToast();

  const [activeScreen, setActiveScreen] = useState<RiskRegisterTab>(
    getSelectedTab(TABS) as RiskRegisterTab,
  );
  const [filter, setFilter] = useState("All");
  const [editingMatrixHelper, setEditingMatrixHelper] =
    useState<RiskRegisterScoringMatrix | null>(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [editingMatrixChanged, setEditingMatrixChanged] = useState(false);
  const [isMatrixSwitchPopupOpened, setIsMatrixSwitchPopupOpened] =
    useState(false);
  const [tabSwitchPopupOpened, setTabSwitchPopupOpened] = useState<
    string | null
  >(null);
  const [riskRatings, setRiskRatings] = useState<ScoringMatrixRating[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showNewSection, setShowNewSection] = useState(false);

  const [mode, setMode] = useState("review");
  const [initialSection, setInitialSection] = useState<null | Section>(null);
  const [completeDate, setCompleteDate] = useState(dayjs().toDate());
  const [rangeModalOpen, setRangeModalOpen] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState<string | null>(null);

  const [groupedRows, setGroupedRows] = useState<
    Record<string, LibraryRecord[]>
  >({});

  // Variables
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const fields = mainStore.fields.visibleFields;
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSectionID = queryParams.get("initialSectionID");

  const loading = useLoading(fields) || mainStore.riskRegisters.loading;

  const { company } = mainStore.companies;
  const { data } = mainStore.riskRegisters;
  const { moduleWorkspaceID, workspaceID, isIW } = mainStore.context;
  const moduleWorkspace = mainStore.moduleWorkspaces.list.find(
    (item) => item.id === moduleWorkspaceID,
  );
  const isLibraryEnabled = isIW || moduleWorkspace?.library_enabled;
  const recordVersions = mainStore.recordVersions.list;
  const riskTableScreens = ["Active", "Completed", "All"];
  const matrices = data?.scoring_matrices || [];
  const rangeRatings = data?.scoring_matrix_range_ratings || [];
  const sectionTagIds = mainStore.sectionTags.orderedList.map(({ id }) => id);
  const sectionTagIdMap = sectionTagIds.reduce<
    Partial<Record<number, boolean>>
  >((tagMap, id) => {
    tagMap[id] = true;
    return tagMap;
  }, {});

  const debouncedHandleSave = useCallback(
    debounce(handleEditMatrixSubmit, 500),
    [moduleWorkspaceID],
  );

  // hooks
  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: fields.filter(
      (item) =>
        !["inherent_risk_rating", "residual_risk_rating"].includes(item.name),
    ),
  });
  const { openedSections, setOpenedSections, handleToggleSection } =
    useOpenSections();
  const openSectionTagIds = Object.entries(openedSections)
    .filter(([_, value]) => value)
    .map(([key]) => Number(key));
  const sectionsRecordIds = Object.keys(groupedRows)
    .filter((key) => openedSections[key])
    .flatMap((key) => groupedRows[key])
    .map(({ id }) => id);

  useEffect(() => {
    if (
      mainStore.sectionTags.orderedList.length &&
      activeScreen.id === "AllRisk"
    ) {
      setOpenedSections(sectionTagIdMap);
    } else {
      setOpenedSections({});
    }
  }, [activeScreen.id, mainStore.sectionTags.orderedList]);

  useEffect(() => {
    const companyId = company?.id;
    if (companyId) {
      mainStore.riskTypes.index(companyId);
      mainStore.mainCategories.index(companyId);
    }
  }, [company]);

  useEffect(() => {
    if (
      workspaceID &&
      mainStore.riskRegisters.data &&
      Object.keys(mainStore.riskRegisters.data).length === 0 &&
      !loading
    ) {
      mainStore.riskRegisters.index({ workspaceID });
    }
  }, [workspaceID, mainStore.riskRegisters.data, loading]);

  useEffect(() => {
    const screenURLPath = location.pathname.split("/").slice(3);

    if (
      screenURLPath[screenURLPath.length - 1] === "active" ||
      screenURLPath[screenURLPath.length - 1] === "completed"
    ) {
      screenURLPath.pop();
    }

    const screenURL = `/${screenURLPath.join("/")}`;
    const newScreen = TABS.find((tab) => tab.value === screenURL);

    if (newScreen && activeScreen.id !== newScreen.name) {
      setActiveScreen(newScreen as RiskRegisterTab);
    }
    setIsUpdated(false);
  }, [location.pathname, activeScreen]);

  useEffect(() => {
    if (initialSectionID) {
      const section =
        mainStore.sectionTags.list.find(
          (item) => item.id === Number(initialSectionID),
        ) || null;
      setInitialSection(section);
      mainStore.riskRegisters.setCurrentSection(section);
    } else {
      setInitialSection(null);
    }
  }, [initialSectionID]);

  useEffect(() => {
    if (editingMatrixHelper) {
      const currentMatrix = matrices.find(
        (matrix) => matrix.id === editingMatrixHelper.id,
      );
      if (currentMatrix && !isEqual(currentMatrix, editingMatrixHelper)) {
        setEditingMatrix(currentMatrix, false);
        setEditingMatrixChanged(false);
      }
    }
  }, [matrices]);

  useEffect(() => {
    setRiskRatings(
      mainStore.riskRegisters.scoringMatrixRatings.filter(
        (matrixRating) =>
          matrixRating.risk_type === activeScreen.id.toLowerCase(),
      ),
    );
  }, [mainStore.riskRegisters.scoringMatrixRatings, activeScreen]);

  function setEditingMatrix(
    newMatrix: RiskRegisterScoringMatrix | null,
    isUpdatedByUser = true,
  ) {
    setEditingMatrixHelper(newMatrix);
    if (isUpdatedByUser) {
      setIsUpdated(true);
    }
  }

  function setRiskRatingsHelper(
    newRiskRatings: ScoringMatrixRating[],
    isUpdatedByUser = true,
  ) {
    setRiskRatings(newRiskRatings);
    if (isUpdatedByUser) {
      setIsUpdated(true);
    }
  }

  function handleResetMatrixMode() {
    setMode("review");
    setEditingMatrixChanged(false);
  }

  async function handleEditMatrixSubmit(newMatrix?: RiskRegisterScoringMatrix) {
    if ((newMatrix && !isEqual(newMatrix, editingMatrixHelper)) || !newMatrix) {
      const updatedMatrix = newMatrix || editingMatrixHelper;
      if (updatedMatrix) {
        updatedMatrix.scoring_matrix_rows =
          updatedMatrix.scoring_matrix_rows.map((row) => {
            row.scoring_matrix_cells = row.scoring_matrix_cells.map((cell) => {
              if (Number.isNaN(cell.rating_id)) {
                cell.rating_id =
                  mainStore.riskRegisters.scoringMatrixRatings.find(
                    (matrixRating) => matrixRating.title === cell.title,
                  )?.id;
              }
              return cell;
            });
            return row;
          });
      }

      await mainStore.riskRegisters.updateScoringMatrix(
        moduleWorkspaceID,
        updatedMatrix,
      );

      setIsUpdated(false);
    }
  }

  function handleTabChange(
    newScreen: string,
    fromDialog = false,
    filterTab = false,
  ) {
    const oldScreen = activeScreen.id;
    if (mode === "edit" && editingMatrixChanged && !fromDialog) {
      return setTabSwitchPopupOpened(newScreen);
    }

    const selectedScreen = getTabByURL(
      filterTab ? FILTER_TABS : TABS,
      newScreen,
    );

    setTabSwitchPopupOpened(null);

    if (filterTab) {
      setFilter(selectedScreen?.name || "All");

      if (selectedScreen?.name !== filter) {
        mainStore.riskRegisters.index({
          workspaceID,
          tab: selectedScreen?.name,
        });
        setCompleteDate(dayjs().toDate());
      }
    } else {
      setActiveScreen((selectedScreen || TABS[0]) as RiskRegisterTab);

      if (selectedScreen?.name !== activeScreen.id) {
        mainStore.riskRegisters.index({
          workspaceID,
          tab: selectedScreen?.name,
        });
      }
    }

    handleResetMatrixMode();
    setEditingMatrix(null, false);
    mainStore.riskRegisters.setLibraryRecords([]);
    history.push(`/workspaces/${workspaceID}${selectedScreen?.value}`, {
      from: oldScreen,
    });
  }

  function handleDateChange(date: string) {
    const dateObject = dayjs(date).toDate();
    setCompleteDate(dateObject);
    mainStore.riskRegisters.completedOnDate({ date });
  }

  function handleMatrixModeSwitchClick() {
    if (mode === "edit") {
      return handleResetMatrixMode();
    }

    const matrixName =
      activeScreen.id === "Inherent"
        ? INHERENT_MATRIX_TITLE
        : RESIDUAL_MATRIX_TITLE;
    setEditingMatrix(
      matrices.find((item) => item.name === matrixName) || null,
      false,
    );
    setMode("edit");
    setEditingMatrixChanged(false);
  }

  function handleChangeMatrix(
    newData: RiskRegisterScoringMatrix,
    saveChanges = false,
  ) {
    setEditingMatrix(newData);

    if (saveChanges) {
      debouncedHandleSave(newData);
    } else {
      setEditingMatrixChanged(true);
    }
  }

  function handleMatrixModeSwitchPopupReject() {
    setIsMatrixSwitchPopupOpened(false);
    handleResetMatrixMode();
  }

  async function handleMatrixModeSwitchPopupConfirm() {
    const riskType = activeScreen.id === "Inherent" ? "inherent" : "residual";
    await mainStore.riskRegisters.updateMatrixRatings(
      moduleWorkspaceID,
      riskRatings,
      riskType,
      false,
    );
    await mainStore.riskRegisters.updateScoringMatrix(
      moduleWorkspaceID,
      editingMatrixHelper,
    );
    handleMatrixModeSwitchPopupReject();
  }

  async function handleTabSwitchPopupConfirm() {
    setIsUpdated(false);
    const riskType = activeScreen.id === "Inherent" ? "inherent" : "residual";
    await mainStore.riskRegisters.updateMatrixRatings(
      moduleWorkspaceID,
      riskRatings,
      riskType,
      false,
    );
    await mainStore.riskRegisters.updateScoringMatrix(
      moduleWorkspaceID,
      editingMatrixHelper,
    );
    handleTabChange(tabSwitchPopupOpened!, true);
  }

  function handleTabSwitchPopupReject() {
    setConfirmDialogOpen(false);
    setTabSwitchPopupOpened(null);
    mainStore.riskRegisters.index({ workspaceID });
    handleTabChange(redirectLocation!, true);
  }

  const tabClicked = (tab: string) => {
    const popupEnabled = mode === "edit" && editingMatrixChanged;

    if (popupEnabled) {
      setRedirectLocation(tab);
      setConfirmDialogOpen(true);
    } else {
      handleTabChange(tab);
    }
  };

  const renderMatrixModeSwitchTrigger = (
    <div>
      <Switch
        active
        indeterminate={false}
        checked={mode === "edit"}
        disabled={mode === "edit" && editingMatrixChanged}
        onChange={handleMatrixModeSwitchClick}
      />
    </div>
  );

  const renderMatrixModeSwitchConfirmation = (
    <ConfirmationDialog
      heading="Save Now?"
      content="Do you want to save changes?"
      handleConfirm={handleMatrixModeSwitchPopupConfirm}
      handleReject={handleMatrixModeSwitchPopupReject}
    />
  );

  const renderMatrixModeSwitch = (
    <div className="rr-mode-switch" data-testid="rr-mode-switch">
      <span className={classNames({ active: mode === "review" })}>Review</span>
      <Popup
        position="bottom center"
        trigger={renderMatrixModeSwitchTrigger}
        open={
          mode === "edit" && editingMatrixChanged && isMatrixSwitchPopupOpened
        }
        onOpen={() => setIsMatrixSwitchPopupOpened(true)}
        onClose={() => setIsMatrixSwitchPopupOpened(false)}
      >
        <div className="table-dropdown success-dropdown">
          {renderMatrixModeSwitchConfirmation}
        </div>
      </Popup>
      <span className={classNames({ active: mode === "edit" })}>Modify</span>
    </div>
  );

  const onModalClose = () => {
    setRangeModalOpen(false);
  };

  usePreventUnsavedChanges([isUpdated], [isUpdated]);

  const handleRiskRegisterAddNewGroup = () => {
    document.getElementById("add-new-risk-register-scoring-group-btn")?.click();
  };

  const tabFilterButton = (
    <AllRiskPopup
      activeScreen={activeScreen.key}
      onClick={handleTabChange}
      showConfirmation={
        mode === "edit" &&
        editingMatrixChanged &&
        tabSwitchPopupOpened === "All"
      }
      onConfirmYes={handleTabSwitchPopupConfirm}
      onConfirmNo={handleTabSwitchPopupReject}
      moduleWorkspaceID={Number(moduleWorkspaceID)}
      initialSection={initialSection}
    />
  );

  if (loading) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <Table>
      <RiskRegisterScoringModal
        open={rangeModalOpen}
        moduleWorkspaceID={moduleWorkspaceID}
        onClose={onModalClose}
        rangeRatings={rangeRatings}
      />
      <Dialog open={confirmDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save Now?</DialogTitle>
            <DialogDescription>
              Changes will be lost if you leave without saving.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="tw-pt-6">
            <Button color="transparent" onClick={handleTabSwitchPopupReject}>
              Leave Anyway
            </Button>
            <Button onClick={handleTabSwitchPopupConfirm}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="table-header-wrap" data-testid="risk-register-header">
        <HeaderTabs
          tabs={TABS}
          tabButtons={
            activeScreen.id === "AllRisk"
              ? [{ tabIndex: 0, button: tabFilterButton }]
              : []
          }
          selectedTab={activeScreen.value}
          onSelectTab={tabClicked}
        />
        <div className="import-export-buttons-container">
          {["All", "AllRisk"].includes(activeScreen.id) && (
            <>
              <button
                className="risk-rating-top-button gray-button"
                onClick={() => setRangeModalOpen(true)}
              >
                Edit Scoring
              </button>
              <ExportBulk openSectionTagIds={openSectionTagIds} />
              <ImportBulk />
            </>
          )}
          {activeScreen.id === "Scoring" && (
            <>
              <Button onClick={handleRiskRegisterAddNewGroup}>
                Add New Group
              </Button>
              <Button
                color="tertiary"
                LeftIcon={PiDownloadSimple}
                onClick={() => {
                  toast({
                    content: "The excel spreadsheet is downloading",
                    variant: "download",
                    hideCloseButton: true,
                  });
                  exportRiskRegisterGroups(workspaceID!);
                }}
              >
                Export
              </Button>
            </>
          )}
          {filter === "Active" && (
            <ExportBulk
              specificStatuses={["new", "in_review", "ready_to_finalize"]}
            />
          )}
          {filter === "Completed" && (
            <ExportBulk specificStatuses={["completed"]} />
          )}

          {(activeScreen.id === "Templates" ||
            activeScreen.id === "ThemisRiskLibrary") && (
            <>
              {activeScreen.id === "Templates" && isLibraryEnabled && (
                <>
                  <LibraryImport moduleWorkspaceID={moduleWorkspaceID} />
                  <RiskRegisterLibraryApplyButton libraryType="user_generated" />
                </>
              )}
              {activeScreen.id === "ThemisRiskLibrary" && isLibraryEnabled && (
                <>
                  {isIW && (
                    <LibraryExport
                      sectionsRecordIds={sectionsRecordIds}
                      moduleWorkspaceID={moduleWorkspaceID}
                    />
                  )}
                  {isIW && (
                    <RiskRegisterLibraryTemplateButton
                      handleScreenChange={handleTabChange}
                    />
                  )}
                  <RiskRegisterLibraryApplyButton libraryType="themis_generated" />
                </>
              )}
            </>
          )}
        </div>
        <div className="add-new-block">
          {activeScreen.id === "AllRisk" &&
            riskTableScreens.includes(filter) && (
              <div className="vendor-heading-actions">{filtersTrigger}</div>
            )}

          {(activeScreen.id === "Inherent" || activeScreen.id === "Residual") &&
            isCurrentWorkspaceActive && (
              <div className="vendor-heading-actions">
                {renderMatrixModeSwitch}
              </div>
            )}
        </div>
      </div>
      {activeScreen.id === "AllRisk" &&
        ["All", "Active", "Completed"].includes(filter) && (
          <div
            className={classNames("table-header-wrap", {
              "header-with-filters": filtersViewEnabled,
            })}
            data-testid="risk-register-header"
          >
            <HeaderTabs
              tabs={FILTER_TABS}
              selectedTab={getTabByName(FILTER_TABS, filter)?.value || "All"}
              onSelectTab={(newScreen) =>
                handleTabChange(newScreen, false, true)
              }
            />

            {filter === "Completed" && (
              <div className="date-block">
                <span data-tooltip-left='Provides a historical snapshot. This will show you what the "completed" tab would have looked like on the selected date.'>
                  Show Completed Records As Of:
                </span>
                <RegularDateSelect
                  initialValue={completeDate}
                  handleUpdateDate={handleDateChange}
                />
              </div>
            )}
          </div>
        )}
      {filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersContent}
        </div>
      )}
      {activeScreen.id === "AllRisk" && filter === "Active" && (
        <AddRecordHeader
          onAddRecord={() => setShowPopup(!showPopup)}
          recordName="Risk Event"
          canAddSection
          onAddSection={() => setShowNewSection(true)}
        />
      )}
      <AddNewButtonPopup
        sectionTagID={mainStore.sectionTags.orderedList[0]?.id}
        workspaceID={workspaceID}
        showPopupParent={showPopup}
      />
      {activeScreen.id === "AllRisk" && riskTableScreens.includes(filter) && (
        <RiskRegistersTable
          recordVersions={recordVersions}
          fields={fields}
          loading={loading}
          moduleWorkspaceID={Number(moduleWorkspaceID)}
          initialSection={initialSection}
          forcedShowNewSectionDetails={showNewSection}
          onToggleSection={handleToggleSection}
        />
      )}
      {!loading && activeScreen.id === "Inherent" && (
        <Scoring
          editingMode={mode === "edit"}
          matrix={
            editingMatrixHelper ||
            matrices.find((item) => item.name === INHERENT_MATRIX_TITLE)
          }
          setMatrix={handleChangeMatrix}
          riskRatings={riskRatings}
          setRiskRatings={setRiskRatingsHelper}
          handleEditSubmit={handleEditMatrixSubmit}
          setEditingMatrixChanged={setEditingMatrixChanged}
        />
      )}
      {!loading && activeScreen.id === "Residual" && (
        <Scoring
          editingMode={mode === "edit"}
          matrix={
            editingMatrixHelper ||
            matrices.find((item) => item.name === RESIDUAL_MATRIX_TITLE)
          }
          setMatrix={handleChangeMatrix}
          riskRatings={riskRatings}
          setRiskRatings={setRiskRatingsHelper}
          handleEditSubmit={handleEditMatrixSubmit}
          setEditingMatrixChanged={setEditingMatrixChanged}
        />
      )}
      {!loading && activeScreen.id === "Templates" && (
        <Library
          moduleWorkspaceID={Number(moduleWorkspaceID)}
          libraryType="user_generated"
        />
      )}
      {!loading && activeScreen.id === "ThemisRiskLibrary" && (
        <Library
          moduleWorkspaceID={Number(moduleWorkspaceID)}
          libraryType="themis_generated"
          onToggleSection={handleToggleSection}
          onChangeGroupedRows={setGroupedRows}
        />
      )}
      {activeScreen.id === "Scoring" && <GroupScoringPage />}
    </Table>
  );
}
export default observer(RiskRegister);
