import { faker } from "@faker-js/faker";

import type { File } from "../models/File";

export function createFile(
  data: NonNullable<Partial<File>> = {},
): NonNullable<File> {
  return {
    ...{
      content_type: faker.string.alpha(),
      created_at: faker.date.anytime().toISOString(),
      file_name: faker.string.alpha(),
      file_size: faker.string.alpha(),
      file_url: faker.string.alpha(),
      signed_id: faker.string.alpha(),
    },
    ...data,
  };
}
