import React from "react";

import { useApiAuthGet } from "@/api/queries/apiAuth";
import CompanySettingsNavigation from "@/components/settings/CompanySettingsNavigation";
import { useMainStore } from "@/contexts/Store";

import { WebMonitoringApiKeyForm } from "./WebMonitoringApiKeyForm";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32 tw-pt-10">
      {children}
    </div>
  );
}

function WebMonitoringApiKey() {
  // Import MobX stores
  const mainStore = useMainStore();
  const { company } = mainStore.companies;
  const companyId = Number(company.id);

  const { data } = useApiAuthGet({ company_id: companyId });

  const rows = data?.data?.sort((a, b) => a.id - b.id);

  return (
    <div className="settings-wrap company-info-settings-container">
      <CompanySettingsNavigation />

      <div className="workspace-dropdown-wrap workspace-dropdown-integration">
        <div className="workspace-dropdown">
          <FormContainer>
            <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
              Sei Api Keys
            </h2>
            <div>
              {rows?.map((item) => (
                <WebMonitoringApiKeyForm
                  key={item.id}
                  defaultValues={item}
                  companyId={companyId}
                />
              ))}
              {(!rows || (rows && rows.length < 1)) && (
                <WebMonitoringApiKeyForm
                  defaultValues={undefined}
                  companyId={companyId}
                />
              )}
            </div>
          </FormContainer>
        </div>
      </div>
    </div>
  );
}

export default WebMonitoringApiKey;
