import { Button, IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import Unlock from "../shared/Unlock";

interface Props {
  procedureRecordVersion: RecordVersion;
  isClosed?: boolean;
  isCompleted?: boolean;
}

function QAProcedureStepsContextMenu({
  procedureRecordVersion,
  isClosed,
  isCompleted,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  // Variables
  const { canDeleteRecords } = mainStore.userPermissions;
  const listPointsClasses = classNames("list-points");
  const history = useHistory();
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const testRecordVersionId = Number(record_version_id);
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;
  const { taskDetail } = mainStore;

  // Functions
  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  async function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.qa.deleteProcedureStep(procedureRecordVersion.record_id);
      mainStore.toast.setText("Procedure step deleted!");
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  function close() {
    mainStore.qa.closeQAProcedure(procedureRecordVersion.id);
  }

  function reOpen() {
    mainStore.qa.reopenQAProcedure(procedureRecordVersion.id);
  }

  function handleClick() {
    history.push(
      `/workspaces/${workspaceID}/modules/qa-tests/${testRecordVersionId}/view-procedures/${procedureRecordVersion.id}`,
    );
  }

  const handleCreateTask = () => {
    handlePopUpClose();
    taskDetail.openFromRecord(procedureRecordVersion);
  };

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="qa-procedure-steps-context-menu-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="qa-procedure-steps-context-menu-dropdown"
    >
      <ul>
        <li onClick={handleClick} data-testid="view-detail">
          View Procedure Step Detail
        </li>
        <li onClick={handleCreateTask}>Create Task</li>

        {!isCompleted && canDeleteRecords && (
          <>
            <hr />
            <li
              onClick={deleteRowConfirmation}
              data-testid="qa-procedure-steps-context-menu-dropdown-delete"
            >
              Delete
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Procedure"
      content="Are you sure you want to delete this procedure step? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  const renderButton = isClosed ? (
    <Unlock
      onYes={reOpen}
      tableName="Default"
      moduleIdentifier="qa_procedures"
      buttonText="Reopen"
      actualModelName="Procedure"
    />
  ) : (
    <Button color="tertiary" size="md" onClick={close}>
      Close
    </Button>
  );

  return (
    <div
      className={listPointsClasses}
      data-testid="qa-procedure-steps-context-menu"
    >
      {!isCompleted && hasModuleWriteAccess && renderButton}
      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

QAProcedureStepsContextMenu.defaultProps = {
  isClosed: false,
};

export default observer(QAProcedureStepsContextMenu);
