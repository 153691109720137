import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const PushRight = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.666 2.50008C16.666 2.03984 17.0391 1.66675 17.4993 1.66675C17.9596 1.66675 18.3327 2.03984 18.3327 2.50008V17.5001C18.3327 17.9603 17.9596 18.3334 17.4993 18.3334C17.0391 18.3334 16.666 17.9603 16.666 17.5001V2.50008ZM14.9993 10.0001L14.996 10.0684L14.9825 10.1674L14.9579 10.2602L14.9213 10.3528L14.8777 10.434L14.8358 10.496L14.7553 10.5893L11.4219 13.9227C11.0965 14.2481 10.5689 14.2481 10.2434 13.9227C9.94302 13.6223 9.91992 13.1496 10.1741 12.8227L10.2434 12.7442L12.1535 10.8334H2.49935C2.03911 10.8334 1.66602 10.4603 1.66602 10.0001C1.66602 9.53984 2.03911 9.16675 2.49935 9.16675H12.1535L10.2434 7.256C9.94302 6.9556 9.91992 6.48291 10.1741 6.156L10.2434 6.07749C10.5438 5.77709 11.0165 5.75398 11.3434 6.00817L11.4219 6.07749L14.7553 9.41083L14.7898 9.44746L14.8357 9.50399L14.8955 9.5967L14.9213 9.6475L14.9494 9.71537L14.9695 9.77842L14.9937 9.90219L14.9993 10.0001Z"
      fill="currentColor"
    />
  </svg>
);

PushRight.displayName = "PushRightIcon";

export default PushRight;
