import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import PartnerDetailsForm from "@/features/risk-assessment/pages/PartnerDetailsForm";

import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import DashboardContent from "../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import ModuleHeader from "../components/ModuleHeader/ModuleHeader";
import PartnerTabs from "../components/PartnerTabs";
import { routes } from "../routes";
import { getPartnerName } from "../utils/partners";

function PartnerDetailsPage({ isNew }: { isNew?: boolean }) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { workspaceID, moduleWorkspaceID } = mainStore.context;

  // Hooks
  const history = useHistory();
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const partnerName = getPartnerName(
    mainStore.riskAssessmentPartners.partners,
    record_version_id,
  );

  // Effects
  useEffect(() => {
    if (moduleWorkspaceID) {
      mainStore.sectionTags.index(moduleWorkspaceID);
      return () => mainStore.riskAssessmentPartners.abort();
    }
  }, [moduleWorkspaceID]);

  return (
    <DashboardContent>
      <DashboardHeader
        title={`${partnerName} Partner Details`.trim()}
        onBackClick={() =>
          history.push(
            generatePath(routes.PARTNERS_PATH, {
              workspace_id: Number(workspaceID),
            }),
          )
        }
        LeftActionBar={<ViewModuleUsers />}
      />
      <DashboardContentWrapper>
        {!isNew && (
          <ModuleHeader>
            <PartnerTabs recordVersionId={record_version_id} />
          </ModuleHeader>
        )}
        <PartnerDetailsForm isNew={isNew} />
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(PartnerDetailsPage);
