import classNames from "classnames";
import React from "react";

export interface LoadingProps {
  loadingLayout:
    | "table"
    | "sections"
    | "grid"
    | "small-table"
    | "table-no-add-new"
    | "single-file-form";
  showTableHeader?: boolean;
  fullWidth?: boolean;
  className?: string;
}

function Loading({
  loadingLayout,
  showTableHeader,
  fullWidth,
  className,
}: LoadingProps) {
  const renderHeadBlock = Array(4)
    .fill(0)
    .map((_, id) => <div key={id} className="head-block" />);

  const renderSingleBlock = Array(10)
    .fill(0)
    .map((_, id) => (
      <div key={id} className="block-wrap single-loading">
        <div className="small-block" />
        <div className="big-block" />
      </div>
    ));

  const renderMultipleBlock = Array(3)
    .fill(0)
    .map((_, id) => (
      <div key={id} className="multiple-loading">
        <div className="long-block" />
        <div className="border-block" />
        {Array(2)
          .fill(0)
          .map((elem, block) => (
            <div key={block} className="block-wrap">
              <div className="small-block" />
              <div className="big-block" />
            </div>
          ))}
      </div>
    ));

  const renderSquareBlock = Array(16)
    .fill(0)
    .map((_, id) => (
      <div key={id} className="square-block">
        <div className="square-block-element" />
      </div>
    ));

  // @ts-expect-error TS(7006) FIXME: Parameter 'numberOfBlocks' implicitly has an 'any'... Remove this comment to see the full error message
  const renderSmallBlock = (numberOfBlocks) =>
    Array(3)
      .fill(0)
      .map((_, id) => (
        <div key={id} className="small-element-wrap">
          <div className="head-block" />
          {Array(numberOfBlocks)
            .fill(0)
            .map((elem, block) => (
              <div key={block} className="block-wrap single-loading">
                <div className="big-block" />
              </div>
            ))}
        </div>
      ));

  return (
    <div
      className={classNames("loading-wrap", className, {
        "full-width": fullWidth,
      })}
      aria-label="loading"
      data-testid="loading"
      role="status"
    >
      {showTableHeader && <div className="head-wrap">{renderHeadBlock}</div>}
      {loadingLayout === "table" && (
        <>
          <div className="long-block" />
          <div className="border-block" />
          <div>{renderSingleBlock}</div>
        </>
      )}
      {loadingLayout === "sections" && renderMultipleBlock}
      {loadingLayout === "grid" && (
        <div className="square-block-container">
          <div className="square-block-wrap">{renderSquareBlock}</div>
        </div>
      )}
      {loadingLayout === "small-table" && (
        <div className="small-table-wrap">
          {renderSmallBlock(3)}
          {renderSmallBlock(2)}
        </div>
      )}
      {loadingLayout === "single-file-form" && (
        <div className="tw-m-auto tw-grid tw-w-full tw-max-w-[864px] tw-grid-cols-1 tw-gap-x-16 tw-pt-3">
          {renderSmallBlock(1)}
        </div>
      )}
      {loadingLayout === "table-no-add-new" && <div>{renderSingleBlock}</div>}
    </div>
  );
}

export default Loading;
