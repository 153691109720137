import { http } from "msw";

import { createDeleteThemisDocumentMutationResponse } from "../createDeleteThemisDocument";

export const deleteThemisDocumentHandler = http.delete(
  "*/themis_documents/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteThemisDocumentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
