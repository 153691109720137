import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import plusBlack from "../../../../images/table-image/icon/plus-black.svg";

interface Props {
  fieldName: string;
  hasErrorClass: string;
  hasErrors: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedIDs: any[];
  singleSelection: boolean;
  width: number | string;
  errorMessage?: string;
  locked?: boolean;
  isUserSection?: boolean;
  pinned?: boolean;
  recordVersionID?: number;
  onDataChange?: (
    fieldName: string,
    serializedValue: unknown,
    value?: unknown,
  ) => void;
}

function DepartmentSelect({
  fieldName,
  recordVersionID,
  selectedIDs,
  singleSelection,
  width,
  hasErrors,
  errorMessage,
  locked,
  pinned,
  hasErrorClass,
  isUserSection = false,
  onDataChange,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Refs
  const departmentTitleInputRef = useRef<HTMLInputElement>(null);

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [ids, setIds] = useState(selectedIDs);
  const [addNew, setAddNew] = useState(false);
  const [departmentTitle, setDepartmentTitle] = useState("");
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  if (selectedIDs !== ids && !isSelectModalOpen) {
    setIds(selectedIDs);
  }

  // Effects
  useEffect(() => {
    if (addNew) {
      departmentTitleInputRef.current?.focus();
    }
  }, [addNew]);

  const refreshDepartments = () => {
    const { company } = mainStore.companies;
    if (company && company.id) {
      mainStore.departments.index(company.id);
    }
  };

  const addItemById = (id: number) => {
    const newIds = singleSelection ? [id] : [...ids, id];

    setIds(newIds);

    if (onDataChange) {
      onDataChange(fieldName, { ids: newIds });
    }

    if (singleSelection) {
      setShowPopup(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      createNewDepartment();
    }
  };

  const createNewDepartment = () => {
    if (departmentTitle.length === 0) {
      return;
    }

    const departmentParams = {
      title: departmentTitleInputRef.current?.value,
    };

    const { company } = mainStore.companies;
    if (company && company.id) {
      mainStore.departments.createWithParams(company.id, departmentParams);
    }

    setAddNew(false);
    setDepartmentTitle("");
  };

  const departmentTitleChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDepartmentTitle(event.target.value);
  };

  // Popup lifecycle
  const onOpen = () => {
    refreshDepartments();
    setShowPopup(true);
    setIsSelectModalOpen(true);
  };

  const onClose = () => {
    setShowPopup(false);
    setIsSelectModalOpen(false);

    if (ids === selectedIDs) {
      return;
    }

    if (isUserSection) {
      if (fieldName) {
        const department = mainStore.departments.departments.find(
          (dept) => dept.id === ids[0],
        );

        if (department) {
          mainStore.users.updateSelectedUser(fieldName, department);
        }
      }
    } else {
      mainStore.recordVersions.update({
        fieldName,
        recordVersionID,
        value: mainStore.avroSchemas.serializeValue(fieldName, ids),
      });
    }

    setAddNew(false);
    setDepartmentTitle("");
  };

  const liClassNames = classNames("department-tag", {
    active: isSelectModalOpen,
    [hasErrorClass]: hasErrors,
    illumination: isSelectModalOpen,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isReadOnly,
    "table-cell--disabled": isReadOnly,
    pinned,
  });

  return (
    <Popup
      position="bottom left"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => (
        <li
          className={liClassNames}
          style={{ width }}
          data-testid="departments-cell-trigger"
        >
          <div className="cell-content">
            <div className="users">
              {ids?.length === 0 && !hasErrors && (
                <p
                  className={classNames(
                    "users-placeholder",
                    "nodata",
                    "users-circle-placeholder",
                    {
                      "placeholder-locked": locked,
                    },
                  )}
                >
                  {locked ? "N/A" : "- Select -"}
                </p>
              )}

              {hasErrors ? (
                <div>{errorMessage}</div>
              ) : (
                mainStore.departments.departments
                  .filter((element) => selectedIDs?.includes(element.id))
                  .map((item) => (
                    <span
                      title={item.title}
                      key={item.id}
                      className="department-tag__name"
                    >
                      {item.title}
                    </span>
                  ))
              )}
            </div>
          </div>
        </li>
      )}
      open={showPopup}
      onOpen={onOpen}
      onClose={onClose}
      keepTooltipInside
    >
      <div className="select-small-wrap" data-testid="departments-cell-list">
        <ul>
          {mainStore.departments.departments.map((item) => (
            <li
              key={item.id}
              title={item.title}
              className={classNames({ active: ids?.includes(item.id) })}
              data-testid="departments-cell-dropdown-item"
              onClick={() => addItemById(item.id)}
            >
              {item.title}
            </li>
          ))}
        </ul>
        {mainStore.userPermissions.canManageTags && (
          <div className="option-link">
            {!addNew && (
              <button
                className="option-link-element option-add-new"
                data-testid="departments-cell-add-new-button"
                onClick={() => setAddNew(true)}
              >
                Add New +
              </button>
            )}

            {addNew && (
              <div className="option-link-element option-link-form">
                <input
                  data-testid="departments-cell-add-new-input"
                  type="text"
                  placeholder="Add New +"
                  ref={departmentTitleInputRef}
                  value={departmentTitle}
                  onChange={departmentTitleChanged}
                  onKeyDown={handleKeyDown}
                />
                <button onClick={createNewDepartment}>
                  <img src={plusBlack} alt="plus-black" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </Popup>
  );
}

DepartmentSelect.defaultProps = {
  hasErrorClass: "has-errors",
};

export default observer(DepartmentSelect);
