import { faker } from "@faker-js/faker";

import type { RecordVersionData } from "../models/RecordVersionData";

export function createRecordVersionData(
  data: NonNullable<Partial<RecordVersionData>> = {},
): NonNullable<RecordVersionData> {
  return {
    ...{
      material: {
        options: faker.helpers.arrayElements([
          faker.helpers.arrayElement<any>(["yes", "no"]),
        ]) as any,
      },
      pii_categories: {
        options: faker.helpers.arrayElements([
          faker.helpers.arrayElement<any>([
            "telephone_number",
            "date_of_birth",
            "credit_card_numbers",
            "driver_s_license_number",
            "passport_number",
            "social_security_number",
            "email_address",
            "home_address",
            "full_name",
          ]),
        ]) as any,
      },
      pii_provided: {
        options: faker.helpers.arrayElements([
          faker.helpers.arrayElement<any>(["yes", "no"]),
        ]) as any,
      },
      risk_level: {
        options: faker.helpers.arrayElements([
          faker.helpers.arrayElement<any>(["low", "medium", "high"]),
        ]) as any,
      },
    },
    ...data,
  };
}
