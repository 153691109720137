import classNames from "classnames";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useReducer } from "react";
import { useLocation } from "react-router-dom";

import type { Field } from "@/api";
import { useMainStore } from "@/contexts/Store";
import { useTabDetection } from "@/hooks/useTabDetection";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import { Icon } from "../Elements";
import Checkbox from "../table/shared/Checkbox";
import { EMPTY_OPTIONS } from "./constants";

const DEBOUNCE_TIMEOUT = 250;
const MINIMUM_SEARCH_LENGTH_FOR_TYPEAHEAD = 3;

interface Props {
  field: Field;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOptionSelect?: (...args: any[]) => any;
  hideEmptyConditions?: boolean;
  handleConditionSelectNew?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter: any,
    condition: string,
    fieldName?: string,
  ) => void;
}

function FiltersOptionsSelect({
  field,
  onOptionSelect,
  hideEmptyConditions,
  handleConditionSelectNew,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { getTableFiltersParam, isFilterOptionPresent } = useUpdateFilter();

  // State
  const location = useLocation();
  const tab = useTabDetection(location);

  const [data, setData] = useReducer(
    // @ts-expect-error TS(7006) FIXME: Parameter 'prev' implicitly has an 'any' type.
    (prev, action) => ({ ...prev, ...action }),
    {
      loading: false,
      search: "",
      filteredOptionsWithLabels: [],
      confirmedSelectedOptions: [],
      modes: mainStore.filters.getFilterOptionsGeneratingModes(field.data_type),
    },
  );

  // Vars
  const { moduleWorkspaceID, tableName } = mainStore.context;

  const filters = getTableFiltersParam();

  const selectedOptions =
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    filters.find((item) => item.name === field.name)?.options || [];
  const isSelectionDisabled = selectedOptions.length > 1 && data.modes.calendar;
  const isTagUserType = field.data_type === "com.askthemis.types.v1.tag_user";
  const isTagUserContactType =
    field.data_type === "com.askthemis.types.v1.tag_user_contact";
  const isIntegerType = field.data_type === "com.askthemis.types.v1.integer";
  const isUserType = isTagUserType || isTagUserContactType;

  const allUserAndContactsIDs = mainStore.users.users.map((user) => user.id);
  const deletedUserIDs = mainStore.users.deletedUsers.map((user) => user.id);

  // effects
  useEffect(() => {
    mainStore.filters.setTypeaheadOptions([]);
    if (!data.modes?.typeahead) {
      return;
    }

    fetchSearchResults("");
  }, [data.modes]);

  useEffect(() => {
    setData({
      filteredOptionsWithLabels: generateFilteredOptions(),
      confirmedSelectedOptions: selectedOptions,
    });
  }, [selectedOptions?.length]);

  // memo
  const debouncedHandleFetchSearchResults = useMemo(
    () => debounce(fetchSearchResults, DEBOUNCE_TIMEOUT),
    [],
  );

  // funcs
  function generateFilteredOptions({ search = "", propModes = null } = {}) {
    // vars
    const modes =
      propModes ||
      mainStore.filters.getFilterOptionsGeneratingModes(field.data_type);

    // generate basic options for this mode
    let options = [];
    if (modes.typeahead) {
      options = mainStore.filters.typeaheadOptions;
    } else {
      options =
        mainStore.avroSchemas.getAvailableOptionsForFieldType(
          field.data_type,
          field.name,
        ) || [];
    }

    // remove already selected options from available options list
    options = options.filter(
      (item) =>
        !selectedOptions.find(
          // @ts-expect-error TS(7006) FIXME: Parameter 'opt' implicitly has an 'any' type.
          (opt) =>
            // eslint-disable-next-line no-nested-ternary
            (modes.calendar
              ? dayjs(opt.value).isSame(item.value)
              : opt.value?.option
              ? opt.value.option === item.value?.option
              : opt.value === item.value) && opt.label === item.label,
        ),
    );

    // show users
    if (isUserType) {
      if (isTagUserType) {
        options = options.filter((option) =>
          allUserAndContactsIDs.includes(option.value),
        );
      }
      options = options.filter(
        (option) => !deletedUserIDs.includes(option.value),
      );
    }

    if (isIntegerType) {
      return options.filter((option) => option.label);
    }
    // return filtered options for String
    return options.filter(
      (option) => option.label?.toLowerCase()?.includes(search.toLowerCase()),
    );
  }

  // set search; call debounced func (with request) for typeahead scenario
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleSearchChange(e) {
    const { value: search } = e.target;
    const newData = { search };

    if (
      data.modes.typeahead &&
      search.length >= MINIMUM_SEARCH_LENGTH_FOR_TYPEAHEAD
    ) {
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type '{ searc... Remove this comment to see the full error message
      newData.loading = true;
      debouncedHandleFetchSearchResults(search);
    }

    // @ts-expect-error TS(2339) FIXME: Property 'filteredOptionsWithLabels' does not exis... Remove this comment to see the full error message
    newData.filteredOptionsWithLabels = generateFilteredOptions({
      search,
      propModes: data.modes,
    });
    setData(newData);
  }

  // fetch options by search value
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  async function fetchSearchResults(value) {
    if (moduleWorkspaceID && tableName) {
      await mainStore.filters.fetchTypeaheadOptions({
        moduleWorkspaceID,
        tableName,
        value,
        fieldName: field.name,
        dataType: field.data_type,
        tab,
      });
      setData({
        filteredOptionsWithLabels: generateFilteredOptions({
          search: value,
          propModes: data.modes,
        }),
        loading: false,
      });
    }
  }

  // renders
  const renderHeading = (
    <h5 data-testid="filters-options-select-heading">{field.display_name}</h5>
  );

  const renderSearch = (
    <div
      className="options-search-container"
      data-testid="filters-options-select-search"
    >
      <input
        type="text"
        placeholder="Search..."
        autoComplete="off"
        onChange={handleSearchChange}
        value={data.search}
      />
      <Icon name="search" color="generalDark" size="de" />
    </div>
  );

  const renderPlaceholder = () => {
    const renderContent = () => {
      if (data.loading) {
        return "Loading...";
      }
      if (
        data.modes.typeahead &&
        data.search.length < MINIMUM_SEARCH_LENGTH_FOR_TYPEAHEAD
      ) {
        return "Type at least 3 chars to get started...";
      }

      return "-No options available-";
    };

    return (
      <p
        className="options-empty"
        data-testid="filters-options-select-placeholder"
      >
        {renderContent()}
      </p>
    );
  };

  const renderEmptyConditions = () => (
    <div className="empty-conditions">
      {EMPTY_OPTIONS.map((item) => (
        <div
          key={item}
          className="item"
          onClick={() => {
            const filter = filters.find(
              (f: { condition: string; name: string }) =>
                f.condition === item && f.name === field.name,
            );
            handleConditionSelectNew?.(filter, item, field.name);
          }}
          data-testid="item"
        >
          {item}
        </div>
      ))}
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'options' implicitly has an 'any' type.
  const renderOptions = (options, selected = false) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
    options.map((option, value) => (
      <div
        key={`${option.name}-${value}`}
        className={classNames("options-list-item", {
          disabled: isSelectionDisabled && !selected,
        })}
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClick={() => onOptionSelect(option, field.name)}
        data-testid={`filters-options-select-item-${
          selected ? "selected" : "available"
        }`}
      >
        <Checkbox
          checked={isFilterOptionPresent(option, field.name)}
          data-testid="filters-options-select-item-checkbox"
          disabled
        />
        {option.label}
      </div>
    ));

  const hideOptions = field.data_type === "com.askthemis.types.v1.attachment";

  return (
    <div className="options-list" data-testid="filters-options-select">
      {renderHeading}
      {renderSearch}
      {!hideEmptyConditions && renderEmptyConditions()}
      {!hideOptions && (
        <div
          className="filters-popup-scrollable-content"
          data-testid="filters-options-select-content"
        >
          {renderOptions(data.confirmedSelectedOptions, true)}
          {data.filteredOptionsWithLabels.length === 0 &&
          data.confirmedSelectedOptions.length === 0
            ? renderPlaceholder()
            : renderOptions(data.filteredOptionsWithLabels)}
        </div>
      )}
    </div>
  );
}

FiltersOptionsSelect.defaultProps = {
  onOptionSelect: () => {},
};

export default observer(FiltersOptionsSelect);
