import { http } from "msw";

import { createCreateFindingMutationResponse } from "../createCreateFinding";

export const createFindingHandler = http.post(
  "*/findings",
  function handler(info) {
    return new Response(JSON.stringify(createCreateFindingMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
