import { http } from "msw";

import { createRecordVersionQueryResponse } from "../createRecordVersion";

export const recordVersionHandler = http.get(
  "*/record_versions/:id",
  function handler(info) {
    return new Response(JSON.stringify(createRecordVersionQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
