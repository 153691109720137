import React from "react";

export const CreateDisplayFollowUpSidebarContext = React.createContext<{
  openDisplaySidebar: (
    questionID: number,
    questionText?: string,
    questionOrder?: string,
  ) => void;
  closeDisplaySidebar: () => void;
}>(undefined!);

export const useDisplayFollowUpSidebar = () =>
  React.useContext(CreateDisplayFollowUpSidebarContext);
