import { faker } from "@faker-js/faker";

import type {
  ListTags200,
  ListTagsPathParams,
  ListTagsQueryResponse,
} from "../models/ListTags";
import { createTag } from "./createTag";

export function createListTagsPathParams(): NonNullable<ListTagsPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListTags200(): NonNullable<ListTags200> {
  return faker.helpers.arrayElements([createTag()]) as any;
}

/**
 * @description OK
 */
export function createListTagsQueryResponse(): NonNullable<ListTagsQueryResponse> {
  return faker.helpers.arrayElements([createTag()]) as any;
}
