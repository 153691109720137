import { http } from "msw";

import { createDeleteContactMutationResponse } from "../createDeleteContact";

export const deleteContactHandler = http.delete(
  "*/contacts/:id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteContactMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
