import "./risk-areas-page.scss";

import React from "react";

import HeadSelect from "@/components/table/shared/HeadSelect";

import ViewModuleUsers from "../../../../components/dashboard/ViewModuleUsers";
import DashboardContent from "../../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../../components/shared/DashboardHeader";
import ModuleHeader from "../../components/ModuleHeader/ModuleHeader";
import RiskAreaTable from "../../components/RiskAreas/RiskAreaTable/RiskAreaTable";
import RiskAssessmentTabs from "../../components/RiskAssessmentTabs";
import TemplateTabs from "../../components/TemplateTabs";

export default function RiskAreasPage() {
  return (
    <DashboardContent>
      <DashboardHeader
        title="Questionnaires"
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper>
        <ModuleHeader>
          <RiskAssessmentTabs />
        </ModuleHeader>
        <ModuleHeader>
          <TemplateTabs />
        </ModuleHeader>
        <RiskAreaTable />
      </DashboardContentWrapper>
    </DashboardContent>
  );
}
