import { z } from "zod";

import type { FieldConfiguration } from "@/api";

export const getStringSchema = (
  field: FieldConfiguration,
  requiredMessage: string,
) => {
  const schema = field.multiselect ? z.array(z.string()) : z.string();

  if (field.required) {
    return schema.min(1, requiredMessage);
  }

  return schema.optional();
};
