import "../../stylesheets/components/overviewMenu/overview-menu.scss";

import { observer } from "mobx-react";
import React from "react";

import mainStore from "@/stores/Main";

import { IconButton, Typography } from "../Elements";
import NotificationsList from "../home/notifications/NotificationsList";

const OverviewMenu = observer(() => (
  <div className="overview-menu">
    {mainStore.notifications.isOpen && (
      <div className="overview-menu__content">
        <div className="overview-menu__close-panel">
          <IconButton
            className="overview-menu__button"
            icon="pushRight"
            transparent
            color="generalWhite"
            onClick={() => mainStore.notifications.setIsOpen(false)}
          />
          <Typography
            color="generalWhite"
            label="Hide Notifications"
            size="sm"
            weight="bold"
          />
        </div>
        <NotificationsList />
      </div>
    )}
  </div>
));

export default OverviewMenu;
