import { useToast } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";

import {
  useQuestionnairePdfReport,
  useQuestionnaireXlsxReport,
} from "@/api/queries/exports";
import { useFetchFile } from "@/api/queries/use-fetch-file";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";

interface Props {
  questionnaireId: number;
}

function QuestionareExport({ questionnaireId }: Props) {
  const toast = useToast();
  const liClass =
    "text-sky-400 tw-cursor-pointer tw-px-4 tw-py-2.5 hover:tw-bg-primary-25 hover:tw-text-primary-300";

  const { mutateAsync: fetchFile } = useFetchFile();
  const { mutateAsync: questionnairePdf } = useQuestionnairePdfReport({
    questionnaireId,
    onSuccess: () => {
      toast({
        content: "Questionnaire PDF Report has been exported!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to export PDF Report!",
        variant: "error",
      });
    },
  });

  const { mutateAsync: questionnaireXlsx } = useQuestionnaireXlsxReport({
    questionnaireId,
    onSuccess: () => {
      toast({
        content: "Questionnaire XLSX Report has been exported!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to export XLSX Report!",
        variant: "error",
      });
    },
  });

  async function downloadXlsxReport() {
    toast({
      content: "Questionnaire XLSX Report is preparing to be exported",
      variant: "success",
    });

    await questionnaireXlsx();
  }

  async function downloadPDFReport() {
    toast({
      content: "Questionnaire PDF Report is preparing to be exported",
      variant: "success",
    });

    const { data } = await questionnairePdf();

    if (!data) {
      return;
    }

    const blob = await fetchFile(data.file_url);
    return downloadFile(blob, data.file_name);
  }

  return (
    <div className="tw-pt-2">
      <h3 className="tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-text-primaryDim-300 ">
        Select an export option below
      </h3>
      <ul className="tw-text-sm tw-font-semibold tw-text-neutral-500">
        <li className={liClass} onClick={() => downloadXlsxReport()}>
          XLSX
        </li>
        <li className={liClass} onClick={() => downloadPDFReport()}>
          PDF Report
        </li>
      </ul>
    </div>
  );
}

export default observer(QuestionareExport);
