import { http } from "msw";

import { createShowTrainingQueryResponse } from "../createShowTraining";

export const showTrainingHandler = http.get(
  "*/trainings/:id",
  function handler(info) {
    return new Response(JSON.stringify(createShowTrainingQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
