import { http } from "msw";

import { createDeleteContractMutationResponse } from "../createDeleteContract";

export const deleteContractHandler = http.delete(
  "*/contracts/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteContractMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
