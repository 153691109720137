import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupResults200,
  WebsiteMonitoringGroupResultsPathParams,
  WebsiteMonitoringGroupResultsQueryResponse,
} from "../models/WebsiteMonitoringGroupResults";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";

export function createWebsiteMonitoringGroupResultsPathParams(): NonNullable<WebsiteMonitoringGroupResultsPathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupResults200(): NonNullable<WebsiteMonitoringGroupResults200> {
  return { data: createMonitoringGroupAsset() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupResultsQueryResponse(): NonNullable<WebsiteMonitoringGroupResultsQueryResponse> {
  return { data: createMonitoringGroupAsset() };
}
