import { faker } from "@faker-js/faker";

import type {
  ListOfAccountContacts200,
  ListOfAccountContactsPathParams,
  ListOfAccountContactsQueryResponse,
} from "../models/ListOfAccountContacts";
import { createContact } from "./createContact";

export function createListOfAccountContactsPathParams(): NonNullable<ListOfAccountContactsPathParams> {
  return { account_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListOfAccountContacts200(): NonNullable<ListOfAccountContacts200> {
  return { contacts: faker.helpers.arrayElements([createContact()]) as any };
}

/**
 * @description OK
 */
export function createListOfAccountContactsQueryResponse(): NonNullable<ListOfAccountContactsQueryResponse> {
  return { contacts: faker.helpers.arrayElements([createContact()]) as any };
}
