import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { useMainStore } from "@/contexts/Store";

import TextareaCell from "./TextareaCell";

interface Props {
  fieldName: string;
  locked: boolean;
  maxLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  recordVersionID?: number;
  hasReadWriteAccess?: boolean;
}

function ViewTextareaCell({
  recordVersion,
  fieldName,
  recordVersionID,
  locked = false,
  maxLength,
  onDataChange,
  hasReadWriteAccess = false,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const value = useMemo(
    () => mainStore.avroSchemas.valueForField(fieldName, recordVersion?.data),
    [fieldName, recordVersion?.data],
  );

  const cellError = useMemo(
    () => mainStore.recordVersions.errorsForField(recordVersion?.id, fieldName),
    [
      recordVersion?.id,
      fieldName,
      mainStore.recordVersions.cellsErrors,
      mainStore.recordVersions.newRecordVersionErrors,
    ],
  );

  const hasErrors = Boolean(cellError);

  return (
    <TextareaCell
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      initialValue={value}
      locked={locked}
      maxLength={maxLength}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      hasErrorClass="detail-view-has-errors"
      onDataChange={onDataChange}
      hasReadWriteAccess={hasReadWriteAccess}
    />
  );
}

export default observer(ViewTextareaCell);
