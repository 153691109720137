import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import check from "../../../images/table-image/icon/check-small-tail.svg";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaire: any;
}

function QuestionnaireApproval({ questionnaire }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { moduleWorkspaceID } = mainStore.context;

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [approved, setApproved] = useState(false);

  // effects
  useEffect(() => {
    setApproved(Boolean(questionnaire?.approver?.id));
  }, [questionnaire]);

  // Variables
  const buttonClasses = classNames("table-button questionnaire-button", {
    active: isOpenModal,
    unapprove: approved,
  });

  // Functions
  async function handleApproveChange() {
    const toastSuccess = `${questionnaire.name} ${
      approved ? "unapproved" : "approved"
    }!`;

    if (approved) {
      await mainStore.vendors.unapproveChecklist(
        moduleWorkspaceID,
        questionnaire.id,
      );
    } else {
      await mainStore.vendors.approveChecklist(
        moduleWorkspaceID,
        questionnaire.id,
      );
    }

    await mainStore.toast.setInfoText(toastSuccess);
    setApproved(!approved);
    handlePopUpClose();
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
  }

  // elements
  const renderTrigger = (
    <button
      className={buttonClasses}
      data-testid="vdd-questionnaires-approval-trigger"
    >
      {!approved && <img src={check} alt="check-small-tail-icon" />}
      {approved ? "Unapprove" : "Approve"}
    </button>
  );

  const renderConfirmation = (
    <ConfirmationDialog
      heading={approved ? "Unapprove Questionnaire" : "Approve Questionnaire"}
      content={
        approved
          ? "Do you want to unapprove this questionnaire?"
          : "Do you want to approve this questionnaire?"
      }
      handleConfirm={handleApproveChange}
      handleReject={handlePopUpClose}
    />
  );

  return (
    <Popup
      position="bottom right"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={isOpenModal}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      <div className="table-dropdown success-dropdown">
        {renderConfirmation}
      </div>
    </Popup>
  );
}

export default observer(QuestionnaireApproval);
