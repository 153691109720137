import { groupBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import { Icon } from "@/components/Elements";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import KRITabsHeader from "../components/KRITabsHeader";
import KRIThemisLibrarySection from "../components/KRIThemisLibrarySection";
import type { KRICompanyLibraryRecord } from "./types";

const KRIThemisLibraryPage = ({ moduleName }: { moduleName: string }) => {
  const mainStore = useMainStore();
  const { company } = mainStore.companies;
  const history = useHistory();
  const { workspaceID } = mainStore.context;
  const checkedRows = mainStore.keyRiskIndicatorsCompanyLibrary.checkedRowIDs;
  const setCheckedRows =
    mainStore.keyRiskIndicatorsCompanyLibrary.setCheckedRowIDs;
  const [search, setSearch] = useState("");
  const { canManageTags, hasModuleWriteAccess } = mainStore.userPermissions;
  useEffect(() => {
    if (company.id) {
      mainStore.keyRiskIndicatorsCompanyLibrary.index({
        companyID: company.id,
      });
    }
    mainStore.keyRiskIndicatorsCompanyLibrary.setCheckedRowIDs([]);
  }, []);

  const {
    libraryRecords: rows,
  }: { libraryRecords: KRICompanyLibraryRecord[] } =
    mainStore.keyRiskIndicatorsCompanyLibrary;

  const filteredRows = rows.filter((item) => {
    const includesSearch =
      item.name?.toLowerCase().includes(search.toLowerCase()) ||
      item.key_risk_indicators_company_risk_categories[0].name
        .toLowerCase()
        .includes(search.toLowerCase());

    return includesSearch;
  });

  const filteredRowsGroupedBySection = groupBy(
    filteredRows,
    (e) => e.key_risk_indicators_company_risk_categories[0].name,
  );

  const handleRowCheckboxChange = (id: number, isChecked: boolean) => {
    const newCheckedRows: number[] = isChecked
      ? [...checkedRows, id]
      : checkedRows.filter((rowId) => rowId !== id);

    setCheckedRows(newCheckedRows);
  };

  const handleSectionCheckboxChange = (ids: number[], isChecked: boolean) => {
    let newCheckedRows: number[] = [...checkedRows];

    if (isChecked) {
      // Add all of the checked rows that don't yet exist in checkedRows
      ids.forEach((id) => {
        if (!newCheckedRows.includes(id)) {
          newCheckedRows.push(id);
        }
      });
    } else {
      // Remove items in checkedRows that were just unchecked (values in ids)
      newCheckedRows = checkedRows.filter((rowId) => !ids.includes(rowId));
    }

    setCheckedRows(newCheckedRows);
  };

  async function createNew() {
    history.push(
      `/workspaces/${workspaceID}/modules/key-risk-indicators/templates/new`,
    );
  }

  const sectionTitles = Object.keys(filteredRowsGroupedBySection);

  const renderSection = (section: string) => (
    <KRIThemisLibrarySection
      key={section}
      handleRowCheckboxChange={handleRowCheckboxChange}
      handleSectionCheckboxChange={handleSectionCheckboxChange}
      sectionTitle={section}
      rows={filteredRowsGroupedBySection[section]}
      checkedRows={checkedRows}
      type="company"
    />
  );
  const renderPermissionHelper = () => {
    if (!canManageTags) {
      return (
        <div className="settings-explainer kri">
          {`Adding and editing company library records is disabled for users
          without the "Manage Tags" permission. Please talk to your workspace
          administrator if you require this permission.`}
        </div>
      );
    }
    return null;
  };

  const renderSearch = (
    <>
      <input
        type="text"
        placeholder="Search KRIs"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        data-testid="kri-library-search-input"
      />
      <Icon name="search" color="generalDark" size="de" />
    </>
  );

  const canUpdateLibrary = canManageTags;

  return (
    <DashboardContent>
      <DashboardHeader
        title={moduleName}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper disableScroll>
        <KRITabsHeader />
        {sectionTitles?.length === 0 && hasModuleWriteAccess && (
          <AddRecordHeader
            onAddRecord={createNew}
            recordName="Company KRI Library Item"
          />
        )}
        {sectionTitles?.length > 0 && canUpdateLibrary && (
          <AddRecordHeader
            onAddRecord={() =>
              history.push(
                `/workspaces/${workspaceID}/modules/key-risk-indicators/templates/new`,
              )
            }
            recordName="Library Item"
          />
        )}
        {sectionTitles?.length === 0 && hasModuleWriteAccess && (
          <AddRecordHeader
            onAddRecord={createNew}
            recordName="Company KRI Library Item"
          />
        )}
        <div className="kri-library-wrapper">
          <div className="kri-company-library-tools">
            <div
              className="controls-search-container kri-library-search-container"
              data-testid="kri-library-search-container"
            >
              {renderSearch}
            </div>
            {renderPermissionHelper()}
          </div>

          <div className="kri-library-sections-wrapper">
            {sectionTitles?.length === 0 && hasModuleWriteAccess && (
              <div className="create-new-record risk-register library empty active">
                <button
                  type="button"
                  onClick={createNew}
                  data-testid="library-add-new"
                  id="add-first-company-risk-library-item"
                >
                  Add First Company KRI Library Item +
                </button>
              </div>
            )}
            {sectionTitles.map(renderSection)}
          </div>
        </div>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(KRIThemisLibraryPage);
