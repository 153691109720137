import { faker } from "@faker-js/faker";

import type {
  CreateRiskRegisterGroup,
  CreateRiskRegisterGroup201,
  CreateRiskRegisterGroup403,
  CreateRiskRegisterGroupMutationRequest,
  CreateRiskRegisterGroupMutationResponse,
  CreateRiskRegisterGroupPathParams,
} from "../models/CreateRiskRegisterGroup";
import { createRiskRegisterGroup } from "./createRiskRegisterGroup";

export function createCreateRiskRegisterGroup(
  data: NonNullable<Partial<CreateRiskRegisterGroup>> = {},
): NonNullable<CreateRiskRegisterGroup> {
  return {
    ...{
      name: faker.string.alpha(),
      risk_register_ids: faker.helpers.arrayElements([
        faker.number.int(),
      ]) as any,
    },
    ...data,
  };
}

export function createCreateRiskRegisterGroupPathParams(): NonNullable<CreateRiskRegisterGroupPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description CREATED
 */
export function createCreateRiskRegisterGroup201(): NonNullable<CreateRiskRegisterGroup201> {
  return { data: { schema: createRiskRegisterGroup() } };
}

/**
 * @description Forbidden
 */
export function createCreateRiskRegisterGroup403(): NonNullable<CreateRiskRegisterGroup403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateRiskRegisterGroupMutationRequest(): NonNullable<CreateRiskRegisterGroupMutationRequest> {
  return createCreateRiskRegisterGroup();
}

/**
 * @description CREATED
 */
export function createCreateRiskRegisterGroupMutationResponse(): NonNullable<CreateRiskRegisterGroupMutationResponse> {
  return { data: { schema: createRiskRegisterGroup() } };
}
