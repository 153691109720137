import { faker } from "@faker-js/faker";

import type {
  ListRelatables200,
  ListRelatablesPathParams,
  ListRelatablesQueryParams,
  ListRelatablesQueryResponse,
} from "../models/ListRelatables";
import { createPaginatedMeta } from "./createPaginatedMeta";
import { createRelatable } from "./createRelatable";

export function createListRelatablesPathParams(): NonNullable<ListRelatablesPathParams> {
  return { company_id: faker.number.int() };
}

export function createListRelatablesQueryParams(): NonNullable<ListRelatablesQueryParams> {
  return {
    module_workspace_id: faker.number.int(),
    name: faker.string.alpha(),
    offset: faker.number.int(),
    limit: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createListRelatables200(): NonNullable<ListRelatables200> {
  return {
    data: faker.helpers.arrayElements([createRelatable()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createListRelatablesQueryResponse(): NonNullable<ListRelatablesQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createRelatable()]) as any,
    meta: createPaginatedMeta(),
  };
}
