import { useQuery } from "@tanstack/react-query";

function getLocaleMessages(locale: string) {
  switch (locale) {
    case "en":
      return import("../../../lang/compiled/en.json");
    default:
      return import("../../../lang/compiled/en.json");
  }
}

export const useLocaleMessages = (locale: string) =>
  useQuery({
    queryKey: ["locale-messages", locale],
    queryFn: () => getLocaleMessages(locale),
  });
