import { http } from "msw";

import { createRecordsByMonthByWorkspaceQueryResponse } from "../createRecordsByMonthByWorkspace";

export const recordsByMonthByWorkspaceHandler = http.get(
  "*/companies/:company_id/:record_type/reports/records_by_month_by_workspace",
  function handler(info) {
    return new Response(
      JSON.stringify(createRecordsByMonthByWorkspaceQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
