import React, { useEffect, useState } from "react";

import { userColors } from "@/components/constants";
import UsersCircle from "@/components/table/shared/UsersCircle";
import { COLOR } from "@/config/theme";
import type { User } from "@/stores/types/user-types";

import ContentCell from "./ContentCell";

interface Props {
  userIds: number[];
  accessUsers?: User[];
}

function UserCircleCell({ userIds, accessUsers }: Props) {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const userList: User[] = [];

      userIds.forEach((userId) => {
        const user = accessUsers?.find((u) => u.id === userId);
        if (user) {
          userList.push(user);
        }
      });
      setUsers(userList);
    };

    if (userIds.length > 0) {
      fetchUsers();
    }
  }, [userIds, accessUsers]);

  return (
    <ContentCell
      content={
        <div className="user-circle-cell">
          {users.length === 0 ? (
            <div>N/A</div>
          ) : (
            <>
              {users.slice(0, 3).map((user) => (
                <div key={`User-circle-${user.id}`}>
                  <UsersCircle
                    user={user}
                    showFullName={false}
                    additionalStyles={{
                      background: userColors[user.icon_color_index],
                      marginRight: "0",
                    }}
                  />
                </div>
              ))}
              {users.length > 3 && (
                <div key="User-circle-user-count">
                  <UsersCircle
                    user={{
                      full_name: "",
                      initials: users.length - 3,
                      is_contact: false,
                      is_inactive: false,
                      icon_color_index: 3,
                    }}
                    showFullName={false}
                    additionalStyles={{
                      background: COLOR.generalIconGray,
                      marginRight: "0",
                      color: COLOR.generalMidnightDark,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      }
    />
  );
}

export default UserCircleCell;
