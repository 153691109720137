import { http } from "msw";

import { createCreateDepartmentMutationResponse } from "../createCreateDepartment";

export const createDepartmentHandler = http.post(
  "*/companies/:company_id/departments",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateDepartmentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
