import { http } from "msw";

import { createCreateFieldMutationResponse } from "../createCreateField";

export const createFieldHandler = http.post(
  "*/companies/:company_id/:record_type/fields",
  function handler(info) {
    return new Response(JSON.stringify(createCreateFieldMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
