import { http } from "msw";

import { createGetProjectQueryResponse } from "../createGetProject";

export const getProjectHandler = http.get(
  "*/projects/:id",
  function handler(info) {
    return new Response(JSON.stringify(createGetProjectQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
