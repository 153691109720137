import type { ParsedQs } from "qs";
import qs from "qs";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useSearchParams<T = ParsedQs>(): [
  T,
  (newSearchParams: T, replace?: boolean, customizeArray?: boolean) => void,
] {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }) as T,
    [location.search],
  );

  const setSearchParams = useCallback(
    (newSearchParams: T, replace = false, customizeArray = false) => {
      const customizeArrayParams: qs.IStringifyOptions = customizeArray
        ? {
            arrayFormat: "comma", // Customize the array in the desired format. Will get as comma separated [1,2,3]
          }
        : {}; // The default array format

      const newStringifiedParams = qs.stringify(
        newSearchParams,
        customizeArrayParams,
      );

      if (replace) {
        history.replace({ search: newStringifiedParams });
      } else {
        history.push({ search: newStringifiedParams });
      }
    },
    [history],
  );

  return [searchParams, setSearchParams];
}
