import { useIntl } from "react-intl";
import { z } from "zod";

import type { FieldConfiguration, FieldConfigurationFieldType } from "@/api";
import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";

import { getDateValidationSchema } from "./get-date-schema/get-date-schema";
import { getReferenceSchema } from "./get-reference-schema/get-reference-schema";
import { getStringSchema } from "./get-string-schema/get-string-schema";

const getters = {
  date: getDateValidationSchema,
  datetime: getStringSchema,
  reference: getReferenceSchema,
  select: getStringSchema,
  status: getStringSchema,
  text: getStringSchema,
  textarea: getStringSchema,
} satisfies Record<
  FieldConfigurationFieldType,
  (config: FieldConfiguration, requiredMessage: string) => z.ZodType
>;

export function useDynamicValidationSchema(
  customColumnsConfig: FieldConfigurationWithLayout[],
) {
  const { formatMessage } = useIntl();

  return z.object(
    customColumnsConfig.reduce<Record<string, z.ZodType>>((acc, { field }) => {
      const getSchema = getters[field.field_type];

      const requiredMessage = formatMessage(
        { defaultMessage: "{displayName} is required" },
        { displayName: field.display_name },
      );

      acc[field.name] = getSchema(field, requiredMessage);

      return acc;
    }, {}),
  );
}
