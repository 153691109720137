import { faker } from "@faker-js/faker";

import type { Relatable } from "../models/Relatable";

export function createRelatable(
  data: NonNullable<Partial<Relatable>> = {},
): NonNullable<Relatable> {
  return {
    ...{
      id: faker.number.int(),
      identifier: faker.string.alpha(),
      name: faker.string.alpha(),
      path: faker.string.alpha(),
      relatable_type: faker.helpers.arrayElement<any>([
        "record_version",
        "record",
        "answer",
        "training",
        "account",
      ]),
    },
    ...data,
  };
}
