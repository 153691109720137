import { faker } from "@faker-js/faker";

import type { Workspace } from "../models/Workspace";

export function createWorkspace(
  data: NonNullable<Partial<Workspace>> = {},
): NonNullable<Workspace> {
  return {
    ...{ id: faker.number.int(), is_archived: faker.datatype.boolean() },
    ...data,
  };
}
