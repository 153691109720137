import { faker } from "@faker-js/faker";

import type { RiskMethodology } from "../models/RiskMethodology";
import { createRiskRating } from "./createRiskRating";

export function createRiskMethodology(
  data: NonNullable<Partial<RiskMethodology>> = {},
): NonNullable<RiskMethodology> {
  return {
    ...{
      id: faker.number.int(),
      name: faker.string.alpha(),
      lower_range: faker.number.float(),
      higher_range: faker.number.float(),
      weighting_enabled: faker.datatype.boolean(),
      last_edited_by_id: faker.number.int(),
      last_updated_at: faker.date.anytime().toISOString(),
      inherent_risk_ratings: faker.helpers.arrayElements([
        createRiskRating(),
      ]) as any,
      control_risk_ratings: faker.helpers.arrayElements([
        createRiskRating(),
      ]) as any,
      residual_risks: faker.helpers.arrayElements([createRiskRating()]) as any,
      residual_risk_matrices: faker.helpers.arrayElements([
        {
          id: faker.number.int(),
          control_risk_rating: createRiskRating(),
          inherent_risk_rating: createRiskRating(),
          residual_risk: createRiskRating(),
        },
      ]) as any,
    },
    ...data,
  };
}
