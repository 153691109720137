import { z } from "zod";

import type { FieldConfiguration } from "@/api";

export function getDateValidationSchema(
  field: FieldConfiguration,
  requiredMessage: string,
) {
  if (field.required) {
    return z.string().nonempty(requiredMessage).date();
  }

  return z.union([z.string().date(), z.literal(""), z.undefined(), z.null()]);
}
