import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";
import { generatePath, useHistory } from "react-router-dom";

import type { RecordVersion, TableName } from "@/api";
import { useCustomColumns } from "@/components/table/shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "@/components/table/shared/ModuleRecordVersion";
import ModuleTableColumn from "@/components/table/shared/ModuleTableColumn";
import ReorderableTable from "@/components/table/shared/ReorderableTable";
import SectionPopupSettings from "@/components/table/shared/SectionPopupSettings";
import SectionTag from "@/components/table/shared/SectionTag";
import TableMenu from "@/components/table/shared/tableMenu";
import { useMainStore } from "@/contexts/Store";
import { useSortingAndPagination } from "@/hooks/useSortingAndPagination";
import type { Section } from "@/stores/types/section-tags";

import { routes } from "../../routes";

const IGNORED_FIELD_NAMES = [
  "partner_name",
  "business_description",
  "principal_contact_and_title",
  "email_address",
  "phone_number",
];

interface Props {
  moduleWorkspaceID?: number;
  recordVersions?: RecordVersion[];
  sectionTag?: Section;
  tableName?: TableName;
}

function PartnerSection({
  moduleWorkspaceID,
  recordVersions,
  sectionTag,
  tableName,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  // Hooks
  const history = useHistory();

  // Variables
  const { canManageSections } = mainStore.userPermissions;
  const { visibleFields } = mainStore.fields;
  const fields = visibleFields.filter(
    (field) => !IGNORED_FIELD_NAMES.includes(field.name),
  );
  const { workspaceID } = mainStore.context;
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const sectionTagParam = sectionTag?.id
    ? `?section_tag_id=${sectionTag?.id}`
    : "";

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({ store: mainStore.riskAssessmentPartners });

  const addNewPartner = async () => {
    history.push(
      generatePath(`${routes.PARTNERS_DETAILS_NEW_PATH}${sectionTagParam}`, {
        workspace_id: workspaceID as number,
      }),
    );
  };

  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renameClick = () => {
    setIsEditMode(true);
  };

  const deleteSectionTag = () => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const resetMenuActions = () => {
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx: number) => (
    <ModuleRecordVersion
      key={recordVersion.id}
      recordVersion={recordVersion}
      fields={fields}
      moduleWorkspaceID={moduleWorkspaceID}
      tableID={recordVersion.table_id}
      tableName={recordVersion.table_name}
      currentTableName={tableName}
      moduleIdentifier="risk_assessment"
      inSelectMenuActionMode={false}
      isDraggable
      isSorted={false}
      isLockedArchiveTable
      order={idx}
    />
  );

  const renderFields = fields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  return (
    <>
      {sectionTag && (
        <div className="tw-ml-[18px] tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={dropdownClick}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center tw-text-neutral-500",
                {
                  "tw-rotate-180": !isDropdownOpen,
                  "tw-rotate-0 ": isDropdownOpen,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag?.editable && !isEditMode && canManageSections && (
            <SectionPopupSettings
              onRename={renameClick}
              onDelete={deleteSectionTag}
            />
          )}
        </div>
      )}

      {isDropdownOpen && (
        <div
          className="dropdown-table draggable"
          data-testid="risk-assessment-partner-dropdown-table"
          id={sectionTableID}
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="procedures-list-column-first-blocks table-column-reorder">
                  {fields?.length > 0 && (
                    <TableMenu
                      resetMenuActions={resetMenuActions}
                      onlyIncludeOptions={[
                        "selectIndividual",
                        "manageSections",
                      ]}
                    />
                  )}
                </div>
                <div className="list-title-table-wrap">
                  {renderFields}
                  <span className="stretch-cell" />
                </div>
              </div>
              <AddColumnButton onAdd={createColumn} />
            </ul>
          </div>
          <ReorderableTable
            recordVersions={recordVersions}
            isSorted={!!sortByFieldName}
            renderer={renderRow}
            sectionTag={sectionTag}
            onAddNewRow={addNewPartner}
            newRowName="Partner"
          />
        </div>
      )}
    </>
  );
}

export default observer(PartnerSection);
