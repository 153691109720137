import React from "react";

import { Button } from "@/components/Elements";
import Spinner from "@/components/table/shared/Spinner";

export interface IntegrationItemProps {
  icon: string;
  title: string;
  subtitle: string;
  enabled?: boolean;
  onConnect: () => void;
  onConfigure?: () => void;
  onDisconnect?: () => void;
  loadingDisconnect?: boolean;
}

function IntegrationItem({
  icon,
  title,
  subtitle,
  enabled = undefined,
  onConnect,
  onConfigure = undefined,
  onDisconnect = undefined,
  loadingDisconnect,
}: IntegrationItemProps) {
  return (
    <div className="integration-item-wrap">
      <div className="integration-logo">
        <img src={icon} alt="warning-icon" />
      </div>
      <div>
        <div className="integration-title">{title}</div>
        <div className="integration-subtitle">{subtitle}</div>
      </div>
      <div className="integration-button-container">
        {loadingDisconnect && <Spinner />}

        {!loadingDisconnect && !enabled && (
          <Button label="Connect" theme="primary" onClick={onConnect} />
        )}

        {!loadingDisconnect && enabled && onConfigure && (
          <Button label="Configure" theme="tertiary" onClick={onConfigure} />
        )}

        {!loadingDisconnect && enabled && (
          <Button label="Disconnect" theme="tertiary" onClick={onDisconnect} />
        )}
      </div>
    </div>
  );
}

export default IntegrationItem;
