import { faker } from "@faker-js/faker";

import type {
  ListFieldOptions200,
  ListFieldOptions403,
  ListFieldOptionsPathParams,
  ListFieldOptionsQueryResponse,
} from "../models/ListFieldOptions";
import { createFieldOption } from "./createFieldOption";

export function createListFieldOptionsPathParams(): NonNullable<ListFieldOptionsPathParams> {
  return { field_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListFieldOptions200(): NonNullable<ListFieldOptions200> {
  return { data: faker.helpers.arrayElements([createFieldOption()]) as any };
}

/**
 * @description Forbidden
 */
export function createListFieldOptions403(): NonNullable<ListFieldOptions403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createListFieldOptionsQueryResponse(): NonNullable<ListFieldOptionsQueryResponse> {
  return { data: faker.helpers.arrayElements([createFieldOption()]) as any };
}
