import { faker } from "@faker-js/faker";

import type {
  ListOfAccountDocuments200,
  ListOfAccountDocumentsPathParams,
  ListOfAccountDocumentsQueryResponse,
} from "../models/ListOfAccountDocuments";
import { createDocument } from "./createDocument";

export function createListOfAccountDocumentsPathParams(): NonNullable<ListOfAccountDocumentsPathParams> {
  return { account_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListOfAccountDocuments200(): NonNullable<ListOfAccountDocuments200> {
  return { documents: faker.helpers.arrayElements([createDocument()]) as any };
}

/**
 * @description OK
 */
export function createListOfAccountDocumentsQueryResponse(): NonNullable<ListOfAccountDocumentsQueryResponse> {
  return { documents: faker.helpers.arrayElements([createDocument()]) as any };
}
