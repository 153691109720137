import { faker } from "@faker-js/faker";

import type {
  ListPolicies200,
  ListPoliciesPathParams,
  ListPoliciesQueryParams,
  ListPoliciesQueryResponse,
} from "../models/ListPolicies";
import { createField } from "./createField";
import { createRecordVersion } from "./createRecordVersion";
import { createTableFilters } from "./createTableFilters";

export function createListPoliciesPathParams(): NonNullable<ListPoliciesPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createListPoliciesQueryParams(): NonNullable<ListPoliciesQueryParams> {
  return {
    table_title: faker.string.alpha(),
    table_filters: createTableFilters(),
    section_tag_id: faker.number.int(),
    sort_field_name: faker.string.alpha(),
    sort_direction: faker.helpers.arrayElement<any>(["ASC", "DESC"]),
  };
}

/**
 * @description OK
 */
export function createListPolicies200(): NonNullable<ListPolicies200> {
  return {
    fields: faker.helpers.arrayElements([createField()]) as any,
    record_versions: faker.helpers.arrayElements([
      createRecordVersion(),
    ]) as any,
    module_workspace_id: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createListPoliciesQueryResponse(): NonNullable<ListPoliciesQueryResponse> {
  return {
    fields: faker.helpers.arrayElements([createField()]) as any,
    record_versions: faker.helpers.arrayElements([
      createRecordVersion(),
    ]) as any,
    module_workspace_id: faker.number.int(),
  };
}
