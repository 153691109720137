import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateLayoutMutationRequest,
  UpdateLayoutMutationResponse,
  UpdateLayoutPathParams,
} from "../../models/UpdateLayout";

/**
 * @description Update layout
 * @summary Update layout
 * @link /companies/:company_id/:record_type/layouts/:id
 */
export async function updateLayout(
  companyId: UpdateLayoutPathParams["company_id"],
  recordType: UpdateLayoutPathParams["record_type"],
  id: UpdateLayoutPathParams["id"],
  data: UpdateLayoutMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateLayoutMutationResponse>["data"]> {
  const res = await client<
    UpdateLayoutMutationResponse,
    UpdateLayoutMutationRequest
  >({
    method: "put",
    url: `/companies/${companyId}/${recordType}/layouts/${id}`,
    data,
    ...options,
  });
  return res.data;
}
