import { faker } from "@faker-js/faker";

import type {
  SendAttestationList201,
  SendAttestationListMutationResponse,
  SendAttestationListPathParams,
} from "../models/SendAttestationList";
import { createAttestationList } from "./createAttestationList";

export function createSendAttestationListPathParams(): NonNullable<SendAttestationListPathParams> {
  return { attestation_list_id: faker.number.int() };
}

/**
 * @description Job to send attestation list has been created
 */
export function createSendAttestationList201(): NonNullable<SendAttestationList201> {
  return { data: createAttestationList() };
}

/**
 * @description Job to send attestation list has been created
 */
export function createSendAttestationListMutationResponse(): NonNullable<SendAttestationListMutationResponse> {
  return { data: createAttestationList() };
}
