import { faker } from "@faker-js/faker";

import type {
  CountByDate200,
  CountByDateMutationRequest,
  CountByDateMutationResponse,
} from "../models/CountByDate";

/**
 * @description OK
 */
export function createCountByDate200(): NonNullable<CountByDate200> {
  return faker.number.int();
}

export function createCountByDateMutationRequest(): NonNullable<CountByDateMutationRequest> {
  return {
    module_workspace_id: faker.number.int(),
    section_tag_id: faker.number.int(),
    field_name: faker.string.alpha(),
    number_of_days: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createCountByDateMutationResponse(): NonNullable<CountByDateMutationResponse> {
  return faker.number.int();
}
