import { Button, IconButton } from "@themis/ui";
import React, { useState } from "react";
import { PiXBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { ListCommentsPathParams } from "@/api";
import { useComments } from "@/api/queries/comments/useComments";
import { useCreateComment } from "@/api/queries/comments/useCreateComment";
import { useUsers } from "@/api/queries/users/use-users";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { Typography } from "../Elements";
import Spinner from "../table/shared/Spinner";
import Comment from "./comment/Comment";
import { useCommentsRouteParams } from "./useCommentsRouteParams";
import { UserMention } from "./user-mention/user-mention";

interface CommentsSidebarProps {
  detailsPagePath: string;
  recordType: ListCommentsPathParams["record_type"];
}

export function CommentsSidebar({
  detailsPagePath,
  recordType,
}: CommentsSidebarProps) {
  const { formatMessage } = useIntl();
  const {
    isOpen,
    close: closeComments,
    selectedRecordId,
  } = useCommentsRouteParams();
  const { data: comments, status: commentsStatus } = useComments(
    {
      recordType,
      recordId: selectedRecordId,
    },
    { enabled: isOpen },
  );

  const createComment = useCreateComment(
    {
      recordType,
      recordId: selectedRecordId,
    },
    { onSuccess: () => setCommentInput("") },
  );

  const [commentInput, setCommentInput] = useState("");

  const workspaceId = useRouteWorkspaceId();
  const { data: users = [] } = useUsers(workspaceId);

  const handleCreateComment = () =>
    createComment.mutate({ comment: { content: commentInput } });

  return (
    <SlideMenu open={isOpen} closeSlideMenu={closeComments} singleSlideMenu>
      <div className="tw-flex tw-h-14 tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-px-5">
        <label className="tw-text-base tw-font-bold tw-text-primary-600">
          {formatMessage({
            defaultMessage: "Comments",
          })}
        </label>
        <IconButton
          Icon={PiXBold}
          color="transparent"
          onClick={closeComments}
        />
      </div>
      <div className="tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-px-5 tw-py-4">
        <UserMention
          commentInput={commentInput}
          disableSubmit={createComment.isPending}
          onChangeCommentInput={setCommentInput}
          onSubmit={handleCreateComment}
          users={users}
          placeholder={formatMessage({
            defaultMessage: "Add a comment. Use @ to mention a user.",
          })}
        />
      </div>

      <div className="tw-flex tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-px-5 tw-py-2">
        <Button
          color="primary"
          loading={createComment.isPending}
          onClick={() =>
            createComment.mutate({ comment: { content: commentInput } })
          }
          disabled={commentInput.length === 0}
        >
          {formatMessage({
            defaultMessage: "Confirm",
          })}
        </Button>
      </div>
      {commentsStatus === "pending" && (
        <div className="tw-flex tw-justify-center">
          <Spinner />
        </div>
      )}
      {commentsStatus === "error" && (
        <Typography>
          {formatMessage({
            defaultMessage: "An error occurred in loading comments",
          })}
        </Typography>
      )}
      {commentsStatus === "success" &&
        comments.map((comment) => {
          const replies = comments.filter((c) => c.parent_id === comment.id);
          if (comment.parent_id) {
            return null;
          }
          return (
            <Comment
              detailsPagePath={detailsPagePath}
              key={comment.id}
              comment={comment}
              replies={replies}
              recordId={selectedRecordId}
              recordType={recordType}
            />
          );
        })}
      {commentsStatus === "success" && comments.length === 0 && (
        <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-py-6">
          <h3 className="tw-text-neutral-500">
            {formatMessage({
              defaultMessage: "No Comments added yet",
            })}
          </h3>
          <label className="tw-w-80 tw-text-center tw-text-neutral-300">
            {formatMessage({
              defaultMessage:
                "Type in the “Add new comment” field above and hit Enter to submit.",
            })}
          </label>
        </div>
      )}
    </SlideMenu>
  );
}
