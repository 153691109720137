import { noop } from "lodash";
import React from "react";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import Table from "@/components/table/Table";

import PoliciesLibrary from "../components/PoliciesLibrary";
import PoliciesTabHeader from "../components/PoliciesTabHeader";

interface PoliciesLibraryPageProps {
  tableName: string;
  moduleWorkspaceID: number;
  recordName: string;
}

const PoliciesLibraryPage = ({
  tableName,
  moduleWorkspaceID,
  recordName,
}: PoliciesLibraryPageProps) => (
  <DashboardContent>
    <DashboardHeader
      title={`${recordName} Library`}
      LeftActionBar={<ViewModuleUsers />}
      RightActionBar={<HeadSelect />}
    />
    <DashboardContentWrapper>
      <Table>
        <PoliciesTabHeader
          tableName={tableName}
          moduleWorkspaceID={moduleWorkspaceID}
          openImportSlideOut={noop}
          isShowExport={false}
        />
        <div className="policies-page-content">
          <PoliciesLibrary />
        </div>
      </Table>
    </DashboardContentWrapper>
  </DashboardContent>
);

export default PoliciesLibraryPage;
