import { http } from "msw";

import { createUpdateTaskSectionMutationResponse } from "../createUpdateTaskSection";

export const updateTaskSectionHandler = http.put(
  "*/task_sections/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateTaskSectionMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
