import { IconButton } from "@themis/ui";
import React from "react";
import { generatePath, Link, useParams } from "react-router-dom";

import Edit from "@/assets/svgs/edit";
import { Flex, MiniTag } from "@/components/Elements";
import {
  DATE_TAB,
  MODULE_NOTIFICATIONS_EDIT_PATH,
} from "@/features/notifications/pages/constants";
import "@/features/notifications/pages/module-notifications-routes";
import type { ModuleNotificationParams } from "@/features/notifications/types/types";
import type NotificationsRulesStore from "@/stores/data/NotificationsRules";
import type { NotificationRuleData } from "@/stores/types/notification-rule-types";
import {
  ENABLED,
  NOTIFICATION_TYPE,
} from "@/stores/types/notification-rule-types";

import { NotificationText } from "../text/notification-text";
import { getConditionText, getFrequencyText, getNotifyText } from "./helpers";

export function DateNotificationRow(props: {
  notificationRule: NotificationRuleData;
  fieldsForSelectedModule: NotificationsRulesStore["fieldsForSelectedModule"];
}) {
  const params = useParams<ModuleNotificationParams>();
  const { scheduled, recurring } = props.notificationRule.data;

  const notificationTypeText =
    props.notificationRule.data.notification_type.options.includes(
      NOTIFICATION_TYPE.OVERDUE,
    )
      ? "Overdue"
      : "Reminder";

  const isEnabled = props.notificationRule.data.enabled.options.includes(
    ENABLED.TRUE,
  );

  const triggerColumnText = props.fieldsForSelectedModule.find(
    (columnOption) =>
      columnOption.name ===
      (scheduled || recurring)?.trigger_column?.options?.[0],
  )?.display_name;

  return (
    <Flex
      alignCenter
      justifySpaceBetween
      className="notifications-container__single"
    >
      <Flex columnGap={6}>
        <NotificationText label="Send a" />
        <NotificationText
          // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          label={getFrequencyText(scheduled, recurring)}
          accent
        />
        <MiniTag label={notificationTypeText} theme="violet" />
        <NotificationText label="email (" />
        {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <NotificationText label={getNotifyText(scheduled, recurring)} accent />
        <NotificationText
          label={getConditionText(scheduled, recurring)}
          accent
        />
        <NotificationText label="the" accent />
        {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <NotificationText label={triggerColumnText} accent />
        <NotificationText label=")" />
      </Flex>
      <Flex alignCenter columnGap={8}>
        <MiniTag
          label={isEnabled ? "Enabled" : "Disabled"}
          theme={isEnabled ? "green" : "gray"}
        />
        <Link
          className="tw-flex"
          to={{
            pathname: generatePath(MODULE_NOTIFICATIONS_EDIT_PATH, {
              ...params,
              tab: DATE_TAB,
              notificationId: props.notificationRule.id,
            }),
            state: { fromAllTab: !params.tab },
          }}
        >
          <IconButton
            // eslint-disable-next-line react/no-unstable-nested-components
            Icon={() => <Edit width="16px" />}
            color="transparent"
            size="sm"
          />
        </Link>
      </Flex>
    </Flex>
  );
}
