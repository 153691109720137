import DocViewer from "@cyntler/react-doc-viewer";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useFetchFile } from "@/api/queries/use-fetch-file";
import { useMainStore } from "@/contexts/Store";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";
import type { Folder } from "@/stores/types/section-tags";

import CreativeViewImage from "../../shared/creative-view/CreativeViewImage";
import CreativeViewPdf from "../../shared/creative-view/CreativeViewPdf";
import CreativeViewTypeError from "../../shared/creative-view/CreativeViewTypeError";
import CreativeViewVideo from "../../shared/creative-view/CreativeViewVideo";

function SectionChangeManagementCreativeView() {
  const mainStore = useMainStore();
  const { mutateAsync: fetchFile } = useFetchFile();

  // State
  const [numPages, setNumPages] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [docURI, setDocURI] = useState(null);

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'section_id' does not exist on type '{}'.
  const { section_id, file_id } = useParams();
  const sectionID = Number(section_id);
  const fileID = Number(file_id);
  const sectionTags = mainStore.sectionTags.list?.find(
    (section_tag) => section_tag.id === sectionID,
  ) as Folder;

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const file = sectionTags?.files.find((elem) => elem.id === fileID);
  const url = file?.url;
  const contentType = file?.content_type;
  const fileName = file?.name;
  const isPdf = contentType === "application/pdf";
  const isImage =
    contentType === "image/jpeg" ||
    contentType === "image/png" ||
    contentType === "image/gif";
  const isVideo =
    contentType === "video/mp4" || contentType === "video/quicktime";
  const isDoc =
    contentType === "application/msword" ||
    contentType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const isPptx =
    contentType ===
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  const isMicrosoftPackage = isDoc || isPptx;
  const pdf = {
    url,
    fileType: "application/pdf",
  };

  // Effects
  useEffect(() => {
    (async () => {
      if (!sectionID || !fileID) {
        return;
      }
      if (isMicrosoftPackage) {
        const uri = await mainStore.sectionTags.generateDocumentURI(
          sectionID,
          fileID,
        );
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        setDocURI(uri);
      } else {
        setDocURI(null);
      }
    })();
  }, [sectionID, fileID, isMicrosoftPackage]);

  // Refs
  const zoomComponentRef = useRef(null);
  const fileRef = useRef(null);
  const boundsRef = useRef();
  const pdfRef = useRef();

  // Functions
  // @ts-expect-error TS(7031) FIXME: Binding element 'newNumPages' implicitly has an 'a... Remove this comment to see the full error message
  function onPdfDocumentLoadSuccess({ numPages: newNumPages }) {
    setNumPages(newNumPages);
    handleFileLoaded();
  }

  function handleFileLoaded() {
    setZoomLevel(1);
  }

  async function handleDownload() {
    if (!url) {
      return;
    }

    const blob = await fetchFile(url);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    downloadFile(blob, fileName);
  }

  // elements
  const renderImage = (
    <CreativeViewImage
      zoomComponentRef={zoomComponentRef}
      setZoomLevel={setZoomLevel}
      url={url as string}
      fileRef={fileRef}
      handleFileLoaded={handleFileLoaded}
    />
  );

  const renderPdf = (
    <CreativeViewPdf
      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      pdfRef={pdfRef}
      // @ts-expect-error TS(2322) FIXME: Type '{ url: string | undefined; fileType: string;... Remove this comment to see the full error message
      pdf={pdf}
      onPdfDocumentLoadSuccess={onPdfDocumentLoadSuccess}
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
      numPages={numPages}
      zoomLevel={zoomLevel}
    />
  );

  const renderDoc = () => {
    if (!docURI) {
      return null;
    }
    const docs = [{ uri: docURI }];

    return (
      <DocViewer
        data-testid="cv-doc-viewer"
        className="file-viewer"
        style={{ width: "80%" }}
        documents={docs}
        config={{
          header: {
            disableHeader: true,
          },
        }}
      />
    );
  };

  const renderMainContent = () => {
    if (!url) {
      return;
    }
    if (isMicrosoftPackage) {
      return renderDoc();
    }
    if (isPdf) {
      return renderPdf;
    }
    if (isImage) {
      return renderImage;
    }
    if (isVideo) {
      return (
        <CreativeViewVideo
          handleFileLoaded={handleFileLoaded}
          isControls
          url={url}
        />
      );
    }
    return <CreativeViewTypeError handleDownload={handleDownload} />;
  };

  return (
    <div
      className={classNames(
        "creative-file-container creative-file-npa-container",
        { pdf: isPdf },
      )}
      data-testid="cv-file-container"
    >
      <div
        className="creative-draggable-bounds"
        // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={boundsRef}
        data-testid="cv-bounds"
      >
        {renderMainContent()}
      </div>
    </div>
  );
}

export default observer(SectionChangeManagementCreativeView);
