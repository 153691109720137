import { faker } from "@faker-js/faker";

import type {
  PolicyExportPdf200,
  PolicyExportPdf403,
  PolicyExportPdfPathParams,
  PolicyExportPdfQueryParams,
  PolicyExportPdfQueryResponse,
} from "../models/PolicyExportPdf";
import { createFile } from "./createFile";

export function createPolicyExportPdfPathParams(): NonNullable<PolicyExportPdfPathParams> {
  return { id: faker.number.int() };
}

export function createPolicyExportPdfQueryParams(): NonNullable<PolicyExportPdfQueryParams> {
  return {
    sections: faker.helpers.arrayElements([
      faker.helpers.arrayElement<any>([
        "record_details",
        "record_comments_summary",
        "attachments",
        "attachments_comment_summary",
      ]),
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createPolicyExportPdf200(): NonNullable<PolicyExportPdf200> {
  return { data: createFile() };
}

/**
 * @description Forbidden
 */
export function createPolicyExportPdf403(): NonNullable<PolicyExportPdf403> {
  return {
    errors: faker.helpers.arrayElements([
      { code: faker.string.alpha(), message: faker.string.alpha() },
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createPolicyExportPdfQueryResponse(): NonNullable<PolicyExportPdfQueryResponse> {
  return { data: createFile() };
}
