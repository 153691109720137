export interface FeatureFlag {
  flipper_id: string;
  features: { [feature: string]: { enabled: boolean } };
}

type FeatureGateKey =
  | "boolean"
  | "actors"
  | "percentage_of_actors"
  | "percentage_of_time"
  | "groups";

interface FeatureGate {
  key: FeatureGateKey;
  name: string;
  value: string[];
}

export interface FeatureFlagResponse {
  features: {
    gates: FeatureGate[];
    key: FEATURE_FLAG_ID;
    state: FEATURE_STATE;
  }[];
}

export interface FeatureFlagActors {
  companyIds: number[];
  workspaceIds: number[];
  userIds: number[];
}

export type FeatureFlagActorMap = {
  [key in FEATURE_FLAG_ID]?: FeatureFlagActors | boolean;
};

export enum FEATURE_STATE {
  ON = "on",
  OFF = "off",
  CONDITIONAL = "conditional",
}

export enum FEATURE_FLAG_ID {
  COM_PUBLIC_API = "COM-public-api",
  COM_WEBSITE_MONITORING = "COM-website-monitoring",
  GENG_RESOLVE_COMMENT_THREAD = "GENG-resolve-comment-thread",
  GENG_COMMENT_MENTIONS = "GENG-comment-mentions",
  GENG_TRAINING_MODULE_REVAMP = "GENG-training-module-revamp",
  GENG_CUSTOM_COLUMNS = "GENG-custom-columns",
  GENG_PDF_EXPORT_FOR_POLICIES = "GENG-pdf-export-for-policies",
}

export enum ACTOR_TYPE {
  USER = "User",
  WORKSPACE = "Workspace",
  COMPANY = "Company",
}
