import { faker } from "@faker-js/faker";

import type {
  CreateTaskLink201,
  CreateTaskLinkMutationRequest,
  CreateTaskLinkMutationResponse,
  CreateTaskLinkPathParams,
} from "../models/CreateTaskLink";
import { createTaskLink } from "./createTaskLink";

export function createCreateTaskLinkPathParams(): NonNullable<CreateTaskLinkPathParams> {
  return { task_id: faker.number.int() };
}

/**
 * @description Taskable created
 */
export function createCreateTaskLink201(): NonNullable<CreateTaskLink201> {
  return { data: createTaskLink() };
}

export function createCreateTaskLinkMutationRequest(): NonNullable<CreateTaskLinkMutationRequest> {
  return { task_link: createTaskLink() };
}

/**
 * @description Taskable created
 */
export function createCreateTaskLinkMutationResponse(): NonNullable<CreateTaskLinkMutationResponse> {
  return { data: createTaskLink() };
}
