import { faker } from "@faker-js/faker";

import type { TableName } from "../models/TableName";

export function createTableName(): NonNullable<TableName> {
  return faker.helpers.arrayElement<any>([
    "Drafts",
    "Finalized",
    "Archived",
    "PoliciesAttestation",
    "ProceduresDrafts",
    "ProceduresFinalized",
    "ProceduresArchived",
    "ProceduresAttestation",
    "Default",
    "Completed",
    "FINRA Review",
    "FINRA Escalated",
    "Customer Support",
    "Compliance",
    "Partners",
    "RelatedRisks",
    "OperationalControls",
    "Active",
    "Inactive",
  ]);
}
