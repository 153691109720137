import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroups200,
  WebsiteMonitoringGroupsPathParams,
  WebsiteMonitoringGroupsQueryResponse,
} from "../models/WebsiteMonitoringGroups";
import { createMonitoringGroup } from "./createMonitoringGroup";

export function createWebsiteMonitoringGroupsPathParams(): NonNullable<WebsiteMonitoringGroupsPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroups200(): NonNullable<WebsiteMonitoringGroups200> {
  return {
    data: faker.helpers.arrayElements([createMonitoringGroup()]) as any,
  };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupsQueryResponse(): NonNullable<WebsiteMonitoringGroupsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createMonitoringGroup()]) as any,
  };
}
