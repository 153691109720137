import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  createModuleAssessmentDocument,
  listOfModuleAssessmentDocuments,
  moduleAssessmentDeleteDocument,
} from "@/api/gen/axios/documentsController";
import {
  createModuleAssessment,
  deleteModuleAssessment,
  listModuleAssessments,
  showModuleAssessment,
  updateModuleAssessment,
  updateModuleAssessmentStatus,
} from "@/api/gen/axios/moduleAssessmentsController";
import type {
  CreateModuleAssessmentDocumentMutationRequest,
  CreateModuleAssessmentMutationRequest,
  UpdateModuleAssessmentMutationRequest,
  UpdateModuleAssessmentStatusQueryParams,
} from "@/api/gen/models";

export const LIST_MODULE_ASSESSMENT_QUERY_KEY = "list_module_assessment";
export const SHOW_MODULE_ASSESSMENT_QUERY_KEY = "show_module_assessment";
export const DOCUMENTS_MODULE_ASSESSMENT_QUERY_KEY =
  "documents_module_assessment";
export const MODULE_ASSESSMENT_COMPLETE_QUERY_KEY =
  "module_assessment_complete";

export function useListModuleAssessments(recordVersionId: number) {
  return useQuery({
    queryKey: [LIST_MODULE_ASSESSMENT_QUERY_KEY, recordVersionId],
    queryFn: () => listModuleAssessments(recordVersionId),
    enabled: !!recordVersionId,
  });
}

export function useShowModuleAssessments(moduleAssessmentId: number) {
  return useQuery({
    queryKey: [SHOW_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
    queryFn: () => showModuleAssessment(moduleAssessmentId),
    enabled: !!moduleAssessmentId,
  });
}

export function useCreateModuleAssessment({
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateModuleAssessmentMutationRequest) =>
      createModuleAssessment(recordVersionId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [LIST_MODULE_ASSESSMENT_QUERY_KEY, recordVersionId],
      });
      queryClient.invalidateQueries({
        queryKey: [SHOW_MODULE_ASSESSMENT_QUERY_KEY, recordVersionId],
      });
    },
    onError,
    onSuccess,
  });
}

export function useUpdateModuleAssessment({
  moduleAssessmentId,
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  moduleAssessmentId: number;
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateModuleAssessmentMutationRequest) =>
      updateModuleAssessment(moduleAssessmentId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [LIST_MODULE_ASSESSMENT_QUERY_KEY, recordVersionId],
      });
      queryClient.invalidateQueries({
        queryKey: [SHOW_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
      });
    },
    onError,
    onSuccess,
  });
}

export function useDeleteModuleAssessment({
  moduleAssessmentId,
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  moduleAssessmentId: number;
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteModuleAssessment(moduleAssessmentId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [SHOW_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
      });
      queryClient.invalidateQueries({
        queryKey: [LIST_MODULE_ASSESSMENT_QUERY_KEY, recordVersionId],
      });
    },
    onSuccess,
    onError,
  });
}

export function useModuleAssessmentDocuments(moduleAssessmentId: number) {
  return useQuery({
    queryKey: [DOCUMENTS_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
    queryFn: () => listOfModuleAssessmentDocuments(moduleAssessmentId),
    enabled: !!moduleAssessmentId,
  });
}

export function useCreateDocument({
  moduleAssessmentId,
  onSuccess = () => {},
  onError = () => {},
}: {
  moduleAssessmentId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateModuleAssessmentDocumentMutationRequest) =>
      createModuleAssessmentDocument(moduleAssessmentId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
      });
      queryClient.invalidateQueries({
        queryKey: [SHOW_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
      });
    },
    onError,
    onSuccess,
  });
}

export function useDeleteDocument({
  moduleAssessmentId,
  onSuccess = () => {},
  onError = () => {},
}: {
  moduleAssessmentId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (documentId: number) =>
      moduleAssessmentDeleteDocument(documentId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
      });
      queryClient.invalidateQueries({
        queryKey: [SHOW_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
      });
    },
    onError,
    onSuccess,
  });
}

export function useUpdateStatusModuleAssessment({
  moduleAssessmentId,
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  moduleAssessmentId: number;
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateModuleAssessmentStatusQueryParams) =>
      updateModuleAssessmentStatus(moduleAssessmentId, params),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [MODULE_ASSESSMENT_COMPLETE_QUERY_KEY, moduleAssessmentId],
      });
      queryClient.invalidateQueries({
        queryKey: [SHOW_MODULE_ASSESSMENT_QUERY_KEY, moduleAssessmentId],
      });
      queryClient.invalidateQueries({
        queryKey: [LIST_MODULE_ASSESSMENT_QUERY_KEY, recordVersionId],
      });
    },
    onError,
    onSuccess,
  });
}
