import "./styles.scss";

import React, { useState } from "react";

import * as ApiKeysAPI from "@/api/legacy/api-keys";
import { Flex } from "@/components/Elements";
import LoadingWrapper from "@/components/loading-wrapper";
import APIKeyInformation from "@/components/settings/api-keys/APIKeyInformation";
import APIKeyRow from "@/components/settings/api-keys/APIKeyRow";
import CurrentUserSettingsNavigation from "@/components/settings/CurrentUserSettingsNavigation";
import { MessageGroup } from "@/components/shared/message-group/message-group";
import { useLoadableData } from "@/hooks/useLoadableData";

function APIKeys() {
  // State
  const [showModal, setShowModal] = useState(false);

  // Hooks
  const apiKeys = useLoadableData({
    request: () => ApiKeysAPI.getApiKeys(),
    onError: () =>
      "There was an issue loading the API Keys. Please refresh to try again.",
  });

  return (
    <LoadingWrapper
      loading={!apiKeys.data && apiKeys.loading}
      errorText={apiKeys.errorText}
      loadingLayout="small-table"
    >
      <CurrentUserSettingsNavigation />
      <Flex
        column
        justifyCenter
        alignCenter
        rowGap={24}
        className="api-keys-container"
      >
        <MessageGroup
          title="API Keys"
          addButtonText="Create API Key"
          addButtonClick={() => setShowModal(true)}
          initialStateDescription="No API Keys created yet."
        >
          {apiKeys.data?.api_keys.length && (
            <Flex column>
              {apiKeys.data?.api_keys.map((apiKey) => (
                <APIKeyRow
                  key={`${apiKey.id}-${apiKey.title}`}
                  title={apiKey.title}
                  id={apiKey.id}
                  refetch={apiKeys.refetch}
                />
              ))}
            </Flex>
          )}
        </MessageGroup>
      </Flex>
      <APIKeyInformation
        showModal={showModal}
        setShowModal={setShowModal}
        refetch={apiKeys.refetch}
      />
    </LoadingWrapper>
  );
}

export default APIKeys;
