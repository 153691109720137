import { http } from "msw";

import { createUpdateFieldMutationResponse } from "../createUpdateField";

export const updateFieldHandler = http.put(
  "*/companies/:company_id/:record_type/fields/:id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateFieldMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
