import { faker } from "@faker-js/faker";

import type { Attachment } from "../models/Attachment";
import { createAttachmentFile } from "./createAttachmentFile";

export function createAttachment(
  data: NonNullable<Partial<Attachment>> = {},
): NonNullable<Attachment> {
  return {
    ...{
      attachment_group_id: faker.number.int(),
      created_at: faker.string.alpha(),
      file_type: faker.helpers.arrayElement<any>([
        "direct_upload",
        "figma",
        "google_drive",
        "share_point",
        "url_upload",
      ]),
      id: faker.number.int(),
      original: Object.assign({}, createAttachmentFile()),
      preview: Object.assign({}, createAttachmentFile()),
      processed: Object.assign({}, createAttachmentFile()),
      upload_date: faker.string.alpha(),
      url: faker.string.alpha(),
      version: faker.number.int(),
    },
    ...data,
  };
}
