import type { FormatDateOptions } from "react-intl";
import { useIntl } from "react-intl";

import type { DynamicCellProps } from "../dynamic-cell";

const formatDateOptions: FormatDateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

export const DynamicDateCell = ({ value }: DynamicCellProps<string>) => {
  const intl = useIntl();

  const dates = (Array.isArray(value) ? value : [value]).filter(
    (date) => date !== undefined,
  );

  return (
    <ul>
      {dates.map((date, i) => (
        <li key={`${date}-${i}`}>{intl.formatDate(date, formatDateOptions)}</li>
      ))}
    </ul>
  );
};
