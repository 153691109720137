import { http } from "msw";

import { createCreateAccountDocumentMutationResponse } from "../createCreateAccountDocument";

export const createAccountDocumentHandler = http.post(
  "*/accounts/:account_id/documents",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateAccountDocumentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
