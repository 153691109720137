import { observer } from "mobx-react";
import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import Loading from "@/components/Loading";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import { useQuestionnaireData } from "@/features/risk-assessment";

import AssessmentScoring from "../../components/Assessment/AssessmentScoring/AssessmentScoring";
import CWCustomerQuestionnaireDetailTabs from "../../components/CWCustomerQuestionnaireDetailTabs";
import EmptyMessage from "../../components/EmptyMessage/EmptyMessage";
import { ModuleContentWrapper } from "../../components/ModuleContentWrapper/ModuleContentWrapper";
import ModuleHeader from "../../components/ModuleHeader/ModuleHeader";
import { routes } from "../../routes";

function CustomerQuestionnaireScoringPage() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const history = useHistory();
  const activeWorkspaceId: number | undefined =
    mainStore.context.activeWorkspace?.id;
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const { questionnaire, riskMethodology, riskAreas } =
    useQuestionnaireData(activeWorkspaceId);

  if (!questionnaire || !activeWorkspaceId || !riskMethodology || !riskAreas) {
    return <Loading loadingLayout="small-table" />;
  }

  return (
    <DashboardContent>
      <DashboardHeader
        title={questionnaire.name || "Questionnaire"}
        onBackClick={() =>
          history.push(
            generatePath(routes.DASHBOARD_PATH, {
              workspace_id: Number(workspaceID),
            }),
          )
        }
      />
      <DashboardContentWrapper>
        <ModuleHeader>
          <CWCustomerQuestionnaireDetailTabs
            questionnaireId={Number(questionnaireId)}
          />
        </ModuleHeader>
        <ModuleContentWrapper>
          {questionnaire.scoring && (
            <AssessmentScoring
              scoring={questionnaire.scoring}
              riskMethodology={riskMethodology}
              riskAreas={riskAreas}
              headerText="Questionnaire"
            />
          )}
          {!questionnaire.scoring && (
            <EmptyMessage>
              No score available. Questionnaire still in progress.
            </EmptyMessage>
          )}
        </ModuleContentWrapper>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(CustomerQuestionnaireScoringPage);
