import { faker } from "@faker-js/faker";

import type { RiskRating } from "../models/RiskRating";

export function createRiskRating(
  data: NonNullable<Partial<RiskRating>> = {},
): NonNullable<RiskRating> {
  return {
    ...{
      id: faker.number.int(),
      text: faker.string.alpha(),
      risk_type: faker.string.alpha(),
      color: faker.string.alpha(),
      lower_range: faker.number.float(),
      higher_range: faker.number.float(),
    },
    ...data,
  };
}
