import { Button, Checkbox, useToast } from "@themis/ui";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { policyExportPdfQueryParamsSections as pdfParamsSections } from "@/api";
import type { PolicyExportPdfQueryParamsSections } from "@/api";
import { usePolicyExport } from "@/api/queries/policies/use-policy-export";
import { useFetchFile } from "@/api/queries/use-fetch-file";
import { Divider } from "@/components/IconMenu";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";

interface PolicyPdfExportProps {
  recordVersionId: number;
  onPopupClose: () => void;
}

const exportOptionMessages = defineMessages<string>({
  [pdfParamsSections.record_details]: {
    defaultMessage: "Record Details",
  },
  [pdfParamsSections.record_comments_summary]: {
    defaultMessage: "Record Comments Summary",
  },
  [pdfParamsSections.attachments]: {
    defaultMessage: "Attachment(s)",
  },
  [pdfParamsSections.attachments_comment_summary]: {
    defaultMessage: "Attachments Comment Summary",
  },
});

export function PolicyPdfExport({
  recordVersionId,
  onPopupClose,
}: PolicyPdfExportProps) {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { mutateAsync: fetchFile } = useFetchFile();
  const { mutateAsync: getPolicyPdfReport } = usePolicyExport(recordVersionId, {
    onSuccess: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Policies PDF Report has been exported!",
        }),
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Failed to export PDF Report!",
        }),
        variant: "error",
      });
    },
  });

  const [exportOptions, setExportOptions] = useState<Record<string, boolean>>(
    Object.keys(pdfParamsSections).reduce(
      (acc, key) => ({
        ...acc,
        [key]: false,
      }),
      {},
    ),
  );

  const checkboxes = Object.entries(pdfParamsSections).map(([_, value]) => ({
    id: value,
    label: formatMessage(exportOptionMessages[value]),
  }));
  const allChecked = Object.values(exportOptions).every((value) => value);
  const isExportOptionSelected = Object.values(exportOptions).some(
    (value) => value,
  );
  const selectedOptions = Object.keys(exportOptions).filter(
    (key) => exportOptions[key],
  ) as PolicyExportPdfQueryParamsSections[];

  const toggleCheckbox = (id: string) => {
    setExportOptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleAllCheckboxes = (isChecked: boolean) => {
    const updatedOptions = Object.keys(pdfParamsSections).reduce(
      (acc, key) => ({
        ...acc,
        [key]: isChecked,
      }),
      {},
    );

    setExportOptions(updatedOptions);
  };

  async function downloadPdfReport() {
    onPopupClose();
    toast({
      content: formatMessage({
        defaultMessage: "Policies PDF Report is preparing to be exported",
      }),
      variant: "success",
    });

    const data = await getPolicyPdfReport({
      sections: selectedOptions,
    });

    if (!data) {
      toast({
        content: formatMessage({
          defaultMessage: "Failed to export PDF Report!",
        }),
        variant: "error",
      });

      return;
    }

    const blob = await fetchFile(data.file_url);
    downloadFile(blob, data.file_name);
  }

  return (
    <div className="tw-max-w-64 tw-rounded-xl tw-bg-white tw-py-2 tw-shadow-lg">
      <div className="tw-px-4 tw-pb-2 tw-text-sm tw-font-semibold tw-text-primaryDim-300">
        {formatMessage({
          defaultMessage: "Select sections to include in your export",
        })}
      </div>
      <div
        onClick={() => toggleAllCheckboxes(!allChecked)}
        className="tw-group tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-px-4 tw-py-2.5 tw-text-neutral-500 hover:tw-bg-primary-25 hover:tw-text-primaryDim-300"
      >
        <Checkbox color="secondary" size="md" checked={allChecked} />
        <div className="tw-select-none tw-text-sm tw-text-inherit">
          {formatMessage({ defaultMessage: "Export Full PDF Report" })}
        </div>
      </div>
      <Divider />
      <div className="tw-flex tw-flex-col">
        {checkboxes.map(({ id, label }) => (
          <div
            key={id}
            className="tw-group tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-px-4 tw-py-2.5 tw-text-neutral-500 hover:tw-bg-primary-25 hover:tw-text-primaryDim-300"
            onClick={() => toggleCheckbox(id)}
          >
            <Checkbox color="secondary" size="md" checked={exportOptions[id]} />
            <div className="tw-select-none tw-text-sm tw-text-inherit">
              {label}
            </div>
          </div>
        ))}
      </div>
      <Divider />
      <Button
        className="tw-ml-auto tw-mr-4 tw-flex"
        onClick={downloadPdfReport}
        disabled={!isExportOptionSelected}
      >
        {formatMessage({ defaultMessage: "Export" })}
      </Button>
    </div>
  );
}
