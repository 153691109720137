import { http } from "msw";

import { createGetDashboardsQueryResponse } from "../createGetDashboards";

export const getDashboardsHandler = http.get(
  "*/workspaces/:workspace_id/dashboards",
  function handler(info) {
    return new Response(JSON.stringify(createGetDashboardsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
