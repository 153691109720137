import { http } from "msw";

import { createShowContractQueryResponse } from "../createShowContract";

export const showContractHandler = http.get(
  "*/contracts/:id",
  function handler(info) {
    return new Response(JSON.stringify(createShowContractQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
