import { action, makeObservable, observable } from "mobx";

import type { ControlMappingsEffectiveness } from "@/api/gen/models/ControlMappingsEffectiveness";
import legacyApi from "@/api/legacy/legacy-api";
import type { LibraryRecord } from "@/stores/types/control-mapping-types";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class ControlMappings implements ModuleStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError: any;
  mainStore: MainStore;

  // Observable objects
  data = {};
  libraryItem: LibraryRecord | null = null;
  lastTab = "Active";
  libraryRecords: Array<LibraryRecord> = [];
  historical = [];
  moduleWorkspaceID = null;
  controlMappingsEffectiveness: Array<ControlMappingsEffectiveness> = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      libraryItem: observable,
      lastTab: observable,
      libraryRecords: observable,
      moduleWorkspaceID: observable,
      historical: observable,
      controlMappingsEffectiveness: observable,
      setData: action,
      setLibraryItem: action,
      setLastTab: action,
      setLibraryRecords: action,
      setModuleWorkspaceID: action,
      setHistorical: action,
      setControlMappingsEffectiveness: action,
    });

    this.mainStore = mainStore;
  }

  // @route GET /api/react/control_mappings {format: "json"} (api_react_control_mappings)
  async index({
    tab = this.lastTab,
    sortParams,
    tableFilters = [],
  }: IndexParams) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        tab,
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/control_mappings`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.mainStore.fields.setList(response.data.fields);

      this.setData(response.data);
      this.setLastTab(tab);
      this.setModuleWorkspaceID(response.data.module_workspace_id);
      await this.fetchControlMappingsEffectiveness(
        response.data.module_workspace_id,
      );
    } catch (error) {
      window.console.log(
        `"ControlMappings#Index for Workspace" error ${error}`,
      );
    }
  }

  // @route POST /api/react/control_mappings {format: "json"} (api_react_control_mappings)
  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTagID' implicitly has an 'any' t... Remove this comment to see the full error message
  async create(sectionTagID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings`,
        headers: this.mainStore.getHeaders(),
        data: { section_tag_id: sectionTagID === 0 ? null : sectionTagID },
      });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"ControlMappings#create" error ${error}`);
    }
  }

  // @route DELETE /api/react/control_mappings/:id {format: "json"} (api_react_control_mapping)
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/control_mappings/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"ControlMappings#delete" error ${error}`);
    }
  }

  // @route GET /api/react/control_mappings/library {format: "json"} (library_api_react_control_mappings)
  async fetchLibrary(moduleWorkspace: number, libraryType: string) {
    const params = { library_type: libraryType };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/control_mappings/library`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setLibraryRecords(response.data.library_items);
    } catch (error) {
      window.console.log(`"ControlMappings#fetchLibrary" error ${error}`);
    }
  }

  async fetchControlMappingsEffectiveness(moduleWorkspace: number) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `/api/frontend/module_workspaces/${moduleWorkspace}/control_mappings/effectiveness`,
        headers: this.mainStore.getHeaders(),
      });

      this.setControlMappingsEffectiveness(response.data?.data);
    } catch (error) {
      window.console.log(
        `"ControlMappings#fetchControlMappingsEffectiveness" error ${error}`,
      );
    }
  }

  // @route POST /api/react/control_mappings/bulk {format: "json"} (bulk_api_react_control_mappings)
  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  async createBulk(ids, sectionTagID, libraryType) {
    const data = {
      ids,
      section_tag_id: sectionTagID,
      library_type: libraryType,
    };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings/bulk`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.mainStore.toast.setText(
        "Control Mapping Records Being Created. This may take a few minutes.",
      );
    } catch (error) {
      window.console.log(`"ControlMappings#createBulk" error ${error}`);
    }
  }

  // @route POST /api/react/control_mappings/library_items {format: "json"} (library_items_api_react_control_mappings)
  async createLibraryItem() {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings/library_items`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setLibraryRecords([...this.libraryRecords, response.data.item]);
      return response.data.item;
    } catch (error) {
      window.console.log(`"ControlMappings#createLibraryItem" error ${error}`);
    }
  }

  // @route PUT /api/react/control_mappings/library_items/:id {format: "json"}
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async updateLibraryItem(id, params, controlCategory, controlMappingType) {
    const data = {
      data: params,
      control_category_title: controlCategory,
      control_mapping_type_title: controlMappingType,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/control_mappings/library_items/${id}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setLibraryRecords(
        this.libraryRecords.map((item) =>
          item.id === id ? response.data.item : item,
        ),
      );
      this.setLibraryItem({});
      return response.data.item;
    } catch (error) {
      window.console.log(`"ControlMappings#updateLibraryItem" error ${error}`);
    }
  }

  // @route DELETE /api/react/control_mappings/library_items/:id {format: "json"}
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async deleteLibraryItem(id) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/control_mappings/library_items/${id}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setLibraryRecords(
        this.libraryRecords.filter((item) => item.id !== id),
      );
    } catch (error) {
      window.console.log(`"ControlMappings#deleteLibraryItem" error ${error}`);
    }
  }

  // @route POST /api/react/control_mappings/library/export {format: "json"} (library_export_api_react_control_mappings)
  async exportLibrary(moduleWorkspaceID: number, sectionsRecordIds: number[]) {
    const data = {
      control_mapping_library_record_ids: sectionsRecordIds,
      moduleWorkspaceID,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings/library/export`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      return response.data;
    } catch (error) {
      window.console.log(`"ControlMappings#exportLibrary" error ${error}`);
    }
  }

  // @route POST /api/react/control_mappings/library/import {format: "json"} (library_import_api_react_control_mappings)
  async importLibrary(moduleWorkspaceID: number, file: File) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings/library/import`,
        headers: {
          ...this.mainStore.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }
      this.fetchLibrary(moduleWorkspaceID, "company");
    } catch (error) {
      window.console.log(`"ControlMappings#importLibrary" error ${error}`);
    }
  }

  // @route GET /api/react/control_mappings/library/template {format: "json"} (library_template_api_react_control_mappings)
  async downloadLibraryTemplate() {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/control_mappings/library/template`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "An error occurred. Please try again or contact Themis support if the problem persists.",
        );
        return;
      }

      return result.data;
    } catch (error) {
      window.console.log(
        `"ControlMappings#downloadLibraryTemplate" error ${error}`,
      );
    }
  }

  // @route POST /api/react/control_mappings/:id/finalize {format: "json"} (finalize_api_react_control_mapping)
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }
    } catch (error) {
      window.console.log(`"ControlMappings#finalize" error ${error}`);
    }
  }

  // @route POST /api/react/control_mappings/:id/unlock {format: "json"} (unlock_api_react_control_mapping)
  async unlock(recordVersionID: number) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
      return data;
    } catch (error) {
      window.console.log(`"ControlMappings#unlock" error ${error}`);
    }
  }

  // POST /api/react/control_mappings/:id/archive
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async archive(recordVersionID) {
    const params = { identifier: "control_mapping" };

    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/control_mappings/${recordVersionID}/archive`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return data;
    } catch (error) {
      window.console.log(`"ControlMappings#archive" error ${error}`);
    }
  }

  // GET /api/react/control_mappings/archived
  async archivedList() {
    const params = { identifier: "control_mapping" };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/control_mappings/archived`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return response.data;
    } catch (error) {
      window.console.log(`"ControlMappings#archivedList" error ${error}`);
    }
  }

  // GET /api/react/control_mappings/:id/historical
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async historicalList(recordVersionID) {
    const params = { identifier: "control_mapping" };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/control_mappings/${recordVersionID}/historical`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setHistorical(response.data.record_versions);
    } catch (error) {
      this.setHistorical([]);
      window.console.log(`"ControlMappings#historicalList" error ${error}`);
    }
  }

  async updateSection(recordVersionID: number, sectionTagId: number) {
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/control_mappings/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data: { section_tag_id: sectionTagId },
      });
    } catch (error) {
      window.console.log(`"ControlMappings#update" error ${error}`);
    }
  }

  async createBulkCompanyLibraryItems(ids: number[]) {
    const params = { ids };
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "POST",
        url: "/api/react/control_mappings/create_bulk_company_library_items",
        headers: this.mainStore.getHeaders(),
        data: params,
      });
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          response.response,
          // @ts-expect-error TS(2304) FIXME: Cannot find name 'defaultMessage'.
          (defaultMessage =
            "Something went wrong. Please contact support@themis.com"),
        );
      } else {
        this.mainStore.toast.setText("Items added to Company Library");
      }
    } catch (err) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.mainStore.toast.setErrorText(err);
      window.console.log(
        `"ControlMappings#createBulkCompanyLibraryItems" error ${err}`,
      );
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLastTab(value) {
    this.lastTab = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLibraryItem(value) {
    this.libraryItem = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLibraryRecords(value) {
    this.libraryRecords = value;
  }

  setControlMappingsEffectiveness(value: ControlMappingsEffectiveness[]) {
    this.controlMappingsEffectiveness = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setModuleWorkspaceID(value) {
    this.moduleWorkspaceID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setHistorical(value) {
    if (value) {
      this.historical = value;
    } else {
      this.historical = [];
    }
  }

  // Store Helpers
  cleanup() {
    this.setData({});
    this.setLibraryItem({});
    this.setLibraryRecords([]);
    this.setModuleWorkspaceID(null);
    this.setHistorical([]);
    this.setControlMappingsEffectiveness([]);
  }

  abort() {
    this.controller?.abort();
  }
}
