import React from "react";

import type { ModuleSectionNode } from "../../../stores/types/workspace-types";
import { Icon, Typography } from "../../Elements";
import type { IconName } from "../../Elements/Icon/icon";

interface SectionNodeProps {
  summary: ModuleSectionNode;
  icon?: IconName;
}

const SectionNode = (props: SectionNodeProps) => {
  const icon = props.icon || "listBullet";
  return (
    <div className="section-node">
      <div className="section-node__title">
        <Icon name={icon} size="md" />
        <Typography
          label={props.summary.title}
          weight="semiBold"
          size="sm"
          color="generalMidnightDark"
          className="section-node__name"
        />
      </div>
      {props.summary.sections.map((section) => (
        <SectionNode key={section.id} summary={section} icon={icon} />
      ))}
    </div>
  );
};

export default SectionNode;
