import { http } from "msw";

import { createListPoliciesQueryResponse } from "../createListPolicies";

export const listPoliciesHandler = http.get(
  "*/workspaces/:workspace_id/policies",
  function handler(info) {
    return new Response(JSON.stringify(createListPoliciesQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
