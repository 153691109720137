import { http } from "msw";

import { createSaveControlsModulesMutationResponse } from "../createSaveControlsModules";

export const saveControlsModulesHandler = http.post(
  "*/record_versions/:record_version_id/controls/modules",
  function handler(info) {
    return new Response(
      JSON.stringify(createSaveControlsModulesMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
