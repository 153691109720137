import { faker } from "@faker-js/faker";

import type { ApiAuth } from "../models/ApiAuth";

export function createApiAuth(
  data: NonNullable<Partial<ApiAuth>> = {},
): NonNullable<ApiAuth> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      api_key: faker.string.alpha(),
      status: faker.string.alpha(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
    },
    ...data,
  };
}
