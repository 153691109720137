import { http } from "msw";

import { createGetCommentQueryResponse } from "../createGetComment";

export const getCommentHandler = http.get(
  "*/:record_type/:record_id/comments/:id",
  function handler(info) {
    return new Response(JSON.stringify(createGetCommentQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
