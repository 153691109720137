import { observer } from "mobx-react";
import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import Loading from "@/components/Loading";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";

import EmptyMessage from "../components/EmptyMessage/EmptyMessage";
import { ModuleContentWrapper } from "../components/ModuleContentWrapper/ModuleContentWrapper";
import QuestionnaireScoring from "../components/Questionnaires/QuestionnaireScoring/QuestionnaireScoring";
import { useQuestionnaireData } from "../hooks";
import { routes } from "../routes";
import { getPartnerName } from "../utils/partners";

function QuestionnaireScoringPage() {
  const mainStore = useMainStore();
  const history = useHistory();
  const activeWorkspaceId: number | undefined =
    mainStore.context.activeWorkspace?.id;
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const { questionnaire, riskMethodology, riskAreas } =
    useQuestionnaireData(activeWorkspaceId);

  if (!questionnaire) {
    return <Loading loadingLayout="small-table" />;
  }

  const partnerName = getPartnerName(
    mainStore.riskAssessmentPartners.partners,
    record_version_id.toString(),
  );

  return (
    <DashboardContent>
      <DashboardHeader
        title={`${partnerName} ${
          questionnaire?.name || "Questionnaire"
        }`.trim()}
        onBackClick={() =>
          history.push(
            generatePath(routes.PARTNERS_QUESTIONNAIRES_PATH, {
              workspace_id: activeWorkspaceId,
              record_version_id,
            }),
          )
        }
      />
      <DashboardContentWrapper>
        <ModuleContentWrapper>
          {questionnaire.scoring && riskMethodology && riskAreas ? (
            <QuestionnaireScoring
              scoring={questionnaire.scoring}
              riskMethodology={riskMethodology}
              riskAreas={riskAreas}
            />
          ) : (
            <EmptyMessage>
              No score available. Questionnaire still in progress.
            </EmptyMessage>
          )}
        </ModuleContentWrapper>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(QuestionnaireScoringPage);
