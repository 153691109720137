import "./finalize-button.scss";

import { Button } from "@themis/ui";
import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog/confirmation-dialog";
import { useMainStore } from "@/contexts/Store";

import { routes } from "../../../routes";
import type { QuestionnaireRead } from "../../../types/questionnaire";

interface FinalizeButtonProps {
  questionnaireId: number;
  assessmentId?: number;
  updateQuestionnaire: (questionnaire: QuestionnaireRead) => void;
  redirectPathAfterCompletion?: string;
  disabled?: boolean;
}

export default function FinalizeButton({
  questionnaireId,
  updateQuestionnaire,
  redirectPathAfterCompletion,
  disabled,
}: FinalizeButtonProps) {
  const mainStore = useMainStore();
  const history = useHistory();
  const { workspaceID } = mainStore.context;

  const [showPopup, setShowPopup] = useState(false);

  const finalize = async () => {
    try {
      const questionnaire = await QuestionnaireAPI.finalize(questionnaireId);
      updateQuestionnaire(questionnaire);
      history.push(
        redirectPathAfterCompletion ||
          generatePath(routes.PARTNERS_QUESTIONNAIRES_PATH, {
            record_version_id: questionnaire.record_version_id,
            workspace_id: Number(workspaceID),
          }),
      );
    } catch (error) {
      mainStore.toast.setErrorFromResponse(error);
      setShowPopup(false);
    }
  };

  if (disabled) {
    return (
      <Button data-testid="complete-button" disabled>
        Complete
      </Button>
    );
  }

  const renderButtonTrigger = (
    <Button data-testid="complete-button">Complete</Button>
  );
  return (
    <div className="finalize-assesment-questionnaire">
      <Popup
        position="center center"
        trigger={renderButtonTrigger}
        open={showPopup}
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
        keepTooltipInside
        className="finalize-assesment-questionnaire-popup"
      >
        <div className="table-dropdown deactivate-dropdown">
          <ConfirmationDialog
            heading="Complete questionnaire"
            content="Make sure all override questions have been completed. Once you have completed this questionnaire, status will move to “Complete” and you will no longer be able to update any questions. Are you sure you want to proceed?"
            handleConfirm={finalize}
            handleReject={() => setShowPopup(false)}
          />
        </div>
      </Popup>
    </div>
  );
}
