import { faker } from "@faker-js/faker";

import type {
  ListPermissionSets200,
  ListPermissionSetsPathParams,
  ListPermissionSetsQueryResponse,
} from "../models/ListPermissionSets";
import { createPermissionSet } from "./createPermissionSet";

export function createListPermissionSetsPathParams(): NonNullable<ListPermissionSetsPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListPermissionSets200(): NonNullable<ListPermissionSets200> {
  return faker.helpers.arrayElements([createPermissionSet()]) as any;
}

/**
 * @description OK
 */
export function createListPermissionSetsQueryResponse(): NonNullable<ListPermissionSetsQueryResponse> {
  return faker.helpers.arrayElements([createPermissionSet()]) as any;
}
