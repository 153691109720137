import { observer } from "mobx-react";
import React from "react";

import * as CustomNotificationsAPI from "@/api/legacy/custom-notifications";
import { Flex } from "@/components/Elements";
import LoadingWrapper from "@/components/loading-wrapper";
import CustomNotificationRow from "@/components/settings/notificationsCenter/custom-notifications/CustomNotificationRow";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import { MessageGroup } from "@/components/shared/message-group/message-group";
import { useMainStore } from "@/contexts/Store";
import { useLoadableData } from "@/hooks/useLoadableData";

function CustomNotifications() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const customNotifications = useLoadableData({
    request: () =>
      CustomNotificationsAPI.getCustomNotifications(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        mainStore.context.workspaceID,
      ),
    enabled: !!mainStore.context.workspaceID,
    onError: () =>
      "There was an issue loading the notifications data. Please refresh to try again.",
  });

  return (
    <DashboardContentWrapper>
      <LoadingWrapper
        loading={customNotifications.loading}
        errorText={customNotifications.errorText}
        loadingLayout="table"
      >
        {customNotifications.data && (
          <Flex
            column
            justifyCenter
            alignCenter
            rowGap={24}
            className="notifications-container"
          >
            <MessageGroup
              title={`${customNotifications.data?.length} Notifications`}
              description=""
              addButtonText="Add Custom Notification"
              addButtonLink="/notifications/custom-notifications/create"
              initialStateDescription="No Notifications yet. Click “Add Custom Notification” to get started."
            >
              {customNotifications.data?.length && (
                <Flex column className="notifications-container__list">
                  {customNotifications.data?.map((customNotification) => (
                    <CustomNotificationRow
                      key={customNotification.id}
                      customNotification={customNotification}
                    />
                  ))}
                </Flex>
              )}
            </MessageGroup>
          </Flex>
        )}
      </LoadingWrapper>
    </DashboardContentWrapper>
  );
}

export default observer(CustomNotifications);
