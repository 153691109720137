var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/Tooltip";
export var SimpleTooltip = function (_a) {
    var children = _a.children, title = _a.title, _b = _a.tooltipTriggerProps, tooltipTriggerProps = _b === void 0 ? {} : _b, tooltipContentProps = _a.tooltipContentProps, disabled = _a.disabled, rest = __rest(_a, ["children", "title", "tooltipTriggerProps", "tooltipContentProps", "disabled"]);
    if (disabled || !title) {
        return children;
    }
    var asChild = tooltipTriggerProps.asChild, restTooltipTriggerProps = __rest(tooltipTriggerProps, ["asChild"]);
    return (React.createElement(Tooltip, __assign({}, rest),
        React.createElement(TooltipTrigger, __assign({ asChild: asChild === undefined ? true : asChild }, restTooltipTriggerProps), children),
        React.createElement(TooltipContent, __assign({}, tooltipContentProps), title)));
};
