import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useModuleDetection } from "../hooks/useModuleDetection";

function LocationTracker() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();
  const location = useLocation();

  // State
  const [redirectPath, setRedirectPath] = useState("");

  // Variables
  const { pathname } = location;
  const { accessToken } = mainStore.webSessions;
  const { workspaceID } = mainStore.context;
  const allowedPaths = [
    "/sign-in",
    "/sign-up",
    "/forgot-password",
    "/create-new-password",
    "/email-verification-success",
  ];
  const wildcardAllowedPaths = [
    "/invite-sign-up",
    "/verify_email",
    "/submit_conflict_of_interest",
    "/submit_complaints",
    "/submit_customer_support",
    "/submit_more_information",
    "/conflicts_of_interest/",
    "/forms/vendor-questionnaires/",
    "/forms/questionnaires",
  ];
  const { user } = mainStore.users;
  const themisModuleIdentifier = useModuleDetection(location);
  const moduleWorkspaces = mainStore.moduleWorkspaces.list.filter(
    (moduleWorkspace) => moduleWorkspace.added,
  );
  const MultiFactorAuthPath = "/multi-factor-auth";

  useEffect(() => {
    if (location.pathname.startsWith("/workspaces/")) {
      const workspaceIdPath = location.pathname?.split("/")[2];
      if (
        workspaceIdPath &&
        Number(workspaceIdPath) !== mainStore.context.workspaceID
      ) {
        mainStore.workspaces.switch(workspaceIdPath);
      }
    }
  }, [location]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'currentPath' implicitly has an 'any' ty... Remove this comment to see the full error message
  function isPublicPath(currentPath) {
    return (
      allowedPaths.includes(currentPath) ||
      wildcardAllowedPaths.some((path) => currentPath.includes(path)) ||
      pathname.startsWith("/attestations/")
    );
  }

  // If not logged in - remember location
  useEffect(() => {
    const loggedIn = Boolean(accessToken);
    if (!loggedIn && !pathname.includes("/invite-sign-up")) {
      setRedirectPath(pathname + location.search);
    }
  }, []);

  useEffect(() => {
    if (accessToken && user) {
      if (user.verification_expired === true) {
        history.push("/email-verification-expired");
      } else if (
        user.is_email_confirmed === false &&
        pathname !== "/thanks-for-signing-up"
      ) {
        history.push("/thanks-for-signing-up");
      } else if (
        user.status === "Onboarding" &&
        user.is_email_confirmed &&
        user.roles_count === 0 &&
        user.workspaces_count === 0 &&
        !["/create-company", "/email-verification-success"].includes(pathname)
      ) {
        history.push("/create-company");
      } else if (
        user.is_email_confirmed &&
        user.roles_count &&
        user.roles_count > 0 &&
        pathname === "/create-company"
      ) {
        history.push("/pick-modules");
      } else if (
        user.is_email_confirmed &&
        user.mfa_challenge_in_progress &&
        pathname !== MultiFactorAuthPath
      ) {
        history.push(MultiFactorAuthPath);
      } else if (
        user.is_email_confirmed &&
        user.workspaces_count &&
        user.workspaces_count > 0 &&
        pathname === "/create-company"
      ) {
        redirectToDeeplinkOrHome();
      } else if (
        [
          "/sign-in",
          "/sign-up",
          "/forgot-password",
          "/create-new-password",
          "/home",
          "/",
        ].includes(pathname)
      ) {
        redirectToDeeplinkOrHome();
      } else if (workspaceID && pathname.startsWith("/modules/")) {
        history.push(`/workspaces/${workspaceID}${pathname}`);
      }
    } else {
      if (!isPublicPath(pathname)) {
        if (!redirectPath) {
          setRedirectPath(pathname + location.search);
        }
        history.push("/sign-in");
      }
    }
  }, [accessToken, user, themisModuleIdentifier, moduleWorkspaces]);

  function redirectToDeeplinkOrHome() {
    if (redirectPath && !isPublicPath(redirectPath)) {
      history.push(redirectPath);
      setRedirectPath("");
    } else if (workspaceID) {
      history.push(`/workspaces/${workspaceID}/home`);
    }
  }

  return <div />;
}

export default observer(LocationTracker);
