import type { CustomTooltipProps } from "@ag-grid-community/react";
import { TableTooltipContainer } from "@themis/ui";
import React, { useMemo } from "react";

import type { Account } from "@/api";
import { useAccountTypeFieldOptions } from "@/hooks/use-account-type-field-options";

import { AccountTypeTagsList } from "./account-type-tags/account-type-tags-list";

export function AccountTypeCellTooltip({
  value,
}: CustomTooltipProps<Account, Account["account_types"]>) {
  const { options } = useAccountTypeFieldOptions();

  const selectedTypes = useMemo(
    () => options.filter((option) => value?.includes(option.key)),
    [options, value],
  );

  if (!value || value.length < 3) {
    return null;
  }

  return (
    <TableTooltipContainer>
      <div className="tw-flex tw-flex-col tw-gap-1">
        <AccountTypeTagsList isInTooltip types={selectedTypes} />
      </div>
    </TableTooltipContainer>
  );
}
