import { Label, useToast } from "@themis/ui";
import React from "react";
import { useIntl } from "react-intl";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import type {
  Account,
  CreateAccountMutationRequest,
  ModifyAccountRequest,
} from "@/api";
import {
  useCreateAccount,
  useUpdateAccount,
} from "@/api/queries/accounts/accounts";
import { useCompany } from "@/api/queries/companies";
import { useLayoutFields } from "@/api/utils/fields/use-layout-fields";
import { formatDynamicFormValuesToRequest } from "@/components/dynamic-field/utils/format-dynamic-form-values-to-request";
import { AccountInfoForm } from "@/features/accounts/components/AccountInfo/account-info-form/AccountInfoForm";
import type { AccountInfoSchema } from "@/features/accounts/components/AccountInfo/account-info-form/use-validation-schema";
import { ActiveTag } from "@/features/accounts/components/ActiveTag";
import { RecordLinkSection } from "@/features/misc/RecordLinkSection";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { useSearchParams } from "@/hooks/useSearchParams";

import { ACCOUNTS_LIST_PATH } from "../../../accounts-routes";
import type { AccountLocationState } from "../AccountDetails";
import { ContactsPreview } from "./contacts-preview";
import { ContractsPreview } from "./contracts-preview";
import { DocumentsPreview } from "./documents-preview";
import { SubAccountsPreview } from "./sub-accounts-preview";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function AccountInfoContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

export function AccountInfo({ account }: { account?: Account }) {
  const toast = useToast();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();

  const { data: company } = useCompany("current");
  const companyId = Number(company?.data.company.id);

  const workspaceId = useRouteWorkspaceId();

  const isNewAccount = !account;

  const { state: locationState } = useLocation<AccountLocationState>();

  const { customFields, isPending: isLayoutFieldsPending } = useLayoutFields({
    companyId,
    recordType: "accounts",
    workspaceId,
    layoutType: "detail",
  });

  /**
   * If exists, redirect to the specified URL after saving the account
   * (e.g. when creating an account from the vendor page, redirect back to the vendor page)
   */
  const [{ redirectTo }] = useSearchParams<{ redirectTo?: string }>();

  const accountsListPath = generatePath(ACCOUNTS_LIST_PATH, {
    workspace_id,
  });

  const { mutateAsync: createAccount } = useCreateAccount({
    workspaceId: Number(workspace_id),
  });

  const { mutateAsync: updateAccount } = useUpdateAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
  });

  function getFromLocation(newAccountId?: number) {
    if (isNewAccount) {
      return redirectTo
        ? `${redirectTo}?account_id=${newAccountId}`
        : accountsListPath;
    }

    return redirectTo || locationState?.from || accountsListPath;
  }

  async function handleCreateAccount(
    newAccountData: CreateAccountMutationRequest,
  ) {
    try {
      const response = await createAccount(newAccountData);

      history.push(getFromLocation(response.data?.id));

      toast({
        content: `Account "${newAccountData.account.name}" has been added!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not create account.",
        variant: "error",
      });
    }
  }

  async function handleUpdateAccount(newAccountData: ModifyAccountRequest) {
    try {
      await updateAccount(newAccountData);

      history.push(getFromLocation());

      toast({
        content: `Account "${newAccountData.name}" has been updated!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not update account.",
        variant: "error",
      });
    }
  }

  function handleSubmitForm(values: AccountInfoSchema) {
    const newAccountData: ModifyAccountRequest = {
      ...values,
      logo: values.logo?.signedId ?? undefined,
      custom_fields: formatDynamicFormValuesToRequest(
        customFields,
        values.custom_fields,
      ),
    };

    if (!account) {
      handleCreateAccount({ account: newAccountData });
    } else {
      handleUpdateAccount(newAccountData);
    }
  }

  return (
    <FormContainer>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <StatusContainer>
          <Label
            id="status-label"
            className="tw-font-semibold tw-text-neutral-500"
          >
            {formatMessage({
              defaultMessage: "Status",
              description: "Record status label",
            })}
          </Label>
          <ActiveTag
            aria-labelledby="status-label"
            isActive={account?.status === "active"}
          />
        </StatusContainer>
        <StatusContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">
            {formatMessage({
              defaultMessage: "Parent Account",
            })}
          </Label>
          <p className="tw-text-neutral-300">
            {account?.parent?.name ||
              formatMessage({
                defaultMessage: "N/A",
              })}
          </p>
        </StatusContainer>
      </div>
      <AccountInfoContainer>
        <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
          {formatMessage({
            defaultMessage: "Account Info",
            description: "Account info section title",
          })}
        </h2>

        {!isLayoutFieldsPending && (
          <AccountInfoForm
            defaultValues={account}
            contacts={account?.contacts}
            customFields={customFields}
            onSubmit={handleSubmitForm}
          />
        )}
      </AccountInfoContainer>
      <ContactsPreview account={account} />
      <ContractsPreview account={account} />
      <SubAccountsPreview account={account} />
      <DocumentsPreview account={account} />
      <RecordLinkSection
        recordId={Number(accountId)}
        recordType="accounts"
        disabled={isNewAccount}
      />
    </FormContainer>
  );
}
