import { http } from "msw";

import { createListTaskSectionsQueryResponse } from "../createListTaskSections";

export const listTaskSectionsHandler = http.get(
  "*/companies/:company_id/task_sections",
  function handler(info) {
    return new Response(JSON.stringify(createListTaskSectionsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
