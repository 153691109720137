import { faker } from "@faker-js/faker";

import type {
  ListTrainings200,
  ListTrainingsPathParams,
  ListTrainingsQueryParams,
  ListTrainingsQueryResponse,
} from "../models/ListTrainings";
import { createTraining } from "./createTraining";

export function createListTrainingsPathParams(): NonNullable<ListTrainingsPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createListTrainingsQueryParams(): NonNullable<ListTrainingsQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
    expand: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createListTrainings200(): NonNullable<ListTrainings200> {
  return {
    data: faker.helpers.arrayElements([
      Object.assign({}, createTraining()),
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createListTrainingsQueryResponse(): NonNullable<ListTrainingsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([
      Object.assign({}, createTraining()),
    ]) as any,
  };
}
