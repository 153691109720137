var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import dayjs from "dayjs";
import React from "react";
import { filterCellProps } from "../../../lib/utils";
import { DatePicker } from "../../Form/DatePicker";
function DatePickerCell(_a) {
    var _b, _c;
    var value = _a.value, onValueChange = _a.onValueChange, mode = _a.mode, props = __rest(_a, ["value", "onValueChange", "mode"]);
    var filteredProps = filterCellProps(__assign({ value: value, onValueChange: onValueChange }, props));
    var editable = Boolean((_b = props.colDef) === null || _b === void 0 ? void 0 : _b.editable);
    var parseAndFormatDate = function (dateString) {
        try {
            // Handle ISO 8601 or other valid date strings
            var parsedDate = dayjs(dateString);
            if (parsedDate.isValid()) {
                return new Date(parsedDate.year(), parsedDate.month(), parsedDate.date());
            }
        }
        catch (error) {
            console.error("Invalid date string:", dateString);
        }
        return new Date(dateString);
    };
    var getCalendarProps = function () {
        switch (mode) {
            case "multiple":
                return {
                    mode: "multiple",
                    selected: Array.isArray(value) && value.length > 0
                        ? value.map(function (date) { return parseAndFormatDate(date); })
                        : undefined,
                    onSelect: onValueChange,
                };
            case "range":
                return {
                    mode: "range",
                    selected: value && value.from && value.to
                        ? {
                            from: parseAndFormatDate(value.from),
                            to: parseAndFormatDate(value.to),
                        }
                        : undefined,
                    onSelect: onValueChange,
                };
            default:
                return {
                    mode: "single",
                    selected: value ? parseAndFormatDate(value) : undefined,
                    onSelect: onValueChange,
                };
        }
    };
    return (React.createElement(DatePicker, __assign({ calendarProps: getCalendarProps(), className: "tw-h-full tw-w-full tw-rounded-none tw-border-0 tw-bg-transparent read-only:tw-cursor-default read-only:tw-bg-transparent read-only:tw-text-neutral-300 group-hover/row:tw-bg-neutral-50", defaultOpen: !!(((_c = props.api.getFocusedCell()) === null || _c === void 0 ? void 0 : _c.column.getColId()) ===
            props.column.getColId() &&
            props.cellStartedEdit &&
            editable), onClose: function () {
            props.api.stopEditing();
        }, readOnly: !editable }, filteredProps)));
}
export default DatePickerCell;
