import { cn, useToast } from "@themis/ui";
import React, { useRef, useState } from "react";

import type { Comment, ListCommentsPathParams } from "@/api";
import { useDeleteComment } from "@/api/queries/comments/useDeleteComment";
import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useClickAwayListener } from "@/hooks/useClickAwayListener";
import { useSearchParams } from "@/hooks/useSearchParams";

import { useCopyCommentLink } from "../comment/useCopyCommentLink";
import { ReplyContent } from "./reply-content";
import { ReplyHeader } from "./reply-header";

interface ReplyProps {
  detailsPagePath: string;
  reply: Comment;
  selectedCommentRef: React.RefObject<HTMLDivElement>;
  recordType: ListCommentsPathParams["record_type"];
  recordId: ListCommentsPathParams["record_id"];
}

export function Reply({
  detailsPagePath,
  reply,
  selectedCommentRef,
  recordId,
  recordType,
}: ReplyProps) {
  const toast = useToast();
  const replyWrapper = useRef(null);
  const [isEditingReply, setIsEditingReply] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams<{
    comments_tab?: CommentTab;
    comment_id?: string;
  }>();

  const selectedCommentId = Number(searchParams.comment_id);
  const isActiveComment = reply.id === selectedCommentId;

  const { mutate: deleteComment } = useDeleteComment(
    {
      recordType,
      recordId,
    },
    {
      onError: () => {
        toast({
          content: "Failed to delete comment!",
          variant: "error",
        });
      },
    },
  );

  function handleClickAway() {
    setSearchParams({ ...searchParams, comment_id: undefined });
  }

  useClickAwayListener(
    replyWrapper,
    isActiveComment ? handleClickAway : undefined,
  );

  const handleCopyLink = useCopyCommentLink({
    basePath: detailsPagePath,
    commentId: reply.id,
    recordId,
  });

  return (
    <div ref={replyWrapper} key={reply.id}>
      <div
        ref={isActiveComment ? selectedCommentRef : undefined}
        className={cn(
          "tw-mb-0.5 tw-rounded-lg tw-bg-neutral-50 tw-px-3 tw-py-2",
          isActiveComment && "tw-bg-lightBlue-2/20",
        )}
      >
        <ReplyHeader
          comment={reply}
          onConfirmDelete={() => deleteComment(reply.id)}
          onStartEditing={() => setIsEditingReply(true)}
          onCopyCommentLink={handleCopyLink}
        />
        <ReplyContent
          reply={reply}
          editing={isEditingReply}
          onStopEditing={() => setIsEditingReply(false)}
          recordId={recordId}
          recordType={recordType}
        />
      </div>
    </div>
  );
}
