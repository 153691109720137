import { orderBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { Flex, Icon } from "@/components/Elements";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";

import { routes } from "../../routes";
import RiskMethodologyRow from "./RiskMethodologyRow";

function RiskMethodologyTable() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const riskMethodologies = mainStore.riskMethodologies.list || [];
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const orderedRiskMethodologies = orderBy(
    riskMethodologies,
    ["name"],
    ["asc"],
  );

  useEffect(() => {
    if (!workspaceID) {
      return;
    }

    mainStore.riskMethodologies
      .index(workspaceID)
      .catch(() => {
        mainStore.toast.setErrorText(
          "There was a problem loading risk methodologies...",
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [workspaceID]);

  if (isLoading) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <Flex column rowGap={10} fullWidth>
      <Table
        header={
          <>
            <TableHeaderCell
              title="Name"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="text" />}
              firstDataHeader
            />
            <TableHeaderCell
              title="Inherent Risk Ratings"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="text" />}
            />
            <TableHeaderCell
              title="Control Risk Ratings"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="text" />}
            />
            <TableHeaderCell
              title="Residual Risk Ratings"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="text" />}
              lastDataHeader
            />
          </>
        }
        actionRow={{
          text: "Add Template",
          onClick: () =>
            history.push(
              generatePath(routes.METHODOLOGY_CREATION_PATH, {
                workspace_id: Number(workspaceID),
              }),
            ),
        }}
      >
        {orderedRiskMethodologies.map((rm, i) => (
          <RiskMethodologyRow key={rm.id} riskMethodology={rm} index={i} />
        ))}
      </Table>
    </Flex>
  );
}

export default observer(RiskMethodologyTable);
