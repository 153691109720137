import { http } from "msw";

import { createCreateBlobMutationResponse } from "../createCreateBlob";

export const createBlobHandler = http.post("*/blobs", function handler(info) {
  return new Response(JSON.stringify(createCreateBlobMutationResponse()), {
    headers: {
      "Content-Type": "application/json",
    },
  });
});
