import { useMainStore } from "@/contexts/Store";
import type { UserPermissions } from "@/stores/types/user-permissions-types";

export function useHasPermission(permissionCode: keyof UserPermissions) {
  const { context, userPermissions } = useMainStore();

  return Boolean(
    !context.activeWorkspace?.is_archived &&
      userPermissions.permissions[permissionCode],
  );
}
