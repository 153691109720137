import React, { useEffect, useState } from "react";
import { PiArrowDown, PiArrowsDownUp, PiArrowUp, PiPushPin, PiPushPinFill, } from "react-icons/pi";
import { cn } from "../../../lib/utils";
import { IconButton } from "../../Button/IconButton";
var Header = function (_a) {
    var _b;
    var _c = _a.displayName, displayName = _c === void 0 ? "" : _c, column = _a.column, context = _a.context, leftIcon = _a.leftIcon, progressSort = _a.progressSort;
    var gridRef = (_b = context.gridRef) === null || _b === void 0 ? void 0 : _b.current;
    var isPinned = column.isPinned();
    var enablePinning = !column.getColDef().lockPinned;
    var _d = useState(column.getSort()), sort = _d[0], setSort = _d[1];
    var handlePinClick = function () {
        var _a;
        (_a = gridRef.api) === null || _a === void 0 ? void 0 : _a.applyColumnState({
            state: [{ colId: column.getColId(), pinned: isPinned ? null : "left" }],
        });
    };
    var onSortClicked = function () {
        progressSort();
    };
    useEffect(function () {
        var onSortChanged = function () {
            setSort(column.getSort());
        };
        column.addEventListener("sortChanged", onSortChanged);
        onSortChanged();
        return function () {
            column.removeEventListener("sortChanged", onSortChanged);
        };
    }, [column]);
    if (!displayName) {
        return null;
    }
    return (React.createElement("div", { className: "tw-group/header tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-0.5" },
        leftIcon,
        React.createElement("span", { className: "tw-mr-auto tw-truncate" }, displayName),
        React.createElement("div", { className: "tw-flex tw-items-center" },
            enablePinning && (React.createElement(IconButton, { className: cn("tw-transition tw-duration-200 group-hover/header:tw-flex", {
                    "tw-hidden": !isPinned,
                }), Icon: isPinned ? PiPushPinFill : PiPushPin, color: "transparent", onClick: handlePinClick, "aria-label": "Pin column" })),
            React.createElement(IconButton, { onClick: onSortClicked, Icon: getSortIcon(sort), color: "transparent", "aria-label": "Sort column" }))));
};
function getSortIcon(sort) {
    if (sort === "asc") {
        return PiArrowUp;
    }
    if (sort === "desc") {
        return PiArrowDown;
    }
    return PiArrowsDownUp;
}
export default Header;
