import axios from "axios";

import type { CompanyInfo } from "./types";

export const CLEARBIT_API_URL =
  "https://autocomplete.clearbit.com/v1/companies/suggest";

export async function fetchCompanyInfo(
  companyNameOrDomain: string,
): Promise<CompanyInfo[]> {
  if (!companyNameOrDomain) {
    return [];
  }
  const response = await axios.get(
    `${CLEARBIT_API_URL}?query=${companyNameOrDomain}`,
  );
  return response.data;
}
