import { faker } from "@faker-js/faker";

import type {
  CreateFieldOption201,
  CreateFieldOption403,
  CreateFieldOption422,
  CreateFieldOptionMutationRequest,
  CreateFieldOptionMutationResponse,
  CreateFieldOptionPathParams,
} from "../models/CreateFieldOption";
import { createFieldOption } from "./createFieldOption";

export function createCreateFieldOptionPathParams(): NonNullable<CreateFieldOptionPathParams> {
  return { field_id: faker.number.int() };
}

/**
 * @description Field option created successfully
 */
export function createCreateFieldOption201(): NonNullable<CreateFieldOption201> {
  return { data: createFieldOption() };
}

/**
 * @description Forbidden
 */
export function createCreateFieldOption403(): NonNullable<CreateFieldOption403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Unprocessable Content
 */
export function createCreateFieldOption422(): NonNullable<CreateFieldOption422> {
  return {
    errors: {
      key: faker.helpers.arrayElements([faker.string.alpha()]) as any,
      value: faker.helpers.arrayElements([faker.string.alpha()]) as any,
    },
  };
}

export function createCreateFieldOptionMutationRequest(): NonNullable<CreateFieldOptionMutationRequest> {
  return { option: { key: faker.string.alpha(), value: faker.string.alpha() } };
}

/**
 * @description Field option created successfully
 */
export function createCreateFieldOptionMutationResponse(): NonNullable<CreateFieldOptionMutationResponse> {
  return { data: createFieldOption() };
}
