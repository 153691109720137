import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";

import { useMainStore } from "@/contexts/Store";

import VendorsTable from "./VendorsTable";

interface VendorsScreenProps {
  isActiveScreen: boolean;
  canCreateNewVendors: boolean;
}

function VendorsScreen({
  isActiveScreen,
  canCreateNewVendors,
}: VendorsScreenProps) {
  const mainStore = useMainStore();
  const { moduleWorkspaceID } = mainStore.context;
  const { visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;
  const { formatMessage } = useIntl();

  const vendors = recordVersions.filter((vendor) => {
    if (isActiveScreen) {
      return vendor.record.account?.status === "active";
    }

    return vendor.record.account?.status === "inactive";
  });

  const vendorsWithoutGroup = vendors.filter((rv) => {
    const groupingValue = mainStore.avroSchemas.valueForField(
      "grouping",
      rv?.data,
    );
    return !groupingValue || groupingValue?.length === 0;
  });

  const getGroupsWithVendors = () =>
    mainStore.fieldOptions
      .optionsForField("grouping")
      .map((option) => ({
        ...option,
        vendors: vendors.filter(
          (rv) =>
            mainStore.avroSchemas.firstValueForField("grouping", rv?.data) ===
            option.name,
        ),
      }))
      .filter((group) => group.vendors.length > 0);

  const groupsWithVendors = getGroupsWithVendors();

  if (!moduleWorkspaceID) {
    return null;
  }

  return (
    <>
      {isActiveScreen && (
        <div className="vdd-page-content table-list-wrap">
          <VendorsTable
            vendors={vendorsWithoutGroup}
            fields={visibleFields}
            moduleWorkspaceID={moduleWorkspaceID}
            isActiveScreen
            canCreateNewVendors={canCreateNewVendors}
          />

          {groupsWithVendors.length > 0 && (
            <>
              <div className="vdd-divider table-header-wrap" />
              <div className="vdd-groups">
                {groupsWithVendors.map((group) => (
                  <VendorsTable
                    key={group.name}
                    sectionId={group.name}
                    title={capitalize(group.name)}
                    vendors={group.vendors}
                    fields={visibleFields}
                    moduleWorkspaceID={moduleWorkspaceID}
                    isActiveScreen
                    canCreateNewVendors={canCreateNewVendors}
                  />
                ))}
              </div>
            </>
          )}

          {isActiveScreen && !canCreateNewVendors && (
            <div className="vendor-payment-alert">
              {" "}
              {formatMessage({
                defaultMessage:
                  "You can test the Vendor Due Diligence platform by profiling and scoring 3 of your vendors. To learn more about how you can use this module for all of your vendors, please contact Themis at hello@themis.com",
              })}{" "}
            </div>
          )}
        </div>
      )}

      {!isActiveScreen && (
        <div className="vdd-page-content table-list-wrap">
          <VendorsTable
            vendors={vendors}
            fields={visibleFields}
            moduleWorkspaceID={moduleWorkspaceID}
            isActiveScreen={false}
            canCreateNewVendors={canCreateNewVendors}
          />
        </div>
      )}
    </>
  );
}

export default observer(VendorsScreen);
