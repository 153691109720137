import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { reportsService } from "@/api";

export const getReportContByStatusesQueryKey = (
  workspaceId: number,
): QueryKey => [workspaceId, "reportsService", "countByStatuses"];

async function countByStatuses(workspaceId: number) {
  const results = await Promise.all([
    reportsService.countByStatus({
      module_workspace_id: workspaceId,
      statuses: "closed",
    }),
    reportsService.countByStatus({
      module_workspace_id: workspaceId,
      statuses: "open",
    }),
  ]);

  return {
    completed_count: results[0],
    draft_count: results[1],
  };
}

export const useReportCountByStatuses = (
  workspaceId: number,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery({
    queryFn: () => countByStatuses(workspaceId),
    queryKey: getReportContByStatusesQueryKey(workspaceId),
    enabled: !!options?.enabled,
  });
