import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateBlobMutationRequest,
  CreateBlobMutationResponse,
} from "../../models/CreateBlob";

/**
 * @description Create a blob from a given URL
 * @summary Create a blob from a given URL
 * @link /blobs
 */
export async function createBlob(
  data: CreateBlobMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateBlobMutationResponse>["data"]> {
  const res = await client<
    CreateBlobMutationResponse,
    CreateBlobMutationRequest
  >({
    method: "post",
    url: `/blobs`,
    data,
    ...options,
  });
  return res.data;
}
