import type { ColDef } from "@ag-grid-community/core";
import { useMemo } from "react";

export const useContractsPreviewTableColumns = () =>
  useMemo(
    (): ColDef[] => [
      {
        headerName: "Contract",
        minWidth: 300,
        cellRenderer: "attachmentCell",
        flex: 1,
        field: "file",
        valueFormatter: (params) => params.value || "No file found",
      },
      {
        headerName: "Contract Start Date",
        minWidth: 200,
        flex: 1,
        cellRenderer: "datePickerCell",
        field: "start_date",
      },
      {
        headerName: "Contract End Date",
        minWidth: 200,
        flex: 1,
        cellRenderer: "datePickerCell",
        field: "end_date",
      },
    ],
    [],
  );
