import { http } from "msw";

import { createListTablesQueryResponse } from "../createListTables";

export const listTablesHandler = http.get(
  "*/module_workspaces/:module_workspace_id/tables",
  function handler(info) {
    return new Response(JSON.stringify(createListTablesQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
