import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListThemisRecordLayoutsPathParams,
  ListThemisRecordLayoutsQueryParams,
  ListThemisRecordLayoutsQueryResponse,
} from "../../models/ListThemisRecordLayouts";

/**
 * @description Fetch all layouts for specific ThemisRecord in Workspace
 * @summary Fetch layout for ThemisRecord
 * @link /companies/:company_id/:record_type/layouts
 */
export async function listThemisRecordLayouts(
  companyId: ListThemisRecordLayoutsPathParams["company_id"],
  recordType: ListThemisRecordLayoutsPathParams["record_type"],
  params: ListThemisRecordLayoutsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListThemisRecordLayoutsQueryResponse>["data"]> {
  const res = await client<ListThemisRecordLayoutsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/${recordType}/layouts`,
    params,
    ...options,
  });
  return res.data;
}
