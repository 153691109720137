import React from "react";

export const MonitoringSidebarContext = React.createContext<{
  activeId: number | undefined;
  openSidebarWithId: (id: number) => void;
  closeSidebar: () => void;
}>(undefined!);

export const useMonitoringSidebar = () =>
  React.useContext(MonitoringSidebarContext);
