import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupChild200,
  WebsiteMonitoringGroupChildPathParams,
  WebsiteMonitoringGroupChildQueryParams,
  WebsiteMonitoringGroupChildQueryResponse,
} from "../models/WebsiteMonitoringGroupChild";
import { createMonitoringGroupAssetChild } from "./createMonitoringGroupAssetChild";

export function createWebsiteMonitoringGroupChildPathParams(): NonNullable<WebsiteMonitoringGroupChildPathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

export function createWebsiteMonitoringGroupChildQueryParams(): NonNullable<WebsiteMonitoringGroupChildQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
  };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupChild200(): NonNullable<WebsiteMonitoringGroupChild200> {
  return {
    data: faker.helpers.arrayElements([
      createMonitoringGroupAssetChild(),
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupChildQueryResponse(): NonNullable<WebsiteMonitoringGroupChildQueryResponse> {
  return {
    data: faker.helpers.arrayElements([
      createMonitoringGroupAssetChild(),
    ]) as any,
  };
}
