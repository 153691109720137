import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import ApproveButton from "../shared/ApproveButton";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import MoveToSectionPopup from "../shared/MoveToSectionPopup";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import Unlock from "../shared/Unlock";

interface Props {
  recordVersion: RecordVersion;
}

function ThemisAuditContextMenu({ recordVersion }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("audits", moduleWorkspaces, true);
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // State
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [sendSlide, setSendSlide] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("menu");
  const { taskDetail } = mainStore;

  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
    }
  }, [showPopup]);

  // Variables
  const recordVersionID = recordVersion.id;
  const { workspaceID, isIW: isInternalWorkspace } = mainStore.context;
  const { canDeleteRecords, canPublishOrLockRecords } =
    mainStore.userPermissions;
  const isApproved = Boolean(
    mainStore.avroSchemas.valueForField("approval", recordVersion?.data)
      ?.verifier_id,
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'rvID' implicitly has an 'any' type.
  const handleMove = async (rvID, sectionTagID) => {
    await mainStore.themisAudits.updateSection(rvID, sectionTagID);
  };

  function noButtonPressed() {
    handlePopUpClose();
  }

  function deleteRowConfirmation() {
    setIsDeleteConfirmationOpen(true);
  }

  function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      mainStore.themisAudits.deleteAuditIssue(recordVersionID);
      mainStore.toast.setText("Audit deleted!");
    }
  }

  function handlePopUpOpen() {
    setIsPopupOpen(true);
  }

  function handlePopUpClose() {
    setIsPopupOpen(false);
    setIsDeleteConfirmationOpen(false);
    setViewPopup("menu");
  }

  function openSendSlideMenu() {
    setShowSlideMenu(true);
    setIsPopupOpen(false);
    setSendSlide(true);
  }

  function closeSlideMenu() {
    setShowSlideMenu(false);
    setSendSlide(false);
  }

  function reopen() {
    mainStore.themisAudits.reopenAuditIssue(recordVersionID);
  }

  function approve() {
    mainStore.themisAudits.approveAuditIssue(recordVersionID);
  }

  function duplicateRecord() {
    mainStore.recordVersions.duplicate(recordVersionID);
    setIsPopupOpen(false);
  }
  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="themis-audit-context-menu-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isPopupOpen,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="themis-audit-context-menu-dropdown"
    >
      <ul>
        <Link
          to={`/workspaces/${workspaceID}/modules/audits/${recordVersionID}`}
          data-testid="view-detail"
        >
          View Audit Detail
        </Link>
        {(hasModuleWriteAccess || canDeleteRecords) && <hr />}

        {hasModuleWriteAccess && (
          <li
            className={classNames({
              disabled: mainStore.sectionTags.list.length === 0,
            })}
            onClick={() => setViewPopup("move")}
            data-testid="audits-context-menu-dropdown-move"
          >
            Move to
          </li>
        )}
        {isInternalWorkspace && hasModuleWriteAccess && (
          <ShareToCW recordName={recordName} sendTrigger={openSendSlideMenu} />
        )}
        {hasModuleWriteAccess && (
          <li data-testid="duplicate-test-trigger" onClick={duplicateRecord}>
            Duplicate
          </li>
        )}
        <li onClick={handleCreateTask}>Create Task</li>

        {canDeleteRecords && (
          <>
            <hr />
            <li
              onClick={deleteRowConfirmation}
              data-testid="themis-audit-context-menu-dropdown-delete"
            >
              Delete
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete audit issue"
      content="Are you sure you want to delete this audit issue? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  let renderButton;

  if (isApproved) {
    renderButton = (
      <Unlock
        onYes={reopen}
        tableName="Default"
        moduleIdentifier="audits"
        buttonText="Reopen"
      />
    );
  } else {
    renderButton = (
      <ApproveButton onClick={approve} disabled={!canPublishOrLockRecords} />
    );
  }

  return (
    <div className="list-points" data-testid="themis-audit-context-menu">
      {renderButton}

      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isPopupOpen}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {!isDeleteConfirmationOpen && viewPopup === "menu" && renderBaseContent}
        {viewPopup === "move" && (
          <MoveToSectionPopup
            moduleName="Audits"
            recordVersionID={recordVersionID}
            onMove={handleMove}
            onClose={() => setShowPopup(false)}
            omitTopLevelSection
          />
        )}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        {sendSlide && (
          <SendRecordVersion
            recordVersionID={recordVersionID}
            recordName={recordName}
          />
        )}
      </SlideMenu>
    </div>
  );
}

export default observer(ThemisAuditContextMenu);
