import React from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import DashboardContent from "../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import LegacyAuditTrail from "../../../components/table/shared/LegacyAuditTrail";
import Table from "../../../components/table/Table";

const AuditTrailPage = ({
  RightActionComponent,
}: {
  RightActionComponent: React.ElementType;
}) => {
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader
        title="Module Level Audit Trail"
        onBackClick={() => history.goBack()}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<RightActionComponent />}
      />
      <DashboardContentWrapper>
        <Table>
          <LegacyAuditTrail />
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default AuditTrailPage;
