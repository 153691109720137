import { http } from "msw";

import { createModuleAssessmentDeleteDocumentMutationResponse } from "../createModuleAssessmentDeleteDocument";

export const moduleAssessmentDeleteDocumentHandler = http.delete(
  "*/module_assessments/documents/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createModuleAssessmentDeleteDocumentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
