import { faker } from "@faker-js/faker";

import type { Training } from "../models/Training";
import { createDepartment } from "./createDepartment";
import { createRecordShow } from "./createRecordShow";
import { createThemisDocument } from "./createThemisDocument";
import { createThemisRecord } from "./createThemisRecord";

export function createTraining(
  data?: NonNullable<Partial<Training>>,
): NonNullable<Training> {
  return Object.assign({}, createThemisRecord(), {
    name: faker.string.alpha(),
    status: faker.helpers.arrayElement<any>([
      "pending_attachment",
      "in_review",
      "ready_to_finalize",
      "completed",
      "archived",
    ]),
    notes: faker.string.alpha(),
    training_type: faker.string.alpha(),
    comments_count: faker.number.int(),
    training_material: createThemisDocument(),
    policy: Object.assign({}, createRecordShow()),
    department: Object.assign({}, createDepartment()),
  });
}
