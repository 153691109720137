import { faker } from "@faker-js/faker";

import type {
  ListTasks200,
  ListTasksPathParams,
  ListTasksQueryParams,
  ListTasksQueryResponse,
} from "../models/ListTasks";
import { createTask } from "./createTask";

export function createListTasksPathParams(): NonNullable<ListTasksPathParams> {
  return { company_id: faker.number.int() };
}

export function createListTasksQueryParams(): NonNullable<ListTasksQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
    expand: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createListTasks200(): NonNullable<ListTasks200> {
  return { data: faker.helpers.arrayElements([createTask()]) as any };
}

/**
 * @description OK
 */
export function createListTasksQueryResponse(): NonNullable<ListTasksQueryResponse> {
  return { data: faker.helpers.arrayElements([createTask()]) as any };
}
