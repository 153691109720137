import { faker } from "@faker-js/faker";

import type {
  MonitoringGroupPolicies200,
  MonitoringGroupPoliciesPathParams,
  MonitoringGroupPoliciesQueryResponse,
} from "../models/MonitoringGroupPolicies";
import { createMonitoringGroupPolicy } from "./createMonitoringGroupPolicy";

export function createMonitoringGroupPoliciesPathParams(): NonNullable<MonitoringGroupPoliciesPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createMonitoringGroupPolicies200(): NonNullable<MonitoringGroupPolicies200> {
  return {
    data: faker.helpers.arrayElements([createMonitoringGroupPolicy()]) as any,
  };
}

/**
 * @description OK
 */
export function createMonitoringGroupPoliciesQueryResponse(): NonNullable<MonitoringGroupPoliciesQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createMonitoringGroupPolicy()]) as any,
  };
}
