import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateCommentMutationRequest,
  CreateCommentMutationResponse,
  CreateCommentPathParams,
} from "../../models/CreateComment";

/**
 * @description Create a comment
 * @summary Create a comment
 * @link /:record_type/:record_id/comments
 */
export async function createComment(
  recordType: CreateCommentPathParams["record_type"],
  recordId: CreateCommentPathParams["record_id"],
  data: CreateCommentMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateCommentMutationResponse>["data"]> {
  const res = await client<
    CreateCommentMutationResponse,
    CreateCommentMutationRequest
  >({
    method: "post",
    url: `/${recordType}/${recordId}/comments`,
    data,
    ...options,
  });
  return res.data;
}
