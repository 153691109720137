import { http } from "msw";

import { createUpdateTagMutationResponse } from "../createUpdateTag";

export const updateTagHandler = http.put(
  "*/tags/:tag_id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateTagMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
