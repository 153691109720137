import { http } from "msw";

import { createListThemisCustomAuditsForThemisModuleQueryResponse } from "../createListThemisCustomAuditsForThemisModule";

export const listThemisCustomAuditsForThemisModuleHandler = http.get(
  "*/companies/:company_id/:record_type/audits",
  function handler(info) {
    return new Response(
      JSON.stringify(
        createListThemisCustomAuditsForThemisModuleQueryResponse(),
      ),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
