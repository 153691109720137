import { http } from "msw";

import { createListThemisCustomAuditsForThemisRecordQueryResponse } from "../createListThemisCustomAuditsForThemisRecord";

export const listThemisCustomAuditsForThemisRecordHandler = http.get(
  "*/:record_type/:record_id/audits",
  function handler(info) {
    return new Response(
      JSON.stringify(
        createListThemisCustomAuditsForThemisRecordQueryResponse(),
      ),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
