import { faker } from "@faker-js/faker";

import type { MonitoringGroupAssetChildResult } from "../models/MonitoringGroupAssetChildResult";
import { createFile } from "./createFile";
import { createMonitoringResultAttachmentFile } from "./createMonitoringResultAttachmentFile";

export function createMonitoringGroupAssetChildResult(
  data: NonNullable<Partial<MonitoringGroupAssetChildResult>> = {},
): NonNullable<MonitoringGroupAssetChildResult> {
  return {
    ...{
      id: faker.number.int(),
      copy_title_data: faker.string.alpha(),
      status: faker.helpers.arrayElement<any>([
        "unreviewed",
        "in_review",
        "in_remediation",
        "false_positive",
        "remediated",
      ]),
      comment: faker.string.alpha(),
      evidence: Object.assign({}, createFile()),
      type: faker.string.alpha(),
      sub_type: faker.string.alpha(),
      document_type: faker.string.alpha(),
      url: faker.string.alpha(),
      compliance_finding_uid: faker.string.alpha(),
      compliance_hash_group_uid: faker.string.alpha(),
      compliance_copy: faker.string.alpha(),
      enforcement_actions: faker.helpers.arrayElements([undefined]) as any,
      exact_term: faker.string.alpha(),
      reason: faker.string.alpha(),
      rule: faker.string.alpha(),
      recommendation: faker.string.alpha(),
      severity: faker.string.alpha(),
      feedback: {},
      screenshots: faker.helpers.arrayElements([undefined]) as any,
      facts: {},
      monitoring_group_asset_child_result_uid: faker.string.alpha(),
      monitoring_group_asset_child_id: faker.number.int(),
      monitoring_group_policy_uid: faker.string.alpha(),
      monitoring_group_policy_name: faker.string.alpha(),
      image_attachment: Object.assign(
        {},
        createMonitoringResultAttachmentFile(),
      ),
      screenshots_attachments: faker.helpers.arrayElements([
        createMonitoringResultAttachmentFile(),
      ]) as any,
      facts_attachments: faker.helpers.arrayElements([
        createMonitoringResultAttachmentFile(),
      ]) as any,
      associated_screenshots: faker.helpers.arrayElements([
        createMonitoringResultAttachmentFile(),
      ]) as any,
      monitoring_group_asset_child_result_created_at: faker.date
        .anytime()
        .toISOString(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
    },
    ...data,
  };
}
