import { faker } from "@faker-js/faker";

import type {
  ListOfAccountContracts200,
  ListOfAccountContractsPathParams,
  ListOfAccountContractsQueryParams,
  ListOfAccountContractsQueryResponse,
} from "../models/ListOfAccountContracts";
import { createContract } from "./createContract";

export function createListOfAccountContractsPathParams(): NonNullable<ListOfAccountContractsPathParams> {
  return { account_id: faker.number.int() };
}

export function createListOfAccountContractsQueryParams(): NonNullable<ListOfAccountContractsQueryParams> {
  return {
    sort_field_name: faker.string.alpha(),
    sort_direction: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createListOfAccountContracts200(): NonNullable<ListOfAccountContracts200> {
  return { contracts: faker.helpers.arrayElements([createContract()]) as any };
}

/**
 * @description OK
 */
export function createListOfAccountContractsQueryResponse(): NonNullable<ListOfAccountContractsQueryResponse> {
  return { contracts: faker.helpers.arrayElements([createContract()]) as any };
}
