import { faker } from "@faker-js/faker";

import type {
  QuestionnairePdf200,
  QuestionnairePdf403,
  QuestionnairePdfPathParams,
  QuestionnairePdfQueryResponse,
} from "../models/QuestionnairePdf";
import { createFile } from "./createFile";

export function createQuestionnairePdfPathParams(): NonNullable<QuestionnairePdfPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createQuestionnairePdf200(): NonNullable<QuestionnairePdf200> {
  return { data: createFile() };
}

/**
 * @description Forbidden
 */
export function createQuestionnairePdf403(): NonNullable<QuestionnairePdf403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createQuestionnairePdfQueryResponse(): NonNullable<QuestionnairePdfQueryResponse> {
  return { data: createFile() };
}
