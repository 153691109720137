import { faker } from "@faker-js/faker";

import type {
  CreateProject201,
  CreateProjectMutationRequest,
  CreateProjectMutationResponse,
  CreateProjectPathParams,
} from "../models/CreateProject";
import { createCreateProjectRequest } from "./createCreateProjectRequest";
import { createProject } from "./createProject";

export function createCreateProjectPathParams(): NonNullable<CreateProjectPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description Created
 */
export function createCreateProject201(): NonNullable<CreateProject201> {
  return { data: createProject() };
}

export function createCreateProjectMutationRequest(): NonNullable<CreateProjectMutationRequest> {
  return createCreateProjectRequest();
}

/**
 * @description Created
 */
export function createCreateProjectMutationResponse(): NonNullable<CreateProjectMutationResponse> {
  return { data: createProject() };
}
