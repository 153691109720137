import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import type { AttachmentGroup, RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import MoveToSectionPopup from "../shared/MoveToSectionPopup";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import RenderMarketingButton from "./RenderMarketingButton";

interface Props {
  recordVersion: RecordVersion;
}

function MarketingContextMenu({ recordVersion }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // States
  const [showPopup, setShowPopup] = useState(false);
  const [view, setView] = useState("main"); // one of ['main', 'move', 'delete']
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [sendSlide, setSendSlide] = useState(false);
  const [fileAttachmentGroups, setFileAttachmentGroups] = useState<
    AttachmentGroup[]
  >([]);

  // Variables
  const { activeWorkspace, workspaceID } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const recordVersionID = recordVersion.id;
  const sections = mainStore.sectionTags.list;
  const hasSections = sections.length > 0;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("marketing", moduleWorkspaces, true);
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { taskDetail } = mainStore;

  // Hooks
  useEffect(() => {
    const attachmentGroups = recordVersion.attachment_groups.filter(
      (attachmentGroup) => attachmentGroup.field_name === "creative",
    );

    setFileAttachmentGroups(attachmentGroups);
  }, [recordVersion.attachment_groups]);

  // Functions
  const onPopupOpen = () => {
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
    setView("main");
  };

  const onOpenSlideMenu = () => {
    setShowSlideMenu(true);
    setShowPopup(false);
  };

  const onOpenSendSlideMenu = () => {
    setShowSlideMenu(true);
    setShowPopup(false);
    setSendSlide(true);
  };

  const onCloseSlideMenu = () => {
    setShowSlideMenu(false);
    setSendSlide(false);
  };

  const handleDelete = () => {
    // Delete RecordVersion
    const { company } = mainStore.companies;

    if (company && company.id) {
      mainStore.creatives.delete(recordVersionID);
      mainStore.toast.setText("Creative deleted!");
    }
  };

  const handleCreateTask = () => {
    setShowPopup(false);
    taskDetail.openFromRecord(recordVersion);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'rvID' implicitly has an 'any' type.
  const handleMove = async (rvID, sectionTagID) => {
    await mainStore.creatives.updateSection(rvID, sectionTagID);
  };

  const handleUnlock = async () => {
    await mainStore.creatives.unlock(recordVersionID, true);
  };

  const handleGeneratePDFReport = () => {
    setShowPopup(false);
    mainStore.creatives.generateAndOpenPDFReport(recordVersionID);
  };

  return (
    <>
      <div className="list-points">
        <RenderMarketingButton
          recordVersionID={recordVersionID}
          handleUnlock={handleUnlock}
        />
        <Popup
          position="bottom right"
          // eslint-disable-next-line react/no-unstable-nested-components
          trigger={() => (
            <IconButton
              variant="vertical"
              color="transparent"
              size="md"
              Icon={PiDotsThreeOutlineVerticalFill}
              data-testid="points-button-trigger"
              data-tooltip-id="tooltip"
              data-tooltip-content="More Options"
              data-tooltip-place="bottom"
              className={classNames({
                "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
                  showPopup,
              })}
            />
          )}
          open={showPopup}
          onOpen={onPopupOpen}
          onClose={onPopupClose}
          keepTooltipInside
        >
          {view === "main" && (
            <div className="table-dropdown policy-dropdown">
              <ul>
                <Link
                  to={`/workspaces/${workspaceID}/modules/marketing/${recordVersionID}`}
                  data-testid="view-detail"
                >
                  View Marketing Detail
                </Link>
                {activeWorkspace?.is_internal === true &&
                  hasModuleWriteAccess && (
                    <ShareToCW
                      recordName={recordName}
                      // @ts-expect-error TS(2322) FIXME: Type '{ recordName: string; liveTrigger: () => voi... Remove this comment to see the full error message
                      liveTrigger={onOpenSlideMenu}
                      sendTrigger={onOpenSendSlideMenu}
                    />
                  )}
                <li
                  data-testid="export-to-pdf"
                  onClick={handleGeneratePDFReport}
                >
                  Export to PDF
                </li>
                {hasSections && hasModuleWriteAccess && (
                  <li
                    onClick={() => setView("move")}
                    data-testid="archive-button-trigger"
                  >
                    Move to
                  </li>
                )}
                {fileAttachmentGroups.length > 0 && (
                  <Link
                    to={`/workspaces/${workspaceID}/modules/marketing/attachment_view/${recordVersionID}/${fileAttachmentGroups[0].id}`}
                  >
                    Open Creative View
                  </Link>
                )}

                <li onClick={handleCreateTask}>Create Task</li>

                {canDeleteRecords && (
                  <>
                    <hr />
                    <li
                      data-testid="marketing-dropdown-delete"
                      onClick={() => setView("delete")}
                    >
                      Delete
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
          {view === "move" && (
            <MoveToSectionPopup
              moduleName="Marketings"
              recordVersionID={recordVersionID}
              onMove={handleMove}
              onClose={() => setShowPopup(false)}
            />
          )}

          {view === "delete" && (
            <div className="table-dropdown success-dropdown">
              <ConfirmationDialog
                heading="Delete Creative"
                content="Are you sure you want to delete this creative? Actions are not reversible."
                handleConfirm={handleDelete}
                handleReject={onPopupClose}
              />
            </div>
          )}
        </Popup>
      </div>

      <SlideMenu open={showSlideMenu} closeSlideMenu={onCloseSlideMenu}>
        {sendSlide && (
          <SendRecordVersion
            recordVersionID={recordVersionID}
            recordName={recordName}
          />
        )}
      </SlideMenu>
    </>
  );
}

export default observer(MarketingContextMenu);
