import { http } from "msw";

import { createCloneObjectMutationResponse } from "../createCloneObject";

export const cloneObjectHandler = http.post(
  "*/:record_type/:record_id/clone",
  function handler(info) {
    return new Response(JSON.stringify(createCloneObjectMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
