import { MiniTag, Stack } from "@themis/ui";
import { keyBy } from "lodash";

import { hashString } from "@/utils/hash-string";

import type { DynamicCellProps } from "../dynamic-cell";

export const DynamicSelectCell = ({
  value,
  configuration,
}: DynamicCellProps<string>) => {
  if (!value) {
    return null;
  }

  const optionsMap = keyBy(configuration.options, "key");

  const options = (Array.isArray(value) ? value : [value])
    .map((item) => (item ? optionsMap[item] : undefined))
    .filter((item) => item !== undefined);

  return (
    <Stack direction="row" wrap={false} spacing="0.5">
      {options.map((option) => (
        <MiniTag
          key={option?.key}
          size="lg"
          colorIndex={hashString(option?.key ?? "")}
        >
          {option?.value}
        </MiniTag>
      ))}
    </Stack>
  );
};
