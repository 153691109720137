import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@themis/ui";
import { PiDotsThreeBold } from "react-icons/pi";
import { useIntl } from "react-intl";

export function CommentHeaderMenu({
  isAuthor,
  onStartEditing,
  onCopyCommentLink,
  openConfirmDialog,
}: {
  isAuthor: boolean;
  onStartEditing: () => void;
  onCopyCommentLink: () => void;
  openConfirmDialog: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <PiDotsThreeBold className="tw-text-xl" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {isAuthor && (
          <DropdownMenuItem onSelect={onStartEditing}>
            {formatMessage({ defaultMessage: "Edit" })}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onSelect={onCopyCommentLink}>
          {formatMessage({ defaultMessage: "Copy link" })}
        </DropdownMenuItem>
        {isAuthor && (
          <DropdownMenuItem onSelect={openConfirmDialog}>
            {formatMessage({ defaultMessage: "Delete" })}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
