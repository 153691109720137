import { faker } from "@faker-js/faker";

import type {
  ListOfWorkspaceContracts200,
  ListOfWorkspaceContractsPathParams,
  ListOfWorkspaceContractsQueryParams,
  ListOfWorkspaceContractsQueryResponse,
} from "../models/ListOfWorkspaceContracts";
import { createContract } from "./createContract";

export function createListOfWorkspaceContractsPathParams(): NonNullable<ListOfWorkspaceContractsPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createListOfWorkspaceContractsQueryParams(): NonNullable<ListOfWorkspaceContractsQueryParams> {
  return {
    sort_field_name: faker.string.alpha(),
    sort_direction: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createListOfWorkspaceContracts200(): NonNullable<ListOfWorkspaceContracts200> {
  return { data: faker.helpers.arrayElements([createContract()]) as any };
}

/**
 * @description OK
 */
export function createListOfWorkspaceContractsQueryResponse(): NonNullable<ListOfWorkspaceContractsQueryResponse> {
  return { data: faker.helpers.arrayElements([createContract()]) as any };
}
