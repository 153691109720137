import { type SelectItem, Stack } from "@themis/ui";
import React from "react";
import type { IconType } from "react-icons";
import {
  PiArrowDown,
  PiCalendarBlank,
  PiLink,
  PiListChecks,
  PiPaperclip,
  PiTextAlignLeft,
  PiUser,
} from "react-icons/pi";
import { useIntl } from "react-intl";

import { Typography } from "@/components/Elements";

import type { ValidationSchema } from "../../use-validation-schema/use-validation-schema";

const icons: Record<ValidationSchema["columnType"], IconType> = {
  text: PiTextAlignLeft,
  attachment: PiPaperclip,
  checklist: PiListChecks,
  date: PiCalendarBlank,
  select: PiArrowDown,
  link: PiLink,
  user: PiUser,
};

const LabelWithIcon = (props: SelectItem<ValidationSchema["columnType"]>) => {
  const Icon = icons[props.value];

  return (
    <Stack direction="row" className="tw-items-center">
      <Icon />
      <Typography>{props.label}</Typography>
    </Stack>
  );
};

export const useColumnTypeOptions = (): SelectItem<
  ValidationSchema["columnType"]
>[] => {
  const { formatMessage } = useIntl();

  return [
    {
      value: "text",
      label: formatMessage({ defaultMessage: "Text" }),
      Component: LabelWithIcon,
    },
    {
      value: "select",
      label: formatMessage({ defaultMessage: "Dropdown" }),
      Component: LabelWithIcon,
    },
    // TODO: Will be added back when we figure out how to implement it
    // {
    //   value: "link",
    //   label: formatMessage({ defaultMessage: "Link" }),
    //   Component: LabelWithIcon,
    // },
    {
      value: "date",
      label: formatMessage({ defaultMessage: "Date" }),
      Component: LabelWithIcon,
    },
    // TODO: Will be added back when we figure out how to implement it
    // {
    //   value: "checklist",
    //   label: formatMessage({ defaultMessage: "Checklist" }),
    //   Component: LabelWithIcon,
    // },
    {
      value: "attachment",
      label: formatMessage({ defaultMessage: "Attachment" }),
      Component: LabelWithIcon,
    },
    {
      value: "user",
      label: formatMessage({ defaultMessage: "User" }),
      Component: LabelWithIcon,
    },
  ];
};
