import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { skipToken, useQuery } from "@tanstack/react-query";

import type {
  GetDepartmentsPathParams,
  GetDepartmentsQueryResponse,
} from "@/api/gen";
import { departmentsService } from "@/api/gen";

export const getDepartmentsQueryKey = (
  companyId?: GetDepartmentsPathParams["company_id"],
): QueryKey => ["deparments", "list", companyId].filter(Boolean);

export const useDepartments = <
  TError = Error,
  TData = GetDepartmentsQueryResponse["data"],
>(
  companyId: GetDepartmentsPathParams["company_id"] | undefined,
  options?: Partial<
    UseQueryOptions<GetDepartmentsQueryResponse["data"], TError, TData>
  >,
) =>
  useQuery({
    queryFn: companyId
      ? async () => {
          const response = await departmentsService.getDepartments(companyId);

          return response.data;
        }
      : skipToken,
    queryKey: getDepartmentsQueryKey(companyId),
    ...options,
  });
