import { faker } from "@faker-js/faker";

import type {
  SaveControlsModules201,
  SaveControlsModulesMutationRequest,
  SaveControlsModulesMutationResponse,
  SaveControlsModulesPathParams,
} from "../models/SaveControlsModules";
import { createSaveControlsModuleRequest } from "./createSaveControlsModuleRequest";
import { createSaveControlsModuleResponse } from "./createSaveControlsModuleResponse";

export function createSaveControlsModulesPathParams(): NonNullable<SaveControlsModulesPathParams> {
  return { record_version_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createSaveControlsModules201(): NonNullable<SaveControlsModules201> {
  return { data: createSaveControlsModuleResponse() };
}

export function createSaveControlsModulesMutationRequest(): NonNullable<SaveControlsModulesMutationRequest> {
  return createSaveControlsModuleRequest();
}

/**
 * @description OK
 */
export function createSaveControlsModulesMutationResponse(): NonNullable<SaveControlsModulesMutationResponse> {
  return { data: createSaveControlsModuleResponse() };
}
