import { Label, Table } from "@themis/ui";
import React from "react";
import { generatePath } from "react-router-dom";

import { useMonitoringGroupChildren } from "@/api/queries/websiteMonitoring";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";

import { MONITORING_GROUP_ASSET_CHILDREN_COLUMNS_TABLE } from "../config/monitoringGroupAssetChildrenTable";
import { useChildrenFilterSort } from "../hooks/useChildrenFilterSort";
import { websiteMonitoringRoutes } from "../routes";

export function MonitoringGroupChildrenTable({
  monitoringGroupAssetId,
}: {
  monitoringGroupAssetId: number;
}) {
  const { listRequestQueryParams } = useChildrenFilterSort();
  const { data, isLoading, error } = useMonitoringGroupChildren(
    monitoringGroupAssetId,
    listRequestQueryParams,
  );

  if (isLoading) {
    return <Loading loadingLayout="table" />;
  }

  if (error) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath(`${websiteMonitoringRoutes.monitoringGroups}`),
        }}
      >
        Could not load Asset Children.
      </ErrorContainer>
    );
  }

  return (
    <>
      <div className="tw-pb-2">
        <Label className="tw-text-lg tw-font-semibold tw-text-neutral-500">
          Children URLs
          <span className="tw-pl-2 tw-text-sm tw-text-neutral-300">
            {data?.data?.length}
          </span>
          <span className="tw-pl-1 tw-text-sm tw-text-neutral-300">
            {(data?.data?.length || 0) > 1 ? "children" : "child"}
          </span>
        </Label>
      </div>
      <Table
        width="2000px"
        rows={data?.data || []}
        columns={MONITORING_GROUP_ASSET_CHILDREN_COLUMNS_TABLE}
      />
    </>
  );
}
