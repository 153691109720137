import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis, } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from "@dnd-kit/sortable";
import { useEffect } from "react";
import React, { useState } from "react";
import { SortableItem } from "./SortableItem";
function SortableList(_a) {
    var items = _a.items, classes = _a.classes, className = _a.className, getIsItemDisabled = _a.getIsItemDisabled, _b = _a.iconPosition, iconPosition = _b === void 0 ? "right" : _b, renderItem = _a.renderItem, onReorder = _a.onReorder;
    var _c = useState(items), localItems = _c[0], setLocalItems = _c[1];
    useEffect(function () {
        setLocalItems(items);
    }, [items]);
    var sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    function handleDragEnd(event) {
        var active = event.active, over = event.over;
        if (active.id !== (over === null || over === void 0 ? void 0 : over.id)) {
            setLocalItems(function (prev) {
                var oldIndex = prev.findIndex(function (it) { return it.id === active.id; });
                var newIndex = prev.findIndex(function (it) { return it.id === (over === null || over === void 0 ? void 0 : over.id); });
                var newArray = arrayMove(prev, oldIndex, newIndex);
                onReorder === null || onReorder === void 0 ? void 0 : onReorder(newArray);
                return newArray;
            });
        }
    }
    return (React.createElement(DndContext, { autoScroll: true, modifiers: [restrictToVerticalAxis, restrictToParentElement], sensors: sensors, collisionDetection: closestCenter, onDragEnd: handleDragEnd },
        React.createElement(SortableContext, { items: localItems, strategy: verticalListSortingStrategy },
            React.createElement("div", { className: className }, localItems.map(function (item, index) {
                var _a;
                return (React.createElement(SortableItem, { key: item.id, className: classes === null || classes === void 0 ? void 0 : classes.item, id: item.id, iconPosition: iconPosition, isSortDisabled: (_a = getIsItemDisabled === null || getIsItemDisabled === void 0 ? void 0 : getIsItemDisabled(item)) !== null && _a !== void 0 ? _a : false }, renderItem(item, index)));
            })))));
}
export { SortableList };
