import type { AxiosError } from "axios";
import { isAxiosError } from "axios";

export type RailsErrors<T extends object = Record<string, string | string[]>> =
  {
    [key in keyof T]: string | string[];
  };

export function formatErrors<
  T extends object = Record<string, string | string[]>,
>(errors?: RailsErrors<T>): string {
  const formattedErrors = [];

  for (const key in errors) {
    const fieldErrors = errors[key as keyof T];
    const formattedFieldErrors = Array.isArray(fieldErrors)
      ? fieldErrors.join(", ")
      : fieldErrors;

    if (key === "base") {
      formattedErrors.push(`${formattedFieldErrors}.`);
    } else {
      const capitalizedKey = key[0].toUpperCase() + key.slice(1).toLowerCase();
      formattedErrors.push(`${capitalizedKey} ${formattedFieldErrors}.`);
    }
  }

  return formattedErrors.join("\n");
}

export function axiosConfigContainsError<
  T extends object = Record<string, string | string[]>,
>(
  config: AxiosError<RailsErrors<T>> | unknown,
  fieldName: keyof T,
  errorMessage: string,
): boolean {
  if (isAxiosError(config)) {
    return containsError(
      config.response?.data?.errors,
      fieldName,
      errorMessage,
    );
  }

  return false;
}

export function containsError<
  T extends object = Record<string, string | string[]>,
>(
  errors: RailsErrors<T> | null,
  fieldName: keyof T,
  errorMessage: string,
): boolean {
  const fieldErrors = errors?.[fieldName] || [];
  return Array.isArray(fieldErrors)
    ? fieldErrors.includes(errorMessage)
    : fieldErrors === errorMessage;
}
