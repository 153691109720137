import { faker } from "@faker-js/faker";

import type {
  UpdateFieldOption200,
  UpdateFieldOption403,
  UpdateFieldOptionMutationRequest,
  UpdateFieldOptionMutationResponse,
  UpdateFieldOptionPathParams,
} from "../models/UpdateFieldOption";
import { createFieldOption } from "./createFieldOption";

export function createUpdateFieldOptionPathParams(): NonNullable<UpdateFieldOptionPathParams> {
  return { field_id: faker.number.int(), id: faker.number.int() };
}

/**
 * @description Field option updated successfully
 */
export function createUpdateFieldOption200(): NonNullable<UpdateFieldOption200> {
  return { data: createFieldOption() };
}

/**
 * @description Forbidden
 */
export function createUpdateFieldOption403(): NonNullable<UpdateFieldOption403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createUpdateFieldOptionMutationRequest(): NonNullable<UpdateFieldOptionMutationRequest> {
  return { option: { value: faker.string.alpha() } };
}

/**
 * @description Field option updated successfully
 */
export function createUpdateFieldOptionMutationResponse(): NonNullable<UpdateFieldOptionMutationResponse> {
  return { data: createFieldOption() };
}
