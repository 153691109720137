import { http } from "msw";

import { createRecordsByMonthByFieldOptionQueryResponse } from "../createRecordsByMonthByFieldOption";

export const recordsByMonthByFieldOptionHandler = http.get(
  "*/companies/:company_id/:record_type/reports/records_by_month_by_field_option",
  function handler(info) {
    return new Response(
      JSON.stringify(createRecordsByMonthByFieldOptionQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
