import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@themis/ui";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiArchiveBold, PiClockBold, PiInfoBold } from "react-icons/pi";
import { Link, matchPath } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import MoreOptionsButton from "@/features/misc/MoreOptionsButton";

import { moduleInfo } from "./constants/module_info";

function HeadSelect() {
  const mainStore = useMainStore();

  const { themisModuleIdentifier: moduleIdentifier, workspaceID } =
    mainStore.context;
  const selectedModuleInfo = moduleInfo.find(
    (module) => module.identifier === moduleIdentifier,
  );
  const isActivityPage = !!matchPath(
    location.pathname,
    "/workspaces/:workspace_id/modules/:module_identifier/audit_trail",
  );

  const [isOpenModuleInfoModal, setIsOpenModuleInfoModal] = useState(false);

  const showMoreButton =
    moduleIdentifier &&
    (mainStore.userPermissions.canSeeNotifications ||
      moduleIdentifier !== "risk_assessment");

  return (
    <div className="tw-flex">
      {selectedModuleInfo && (
        <TooltipProvider>
          <Tooltip delayDuration={700}>
            <TooltipTrigger>
              <Popover
                onOpenChange={(isOpen) => setIsOpenModuleInfoModal(isOpen)}
              >
                <PopoverTrigger role="dialog" aria-label="Info" asChild>
                  <div
                    className={classNames(
                      "tw-flex tw-h-8 tw-w-8  tw-items-center tw-justify-center tw-rounded-md ",
                      {
                        "tw-rounded-md tw-bg-black": isOpenModuleInfoModal,
                        "hover:tw-bg-primary-25": !isOpenModuleInfoModal,
                      },
                    )}
                  >
                    <PiInfoBold
                      size="16px"
                      fill={isOpenModuleInfoModal ? "#fff" : "#353549"}
                    />
                  </div>
                </PopoverTrigger>
                <PopoverContent
                  className="tw-w-64 tw-rounded-lg tw-bg-black tw-p-4"
                  side="left"
                >
                  <div className="tw-text-left tw-text-sm tw-font-medium tw-leading-4 tw-tracking-lesswide tw-text-white">
                    <h2>{selectedModuleInfo.name}</h2>
                    <p className="tw-mt-2">{selectedModuleInfo.description}</p>
                    <ol className="tw-mt-5 tw-pl-4">
                      {selectedModuleInfo.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ol>
                    {selectedModuleInfo.link && (
                      <div className="tw-mt-5">
                        <p className="tw-text-xs tw-font-semibold tw-leading-5 tw-tracking-tight">
                          Want to learn more?{" "}
                          <a
                            className="tw-text-white tw-underline"
                            href={selectedModuleInfo.link}
                          >
                            Visit our module documentation
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                </PopoverContent>
              </Popover>
            </TooltipTrigger>
            <TooltipContent align="end" side="top" disableArrow>
              <p>Module Information</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      {moduleIdentifier &&
        [
          "control_mapping",
          "documents",
          "policy",
          "procedures",
          "training",
        ].includes(moduleIdentifier) && (
          <TooltipProvider>
            <Tooltip delayDuration={700}>
              <TooltipTrigger asChild>
                <Link
                  to={`/workspaces/${workspaceID}/modules/${kebabCase(
                    moduleIdentifier,
                  )}/archived`}
                  aria-label="View Archived"
                >
                  <div className="tw-flex tw-h-8 tw-w-8 tw-cursor-pointer  tw-items-center tw-justify-center tw-rounded-md hover:tw-bg-primary-25">
                    <PiArchiveBold size="16px" fill="353549" />
                  </div>
                </Link>
              </TooltipTrigger>
              <TooltipContent align="end" side="top" disableArrow>
                <p>View Archived</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

      {moduleIdentifier && moduleIdentifier !== "risk_assessment" && (
        <TooltipProvider>
          <Tooltip delayDuration={700}>
            <TooltipTrigger
              className={classNames({
                "tw-cursor-not-allowed": isActivityPage,
                "tw-cursor-pointer": !isActivityPage,
              })}
              asChild
            >
              <Link
                to={`/workspaces/${workspaceID}/modules/${kebabCase(
                  moduleIdentifier,
                )}/audit_trail`}
                aria-label="View Activity"
                className={classNames({
                  "tw-pointer-events-none": isActivityPage,
                })}
              >
                <div
                  className={classNames(
                    "tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-md hover:tw-bg-primary-25",
                  )}
                >
                  <PiClockBold size="16px" fill="#353549" />
                </div>
              </Link>
            </TooltipTrigger>
            <TooltipContent align="end" side="top" disableArrow>
              <p>View Activity</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      {showMoreButton && (
        <MoreOptionsButton moduleIdentifier={moduleIdentifier} />
      )}
    </div>
  );
}

export default observer(HeadSelect);
