import { http } from "msw";

import { createCreateCommentMutationResponse } from "../createCreateComment";

export const createCommentHandler = http.post(
  "*/:record_type/:record_id/comments",
  function handler(info) {
    return new Response(JSON.stringify(createCreateCommentMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
