import { faker } from "@faker-js/faker";

import type { DashboardComponent } from "../models/DashboardComponent";

export function createDashboardComponent(
  data: NonNullable<Partial<DashboardComponent>> = {},
): NonNullable<DashboardComponent> {
  return {
    ...{
      id: faker.number.int(),
      dashboard_id: faker.number.int(),
      report_id: faker.number.int(),
      name: faker.string.alpha(),
      system_name: faker.string.alpha(),
      component_type: faker.helpers.arrayElement<any>([
        "bar",
        "bar_grouped",
        "bar_stacked",
        "column",
        "column_grouped",
        "column_line",
        "column_line_grouped",
        "column_line_stacked",
        "column_stacked",
        "donut",
        "gauge",
        "line",
        "line_cumulative",
        "line_grouped",
        "line_grouped_cumulative",
        "metric",
        "pie",
        "scatter",
        "scatter_grouped",
        "table",
      ]),
      data: {},
    },
    ...data,
  };
}
