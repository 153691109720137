import { z } from "zod";

import type { FieldConfiguration } from "@/api";

export function getThemisDocumentSchema(
  field: FieldConfiguration,
  requiredMessage: string,
) {
  return z
    .object({
      signedId: z.string().optional(),
      url: z.string().optional(),
      type: z.string().optional(),
    })
    .nullable()
    .refine(
      (data) => {
        if (!field.required) {
          return true;
        }
        return Boolean(data?.signedId);
      },
      { message: requiredMessage },
    );
}
