import { http } from "msw";

import { createListTrainingsQueryResponse } from "../createListTrainings";

export const listTrainingsHandler = http.get(
  "*/workspaces/:workspace_id/trainings",
  function handler(info) {
    return new Response(JSON.stringify(createListTrainingsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
