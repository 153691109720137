import type { SelectItem } from "@themis/ui";
import { UserLabel } from "@themis/ui";
import { keyBy } from "lodash";
import { useMemo } from "react";
import type { FieldValues } from "react-hook-form";

import { useCompanyUsers } from "@/api/queries/users/use-company-users";
import type { SelectFormFieldProps } from "@/components/form-fields/select-form-field/select-form-field";
import { SelectFormField } from "@/components/form-fields/select-form-field/select-form-field";
import { useCompanyId } from "@/hooks/use-company-id";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { MultiSelectAvatar } from "./multi-select-avatar/multi-select-avatar";

type UserSelectFormFieldProps<T extends FieldValues> = Omit<
  SelectFormFieldProps<T>,
  "items"
>;

export function UserSelectFormField<T extends FieldValues>({
  multiselect,
  ...rest
}: UserSelectFormFieldProps<T>) {
  const { companyId } = useCompanyId();
  const workspaceId = useRouteWorkspaceId();

  const { data: users = [] } = useCompanyUsers({
    companyId,
    workspace_id: workspaceId,
  });

  const usersMap = useMemo(() => keyBy(users, "id"), [users]);

  const options: SelectItem[] = users.map((user) => ({
    label: user.full_name ?? "",
    value: user.id.toString(),
  }));

  return (
    <SelectFormField
      searchable
      items={options ?? []}
      renderOption={(option) => {
        const user = usersMap[option.value];

        if (!user.full_name) {
          return null;
        }

        return (
          <UserLabel
            initials={user.initials}
            colorIndex={user.icon_color_index}
            fullName={user.full_name}
          />
        );
      }}
      renderSelected={
        multiselect
          ? ({ value: userId }) => (
              <MultiSelectAvatar
                key={userId}
                usersMap={usersMap}
                userId={userId}
              />
            )
          : undefined
      }
      multiselect={multiselect}
      {...rest}
    />
  );
}
