import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import LinkCell from "../cell-type/LinkCell";

interface Props {
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  onDataChange?: (
    fieldname: string,
    serializedVal: string,
    val?: string,
  ) => unknown;
  fieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  type?: string;
}

function ViewLinkCell({
  recordVersion,
  fieldName,
  recordVersionID,
  type,
  exposeData,
  onDataChange,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const link = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion?.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion?.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <LinkCell
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      width="100%"
      initialValue={link}
      disabled={false}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      type={type}
      isUserEditable
      hasErrorClass="detail-view-has-errors"
      exposeData={exposeData}
      onDataChange={onDataChange}
    />
  );
}

export default observer(ViewLinkCell);
