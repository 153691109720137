import { useCallback } from "react";
import { useSessionStorage } from "usehooks-ts";

import { useCurrentUser } from "@/api/queries/users";

import { isQuarter } from "../help/utils";

export interface DashboardTimeFrameParams {
  days?: string;
  start_date?: string;
  end_date?: string;
  quarter_start_date?: string;
  quarter_end_date?: string;
}

/**
 * Hook to manage the dashboard time frame params in the session storage
 */
export function useDashboardTimeFrameParams(isOutlook?: boolean) {
  const { data: user } = useCurrentUser();

  const [value, setValue] = useSessionStorage<DashboardTimeFrameParams>(
    `dashboard-params-user-${user?.id}`,
    {},
  );

  const setDashboardTimeFrameParams = useCallback(
    (paramsToUpdate: Partial<DashboardTimeFrameParams>) => {
      setValue((currentValue) => ({ ...currentValue, ...paramsToUpdate }));
    },
    [],
  );

  return {
    dashboardTimeFrameParams: {
      ...value,
      days: isOutlook && isQuarter(value.days || "") ? undefined : value.days,
    },
    setDashboardTimeFrameParams,
  };
}
