var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { PiDotsSixVertical } from "react-icons/pi";
import { cn } from "../../lib/utils";
import { IconButton } from "../Button/IconButton";
export function SortableItem(_a) {
    var id = _a.id, children = _a.children, className = _a.className, _b = _a.iconPosition, iconPosition = _b === void 0 ? "right" : _b, _c = _a.isSortDisabled, isSortDisabled = _c === void 0 ? false : _c;
    var _d = useSortable({ id: id }), attributes = _d.attributes, isDragging = _d.isDragging, listeners = _d.listeners, setNodeRef = _d.setNodeRef, transform = _d.transform, transition = _d.transition;
    var style = __assign({ transform: CSS.Transform.toString(transform), transition: transition }, (isDragging && {
        position: "relative",
        zIndex: 1,
    }));
    var Icon = !isSortDisabled ? (React.createElement(IconButton, __assign({ className: "hover:tw-cursor-grab active:tw-cursor-grabbing active:tw-bg-primary-25 active:tw-text-neutral-500", color: "transparent", Icon: PiDotsSixVertical }, listeners))) : null;
    return (React.createElement("div", __assign({ ref: !isSortDisabled ? setNodeRef : null, style: style }, attributes, { className: cn("tw-flex tw-select-none tw-flex-row tw-items-center tw-justify-between", className) }),
        iconPosition === "left" && Icon,
        children,
        iconPosition === "right" && Icon));
}
