import { AddNewRowLink, Table } from "@themis/ui";
import React from "react";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";

import type { Contract } from "@/api";

import { useContractsTableColumns } from "../../config/use-contracts-table-columns";

export function AccountContracts({ contracts }: { contracts: Contract[] }) {
  const { url } = useRouteMatch();
  const { formatMessage } = useIntl();

  const columns = useContractsTableColumns();

  return (
    <>
      <Table columns={columns} rows={contracts || []} />

      <AddNewRowLink
        className="tw-mt-1"
        title={formatMessage({ defaultMessage: "Add Contract" })}
        to={{
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }}
      />
    </>
  );
}
