import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import CreateNewPassword from "./CreateNewPassword";
import EmailVerificationExpired from "./EmailVerificationExpired";
import EmailVerificationSuccess from "./EmailVerificationSuccess";
import ForgotPassword from "./ForgotPassword";
import InviteSignUp from "./InviteSignUp";
import { routes } from "./routes";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

export const AuthRoutes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to={routes.signIn} />
    </Route>
    <Route path={routes.signIn}>
      <SignIn />
    </Route>
    <Route path={routes.signUp}>
      <SignUp />
    </Route>
    <Route path={routes.forgotPassword}>
      <ForgotPassword />
    </Route>
    <Route path={routes.createNewPassword}>
      <CreateNewPassword />
    </Route>
    <Route path={routes.emailVerificationExpired}>
      <EmailVerificationExpired />
    </Route>
    <Route path={routes.emailVerificationSuccess}>
      <EmailVerificationSuccess />
    </Route>
    <Route path={routes.inviteSignUp}>
      <InviteSignUp />
    </Route>
  </Switch>
);
