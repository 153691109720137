import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";

import { useMainStore } from "@/contexts/Store";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { getSelectedTab } from "../helpers/Tabs";

function CurrentUserSettingsNavigation() {
  const mainStore = useMainStore();

  const { isCurrentWorkspaceActive } = mainStore.workspaces;

  const { pathname } = useLocation();

  const { formatMessage } = useIntl();

  const publicApiEnabled =
    isCurrentWorkspaceActive &&
    mainStore.featureFlags.getIsEnabled(FEATURE_FLAG_ID.COM_PUBLIC_API);

  const TABS = [
    {
      name: formatMessage({ defaultMessage: "Profile Details" }),
      key: "profile-details",
      value: "/user/profile",
    },
    {
      name: formatMessage({ defaultMessage: "Integrations" }),
      key: "integrations",
      value: "/user/integrations",
    },
    ...(publicApiEnabled
      ? [
          {
            name: formatMessage({ defaultMessage: "API Keys" }),
            key: "api-keys",
            value: "/user/api-keys",
          },
        ]
      : []),
  ];

  return (
    <div className="tw-flex tw-h-12 tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5 tw-py-0">
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS, true, pathname)?.value || ""}
        isLink
      />
    </div>
  );
}

export default observer(CurrentUserSettingsNavigation);
