import { http } from "msw";

import { createListOfCompanyControlMappingTypesQueryResponse } from "../createListOfCompanyControlMappingTypes";

export const listOfCompanyControlMappingTypesHandler = http.get(
  "*/companies/:company_id/control_mapping_types",
  function handler(info) {
    return new Response(
      JSON.stringify(createListOfCompanyControlMappingTypesQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
