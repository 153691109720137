export const myTaskRoutes = {
  base: "/my-tasks",
  viewsBase: "/my-tasks/view",
  taskDetail: "/my-tasks/tasks/:task_id",
  assignedToMeView: "/my-tasks/view/assigned-to-me",
  createdByMeView: "/my-tasks/view/created-by-me",
  assignedToOthersView: "/my-tasks/view/assigned-to-others",
  allView: "/my-tasks/view/all",
  archived: "/my-tasks/archived",
};
