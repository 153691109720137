import { faker } from "@faker-js/faker";

import type { User } from "../models/User";

export function createUser(
  data: NonNullable<Partial<User>> = {},
): NonNullable<User> {
  return {
    ...{
      id: faker.number.int(),
      full_name: faker.string.alpha(),
      initials: faker.string.alpha(),
      icon_color_index: faker.number.int(),
      is_active: faker.datatype.boolean(),
      can_authenticate_with_email_and_password: faker.datatype.boolean(),
    },
    ...data,
  };
}
