import { faker } from "@faker-js/faker";

import type {
  ExportRiskRegisterGroups200,
  ExportRiskRegisterGroups403,
  ExportRiskRegisterGroupsPathParams,
  ExportRiskRegisterGroupsQueryParams,
  ExportRiskRegisterGroupsQueryResponse,
} from "../models/ExportRiskRegisterGroups";

export function createExportRiskRegisterGroupsPathParams(): NonNullable<ExportRiskRegisterGroupsPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createExportRiskRegisterGroupsQueryParams(): NonNullable<ExportRiskRegisterGroupsQueryParams> {
  return { exported_group_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createExportRiskRegisterGroups200(): NonNullable<ExportRiskRegisterGroups200> {
  return faker.string.alpha();
}

/**
 * @description Forbidden
 */
export function createExportRiskRegisterGroups403(): NonNullable<ExportRiskRegisterGroups403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createExportRiskRegisterGroupsQueryResponse(): NonNullable<ExportRiskRegisterGroupsQueryResponse> {
  return faker.string.alpha();
}
