import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import { FIELD_TYPES } from "@/components/helpers/fieldTypes";
import type { ColorName } from "@/config/theme";
import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/check-icon.svg";
import type { IconName } from "../../Elements/Icon/icon";
import { useResize } from "../../useResize/useResize";
import Switch from "./Switch";

interface Icons {
  [key: string]: IconName;
}

export function iconForDataType(dataType: string): IconName {
  const icons: Icons = {
    "com.askthemis.types.v1.date": "calendar",
    "com.askthemis.types.v1.attachment": "attachment",
    "com.askthemis.types.v1.integer": "number",
    "com.askthemis.types.v1.float": "number",
    "com.askthemis.types.v1.option": "listBullet",
    "com.askthemis.types.v1.constrained_option": "listBullet",
    "com.askthemis.types.v1.record_policy": "contact",
    "com.askthemis.types.v1.record_procedure": "contact",
    "com.askthemis.types.v1.tag_department": "tag",
    "com.askthemis.types.v1.tag_user": "contact",
    "com.askthemis.types.v1.tag_user_contact": "contact",
    "com.askthemis.types.v1.tag_product": "tag",
    "com.askthemis.types.v1.tag": "tag",
    "com.askthemis.types.v1.link": "link",
    "com.askthemis.types.v1.text": "text",
    "com.askthemis.types.v1.long_text": "text",
    "com.askthemis.types.v1.multiline_text": "text",
    "com.askthemis.types.v1.tag_verifier": "contact",
    "com.askthemis.types.v1.checkbox": "tag",
    "com.askthemis.types.v1.checklist": "checkedLinear",
    "com.askthemis.types.v1.control_effectiveness_rating": "listBullet",
  };

  return icons[dataType] || "text";
}

// @ts-expect-error TS(7006) FIXME: Parameter 'computedColumnIdentifier' implicitly ha... Remove this comment to see the full error message
export function titleForComputedDataType(computedColumnIdentifier) {
  switch (computedColumnIdentifier) {
    case "record_version":
      return "Version";
    case "owner_id":
      return "Owner";
    case "meta.days_open":
      return "Days Open";
    case "questionnaires":
      return "Questionnaires";
    case "contracts":
      return "Contracts";
    case "policies_controls":
      return "Policies";
    case "procedures_controls":
      return "Procedures";
    default:
      return "Unknown";
  }
}

const FILTERABLE_TYPES = [
  "com.askthemis.types.v1.date",
  "com.askthemis.types.v1.integer",
  "com.askthemis.types.v1.tag_department",
  "com.askthemis.types.v1.tag_user",
  "com.askthemis.types.v1.tag_user_contact",
  "com.askthemis.types.v1.tag_product",
  "com.askthemis.types.v1.text",
  "com.askthemis.types.v1.long_text",
  "com.askthemis.types.v1.multiline_text",
  "com.askthemis.types.v1.option",
  "com.askthemis.types.v1.attachment",
];

// @ts-expect-error TS(7006) FIXME: Parameter 'displayName' implicitly has an 'any' ty... Remove this comment to see the full error message
function dataTestNameFromDisplayName(displayName) {
  return `${displayName
    ?.toString()
    .toLowerCase()
    .replace(/ /g, "-")}-column-trigger`;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRename?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortByDirection?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortByFieldName?: (...args: any[]) => any;
  sortDirection?: string;
  disableResize?: boolean;
}

function ModuleTableColumn({
  field,
  sortDirection,
  setSortByDirection,
  setSortByFieldName,
  onDelete,
  onRename,
  disableResize,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { workspaceID, moduleWorkspaceID, tableID } = mainStore.context;
  const { canManageColumns, hasModuleWriteAccess } = mainStore.userPermissions;
  const fieldName = field.name;
  const isThemisID = field.name === "id";
  const width = isThemisID ? 160 : field.width || 200;
  const displayName = field.display_name;
  const isPinned = fieldName === mainStore.fields.pinnedFieldName;
  const isCustom = field.is_custom_field;
  const isHidden = field.is_hidden;
  const isDefaultField = field.is_default_field;
  const { isCW } = mainStore.context;
  const columnData = FIELD_TYPES.find((type) => type.value === field.data_type);
  const isKriInputStart = field.kri_input_start_month;
  const isKriInputEnd = field.kri_input_end_month;
  const rulesIndicatingRequiredField = [
    "present",
    "exists",
    "validators",
    "attachment",
    "checklist",
  ];
  const isRequired =
    // field is not always type Field therefore does not always have validation_rules
    rulesIndicatingRequiredField.some(
      (rule) => field.validation_rules?.[rule],
    ) ||
    (isCustom && field.is_required);

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [popUp, setPopUp] = useState(null);
  const [fieldTitle, setFieldTitle] = useState(displayName || "");

  // Pre-fetch data for Columns
  useEffect(() => {
    if (
      (workspaceID &&
        dataType === "record:policy" &&
        mainStore.records.policies.length === 0) ||
      (dataType === "record:procedure" &&
        mainStore.records.procedures.length === 0)
    ) {
      mainStore.records.index(dataType, workspaceID);
    }
  }, []);

  const ref = React.useRef();
  const options = {
    step: 1,
    axis: "horizontal",
  };
  const { initResize, size } = useResize(ref, options, width);

  const style = { width };

  const dataType = field.data_type;

  function saveNewWidth() {
    // Only persist change if new width has changed
    if (size?.width && size.width !== width) {
      mainStore.fields.updateWidth(tableID, fieldName, size.width);
    }
  }

  function closePopUp() {
    setShowPopup(false);
    setPopUp(null);
  }

  async function togglePin() {
    await mainStore.fields.togglePinnedField(moduleWorkspaceID, fieldName);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleCustomColumnRenameSubmit(event) {
    event.preventDefault();
    if (onRename && fieldTitle !== displayName) {
      onRename(fieldName, fieldTitle);
    }
    setShowPopup(false);
  }

  function sortColumn(sortName: SortOptions) {
    if (!filterable) {
      return;
    }
    if (setSortByDirection && setSortByFieldName && fieldName) {
      setSortByDirection(sortName);
      setSortByFieldName(fieldName);
      closePopUp();
    }
  }

  function handleClearSortValues() {
    if (setSortByDirection) {
      setSortByDirection("");
      closePopUp();
    }
  }

  function pinUnpin() {
    togglePin();
    closePopUp();
  }

  useEffect(() => {
    window.addEventListener("mouseup", saveNewWidth);

    return () => {
      window.removeEventListener("mouseup", saveNewWidth);
    };
  }, [size.width]);

  const filterable = FILTERABLE_TYPES.includes(field.data_type);
  const headerClasses = classNames("list-title-wrap", {
    sorted: sortDirection,
    pinned: isPinned,
    "global-id-width global-id-column": isThemisID,
    "global-id-mr first-position": field.position === 1 && isThemisID,
  });

  const isPopupDisabled =
    [
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/templates`,
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/risk-assessment-templates`,
    ].includes(location.pathname) ||
    (location.pathname.startsWith(
      `/workspaces/${workspaceID}/modules/qa-tests`,
    ) &&
      location.pathname.endsWith("/linked_documents")); // LinkedDocuments are without context menu

  if (isHidden) {
    return <div />;
  }

  function showColumnIcon(): JSX.Element {
    if (isThemisID) {
      return <Icon name="globe" color="brandingHoverNavy" />;
    }

    if (isPinned || sortDirection) {
      return (
        <Icon
          name={iconForDataType(dataType)}
          color="brandingHighlightTurquoise"
        />
      );
    }

    return <Icon name={iconForDataType(dataType)} color="generalDarkGray" />;
  }

  function showPinnedIcon(): JSX.Element {
    return isThemisID ? (
      <Icon name="anchor" color="brandingHoverNavy" data-testid="anchor-icon" />
    ) : (
      <Icon
        name="anchor"
        color="brandingHighlightTurquoise"
        data-testid="anchor-icon"
      />
    );
  }

  function showSortDirectionIcon(): JSX.Element {
    let sourceIcon: IconName;
    let dataTestId: string;

    const color: ColorName = isThemisID
      ? "brandingHoverNavy"
      : "brandingHighlightTurquoise";

    if (sortDirection === "ASC") {
      sourceIcon = "arrowUp";
      dataTestId = "asc-icon";
    } else {
      sourceIcon = "arrowDown";
      dataTestId = "desc-icon";
    }

    return <Icon name={sourceIcon} color={color} data-testid={dataTestId} />;
  }

  function showKriIcon(icon: "pause" | "play"): JSX.Element {
    const color = isPinned ? "brandingHighlightTurquoise" : "generalDarkGray";
    return <Icon name={icon} color={color} size="de" />;
  }

  return (
    <li className={headerClasses}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
      <div ref={ref} className="title-resize-wrap" style={style}>
        <Popup
          position="bottom right"
          trigger={
            <div className="title-trigger-wrap" data-testid="column-trigger">
              <div
                className={classNames("title-trigger-block", {
                  "global-id": isThemisID,
                })}
                data-testid={dataTestNameFromDisplayName(field.display_name)}
              >
                {showColumnIcon()}

                <label
                  className={
                    isThemisID ? "global-id-column-context" : "column-title"
                  }
                >
                  {displayName}
                </label>

                {isRequired && <div className="required">*</div>}

                {isKriInputStart && showKriIcon("play")}
                {isKriInputEnd && showKriIcon("pause")}

                {isPinned && showPinnedIcon()}

                {sortDirection && showSortDirectionIcon()}
              </div>
            </div>
          }
          disabled={isPopupDisabled}
          open={showPopup}
          onOpen={() =>
            canManageColumns ? setShowPopup(true) : setShowPopup(false)
          }
          onClose={closePopUp}
          keepTooltipInside
        >
          {!popUp && (
            <div
              className="table-dropdown title-select"
              data-testid="column-dropdown"
            >
              {isCustom && hasModuleWriteAccess && (
                <form
                  className="title-input-block"
                  onSubmit={handleCustomColumnRenameSubmit}
                >
                  <input
                    type="text"
                    value={fieldTitle}
                    maxLength={40}
                    onChange={(event) => setFieldTitle(event.target.value)}
                    data-testid="column-dropdown-title-input"
                    className="custom-column-input "
                  />
                  <button
                    disabled={fieldTitle.length > 40}
                    className="check-icon"
                    data-testid="custom-column-rename-submit"
                    onClick={handleCustomColumnRenameSubmit}
                  >
                    <img src={checkIcon} alt="icon" />
                  </button>
                </form>
              )}

              <div className="title-block">
                <h5>Property</h5>
                <ul>
                  <li className="property">
                    <Icon
                      name={iconForDataType(field.data_type)}
                      color="generalLightBlue"
                    />
                    {columnData?.label ? columnData?.label : "Free Type"}
                  </li>
                </ul>
              </div>
              <hr />
              <ul>
                <li
                  className={classNames(
                    { enabled: filterable },
                    { active: sortDirection === "ASC" },
                  )}
                  onClick={() => sortColumn("ASC")}
                  data-testid="sort-asc-button"
                >
                  {filterable ? (
                    <Icon name="arrowUp" color="generalDark" />
                  ) : (
                    <Icon name="arrowUp" color="generalDarkGray" />
                  )}
                  Sort Ascending
                </li>
                <li
                  className={classNames(
                    { enabled: filterable },
                    { active: sortDirection === "DESC" },
                  )}
                  onClick={() => sortColumn("DESC")}
                  data-testid="sort-desc-button"
                >
                  {filterable ? (
                    <Icon name="arrowDown" color="generalDark" />
                  ) : (
                    <Icon name="arrowDown" color="generalDarkGray" />
                  )}
                  Sort Descending
                </li>
                <li
                  className={classNames("pin", "enabled", {
                    active: isPinned,
                  })}
                  onClick={pinUnpin}
                  data-testid="pin-unpin-button"
                >
                  <Icon name="anchor" color="generalDark" />
                  {isPinned ? "Unpin" : "Pin"}
                </li>

                {sortDirection && (
                  <>
                    <hr />
                    <li
                      className="clear"
                      onClick={() => handleClearSortValues()}
                      data-testid="clear-button"
                    >
                      <Icon name="close" color="generalError" />
                      Clear Sort
                    </li>
                  </>
                )}

                {isCustom && canManageColumns && !isDefaultField && (
                  <>
                    <li
                      className="enabled required-switch-row"
                      data-tooltip-left="Required columns must be completed in order to finalize or lock a record."
                    >
                      Required Column
                      <div className="is-required-switch">
                        <Switch
                          active
                          indeterminate={false}
                          checked={field.is_required}
                          onChange={() =>
                            mainStore.fields.toggleIsRequired(
                              tableID,
                              field.name,
                            )
                          }
                        />
                      </div>
                    </li>
                    <hr />
                    <li
                      className="clear"
                      // @ts-expect-error TS(2345) FIXME: Argument of type '"delete"' is not assignable to p... Remove this comment to see the full error message
                      onClick={() => setPopUp("delete")}
                      data-testid="column-dropdown-delete-button"
                    >
                      <Icon name="trash" color="generalError" />
                      Delete Column
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}

          {popUp === "delete" && (
            <div
              className="table-dropdown title-select delete-column"
              data-testid="column-dropdown-delete-popup"
            >
              <h5>Delete Custom Column</h5>
              <p>Do you want to remove this column permanently?</p>
              <p className="danger">
                {isCW
                  ? "It will be removed from the current workspace"
                  : "It will be removed from internal and collaborative workspaces."}
              </p>
              <p className="danger">
                All the content under this column will be removed completely.
              </p>
              <p>Are you sure you want to proceed?</p>
              <div className="buttons">
                <button
                  type="button"
                  className="primary"
                  data-testid="column-dropdown-delete-popup-confirm"
                  onClick={() => {
                    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                    onDelete(fieldName);
                    closePopUp();
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={() => setPopUp(null)}
                  data-testid="column-dropdown-delete-popup-reject"
                >
                  No
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>
      <span
        className={classNames("resize-block", {
          hidden: disableResize || isThemisID,
        })}
        onMouseDown={initResize}
      />
    </li>
  );
}

export default observer(ModuleTableColumn);
