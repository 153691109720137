import { faker } from "@faker-js/faker";

import type {
  GetAttestationListAttestations200,
  GetAttestationListAttestationsPathParams,
  GetAttestationListAttestationsQueryParams,
  GetAttestationListAttestationsQueryResponse,
} from "../models/GetAttestationListAttestations";
import { createAttestation } from "./createAttestation";
import { createPaginatedMeta } from "./createPaginatedMeta";

export function createGetAttestationListAttestationsPathParams(): NonNullable<GetAttestationListAttestationsPathParams> {
  return { attestation_list_id: faker.number.int() };
}

export function createGetAttestationListAttestationsQueryParams(): NonNullable<GetAttestationListAttestationsQueryParams> {
  return {
    sort_by: faker.string.alpha(),
    limit: faker.number.int(),
    offset: faker.number.int(),
  };
}

/**
 * @description OK
 */
export function createGetAttestationListAttestations200(): NonNullable<GetAttestationListAttestations200> {
  return {
    data: faker.helpers.arrayElements([createAttestation()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createGetAttestationListAttestationsQueryResponse(): NonNullable<GetAttestationListAttestationsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createAttestation()]) as any,
    meta: createPaginatedMeta(),
  };
}
