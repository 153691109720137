import type { ComponentType } from "react";

import type { FieldConfigurationReferenceType } from "@/api";

import type { DynamicCellProps } from "../dynamic-cell";
import { DepartmentCell } from "./department-cell/department-cell";
import { PolicyCell } from "./policy-cell/policy-cell";
import { ReviewCell } from "./review-cell/review-cell";
import { ThemisDocumentCell } from "./themis-document-cell/themis-document-cell";
import { UserCell } from "./user-cell/user-cell";

const referenceCells = {
  Account: null,
  Attachment: null,
  Contact: null,
  Contract: null,
  Department: DepartmentCell,
  Issue: null,
  Policy: PolicyCell,
  Questionnaire: null,
  RecordLink: null,
  RecordVersion: null,
  Review: ReviewCell,
  Task: null,
  ThemisDocument: ThemisDocumentCell,
  User: UserCell,
  VendorChecklist: null,
} satisfies Record<
  FieldConfigurationReferenceType,
  ComponentType<DynamicCellProps> | null
>;

export const DynamicReferenceCell = ({
  value,
  configuration,
  record,
}: DynamicCellProps) => {
  if (!configuration.reference_type) {
    return null;
  }

  const Component = referenceCells[configuration.reference_type];

  if (Component) {
    return (
      <Component value={value} configuration={configuration} record={record} />
    );
  }
};
