import { http } from "msw";

import { createDeleteSectionMutationResponse } from "../createDeleteSection";

export const deleteSectionHandler = http.delete(
  "*/sections/:id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteSectionMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
