import { http } from "msw";

import { createGetTaskQueryResponse } from "../createGetTask";

export const getTaskHandler = http.get("*/tasks/:id", function handler(info) {
  return new Response(JSON.stringify(createGetTaskQueryResponse()), {
    headers: {
      "Content-Type": "application/json",
    },
  });
});
