import { faker } from "@faker-js/faker";

import type {
  ListModuleAssessments200,
  ListModuleAssessmentsPathParams,
  ListModuleAssessmentsQueryParams,
  ListModuleAssessmentsQueryResponse,
} from "../models/ListModuleAssessments";
import { createModuleAssessment } from "./createModuleAssessment";

export function createListModuleAssessmentsPathParams(): NonNullable<ListModuleAssessmentsPathParams> {
  return { record_version_id: faker.number.int() };
}

export function createListModuleAssessmentsQueryParams(): NonNullable<ListModuleAssessmentsQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
  };
}

/**
 * @description OK
 */
export function createListModuleAssessments200(): NonNullable<ListModuleAssessments200> {
  return {
    data: faker.helpers.arrayElements([createModuleAssessment()]) as any,
  };
}

/**
 * @description OK
 */
export function createListModuleAssessmentsQueryResponse(): NonNullable<ListModuleAssessmentsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createModuleAssessment()]) as any,
  };
}
