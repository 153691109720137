import { faker } from "@faker-js/faker";

import type {
  CreateFinding201,
  CreateFinding403,
  CreateFinding404,
  CreateFindingMutationRequest,
  CreateFindingMutationResponse,
} from "../models/CreateFinding";
import { createCreateFindingRequest } from "./createCreateFindingRequest";
import { createFinding } from "./createFinding";

/**
 * @description OK
 */
export function createCreateFinding201(): NonNullable<CreateFinding201> {
  return { data: createFinding() };
}

/**
 * @description Forbidden
 */
export function createCreateFinding403(): NonNullable<CreateFinding403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description Not Found
 */
export function createCreateFinding404(): NonNullable<CreateFinding404> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateFindingMutationRequest(): NonNullable<CreateFindingMutationRequest> {
  return createCreateFindingRequest();
}

/**
 * @description OK
 */
export function createCreateFindingMutationResponse(): NonNullable<CreateFindingMutationResponse> {
  return { data: createFinding() };
}
