import type { FormatDateOptions } from "react-intl";
import { useIntl } from "react-intl";

import type { DynamicCellProps } from "../dynamic-cell";

const formatDateOptions: FormatDateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const DynamicDateTimeCell = ({ value }: DynamicCellProps<string>) => {
  const intl = useIntl();

  const dateTimes = (Array.isArray(value) ? value : [value]).filter(
    (date) => date !== undefined,
  );

  return (
    <ul>
      {dateTimes.map((dateTime, i) => (
        <li key={`${dateTime}-${i}`}>
          {intl.formatDate(dateTime, formatDateOptions)}
        </li>
      ))}
    </ul>
  );
};
