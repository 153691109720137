import { noop } from "lodash";
import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { useMainStore } from "@/contexts/Store";

import DateSelect from "../cell-type/DateSelect";
import DateTimeCell from "../cell-type/DateTimeCell";

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  dataTestId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  locked?: boolean;
  isUserEditable?: boolean;
  isDefaultField?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDate?: any; // TODO: PropTypes.instanceOf(Date)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDateFunction?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDate?: any; // TODO: PropTypes.instanceOf(Date)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDateFunction?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  onlyMonthYearPicker?: boolean;
  recordVersionID?: number;
  hasReadWriteAccess?: boolean;
}

function ViewDateSelect({
  recordVersion,
  fieldName,
  recordVersionID,
  locked,
  maxDate,
  minDate,
  dataTestId,
  minDateFunction,
  maxDateFunction,
  exposeData,
  initialValue,
  onlyMonthYearPicker,
  onDataChange,
  isUserEditable,
  isDefaultField,
  hasReadWriteAccess,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const value =
    initialValue ||
    mainStore.avroSchemas.valueForField(fieldName, recordVersion?.data);
  const cellError = useMemo(
    () => mainStore.recordVersions.errorsForField(recordVersion?.id, fieldName),
    [
      recordVersion?.id,
      fieldName,
      mainStore.recordVersions.cellsErrors,
      mainStore.recordVersions.newRecordVersionErrors,
    ],
  );
  const hasErrors = Boolean(cellError);

  const dateSelect = (
    <DateSelect
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      width="100%"
      initialValue={value}
      locked={locked}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      maxDate={maxDate}
      minDate={minDate}
      minDateFunction={minDateFunction}
      maxDateFunction={maxDateFunction}
      dataTestId={dataTestId}
      exposeData={exposeData}
      hasErrorClass="detail-view-has-errors"
      onDataChange={onDataChange}
      onlyMonthYearPicker={onlyMonthYearPicker}
      isUserEditable={isUserEditable}
      hasReadWriteAccess={hasReadWriteAccess}
    />
  );

  const dateDefault = (
    <DateTimeCell
      value={value}
      // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
      locked={locked}
    />
  );

  return isDefaultField ? dateDefault : dateSelect;
}

ViewDateSelect.defaultProps = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
  locked: false,
  dataTestId: "",
  minDateFunction: noop,
  maxDateFunction: noop,
  isDefaultField: false,
  hasReadWriteAccess: false,
};

export default observer(ViewDateSelect);
