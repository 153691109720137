import { http } from "msw";

import { createCreateModuleAssessmentDocumentMutationResponse } from "../createCreateModuleAssessmentDocument";

export const createModuleAssessmentDocumentHandler = http.post(
  "*/module_assessments/:module_assessment_id/documents",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateModuleAssessmentDocumentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
