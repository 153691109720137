import { faker } from "@faker-js/faker";

import type {
  ShowThemisDocument200,
  ShowThemisDocumentPathParams,
  ShowThemisDocumentQueryResponse,
} from "../models/ShowThemisDocument";
import { createThemisDocument } from "./createThemisDocument";

export function createShowThemisDocumentPathParams(): NonNullable<ShowThemisDocumentPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createShowThemisDocument200(): NonNullable<ShowThemisDocument200> {
  return { data: createThemisDocument() };
}

/**
 * @description OK
 */
export function createShowThemisDocumentQueryResponse(): NonNullable<ShowThemisDocumentQueryResponse> {
  return { data: createThemisDocument() };
}
