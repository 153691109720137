import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateTrainingMutationRequest,
  CreateTrainingMutationResponse,
  CreateTrainingPathParams,
} from "../../models/CreateTraining";

/**
 * @description Create training
 * @link /workspaces/:workspace_id/trainings
 */
export async function createTraining(
  workspaceId: CreateTrainingPathParams["workspace_id"],
  data: CreateTrainingMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateTrainingMutationResponse>["data"]> {
  const res = await client<
    CreateTrainingMutationResponse,
    CreateTrainingMutationRequest
  >({
    method: "post",
    url: `/workspaces/${workspaceId}/trainings`,
    data,
    ...options,
  });
  return res.data;
}
