import { faker } from "@faker-js/faker";

import type { ApprovalConfig } from "../models/ApprovalConfig";
import { createApprovalStep } from "./createApprovalStep";

export function createApprovalConfig(
  data: NonNullable<Partial<ApprovalConfig>> = {},
): NonNullable<ApprovalConfig> {
  return {
    ...{
      approval_steps: faker.helpers.arrayElements([
        createApprovalStep(),
      ]) as any,
      id: faker.number.int(),
      module_workspace_id: faker.number.int(),
    },
    ...data,
  };
}
