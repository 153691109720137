import type { FilePickerProps } from "@themis/ui";
import {
  cn,
  FilePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useToast,
} from "@themis/ui";
import { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import type {
  Control,
  ControllerRenderProps,
  FieldValues,
  Path,
} from "react-hook-form";
import { useIntl } from "react-intl";

interface AttachmentFormFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  required?: boolean;
  placeholder?: string;
}

export function AttachmentFormField<T extends FieldValues>({
  control,
  name,
  label,
  required,
  placeholder,
}: AttachmentFormFieldProps<T>) {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const [file, setFile] = useState<FilePickerProps["file"]>(undefined);
  const filePickerRef = useRef<HTMLButtonElement>(null);
  const fileToUpload = useRef<File>();

  const handleUploadSuccess = (
    signedIds: string[],
    onChange: ControllerRenderProps["onChange"],
  ) => {
    if (!fileToUpload.current) {
      return;
    }

    filePickerRef.current?.click();

    setFile(fileToUpload.current);

    onChange({
      signedId: signedIds[0],
    });
  };

  const handleError = () => {
    toast({
      content: formatMessage({
        defaultMessage:
          "Error uploading file. Please try again or use a different file type.",
      }),
      variant: "error",
    });
  };

  return (
    <FormField
      control={control}
      name={name}
      required={required}
      render={({ field, fieldState }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <DirectUploadProvider
              onSuccess={(signedIds: string[]) => {
                handleUploadSuccess(signedIds, field.onChange);
              }}
              onError={handleError}
              render={({ handleUpload, uploads }) => (
                <FilePicker
                  ref={filePickerRef}
                  placeholder={placeholder}
                  className={cn({
                    "tw-bg-warning-50 tw-text-warning-300": fieldState.error,
                  })}
                  isLoading={["uploading", "waiting"].includes(
                    uploads[0]?.state,
                  )}
                  percentage={Math.round(uploads[0]?.progress) || 0}
                  onSelectFile={(selectedFile) => {
                    // DirectUploadProvider does not return file onSuccess so we have to save a ref of it to use it in handleUploadSuccess
                    fileToUpload.current = selectedFile;
                    handleUpload([selectedFile]);
                  }}
                  onRemoveFile={() => {
                    setFile(undefined);
                    field.onChange(null);
                  }}
                  file={file || field.value}
                />
              )}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
