import { useToast } from "@themis/ui";
import React from "react";
import { useParams } from "react-router-dom";

import { useIssuesReport } from "@/api/queries/exports";
import { useFetchFile } from "@/api/queries/use-fetch-file";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";
import { getTableFiltersParam } from "@/stores/helpers/getTableFiltersParam";

function IMExportReport({ onClose }: { onClose?: () => void }) {
  const toast = useToast();
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { mutateAsync: fetchFile } = useFetchFile();
  const { mutateAsync: issuesReport } = useIssuesReport({
    workspaceId: Number(workspace_id),
    onSuccess: () => {
      toast({
        content: "Issue Report has been exported!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to export document!",
        variant: "error",
      });
    },
  });

  async function downloadExcelReport(file_format: "xlsx" | "csv") {
    toast({
      content: "Issue Report is preparing to be exported",
      variant: "success",
    });

    if (onClose) {
      onClose();
    }

    const { data } = await issuesReport({
      filters: getTableFiltersParam(),
      file_format,
    });

    if (!data) {
      return;
    }

    const blob = await fetchFile(data.file_url);
    downloadFile(blob, data.file_name);
  }

  return (
    <div className="tw-pt-2">
      <h3 className="tw-px-4 tw-pb-2 tw-pt-1 tw-text-sm tw-font-semibold tw-text-primaryDim-300">
        Export Issue & Action Plan Report
      </h3>
      <ul className="tw-text-sm tw-font-semibold tw-text-neutral-500">
        <li
          className="tw-cursor-pointer tw-px-4 tw-py-2.5 hover:tw-bg-primary-25"
          onClick={() => downloadExcelReport("xlsx")}
        >
          XLSX
        </li>
        <li
          className="tw-cursor-pointer tw-px-4 tw-py-2.5 hover:tw-bg-primary-25"
          onClick={() => downloadExcelReport("csv")}
        >
          CSV
        </li>
      </ul>
    </div>
  );
}

export default IMExportReport;
