import { faker } from "@faker-js/faker";

import type { LayoutField } from "../models/LayoutField";

export function createLayoutField(
  data: NonNullable<Partial<LayoutField>> = {},
): NonNullable<LayoutField> {
  return {
    ...{
      id: faker.number.int(),
      layout_id: faker.number.int(),
      field_id: faker.number.int(),
      previous_layout_field_id: faker.number.int(),
      visible: faker.datatype.boolean(),
      width: faker.number.int(),
    },
    ...data,
  };
}
