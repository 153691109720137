import { http } from "msw";

import { createUpdateRiskRegisterGroupsMutationResponse } from "../createUpdateRiskRegisterGroups";

export const updateRiskRegisterGroupsHandler = http.put(
  "*/workspaces/:workspace_id/risk_registers/risk_register_groups/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateRiskRegisterGroupsMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
