import { faker } from "@faker-js/faker";

import type { UncompletedCommentsCount } from "../models/UncompletedCommentsCount";

export function createUncompletedCommentsCount(
  data: NonNullable<Partial<UncompletedCommentsCount>> = {},
): NonNullable<UncompletedCommentsCount> {
  return {
    ...{ private: faker.number.int(), public: faker.number.int() },
    ...data,
  };
}
