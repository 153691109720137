import type { Relatable } from "./Relatable";

export const taskableStatus = {
  active: "active",
  deleted: "deleted",
  no_access: "no_access",
} as const;
export type TaskableStatus =
  (typeof taskableStatus)[keyof typeof taskableStatus];
export type Taskable = {
  /**
   * @description Taskable ID
   * @type integer
   */
  id: number;
  target?: Relatable;
  /**
   * @description Whether the user has access to the target
   * @type string
   */
  status: TaskableStatus;
};
