import { http } from "msw";

import { createDeleteCommentMutationResponse } from "../createDeleteComment";

export const deleteCommentHandler = http.delete(
  "*/:record_type/:record_id/comments/:id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteCommentMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
