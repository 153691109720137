import React, { useMemo } from "react";
import { cn } from "../../../lib/utils";
import { ColorCircle } from "../../general";
import { Select } from "../select/Select";
/**
 * @todo replace with color palette from design system
 */
export var colorSelectColors = [
    "#EB2E4E",
    "#FF879B",
    "#FF9900",
    "#FDB932",
    "#66AA22",
    "#6D8CF9",
    "#6161C3",
];
export function ColorSelect(_a) {
    var className = _a.className, disabled = _a.disabled, size = _a.size, value = _a.value, onChange = _a.onChange;
    var items = useMemo(function () {
        return colorSelectColors.map(function (color) { return ({
            label: color,
            value: color,
        }); });
    }, []);
    return (React.createElement(Select, { popoverContentClassName: "tw-min-w-0", className: cn("tw-rounded-2xl", className), disabled: disabled, items: items, size: size, renderOption: function (item) { return (React.createElement("div", { className: "tw-flex tw-items-center tw-gap-2" },
            React.createElement(ColorCircle, { color: item.value }))); }, renderSelected: function (item) {
            return (item === null || item === void 0 ? void 0 : item.value) ? React.createElement(ColorCircle, { color: item.value }) : null;
        }, selected: value, onSelect: onChange }));
}
