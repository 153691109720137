import { faker } from "@faker-js/faker";

import type { RiskRegisterGroup } from "../models/RiskRegisterGroup";
import { createLevel } from "./createLevel";
import { createRiskRegisterGroupItem } from "./createRiskRegisterGroupItem";

export function createRiskRegisterGroup(
  data: NonNullable<Partial<RiskRegisterGroup>> = {},
): NonNullable<RiskRegisterGroup> {
  return {
    ...{
      id: faker.number.int(),
      name: faker.string.alpha(),
      overall_score: faker.number.float(),
      inherent_score: faker.number.float(),
      residual_risk_score: faker.number.float(),
      inherent_level: createLevel(),
      residual_level: createLevel(),
      risk_register_group_items: createRiskRegisterGroupItem(),
    },
    ...data,
  };
}
