import { Button, HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import type { RecordVersion } from "@/api";
import { getSelectedTab } from "@/components/helpers/Tabs";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import ExportWorkspacePopup from "@/components/shared/export-workspace-popup/ExportWorkspacePopup";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useTableData } from "@/hooks/useTableData";

import { useLoading } from "../../../hooks/useLoading";
import ImportBulk from "../../dashboard/ImportBulk";
import Loading from "../../Loading";
import CreateCTACW from "../shared/CreateCTACW";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import ShareLink from "../shared/ShareLink";
import Table from "../Table";
import ComplaintsSection from "./ComplaintsSection";

const TABS = [
  {
    name: "Active",
    key: "active",
    value: "/modules/complaints",
    dataTestId: "marketing-tab-Active-trigger",
  },
  {
    name: "Completed",
    key: "completed",
    value: "/modules/complaints/completed",
    dataTestId: "marketing-tab-Completed-trigger",
  },
];

function DefaultComplaints() {
  const mainStore = useMainStore();

  const [showAddNewSection, setShowAddNewSection] = useState(false);

  const { visibleFields } = mainStore.fields;
  const { data } = mainStore.complaints;
  const recordVersions = mainStore.recordVersions.list;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { tableName, isSupport, isIW, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;

  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = data?.module_workspace_id;
  const history = useHistory();
  const loading = useLoading(visibleFields);

  useTableData();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    mainStore.complaints.setDefaultPages(sectionTags);
  }, [sectionTags]);

  const addNewSection = () => {
    setShowAddNewSection((prevState) => !prevState);
  };

  const onSectionChange = (
    recordVersionId: RecordVersion,
    sectionId: number,
  ) => {
    mainStore.complaints.updateSection(recordVersionId, sectionId);
  };

  function renderCTA() {
    return workspaceID && isIW ? (
      <CreateFirstCTA subject="Complaint" />
    ) : (
      <CreateCTACW subject="Complaint" />
    );
  }

  async function createNewComplaint() {
    const recordVersion = await mainStore.complaints.create(
      tableName,
      undefined,
    );
    if (
      location.pathname === `/workspaces/${workspaceID}/modules/complaints` &&
      recordVersion
    ) {
      history.push(
        `/workspaces/${workspaceID}/modules/complaints/${recordVersion.id}`,
      );
    }
  }

  const onTabClick = (tab: string) => {
    mainStore.recordVersions.resetList();
    mainStore.complaints.cleanup();
    mainStore.complaints.index({
      workspaceID,
      tab,
      tableName: tableName || undefined,
    });
    history.push(`/workspaces/${workspaceID}${tab}`);
  };

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader />;
  }

  return (
    <Table extraClassNames="obi-table">
      <>
        <div className={classNames("table-header-wrap")}>
          <HeaderTabs
            tabs={TABS}
            selectedTab={getSelectedTab(TABS)?.value || ""}
            onSelectTab={onTabClick}
            dataTestId="marketing-main-tabs"
          />
          <div className="import-export-buttons-container">
            <ExportWorkspacePopup
              trigger={
                <Button
                  LeftIcon={PiDownloadSimpleBold}
                  size="md"
                  color="tertiary"
                >
                  Export
                </Button>
              }
              moduleIdentifier="complaints"
              successMessage="Complaints are preparing to be exported"
            />
            <ShareLink moduleIdentifier="complaints" />
            <ImportBulk topLevelSection />
            {filtersTrigger}
          </div>
        </div>
        {filtersViewEnabled && (
          <div className="filters-wrap">
            <div className="switch-table-wrap" />
            {filtersContent}
          </div>
        )}
        {getSelectedTab(TABS)?.name === "Active" && (
          <AddRecordHeader
            onAddRecord={createNewComplaint}
            recordName="Complaint"
            canAddSection
            onAddSection={addNewSection}
          />
        )}
        <div
          className="table-list-wrap complaints-list-wrap"
          data-testid="complaints-table"
        >
          <SectionDragContext onSectionChange={onSectionChange}>
            <ComplaintsSection
              isDropdownOpenProp
              moduleWorkspaceID={moduleWorkspaceID}
              currentScreen={getSelectedTab(TABS)?.name}
              recordVersions={recordVersions.filter(
                (recordVersion) => !recordVersion.section_tag_id,
              )}
              pageID={0}
            />

            <div className="complaints-section">
              {mainStore.sectionTags.orderedList.map((sectionTag) => {
                const filteredRecordVersions = recordVersions.filter(
                  (recordVersion) =>
                    recordVersion.section_tag_id === sectionTag.id,
                );

                return (
                  <ComplaintsSection
                    key={sectionTag.id}
                    recordVersions={filteredRecordVersions}
                    sectionTag={sectionTag}
                    isDropdownOpenProp
                    moduleWorkspaceID={moduleWorkspaceID}
                    currentScreen={getSelectedTab(TABS)?.name}
                    pageID={sectionTag.id}
                  />
                );
              })}
            </div>

            <div className="complaints-add-margin" />

            {showAddNewSection && (
              <NewSectionTag moduleWorkspaceID={moduleWorkspaceID} />
            )}
            {canManageSections && (
              <div className="tw-sticky tw-left-[25px] tw-ml-[25px] tw-mt-8">
                <AddNewSectionButton onClick={addNewSection} />
              </div>
            )}

            {!isSupport && recordVersions.length < 3 && renderCTA()}
          </SectionDragContext>
        </div>
      </>
    </Table>
  );
}

export default observer(DefaultComplaints);
