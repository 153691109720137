import { http } from "msw";

import { createDeleteTagMutationResponse } from "../createDeleteTag";

export const deleteTagHandler = http.delete(
  "*/tags/:tag_id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteTagMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
