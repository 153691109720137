import { Avatar, Stack, UserLabel } from "@themis/ui";
import { keyBy } from "lodash";

import { useCompany } from "@/api/queries/companies";
import { useCompanyUsers } from "@/api/queries/users/use-company-users";

import type { DynamicCellProps } from "../../dynamic-cell";
import type { GetReferenceType } from "../../field-configuration";

type ReferenceType = GetReferenceType<"User">;

export const UserCell = ({ value }: DynamicCellProps<ReferenceType>) => {
  const { data: company } = useCompany("current");

  const { data: usersMap } = useCompanyUsers(
    { companyId: company?.data.company.id },
    {
      select: (data) => keyBy(data, "id"),
      enabled: !!value,
    },
  );

  if (!value) {
    return null;
  }

  const isMultiple = Array.isArray(value);

  const users = (isMultiple ? value : [value])
    .map((userId) => (userId ? usersMap?.[userId] : undefined))
    .filter((user) => user !== undefined);

  return (
    <Stack direction="row" wrap>
      {users.map((user) => {
        if (!user?.full_name) {
          return null;
        }

        if (isMultiple) {
          return (
            <Avatar key={user.id} colorIndex={user.icon_color_index}>
              {user.initials}
            </Avatar>
          );
        }

        return (
          <UserLabel
            key={user?.id}
            fullName={user?.full_name}
            initials={user?.initials}
            colorIndex={user?.icon_color_index}
          />
        );
      })}
    </Stack>
  );
};
