import { http } from "msw";

import { createDeleteRecordLinkMutationResponse } from "../createDeleteRecordLink";

export const deleteRecordLinkHandler = http.delete(
  "*/record_links/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteRecordLinkMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
