import { faker } from "@faker-js/faker";

import type { Finding } from "../models/Finding";
import { createLinkedRecord } from "./createLinkedRecord";
import { createThemisDocument } from "./createThemisDocument";
import { createUser } from "./createUser";

export function createFinding(
  data: NonNullable<Partial<Finding>> = {},
): NonNullable<Finding> {
  return {
    ...{
      custom_fields: {},
      id: faker.number.int(),
      name: faker.string.alpha(),
      status: faker.helpers.arrayElement<any>(["open", "sent", "closed"]),
      description: faker.string.alpha(),
      source: faker.helpers.arrayElement<any>([
        "compliance",
        "audit",
        "regulatory",
      ]),
      risk_level: faker.helpers.arrayElement<any>(["low", "medium", "high"]),
      due_date: faker.string.alpha(),
      date_identified: faker.string.alpha(),
      action_plan_title: faker.string.alpha(),
      supporting_evidence: createThemisDocument(),
      owner: Object.assign({}, createUser()),
      linked_records: faker.helpers.arrayElements([
        createLinkedRecord(),
      ]) as any,
      record_id: faker.number.int(),
      record_name: faker.string.alpha(),
    },
    ...data,
  };
}
