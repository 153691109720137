import { faker } from "@faker-js/faker";

import type {
  ShowContract200,
  ShowContractPathParams,
  ShowContractQueryResponse,
} from "../models/ShowContract";
import { createContract } from "./createContract";

export function createShowContractPathParams(): NonNullable<ShowContractPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createShowContract200(): NonNullable<ShowContract200> {
  return { contract: createContract() };
}

/**
 * @description OK
 */
export function createShowContractQueryResponse(): NonNullable<ShowContractQueryResponse> {
  return { contract: createContract() };
}
