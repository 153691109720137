import classNames from "classnames";
import React from "react";

import MiniTag from "@/components/Elements/MiniTag/mini-tag";
import Typography from "@/components/Elements/Typography/typography";
import type { THEMIS_TOGGLE_SIZES } from "@/config/theme";

export interface ToggleProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  labelType?: "text" | "tag";
  offLabel?: string;
  onLabel?: string;
  size?: (typeof THEMIS_TOGGLE_SIZES)[number];
  onChange: (_: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({
  active = false,
  className = "",
  disabled = false,
  labelType = "text",
  offLabel = "",
  onLabel = "",
  size = "de",
  onChange,
  ...rest
}) => {
  const handleChange = () => {
    if (!disabled) {
      onChange(!active);
    }
  };

  const renderLabel = (label: string, isLabelActive: boolean) => {
    if (labelType === "text") {
      return (
        <Typography
          label={label}
          size="sm"
          color={isLabelActive ? "generalMidnightDark" : "generalMidGray"}
        />
      );
    }

    return <MiniTag label={label} theme={isLabelActive ? "purple" : "gray"} />;
  };

  return (
    <div
      className={classNames(
        "themisToggle",
        `themisToggle__${size}`,
        disabled && "themisToggle--disabled",
        !disabled && active && "themisToggle--active",
        className,
      )}
    >
      {offLabel && renderLabel(offLabel, !active)}
      <label className="themisToggle__label">
        <input
          data-testid="toggle-input"
          type="checkbox"
          value={String(active)}
          checked={active}
          disabled={disabled}
          onChange={handleChange}
          {...rest}
        />
        <div className="themisToggle__track">
          <div className="themisToggle__thumb" />
        </div>
      </label>
      {onLabel && renderLabel(onLabel, active)}
    </div>
  );
};

Toggle.displayName = "Toggle";

export default Toggle;
