import { faker } from "@faker-js/faker";

import type {
  UpdateContract200,
  UpdateContractMutationRequest,
  UpdateContractMutationResponse,
  UpdateContractPathParams,
} from "../models/UpdateContract";
import { createContract } from "./createContract";
import { createModifyContractRequest } from "./createModifyContractRequest";

export function createUpdateContractPathParams(): NonNullable<UpdateContractPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateContract200(): NonNullable<UpdateContract200> {
  return { contract: createContract() };
}

export function createUpdateContractMutationRequest(): NonNullable<UpdateContractMutationRequest> {
  return { contract: createModifyContractRequest() };
}

/**
 * @description OK
 */
export function createUpdateContractMutationResponse(): NonNullable<UpdateContractMutationResponse> {
  return { contract: createContract() };
}
