import { http } from "msw";

import { createListShowRecordsQueryResponse } from "../createListShowRecords";

export const listShowRecordsHandler = http.get(
  "*/records",
  function handler(info) {
    return new Response(JSON.stringify(createListShowRecordsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
