import { faker } from "@faker-js/faker";

import type {
  ShowModuleAssessment200,
  ShowModuleAssessmentPathParams,
  ShowModuleAssessmentQueryResponse,
} from "../models/ShowModuleAssessment";
import { createModuleAssessment } from "./createModuleAssessment";

export function createShowModuleAssessmentPathParams(): NonNullable<ShowModuleAssessmentPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createShowModuleAssessment200(): NonNullable<ShowModuleAssessment200> {
  return { data: createModuleAssessment() };
}

/**
 * @description OK
 */
export function createShowModuleAssessmentQueryResponse(): NonNullable<ShowModuleAssessmentQueryResponse> {
  return { data: createModuleAssessment() };
}
