import { faker } from "@faker-js/faker";

import type {
  ListOfCompanyUsers200,
  ListOfCompanyUsers403,
  ListOfCompanyUsersPathParams,
  ListOfCompanyUsersQueryParams,
  ListOfCompanyUsersQueryResponse,
} from "../models/ListOfCompanyUsers";
import { createCompanyUser } from "./createCompanyUser";

export function createListOfCompanyUsersPathParams(): NonNullable<ListOfCompanyUsersPathParams> {
  return { company_id: faker.number.int() };
}

export function createListOfCompanyUsersQueryParams(): NonNullable<ListOfCompanyUsersQueryParams> {
  return {
    workspace_id: faker.number.int(),
    view: faker.helpers.arrayElement<any>(["all", "active"]),
    sort_by: { email: faker.string.alpha(), full_name: faker.string.alpha() },
  };
}

/**
 * @description OK
 */
export function createListOfCompanyUsers200(): NonNullable<ListOfCompanyUsers200> {
  return { data: faker.helpers.arrayElements([createCompanyUser()]) as any };
}

/**
 * @description Forbidden
 */
export function createListOfCompanyUsers403(): NonNullable<ListOfCompanyUsers403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createListOfCompanyUsersQueryResponse(): NonNullable<ListOfCompanyUsersQueryResponse> {
  return { data: faker.helpers.arrayElements([createCompanyUser()]) as any };
}
