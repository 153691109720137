import { faker } from "@faker-js/faker";

import type {
  GetExportedFile200,
  GetExportedFilePathParams,
  GetExportedFileQueryResponse,
} from "../models/GetExportedFile";
import { createExportedFile } from "./createExportedFile";

export function createGetExportedFilePathParams(): NonNullable<GetExportedFilePathParams> {
  return { exported_file_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetExportedFile200(): NonNullable<GetExportedFile200> {
  return createExportedFile();
}

/**
 * @description OK
 */
export function createGetExportedFileQueryResponse(): NonNullable<GetExportedFileQueryResponse> {
  return createExportedFile();
}
