import { faker } from "@faker-js/faker";

import type { ExportedFile } from "../models/ExportedFile";

export function createExportedFile(
  data: NonNullable<Partial<ExportedFile>> = {},
): NonNullable<ExportedFile> {
  return {
    ...{
      id: faker.number.int(),
      user_id: faker.number.int(),
      url: faker.string.alpha(),
    },
    ...data,
  };
}
