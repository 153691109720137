import { faker } from "@faker-js/faker";

import type {
  GetCompanyAttestationLists200,
  GetCompanyAttestationListsPathParams,
  GetCompanyAttestationListsQueryParams,
  GetCompanyAttestationListsQueryResponse,
} from "../models/GetCompanyAttestationLists";
import { createAttestationList } from "./createAttestationList";

export function createGetCompanyAttestationListsPathParams(): NonNullable<GetCompanyAttestationListsPathParams> {
  return { company_id: faker.number.int() };
}

export function createGetCompanyAttestationListsQueryParams(): NonNullable<GetCompanyAttestationListsQueryParams> {
  return {
    workspace_id: faker.number.int(),
    record_type: faker.helpers.arrayElement<any>(["trainings"]),
  };
}

/**
 * @description OK
 */
export function createGetCompanyAttestationLists200(): NonNullable<GetCompanyAttestationLists200> {
  return {
    data: faker.helpers.arrayElements([createAttestationList()]) as any,
  };
}

/**
 * @description OK
 */
export function createGetCompanyAttestationListsQueryResponse(): NonNullable<GetCompanyAttestationListsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createAttestationList()]) as any,
  };
}
