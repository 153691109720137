import { IconButton } from "@themis/ui";
import { observer } from "mobx-react";
import { useRef } from "react";
import { PiArchiveLight, PiCheckBold } from "react-icons/pi";
import { useIntersectionObserver } from "usehooks-ts";

import {
  useDismissAllNotifications,
  useNotifications,
  useReadAllNotifications,
} from "@/api/queries/notifications";
import mainStore from "@/stores/Main";

import moduleIcons from "../../../images/modules.webp";
import { NotificationItem } from "./NotificationItem";

function NotificationsList() {
  const endOfListRef = useRef<HTMLDivElement>(null);

  const { data, hasNextPage, isFetchingNextPage, isFetching, fetchNextPage } =
    useNotifications();
  const { mutateAsync: dismissAll } = useDismissAllNotifications();
  const { mutateAsync: readAll } = useReadAllNotifications();
  const notifications = data?.pages.flatMap((p) => p.notifications) || [];

  const { ref } = useIntersectionObserver({
    root: endOfListRef.current,
    onChange: (isIntersecting) => {
      if (hasNextPage && !isFetchingNextPage && isIntersecting) {
        fetchNextPage();
      }
    },
    threshold: 0.5,
  });

  function handleReadAll() {
    readAll(new Date().toString());
  }

  function handleDismissAll() {
    dismissAll(new Date().toString());
  }

  return (
    <div className="tw-overflow-y-auto">
      <div className="tw.bg-white tw.w-full tw-sticky tw-top-0 tw-z-20 tw-border-0 tw-border-b tw-border-solid tw-border-gray-600">
        <div className="tw-flex tw-justify-between tw-bg-[#353549] tw-p-3">
          <h4 className="tw-pl-[10px] tw-font-semibold tw-text-white">
            {mainStore.notifications.unreadNotificationsCount} Unread
          </h4>
          {notifications.length > 0 && (
            <div className="tw-flex tw-items-center">
              <IconButton
                Icon={PiCheckBold}
                color="secondary"
                size="sm"
                onClick={handleReadAll}
                data-tooltip-id="tooltip"
                data-tooltip-content="Mark all as read"
                data-tooltip-place="bottom"
              />
              <IconButton
                Icon={PiArchiveLight}
                color="secondary"
                size="sm"
                className="tw-ml-1"
                onClick={handleDismissAll}
                data-tooltip-id="tooltip"
                data-tooltip-content="Dismiss All"
                data-tooltip-place="bottom"
              />
            </div>
          )}
        </div>
      </div>
      {!isFetching && notifications.length === 0 && (
        <div className="tw-flex tw-flex-col tw-items-center tw-text-white">
          <img src={moduleIcons} />
          <h2>Hooray!</h2>
          <h4>You have no notifications.</h4>
          <h4 className="tw-mt-5">Take a break!</h4>
        </div>
      )}
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          id={notification.id}
          title={notification.title}
          actorColorIndex={notification.actor_color_index || 0}
          actorInitials={notification.actor_initials || ""}
          body={notification.body}
          createdAt={notification.created_at}
          unread={!notification.read_at}
          link={notification.link || ""}
          moduleIdentifier={notification.module_identifier || ""}
          themisId={notification.themis_id!}
          moduleDisplayName={notification.module_display_name || ""}
        />
      ))}
      <div ref={ref} />
    </div>
  );
}

export default observer(NotificationsList);
