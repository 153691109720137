var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import "./custom-theme.css";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { AgGridReact } from "@ag-grid-community/react";
import React, { useEffect, useMemo, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { PiArrowDownBold, PiArrowUpBold, PiDotsSixVerticalBold, } from "react-icons/pi";
import AddColumnButton from "./AddColumnButton";
import AttachmentCell from "./Cells/AttachmentCell";
import ButtonCell from "./Cells/ButtonCell";
import CheckboxCell from "./Cells/CheckboxCell";
import CustomTooltip from "./Cells/CustomTooltip";
import DatePickerCell from "./Cells/DatePickerCell";
import IconButtonCell from "./Cells/IconButtonCell";
import IdentifierCellEditor from "./Cells/IdentifierCellEditor";
import IdentifierCellRenderer from "./Cells/IdentifierCellRenderer";
import ImagePickerCell from "./Cells/ImagePickerCell";
import LinkEditorCell from "./Cells/LinkEditorCell";
import LinkRenderCell from "./Cells/LinkRenderCell";
import SelectCell from "./Cells/SelectCell";
import TextEditorCell from "./Cells/TextEditorCell";
import TextRenderCell from "./Cells/TextRenderCell";
import UsersCell from "./Cells/UsersCell";
import Header from "./Header/Header";
import { suppressKeyboardEvent } from "./utils/supress-keyboard-event";
export var CHECKBOX_COLUMN = {
    minWidth: 32,
    maxWidth: 32,
    pinned: "left",
    resizable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerClass: "!tw-border-none",
    cellClass: "!tw-border-none !tw-pl-2.5",
};
export var TableComponent = {
    addColumnButton: AddColumnButton,
    textEditorCell: TextEditorCell,
    textRenderCell: TextRenderCell,
    buttonCell: ButtonCell,
    checkboxCell: CheckboxCell,
    selectCell: SelectCell,
    identifierCellEditor: IdentifierCellEditor,
    identifierCellRenderer: IdentifierCellRenderer,
    imagePickerCell: ImagePickerCell,
    datePickerCell: DatePickerCell,
    iconButtonCell: IconButtonCell,
    attachmentCell: AttachmentCell,
    linkRenderCell: LinkRenderCell,
    linkEditorCell: LinkEditorCell,
    customTooltip: CustomTooltip,
    usersCell: UsersCell,
    agColumnHeader: Header,
};
export function Table(_a) {
    var id = _a.id, columns = _a.columns, _b = _a.enablePinning, enablePinning = _b === void 0 ? true : _b, rows = _a.rows, _c = _a.width, width = _c === void 0 ? "100%" : _c, _d = _a.rowHeight, rowHeight = _d === void 0 ? 40 : _d, _e = _a.resizableColumns, resizableColumns = _e === void 0 ? true : _e, rowSelection = _a.rowSelection, _f = _a.singleClickEdit, singleClickEdit = _f === void 0 ? true : _f, columnDef = _a.columnDef, tableProps = _a.tableProps, domLayout = _a.domLayout, _g = _a.onChange, onChange = _g === void 0 ? function () { } : _g, rest = __rest(_a, ["id", "columns", "enablePinning", "rows", "width", "rowHeight", "resizableColumns", "rowSelection", "singleClickEdit", "columnDef", "tableProps", "domLayout", "onChange"]);
    var gridRef = useRef(null);
    useEffect(function () {
        var _a, _b;
        (_b = (_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.setGridOption("columnDefs", columns);
    }, [columns]);
    var defaultColDef = useMemo(function () { return (__assign({ suppressKeyboardEvent: suppressKeyboardEvent, resizable: resizableColumns, cellClassRules: {
            "tw-group/cell": function () { return true; },
            "drag-column": function (params) { var _a; return params && !!((_a = params.colDef) === null || _a === void 0 ? void 0 : _a.rowDrag); },
        }, headerClass: function (params) {
            var colDef = params.colDef;
            return colDef && colDef.rowDrag ? "drag-column" : "header-cell";
        }, tooltipComponent: "customTooltip", lockPinned: !enablePinning }, columnDef)); }, [resizableColumns, enablePinning, columnDef]);
    var autoSizeStrategy = useMemo(function () { return ({
        type: "fitGridWidth",
    }); }, []);
    function handleCellValueChanged(changeEvent) {
        var oldValue = changeEvent.oldValue, newValue = changeEvent.newValue;
        if (Array.isArray(newValue) && Array.isArray(oldValue)) {
            if (newValue.join() === oldValue.join()) {
                return;
            }
        }
        if (newValue === oldValue) {
            return;
        }
        onChange(changeEvent);
    }
    var rowClassRules = {
        "tw-group/row": function () { return true; }, // Apply 'group' class to all rows
    };
    // popupParent is used to set the parent element for the popup/tooltip.
    // This is required to prevent the popup/tooltip from being clipped by
    // the grid container.
    // https://www.ag-grid.com/react-data-grid/context-menu/#popup-parent
    var popupParent = useMemo(function () { return document.querySelector("body"); }, []);
    var gridOptions = useMemo(function () { return ({
        icons: {
            rowDrag: function () { return renderToStaticMarkup(React.createElement(PiDotsSixVerticalBold, null)); },
            sortAscending: function () { return renderToStaticMarkup(React.createElement(PiArrowUpBold, null)); },
            sortDescending: function () { return renderToStaticMarkup(React.createElement(PiArrowDownBold, null)); },
        },
        onGridReady: function (params) {
            params.api.sizeColumnsToFit();
        },
        rowSelection: rowSelection,
        singleClickEdit: singleClickEdit,
        tooltipShowDelay: 500,
    }); }, [rowSelection, singleClickEdit]);
    return (React.createElement("div", __assign({ id: id, className: "custom-theme tw-relative tw-min-w-[800px]", style: { width: width } }, rest),
        React.createElement(AgGridReact, __assign({ ref: gridRef, getRowId: function (row) { return String(row.data.id); }, reactiveCustomComponents: true, autoSizeStrategy: autoSizeStrategy, components: TableComponent, columnDefs: columns, rowData: rows, domLayout: domLayout !== null && domLayout !== void 0 ? domLayout : "autoHeight", rowHeight: rowHeight || 40, rowDragManaged: true, defaultColDef: defaultColDef, rowClassRules: rowClassRules, suppressNoRowsOverlay: true, onCellValueChanged: handleCellValueChanged, context: { gridRef: gridRef }, popupParent: popupParent, suppressMultiSort: true, accentedSort: true, gridOptions: gridOptions, modules: [ClientSideRowModelModule, InfiniteRowModelModule] }, tableProps))));
}
