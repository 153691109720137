import { http } from "msw";

import { createRecordVersionsUpdateMutationResponse } from "../createRecordVersionsUpdate";

export const recordVersionsUpdateHandler = http.put(
  "*/record_versions/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createRecordVersionsUpdateMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
