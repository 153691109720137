import { attachmentFileType } from "@/api";
import type { IntegrationAttachmentTypes } from "@/stores/types/attachment-types";

export function downloadFile(blob: Blob | MediaSource, filename: string) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export function getIntegrationName(
  integrationFileType: IntegrationAttachmentTypes,
) {
  switch (integrationFileType) {
    case attachmentFileType.figma:
      return "Figma";
    case attachmentFileType.google_drive:
      return "Google";
    case attachmentFileType.share_point:
      return "Microsoft";
    default:
  }
}
