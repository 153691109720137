import { useQuery } from "@tanstack/react-query";

import type { RecordType } from "@/api";
import { listRecordLinks } from "@/api/gen/axios/recordLinksController";

export function useRecordLinks(recordType: RecordType, recordId: number) {
  return useQuery({
    queryKey: [recordType, recordId],
    queryFn: () => listRecordLinks(recordType, recordId),
  });
}
