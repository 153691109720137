import { http } from "msw";

import { createShowModuleAssessmentQueryResponse } from "../createShowModuleAssessment";

export const showModuleAssessmentHandler = http.get(
  "*/module_assessments/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createShowModuleAssessmentQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
