import { faker } from "@faker-js/faker";

import type { Region } from "../models/Region";

export function createRegion(
  data: NonNullable<Partial<Region>> = {},
): NonNullable<Region> {
  return {
    ...{
      name: faker.string.alpha(),
      code: faker.string.alpha(),
      category: faker.string.alpha(),
    },
    ...data,
  };
}
