import { Mention, MentionsInput } from "react-mentions";

interface DisabledUserMentionProps {
  value: string;
}

export function DisabledUserMention({ value }: DisabledUserMentionProps) {
  return (
    <MentionsInput
      disabled
      value={value}
      className="tw-w-full tw-bg-transparent tw-p-0"
      style={{
        input: {
          border: "solid transparent 1px",
          fontSize: "0.75rem",
          lineHeight: "1rem",
          fontWeight: "600",
          padding: 4,
        },
        control: {
          fontSize: "0.75rem",
          lineHeight: "1rem",
          fontWeight: "600",
        },
        highlighter: {
          zIndex: 1,
          pointerEvents: "none",
          padding: 4,
        },
      }}
    >
      <Mention trigger="@" data={[]} className="tw-text-lightBlue-3" />
    </MentionsInput>
  );
}
