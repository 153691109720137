import { observer } from "mobx-react";
import React from "react";

import Loading from "@/assets/svgs/loading";
import { useMainStore } from "@/contexts/Store";

import QAReportsTableRow from "./QAReportsTableRow";

const QAReportsTable = () => {
  const mainStore = useMainStore();

  if (mainStore.recordVersions.list?.length === 0) {
    return <Loading loadingLayout="sections" />;
  }

  return (
    <table id="qa-reports-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Type</th>
          <th>Test Name</th>
          <th>Frequency</th>
          <th>Start Date</th>
          <th>Target Completion Date</th>
        </tr>
      </thead>
      <tbody>
        {mainStore.reports.qa.recordVersions?.map((recordVersion, index) => (
          <QAReportsTableRow key={index} recordVersion={recordVersion} />
        ))}
      </tbody>
    </table>
  );
};

export default observer(QAReportsTable);
