import { faker } from "@faker-js/faker";

import type { MonitoringGroupAsset } from "../models/MonitoringGroupAsset";
import { createMonitoringGroup } from "./createMonitoringGroup";
import { createMonitoringGroupAssetChild } from "./createMonitoringGroupAssetChild";

export function createMonitoringGroupAsset(
  data: NonNullable<Partial<MonitoringGroupAsset>> = {},
): NonNullable<MonitoringGroupAsset> {
  return {
    ...{
      id: faker.number.int(),
      asset_name: faker.string.alpha(),
      data: faker.string.alpha(),
      asset_status: faker.string.alpha(),
      asset_type: faker.string.alpha(),
      assignee_id: faker.number.int(),
      metadata: {},
      monitoring_group_id: faker.number.int(),
      monitoring_group_asset_uid: faker.string.alpha(),
      asset_children_count: faker.number.int(),
      asset_children_results_count: faker.number.int(),
      asset_children_unresolved_results_count: faker.number.int(),
      group_name: faker.string.alpha(),
      monitoring_group_asset_created_at: faker.date.anytime().toISOString(),
      monitoring_group_asset_updated_at: faker.date.anytime().toISOString(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
      children: faker.helpers.arrayElements([
        createMonitoringGroupAssetChild(),
      ]) as any,
      group: createMonitoringGroup(),
    },
    ...data,
  };
}
