import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { generatePath, Link } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { RISK_RATING_COLORS } from "@/features/risk-assessment";
import RowOptions from "@/features/risk-assessment/components/RowOptions/RowOptions";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import ListCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ListCell/ListCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";

import { RiskMethodologyAPI } from "../../../../api/legacy/risk-assessment/RiskMethodologiesApi";
import { routes } from "../../routes";
import type { RiskMethodology } from "../../types/risk-methodology";
import MiniTag from "../MiniTag/MiniTag";

interface Props {
  index: number;
  riskMethodology: RiskMethodology;
}

function RiskMethodologyRow(props: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  useEffect(() => {
    if (!workspaceID) {
      return;
    }
    mainStore.users.indexForModuleIfNoUsersPresent(workspaceID);
  }, [props.riskMethodology.last_edited_by_id, workspaceID]);

  const deleteRiskMethodology = async () => {
    try {
      await RiskMethodologyAPI.delete(workspaceID!, props.riskMethodology.id!);
      mainStore.riskMethodologies.delete(props.riskMethodology.id!);
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        e.response?.data?.errors?.questionnaires?.length > 1
      ) {
        mainStore.toast.setErrorText(
          `The risk methodology is in use: ${e.response?.data.errors.questionnaires.join(
            ", ",
          )}`,
        );
      } else {
        mainStore.toast.setErrorText("Something went wrong");
      }
    }
  };

  return (
    <TableRow key={props.riskMethodology.id} rowIndex={props.index}>
      <ContentCell
        content={
          <Link
            to={generatePath(routes.METHODOLOGY_EDIT_PATH, {
              riskMethodologyId: props.riskMethodology.id,
              workspace_id: Number(workspaceID),
            })}
          >
            {props.riskMethodology.name}
          </Link>
        }
        isFirstDataCell
      />
      <ListCell>
        {props.riskMethodology.inherent_risk_ratings.map((rating) => (
          <MiniTag
            key={rating.id}
            label={rating.text}
            color={
              rating.color ||
              RISK_RATING_COLORS[
                Math.floor(Math.random() * RISK_RATING_COLORS.length)
              ]
            }
          />
        ))}
      </ListCell>
      <ListCell>
        {props.riskMethodology.control_risk_ratings.map((rating) => (
          <MiniTag
            key={rating.id}
            label={rating.text}
            color={
              rating.color ||
              RISK_RATING_COLORS[
                Math.floor(Math.random() * RISK_RATING_COLORS.length)
              ]
            }
          />
        ))}
      </ListCell>
      <ListCell>
        {props.riskMethodology.residual_risks.map((rating) => (
          <MiniTag
            key={rating.id}
            label={rating.text}
            color={
              rating.color ||
              RISK_RATING_COLORS[
                Math.floor(Math.random() * RISK_RATING_COLORS.length)
              ]
            }
          />
        ))}
      </ListCell>
      <ActionCell>
        <RowOptions
          options={[
            { label: "Delete", onClick: () => deleteRiskMethodology() },
          ]}
        />
      </ActionCell>
    </TableRow>
  );
}

export default observer(RiskMethodologyRow);
