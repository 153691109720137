import { MiniTag } from "@themis/ui";
import { useIntl } from "react-intl";

import type { DynamicCellProps } from "../../dynamic-cell";
import type { GetReferenceType } from "../../field-configuration";

type ReferenceType = GetReferenceType<"Review">;

export const ReviewCell = (_props: DynamicCellProps<ReferenceType>) => {
  const { formatMessage } = useIntl();

  return (
    <MiniTag color="grey" variant="default">
      {formatMessage({ defaultMessage: "Coming soon" })}
    </MiniTag>
  );
};
