import { faker } from "@faker-js/faker";

import type {
  ListColorSchemes200,
  ListColorSchemesQueryResponse,
} from "../models/ListColorSchemes";
import { createColorScheme } from "./createColorScheme";

/**
 * @description OK
 */
export function createListColorSchemes200(): NonNullable<ListColorSchemes200> {
  return faker.helpers.arrayElements([createColorScheme()]) as any;
}

/**
 * @description OK
 */
export function createListColorSchemesQueryResponse(): NonNullable<ListColorSchemesQueryResponse> {
  return faker.helpers.arrayElements([createColorScheme()]) as any;
}
