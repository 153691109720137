import { faker } from "@faker-js/faker";

import type { Account } from "../models/Account";
import { createContact } from "./createContact";
import { createContract } from "./createContract";
import { createDocument } from "./createDocument";
import { createFile } from "./createFile";
import { createPartialRecordVersion } from "./createPartialRecordVersion";
import { createQuestionnaire } from "./createQuestionnaire";
import { createThemisRecord } from "./createThemisRecord";

export function createAccount(
  data?: NonNullable<Partial<Account>>,
): NonNullable<Account> {
  return Object.assign({}, createThemisRecord(), {
    name: faker.string.alpha(),
    status: faker.helpers.arrayElement<any>(["active", "inactive"]),
    legal_name: faker.string.alpha(),
    account_types: faker.helpers.arrayElements([faker.string.alpha()]) as any,
    website: faker.string.alpha(),
    duns_number: faker.string.alpha(),
    phone: faker.string.alpha(),
    logo: createFile(),
    description: faker.string.alpha(),
    mailing_street: faker.string.alpha(),
    mailing_city: faker.string.alpha(),
    mailing_state: faker.string.alpha(),
    mailing_postal_code: faker.string.alpha(),
    mailing_country: faker.string.alpha(),
    tag: faker.string.alpha(),
    main_contact_id: faker.number.int(),
    main_contact: Object.assign({}, createContact()),
    account_owner_id: faker.number.int(),
    comments_count: faker.number.int(),
    contracts: faker.helpers.arrayElements([createContract()]) as any,
    contacts: faker.helpers.arrayElements([createContact()]) as any,
    documents: faker.helpers.arrayElements([createDocument()]) as any,
    child_accounts: [],
    questionnaires: faker.helpers.arrayElements([createQuestionnaire()]) as any,
    vendors: faker.helpers.arrayElements([createPartialRecordVersion()]) as any,
    parent_id: faker.number.int(),
    parent: null,
  });
}
