import { faker } from "@faker-js/faker";

import type { TaskLink } from "../models/TaskLink";
import { createTaskReference } from "./createTaskReference";

export function createTaskLink(
  data: NonNullable<Partial<TaskLink>> = {},
): NonNullable<TaskLink> {
  return {
    ...{
      id: faker.number.int(),
      link_type: faker.string.alpha(),
      outward_task: Object.assign({}, createTaskReference()),
      outward_task_id: faker.number.int(),
    },
    ...data,
  };
}
