import { useCompany } from "@/api/queries/companies";
import { useFeatureFlags } from "@/api/queries/featureFlags";
import { useCurrentUser } from "@/api/queries/users";
import { parseFeatureFlagResponse } from "@/stores/helpers/FeatureFlagHelpers";
import type { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

export const FEATURE_FLAGS_QUERY_KEY = "feature_flags";

export function useIsFeatureEnabled(featureFlagId: FEATURE_FLAG_ID) {
  const {
    data: featureFlagsData,
    isPending: isFeatureFlagsPending,
    isError: isFeatureFlagsError,
  } = useFeatureFlags();

  const {
    data: user,
    isPending: isUserPending,
    isError: isUserError,
  } = useCurrentUser();

  const {
    data: companyData,
    isPending: isCompanyPending,
    isError: isCompanyError,
  } = useCompany("current");

  if (
    isFeatureFlagsPending ||
    isFeatureFlagsError ||
    isUserPending ||
    isUserError ||
    isCompanyPending ||
    isCompanyError
  ) {
    return undefined;
  }

  const feature = parseFeatureFlagResponse(featureFlagsData.data)[
    featureFlagId
  ];

  if (typeof feature === "boolean" || feature === undefined) {
    return Boolean(feature);
  }

  const { userIds, companyIds, workspaceIds } = feature;

  const companyId = companyData.data.company.id;
  const workspaceId = user.active_workspace.id;
  const userId = user.id;

  return [
    Array.isArray(workspaceIds) && workspaceIds.includes(workspaceId),
    Array.isArray(companyIds) && companyIds.includes(companyId),
    Array.isArray(userIds) && userIds.includes(userId),
  ].some(Boolean);
}
