import { faker } from "@faker-js/faker";

import type {
  ImportContacts200,
  ImportContacts400,
  ImportContacts403,
  ImportContactsMutationRequest,
  ImportContactsMutationResponse,
  ImportContactsPathParams,
} from "../models/ImportContacts";
import { createImportContactsRequest } from "./createImportContactsRequest";

export function createImportContactsPathParams(): NonNullable<ImportContactsPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description Contact imported
 */
export function createImportContacts200(): NonNullable<ImportContacts200> {
  return { data: faker.string.alpha() };
}

/**
 * @description Bad request due to validation errors (e.g., unsupported file type, invalid user roles)
 */
export function createImportContacts400(): NonNullable<ImportContacts400> {
  return { errors: { base: faker.string.alpha() } };
}

/**
 * @description Forbidden
 */
export function createImportContacts403(): NonNullable<ImportContacts403> {
  return {};
}

export function createImportContactsMutationRequest(): NonNullable<ImportContactsMutationRequest> {
  return createImportContactsRequest();
}

/**
 * @description Contact imported
 */
export function createImportContactsMutationResponse(): NonNullable<ImportContactsMutationResponse> {
  return { data: faker.string.alpha() };
}
