import { http } from "msw";

import { createWebsiteMonitoringGroupResultQueryResponse } from "../createWebsiteMonitoringGroupResult";

export const websiteMonitoringGroupResultHandler = http.get(
  "*/website_monitoring/:monitoring_group_result_id/monitoring_group_result",
  function handler(info) {
    return new Response(
      JSON.stringify(createWebsiteMonitoringGroupResultQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
