import { observer } from "mobx-react";
import React from "react";
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useParams,
} from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import type { ModuleNotificationParams } from "../types/types";
import {
  MODULE_NOTIFICATIONS_BASE_PATH,
  MODULE_NOTIFICATIONS_CREATE_PATH,
  MODULE_NOTIFICATIONS_EDIT_PATH,
  MODULE_NOTIFICATIONS_TAB_PATH,
} from "./constants";
import ModuleNotificationsListPage from "./list/module-notifications-list-page";
import { ModuleNotificationsSinglePage } from "./single/module-notifications-single-page";

export const ModuleNotificationsRoutes = observer(() => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const params = useParams<ModuleNotificationParams>();
  const moduleWorkspace = mainStore.moduleWorkspaces.list.find(
    (mw) => mw.id === mainStore.context.moduleWorkspaceID,
  );
  const subModule = moduleWorkspace?.attached_module_workspaces.find(
    (mw) =>
      mw.themis_module.identifier === params.subModule?.replace(/-/g, "_"),
  );
  const moduleWorkspaceID =
    subModule?.id || mainStore.context.moduleWorkspaceID;

  return (
    <Switch>
      <Route
        exact
        path={[MODULE_NOTIFICATIONS_BASE_PATH, MODULE_NOTIFICATIONS_TAB_PATH]}
      >
        <ModuleNotificationsListPage moduleWorkspaceID={moduleWorkspaceID} />
      </Route>
      <Route
        exact
        path={[
          MODULE_NOTIFICATIONS_EDIT_PATH,
          MODULE_NOTIFICATIONS_CREATE_PATH,
        ]}
      >
        <ModuleNotificationsSinglePage moduleWorkspaceID={moduleWorkspaceID} />
      </Route>
      {workspaceID && (
        <Redirect
          from="*"
          to={generatePath(MODULE_NOTIFICATIONS_BASE_PATH, {
            ...params,
            workspace_id: workspaceID,
          })}
        />
      )}
    </Switch>
  );
});
