import { Avatar } from "@themis/ui";
import type { Dictionary } from "lodash";

import type { CompanyUser } from "@/api";

interface MultiSelectAvatarProps {
  usersMap: Dictionary<CompanyUser>;
  userId: string | undefined;
}

export function MultiSelectAvatar({
  usersMap,
  userId,
}: MultiSelectAvatarProps) {
  const user = userId ? usersMap[userId] : null;

  if (!user) {
    return null;
  }

  return (
    <Avatar size="md" colorIndex={user.icon_color_index}>
      {user.initials}
    </Avatar>
  );
}
