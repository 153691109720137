import { http } from "msw";

import { createUpdateModuleAssessmentMutationResponse } from "../createUpdateModuleAssessment";

export const updateModuleAssessmentHandler = http.put(
  "*/module_assessments/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateModuleAssessmentMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
