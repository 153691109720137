import { useIntl } from "react-intl";

import type { Comment } from "@/api";
import { useCurrentUser } from "@/api/queries/users";
import { selectUser, useUsers } from "@/api/queries/users/use-users";
import { ConfirmPopup } from "@/components/confirm-popup";
import { Flex, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import Spinner from "@/components/table/shared/Spinner";
import { UsersCircle } from "@/components/users-circle/users-circle";
import { useOpen } from "@/hooks/use-open/use-open";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { CommentHeaderMenu } from "../comment/comment-header-menu";

interface ReplyHeaderProps {
  comment: Comment;
  onConfirmDelete(): void;
  onCopyCommentLink: () => void;
  onStartEditing: () => void;
}

export function ReplyHeader({
  comment,
  onConfirmDelete,
  onCopyCommentLink,
  onStartEditing,
}: ReplyHeaderProps) {
  const { formatMessage } = useIntl();
  const workspaceId = useRouteWorkspaceId();

  const { isOpen, onIsOpenChange, open: openConfirmDialog } = useOpen();

  const { data: author } = useUsers(workspaceId, {
    select: selectUser(comment.author_id ?? -1),
  });

  const { data: currentUser } = useCurrentUser();
  const isAuthor = currentUser?.id === comment.author_id;

  const formattedCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(comment.created_at),
    { removeAt: true, monthFormat: "short" },
  );

  return (
    <Flex alignCenter justifySpaceBetween>
      {author ? (
        <Flex alignCenter columnGap={8}>
          <UsersCircle
            initials={author?.initials}
            iconColorIndex={author?.icon_color_index}
            small
          />
          <Flex column rowGap={2}>
            <Typography
              label={author?.full_name}
              weight="semiBold"
              color="generalMidnightDark"
              size="sm"
            />
            <Typography
              label={formattedCreateDate}
              weight="semiBold"
              color="extrasBlueGrayDarker"
              size="xs"
            />
          </Flex>
        </Flex>
      ) : (
        <Spinner />
      )}
      <Flex alignCenter>
        <CommentHeaderMenu
          isAuthor={isAuthor}
          onStartEditing={onStartEditing}
          onCopyCommentLink={onCopyCommentLink}
          openConfirmDialog={openConfirmDialog}
        />
        <ConfirmPopup
          title={formatMessage({ defaultMessage: "Delete comment" })}
          content={formatMessage({
            defaultMessage:
              "Are you sure you wish to delete this comment? Actions are non-reversible",
          })}
          showPopup={isOpen}
          setShowPopup={onIsOpenChange}
          onConfirmClick={onConfirmDelete}
        />
      </Flex>
    </Flex>
  );
}
