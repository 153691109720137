import { faker } from "@faker-js/faker";

import type {
  GetTask200,
  GetTaskPathParams,
  GetTaskQueryParams,
  GetTaskQueryResponse,
} from "../models/GetTask";
import { createTask } from "./createTask";

export function createGetTaskPathParams(): NonNullable<GetTaskPathParams> {
  return { id: faker.number.int() };
}

export function createGetTaskQueryParams(): NonNullable<GetTaskQueryParams> {
  return { expand: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createGetTask200(): NonNullable<GetTask200> {
  return { data: createTask() };
}

/**
 * @description OK
 */
export function createGetTaskQueryResponse(): NonNullable<GetTaskQueryResponse> {
  return { data: createTask() };
}
