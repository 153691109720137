import type { File } from "./File";

export type PolicyExportPdfPathParams = {
  /**
   * @description Policy RecordVersion ID
   * @type integer
   */
  id: number;
};

export const policyExportPdfQueryParamsSections = {
  record_details: "record_details",
  record_comments_summary: "record_comments_summary",
  attachments: "attachments",
  attachments_comment_summary: "attachments_comment_summary",
} as const;
export type PolicyExportPdfQueryParamsSections =
  (typeof policyExportPdfQueryParamsSections)[keyof typeof policyExportPdfQueryParamsSections];
export type PolicyExportPdfQueryParams = {
  /**
   * @description Selected sections to include in PDF export
   * @type array | undefined
   */
  sections?: PolicyExportPdfQueryParamsSections[];
};

/**
 * @description OK
 */
export type PolicyExportPdf200 = {
  data: File;
};

/**
 * @description Forbidden
 */
export type PolicyExportPdf403 = {
  /**
   * @type array | undefined
   */
  errors?: {
    /**
     * @description Error code identifying the type of error
     * @type string
     */
    code: string;
    /**
     * @description Human-readable error message
     * @type string
     */
    message: string;
  }[];
};

/**
 * @description OK
 */
export type PolicyExportPdfQueryResponse = {
  data: File;
};

export type PolicyExportPdfQuery = {
  Response: PolicyExportPdfQueryResponse;
  PathParams: PolicyExportPdfPathParams;
  QueryParams: PolicyExportPdfQueryParams;
  Errors: PolicyExportPdf403;
};
