import { faker } from "@faker-js/faker";

import type {
  CreateComment201,
  CreateCommentMutationRequest,
  CreateCommentMutationResponse,
  CreateCommentPathParams,
} from "../models/CreateComment";
import { createComment } from "./createComment";
import { createRecordType } from "./createRecordType";

export function createCreateCommentPathParams(): NonNullable<CreateCommentPathParams> {
  return { record_type: createRecordType(), record_id: faker.number.int() };
}

/**
 * @description Comment created
 */
export function createCreateComment201(): NonNullable<CreateComment201> {
  return { data: createComment() };
}

export function createCreateCommentMutationRequest(): NonNullable<CreateCommentMutationRequest> {
  return {
    comment: { content: faker.string.alpha(), parent_id: faker.number.int() },
  };
}

/**
 * @description Comment created
 */
export function createCreateCommentMutationResponse(): NonNullable<CreateCommentMutationResponse> {
  return { data: createComment() };
}
