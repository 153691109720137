import { faker } from "@faker-js/faker";

import type { ThemisDocument } from "../models/ThemisDocument";
import { createFile } from "./createFile";

export function createThemisDocument(
  data: NonNullable<Partial<ThemisDocument>> = {},
): NonNullable<ThemisDocument> {
  return {
    ...{ id: faker.number.int(), file: createFile(), preview: createFile() },
    ...data,
  };
}
