import { http } from "msw";

import { createRegionsQueryResponse } from "../createRegions";

export const regionsHandler = http.get(
  "*/countries/:country_code/regions",
  function handler(info) {
    return new Response(JSON.stringify(createRegionsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
