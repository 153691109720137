import { http } from "msw";

import { createImportThemisRecordsMutationResponse } from "../createImportThemisRecords";

export const importThemisRecordsHandler = http.post(
  "*/companies/:company_id/:record_type/imports",
  function handler(info) {
    return new Response(
      JSON.stringify(createImportThemisRecordsMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
