import { faker } from "@faker-js/faker";

import type {
  UpdateAccount200,
  UpdateAccountMutationRequest,
  UpdateAccountMutationResponse,
  UpdateAccountPathParams,
} from "../models/UpdateAccount";
import { createAccount } from "./createAccount";
import { createModifyAccountRequest } from "./createModifyAccountRequest";

export function createUpdateAccountPathParams(): NonNullable<UpdateAccountPathParams> {
  return { workspace_id: faker.number.int(), id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateAccount200(): NonNullable<UpdateAccount200> {
  return { data: createAccount() };
}

export function createUpdateAccountMutationRequest(): NonNullable<UpdateAccountMutationRequest> {
  return { account: createModifyAccountRequest() };
}

/**
 * @description OK
 */
export function createUpdateAccountMutationResponse(): NonNullable<UpdateAccountMutationResponse> {
  return { data: createAccount() };
}
