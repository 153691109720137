import { useMutation } from "@tanstack/react-query";

import type { CreateBlobMutationRequest } from "@/api";
import { createBlob } from "@/api/gen/axios/blobsController";

export function useCreateBlob({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: (data: CreateBlobMutationRequest) => createBlob(data),
    onSuccess,
    onError,
  });
}
