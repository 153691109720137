import { http } from "msw";

import { createListRecordLinksQueryResponse } from "../createListRecordLinks";

export const listRecordLinksHandler = http.get(
  "*/:record_type/:record_id/record_links",
  function handler(info) {
    return new Response(JSON.stringify(createListRecordLinksQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
