import { http } from "msw";

import { createGetDashboardComponentQueryResponse } from "../createGetDashboardComponent";

export const getDashboardComponentHandler = http.get(
  "*/workspaces/:workspace_id/components/:system_name",
  function handler(info) {
    return new Response(
      JSON.stringify(createGetDashboardComponentQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
