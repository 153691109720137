import React from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import PolicyActionBar from "@/components/table/policy/PolicyActionBar";
import ViewBuildChecklist from "@/components/table/shared/dynamic-form/ViewBuildChecklist";
import Table from "@/components/table/Table";

interface PoliciesViewBuildChecklistProps {
  editingMode?: boolean;
  isTemplate?: boolean;
}

const PoliciesViewBuildChecklist = ({
  editingMode,
  isTemplate,
}: PoliciesViewBuildChecklistProps) => {
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader
        title="Policy"
        onBackClick={() => history.goBack()}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<PolicyActionBar />}
      />
      <DashboardContentWrapper>
        <Table>
          <ViewBuildChecklist
            editingMode={editingMode}
            isTemplate={isTemplate}
          />
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default PoliciesViewBuildChecklist;
