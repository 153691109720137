import { http } from "msw";

import { createListCommentsQueryResponse } from "../createListComments";

export const listCommentsHandler = http.get(
  "*/:record_type/:record_id/comments",
  function handler(info) {
    return new Response(JSON.stringify(createListCommentsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
