import { AddNewRowLink, Table } from "@themis/ui";
import React from "react";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";

import type { Contact } from "@/api";

import { useContactsColumns } from "../../config/use-contacts-columns";

function AccountContacts({ contacts }: { contacts: Contact[] }) {
  const { formatMessage } = useIntl();
  const { url } = useRouteMatch();

  const columns = useContactsColumns({
    contactsRoute: url,
  });

  return (
    <div>
      <Table columns={columns} rows={contacts} />

      <AddNewRowLink
        className="tw-mt-1"
        title={formatMessage({
          defaultMessage: "Add Contact",
          description:
            "Add button that is shown under the account contacts table",
        })}
        to={{
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }}
      />
    </div>
  );
}

export { AccountContacts };
