import { IconButton } from "@themis/ui";
import { matchesProperty } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { generatePath, Link, useParams } from "react-router-dom";

import type { EventNotificationRule } from "@/api/legacy/event-notifications/types";
import Edit from "@/assets/svgs/edit";
import { Flex, Icon, MiniTag } from "@/components/Elements";
import type { IconName } from "@/components/Elements/Icon/icon";
import { UsersCircle } from "@/components/users-circle/users-circle";
import { useMainStore } from "@/contexts/Store";
import {
  EVENT_TAB,
  MODULE_NOTIFICATIONS_EDIT_PATH,
} from "@/features/notifications/pages/constants";
import type { ModuleNotificationParams } from "@/features/notifications/types/types";
import type { ValueLabelOption } from "@/stores/types/field-types";

import { NotificationText } from "../text/notification-text";
import {
  getMultipleRecipientsText,
  getNumberOfRecipients,
  getSingleRecipientInfo,
} from "./helpers";

function EventNotificationRow(props: {
  eventNotification: EventNotificationRule;
  columns: ValueLabelOption[];
  events: ValueLabelOption[];
}) {
  const mainStore = useMainStore();
  const params = useParams<ModuleNotificationParams>();

  const triggerEvents = props.eventNotification.data.events;
  const { recipients } = props.eventNotification.data;
  const numberOfRecipients = getNumberOfRecipients(recipients);
  const multipleRecipients = numberOfRecipients > 1;

  const singleRecipientInfo =
    !multipleRecipients &&
    getSingleRecipientInfo({
      recipients,
      users: mainStore.users.users,
      departments: mainStore.departments.departments,
      columns: props.columns,
    });

  const triggerEventsText =
    triggerEvents.length > 1
      ? `${triggerEvents.length} events happened`
      : // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        props.events.find(matchesProperty("value", triggerEvents[0])).label;

  return (
    <Flex
      alignCenter
      justifySpaceBetween
      className="notifications-container__single"
    >
      <Flex columnGap={6}>
        <NotificationText label="If" />
        <NotificationText label={triggerEventsText} accent />
        <NotificationText label=", send email to" />
        {multipleRecipients ? (
          <NotificationText
            label={getMultipleRecipientsText(recipients)}
            accent
          />
        ) : (
          <Flex columnGap={8} alignCenter>
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            {singleRecipientInfo.initials && (
              <UsersCircle
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                initials={singleRecipientInfo.initials}
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                iconColorIndex={singleRecipientInfo.icon_color_index}
              />
            )}
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            {singleRecipientInfo.iconName && (
              // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
              <Icon name={singleRecipientInfo.iconName as IconName} />
            )}
            <NotificationText
              // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
              label={singleRecipientInfo.label || singleRecipientInfo.full_name}
              small
            />
          </Flex>
        )}
      </Flex>
      <Flex alignCenter columnGap={8}>
        <MiniTag
          label={props.eventNotification.enabled ? "Enabled" : "Disabled"}
          theme={props.eventNotification.enabled ? "green" : "gray"}
        />
        <Link
          className="tw-flex"
          to={{
            pathname: generatePath(MODULE_NOTIFICATIONS_EDIT_PATH, {
              ...params,
              tab: EVENT_TAB,
              notificationId: props.eventNotification.id,
            }),
            state: { fromAllTab: !params.tab },
          }}
        >
          <IconButton
            // eslint-disable-next-line react/no-unstable-nested-components
            Icon={() => <Edit width="16px" />}
            color="transparent"
            size="sm"
          />
        </Link>
      </Flex>
    </Flex>
  );
}

export default observer(EventNotificationRow);
