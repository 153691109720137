import { faker } from "@faker-js/faker";

import type {
  CreateAttestationList201,
  CreateAttestationListMutationRequest,
  CreateAttestationListMutationResponse,
  CreateAttestationListPathParams,
  CreateAttestationListQueryParams,
} from "../models/CreateAttestationList";
import { createAttestationList } from "./createAttestationList";

export function createCreateAttestationListPathParams(): NonNullable<CreateAttestationListPathParams> {
  return { company_id: faker.number.int() };
}

export function createCreateAttestationListQueryParams(): NonNullable<CreateAttestationListQueryParams> {
  return {
    workspace_id: faker.number.int(),
    record_type: faker.helpers.arrayElement<any>(["trainings"]),
  };
}

/**
 * @description Created
 */
export function createCreateAttestationList201(): NonNullable<CreateAttestationList201> {
  return { data: createAttestationList() };
}

export function createCreateAttestationListMutationRequest(): NonNullable<CreateAttestationListMutationRequest> {
  return { attestation_list: createAttestationList() };
}

/**
 * @description Created
 */
export function createCreateAttestationListMutationResponse(): NonNullable<CreateAttestationListMutationResponse> {
  return { data: createAttestationList() };
}
