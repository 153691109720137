import { HeaderTabs } from "@themis/ui";
import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";

import {
  AUDIT_LIST_PATH,
  moduleAssessmentRoutes,
  RECORD_VERSION_LIST_PATH,
} from "../routes";

interface ModuleAssessmentTabsProps {
  RightComponent?: React.ReactNode;
}

export function ModuleAssessmentHeader({
  RightComponent,
}: ModuleAssessmentTabsProps) {
  const { workspace_id, record_version_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
  }>();

  const history = useHistory();

  function handleTabChange(value: string) {
    history.push(value);
  }

  const TABS = [
    {
      name: "Record View",
      key: "record_view",
      value: generatePath(RECORD_VERSION_LIST_PATH, {
        workspace_id,
        record_version_id,
      }),
    },
    {
      name: "Control Assessment",
      key: "module_assessments",
      value: generatePath(moduleAssessmentRoutes.overview, {
        workspace_id,
        record_version_id,
      }),
    },
    {
      name: "Activity",
      key: "activity",
      value: generatePath(AUDIT_LIST_PATH, {
        workspace_id,
        record_version_id,
      }),
    },
  ];

  return (
    <div className="tw-flex tw-h-[44px] tw-w-full tw-items-center tw-justify-between tw-bg-neutral-25 tw-py-2">
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS)?.value || ""}
        onSelectTab={handleTabChange}
        tabsClassName="tw-w-full"
        tabsListClassName="tw-px-6"
        border
      />
      {RightComponent}
    </div>
  );
}

export default ModuleAssessmentHeader;
