import { faker } from "@faker-js/faker";

import type {
  ApiAuthUpdate200,
  ApiAuthUpdateMutationRequest,
  ApiAuthUpdateMutationResponse,
  ApiAuthUpdatePathParams,
} from "../models/ApiAuthUpdate";
import { createApiAuth } from "./createApiAuth";
import { createUpdateApiAuthRequest } from "./createUpdateApiAuthRequest";

export function createApiAuthUpdatePathParams(): NonNullable<ApiAuthUpdatePathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createApiAuthUpdate200(): NonNullable<ApiAuthUpdate200> {
  return { data: createApiAuth() };
}

export function createApiAuthUpdateMutationRequest(): NonNullable<ApiAuthUpdateMutationRequest> {
  return { data: createUpdateApiAuthRequest() };
}

/**
 * @description OK
 */
export function createApiAuthUpdateMutationResponse(): NonNullable<ApiAuthUpdateMutationResponse> {
  return { data: createApiAuth() };
}
