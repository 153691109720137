import { http } from "msw";

import { createWebsiteMonitoringGroupChildResultsQueryResponse } from "../createWebsiteMonitoringGroupChildResults";

export const websiteMonitoringGroupChildResultsHandler = http.get(
  "*/website_monitoring/:monitoring_group_asset_child_id/monitoring_group_child_results",
  function handler(info) {
    return new Response(
      JSON.stringify(createWebsiteMonitoringGroupChildResultsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
