import { LinkButton } from "@themis/ui";
import { generatePath } from "react-router-dom";

import { websiteMonitoringRoutes } from "../routes";

export function ReviewButton({
  monitoringGroupAssetChildId,
}: {
  monitoringGroupAssetChildId: number;
}) {
  return (
    <LinkButton
      to={generatePath(
        `${websiteMonitoringRoutes.base}${websiteMonitoringRoutes.monitoringGroups}${websiteMonitoringRoutes.monitoringGroupAssetChildResults}`,
        {
          monitoringGroupAssetChildId,
        },
      )}
      size="lg"
      color="tertiary"
    >
      Review
    </LinkButton>
  );
}
