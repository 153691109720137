import { http } from "msw";

import { createCreateAccountContractMutationResponse } from "../createCreateAccountContract";

export const createAccountContractHandler = http.post(
  "*/accounts/:account_id/contracts",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateAccountContractMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
