import { faker } from "@faker-js/faker";

import type {
  ShowDocument200,
  ShowDocumentPathParams,
  ShowDocumentQueryResponse,
} from "../models/ShowDocument";
import { createDocument } from "./createDocument";

export function createShowDocumentPathParams(): NonNullable<ShowDocumentPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createShowDocument200(): NonNullable<ShowDocument200> {
  return { document: createDocument() };
}

/**
 * @description OK
 */
export function createShowDocumentQueryResponse(): NonNullable<ShowDocumentQueryResponse> {
  return { document: createDocument() };
}
