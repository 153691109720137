import { faker } from "@faker-js/faker";

import type {
  CreateTask201,
  CreateTaskMutationRequest,
  CreateTaskMutationResponse,
  CreateTaskPathParams,
} from "../models/CreateTask";
import { createCreateTaskRequest } from "./createCreateTaskRequest";
import { createTask } from "./createTask";

export function createCreateTaskPathParams(): NonNullable<CreateTaskPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description Created
 */
export function createCreateTask201(): NonNullable<CreateTask201> {
  return { data: createTask() };
}

export function createCreateTaskMutationRequest(): NonNullable<CreateTaskMutationRequest> {
  return createCreateTaskRequest();
}

/**
 * @description Created
 */
export function createCreateTaskMutationResponse(): NonNullable<CreateTaskMutationResponse> {
  return { data: createTask() };
}
