import classNames from "classnames";
import { observer } from "mobx-react";
import React, { Fragment } from "react";

import ColorSelect from "@/features/risk-assessment/components/RiskMethodology/RiskMethodologyBuilder/ColorSelect";
import { RISK_RATING_COLUMN_COLORS } from "@/features/risk-assessment/config";
import type {
  ScoringMatrixColumn,
  ScoringMatrixRow,
} from "@/stores/types/risk-register-types";

import ModuleTableColumn from "../shared/ModuleTableColumn";
import ScoringDefinitionsCell from "./ScoringDefinitionsCell";

const FIELDS = [
  {
    id: 1,
    width: 200,
    name: "rating",
    display_name: "Rating",
    data_type: "com.askthemis.types.v1.text",
    is_user_editable: false,
    is_control_definition: false,
    is_edit_display_only: false,
  },
  {
    id: 2,
    width: 242,
    name: "color",
    display_name: "Color",
    data_type: "com.askthemis.types.v1.option",
    is_user_editable: false,
    is_control_definition: true,
    is_edit_display_only: true,
  },
  {
    id: 3,
    width: 665,
    name: "definition",
    display_name: "Definition",
    data_type: "com.askthemis.types.v1.text",
    is_user_editable: false,
    is_control_definition: false,
    is_edit_display_only: false,
  },
  {
    id: 4,
    width: 150,
    name: "value",
    display_name: "Value",
    data_type: "com.askthemis.types.v1.text",
    is_user_editable: false,
    is_control_definition: true,
    is_edit_display_only: false,
  },
];

interface Props {
  handleEditSubmit: (data: (ScoringMatrixRow | ScoringMatrixColumn)[]) => void;
  editingMode?: boolean;
  items?: ScoringMatrixRow[] | ScoringMatrixColumn[];
  title?: string;
  controlDefinition?: boolean;
}

function ScoringDefinitionsTable({
  title,
  items,
  editingMode,
  handleEditSubmit,
  controlDefinition = false,
}: Props) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
  const handleChange = (i, val) => {
    handleEditSubmit(
      (items || []).map((item) => {
        if (item.id === i.id) {
          return { ...item, definition: val };
        }
        return item;
      }),
    );
  };

  const handleValueChange = (
    i: ScoringMatrixColumn | ScoringMatrixRow,
    val: number,
  ) => {
    handleEditSubmit(
      // @ts-expect-error TS(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
      (items || []).map((item) => {
        if (item.id === i.id) {
          return {
            ...item,
            column_scoring_matrix_ratings: {
              ...item.column_scoring_matrix_ratings,
              value: val,
            },
          };
        }
        return item;
      }),
    );
  };

  // renders
  const renderFields = () =>
    FIELDS.map((field) => {
      if (controlDefinition && field.is_edit_display_only && !editingMode) {
        return <Fragment key={field.name} />;
      }
      if (!controlDefinition && field.is_control_definition) {
        return <Fragment key={field.name} />;
      }
      return <ModuleTableColumn key={field.name} field={field} />;
    });

  const updateColor = (index: number, value: string) => {
    handleEditSubmit(
      // @ts-expect-error TS(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
      (items || []).map((item, i) => {
        if (index === i) {
          return {
            ...item,
            column_scoring_matrix_ratings: {
              ...item.column_scoring_matrix_ratings,
              color: value,
            },
          };
        }
        return item;
      }),
    );
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderRows = items.map((item, index) => (
    <div key={index} className="list-table">
      <ul>
        <div className="list-table-wrap">
          <div className="list-table-block">
            <li style={{ width: FIELDS[0].width }}>
              <div className="cell-content rr-scoring-definitions-table-cell-value">
                {item.title}
              </div>
            </li>
            <div
              style={{
                display: controlDefinition && editingMode ? "flex" : "none",
              }}
            >
              <ColorSelect
                index={index}
                selectedColor={
                  item?.column_scoring_matrix_ratings?.color ||
                  RISK_RATING_COLUMN_COLORS[0]
                }
                updateColor={updateColor}
                availableColors={RISK_RATING_COLUMN_COLORS}
                classNameSelected="risk-methodology-builder__content__column-risk-rating-color__selected"
              />
            </div>
            <ScoringDefinitionsCell
              width={FIELDS[2].width}
              initialValue={item.definition}
              onChange={(val) => handleChange(item, val)}
              isUserEditable={editingMode}
            />
            <div
              style={{
                display: controlDefinition ? "flex" : "none",
                backgroundColor:
                  controlDefinition && editingMode
                    ? "transparent"
                    : "whitesmoke",
              }}
            >
              <ScoringDefinitionsCell
                width={FIELDS[3].width}
                initialValue={item?.column_scoring_matrix_ratings?.value?.toString()}
                onChange={(val) => handleValueChange(item, Number(val))}
                isUserEditable={editingMode}
              />
            </div>
            <span className="stretch-cell" />
          </div>
        </div>
      </ul>
    </div>
  ));

  return (
    <div
      className={classNames(
        "rr-scoring-definitions-table vdd-templates-wrapper without-first-block",
        { editable: editingMode },
      )}
    >
      <div>
        <h4 className="questionnaires-title">{title}</h4>
        <div className="list-title-table">
          <ul>
            <div className="list-column-wrap">
              <div className="list-title-table-wrap">
                {renderFields()}
                <span className="stretch-cell" />
              </div>
            </div>
          </ul>
        </div>

        {renderRows}
      </div>
    </div>
  );
}

ScoringDefinitionsTable.defaultProps = {
  title: "Definitions",
  items: [],
  editingMode: false,
};

export default observer(ScoringDefinitionsTable);
