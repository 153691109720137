import { faker } from "@faker-js/faker";

import type { ControlMappingType } from "../models/ControlMappingType";

export function createControlMappingType(
  data: NonNullable<Partial<ControlMappingType>> = {},
): NonNullable<ControlMappingType> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      used_count: faker.number.int(),
      title: faker.string.alpha(),
    },
    ...data,
  };
}
