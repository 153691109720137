import { faker } from "@faker-js/faker";

import type {
  CreateModuleAssessmentDocument201,
  CreateModuleAssessmentDocument403,
  CreateModuleAssessmentDocumentMutationRequest,
  CreateModuleAssessmentDocumentMutationResponse,
  CreateModuleAssessmentDocumentPathParams,
} from "../models/CreateModuleAssessmentDocument";
import { createModifyDocumentRequest } from "./createModifyDocumentRequest";
import { createThemisDocument } from "./createThemisDocument";

export function createCreateModuleAssessmentDocumentPathParams(): NonNullable<CreateModuleAssessmentDocumentPathParams> {
  return { module_assessment_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateModuleAssessmentDocument201(): NonNullable<CreateModuleAssessmentDocument201> {
  return { data: createThemisDocument() };
}

/**
 * @description Forbidden
 */
export function createCreateModuleAssessmentDocument403(): NonNullable<CreateModuleAssessmentDocument403> {
  return { errors: {} };
}

export function createCreateModuleAssessmentDocumentMutationRequest(): NonNullable<CreateModuleAssessmentDocumentMutationRequest> {
  return { data: createModifyDocumentRequest() };
}

/**
 * @description OK
 */
export function createCreateModuleAssessmentDocumentMutationResponse(): NonNullable<CreateModuleAssessmentDocumentMutationResponse> {
  return { data: createThemisDocument() };
}
