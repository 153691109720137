import { faker } from "@faker-js/faker";

import type {
  ModuleAssessmentShowDocument200,
  ModuleAssessmentShowDocumentPathParams,
  ModuleAssessmentShowDocumentQueryResponse,
} from "../models/ModuleAssessmentShowDocument";
import { createThemisDocument } from "./createThemisDocument";

export function createModuleAssessmentShowDocumentPathParams(): NonNullable<ModuleAssessmentShowDocumentPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createModuleAssessmentShowDocument200(): NonNullable<ModuleAssessmentShowDocument200> {
  return { data: createThemisDocument() };
}

/**
 * @description OK
 */
export function createModuleAssessmentShowDocumentQueryResponse(): NonNullable<ModuleAssessmentShowDocumentQueryResponse> {
  return { data: createThemisDocument() };
}
