import { faker } from "@faker-js/faker";

import type {
  Update201,
  UpdateMutationRequest,
  UpdateMutationResponse,
  UpdatePathParams,
} from "../models/Update";
import { createAttestationList } from "./createAttestationList";

export function createUpdatePathParams(): NonNullable<UpdatePathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdate201(): NonNullable<Update201> {
  return { data: createAttestationList() };
}

export function createUpdateMutationRequest(): NonNullable<UpdateMutationRequest> {
  return { attestation_list: createAttestationList() };
}

/**
 * @description OK
 */
export function createUpdateMutationResponse(): NonNullable<UpdateMutationResponse> {
  return { data: createAttestationList() };
}
