import { faker } from "@faker-js/faker";

import type { AppliedPermissionSet } from "../models/AppliedPermissionSet";
import { createPermissionSet } from "./createPermissionSet";

export function createAppliedPermissionSet(
  data: NonNullable<Partial<AppliedPermissionSet>> = {},
): NonNullable<AppliedPermissionSet> {
  return {
    ...{
      id: faker.helpers.arrayElement<any>([
        faker.string.alpha(),
        faker.string.uuid(),
      ]),
      permission_set: Object.assign({}, createPermissionSet()),
      user_id: faker.number.int(),
      workspace_id: faker.number.int(),
    },
    ...data,
  };
}
