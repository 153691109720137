import { observer } from "mobx-react";
import React, { useState } from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Popup from "reactjs-popup";

import { Button, IconButton } from "@/components/Elements";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog/confirmation-dialog";
import { useMainStore } from "@/contexts/Store";

import {
  MODULE_NOTIFICATIONS_BASE_PATH,
  MODULE_NOTIFICATIONS_CREATE_PATH,
} from "../../pages/constants";
import type { ModuleNotificationParams } from "../../types";

function RightActionBar(props: {
  isDirty: boolean;
  onDelete(): Promise<boolean>;
}) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const createMatch = useRouteMatch(MODULE_NOTIFICATIONS_CREATE_PATH);
  const history = useHistory();
  const params = useParams<ModuleNotificationParams>();
  const [showPopup, setShowPopup] = useState(false);

  async function handleConfirm() {
    const deleted = await props.onDelete();
    if (!workspaceID) {
      return;
    }

    if (deleted) {
      mainStore.toast.setInfoText("The notification was successfully deleted.");
      history.push(
        generatePath(MODULE_NOTIFICATIONS_BASE_PATH, {
          ...params,
          workspace_id: workspaceID,
        }),
      );
    }

    handlePopUpClose();
  }

  function handlePopUpOpen() {
    setShowPopup(true);
  }

  function handlePopUpClose() {
    setShowPopup(false);
  }

  return (
    <div className="tw-flex tw-flex-row-reverse tw-items-center tw-gap-2">
      <div>
        <Button
          disabled={!props.isDirty}
          size="sm"
          label={`${createMatch ? "Save" : "Update"} Notification`}
          type="submit"
        />
      </div>
      {!createMatch && (
        <Popup
          position="bottom left"
          trigger={
            <div>
              <IconButton icon="trash" theme="tertiary" />
            </div>
          }
          open={showPopup}
          onOpen={handlePopUpOpen}
          onClose={handlePopUpClose}
          keepTooltipInside
        >
          <div className="table-dropdown success-dropdown back-btn-confirm-popup">
            <ConfirmationDialog
              handleConfirm={handleConfirm}
              handleReject={handlePopUpClose}
              heading="Delete this Notification"
              content={
                "Deleting this notification will lose all the data and you will need to start again if you choose to leave now.\n\nAre you sure you want to proceed?"
              }
            />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default observer(RightActionBar);
