import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateRecordLinkMutationRequest,
  CreateRecordLinkMutationResponse,
  CreateRecordLinkPathParams,
} from "../../models/CreateRecordLink";

/**
 * @description Create a record link
 * @summary Create a record link
 * @link /:record_type/:record_id/record_links
 */
export async function createRecordLink(
  recordType: CreateRecordLinkPathParams["record_type"],
  recordId: CreateRecordLinkPathParams["record_id"],
  data: CreateRecordLinkMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateRecordLinkMutationResponse>["data"]> {
  const res = await client<
    CreateRecordLinkMutationResponse,
    CreateRecordLinkMutationRequest
  >({
    method: "post",
    url: `/${recordType}/${recordId}/record_links`,
    data,
    ...options,
  });
  return res.data;
}
