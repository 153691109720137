import { http } from "msw";

import { createUpdateFindingMutationResponse } from "../createUpdateFinding";

export const updateFindingHandler = http.put(
  "*/findings/:id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateFindingMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
