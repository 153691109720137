import { faker } from "@faker-js/faker";

import type { MonitoringResultAttachmentFile } from "../models/MonitoringResultAttachmentFile";

export function createMonitoringResultAttachmentFile(
  data: NonNullable<Partial<MonitoringResultAttachmentFile>> = {},
): NonNullable<MonitoringResultAttachmentFile> {
  return {
    ...{
      created_at: faker.date.anytime().toISOString(),
      file_name: faker.string.alpha(),
      file_size: faker.string.alpha(),
      file_url: faker.string.alpha(),
      signed_id: faker.string.alpha(),
    },
    ...data,
  };
}
