import { faker } from "@faker-js/faker";

import type {
  GetAttestationList200,
  GetAttestationListPathParams,
  GetAttestationListQueryResponse,
} from "../models/GetAttestationList";
import { createAttestationList } from "./createAttestationList";

export function createGetAttestationListPathParams(): NonNullable<GetAttestationListPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetAttestationList200(): NonNullable<GetAttestationList200> {
  return { data: createAttestationList() };
}

/**
 * @description OK
 */
export function createGetAttestationListQueryResponse(): NonNullable<GetAttestationListQueryResponse> {
  return { data: createAttestationList() };
}
