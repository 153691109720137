import { faker } from "@faker-js/faker";

import type { Notification } from "../models/Notification";

export function createNotification(
  data: NonNullable<Partial<Notification>> = {},
): NonNullable<Notification> {
  return {
    ...{
      id: faker.number.int(),
      workspace_id: faker.number.int(),
      company_id: faker.number.int(),
      created_at: faker.date.anytime().toISOString(),
      dismissed_at: faker.date.anytime().toISOString(),
      actor_id: faker.number.int(),
      recipient_id: faker.number.int(),
      read_at: faker.date.anytime().toISOString(),
      title: faker.string.alpha(),
      body: faker.string.alpha(),
      link: faker.string.alpha(),
      themis_id: faker.string.alpha(),
      module_identifier: faker.string.alpha(),
      module_display_name: faker.string.alpha(),
      actor_initials: faker.string.alpha(),
      actor_color_index: faker.number.int(),
    },
    ...data,
  };
}
