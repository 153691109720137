import { faker } from "@faker-js/faker";

import type { Table } from "../models/Table";
import { createField } from "./createField";
import { createTableName } from "./createTableName";

export function createTable(
  data: NonNullable<Partial<Table>> = {},
): NonNullable<Table> {
  return {
    ...{
      id: faker.number.int(),
      title: createTableName(),
      module_workspace_id: faker.number.int(),
      fields: faker.helpers.arrayElements([createField()]) as any,
    },
    ...data,
  };
}
