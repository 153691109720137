import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { Findings } from "@/features/findings/pages/Findings";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { FindingDetails } from "./FindingDetails/FindingDetails";
import { findingRoutes } from "./routes";

export type FindingsThemisModuleIdentifier = Extract<
  ModuleIdentifier,
  "qa_tests_development" | "audits" | "complaints"
>;

const themisModulePaths: Record<FindingsThemisModuleIdentifier, string> = {
  ["qa_tests_development"]: "qa-tests",
  ["audits"]: "audits",
  ["complaints"]: "complaints",
} as const;

export const getFindingsListPath = (
  moduleIdentifier: FindingsThemisModuleIdentifier,
) =>
  `/workspaces/:workspace_id/modules/${themisModulePaths[moduleIdentifier]}/:record_version_id${findingRoutes.base}` as const;

export const FindingsRoutes = observer(
  ({
    moduleIdentifier,
  }: {
    moduleIdentifier: FindingsThemisModuleIdentifier;
  }) => {
    const mainStore = useMainStore();
    const { path } = useRouteMatch();
    const { workspace_id, record_version_id } = useParams<{
      workspace_id: string;
      record_version_id: string;
    }>();

    const recordVersion = mainStore.recordVersions.list.find(
      (item) => item.id === Number(record_version_id),
    );
    const recordId = recordVersion?.record_id;

    const isIssueManagementModuleAdded =
      mainStore.moduleWorkspaces.isModuleAdded("issue_management");

    useEffect(() => {
      if (recordId) {
        return;
      }

      if (moduleIdentifier === "audits") {
        mainStore.themisAudits.index({ workspaceID: Number(workspace_id) });

        return;
      }

      if (moduleIdentifier === "qa_tests_development") {
        mainStore.qa.index({ workspaceID: Number(workspace_id) });

        return;
      }

      if (moduleIdentifier === "complaints") {
        mainStore.complaints.index({ workspaceID: Number(workspace_id) });
      }
    }, [recordId, moduleIdentifier]);

    return (
      <Switch>
        <Route path={`${path}${findingRoutes.details}`}>
          <FindingDetails
            recordId={recordId}
            isIssueManagementModuleAdded={isIssueManagementModuleAdded}
            moduleIdentifier={moduleIdentifier}
          />
        </Route>
        <Route exact path={path}>
          <Findings recordId={recordId} />
        </Route>
      </Switch>
    );
  },
);
