import { faker } from "@faker-js/faker";

import type {
  CreateAccountContract201,
  CreateAccountContract403,
  CreateAccountContractMutationRequest,
  CreateAccountContractMutationResponse,
  CreateAccountContractPathParams,
} from "../models/CreateAccountContract";
import { createContract } from "./createContract";
import { createModifyContractRequest } from "./createModifyContractRequest";

export function createCreateAccountContractPathParams(): NonNullable<CreateAccountContractPathParams> {
  return { account_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateAccountContract201(): NonNullable<CreateAccountContract201> {
  return { contract: createContract() };
}

/**
 * @description Forbidden
 */
export function createCreateAccountContract403(): NonNullable<CreateAccountContract403> {
  return { error: {} };
}

export function createCreateAccountContractMutationRequest(): NonNullable<CreateAccountContractMutationRequest> {
  return { contract: createModifyContractRequest() };
}

/**
 * @description OK
 */
export function createCreateAccountContractMutationResponse(): NonNullable<CreateAccountContractMutationResponse> {
  return { contract: createContract() };
}
