import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import { useLoading } from "../../../hooks/useLoading";
import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import DetailNav from "../../detailView/DetailNav";
import Loading from "../../Loading";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionDragContext from "../shared/SectionDragContext";
import TableMenu from "../shared/tableMenu/TableMenu";

function ActionPlans() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const { workspaceID, moduleWorkspaceID, subModuleTableID } =
    mainStore.context;
  const visibleFields = mainStore.fields.visibleFields || [];
  const recordVersions = mainStore.recordVersions.list;
  const { list: tables } = mainStore.tables;
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // Hooks
  const loading = useLoading(visibleFields);

  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();
  const {
    createColumn,
    renameColumn,
    deleteColumn,
    AddColumnButton,
    // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'st... Remove this comment to see the full error message
  } = useCustomColumns({ subTableID: subModuleTableID });

  // Effects
  useEffect(() => {
    // Make sure tables are loaded before fetching RecordVersion
    if (!tables.length) {
      return;
    }

    const fetchActionPlans = async () => {
      const { module_workspace_id, table_id } =
        await mainStore.issueManagement.indexActionPlans(recordVersionID);
      await mainStore.fieldOptions.index(module_workspace_id);
      mainStore.context.setSubModuleTableID(table_id);
      mainStore.context.setSubModuleTableName("action_plans");
    };

    fetchActionPlans();
  }, [tables]);

  useEffect(() => {
    if (moduleWorkspaceID && tables.length === 0) {
      mainStore.tables.index(moduleWorkspaceID);
      if (workspaceID) {
        mainStore.users.indexForModules({ workspaceID });
      }
    }
  }, [moduleWorkspaceID]);

  // Functions
  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    // @ts-expect-error TS(2551) FIXME: Property 'clearSelectedrecordVersionIDs' does not ... Remove this comment to see the full error message
    mainStore.dynamicTable.clearSelectedrecordVersionIDs();
  };

  function handleClick() {
    mainStore.issueManagement.createActionPlan(recordVersionID);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx) => {
    const statusValue = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion.data,
    );
    const isClosed = statusValue === "closed";

    const maxDateFunction = (columnTitle: string) => {
      if (
        columnTitle === "Target Completion Date" &&
        recordVersion.record.parent_due_date
      ) {
        return new Date(recordVersion.record.parent_due_date);
      }
    };

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isArchivedRow={isClosed}
        // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName="Default"
        moduleIdentifier="action_plans"
        inSelectMenuActionMode={selectMode}
        recordVersion={recordVersion}
        isDraggable
        fields={visibleFields}
        order={idx}
        maxDateFunction={maxDateFunction}
      />
    );
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'sourcerecordVersionID' implicitly has a... Remove this comment to see the full error message
  const onDragEnd = async (sourcerecordVersionID, payload) => {
    await mainStore.recordVersions.reorder(sourcerecordVersionID, payload);
  };

  // elements
  const renderFields = visibleFields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  return (
    <div
      className="settings-wrap company-users-settings-container draggable action-plans"
      data-testid="action-plans"
    >
      <DetailNav
        moduleIdentifier="issue_management"
        recordVersionID={recordVersionID}
      >
        <div className="import-export-buttons-container action-plans-import-export-container">
          <ExportBulk
            parentRecordVersionID={recordVersionID}
            subModuleDownload
          />
          <ImportBulk
            parentRecordVersionID={recordVersionID}
            subModuleDownload
          />
        </div>
      </DetailNav>
      {!loading && hasModuleWriteAccess && (
        <AddRecordHeader recordName="Action Plan" onAddRecord={handleClick} />
      )}
      {loading ? (
        <Loading loadingLayout="table" showTableHeader={false} />
      ) : (
        <SectionDragContext>
          <div
            className="settings-content-wrap"
            data-testid="action-plans-content"
          >
            <div className="list-title-table">
              <ul>
                <div className="list-column-wrap">
                  <div className="procedures-list-column-first-blocks table-column-reorder">
                    {visibleFields.length > 0 && (
                      <TableMenu
                        setShowCheckbox={setShowCheckbox}
                        resetMenuActions={resetMenuActions}
                        selectMode={selectMode}
                        setSelectMode={setSelectMode}
                        isSubModule
                        onlyIncludeOptions={[
                          "selectIndividual",
                          "manageColumns",
                        ]}
                      />
                    )}
                  </div>
                  {showCheckbox && selectMode ? (
                    <div>
                      <MenuActions resetMenuActions={resetMenuActions} />
                    </div>
                  ) : (
                    <div className="list-title-table-wrap">
                      {renderFields}
                      <span className="stretch-cell" />
                    </div>
                  )}
                </div>
                <AddColumnButton onAdd={createColumn} />
              </ul>
            </div>
            <ReorderableTable
              recordVersions={recordVersions}
              isSorted={false}
              renderer={renderRow}
              onDragEnd={onDragEnd}
              onAddNewRow={hasModuleWriteAccess ? handleClick : undefined}
              newRowName="Action Plan"
            />
          </div>
        </SectionDragContext>
      )}
    </div>
  );
}

export default observer(ActionPlans);
