import { http } from "msw";

import { createDestroyManyMutationResponse } from "../createDestroyMany";

export const destroyManyHandler = http.delete(
  "*/companies/:company_id/:record_type/destroy_many",
  function handler(info) {
    return new Response(JSON.stringify(createDestroyManyMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
