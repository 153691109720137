import classNames from "classnames";
import type { ChangeEvent } from "react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

interface Props {
  fieldName: string;
  hasErrors: boolean;
  recordVersionID: number;
  width: number | string;
  disabled?: boolean;
  exposeData?: (data: string) => void;
  onDataChange?: (
    fieldName: string,
    serializedValue: string,
    value?: string,
  ) => unknown;
  hasErrorClass?: string;
  initialValue?: string;
  isUserEditable?: boolean;
  locked?: boolean;
  pinned?: boolean;
}

function LinkCell({
  fieldName,
  recordVersionID,
  width,
  initialValue,
  disabled,
  hasErrors,
  isUserEditable,
  locked,
  pinned,
  hasErrorClass,
  exposeData,
  onDataChange,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const textInput = useRef();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [valueHelper, setValueHelper] = useState("");

  // Variables
  const style = { width };
  const liClassNames = classNames("cell", "input-cell", "link-cell", {
    active: isActive || showPopup,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "read-only": !isUserEditable,
    "locked-cell pointer-events-none": locked,
    pinned,
  });
  const placeholder = locked ? "N/A" : "- Add Link -";
  const pointerEventsNone = classNames({ "pointer-events-none": !isActive });

  // Effects
  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  useEffect(() => {
    if (isActive) {
      // It won't work without a small timeout :(
      setTimeout(() => {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        textInput.current.focus();
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        textInput.current.select();
      }, 10);
    }
  }, [isActive]);

  // funcs
  const setValue = (val: string) => {
    setValueHelper(val);
    exposeData?.(val);
    onDataChange?.(fieldName, val);
  };
  const handleEnterEditMode = () => {
    setShowPopup(false);
    setIsActive(true);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      textInput.current.blur();
    }
  };

  const onBlur = () => {
    setIsActive(false);
    persistData();
  };

  const persistData = () => {
    if (valueHelper === initialValue) {
      return;
    }

    mainStore.recordVersions.update({
      recordVersionID,
      fieldName,
      value: mainStore.avroSchemas.serializeValue(fieldName, valueHelper),
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onPopupOpen = () => {
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  const getClickable = (val) =>
    val.startsWith("http://") || val.startsWith("https://") ? val : `//${val}`;

  const renderTrigger = (
    <li
      style={style}
      className={liClassNames}
      onBlur={onBlur}
      onClick={() => {
        onPopupClose();
        setIsActive(true);
      }}
      onMouseDown={handleKeyDown}
    >
      <div className="cell-content">
        {initialValue && !isActive ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={valueHelper}
            className={pointerEventsNone}
          >
            {valueHelper}
          </a>
        ) : (
          <input
            // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
            ref={textInput}
            type="text"
            data-testid="input-cell"
            disabled={disabled || locked}
            value={valueHelper}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
        )}
      </div>
    </li>
  );

  if (!initialValue || initialValue === "") {
    return renderTrigger;
  }

  return (
    <Popup
      position="bottom center"
      trigger={renderTrigger}
      open={showPopup && !isActive}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      disabled={disabled || locked}
    >
      <div
        className="table-dropdown policy-dropdown"
        data-testid="link-cell-popup"
      >
        <a target="_blank" rel="noreferrer" href={getClickable(valueHelper)}>
          View Link
        </a>
        <li onClick={handleEnterEditMode}>Replace Link</li>
      </div>
    </Popup>
  );
}

LinkCell.defaultProps = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
  hasErrorClass: "has-errors",
};

export default LinkCell;
