import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";

interface Props {
  riskAssessmentID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalActions?: any[];
  allowDelete?: boolean;
  name?: string;
}

function RiskAssessmentContextMenu({
  riskAssessmentID,
  name,
  additionalActions,
  allowDelete,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const { canDeleteRecords } = mainStore.userPermissions;

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  async function deleteRow() {
    await mainStore.vendors.deleteRiskAssessment(riskAssessmentID);
    const toastSuccess = `“${name}” deleted!`;
    mainStore.toast.setInfoText(toastSuccess);
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="vdd-risk-assessments-context-menu-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown vdd-dropdown"
      data-testid="vdd-risk-assessments-context-menu-content"
    >
      <ul>
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {additionalActions.length > 0 && <hr />}
        {allowDelete && canDeleteRecords && (
          <li
            data-testid="vdd-risk-assessments-context-menu-delete"
            onClick={deleteRowConfirmation}
          >
            Delete
          </li>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Risk Assessment"
      content="Actions are non-reversable. Once deleted, it will also removed from Vendor side (If shared)."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  return (
    ((additionalActions || []).length || allowDelete) && (
      <Popup
        position="bottom right"
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => renderTrigger}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    )
  );
}

RiskAssessmentContextMenu.defaultProps = {
  name: "[noname]",
  additionalActions: [],
  allowDelete: true,
};

export default observer(RiskAssessmentContextMenu);
