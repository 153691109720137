import type { UseQueryOptions } from "@tanstack/react-query";
import { skipToken, useQuery } from "@tanstack/react-query";

import { showAccount } from "@/api/gen/axios/accountsController";
import type {
  ShowAccountQueryParams,
  ShowAccountQueryResponse,
} from "@/api/gen/models";

import { accountsQueryKey } from "./accounts";

interface UseAccountVendorsParams {
  workspaceId: number;
  accountId: number;
}

type QueryFnData = ShowAccountQueryResponse["data"];

export function useAccountVendors<TData = QueryFnData, TError = unknown>(
  { workspaceId, accountId }: UseAccountVendorsParams,
  options?: Partial<UseQueryOptions<QueryFnData, TError, TData>>,
) {
  const queryParams: ShowAccountQueryParams = { expand: "vendors" };

  return useQuery({
    queryKey: accountsQueryKey.details(accountId, queryParams),
    queryFn:
      workspaceId != null && accountId != null
        ? async () => {
            const { data } = await showAccount(
              workspaceId,
              accountId,
              queryParams,
            );
            return data;
          }
        : skipToken,
    ...options,
  });
}
