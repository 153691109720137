import { Icon, IconButton, Typography } from "@/components/Elements";
import type { Question } from "@/features/risk-assessment/types";
import { ConfirmationPopup } from "@/modules/shared/confirmation-popup/ConfirmationPopup";

import type { SelectableQuestion } from "../../../QuestionnaireBuilderPanel/selectable-questions/SelectableQuestionTypes";

interface QuestionHeaderProps {
  order: string;
  questionTypeInfo: SelectableQuestion;
  isTriggerQuestion: boolean;
  onCopy: (() => void) | undefined;
  onDelete: () => void;
  isCollapsed: boolean;
  onCollapseChange: (questionId: number, isCollapsed: boolean) => void;
  question: Question;
}

export function QuestionHeader({
  isCollapsed,
  isTriggerQuestion,
  onCollapseChange,
  onCopy,
  onDelete,
  order,
  question,
  questionTypeInfo,
}: QuestionHeaderProps) {
  return (
    <div className="questionnaire-question__header">
      <div className="questionnaire-question__order-number">{order}</div>
      <div className="questionnaire-question__type-title">
        <Icon name={questionTypeInfo.icon} />
        <Typography label={questionTypeInfo.name} size="sm" />
      </div>
      <div className="questionnaire-question__action-buttons">
        {!isTriggerQuestion && (
          <IconButton size="sm" icon="copy" onClick={onCopy} />
        )}
        <ConfirmationPopup
          text="Are you sure you want to delete this question?"
          onConfirm={onDelete}
          trigger={<IconButton size="sm" icon="trash" />}
        />
        {!isTriggerQuestion && (
          <IconButton
            size="sm"
            icon={isCollapsed ? "chevronDown" : "chevronUp"}
            onClick={() => onCollapseChange(question.id, !isCollapsed)}
          />
        )}
      </div>
    </div>
  );
}
