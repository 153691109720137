import type { UseQueryOptions } from "@tanstack/react-query";
import { skipToken, useQuery } from "@tanstack/react-query";

import type {
  CompanyUser,
  ListOfCompanyUsersPathParams,
  ListOfCompanyUsersQueryParams,
} from "@/api/gen";
import { listOfCompanyUsers } from "@/api/gen/axios/usersController";

interface UseCompanyUsersParams extends ListOfCompanyUsersQueryParams {
  companyId: ListOfCompanyUsersPathParams["company_id"] | undefined;
}

export const getCompanyUsersQueryKey = ({
  companyId,
  workspace_id,
  sort_by,
  view,
}: Partial<UseCompanyUsersParams> = {}) =>
  [
    "company_users",
    companyId ? `companyId:${companyId}` : undefined,
    workspace_id ? `workspace_id:${workspace_id}` : undefined,
    sort_by ? `sort_by:${sort_by}` : undefined,
    view ? `view:${view}` : undefined,
  ].filter(Boolean);

export function useCompanyUsers<TError = Error, TData = CompanyUser[]>(
  params: UseCompanyUsersParams,
  options?: Partial<UseQueryOptions<CompanyUser[], TError, TData>>,
) {
  const { companyId = -1 } = params;

  return useQuery({
    queryKey: getCompanyUsersQueryKey(params),
    queryFn:
      companyId === -1
        ? skipToken
        : async () => {
            const response = await listOfCompanyUsers(companyId, params);
            return response.data;
          },
    ...options,
  });
}
