import { faker } from "@faker-js/faker";

import type {
  UpdateContact200,
  UpdateContactMutationRequest,
  UpdateContactMutationResponse,
  UpdateContactPathParams,
} from "../models/UpdateContact";
import { createContact } from "./createContact";
import { createModifyContactRequest } from "./createModifyContactRequest";

export function createUpdateContactPathParams(): NonNullable<UpdateContactPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateContact200(): NonNullable<UpdateContact200> {
  return { contact: createContact() };
}

export function createUpdateContactMutationRequest(): NonNullable<UpdateContactMutationRequest> {
  return { contact: createModifyContactRequest() };
}

/**
 * @description OK
 */
export function createUpdateContactMutationResponse(): NonNullable<UpdateContactMutationResponse> {
  return { contact: createContact() };
}
