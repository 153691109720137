import type { ControlMappings } from "../models/ControlMappings";

export function createControlMappings(
  data: NonNullable<Partial<ControlMappings>> = {},
): NonNullable<ControlMappings> {
  return {
    ...{ record: {}, record_version: {} },
    ...data,
  };
}
