import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";

interface AttachmentFieldValue {
  name: string;
  signedId: string;
  url: string;
  type: string;
}

function getAttachmentFormValue(value: unknown) {
  const signedId = (value as AttachmentFieldValue | undefined)?.signedId;

  if (!signedId) {
    return undefined;
  }

  return signedId;
}

export function formatDynamicFormValuesToRequest(
  customFields: FieldConfigurationWithLayout[],
  values: Record<string, unknown>,
) {
  return customFields.reduce(
    (acc, { field: { name, field_type, reference_type } }) => {
      const value = values[name];

      if (field_type === "reference" && reference_type === "ThemisDocument") {
        return {
          ...acc,
          [name]: getAttachmentFormValue(value),
        };
      }

      return {
        ...acc,
        [name]: value,
      };
    },
    {},
  );
}
