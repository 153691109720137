import { useQuery } from "@tanstack/react-query";

import type { Company } from "@/stores/types/company-types";

import api from "../legacy/api";

export const COMPANIES_QUERY_KEY = "companies";

export function useCompany(companyId: number | "current") {
  return useQuery({
    queryKey: [COMPANIES_QUERY_KEY, companyId],
    queryFn: () => api.get<{ company: Company }>(`/companies/${companyId}`),
    enabled: !!companyId,
    staleTime: 60 * 60 * 1000,
  });
}
