import { faker } from "@faker-js/faker";

import type {
  WebsiteMonitoringGroupResult200,
  WebsiteMonitoringGroupResultPathParams,
  WebsiteMonitoringGroupResultQueryResponse,
} from "../models/WebsiteMonitoringGroupResult";
import { createMonitoringGroupAssetChildResult } from "./createMonitoringGroupAssetChildResult";

export function createWebsiteMonitoringGroupResultPathParams(): NonNullable<WebsiteMonitoringGroupResultPathParams> {
  return { monitoring_group_result_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupResult200(): NonNullable<WebsiteMonitoringGroupResult200> {
  return { data: createMonitoringGroupAssetChildResult() };
}

/**
 * @description OK
 */
export function createWebsiteMonitoringGroupResultQueryResponse(): NonNullable<WebsiteMonitoringGroupResultQueryResponse> {
  return { data: createMonitoringGroupAssetChildResult() };
}
