import { faker } from "@faker-js/faker";

import type { Attestation } from "../models/Attestation";

export function createAttestation(
  data: NonNullable<Partial<Attestation>> = {},
): NonNullable<Attestation> {
  return {
    ...{
      user_id: faker.number.int(),
      attestation_status: faker.helpers.arrayElement<any>([
        "pending",
        "attested",
      ]),
      attested_at: faker.date.anytime().toISOString(),
    },
    ...data,
  };
}
