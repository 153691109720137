import { faker } from "@faker-js/faker";

import type {
  UpdateProject200,
  UpdateProjectMutationRequest,
  UpdateProjectMutationResponse,
  UpdateProjectPathParams,
} from "../models/UpdateProject";
import { createProject } from "./createProject";
import { createUpdateProjectRequest } from "./createUpdateProjectRequest";

export function createUpdateProjectPathParams(): NonNullable<UpdateProjectPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateProject200(): NonNullable<UpdateProject200> {
  return { data: createProject() };
}

export function createUpdateProjectMutationRequest(): NonNullable<UpdateProjectMutationRequest> {
  return createUpdateProjectRequest();
}

/**
 * @description OK
 */
export function createUpdateProjectMutationResponse(): NonNullable<UpdateProjectMutationResponse> {
  return { data: createProject() };
}
