import { faker } from "@faker-js/faker";

import type {
  UpdateTaskSection200,
  UpdateTaskSectionMutationRequest,
  UpdateTaskSectionMutationResponse,
  UpdateTaskSectionPathParams,
} from "../models/UpdateTaskSection";
import { createTaskSection } from "./createTaskSection";

export function createUpdateTaskSectionPathParams(): NonNullable<UpdateTaskSectionPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateTaskSection200(): NonNullable<UpdateTaskSection200> {
  return { data: createTaskSection() };
}

export function createUpdateTaskSectionMutationRequest(): NonNullable<UpdateTaskSectionMutationRequest> {
  return { data: createTaskSection() };
}

/**
 * @description OK
 */
export function createUpdateTaskSectionMutationResponse(): NonNullable<UpdateTaskSectionMutationResponse> {
  return { data: createTaskSection() };
}
