import { http } from "msw";

import { createWebsiteMonitoringGroupAssetQueryResponse } from "../createWebsiteMonitoringGroupAsset";

export const websiteMonitoringGroupAssetHandler = http.get(
  "*/website_monitoring/:monitoring_group_asset_id/monitoring_group_asset",
  function handler(info) {
    return new Response(
      JSON.stringify(createWebsiteMonitoringGroupAssetQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
