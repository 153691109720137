import React, { useState } from "react";

import { IconButton, Typography } from "..";
import { Flex } from "../Flex/flex";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

export function Accordion({ title, children }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleAccordion() {
    setIsOpen(!isOpen);
  }

  return (
    <Flex column rowGap={12}>
      <Flex columnGap={8} alignCenter>
        <IconButton
          transparent
          size="sm"
          icon={isOpen ? "chevronDown" : "chevronUp"}
          onClick={toggleAccordion}
        />
        <Typography
          size="md"
          weight="semiBold"
          color="generalMidnightDark"
          label={title}
        />
      </Flex>
      {isOpen && children}
    </Flex>
  );
}
