import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import type {
  ListTrainingsQueryParams,
  ListTrainingsQueryResponse,
} from "@/api/gen";
import { listTrainings } from "@/api/gen/axios/trainingsController";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

interface GetTrainingsQueryKeyParams extends ListTrainingsQueryParams {
  workspaceId: number;
}

export const getTrainingsQueryKey = ({
  workspaceId,
  ...params
}: Partial<GetTrainingsQueryKeyParams>): QueryKey =>
  ["trainings", "list", workspaceId, params].filter(Boolean);

export const useTrainings = <
  TError = Error,
  TData = ListTrainingsQueryResponse,
>({
  params,
  options,
}: {
  params: ListTrainingsQueryParams;
  options?: Partial<UseQueryOptions<ListTrainingsQueryResponse, TError, TData>>;
}) => {
  const workspaceId = useRouteWorkspaceId();
  return useQuery({
    queryFn: () => listTrainings(workspaceId, params),
    queryKey: getTrainingsQueryKey({ workspaceId, ...params }),
    ...options,
  });
};
