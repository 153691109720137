import { Button } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";

import { useMainStore } from "@/contexts/Store";

interface AddRecordHeaderProps {
  recordName?: string;
  sectionName?: string;
  canAddSection?: boolean;
  onAddRecord: () => void;
  onAddSection?: () => void;
  wrapperClassNames?: string;
  hideAddRecordButton?: boolean;
}

function AddRecordHeader({
  recordName = "Record",
  sectionName = "Section",
  canAddSection = false,
  hideAddRecordButton = false,
  onAddRecord,
  onAddSection,
  wrapperClassNames,
}: AddRecordHeaderProps) {
  const mainStore = useMainStore();
  const { canManageSections } = mainStore.userPermissions;

  return (
    <div
      className={classNames(
        "tw-mb-0 tw-flex tw-w-full tw-items-center tw-justify-between tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-generals-underline tw-px-6 tw-py-2",
        { [String(wrapperClassNames)]: wrapperClassNames },
      )}
    >
      <div className="tw-flex tw-gap-1">
        {!hideAddRecordButton && (
          <Button onClick={onAddRecord} aria-label={`Add ${recordName}`}>
            Add {recordName}
          </Button>
        )}

        {canManageSections && canAddSection && onAddSection && (
          <Button color="tertiary" onClick={onAddSection}>
            Add {sectionName}
          </Button>
        )}
      </div>
    </div>
  );
}

export default observer(AddRecordHeader);
