import { faker } from "@faker-js/faker";

import type { AttachmentGroup } from "../models/AttachmentGroup";
import { createAttachment } from "./createAttachment";

export function createAttachmentGroup(
  data: NonNullable<Partial<AttachmentGroup>> = {},
): NonNullable<AttachmentGroup> {
  return {
    ...{
      attachments: faker.helpers.arrayElements([createAttachment()]) as any,
      field_name: faker.string.alpha(),
      id: faker.number.int(),
    },
    ...data,
  };
}
