import { observer } from "mobx-react";
import React, { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useHistory } from "react-router-dom";

import DashboardMenu from "@/components/dashboard/sidebar/DashboardMenu";

import { Typography } from "../../../components/Elements";
import DashboardContent from "../../../components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import DuplicatePreview from "../../../components/workspace/duplicate/DuplicatePreview";
import ModuleSelection from "../../../components/workspace/duplicate/ModuleSelection";
import { NameWorkspace } from "../../../components/workspace/duplicate/NameWorkspace";
import SelectWorkspace from "../../../components/workspace/duplicate/SelectWorkspace";
import { useMainStore } from "../../../contexts/Store";
import { useBoolean } from "../../../hooks/useBoolean";

enum STEPS {
  WORKSPACE_SELECT,
  WORKSPACE_NAME,
  MODULE_SELECTION,
  CONFIRMATION,
}

const STEP_NAMES = {
  [STEPS.WORKSPACE_SELECT]: "Select a Workspace",
  [STEPS.WORKSPACE_NAME]: "Create Workspace Name",
  [STEPS.MODULE_SELECTION]: "Select Workspace Modules",
  [STEPS.CONFIRMATION]: "Preview Duplication",
};

const STEP_PROGRESS = {
  [STEPS.WORKSPACE_SELECT]: 25,
  [STEPS.WORKSPACE_NAME]: 50,
  [STEPS.MODULE_SELECTION]: 75,
  [STEPS.CONFIRMATION]: 100,
};

const defaultModules = ["policy", "procedures", "documents"];

function DuplicateWorkspacePage() {
  const mainStore = useMainStore();
  const history = useHistory();
  const loading = useBoolean({ defaultValue: false });
  const [step, setStep] = useState(STEPS.WORKSPACE_SELECT);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  const [sourceWorkspaceId, setSourceWorkspaceId] = useState<string>(undefined);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  const [workspaceName, setWorkspaceName] = useState<string>(undefined);
  const [selectedModules, setSelectedModules] = useState<string[]>([
    ...defaultModules,
  ]);
  const [modulesWithRecords, setModulesWithRecords] = useState<string[]>([]);

  const duplicateWorkspace = async () => {
    loading.onTrue();
    const moduleWorkspaces: { [id: string]: { duplicate_records: boolean } } =
      {};
    mainStore.workspaceSummary.modules
      .filter((module) => selectedModules.includes(module.identifier))
      .forEach(
        (module) =>
          (moduleWorkspaces[module.id.toString()] = {
            duplicate_records: modulesWithRecords.includes(module.identifier),
          }),
      );
    await mainStore.workspaces.duplicate(sourceWorkspaceId, {
      name: workspaceName,
      is_template: false,
      module_workspaces: moduleWorkspaces,
    });
    loading.onFalse();
  };

  return (
    <div className="dashboard-wrap duplicate-workspace-page">
      <DashboardMenu />
      <DashboardContent>
        <DashboardHeader
          title="Duplicate Existing Workspace"
          onBackClick={() => history.goBack()}
          RightActionBar={
            <div className="duplicate-workspace-page__step-info">
              <CircularProgressbar
                value={STEP_PROGRESS[step]}
                strokeWidth={15}
                className="duplicate-workspace-page__progress"
              />
              <Typography
                label={STEP_NAMES[step]}
                weight="semiBold"
                size="md"
                color="brandingDimViolet"
              />
            </div>
          }
        />
        <DashboardContentWrapper>
          {step === STEPS.WORKSPACE_SELECT && (
            <SelectWorkspace
              workspaceId={sourceWorkspaceId}
              onWorkspaceChange={setSourceWorkspaceId}
              onContinue={() => setStep(STEPS.WORKSPACE_NAME)}
              onBack={() => history.goBack()}
            />
          )}
          {step === STEPS.WORKSPACE_NAME && (
            <NameWorkspace
              name={workspaceName}
              onNameChange={setWorkspaceName}
              onContinue={() => setStep(STEPS.MODULE_SELECTION)}
              onBack={() => setStep(() => STEPS.WORKSPACE_SELECT)}
            />
          )}
          {step === STEPS.MODULE_SELECTION && (
            <ModuleSelection
              newWorkspaceName={workspaceName}
              sourceWorkspaceId={sourceWorkspaceId}
              onContinue={() => setStep(STEPS.CONFIRMATION)}
              onBack={() => setStep(() => STEPS.WORKSPACE_NAME)}
              onCancel={() => history.goBack()}
              loading={loading.value}
              selectedModules={selectedModules}
              handleSwitch={(identifier) =>
                selectedModules.includes(identifier)
                  ? setSelectedModules((prev) =>
                      prev.filter((item) => item !== identifier),
                    )
                  : setSelectedModules((prev) => [...prev, identifier])
              }
              modulesWithRecords={modulesWithRecords}
              handleRecordsIncluded={(identifier) =>
                modulesWithRecords.includes(identifier)
                  ? setModulesWithRecords((prev) =>
                      prev.filter((item) => item !== identifier),
                    )
                  : setModulesWithRecords((prev) => [...prev, identifier])
              }
              defaultModules={defaultModules}
            />
          )}
          {step === STEPS.CONFIRMATION && (
            <DuplicatePreview
              newWorkspaceName={workspaceName}
              sourceWorkspaceId={sourceWorkspaceId}
              onConfirm={duplicateWorkspace}
              onBack={() => setStep(() => STEPS.MODULE_SELECTION)}
              onCancel={() => history.goBack()}
              selectedModules={selectedModules}
              modulesWithRecords={modulesWithRecords}
              defaultModules={defaultModules}
              loading={loading.value}
            />
          )}
        </DashboardContentWrapper>
      </DashboardContent>
    </div>
  );
}

export default observer(DuplicateWorkspacePage);
