import { faker } from "@faker-js/faker";

import type { Question } from "../models/Question";
import { createAnswer } from "./createAnswer";

export function createQuestion(
  data: NonNullable<Partial<Question>> = {},
): NonNullable<Question> {
  return {
    ...{
      id: faker.number.int(),
      description: faker.string.alpha(),
      followup_ref_question_id: faker.number.int(),
      input_type: faker.string.alpha(),
      is_optional: faker.datatype.boolean(),
      position: faker.number.float(),
      weight: faker.number.float(),
      question_group_id: faker.number.int(),
      questionnaire_id: faker.number.int(),
      risk_area_id: faker.number.int(),
      risk_type: faker.string.alpha(),
      text: faker.string.alpha(),
      uuid: faker.helpers.arrayElement<any>([
        faker.string.alpha(),
        faker.string.uuid(),
      ]),
      depending_question_choice_ids: faker.helpers.arrayElements([
        undefined,
      ]) as any,
      question_choices: faker.helpers.arrayElements([undefined]) as any,
      answer: Object.assign({}, createAnswer()),
      uncompleted_comments_count: {},
      has_follow_up_questions: faker.datatype.boolean(),
      assignments: faker.helpers.arrayElements([undefined]) as any,
      responders: faker.helpers.arrayElements([undefined]) as any,
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
    },
    ...data,
  };
}
