import { faker } from "@faker-js/faker";

import type {
  GetTag200,
  GetTagPathParams,
  GetTagQueryResponse,
} from "../models/GetTag";
import { createTag } from "./createTag";

export function createGetTagPathParams(): NonNullable<GetTagPathParams> {
  return { tag_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetTag200(): NonNullable<GetTag200> {
  return createTag();
}

/**
 * @description OK
 */
export function createGetTagQueryResponse(): NonNullable<GetTagQueryResponse> {
  return createTag();
}
