import { http } from "msw";

import { createUpdateContactMutationResponse } from "../createUpdateContact";

export const updateContactHandler = http.put(
  "*/contacts/:id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateContactMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
