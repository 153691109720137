import { MiniTag, Stack } from "@themis/ui";
import { keyBy } from "lodash";

import type { DynamicCellProps } from "../dynamic-cell";

export const DynamicStatusCell = ({
  value,
  configuration,
}: DynamicCellProps<string>) => {
  if (!value) {
    return null;
  }

  const optionsMap = keyBy(configuration.options, "key");

  const statusOptions = (Array.isArray(value) ? value : [value])
    .map((item) => (item ? optionsMap[item] : undefined))
    .filter((option) => option !== undefined);

  return (
    <Stack direction="row" spacing="0.5">
      {statusOptions.map((option) => (
        <MiniTag key={option?.value} colorIndex={option?.id}>
          {option?.value}
        </MiniTag>
      ))}
    </Stack>
  );
};
