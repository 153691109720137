import { faker } from "@faker-js/faker";

import type {
  UpdateTag200,
  UpdateTagMutationRequest,
  UpdateTagMutationResponse,
  UpdateTagPathParams,
} from "../models/UpdateTag";
import { createTag } from "./createTag";
import { createUpdateTagRequest } from "./createUpdateTagRequest";

export function createUpdateTagPathParams(): NonNullable<UpdateTagPathParams> {
  return { tag_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateTag200(): NonNullable<UpdateTag200> {
  return createTag();
}

export function createUpdateTagMutationRequest(): NonNullable<UpdateTagMutationRequest> {
  return createUpdateTagRequest();
}

/**
 * @description OK
 */
export function createUpdateTagMutationResponse(): NonNullable<UpdateTagMutationResponse> {
  return createTag();
}
