import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import type { OnChangeHandlerFunc, SuggestionDataItem } from "react-mentions";
import { Mention, MentionsInput } from "react-mentions";

import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useMainStore } from "@/contexts/Store";
import { useSearchParams } from "@/hooks/useSearchParams";
import type { DisplayUser } from "@/stores/types/user-types";

import { userColors } from "../../constants";

interface Props {
  content: string;
  editable: boolean;
  dataTestID?: string;
  privateUsers?: boolean;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;
  onChange?: OnChangeHandlerFunc;
  onKeyDown?: (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  placeholder?: string;
  singleLine?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

function UserMentionsInput({
  content,
  placeholder,
  editable,
  dataTestID,
  style,
  singleLine,
  privateUsers,
  onClick,
  onChange,
  onKeyDown,
  className,
}: Props) {
  const mainStore = useMainStore();
  const suggestionUser = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams<{ comments_tab?: CommentTab }>();
  const isInternalTab = searchParams.comments_tab === "internal";
  const usePrivateUserList = isInternalTab || privateUsers;

  const mentionsClass = classNames({
    "user-mentions-input": editable,
    "user-mentions-text": !editable,
  });

  function renderTagUserSuggestion(suggestion: SuggestionDataItem) {
    const user = suggestion as DisplayUser;
    const optionClasses = classNames("option", {
      "awaiting-verification": user.awaiting_verification,
    });
    return (
      <div className={optionClasses}>
        <span
          className="users-circle"
          style={{ background: userColors[user.icon_color_index] }}
        >
          {user.initials}
        </span>
        {user.full_name}
        {user.awaiting_verification && (
          <span className="awaiting-verification-status">{user.status}</span>
        )}
      </div>
    );
  }

  return (
    <div
      className={`${mentionsClass} ${className}`}
      style={style}
      data-testid="user-mentions-input-container"
    >
      <MentionsInput
        singleLine={singleLine}
        disabled={!editable}
        value={content}
        placeholder={placeholder}
        className="mentions"
        onClick={onClick}
        onChange={onChange}
        onKeyDown={onKeyDown}
        suggestionsPortalHost={
          suggestionUser.current ? suggestionUser.current : undefined
        }
        data-testid={dataTestID}
        allowSuggestionsAboveCursor
      >
        <Mention
          trigger="@"
          data={
            usePrivateUserList
              ? mainStore.comments.privateUsers
              : mainStore.users.activeDisplayUsers
          }
          renderSuggestion={renderTagUserSuggestion}
          appendSpaceOnAdd
        />
      </MentionsInput>
      <div ref={suggestionUser} className="suggestion-wrap" />
    </div>
  );
}

UserMentionsInput.defaultProps = {
  singleLine: true,
};

export default observer(UserMentionsInput);
