import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import api from "@/api/legacy/api";
import { _1_HOUR_MS } from "@/constants/duration";
import type { User } from "@/stores/types/user-types";

import { USERS_QUERY_KEY } from "../users";

export const getUsersQueryKey = (workspaceId: number) => [
  USERS_QUERY_KEY,
  workspaceId,
];

export function useUsers<TError = Error, TData = User[]>(
  workspaceId: number,
  options?: Partial<UseQueryOptions<User[], TError, TData>>,
) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: getUsersQueryKey(workspaceId),
    queryFn: async () => {
      const users = await api.get<{ users: User[] }>(
        `/workspaces/${workspaceId}/users`,
      );

      users.data.users.forEach((user) => {
        queryClient.setQueryData([USERS_QUERY_KEY, user.id], user);
      });

      return users.data.users;
    },
    enabled: !!workspaceId,
    staleTime: _1_HOUR_MS,
    ...options,
  });
}

export const selectUser = (userId: number) => (data: User[]) =>
  data.find((user) => user.id === userId);
