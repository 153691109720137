import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { ACCOUNT_ROUTES } from "../accounts-routes";
import { AccountDetails } from "./AccountDetails";
import { Accounts } from "./Accounts";
import { AllContracts } from "./AllContracts";
import { ContactDetails } from "./ContactDetails";
import { ContractDetails } from "./ContractDetails";
import { Documents } from "./documents/documents";
import { NewChildAccountDetails } from "./NewChildAccountDetails";

export function AccountsRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}${ACCOUNT_ROUTES.allContracts}`}>
        <AllContracts />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.contractDetails}`}>
        <ContractDetails />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.contactDetails}`}>
        <ContactDetails />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.newChildAccountDetails}`}>
        <NewChildAccountDetails />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.documents}`}>
        <Documents />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.details}`}>
        <AccountDetails />
      </Route>

      <Route exact path={path}>
        <Accounts />
      </Route>
    </Switch>
  );
}
