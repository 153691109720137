import { faker } from "@faker-js/faker";

import type {
  CreateDepartment201,
  CreateDepartmentMutationRequest,
  CreateDepartmentMutationResponse,
  CreateDepartmentPathParams,
} from "../models/CreateDepartment";
import { createDepartment } from "./createDepartment";

export function createCreateDepartmentPathParams(): NonNullable<CreateDepartmentPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description Created
 */
export function createCreateDepartment201(): NonNullable<CreateDepartment201> {
  return { data: createDepartment() };
}

export function createCreateDepartmentMutationRequest(): NonNullable<CreateDepartmentMutationRequest> {
  return { department: createDepartment() };
}

/**
 * @description Created
 */
export function createCreateDepartmentMutationResponse(): NonNullable<CreateDepartmentMutationResponse> {
  return { data: createDepartment() };
}
