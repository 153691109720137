import { faker } from "@faker-js/faker";

import type { CompanyUser } from "../models/CompanyUser";
import { createAppliedPermissionSet } from "./createAppliedPermissionSet";
import { createDepartment } from "./createDepartment";
import { createUser } from "./createUser";

export function createCompanyUser(
  data?: NonNullable<Partial<CompanyUser>>,
): NonNullable<CompanyUser> {
  return Object.assign({}, createUser(), {
    email: faker.string.alpha(),
    status: faker.string.alpha(),
    department: Object.assign({}, createDepartment()),
    applied_permission_sets: faker.helpers.arrayElements([
      createAppliedPermissionSet(),
    ]) as any,
  });
}
