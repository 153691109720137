var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cva } from "cva";
import dayjs from "dayjs";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { isDayPickerMultiple, isDayPickerRange, isDayPickerSingle, } from "react-day-picker";
import { PiCalendarBlankBold, PiCaretLeftBold, PiCaretRightBold, } from "react-icons/pi";
import { cn } from "../../lib/utils";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import { Calendar } from "../Calendar/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
var readOnlyStyles = "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var lockedStyles = "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var containerStyles = "tw-inline-flex tw-w-full tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-cursor-pointer tw-bg-neutral-25 tw-transition-colors";
var fontStyles = "tw-text-sm tw-font-medium tw-font-sans tw-text-neutral-500";
var focusVisibleStyles = "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
var datePickerInputVariants = cva({
    base: [containerStyles, fontStyles, focusVisibleStyles],
    variants: {
        size: {
            sm: "tw-h-7",
            md: "tw-h-8",
            lg: "tw-h-9",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});
var MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
var MonthSelect = function (_a) {
    var selected = _a.selected, onSelectMonth = _a.onSelectMonth;
    var _b = useState(Number(selected.getFullYear())), year = _b[0], setYear = _b[1];
    return (React.createElement("div", { className: "tw-w-auto tw-p-5" },
        React.createElement("div", { className: "tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-1" },
            React.createElement(IconButton, { size: "lg", color: "transparent", Icon: PiCaretLeftBold, onClick: function () { return setYear(function (value) { return Number(value) - 1; }); } }),
            React.createElement("span", { className: "tw-font-sans tw-text-sm tw-font-semibold tw-text-neutral-400" }, year),
            React.createElement(IconButton, { size: "lg", color: "transparent", Icon: PiCaretRightBold, onClick: function () { return setYear(function (value) { return Number(value) + 1; }); } })),
        React.createElement("div", { className: "tw-mt-4 tw-grid tw-grid-cols-3 tw-gap-1" }, MONTHS.map(function (month, index) { return (React.createElement(Button, { color: selected.getMonth() === index &&
                Number(selected.getFullYear()) === year
                ? "secondary"
                : "grey", onClick: function () {
                return onSelectMonth(new Date(selected.setFullYear(year, index)));
            }, className: "tw-flex tw-h-8 tw-w-[5.5rem] tw-items-center tw-justify-center tw-rounded tw-px-7", key: month }, month)); }))));
};
var formatDate = function (calendarProps, dateFormat) {
    if (!calendarProps.selected) {
        return;
    }
    if (isDayPickerSingle(calendarProps)) {
        return dayjs(calendarProps.selected).format(dateFormat);
    }
    if (isDayPickerMultiple(calendarProps)) {
        return calendarProps.selected
            .map(function (d) { return dayjs(d).format(dateFormat); })
            .join(", ");
    }
    if (isDayPickerRange(calendarProps)) {
        var _a = calendarProps.selected, from = _a.from, to = _a.to;
        if (!from) {
            return;
        }
        return "".concat(dayjs(from).format(dateFormat), " - ").concat(to ? dayjs(to).format(dateFormat) : "");
    }
};
var getSelectedDate = function (calendarProps) {
    var _a, _b;
    if (isDayPickerSingle(calendarProps)) {
        return calendarProps.selected;
    }
    if (isDayPickerMultiple(calendarProps) && ((_a = calendarProps.selected) === null || _a === void 0 ? void 0 : _a.length)) {
        return calendarProps.selected[0];
    }
    if (isDayPickerRange(calendarProps)) {
        return (_b = calendarProps.selected) === null || _b === void 0 ? void 0 : _b.from;
    }
    return;
};
var DatePicker = forwardRef(function (_a, ref) {
    var _b;
    var size = _a.size, locked = _a.locked, readOnly = _a.readOnly, closeOnDateSelect = _a.closeOnDateSelect, _c = _a.placeholder, placeholder = _c === void 0 ? "- Add Date -" : _c, calendarProps = _a.calendarProps, 
    // Since we are in the U.S., we default to MM/dd/yyyy
    _d = _a.dateFormat, 
    // Since we are in the U.S., we default to MM/dd/yyyy
    dateFormat = _d === void 0 ? "MM/DD/YYYY" : _d, className = _a.className, showHeader = _a.showHeader, defaultOpen = _a.defaultOpen, onClose = _a.onClose, slotProps = __rest(_a, ["size", "locked", "readOnly", "closeOnDateSelect", "placeholder", "calendarProps", "dateFormat", "className", "showHeader", "defaultOpen", "onClose"]);
    var isMountedRef = useRef(false);
    var disabled = locked || readOnly;
    var selectedDate = getSelectedDate(calendarProps);
    var _e = useState(defaultOpen || false), open = _e[0], setOpen = _e[1];
    var _f = useState(selectedDate), month = _f[0], setMonth = _f[1];
    var _g = useState(false), showMonthSelect = _g[0], setShowMonthSelect = _g[1];
    useEffect(function () {
        if (open) {
            setShowMonthSelect(false);
            setMonth(selectedDate);
            isMountedRef.current = true;
        }
        else if (isMountedRef.current && !open && onClose) {
            onClose();
        }
    }, [open]);
    useEffect(function () {
        if (closeOnDateSelect && open) {
            setOpen(false);
        }
    }, [calendarProps.selected]);
    var handleTodayClick = function (event) {
        if (!calendarProps.onSelect) {
            return;
        }
        setShowMonthSelect(false);
        if (isDayPickerSingle(calendarProps)) {
            calendarProps.onSelect(new Date(), new Date(), {}, event);
            setMonth(new Date());
        }
        if (isDayPickerMultiple(calendarProps) && calendarProps.onSelect) {
            calendarProps.onSelect([new Date()], new Date(), {}, event);
            setMonth(new Date());
        }
        if (isDayPickerRange(calendarProps) && calendarProps.onSelect) {
            calendarProps.onSelect({ from: new Date() }, new Date(), {}, event);
            setMonth(new Date());
        }
    };
    var handleSelectMonth = function (selectedMonth) {
        setMonth(selectedMonth);
        setShowMonthSelect(false);
    };
    return (React.createElement(Popover, { modal: true, open: open, onOpenChange: setOpen },
        React.createElement(PopoverTrigger, __assign({ disabled: disabled, className: cn((_b = {},
                _b[lockedStyles] = locked,
                _b[readOnlyStyles] = readOnly,
                _b["tw-cursor-default"] = disabled,
                _b), className), ref: ref, asChild: true }, slotProps),
            React.createElement("button", { "aria-expanded": open, className: cn(datePickerInputVariants({ size: size }), {
                    "tw-border-neutral-100": !open,
                    "tw-border-primary-300": open,
                    "tw-text-neutral-200": !getSelectedDate(calendarProps),
                }) },
                React.createElement("div", { className: "tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap" }, formatDate(calendarProps, dateFormat) || placeholder),
                !disabled && (React.createElement(PiCalendarBlankBold, { className: cn("tw-ml-auto tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-500 tw-transition-transform", { "tw-text-neutral-200": disabled }) })))),
        React.createElement(PopoverContent, { className: "tw-w-auto tw-py-1", align: "start" },
            showMonthSelect ? (React.createElement(MonthSelect, { selected: selectedDate || new Date(), onSelectMonth: handleSelectMonth })) : (React.createElement(React.Fragment, null,
                showHeader && (React.createElement("div", { className: "tw-border-y-0 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-generals-underline tw-pb-2 tw-pl-4 tw-pt-2.5" },
                    React.createElement("div", { className: "tw-mb-2 tw-text-base tw-font-semibold tw-text-primaryDim-300" }, "Selected Date"),
                    React.createElement("div", { className: "tw-font-medium tw-text-neutral-500" }, (selectedDate && dayjs(selectedDate).format(dateFormat)) ||
                        "No Date Selected"))),
                React.createElement(Calendar, __assign({ initialFocus: true, month: month, onMonthChange: handleSelectMonth, onClickMonth: function () { return setShowMonthSelect(true); } }, calendarProps)))),
            React.createElement("div", { className: "tw-flex tw-w-full tw-items-center tw-justify-center tw-pb-3" },
                React.createElement(Button, { onClick: handleTodayClick }, "Set ".concat(showMonthSelect ? "This Month" : "Today"))))));
});
DatePicker.displayName = "DatePicker";
export { DatePicker };
