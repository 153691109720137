var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useState } from "react";
import { FilePicker } from "../../Form/FilePicker";
function AttachmentCell(_a) {
    var _b, _c;
    var value = _a.value, setValue = _a.setValue, handleSelected = _a.handleSelected, props = __rest(_a, ["value", "setValue", "handleSelected"]);
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(0), progress = _e[0], setProgress = _e[1];
    var pickerRef = useRef(null);
    var editable = Boolean((_b = props.colDef) === null || _b === void 0 ? void 0 : _b.editable);
    var handleSelectedPlaceholder = function (file) {
        var _progress = 0;
        setProgress(0);
        setLoading(true);
        var fakeLoading = setInterval(function () {
            var _a;
            if (_progress < 100) {
                _progress += 1;
                setProgress(_progress);
                return;
            }
            clearInterval(fakeLoading);
            (_a = pickerRef.current) === null || _a === void 0 ? void 0 : _a.click();
            setValue(file);
            setProgress(0);
            setLoading(false);
        }, 15);
    };
    var file = {
        name: value === null || value === void 0 ? void 0 : value.file_name,
        type: value === null || value === void 0 ? void 0 : value.content_type,
        url: value === null || value === void 0 ? void 0 : value.file_url,
    };
    return (React.createElement("div", { className: "tw-h-full tw-w-full" },
        React.createElement(FilePicker, __assign({ ref: pickerRef, onSelectFile: handleSelected || handleSelectedPlaceholder, onRemoveFile: function () { return setValue(undefined); }, percentage: progress, isLoading: loading, file: file, className: "tw-h-full tw-w-full tw-rounded-none tw-border-none group-hover/row:tw-bg-neutral-50", isOpen: ((_c = props.api.getFocusedCell()) === null || _c === void 0 ? void 0 : _c.column.getColId()) ===
                props.column.getColId(), readOnly: !editable }, props))));
}
export default AttachmentCell;
