import { http } from "msw";

import { createGetDepartmentsQueryResponse } from "../createGetDepartments";

export const getDepartmentsHandler = http.get(
  "*/companies/:company_id/departments",
  function handler(info) {
    return new Response(JSON.stringify(createGetDepartmentsQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
