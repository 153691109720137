export const PROJECTS_ROUTES = {
  base: "/projects",
  details: "/:project_id",
  tasks: "/:project_id/tasks",
  archived: "/archived",
} as const;

export const PROJECTS_LIST_PATH =
  `/workspaces/:workspace_id${PROJECTS_ROUTES.base}` as const;

export const PROJECT_DETAILS_PATH =
  `${PROJECTS_LIST_PATH}${PROJECTS_ROUTES.details}` as const;

export const PROJECT_TASKS_PATH =
  `${PROJECTS_LIST_PATH}${PROJECTS_ROUTES.tasks}` as const;

export const PROJECTS_ARCHIVE_PATH =
  `${PROJECTS_LIST_PATH}${PROJECTS_ROUTES.archived}` as const;
