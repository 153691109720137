import { faker } from "@faker-js/faker";

import type {
  CreateField201,
  CreateField403,
  CreateFieldMutationRequest,
  CreateFieldMutationResponse,
  CreateFieldPathParams,
} from "../models/CreateField";
import { createFieldConfiguration } from "./createFieldConfiguration";
import { createRecordType } from "./createRecordType";

export function createCreateFieldPathParams(): NonNullable<CreateFieldPathParams> {
  return { company_id: faker.number.int(), record_type: createRecordType() };
}

/**
 * @description Field created successfully
 */
export function createCreateField201(): NonNullable<CreateField201> {
  return { data: createFieldConfiguration() };
}

/**
 * @description Forbidden
 */
export function createCreateField403(): NonNullable<CreateField403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateFieldMutationRequest(): NonNullable<CreateFieldMutationRequest> {
  return {
    field: {
      display_name: faker.string.alpha(),
      field_type: faker.string.alpha(),
      reference_type: faker.string.alpha(),
      multiselect: faker.datatype.boolean(),
      required: faker.datatype.boolean(),
      internal_only: faker.datatype.boolean(),
      options: faker.helpers.arrayElements([
        { key: faker.string.alpha(), value: faker.string.alpha() },
      ]) as any,
    },
  };
}

/**
 * @description Field created successfully
 */
export function createCreateFieldMutationResponse(): NonNullable<CreateFieldMutationResponse> {
  return { data: createFieldConfiguration() };
}
