import { faker } from "@faker-js/faker";

import type {
  CloneObject201,
  CloneObjectMutationRequest,
  CloneObjectMutationResponse,
  CloneObjectPathParams,
} from "../models/CloneObject";
import { createAccount } from "./createAccount";
import { createProject } from "./createProject";
import { createRecordType } from "./createRecordType";
import { createTask } from "./createTask";
import { createTraining } from "./createTraining";

export function createCloneObjectPathParams(): NonNullable<CloneObjectPathParams> {
  return { record_type: createRecordType(), record_id: faker.number.int() };
}

/**
 * @description Object cloned
 */
export function createCloneObject201(): NonNullable<CloneObject201> {
  return {
    data: faker.helpers.arrayElement<any>([
      createTask(),
      createProject(),
      createAccount(),
      createTraining(),
    ]),
  };
}

export function createCloneObjectMutationRequest(): NonNullable<CloneObjectMutationRequest> {
  return { target_workspace_id: faker.number.int() };
}

/**
 * @description Object cloned
 */
export function createCloneObjectMutationResponse(): NonNullable<CloneObjectMutationResponse> {
  return {
    data: faker.helpers.arrayElement<any>([
      createTask(),
      createProject(),
      createAccount(),
      createTraining(),
    ]),
  };
}
