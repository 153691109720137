import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/check-icon.svg";
import pointsIcon from "../../../images/table-image/icon/points-icon.svg";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog/confirmation-dialog";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import Unlock from "../shared/Unlock";

interface Props {
  recordVersion: RecordVersion;
}

function CustomerSupportComplaintsContextMenu({ recordVersion }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [tab, setTab] = useState<null | string>(null);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [sendSlide, setSendSlide] = useState(false);

  // Variables
  const { activeWorkspace, tableName, isAdmin, workspaceID } =
    mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const recordVersionID = recordVersion.id;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("customer_support", moduleWorkspaces, true);
  const { taskDetail } = mainStore;

  // Variables - send to cw
  const handleOpenSendSlideMenu = () => {
    setIsOpenModal(false);
    setShowSlideMenu(true);
    setSendSlide(true);
  };

  const handleCloseSlideMenu = () => {
    setShowSlideMenu(false);
    setSendSlide(false);
  };

  // Variables - Status of the Complaint
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const customerSupportStatus = mainStore.avroSchemas.firstValueForField(
    "customer_support_status",
    recordVersion?.data,
  );
  const isClosed = status === "closed";
  const isComplete = customerSupportStatus === "complete";
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  const isDisabled = useMemo(() => {
    let result = isClosed || isComplete;
    if (!result) {
      return result;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    if (["Customer Support", "Compliance"].includes(tableName) && isAdmin) {
      result = false;
    }
    return result;
  }, [isClosed, isComplete, tableName, isAdmin]);

  const submitButtonTitle = useMemo(() => {
    if (tab === "customer support") {
      return "View";
    }

    if (tab === "compliance") {
      return "Verify";
    }

    return "";
  }, [tab]);

  const reopenButtonTitle = useMemo(() => {
    if (tab === "compliance") {
      return "Reopen";
    }

    return "";
  }, [tab]);

  // Hooks
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    switch (location.pathname) {
      case `/workspaces/${workspaceID}/modules/customer-support/support`:
        setTab("customer support");
        break;
      case `/workspaces/${workspaceID}/modules/customer-support/compliance`:
        setTab("compliance");
        break;
      default:
        setTab(null);
    }
  }, [location]);

  // Functions
  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    if (isDisabled) {
      handlePopUpClose();
    } else {
      setIsDropdownOpen(false);
      setIsDeleteConfirmationOpen(true);
    }
  }

  function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      mainStore.customerSupport.delete(recordVersionID);
      mainStore.toast.setText("Complaint was successfully deleted!");
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDropdownOpen(true);
    setIsDeleteConfirmationOpen(false);
  }

  function reopenComplaint() {
    mainStore.customerSupport.reopen(recordVersionID);
    handlePopUpClose();
  }

  // Verify / Submit
  function submitPressed() {
    mainStore.customerSupport.close(recordVersionID);
  }

  // Customer Support
  function completeComplaint() {
    setIsOpenModal(false);
    setIsDropdownOpen(false);
    mainStore.customerSupport.complete(recordVersionID);
  }

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  const renderTrigger = (
    <button
      className={classNames("points-button", {
        "big-active": isOpenModal,
        "tw-pt-1.5": tab === "compliance",
      })}
      data-testid="complaints-context-menu-trigger"
    >
      <img
        className="points-icon"
        src={pointsIcon}
        alt="points-icon"
        data-testid="points-button-trigger"
      />
    </button>
  );

  return (
    <div
      className={classNames("list-points", {
        "!tw-mt-0 !tw-pl-5": tab === "customer support",
        "!tw-pl-0": tab === "compliance",
      })}
      data-testid="complaints-context-menu"
    >
      {tab === "compliance" && (
        <div>
          {isClosed ? (
            <Unlock
              onYes={reopenComplaint}
              tableName="Compliance"
              moduleIdentifier="customer_support"
              buttonText={reopenButtonTitle}
            />
          ) : (
            <button
              className="finalize-active"
              onClick={submitPressed}
              disabled={!hasModuleWriteAccess}
            >
              {tab === "compliance" && (
                <img src={checkIcon} alt="upload-icon" />
              )}
              {submitButtonTitle}
            </button>
          )}
        </div>
      )}

      <Popup
        position="bottom right"
        trigger={hasModuleWriteAccess ? renderTrigger : <div />}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && (
          <div
            className="table-dropdown policy-dropdown"
            data-testid="complaints-context-menu-dropdown"
          >
            <ul>
              {location.pathname ===
                `/workspaces/${workspaceID}/modules/complaints` && (
                <li
                  onClick={() =>
                    history.push(
                      `/workspaces/${workspaceID}/modules/complaints/${recordVersionID}`,
                    )
                  }
                  data-testid="view-detail"
                >
                  View {recordName} Detail
                </li>
              )}

              {location.pathname ===
                `/workspaces/${workspaceID}/modules/customer-support/support` &&
                !isComplete && (
                  <>
                    <li onClick={completeComplaint}>Submit to Compliance</li>
                    <hr />
                  </>
                )}
              {activeWorkspace?.is_internal === true &&
                hasModuleWriteAccess && (
                  <ShareToCW
                    recordName={recordName}
                    sendTrigger={handleOpenSendSlideMenu}
                  />
                )}

              <li onClick={handleCreateTask}>Create Task</li>

              {canDeleteRecords && (
                <>
                  <hr />
                  <li
                    onClick={deleteRowConfirmation}
                    className={classNames({ disabled: isDisabled })}
                    data-testid="complaints-context-menu-dropdown-delete"
                  >
                    Delete
                  </li>
                </>
              )}
            </ul>
          </div>
        )}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            <ConfirmationDialog
              heading="Delete Complaints"
              content="Are you sure you want to delete this complaints? Actions are not reversable."
              handleConfirm={deleteRow}
              handleReject={noButtonPressed}
            />
          </div>
        )}
      </Popup>

      <SlideMenu open={showSlideMenu} closeSlideMenu={handleCloseSlideMenu}>
        {sendSlide && (
          <SendRecordVersion
            recordVersionID={recordVersionID}
            recordName={recordName}
          />
        )}
      </SlideMenu>
    </div>
  );
}

export default observer(CustomerSupportComplaintsContextMenu);
