import { PreviewTable } from "@themis/ui";
import { PiChartBarBold, PiGlobeBold, PiTextTBold } from "react-icons/pi";
import { generatePath, useRouteMatch } from "react-router-dom";

import { useRecordFindings } from "@/api/queries/findings";
import { useLayoutFields } from "@/api/utils/fields/use-layout-fields";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import mainStore from "@/stores/Main";
import { getDynamicPreviewTableColumns } from "@/utils/table/get-dynamic-preview-table-columns/get-dynamic-preview-table-columns";

import { FINDINGS_TABLE_COMPONENT_OVERRIDES } from "../config/findingsTable";
import { findingRoutes } from "../pages/routes";
import { FindingActions } from "./FindingActions";
import { FindingIdLinkCell } from "./FindingsTable/FindingIdLinkCell";

export interface SendFindingSearchParams
  extends Record<string, string | undefined> {
  send_finding?: string;
}

export function FindingsList({ recordId }: { recordId: number | undefined }) {
  const { url } = useRouteMatch();
  const companyId = mainStore.context.companyID!;
  const workspaceId = useRouteWorkspaceId();

  const {
    layoutFields,
    isPending: isColumnsPending,
    isError: isColumnsError,
  } = useLayoutFields({
    companyId,
    recordType: "findings",
    workspaceId,
    layoutType: "table",
  });

  const {
    data: findings,
    isPending: isFindingsPending,
    isError: isFindingsError,
  } = useRecordFindings(workspaceId, Number(recordId));

  if (isFindingsPending || isColumnsPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isFindingsError || isColumnsError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/modules/qa-tests", {
            workspace_id: workspaceId,
          }),
        }}
      >
        Could not load findings.
      </ErrorContainer>
    );
  }

  return (
    <PreviewTable
      rows={findings?.data}
      columns={[
        {
          title: "ID",
          type: "id",
          width: 160,
          // eslint-disable-next-line react/no-unstable-nested-components
          Component: (finding) => <FindingIdLinkCell findingId={finding.id} />,
          Icon: PiGlobeBold,
        },
        ...getDynamicPreviewTableColumns(layoutFields),
      ]}
      columnIconOverrides={{
        status: PiChartBarBold,
        risk_level: PiChartBarBold,
        action_plan_title: PiTextTBold,
      }}
      componentOverrides={FINDINGS_TABLE_COMPONENT_OVERRIDES}
      // eslint-disable-next-line react/no-unstable-nested-components
      rowActions={(finding) => <FindingActions finding={finding} />}
      addRow={{
        text: "Add Finding",
        linkTo: generatePath(`${url}${findingRoutes.details}`, {
          findingId: "new",
        }),
      }}
    />
  );
}
