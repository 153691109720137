import { observer } from "mobx-react";
import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import { useRecordVersion } from "@/api/queries/recordVersions";
import Loading from "@/components/Loading";

import { moduleAssessmentRoutes } from "../routes";
import { ModuleAssessmentDetails } from "./ModuleAssessmentDetails";
import { ModuleAssessmentsList } from "./ModuleAssessmentsList";

export const ModuleAssessmentRoutes = observer(() => {
  const { path } = useRouteMatch();

  const { record_version_id } = useParams<{
    record_version_id: string;
  }>();

  const { data: recordVersionData, isPending } = useRecordVersion(
    Number(record_version_id),
  );

  const moduleWorkspaceId =
    recordVersionData?.data?.record_version?.record?.module_workspace_id || 0;

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <Switch>
      <Route path={moduleAssessmentRoutes.detail}>
        <ModuleAssessmentDetails
          recordVersionId={Number(record_version_id)}
          moduleWorkspaceId={moduleWorkspaceId}
        />
      </Route>
      <Route exact path={path}>
        <ModuleAssessmentsList recordVersionId={Number(record_version_id)} />
      </Route>
    </Switch>
  );
});
