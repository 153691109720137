import { faker } from "@faker-js/faker";

import type {
  ListAccountsWithLimitedResponse200,
  ListAccountsWithLimitedResponsePathParams,
  ListAccountsWithLimitedResponseQueryResponse,
} from "../models/ListAccountsWithLimitedResponse";

export function createListAccountsWithLimitedResponsePathParams(): NonNullable<ListAccountsWithLimitedResponsePathParams> {
  return { module_workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListAccountsWithLimitedResponse200(): NonNullable<ListAccountsWithLimitedResponse200> {
  return {
    data: faker.helpers.arrayElements([
      { id: faker.number.int(), name: faker.string.alpha() },
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createListAccountsWithLimitedResponseQueryResponse(): NonNullable<ListAccountsWithLimitedResponseQueryResponse> {
  return {
    data: faker.helpers.arrayElements([
      { id: faker.number.int(), name: faker.string.alpha() },
    ]) as any,
  };
}
