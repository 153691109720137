import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import CreativeView from "@/components/table/shared/creative-view/CreativeView";
import { useMainStore } from "@/contexts/Store";

const VersionedDocumentPreviewPage = () => {
  const history = useHistory();
  const mainStore = useMainStore();
  const { current } = mainStore.recordVersions;

  const recordName = current?.meta?.name;

  return (
    <DashboardContent>
      <DashboardHeader
        title={`${recordName ? recordName : ""} Document Preview`}
        onBackClick={() => history.goBack()}
      />
      <DashboardContentWrapper>
        <CreativeView />
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(VersionedDocumentPreviewPage);
