import { faker } from "@faker-js/faker";

import type { MonitoringGroupAssetChild } from "../models/MonitoringGroupAssetChild";
import { createMonitoringGroupAssetChildResult } from "./createMonitoringGroupAssetChildResult";

export function createMonitoringGroupAssetChild(
  data: NonNullable<Partial<MonitoringGroupAssetChild>> = {},
): NonNullable<MonitoringGroupAssetChild> {
  return {
    ...{
      id: faker.number.int(),
      data: faker.string.alpha(),
      content_type: faker.string.alpha(),
      creator_name: faker.string.alpha(),
      page_name: faker.string.alpha(),
      text: faker.string.alpha(),
      asset_name: faker.string.alpha(),
      group_name: faker.string.alpha(),
      group_id: faker.number.int(),
      asset_id: faker.number.int(),
      asset_type: faker.string.alpha(),
      asset_data: faker.string.alpha(),
      child_results_count: faker.number.int(),
      child_unresolved_results_count: faker.number.int(),
      child_grouped_results_count: faker.number.int(),
      child_grouped_unresolved_results_count: faker.number.int(),
      monitoring_group_asset_id: faker.number.int(),
      monitoring_group_asset_child_uid: faker.string.alpha(),
      monitoring_group_asset_child_created_at: faker.date
        .anytime()
        .toISOString(),
      monitoring_group_asset_child_updated_at: faker.date
        .anytime()
        .toISOString(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
      results: faker.helpers.arrayElements([
        createMonitoringGroupAssetChildResult(),
      ]) as any,
      group_by_date: faker.helpers.arrayElements([undefined]) as any,
      docs_compliance_group_by_date: {},
      docs_non_compliance_group_by_date: {},
      docs_group_by_date: {},
      images_group_by_date: {},
      metadata_group_by_date: {},
    },
    ...data,
  };
}
