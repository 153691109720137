import { faker } from "@faker-js/faker";

import type { Dashboard } from "../models/Dashboard";
import { createDashboardComponent } from "./createDashboardComponent";

export function createDashboard(
  data: NonNullable<Partial<Dashboard>> = {},
): NonNullable<Dashboard> {
  return {
    ...{
      id: faker.number.int(),
      workspace_id: faker.number.int(),
      name: faker.string.alpha(),
      system_name: faker.string.alpha(),
      components: faker.helpers.arrayElements([
        createDashboardComponent(),
      ]) as any,
      component_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    },
    ...data,
  };
}
