import { SimpleTooltip } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../Elements";

interface LibraryExportProps {
  moduleWorkspaceID: number | null;
  sectionsRecordIds: number[];
}

function LibraryExport({
  moduleWorkspaceID,
  sectionsRecordIds,
}: LibraryExportProps) {
  const mainStore = useMainStore();
  const [showPopup, setShowPopup] = useState(false);
  const { formatMessage } = useIntl();

  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  async function processTableDownload() {
    const spreadSheetData = await mainStore.riskRegisters.exportLibrary(
      moduleWorkspaceID,
      sectionsRecordIds,
    );
    if (spreadSheetData) {
      const link = document.createElement("a");
      const blob = new Blob([new Uint8Array(spreadSheetData)], {
        type: "application/octet-stream",
      });
      link.href = URL.createObjectURL(blob);
      link.download = "risk_register_library.xlsx";
      link.click();
    }
    setShowPopup(false);
  }

  // elements
  const renderTrigger = (
    <button
      className={classNames("import-button library-export", {
        active: showPopup,
      })}
      data-testid="rr-library-export-trigger"
    >
      <Icon
        name="download"
        color={showPopup ? "generalWhite" : "generalDark"}
      />
      {formatMessage({ defaultMessage: "Export" })}
    </button>
  );

  const renderPopupContent = (
    <div
      className="table-dropdown export-table-dropdown"
      data-testid="rr-library-export-popup"
    >
      <h3>{formatMessage({ defaultMessage: "Export Library to Excel" })}</h3>
      <p>
        {formatMessage({
          defaultMessage:
            "Click the button below to export the library to an Excel file.",
        })}
      </p>
      <SimpleTooltip
        title={formatMessage({
          defaultMessage: "Sections must be expanded to export records.",
        })}
        tooltipTriggerProps={{
          asChild: true,
        }}
        tooltipContentProps={{ side: "bottom" }}
        disabled={!!sectionsRecordIds.length}
      >
        <button
          className="export-table-button"
          onClick={processTableDownload}
          data-testid="rr-library-export-submit"
          disabled={!sectionsRecordIds.length}
        >
          <Icon
            name="download"
            color={
              !sectionsRecordIds.length ? "generalDarkGray" : "generalWhite"
            }
          />
          {formatMessage({ defaultMessage: "Export to XLSX File" })}
        </button>
      </SimpleTooltip>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      {renderPopupContent}
    </Popup>
  );
}

export default observer(LibraryExport);
