import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { ResponseConfig } from "@/api/client";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import type {
  CreateCommentMutationRequest,
  CreateCommentMutationResponse,
  CreateCommentPathParams,
} from "../../gen";
import { createComment } from "../../gen/axios/commentsController";
import { getRecordsQueryKey } from "../records/getRecordsQueryKey";
import { getCommentsQueryKey } from "./useComments";

interface UseCreateCommentParams {
  recordType: CreateCommentPathParams["record_type"];
  recordId: CreateCommentPathParams["record_id"];
}

export function useCreateComment<TError = Error, TContext = unknown>(
  { recordType, recordId }: UseCreateCommentParams,
  {
    onSuccess,
    ...options
  }: Partial<
    UseMutationOptions<
      ResponseConfig<CreateCommentMutationResponse>["data"],
      TError,
      CreateCommentMutationRequest,
      TContext
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const workspaceId = useRouteWorkspaceId();

  return useMutation({
    ...options,
    mutationFn: (data) => createComment(recordType, recordId, data),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({ recordType, workspaceId }),
      });
      queryClient.invalidateQueries({
        queryKey: getCommentsQueryKey({ recordType, recordId }),
      });
      onSuccess?.(data, variables, context);
    },
  });
}
