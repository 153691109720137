import { faker } from "@faker-js/faker";

import type {
  UpdateTask200,
  UpdateTaskMutationRequest,
  UpdateTaskMutationResponse,
  UpdateTaskPathParams,
} from "../models/UpdateTask";
import { createTask } from "./createTask";
import { createUpdateTaskRequest } from "./createUpdateTaskRequest";

export function createUpdateTaskPathParams(): NonNullable<UpdateTaskPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateTask200(): NonNullable<UpdateTask200> {
  return { data: createTask() };
}

export function createUpdateTaskMutationRequest(): NonNullable<UpdateTaskMutationRequest> {
  return createUpdateTaskRequest();
}

/**
 * @description OK
 */
export function createUpdateTaskMutationResponse(): NonNullable<UpdateTaskMutationResponse> {
  return { data: createTask() };
}
