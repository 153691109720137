import { faker } from "@faker-js/faker";

import type {
  ListOfCompanyControlCategories200,
  ListOfCompanyControlCategoriesPathParams,
  ListOfCompanyControlCategoriesQueryResponse,
} from "../models/ListOfCompanyControlCategories";
import { createControlCategory } from "./createControlCategory";

export function createListOfCompanyControlCategoriesPathParams(): NonNullable<ListOfCompanyControlCategoriesPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListOfCompanyControlCategories200(): NonNullable<ListOfCompanyControlCategories200> {
  return {
    data: faker.helpers.arrayElements([createControlCategory()]) as any,
  };
}

/**
 * @description OK
 */
export function createListOfCompanyControlCategoriesQueryResponse(): NonNullable<ListOfCompanyControlCategoriesQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createControlCategory()]) as any,
  };
}
