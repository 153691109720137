import { faker } from "@faker-js/faker";

import type { Task } from "../models/Task";
import { createTaskable } from "./createTaskable";
import { createTaskLink } from "./createTaskLink";
import { createTaskSection } from "./createTaskSection";
import { createThemisDocument } from "./createThemisDocument";
import { createThemisRecord } from "./createThemisRecord";

export function createTask(
  data?: NonNullable<Partial<Task>>,
): NonNullable<Task> {
  return Object.assign({}, createThemisRecord(), {
    name: faker.string.alpha(),
    description: faker.string.alpha(),
    status: faker.helpers.arrayElement<any>([
      "not_started",
      "in_progress",
      "done",
    ]),
    visibility: faker.helpers.arrayElement<any>([
      "internal",
      "public",
      "private",
    ]),
    subtype: faker.helpers.arrayElement<any>(["Task", "Subtask"]),
    parent_id: faker.number.int(),
    due_date: faker.string.alpha(),
    created_at: faker.date.anytime().toISOString(),
    completed_at: faker.date.anytime().toISOString(),
    assignee_id: faker.number.int(),
    priority: faker.string.alpha(),
    labels: faker.helpers.arrayElements([faker.string.alpha()]) as any,
    task_section_id: faker.number.int(),
    workspace_id: faker.number.int(),
    task_section: Object.assign({}, createTaskSection()),
    taskables: faker.helpers.arrayElements([createTaskable()]) as any,
    task_links: faker.helpers.arrayElements([createTaskLink()]) as any,
    collaborator_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    archived_at: faker.date.anytime().toISOString(),
    comments_count: faker.number.int(),
    attachments: faker.helpers.arrayElements([createThemisDocument()]) as any,
  });
}
