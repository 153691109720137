import { http } from "msw";

import { createCountByStatusMutationResponse } from "../createCountByStatus";

export const countByStatusHandler = http.put(
  "*/reports/count_by_status",
  function handler(info) {
    return new Response(JSON.stringify(createCountByStatusMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
