import { http } from "msw";

import { createUpdateLayoutMutationResponse } from "../createUpdateLayout";

export const updateLayoutHandler = http.put(
  "*/companies/:company_id/:record_type/layouts/:id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateLayoutMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
