import { http } from "msw";

import { createDeleteFieldMutationResponse } from "../createDeleteField";

export const deleteFieldHandler = http.delete(
  "*/companies/:company_id/:record_type/fields/:id",
  function handler(info) {
    return new Response(JSON.stringify(createDeleteFieldMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
