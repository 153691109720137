var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cva } from "cva";
import * as React from "react";
import { cn } from "../../lib/utils";
var defaultStyles = "tw-flex tw-w-full tw-box-border tw-resize-y tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-bg-transparent tw-transition-colors";
var fontStyles = "tw-text-sm tw-font-medium tw-font-sans tw-text-neutral-500";
var placeHolderStyles = "placeholder:tw-font-medium placeholder:tw-text-sm placeholder:tw-text-neutral-200 placeholder:tw-font-sans placeholder:tw-opacity-100";
var disabledStyles = "disabled:tw-cursor-not-allowed disabled:tw-opacity-50";
var focusStyles = "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
var readOnlyStyles = "read-only:tw-bg-primaryDim-25 read-only:tw-border-primaryDim-25 read-only:tw-text-neutral-300 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var lockedStyles = "read-only:tw-bg-primary-25 read-only:tw-border-primaryDim-25 read-only:tw-text-primaryDim-300 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var textAreaVariants = cva({
    base: [
        defaultStyles,
        fontStyles,
        placeHolderStyles,
        disabledStyles,
        focusStyles,
    ],
    variants: {
        size: {
            sm: "tw-min-h-9 tw-px-3 tw-py-[0.4375rem]",
            md: "tw-min-h-24 tw-p-3",
        },
    },
    defaultVariants: {
        size: "md",
    },
});
var TextArea = React.forwardRef(function (_a, ref) {
    var _b;
    var locked = _a.locked, readOnly = _a.readOnly, _c = _a.placeholder, placeholder = _c === void 0 ? "Type here" : _c, className = _a.className, size = _a.size, props = __rest(_a, ["locked", "readOnly", "placeholder", "className", "size"]);
    return (React.createElement("textarea", __assign({ className: cn(textAreaVariants({ size: size }), (_b = {},
            _b[lockedStyles] = locked,
            _b[readOnlyStyles] = readOnly,
            _b), className), readOnly: locked || readOnly, placeholder: "- ".concat(placeholder, " -"), ref: ref }, props)));
});
TextArea.displayName = "TextArea";
export { TextArea };
