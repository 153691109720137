import { faker } from "@faker-js/faker";

import type {
  GetDepartments200,
  GetDepartmentsPathParams,
  GetDepartmentsQueryResponse,
} from "../models/GetDepartments";
import { createDepartment } from "./createDepartment";

export function createGetDepartmentsPathParams(): NonNullable<GetDepartmentsPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetDepartments200(): NonNullable<GetDepartments200> {
  return { data: faker.helpers.arrayElements([createDepartment()]) as any };
}

/**
 * @description OK
 */
export function createGetDepartmentsQueryResponse(): NonNullable<GetDepartmentsQueryResponse> {
  return { data: faker.helpers.arrayElements([createDepartment()]) as any };
}
