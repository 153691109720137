import { useMutation } from "@tanstack/react-query";
import type { UseMutationOptions } from "@tanstack/react-query";

import { fetchFile } from "../legacy/fetch-file/fetch-file";

export function useFetchFile<TError = Error>(
  options?: Partial<UseMutationOptions<Blob, TError, string>>,
) {
  return useMutation({
    mutationFn: (urlOrId: string) => fetchFile(urlOrId),
    ...options,
  });
}
