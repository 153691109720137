import "./table-cell.scss";

import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import React from "react";

type TableCellProps = React.PropsWithChildren<{
  isFirstDataCell?: boolean;
  isLastDataCell?: boolean;
  isLinkCell?: boolean;
  noPadding?: boolean;
  isFocused?: boolean;
  isEditable?: boolean;
  minWidth?: number;
  hasErrors?: boolean;
}> &
  ComponentPropsWithoutRef<"td">;

const TableCell = ({
  isFirstDataCell,
  isLastDataCell,
  isLinkCell,
  noPadding,
  isFocused,
  isEditable,
  minWidth,
  width,
  children,
  hasErrors,
  className,
  ...rest
}: TableCellProps) => (
  <td
    className={classNames(`generic-table-cell ${className}`, {
      "generic-table-cell--first-data-cell": isFirstDataCell,
      "generic-table-cell--last-data-cell": isLastDataCell,
      "generic-table-cell--link-cell": isLinkCell,
      "generic-table-cell--no-padding": noPadding,
      "generic-table-cell--focused": isFocused,
      "generic-table-cell--edittable": isEditable,
      "generic-table-cell--has-errors": hasErrors,
    })}
    style={{ minWidth }}
    {...rest}
  >
    {children}
  </td>
);

export default TableCell;
