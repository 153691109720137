import { faker } from "@faker-js/faker";

import type {
  ListOfCompanyContracts200,
  ListOfCompanyContractsPathParams,
  ListOfCompanyContractsQueryResponse,
} from "../models/ListOfCompanyContracts";
import { createContract } from "./createContract";

export function createListOfCompanyContractsPathParams(): NonNullable<ListOfCompanyContractsPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListOfCompanyContracts200(): NonNullable<ListOfCompanyContracts200> {
  return { contracts: faker.helpers.arrayElements([createContract()]) as any };
}

/**
 * @description OK
 */
export function createListOfCompanyContractsQueryResponse(): NonNullable<ListOfCompanyContractsQueryResponse> {
  return { contracts: faker.helpers.arrayElements([createContract()]) as any };
}
