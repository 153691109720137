import { faker } from "@faker-js/faker";

import type {
  CreateSection201,
  CreateSectionMutationRequest,
  CreateSectionMutationResponse,
  CreateSectionPathParams,
} from "../models/CreateSection";
import { createCreateSectionRequest } from "./createCreateSectionRequest";
import { createSection } from "./createSection";

export function createCreateSectionPathParams(): NonNullable<CreateSectionPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description Created
 */
export function createCreateSection201(): NonNullable<CreateSection201> {
  return { data: createSection() };
}

export function createCreateSectionMutationRequest(): NonNullable<CreateSectionMutationRequest> {
  return createCreateSectionRequest();
}

/**
 * @description Created
 */
export function createCreateSectionMutationResponse(): NonNullable<CreateSectionMutationResponse> {
  return { data: createSection() };
}
