import { faker } from "@faker-js/faker";

import type {
  ListComments200,
  ListCommentsPathParams,
  ListCommentsQueryResponse,
} from "../models/ListComments";
import { createComment } from "./createComment";
import { createRecordType } from "./createRecordType";

export function createListCommentsPathParams(): NonNullable<ListCommentsPathParams> {
  return { record_type: createRecordType(), record_id: faker.number.int() };
}

/**
 * @description A list of comments associated to the referenced object
 */
export function createListComments200(): NonNullable<ListComments200> {
  return { data: faker.helpers.arrayElements([createComment()]) as any };
}

/**
 * @description A list of comments associated to the referenced object
 */
export function createListCommentsQueryResponse(): NonNullable<ListCommentsQueryResponse> {
  return { data: faker.helpers.arrayElements([createComment()]) as any };
}
