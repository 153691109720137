import { faker } from "@faker-js/faker";

import type { RiskRegisterGroupItem } from "../models/RiskRegisterGroupItem";
import { createLevel } from "./createLevel";

export function createRiskRegisterGroupItem(
  data: NonNullable<Partial<RiskRegisterGroupItem>> = {},
): NonNullable<RiskRegisterGroupItem> {
  return {
    ...{
      id: faker.number.int(),
      name: faker.string.alpha(),
      weight: faker.number.float(),
      overall_score: faker.number.float(),
      inherent_score: faker.number.float(),
      residual_risk_score: faker.number.float(),
      inherent_level: createLevel(),
      residual_level: createLevel(),
    },
    ...data,
  };
}
