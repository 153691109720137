import { http } from "msw";

import { createUpdateTrainingMutationResponse } from "../createUpdateTraining";

export const updateTrainingHandler = http.put(
  "*/trainings/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateTrainingMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
