import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import api from "@/api/legacy/api";
import type { UserWorkspacePermissions } from "@/stores/types/user-permissions-types";

export const getUserWorkspacePermissionsQueryKey = (
  params: UserWorkspacePermissionsParams,
): QueryKey => ["user-permissions", "workspace", params];

interface UserWorkspacePermissionsParams {
  workspaceId: string | undefined;
  companyId: string | undefined;
}

interface UserWorkspacePermissionsQueryResponse {
  result: UserWorkspacePermissions;
}

export const useUserWorkspacePermissions = <
  TError = Error,
  TData = UserWorkspacePermissionsQueryResponse["result"],
>(
  params: UserWorkspacePermissionsParams,
  options?: Partial<
    UseQueryOptions<
      UserWorkspacePermissionsQueryResponse["result"],
      TError,
      TData
    >
  >,
) =>
  useQuery({
    queryFn: async () => {
      const response = await getUserWorkspacePermissions(params);
      return response.result;
    },
    enabled: params.workspaceId !== undefined && params.companyId !== undefined,
    queryKey: getUserWorkspacePermissionsQueryKey(params),
    ...options,
  });

const getUserWorkspacePermissions = async (
  params: UserWorkspacePermissionsParams,
) => {
  const { data } = await api.get<UserWorkspacePermissionsQueryResponse>(
    `/companies/${params.companyId}/user_permissions`,
    { params: { workspace_id: params.workspaceId, domain: "workspace" } },
  );

  return data;
};
