import { faker } from "@faker-js/faker";

import type {
  CreateTag201,
  CreateTagMutationRequest,
  CreateTagMutationResponse,
  CreateTagPathParams,
} from "../models/CreateTag";
import { createCreateTagRequest } from "./createCreateTagRequest";
import { createTag } from "./createTag";

export function createCreateTagPathParams(): NonNullable<CreateTagPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateTag201(): NonNullable<CreateTag201> {
  return createTag();
}

export function createCreateTagMutationRequest(): NonNullable<CreateTagMutationRequest> {
  return createCreateTagRequest();
}

/**
 * @description OK
 */
export function createCreateTagMutationResponse(): NonNullable<CreateTagMutationResponse> {
  return createTag();
}
