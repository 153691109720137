import { observer } from "mobx-react";
import React from "react";
import { generatePath } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import CWPartnerTabs from "../../components/CWPartnerTabs";
import PartnerDocumentsWrapper from "../../components/Documents/PartnerDocumentsWrapper";
import { routes } from "../../routes";

function PartnerDocumentsPage() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const recordVersionID = mainStore.recordVersions.list[0]?.id || -1;

  return (
    <PartnerDocumentsWrapper
      recordVersionID={recordVersionID.toString()}
      // eslint-disable-next-line react/no-unstable-nested-components
      Tabs={() => <CWPartnerTabs />}
      backButtonPath={generatePath(routes.DASHBOARD_PATH, {
        workspace_id: Number(workspaceID),
      })}
    />
  );
}

export default observer(PartnerDocumentsPage);
