import { http } from "msw";

import { createWebsiteMonitoringGroupResultsQueryResponse } from "../createWebsiteMonitoringGroupResults";

export const websiteMonitoringGroupResultsHandler = http.get(
  "*/website_monitoring/:monitoring_group_asset_id/monitoring_group_results",
  function handler(info) {
    return new Response(
      JSON.stringify(createWebsiteMonitoringGroupResultsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
