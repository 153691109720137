import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { skipToken, useQuery } from "@tanstack/react-query";

import type {
  ListCommentsPathParams,
  ListCommentsQueryResponse,
} from "@/api/gen/models/ListComments";

import { listComments } from "../../gen/axios/commentsController";

interface UseCommentsParams {
  recordType: ListCommentsPathParams["record_type"];
  recordId: ListCommentsPathParams["record_id"];
}

export const getCommentsQueryKey = (params?: UseCommentsParams): QueryKey => [
  "comments",
  params,
];

export function useComments<TError = Error, TData = ListCommentsQueryResponse>(
  { recordType, recordId }: UseCommentsParams,
  options?: Partial<UseQueryOptions<ListCommentsQueryResponse, TError, TData>>,
) {
  return useQuery({
    ...options,
    queryKey: getCommentsQueryKey({ recordType, recordId }),
    queryFn:
      recordType && recordId
        ? () => listComments(recordType, recordId)
        : skipToken,
    select: (data) => data?.data,
  });
}
